export const findPbNameById = (idToFind: string, items): string => {
    if (items) {
        for (const obj in items) {
            if (items.hasOwnProperty(obj) && items[obj]?.playbooks) {
                const foundItem = items[obj].playbooks.find((item: any) => item._id === idToFind);
                if (foundItem) {
                    return foundItem.name;
                }
            }
        }
    }

    return idToFind;
}