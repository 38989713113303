import './account-takeover.scss';
import { BaseProps } from '../../constants/types';
import { DeviationIdentityActivity } from '../../../../types/playbooks-config';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import React from 'react';
import InfoIcon from '@mui/icons-material/Info';
import { AMToolTipAd } from '../../../../components/core/AMTooltipAd/AMTooltipAd';


const options = ['miles', 'km'].map((i) => ({ key: i, value: i }))

const DeviationInIdentityActivity = ({ parentState, isSavedOnce, getState, defaultState,
    formType, ruleType }: BaseProps<DeviationIdentityActivity>) => {
    const [state, setState] = useState<DeviationIdentityActivity>(defaultState);
    const { register, errors, control, getValues, setValue, reset } = useForm<DeviationIdentityActivity>();

    useEffect(() => {
        getState(state)
    })

    useEffect(() => {
        setState(defaultState);
        reset(defaultState)
    }, [defaultState]);

    useEffect(() => {
        if (isSavedOnce) {
            setState(parentState);
            reset(parentState);
        }
    }, [isSavedOnce]);

    const handleFormChange = () => {
        const s = getValues() as any;
        if (s.account_takeover_params) {
            s.account_takeover_params.displacementValue = s?.account_takeover_params?.displacementValue ?
                parseInt(s?.account_takeover_params?.displacementValue?.toString()) : null;
            s.account_takeover_params.distanceTraveledValue = s?.account_takeover_params?.distanceTraveledValue ?
                parseInt(s?.account_takeover_params?.distanceTraveledValue?.toString()) : null
        }

        setState(s)
    }


    return <>
        <form action="/" onChange={handleFormChange}>
            <div className={"account-take-over-container " + (formType === 'view' ? ' disable-config-item' : '')}>
                <span className='playbook-config-title'
                >Detect deviations in identity activity by evaluating access events against the following criteria:
                </span>
                <div className="account-take-over-flex-item">
                    <input type='checkbox'
                        checked={state?.deviation_identity_params?.geoLocationFlag}
                        ref={register}
                        name='deviation_identity_params.geoLocationFlag'
                    ></input>
                    <label className='top15 label-flex'>Access from unusual GeoLocation (Latitude, Longitude)</label>
                    <AMToolTipAd
                        title={"Detect access originating from a new or unusual geolocation (latitude & longitude)."}>
                        <InfoIcon style={{ fontSize: 14, color: '#d4d8e1', marginTop: -10, marginLeft: 2, transform: 'skewX(-10deg)' }} />
                    </AMToolTipAd>
                </div>
                <div className='and-text-at'>AND</div>
                <div className="account-take-over-flex-item">
                    <input type='checkbox'
                        checked={state?.deviation_identity_params?.cityCountryFlag}
                        name='deviation_identity_params.cityCountryFlag' ref={register}></input>
                    <label className='top15 label-flex'>Unusual City or Country</label>
                    <AMToolTipAd
                        title={"Detect access from a new or unusual city and/or country."}>
                        <InfoIcon style={{ fontSize: 14, color: '#d4d8e1', marginTop: -10, marginLeft: 2, transform: 'skewX(-10deg)' }} />
                    </AMToolTipAd>
                </div>
                <div className='and-text-at'>AND</div>
                <div className="account-take-over-flex-item">
                    <input type='checkbox' name='deviation_identity_params.iPCidrFlag'
                        checked={state?.deviation_identity_params?.iPCidrFlag}
                        ref={register}></input>
                    <label className='top6'>Unusual IP Address</label>
                    <AMToolTipAd
                        title={"Detect access originating from a new or unusual IP address."}>
                        <InfoIcon style={{ fontSize: 14, color: '#d4d8e1', marginTop: -10, marginLeft: 2, transform: 'skewX(-10deg)' }} />
                    </AMToolTipAd>
                </div>
                <div className='and-text-at'>AND</div>
                <div className="account-take-over-flex-item">
                    <input type='checkbox' name='deviation_identity_params.ispFlag' ref={register}
                        checked={state?.deviation_identity_params?.ispFlag}
                    ></input>
                    <label className='top6'>Unusual Internet Service Provider (ISP)</label>
                    <AMToolTipAd
                        title={"Detect access from a new or unusual Internet Service Provider (ISP)."}>
                        <InfoIcon style={{ fontSize: 14, color: '#d4d8e1', marginTop: -10, marginLeft: 2, transform: 'skewX(-10deg)' }} />
                    </AMToolTipAd>
                </div>
                <div className='and-text-at'>AND</div>
                <div className="account-take-over-flex-item">
                    <input type='checkbox' name='deviation_identity_params.hostnameAccessFlag' ref={register}
                        checked={state?.deviation_identity_params?.hostnameAccessFlag}
                    ></input>
                    <label className='top6'>Unusual Hostnames</label>
                    <AMToolTipAd
                        title={"Detect access originating from a new or unusual Hostname."}>
                        <InfoIcon style={{ fontSize: 14, color: '#d4d8e1', marginTop: -10, marginLeft: 2, transform: 'skewX(-10deg)' }} />
                    </AMToolTipAd>
                </div>
                <div className='and-text-at'>AND</div>
                <div className="account-take-over-flex-item">
                    <input type='checkbox' name='deviation_identity_params.appByUserFlag' ref={register}
                        checked={state?.deviation_identity_params?.appByUserFlag}
                    ></input>
                    <label className='top6'>Unfamiliar Applications Accessed</label>
                    <AMToolTipAd
                        title={"Detect access to a new or unusual Application."}>
                        <InfoIcon style={{ fontSize: 14, color: '#d4d8e1', marginTop: -10, marginLeft: 2, transform: 'skewX(-10deg)' }} />
                    </AMToolTipAd>
                </div>
                <div className='and-text-at'>AND</div>
                <div className="account-take-over-flex-item">
                    <input type='checkbox' name='deviation_identity_params.deviceAccessFlag' ref={register}
                        checked={state?.deviation_identity_params?.deviceAccessFlag}
                    ></input>
                    <label className='top6'>Unusual Device</label>
                    <AMToolTipAd
                        classes={{ tooltip: 'deviation-tooltip-pb' }}
                        title={<span>Detect access from a new or unusual device (device brand and model). <br /><br />Note: This field might not be available in all the events/flows. If selected in combination with other parameters, it can result in skipping the creation of the incident.</span>}>
                        <InfoIcon style={{ fontSize: 14, color: '#d4d8e1', marginTop: -10, marginLeft: 2, transform: 'skewX(-10deg)' }} />
                    </AMToolTipAd>
                </div>
                <div className='and-text-at'>AND</div>
                <div className="account-take-over-flex-item">
                    <input type='checkbox' name='deviation_identity_params.osAccessFlag' ref={register}
                        checked={state?.deviation_identity_params?.osAccessFlag}
                    ></input>
                    <label className='top6'>Unusual Operating System</label>
                    <AMToolTipAd
                        classes={{ tooltip: 'deviation-tooltip-pb' }}
                        title={<span>Detect access from a new or unusual operating system (OS name and version). <br></br><br />Note: This field might not be available in all the events/flows. If selected in combination with other parameters, it can result in skipping the creation of the incident.</span>}>
                        <InfoIcon style={{ fontSize: 14, color: '#d4d8e1', marginTop: -10, marginLeft: 2, transform: 'skewX(-10deg)' }} />
                    </AMToolTipAd>
                </div>
                <div className='and-text-at'>AND</div>
                <div className="account-take-over-flex-item">
                    <input type='checkbox' name='deviation_identity_params.browserAccessFlag' ref={register}
                        checked={state?.deviation_identity_params?.browserAccessFlag}
                    ></input>
                    <label className='top6'>Unusual Browser</label>
                    <AMToolTipAd
                        classes={{ tooltip: 'deviation-tooltip-pb' }}
                        title={<span>Detect access from a new or unusual browser (browser name and version). <br /><br />Note: This field might not be available in all the events/flows. If selected in combination with other parameters, it can result in skipping the creation of the incident.</span>}>
                        <InfoIcon style={{ fontSize: 14, color: '#d4d8e1', marginTop: -10, marginLeft: 2, transform: 'skewX(-10deg)' }} />
                    </AMToolTipAd>
                </div>
            </div>
        </form>
    </>
}

export default DeviationInIdentityActivity;