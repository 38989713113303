import React, { useEffect, useRef, useState } from "react";
import { ArrayParam, NumberParam, StringParam, withDefault, withQueryParams } from "use-query-params";
import { useAuthDetails } from "../../components/Authorization";
import { Api } from "../../components/Axios";
import { useToasts } from "../../components/core";
import AMAdminTable, { ITableAction, ITableColumn, ITableReference, ITableResult } from "../../components/core/AMTable/AMAdminTable";
import { AMAdminPopUp, AMAdminRightPanel, AMAdminSearchTable, IAMAdminSearchTableRef } from "../ref/AMAdminCommonComponents";
import { DirParamCollection, DirQueryIpDeletePopUpProps, PanelData } from "./DirQueryParamTypes";
import { DirQueryForm } from "./DirQueryParamForm";
import { ColDef } from "@ag-grid-community/core";
import ModalPopup from "../../components/core/AMModalPopup/ModalPopup";
import BaseAMAdminGrid from "../../components/core/AMTable/BaseAMAdminGrid";
import { useBaseAMGrid } from "../../components/core/AMTable/useBaseAMGrid";

const CollectorTableColumns: ColDef[] = [
    {
        headerName: 'Name',
        field: 'name',
        cellClass: "align_left ellipsis",
        sortable: true,
        cellRenderer: ({ data }) => {
            return <div className="width100-query-param ellipsis" title={data.name}>{data.name}</div>
        }
    },
    {
        headerName: 'Host',
        field: 'host',
        sortable: true,
        cellClass: "align_left",
        cellRenderer: ({ data }) => {
            return <div className="width100-query-param ellipsis" title={data.host}>{data.host}</div>
        }
    },
    {
        headerName: 'Port',
        field: 'port',
        cellClass: "align_left ellipsis",
        sortable: true,
        cellRenderer: ({ data }) => {
            return <div className="width100-query-param ellipsis" title={data.port}>{data.port}</div>
        }
    },
    {
        headerName: 'Base Domain',
        field: 'base_domain',
        cellClass: "align_left ellipsis",
        sortable: true,
        cellRenderer: ({ data }) => {
            return <div className="width100-query-param ellipsis" title={data.base_domain}>{data.base_domain}</div>
        }
    },
    {
        headerName: 'Bind DN',
        field: 'binddn',
        cellClass: "align_left ellipsis",
        sortable: true,
        cellRenderer: ({ data }) => {
            return <div className="width100-query-param ellipsis" title={data.binddn}>{data.binddn}</div>
        }
    },
    {
        headerName: 'Type',
        field: 'type',
        cellClass: "align_left ellipsis",
        sortable: true
    },
];

const DirQueryParamsActions: ITableAction<DirParamCollection | unknown>[] = [
    {
        actionId: 'edit-dir-query-param',
        actionLabel: 'Edit',
    },
    {
        actionId: 'delete-dir-query-param',
        actionLabel: 'Delete'
    }
]

const DirQueryParamListing = ({ query, setQuery }: any) => {
    const [data, setData] = useState<ITableResult<DirParamCollection | any> | any>();
    const childRef = useRef<ITableReference>();
    const { refreshGrid } = useBaseAMGrid(childRef)
    const { authDetails } = useAuthDetails()!;
    const [panelData, setPanelData] = useState<PanelData>({ panelTitle: '', showPanel: false, panelData: undefined });
    const [showPopUp, setShowPopUp] = useState<DirParamCollection | undefined>(undefined);
    const searchRef = useRef<IAMAdminSearchTableRef>();
    const { addToast } = useToasts();

    DirQueryParamsActions[0].actionCallback = (data?: DirParamCollection | any) => {
        if (data) {
            setPanelData({ panelTitle: 'Edit Dir Query Params', showPanel: true, panelData: data })
        }
    }

    DirQueryParamsActions[1].actionCallback = (data?: DirParamCollection | any) => {
        if (data) {
            setShowPopUp(data)
        }
    }

    const handleOnClosed = () => {
        setPanelData({ ...panelData, showPanel: false });
        setShowPopUp(undefined);
    }

    const proceedDelete = () => {
        const headers = { 'Operation': 'DELETE' };
        Api.post(`/globaldirectory/${showPopUp?._id}`, "", { headers }).then((res: { data: any }) => {
            handleOnClosed();
            addToast("Dir query params have been deleted successfully.", {
                appearance: 'success',
                autoDismiss: true,
            });
            setQuery({ ...query, hard_refresh: 'true' });
            refreshGrid();
        })
            .catch((error: any) => {
                handleOnClosed();
                handleError(error);
            })
    }

    const handleError = (error: any) => {
        if (error?.response?.data) {
            addToast(error.response.data, {
                appearance: 'error',
                autoDismiss: true,
            })
        } else if (error?.response?.status === 500) {
            addToast("Sorry, something went wrong there, try again.", {
                appearance: 'error',
                autoDismiss: true,
            })
        } else if (error?.response?.status === 419) {
            addToast("We encounted validation problem, please correct and try again.", {
                appearance: 'error',
                autoDismiss: true,
            })
        } else if (error?.response?.status === 404) {
            addToast("We are not able to find associated email, please check and try again.", {
                appearance: 'error',
                autoDismiss: true,
            })
        } else if (error?.response?.status == 409) {
            addToast(error.response.data, {
                appearance: 'error',
                autoDismiss: true,
            })
        }
    }

    const deletePopUpButtons = {
        primary: {
            label: 'Yes',
            action: proceedDelete
        },
        secondary: {
            label: 'No',
            action: handleOnClosed
        }
    }

    const fetchDirQueryParams = (queryParams?, paramGrid?) => {
        if (paramGrid)
            Api.get('/globaldirectory', { params: { ...queryParams } })
                .then((resp: any) => {
                    const d = resp.data as ITableResult<DirParamCollection>;
                    /* const d = {
                        result: [{ ...resp.data, name: 'Test001', type: 'TestType' }, { ...resp.data, name: 'Test002', type: 'TestType' },
                        { ...resp.data, name: 'Test003', type: 'TestType' }]
                    } */
                    setData(d)
                    if (paramGrid) {
                        paramGrid.success({
                            rowData: resp.data.result,
                            rowCount: resp.data.total
                        });
                        if (childRef?.current?.api) {
                            childRef.current.api.hideOverlay();
                            if (resp.data.total === 0) {
                                childRef.current.api.showNoRowsOverlay();
                            }
                        }
                    }
                    setQuery({ ...query, ...queryParams })
                })
                .catch((error: any) => {
                    setData({ result: [] })
                    childRef?.current?.onSetTableError('Error while fetching data');
                })
    }

    useEffect(() => {
        /* fetchDirQueryParams(); */
    }, [query]);

    const onSubmit = (data: any) => {
        query.q = data.query;
        query.page = 1
        setQuery(query)
    }

    const onAddKnownPublicPage = () => {
        setPanelData({ panelTitle: 'Add Dir Query Params', showPanel: true })
    }

    const getActions = () => {
        const action = {
            action: {
                label: 'Add',
                onClickHandler: onAddKnownPublicPage
            }
        };

        return authDetails?.permissions.Admin.dirQueryParam != "readonly" ?
            action : null;
    }

    const SearchTable = () => {
        return <AMAdminSearchTable
            searchTitle="Directory Query Parameters"
            searchPlaceHolder="Host, Bind DN, Base Domain"
            query={query.q} ref={searchRef}
            onSearchValue={onSubmit}
            {...getActions()}
        />;
    }

    const onFormActionSuccess = () => {
        setPanelData({ panelTitle: '', showPanel: false, panelData: undefined });
        refreshGrid();
    }

    const KnownPanel = () => {
        return <ModalPopup title={panelData.panelTitle}
            onCloseHandler={handleOnClosed}
            isOpen={panelData.showPanel}>
            <DirQueryForm dirQueryData={panelData?.panelData} onFormActionSuccess={onFormActionSuccess} />
        </ModalPopup>
    }

    const DeleteKnownIpPopUp = ({ dirQueryData, popUpButtons }: DirQueryIpDeletePopUpProps) => {
        return <AMAdminPopUp popUpTitle={'Delete Dir Query Params'} popUpButtons={popUpButtons} handleClose={handleOnClosed}>
            <span className="font14">Are you sure you want to delete the dir query params for <strong>{dirQueryData.name}</strong> ? </span>
        </AMAdminPopUp>
    }

    return <>
        <SearchTable />
        <BaseAMAdminGrid
            query={query}
            setQuery={setQuery}
            columnDefs={CollectorTableColumns}
            fetchEntitiesFn={fetchDirQueryParams}
            /* actions={DirQueryParamsActions} */
            gridRef={childRef}
            /* TODO//known_public_ip replace with this */
            {...(DirQueryParamsActions?.length > 0 && authDetails.permissions.Admin.dirQueryParam != "readonly"
                ? { actions: DirQueryParamsActions } : null)}
        />
        <KnownPanel />
        {showPopUp ? <DeleteKnownIpPopUp dirQueryData={showPopUp} popUpButtons={deletePopUpButtons} /> : null}
    </>
}

export default withQueryParams({
    q: StringParam,
    page: StringParam,
    rpp: NumberParam,
    sort_by: StringParam,
    order_by: StringParam,
    filters: withDefault(ArrayParam, []),
    hard_refresh: StringParam,
    sensor_id: StringParam
}, DirQueryParamListing);