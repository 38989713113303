import { CustomCellRendererProps } from "@ag-grid-community/react"
import { FunctionComponent } from "react"
import { PostureCategory } from "../../ref/PostureCategories/PostureCategory";
import React from "react";

interface IdentityTypeCellRendererProps extends CustomCellRendererProps {
}


const getItemIconClass = (item) => {

    switch (item?.type) {
        case "Device":
            return "device_icon";
        case "App":
            return "device_icon";
        case "Service":
            return "gear_icon_issue";
        case "Service/Application":
            return "service_application_account_nhi_icon";
        case "Service/Computer Account":
            return "service_computer_account_nhi_icon";
        case "Service/Key and Secret":
            return "service_key_secret__nhi_icon";
        case "Service/Service Account":
            return "service_service_account_nhi_icon";
        case "Service/Service Principal":
            return "service_service_principal_nhi_icon";
        case "Service/Token":
            return "service_service_token_nhi_icon";
        case "User/Agent":
            return "user_agent_icon";
        case "User/Background Browsing":
            return "user_browsing_icon";
        case "User":
            return "user_interactive_icon";
        default:
            // If identity_category_list exists, return 'settings_icon_cloud', otherwise 'settings_icon'
            return item?.identity_category_list ? 'settings_icon_cloud' : 'settings_icon';
    }
}

const getItemIconTitle = (item) => {
    switch (item?.type) {
        case "Device":
            return !item.is_known_identity ? "Unresolved  NHI-Device" : "NHI-Device";
        case "App":
            return !item.is_known_identity ? "Unresolved  NHI-App" : "NHI-App";
        case "Service":
            return !item.is_known_identity ? "Unresolved  NHI-Service" : "NHI-Service";
        case "Service/Application":
            return !item.is_known_identity ? "Unresolved  NHI-Application" : "NHI-Application";
        case "Service/Computer Account":
            return !item.is_known_identity ? "Unresolved NHI-Service/Computer Account" : "NHI-Service/Computer Account";
        case "Service/Service Account":
            return !item.is_known_identity ? "Unresolved NHI-Service" : "NHI-Service";
        case "Service/Service Principal":
            return !item.is_known_identity ? "Unresolved NHI-Service/Service Principal" : "NHI-Service/Service Principal";
        case "Service/Key and Secret":
            return !item.is_known_identity ? "Unresolved NHI-Service/Key and Secret" : "NHI-Service/Key and Secret";
        case "Service/Token":
            return !item.is_known_identity ? "Unresolved NHI-Service/Token" : "NHI-Service/Token";
        case "User/Agent":
            return !item.is_known_identity ? "Unresolved User/Agent" : "User/Agent";
        case "User/Background Browsing":
            return !item.is_known_identity ? "Unresolved User/Background Browsing" : "User/Background Browsing";
        case "User":
            return !item.is_known_identity ? "Unresolved  User" : "User";
        default:
            // If identity_category_list exists, return 'settings_icon_cloud', otherwise 'settings_icon'
            return item?.identity_category_list ? 'settings_icon_cloud' : 'settings_icon';
    }

}

export const IdentityTypeCell: FunctionComponent<IdentityTypeCellRendererProps> = ({ data, node
}: IdentityTypeCellRendererProps) => {
    const item = data;
    /*  if (node.group) {
         console.log("Type has node group", node, data)
         return <span>{ }</span>; // Display group key for grouped rows
     } */
    return <PostureCategory
        type={item.type}
        categories={item.identity_category_list || []}
        title={getItemIconTitle(item)}
    >


        <div className='asset_type_icons'>
            <div className={getItemIconClass(item)} >
                {(item.is_known_identity === false && (item?.type === "User" || item?.type === "User/Agent" || item?.type === "User/Background Browsing")) ? <span title={"Unresolved User"}>?</span> : null}
                {(item.is_known_identity === false && (item?.type === "Service" || item?.type === "Service/Application" || item?.type === 'Service/Computer Account' || item?.type === 'Service/Key and Secret' || item?.type === 'Service/Token' || item?.type === 'Service/Service Principal' || item?.type === 'Service/Service Account')) ? <span>?</span> : null}
            </div>
        </div>
    </PostureCategory>
}