import React, { useState } from 'react';
import { ImportWarning } from './ImportWarning';
import { TIME_BASED_SEARCH_FIELD } from '../../TimeBasedSearch/Constants';
import { getFilterObjFromTag } from './posture-filter-utils';
import { supportedOperators } from '../../../pages/issue_prev/issues/constant/issueTemplate';
import { ExportWarning } from '../../../common/ExportWarning/ExportWarning';

export const ImportExportSearch = ({
	setErrorMsg,
	setIsFormValid,
	setValue,
	isFormValid,
	onSubmit,
	getValues,
	setTimeSearchData,
	setImportTimeTag,
	pageType,
	filterTemplate,
	timeSearchData,
	setIsClearFilter,
	formDirty,
	removeFilterHandler
}) => {
	const [showWarning, setShowWarning] = useState(false);
	const fileInputRef = React.createRef();
	const [showExportWarning, setShowExportWarning] = useState(false);
	const triggerFileInput = () => {
		setShowWarning(true);
	};

	const extractSearchTag = (data) => {
		const filter = TIME_BASED_SEARCH_FIELD;
		let filterValue = data[filter]?.value;
		let opr = supportedOperators.get(String(data[filter]?.type)) || '';
		return `${filter}${opr}${filterValue}`;
	};

	const handleYesImport = () => {
		fileInputRef.current.click();
	};

	function validateRules(rules) {
		let index = 0;
		const searchKeySet = new Set();
		for (const rule of rules) {
			if (rule?.[TIME_BASED_SEARCH_FIELD]) {
				// Special handling for time based range searches
				setTimeSearchData(rule);
				const tag = extractSearchTag(rule);
				setImportTimeTag([
					getFilterObjFromTag(tag, [TIME_BASED_SEARCH_FIELD])
				]);
				rules.splice(index, 1);
				index++;
				continue;
			}
			if (searchKeySet.has(rule.search_key)) {
				return `Duplicate search key found: '${rule.search_key}'.`;
			}

			const conf = filterTemplate.filter(
				(i) => i.search_key === rule.search_key
			);

			if (conf.length === 0) {
				return `Invalid search key '${rule.search_key}' provided for ${pageType} page.`;
			}

			if (
				conf[0].pageType &&
				conf[0].pageType.filter(
					(i) =>
						i.toUpperCase() === pageType.toUpperCase() ||
						i === 'All'
				).length === 0
			) {
				return `Invalid search key '${rule.search_key}' provided for ${pageType} page.`;
			}

			searchKeySet.add(rule.search_key);
			if (Array.isArray(rule.value)) {
				const uniqueValues = new Set(rule.value);
				if (uniqueValues.size !== rule.value.length) {
					return `Duplicate values found in the 'value' array for search key '${rule.search_key}'.`;
				}
				const firstType = typeof rule.value[0];
				const isHomogeneous = rule.value.every(
					(val) => typeof val === firstType
				);
				if (!isHomogeneous) {
					return `Inconsistent types in the 'value' array for search key '${rule.search_key}'.`;
				}
			} else {
				const validTypes = ['string', 'boolean', 'number'];
				if (!validTypes.includes(typeof rule.value)) {
					return `Invalid 'value' field for search key '${rule.search_key}'. It must be a string, boolean, number, or an array of the same.`;
				}
			}
			index++;
		}
		return null;
	}

	const handleFileChange = (event) => {
		setShowWarning(false);
		const file = event.target.files[0];
		event.target.value = null;
		if (file && file.type === 'application/json') {
			const reader = new FileReader();

			reader.onload = (e) => {
				try {
					const loadedData = JSON.parse(e.target.result);
					const err = validateRules(loadedData.data);
					if (err !== null) {
						setErrorMsg(err);
						setIsFormValid(false);
					} else {
						for (
							let i = 0;
							i < Object.keys(getValues()).length;
							i++
						) {
							removeFilterHandler(i);
						}
						setValue('filtersArray', loadedData.data);
						setIsFormValid(true);

						setIsClearFilter(true);
						formDirty.current = true;
					}
				} catch (error) {
					setErrorMsg('Error parsing JSON file.');
				} finally {
					setTimeout(() => setErrorMsg(''), 10000);
				}
			};
			reader.readAsText(file);
		} else {
			setErrorMsg('Please select a valid JSON file.');
			setTimeout(() => setErrorMsg(''), 10000);
		}
	};

	return (
		<>
			{' '}
			<div className='import-export-search-container'>
				<input
					type='file'
					accept='application/json'
					onChange={handleFileChange}
					ref={fileInputRef} // Attach the ref to the input
					style={{ display: 'none' }} // Hide the input element
				/>
				<div
					className={
						'import-export-btn-filter ' +
						((isFormValid && Object.keys(getValues()).length > 0) ||
						timeSearchData
							? ''
							: ' disableItems')
					}
					title='Export Search'
					onClick={(e) => {
						setShowExportWarning(true);
					}}
				>
					<div className={'export_search_icon '}></div>
					<div>Export</div>
				</div>

				<div
					className='import-export-btn-filter '
					title='Import Search'
					onClick={triggerFileInput}
				>
					<div className='import_search_icon' />
					<div>Import</div>
				</div>
			</div>
			{showWarning && (
				<ImportWarning
					handleYes={handleYesImport}
					handleNo={() => setShowWarning(false)}
				/>
			)}
			{showExportWarning && (
				<ExportWarning
					handleNo={() => setShowExportWarning(false)}
					title={'Export Search Filters'}
					handleYes={() => {
						onSubmit(getValues(), true);
						setShowExportWarning(false);
					}}
				/>
			)}
		</>
	);
};
