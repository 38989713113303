import React, { useState, useEffect, useRef } from 'react';
import {
    useTimeBasedSearchDispatchContext,
    useTimeBasedSearchPropsContext,
    useTimeBasedSearchStateContext
} from '../useTimeBasedSearch';
import TimeBasedSearchChartComponent from './TimeBasedSearchChartComponent';
import { Api } from '../../Axios';
import moment from 'moment';
import { useTimeBasedSearchActions } from '../TimeBasedSearchActions';
import axios from 'axios';

const HistogramComponent = ({ isApplyHovered}) => {
    const props = useTimeBasedSearchPropsContext();
    const store = useTimeBasedSearchStateContext();
    const dispatchAction = useTimeBasedSearchDispatchContext();
    const [histogramData, setHistogramData] = useState([]);
    const { appliedFilterTimestamp } = useTimeBasedSearchActions();
    const [startDate, setStartDate] = useState(moment().subtract(60,'days').valueOf());
    const [zoomLevel, setZoomLevel] = useState('hourly');
    const updatedExtremesRef = useRef({ min: null, max: null });

    useEffect(() => {
        fetchHistogramData();
    }, []);


    function getSearchFields() {
        updatedExtremesRef.current = {min:null , max:null}
        let timeFilter = appliedFilterTimestamp();
        if (timeFilter.length > 0) {


            
    
            let startDate = moment(timeFilter[0]).unix();
            let endDate = moment(timeFilter[1]);

       
            
            if (endDate.isAfter(moment())) {
                endDate = moment().unix();
            } else {
                endDate = endDate instanceof moment ? endDate.unix() : moment(endDate).unix();
            }
        
            // Calculate the time difference in hours
            let diffInHours = (endDate - startDate) / 3600;
        
            // Determine the interval based on the difference
            let interval;
            if (diffInHours < 12) {
                interval = '1 MINUTE';
                setZoomLevel('1-minute');
            } else if (diffInHours < 48) {
                interval = '10 MINUTE';
                setZoomLevel('10-minute');
            } else {
                interval = '1 HOUR'; // Replace 'default_interval' with your desired default interval.
                setZoomLevel('hourly');
            }
        
            let searchFields = {
                "any_activity_time": {
                    "value": [startDate, endDate],
                    "type": "between"
                },
                "interval": interval
            };
    
            // Convert to JSON and encode
            return encodeURIComponent(JSON.stringify(searchFields));
        
        }
        return null; // Return null or an empty string if timeFilter is empty
    }

    
    function getSearchFieldsForDate(min,max) {
       
    
        let startDate = moment(min).unix();
        let endDate = moment(max);
        
        if (endDate.isAfter(moment())) {
            endDate = moment().unix();
        } else {
            endDate = endDate instanceof moment ? endDate.unix() : moment(endDate).unix();
        }
    
        // Calculate the time difference in hours
        let diffInHours = (endDate - startDate) / 3600;
    
        // Determine the interval based on the difference
        let interval;
        if (diffInHours < 12) {
            interval = '1 MINUTE';
            setZoomLevel('1-minute');
        } else if (diffInHours < 48) {
            interval = '10 MINUTE';
            setZoomLevel('10-minute');
        } else {
            interval = '1 HOUR'; // Replace 'default_interval' with your desired default interval.
            setZoomLevel('hourly');
        }
    
        let searchFields = {
            "any_activity_time": {
                "value": [startDate, endDate],
                "type": "between"
            },
            "interval": interval
        };

        // Convert to JSON and encode
        return encodeURIComponent(JSON.stringify(searchFields));
}

    const fetchHistogramData = async () => {
        const currentTime = +new Date();
        const cachedFlowData: any = sessionStorage.getItem(
            `histogram-chart-data-${props.pageType}`
        );

          
        const searchHeaders = {
            headers: {
                search_fields: getSearchFields()
            }
        };

       
            Api.get(props.apiUrl ,searchHeaders)
                .then((res: any) => {
                    if (!res?.data?.length || res?.data?.length <= 0) {
                        setHistogramData({
                            flows: [],
                            expiry_time: currentTime + 600000
                        });

                        return;
                    }

                    let result = JSON.parse(JSON.stringify(res?.data)) || [];
                    result.sort((a: any, b: any) =>
                        a?.time < b?.time ? -1 : 1
                    );
                    const cachedFlows = {
                        flows: result,
                        expiry_time: currentTime + 600000
                    };

                    setHistogramData(prepareHistogramData(cachedFlows));

                    sessionStorage.setItem(
                        `histogram-chart-data-${props.pageType}`,
                        JSON.stringify(cachedFlows)
                    );
                })
                .catch((er) => {
                    console.log(er);
                });
       
    };

    const cancelTokenSourceRef = useRef<any>(null);

    const handleRangeChange = async (min: any, max: any) => {
        const currentTime = +new Date();
        const searchHeaders = {
            headers: {
                search_fields: getSearchFieldsForDate(min, max),
            },
        };

        updatedExtremesRef.current = {min,max};

        // Cancel the previous request if one exists
        if (cancelTokenSourceRef.current) {
            cancelTokenSourceRef.current.cancel('Operation canceled due to new request.');
        }

        // Create a new cancel token for the new request
        cancelTokenSourceRef.current = axios.CancelToken.source();

        try {
            const res = await Api.get(props.apiUrl, {
                ...searchHeaders,
                cancelToken: cancelTokenSourceRef.current.token, // Attach the cancel token
            });

            if (!res?.data?.length || res?.data?.length <= 0) {
                setHistogramData({
                    flows: [],
                    expiry_time: currentTime + 600000,
                });
                return;
            }

            let result = JSON.parse(JSON.stringify(res?.data)) || [];
            result.sort((a: any, b: any) => (a?.time < b?.time ? -1 : 1));
            const cachedFlows = {
                flows: result,
                expiry_time: currentTime + 600000,
            };

            setHistogramData(prepareHistogramData(cachedFlows));
        } catch (error) {
            if (axios.isCancel(error)) {
                console.log('Previous request canceled.');
            } else {
                console.log(error);
            }
        }
    };

    const prepareHistogramData = (data) => {
        let chartData = [];
        if (data?.flows && data?.flows?.length > 0) {

         
    // Use timeFilter values if they are present; otherwise, fall back to original logic
      let timeFilter = appliedFilterTimestamp();
      let startFromDate = updatedExtremesRef?.current?.min 
        ? moment(updatedExtremesRef?.current?.min).valueOf()
        : timeFilter?.[0] 
          ? moment(timeFilter[0]).valueOf()
          : Math.max(
            moment(data?.flows[0]?.time).valueOf(),
            moment().subtract(60, 'days').valueOf()
          );

          if(zoomLevel === 'hourly' && updatedExtremesRef.current.min === null){
            setStartDate(data?.flows[0]?.time)
          }

      let endDate = updatedExtremesRef?.current?.max 
        ? moment(updatedExtremesRef?.current?.max).valueOf()
        : timeFilter?.[1] 
          ? moment(timeFilter[1]).valueOf()
          : moment().valueOf();

      const totalDurationHours = moment(endDate).diff(
        moment(startFromDate),
        'hours'
      );

      // Determine the interval based on the total duration
      let interval, intervalUnit;
      if (totalDurationHours < 12) {
        interval = 1;
        intervalUnit = 'minute';
      } else if (totalDurationHours < 48) {
        interval = 10;
        intervalUnit = 'minute';
      } else {
        interval = 1;
        intervalUnit = 'hour';
      }

      let startDate;

      const firstFlowTime = moment(data.flows[0].time);

      // Adjust startDate to keep date and hour from startFromDate but copy minutes from firstFlowTime
      if (intervalUnit !== 'minute') {
        startDate = moment(startFromDate)
          .set({
            minute: firstFlowTime.minute(),
            second: 0,
            millisecond: 0
          })
          .startOf(intervalUnit);
      } else {

        startDate = moment(startFromDate);
        const startMinute = startDate.minute();

        // Calculate the buffer based on the interval
        const buffer = interval - (startMinute % interval);
        startDate.add(buffer, 'minutes').startOf('minute');
      }

      while (startDate.isSameOrBefore(moment(endDate))) {
                const foundFlow = data.flows.find((p) =>
                  moment(startDate).isSame(moment(p.time), intervalUnit)
                );
        
                // Found data on API
                if (foundFlow) {
                    if (props.pageType === 'incidents') { 
                        chartData.push([foundFlow.time, foundFlow.incident_count || 0]);
                    } else {
                        chartData.push([foundFlow.time, foundFlow.flow_count || 0]);
                    }
                 
        
                  // Adjust the interval to match the exact flow time if it doesn't align perfectly
                  startDate = moment(foundFlow.time).add(interval, intervalUnit);
                }
                // 0 flows filler data
                else {
                  chartData.push([startDate.valueOf(), 0]);
                  startDate.add(interval, intervalUnit);
                }
              }

            // stuff 0 for showing line on 1 data point response.
            if (chartData.length === 1) {
                chartData.splice(0, 0, [chartData[0][0] - 3600000, 0]);
            }
        }
        return chartData;
    };

    return (
        <>
            { (histogramData.length === 0 || histogramData?.flows?.length === 0)  && <div style={{color:"#000", fontSize:'14px',marginTop:'50px', marginLeft:'10px'

            }}> No data  available ! Reset Time based filter.</div> }
            {histogramData.length > 0 && (
                <TimeBasedSearchChartComponent
                    isApplyHovered={isApplyHovered}
                    flowsData={histogramData}
                    onRangeChange = {handleRangeChange} 
                    startDate={startDate}
                    zoomLevel={zoomLevel}
                    updatedExtremes={updatedExtremesRef}   
                ></TimeBasedSearchChartComponent>
            )}
        </>
    );
};

export default HistogramComponent;