import React, { useState, useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form'
import {
    withQueryParams,
    StringParam,
    NumberParam,
    ArrayParam,
    withDefault,
} from 'use-query-params'
import ReactPaginate from 'react-paginate'
import moment from 'moment'
import { getRisk } from './../../utils/risk-level';
import { Api } from '../../components/Axios'
import { ReportByRuleType, ReportByStatus, ReportByAssignee } from './sub'
import './index.css'
import { Link } from 'react-router-dom'
import { TicketWidgetResponse } from '../../types/response-types'
import { getIssueNameById } from '../playbooks/helpers/playbook-helper'
import { ColDef } from '@ag-grid-community/core'
import BaseAMAdminGrid from '../../components/core/AMTable/BaseAMAdminGrid'
import { getResolutionMatchFor1920 } from '../../utils/util-methods'

type Search = {
    query?: string;
}

type Listing = {
    _id: string;
    rule: string;
    policy_applied: string;
    src_name: string;
    dest_name: string;
    created_date: string;
    modified_date: string
    severity: number;
    assignee_name: string;
    status: string;
    ticket_status: string;
    matching_rule_name: string;
    playbook_name: string;
    risk: string;
}

type Result = {
    total: number;
    result: Listing[];
    rpp: number;
    sort_by: string;
    order_by: string;
}

interface Params {
    rpp?: number;
    sort_by?: string;
    order_by?: string;
    q?: string;
    page?: number;
}

const Index = ({ query, setQuery }: any) => {
    const { q: q, page: page_number, rpp: record_per_page, order_by: order, sort_by: sort } = query
    const { register, handleSubmit } = useForm<Search>()
    const [result, setResult] = useState<Result>()

    let p: Params = {
        q: q,
        page: page_number,
        rpp: record_per_page,
        order_by: order,
        sort_by: sort
    }

    const childRef = useRef(null);

    const [ticketIdSortDir, setTicketIdSortDir] = useState<string | undefined>("tablesort_down");
    const [lastUpdateSortDir, setLastUpdateSortDir] = useState<string | undefined>("tablesort_down");
    const [createdDateSortDir, setCreatedDateSortDir] = useState<string | undefined>("tablesort_down");
    const [statusSortDir, setStatusSortDir] = useState<string | undefined>("tablesort_down");
    const [matchingRuleSortDir, setMatchingRuleSortDir] = useState<string | undefined>("tablesort_down");
    const [playbookNameSortDir, setPlaybookNameSortDir] = useState<string | undefined>("tablesort_down");
    const [sourceNameSortDir, setSourceNameSortDir] = useState<string | undefined>("tablesort_down");
    const [destinationNameSortDir, setDestinationNameSortDir] = useState<string | undefined>("tablesort_down");
    const [riskSortDir, setRiskSortDir] = useState<string | undefined>("tablesort_down");
    const [assigneeSortDir, setAssigneeSortDir] = useState<string | undefined>("tablesort_down");
    const [summaryFlag, setSummaryFlag] = useState(false);
    const [widgetResponse, setWidgetResponse] = useState<TicketWidgetResponse | undefined>();

    moment.updateLocale('en', { relativeTime: { d: '1 day' } })

    const [TicketsTableCols, setTicketTableCols] = useState<Array<ColDef>>([
        {
            headerName: 'Ticket #',
            field: '_id',
            maxWidth: getResolutionMatchFor1920(100, 100),
            cellRenderer: ({ data }) => {
                const i = data;
                return <Link className="shadowbox" to={`/tickets/${i._id}`}>{i._id}</Link>
            },
            initialSort: 'desc'
        },
        {
            headerName: 'Last Update',
            field: 'modified_date',
            wrapText: true,
            maxWidth: getResolutionMatchFor1920(130, 130),
            autoHeight: true,
            cellRenderer: ({ data }) => {
                return <>{FormatDate(data.modified_date)}</>
            }

        },
        {
            headerName: 'Days Open', field: 'created_date', maxWidth: 100,
            cellRenderer: ({ data }) => {
                return <>{moment(data.created_date).startOf('days').fromNow(true)}</>
            }
        },
        { headerName: 'Status', field: 'ticket_status', maxWidth: 100 },
        {
            headerName: 'Matching Issue',
            wrapText: true,
            autoHeight: true,
            field: 'matching_rule_name', cellRenderer: ({ data }) => {
                return <>{getIssueNameById(data.matching_rule_name.replace('Suspected Attack on Expired Account', 'Suspected Attack on Expired Account/Password'))}</>
            }
        },
        {
            headerName: 'Playbook Name',
            field: 'playbook_name',
            autoHeight: true,
            wrapText: true,
            maxWidth: getResolutionMatchFor1920(260, 260),
            cellRenderer: ({ data }) => {
                return <div className="copy_field ticket-cell-copy" style={{ display: 'flex', alignItems: 'center' }}>
                    <span className="ticket_ellipsis" title={data.playbook_name.toString()}>{data.playbook_name}</span>
                    <div className="copy_icon" title="Copy this text to Clipboard" onClick={() => navigator.clipboard.writeText(data.playbook_name)}></div>
                </div>
            }
        },
        {
            headerName: 'Identity',
            autoHeight: true,
            wrapText: true,
            field: 'source_name', maxWidth: getResolutionMatchFor1920(260, 260),
            cellRenderer: ({ data }) => {
                return <div className="copy_field ticket-cell-copy" style={{ display: 'flex', alignItems: 'center' }}>
                    <span className="ticket_ellipsis" title={data.src_name.toString()}>{data.src_name}</span>
                    <div className="copy_icon" title="Copy this text to Clipboard" onClick={() => navigator.clipboard.writeText(data.src_name)}></div>
                </div>
            }
        },
        {
            headerName: 'Asset',
            autoHeight: true,
            wrapText: true,
            field: "destination_name",
            maxWidth: getResolutionMatchFor1920(260, 260),
            cellRenderer: ({ data }) => {
                return <div className="copy_field ticket-cell-copy" style={{ display: 'flex', alignItems: 'center' }}>
                    <span className="ticket_ellipsis" title={data.dest_name.toString()}>{data.dest_name}</span>
                    <div className="copy_icon" title="Copy this text to Clipboard" onClick={() => navigator.clipboard.writeText(data.dest_name)}></div>
                </div>
            }
        },
        {
            headerName: 'Risk',
            field: 'risk',
            maxWidth: getResolutionMatchFor1920(80, 80),
            headerClass: 'risk-header',
            cellRenderer: ({ data }) => {
                return <span className={getRisk(data.risk)?.toLowerCase()}>&nbsp;</span>
            }
        },
        {
            headerName: 'Assignee',
            maxWidth: getResolutionMatchFor1920(180, 180),
            field: 'assignee_name',
            cellRenderer: ({ data }) => {
                return <>{data.assignee_name ? data.assignee_name : "N/A"}</>
            }
        },

    ])

    const highlightSortDir = () => {
        if (query.sort_by === "_id" && query.order_by === "asc") {
            setTicketIdSortDir("tablesort_up_selected")
        } else if (query.sort_by === "_id" && query.order_by === "desc") {
            setTicketIdSortDir("tablesort_down_selected")
        } else if (query.sort_by === "modified_date" && query.order_by === "asc") {
            setLastUpdateSortDir("tablesort_up_selected")
        } else if (query.sort_by === "modified_date" && query.order_by === "desc") {
            setLastUpdateSortDir("tablesort_down_selected")
        } else if (query.sort_by === "created_date" && query.order_by === "asc") {
            setCreatedDateSortDir("tablesort_up_selected")
        } else if (query.sort_by === "created_date" && query.order_by === "desc") {
            setCreatedDateSortDir("tablesort_down_selected")
        } else if (query.sort_by === "ticket_status" && query.order_by === "asc") {
            setStatusSortDir("tablesort_up_selected")
        } else if (query.sort_by === "ticket_status" && query.order_by === "desc") {
            setStatusSortDir("tablesort_down_selected")
        } else if (query.sort_by === "matching_rule_name" && query.order_by === "asc") {
            setMatchingRuleSortDir("tablesort_up_selected")
        } else if (query.sort_by === "matching_rule_name" && query.order_by === "desc") {
            setMatchingRuleSortDir("tablesort_down_selected")
        } else if (query.sort_by === "playbook_name" && query.order_by === "asc") {
            setPlaybookNameSortDir("tablesort_up_selected")
        } else if (query.sort_by === "playbook_name" && query.order_by === "desc") {
            setPlaybookNameSortDir("tablesort_down_selected")
        } else if (query.sort_by === "src_name" && query.order_by === "asc") {
            setSourceNameSortDir("tablesort_up_selected")
        } else if (query.sort_by === "src_name" && query.order_by === "desc") {
            setSourceNameSortDir("tablesort_down_selected")
        } else if (query.sort_by === "dest_name" && query.order_by === "asc") {
            setDestinationNameSortDir("tablesort_up_selected")
        } else if (query.sort_by === "dest_name" && query.order_by === "desc") {
            setDestinationNameSortDir("tablesort_down_selected")
        } else if (query.sort_by === "risk" && query.order_by === "asc") {
            setRiskSortDir("tablesort_up_selected")
        } else if (query.sort_by === "risk" && query.order_by === "desc") {
            setRiskSortDir("tablesort_down_selected")
        } else if (query.sort_by === "assignee_name" && query.order_by === "asc") {
            setAssigneeSortDir("tablesort_up_selected")
        } else if (query.sort_by === "assignee_name" && query.order_by === "desc") {
            setAssigneeSortDir("tablesort_down_selected")
        }
    }

    useEffect(() => {
        highlightSortDir();
    }, [query])

    const onSubmit = (data: any) => {
        p.q = data.query;
        p.page = 1
        setQuery(p)
    }

    const Paginate = (selectedPage: any) => {
        p.page = selectedPage.selected === 0 ? 1 : selectedPage.selected + 1
        setQuery(p)
    }

    const Listing = (queryParams?, paramGrid?) => {
        if (paramGrid)
            Api.get('/tickets', {
                params: queryParams
            })
                .then((res: { data: any }) => {
                    setResult(res.data)
                    if (paramGrid) {
                        paramGrid.success({
                            rowData: res.data.result,
                            rowCount: res.data.total
                        });
                        if (childRef?.current?.api) {
                            childRef.current.api.hideOverlay();
                            if (res.data.total === 0) {
                                childRef.current.api.showNoRowsOverlay();
                            }
                        }
                    }

                    setQuery({ ...query, ...queryParams })
                })
                .catch((error: any) => {
                })
    }

    const FormatDate = (dte: any) => {
        moment.locale('en')
        return (
            <>
                {moment(dte).format('MMM DD YYYY, h:mm A')}
            </>
        )
    }

    const Severity = (severity: number) => {
        if (severity === 4) {
            return "severity_pink"
        } else if (severity === 3) {
            return "severity_orange"
        } else if (severity === 2) {
            return "severity_yellow"
        }
    }

    useEffect(() => {
        Listing();
    }, [query]);

    useEffect(() => {
        if (!summaryFlag) {
            fetchTicketSummaryData();
        }
    }, [summaryFlag])

    const LoadSorting = (field: string) => {
        if (p.sort_by === field) {
            p.order_by = p.order_by === 'asc' ? 'desc' : 'asc'
            setQuery(p)
        } else {
            p.sort_by = field
            p.order_by = 'desc'
            setQuery(p)
        }
    };

    const SortBy = (name: string) => {
        if (p.sort_by === name) {
            return p.order_by === 'desc' ? 'tablesort_down' : 'tablesort_up'
        }
    }

    const handleSummary = () => {
        setSummaryFlag(flag => !flag);
    }

    const fetchTicketSummaryData = () => {
        Api.get('/ticket/widget')
            .then((res) => {
                if (res.status === 200) {
                    setWidgetResponse(res.data)
                }
            })
            .catch(err => console.log);
    }

    return (
        <>
            <div className="page_title_area">
                <div className="page_title fl">Summary of Tickets</div>
                <div className="launch_button margintop20">
                    {summaryFlag ? <h4 style={{ cursor: 'pointer' }} onClick={handleSummary}>SHOW SUMMARY &#9660;</h4>
                        : <h4 style={{ cursor: 'pointer' }} onClick={handleSummary}>HIDE SUMMARY &#9650;</h4>}
                </div>
            </div>
            {!summaryFlag ?
                <div className="summary_box">
                    <ReportByRuleType ticketRuleType={widgetResponse?.by_rule_type} />

                    <ReportByStatus ticketStatus={widgetResponse?.past_10_days} />

                    <ReportByAssignee assigneeTicket={widgetResponse?.by_assignee} />
                </div>
                : null
            }
            <div className="hr_ruler margintop20"></div>

            <div className="page_title_area">
                <form onSubmit={handleSubmit(onSubmit)} style={{ marginTop: '1%' }}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <div className='font18'>TICKETS</div>
                        <div className="search_container issue_page scrollbar-container marginleft20">
                            <div className="search_white_icon flex_none" style={{ cursor: 'pointer' }}
                                onClick={handleSubmit(onSubmit)}
                            >&nbsp;</div>&nbsp;
                            <input
                                className="search_input flex_none admin-page"
                                defaultValue={p.q}
                                placeholder="Search"
                                ref={register}
                                name='query'
                            />
                        </div>
                    </div>
                </form>
            </div>
            <div className="clrBoth margintop20"></div>
            <div className='tickets-page-container ticket_tb'> <BaseAMAdminGrid
                query={query} setQuery={setQuery}
                fetchEntitiesFn={Listing}
                columnDefs={TicketsTableCols}
                gridRef={childRef}
            /></div>
        </>
    )
}

export default withQueryParams({
    q: StringParam,
    page: StringParam,
    rpp: NumberParam,
    sort_by: StringParam,
    order_by: StringParam,
    filters: withDefault(ArrayParam, [])
}, Index)