import { CustomCellRendererProps } from "@ag-grid-community/react"
import React from "react"
import { FunctionComponent } from "react"
import { Chip, Tooltip } from '@mui/material';
import { DirectoriesObj, RulesDetailObj } from "../../../../types/response-types";
import { getTimeBasedSearchQueryParam } from "../../../../components/core/PostureFilter/posture-filter-utils";
import { PostureSearchTemplate } from "../../constants/PostureSearchTemplate";
import { usePostureStateContext } from "../../../../store/PostureStateContextProvider";
import InfoIcon from '@mui/icons-material/Info';
import { useHistory } from "react-router-dom";
import { BasePostureActionMenu } from "../../ref/PostureActionMenus/BasePostureActionMenu";
import { isIssueType } from "../../../../utils/util-methods";
import { DirectoriesActionMenu } from "../../constants/IDPostureMenu";
import { PostureMenuItem, PostureMenuItemAction } from "../../ref/PostureActionMenus/PostureActionMenu";

interface IdentitySystemNameCellRendererProps extends CustomCellRendererProps {
    fetchIdentitySystems,
    items,
    tags,
    markKnownCallback
}

const getRules = (item: DirectoriesObj, items) => {
    let rule = '';
    Object.values(item.rules).map((i: RulesDetailObj) => {
        const isIssue = isIssueType(items, i.name);
        if (isIssue) {
            if (i.issue_count > 0 && i.entity_type === 'Directory') {
                rule += i.name + ',';
            }
        }
    });
    return rule.substring(0, rule.length - 1);
};

const getIssueCount = (item: DirectoriesObj) => {
    return item.rules
        ? Object.values(item.rules).reduce(
            (prev: RulesDetailObj, i: RulesDetailObj) => ({
                ...prev,
                issue_count: prev.issue_count + i.issue_count
            }),
            {
                issue_count: 0,
                issue_flow_count: 0,
                issue_risk: 4,
                name: ''
            }
        ).issue_count
        : 0;
};


export const IdentitySystemNameCell: FunctionComponent<IdentitySystemNameCellRendererProps> =
    ({ tags, data, fetchIdentitySystems, items, markKnownCallback }) => {
        const item = data;
        const { PostureSearchState } = usePostureStateContext();
        const history = useHistory();

        let ip_hostsList: any = [];
        let hostDetails: any = item.host_detail
            ? item.host_detail.filter(
                (v, i, a) => a.findIndex((v2) => v2.ip === v.ip) === i
            )
            : false;
        hostDetails?.forEach((group: any, index: any) => {
            if (
                group.ip !== '' &&
                group.ip !== undefined &&
                group.ip !== null
            ) {
                ip_hostsList.push({ ip: group.ip });
            }
        });

        const handleAsstRedirect = (item: DirectoriesObj) => {
            PostureSearchState.setIsAdSearchApplied(false);
            const timeSearchParam = getTimeBasedSearchQueryParam(PostureSearchTemplate, tags);
            let timeParam = "";
            if (timeSearchParam) {
                timeParam = "%2B" + encodeURIComponent(timeSearchParam)
            }
            if (item.is_public) {
                history.push(
                    `/posture/assets?order_by=desc&page=1&q=asset_name:${item._id}` + timeParam + "&sort_by=flow_count",
                    { breadcrumbId: 'DirectoryAssetServices' }
                );
            } else {
                history.push(
                    `/posture/assets?order_by=desc&page=1&q=asset_hostname:${item._id}` + timeParam + `&sort_by=flow_count`,
                    { breadcrumbId: 'DirectoryAssetServices' }
                );
            }
        };

        return <div className='action-btn-cell' style={{flexGrow:'1'}}>
            <div className='copy_field copy_host_count'>
                <div
                    className='ellipsis_access_dir align_left directories droplist'
                    style={{ display: 'flex' }}
                >
                    <Tooltip
                        style={{ display: 'flex' }}
                        className='ellipsis_access_dir'
                        classes={{
                            tooltip:
                                'posture-root-column-container scrollbar-container'
                        }}
                        title={
                            <div className='posture-root-column'>
                                <h2>
                                    <span
                                        className='ellipsis_idassetname tooltip_ellipses'
                                        title={item._id?.toString()}
                                    >
                                        {item._id?.toString()}
                                    </span>
                                    <div
                                        className='copy_idassetname tooltip_copy_title'
                                        title='Copy this text to Clipboard'
                                        onClick={() =>
                                            navigator.clipboard.writeText(
                                                item._id
                                            )
                                        }
                                    ></div>
                                </h2>

                                {item.orignal_names &&
                                    item.orignal_names?.length >
                                    0 ? (
                                    <table>
                                        <tr>
                                            <th
                                                className='align_left'
                                                style={{
                                                    display:
                                                        'flex',
                                                    alignItems:
                                                        'center'
                                                }}
                                            >
                                                <span>
                                                    Directory
                                                    Services
                                                </span>
                                                <div
                                                    style={{
                                                        width: 'auto',
                                                        marginLeft:
                                                            '4%',
                                                        background:
                                                            'white'
                                                    }}
                                                    onClick={(
                                                        e: any
                                                    ) =>
                                                        handleAsstRedirect(
                                                            item
                                                        )
                                                    }
                                                    className='shadowbox directories-shadow'
                                                    title='Click to see all services of this directory/IDP.'
                                                >
                                                    See
                                                    Details
                                                </div>
                                            </th>
                                        </tr>
                                        {item.orignal_names !==
                                            null &&
                                            item.orignal_names !==
                                            undefined
                                            ? item.orignal_names.map(
                                                (
                                                    group: any
                                                ) => {
                                                    return (
                                                        <tr
                                                            key={
                                                                group
                                                            }
                                                        >
                                                            <td
                                                                title={
                                                                    group
                                                                }
                                                                className='hostname_td align_left'
                                                            >
                                                                <span className='ellipsis_idassetname'>
                                                                    {
                                                                        group
                                                                    }
                                                                </span>
                                                                <div
                                                                    className='copy_idassetname'
                                                                    title='Copy this text to Clipboard'
                                                                    onClick={() =>
                                                                        navigator.clipboard.writeText(
                                                                            group
                                                                        )
                                                                    }
                                                                ></div>
                                                            </td>
                                                        </tr>
                                                    );
                                                }
                                            )
                                            : null}
                                    </table>
                                ) : null}

                                {item.orignal_unknown_names &&
                                    item.orignal_unknown_names
                                        ?.length > 0 ? (
                                    <table>
                                        <tr>
                                            <th className='align_left'>
                                                Other
                                                Services
                                            </th>
                                        </tr>
                                        {item.orignal_unknown_names !==
                                            null &&
                                            item.orignal_unknown_names !==
                                            undefined
                                            ? item.orignal_unknown_names.map(
                                                (
                                                    group: any
                                                ) => {
                                                    return (
                                                        <tr
                                                            key={
                                                                group
                                                            }
                                                        >
                                                            <td
                                                                title={
                                                                    group
                                                                }
                                                                className='hostname_td align_left'
                                                            >
                                                                <span className='ellipsis_idassetname'>
                                                                    {
                                                                        group
                                                                    }
                                                                </span>
                                                                <div
                                                                    className='copy_idassetname'
                                                                    title='Copy this text to Clipboard'
                                                                    onClick={() =>
                                                                        navigator.clipboard.writeText(
                                                                            group
                                                                        )
                                                                    }
                                                                ></div>
                                                            </td>
                                                        </tr>
                                                    );
                                                }
                                            )
                                            : null}
                                    </table>
                                ) : null}

                                {ip_hostsList.length > 0 ? (
                                    <table>
                                        <tr>
                                            <th className='align_left'>
                                                IP Address /
                                                Hostname
                                            </th>
                                        </tr>
                                        {ip_hostsList.map(
                                            (
                                                group: any,
                                                index: any
                                            ) => {
                                                if (
                                                    index <
                                                    5
                                                ) {
                                                    return (
                                                        <tr>
                                                            <td
                                                                title={
                                                                    group.ip
                                                                }
                                                                className='hostname_td align_left'
                                                            >
                                                                <span className='ellipsis_idassetname'>
                                                                    {
                                                                        group.ip
                                                                    }
                                                                </span>
                                                                <div
                                                                    className='copy_idassetname'
                                                                    title='Copy this text to Clipboard'
                                                                    onClick={() =>
                                                                        navigator.clipboard.writeText(
                                                                            group.ip
                                                                        )
                                                                    }
                                                                ></div>
                                                            </td>
                                                        </tr>
                                                    );
                                                }
                                            }
                                        )}
                                        {ip_hostsList.length >
                                            5 ? (
                                            <tr>
                                                <td
                                                    colSpan={
                                                        2
                                                    }
                                                    className='hostname_td align_left'
                                                >
                                                    <span className='ellipsis_idassetname'>
                                                        ...
                                                    </span>
                                                </td>
                                            </tr>
                                        ) : null}
                                    </table>
                                ) : null}
                            </div>
                        }
                    >
                        <div>
                            <span
                                style={{
                                    display: 'inline-block',
                                    maxWidth:
                                        window.matchMedia(
                                            '(min-width: 1901px)'
                                        ).matches
                                            ? '380px'
                                            : '220px',
                                    textOverflow:
                                        'ellipsis',
                                    overflow: 'hidden'
                                }}
                            >
                                {item.name}
                            </span>
                            <span>
                                <InfoIcon
                                    style={{
                                        fontSize: 14,
                                        color: '#d4d8e1',
                                        marginTop: 2,
                                        marginLeft: 2,
                                        transform:
                                            'skewX(-10deg)'
                                    }}
                                />
                            </span>
                        </div>
                    </Tooltip>
                </div>
                <div
                    className='directories copy_access'
                    title='Copy this text to Clipboard'
                    onClick={() =>
                        navigator.clipboard.writeText(
                            item.name
                        )
                    }
                ></div>
                {
                    <BasePostureActionMenu
                        actionData={item}
                        menuItems={
                            getRules(item, items).length > 0
                                ? DirectoriesActionMenu
                                : DirectoriesActionMenu.filter(
                                    (
                                        item: PostureMenuItem
                                    ) =>
                                        item.action === PostureMenuItemAction.MARK_KNOWN
                                )
                        }
                        exceptionData={[
                            `dir_hostname:${item._id}`
                        ]}
                        classes={
                            'directories action_button'
                        }
                        getContent={() => []}
                        incContent={() => [
                            `inc_count:${getIssueCount(
                                item
                            )}`,
                            `dir:${item._id}`,
                            `rule_name:` + getRules(item, items)
                        ]}
                        closeIncQuery={
                            `{"status":"Open","is_external":"false","dir_hostname":"${item._id}"` +
                            `,"rule_name":"` +
                            getRules(item, items) +
                            `"}`
                        }
                        issueCount={getIssueCount(item)}
                        reloadList={fetchIdentitySystems}
                        config={{
                            hideMarkKnown: true,
                            markKnownCallback:
                                markKnownCallback.bind(
                                    null,
                                    item
                                )
                        }}
                        keyName={`directory-${item._id}`}
                    />
                }
            </div>
        </div>
    }