import { CustomCellRendererProps } from "@ag-grid-community/react";
import React, { FunctionComponent } from "react";
import { PostureCategory } from "../../ref/PostureCategories/PostureCategory";
import { BasePostureActionMenu } from "../../ref/PostureActionMenus/BasePostureActionMenu";
import moment from "moment";
import { Api } from "../../../../components/Axios";
import { findDifferenceInDays } from "../../../../utils/util-methods";

interface AccessFlowCountCellRendererProps extends CustomCellRendererProps {
    widgetData, setOpenFlow, setZoomLevel, updatedExtremesRef,
    setFlowsTrendResponseData, setFlowsChartDetails, risk, appliedFilterTimestamp, getChartTitle
}

export const AccessFlowCountCell: FunctionComponent<AccessFlowCountCellRendererProps> = ({ data,
    widgetData, setOpenFlow, setZoomLevel, updatedExtremesRef, appliedFilterTimestamp, getChartTitle,
    setFlowsTrendResponseData, setFlowsChartDetails, risk }) => {
    const item = data;


    function getSearchFields() {
        let timeFilter = appliedFilterTimestamp();
        if (timeFilter.length > 0) {

            let startDate = moment(timeFilter[0]).unix();
            let endDate: any = moment(timeFilter[1]);

            if (endDate.isAfter(moment())) {
                endDate = moment().unix();
            } else {
                endDate = endDate instanceof moment ? endDate.unix() : moment(endDate).unix();
            }

            // Calculate the time difference in hours
            let diffInHours = (endDate - startDate) / 3600;

            // Determine the interval based on the difference
            let interval;
            if (diffInHours < 12) {
                interval = '1 MINUTE';
                setZoomLevel('1-minute');
            } else if (diffInHours < 48) {
                interval = '10 MINUTE';
                setZoomLevel('10-minute');
            } else {
                interval = '1 HOUR'; // Replace 'default_interval' with your desired default interval.
                setZoomLevel('hourly');
            }

            let searchFields = {
                "any_activity_time": {
                    "value": [startDate, endDate],
                    "type": "between"
                },
                "interval": interval
            };

            // Convert to JSON and encode
            return encodeURIComponent(JSON.stringify(searchFields));

        }
        return null; // Return null or an empty string if timeFilter is empty
    }

    const handleFlowClick = (event: any, data: any) => {
        setOpenFlow(true);
        setZoomLevel('hourly');
        updatedExtremesRef.current = { min: null, max: null };
        let {
            id: uniqueId,
            identityName,
            assetName,
            is_directory_public,
            directory_name = '',
            asset_type,
            identity_type
        } = data;
        const currentTime = +new Date();
        const cachedFlowData: any = sessionStorage.getItem(
            `access-flow-trend-${uniqueId}-${asset_type}`
        );

        const encodedIdentityName = encodeURIComponent(identityName);
        const encodedAssetName = encodeURIComponent(assetName);

        const dirParams = `&directory_name=${encodeURIComponent(directory_name)}&is_directory_public=${encodeURIComponent(is_directory_public)}`;
        const flowTrendURL = `/assets/flowcounts?search_type=access&search_value1=${encodedIdentityName}&search_value2=${encodedAssetName}${dirParams}&asset_type=${encodeURIComponent(asset_type)}&identity_type=${encodeURIComponent(identity_type)}`;

        sessionStorage.setItem('flowDataAccess', JSON.stringify(data));

        let queryData = `identity_name:${encodedIdentityName}+asset_name:${encodedAssetName}`;
        // if (directory_name !== '') {
        //     queryData += `+directory_name=${directory_name}`
        // }


        const searchHeaders = {
            headers: {
                search_fields: getSearchFields()
            }
        };
        Api.get(flowTrendURL, searchHeaders)
            .then((res: any) => {
                if (!res?.data?.length || res?.data?.length <= 0) {
                    setFlowsTrendResponseData({
                        flows: [],
                        expiry_time: currentTime + 600000
                    });

                    // Chart header and color
                    // const chartTitle = getChartTitle(
                    //     identityName,
                    //     assetName
                    // );

                    setFlowsChartDetails({
                        chartTitle, itemData: {
                            queryData
                        }
                    });

                    return;
                }

                let result = JSON.parse(JSON.stringify(res?.data)) || [];
                result.sort((a: any, b: any) =>
                    a?.time < b?.time ? -1 : 1
                );
                const cachedFlows = {
                    flows: result,
                    identityName,
                    risk,
                    expiry_time: currentTime + 600000
                };
                setFlowsTrendResponseData(cachedFlows);

                // Chart header and color
                const startFromDate = Math.max(
                    moment(result?.[0]?.time).valueOf(),
                    moment().subtract(90, 'days').valueOf()
                );
                // const chartTitle = getChartTitle(
                //     identityName,
                //     assetName,
                //     startFromDate
                // );

                const chartTitle = appliedFilterTimestamp()?.length > 0 ? getChartTitle(
                    identityName,
                    assetName,
                    appliedFilterTimestamp()[0],
                    appliedFilterTimestamp()[1],

                ) : getChartTitle(
                    identityName,
                    assetName,
                    startFromDate
                );
                setFlowsChartDetails({
                    chartTitle, itemData: {
                        queryData
                    }
                });

                sessionStorage.setItem(
                    `access-flow-trend-${uniqueId}-${asset_type}`,
                    JSON.stringify(cachedFlows)
                );
            })
            .catch((er) => {
                console.log(er);
            });
    };

    return <>
        {item.flow_count === 0 ?
            <div>1</div>
            : (findDifferenceInDays(item.latest_time) <= (widgetData?.posture_view_date_range || 60)) ?
                <div style={{maxWidth:'70px'}} className={`${item.flow_count > 1 && 'shadowbox'}`} id={item._id?.toString()}
                    onClick={(event) => { if (item.flow_count > 1) { handleFlowClick(event, { id: item._id, identity_type: item.identity_type, is_directory_public: item?.is_directory_public, identityName: item.identity?.toString(), directory_name: item?.directory_hostname, assetName: item.asset?.toString(), risk: undefined, asset_type: item.asset_type }) } }}
                >
                    {item.flow_count?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </div> :
                <div id={item._id?.toString()}>
                    {item.flow_count?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </div>
        }
    </>
}