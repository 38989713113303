import { RuleType } from "../../../playbooks/constants/Constants";

export const useBaseAMPostureGridHook = () => {

    const scrollToIssueColumnFn = (query: any, q: any, type?: any, scrollCallback?) => {

        const rule = Object.values(RuleType).filter(
            (i: RuleType) => i.toString() === query?.sort_by
        );

        const playbookPrefix = type === 'identities' ? 'identity_playbooks' :
            type === 'assets' ? 'asset_playbooks' :
                type === 'directory' ? 'directory_playbooks' :
                    '';

        let queries =
            q?.split('+').length > 0 && q?.split('+')[0]
                ? q?.split('+')
                : rule[0]
                    ? [`rule_name:${rule[0]}`]
                    : query?.sort_by ? [`${playbookPrefix}:${query?.sort_by}`] : [''];
        if (queries?.length > 0) {

            let issues = queries.find((i: string) => i.includes('rule_name'));
            if (!issues && playbookPrefix) {
                issues = queries.find((i: string) => i.includes(`${playbookPrefix}`));
            }
            if (issues) {
                const issuesList =
                    issues?.substring(issues.indexOf(':') + 1)?.split(',') ||
                    '';
                if (issuesList?.length === 1) {
                    scrollCallback(undefined, issuesList[0]);
                }
            } else {
               /*  scrollCallback(undefined, ''); */
            }
        }
    };

    return {
        scrollToIssueColumnFn
    }
}