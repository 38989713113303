import { IHeaderComp, IHeaderParams } from '@ag-grid-community/core';
import './PostureGrid.scss'

export class PostureGridIssueCustomHeader implements IHeaderComp {
    params!: IHeaderParams;
    eGui!: HTMLDivElement;
    eFilterMenu: any;
    eText: any;
    filterMenuPresent!: boolean;
    sortDirection: 'asc' | 'desc' | null = null;
    onMenuClickListener: any;
    onHeaderClickListener: any;

    static currentlySortedColumn: string | null = null;

    init(params: IHeaderParams) {
        this.params = params;
        this.eGui = document.createElement('div');
        this.eGui.innerHTML =
            '' +
            '<div style="display: flex; align-items: center; cursor: pointer;" class="base-am-posture-grid-custom-header-root"'
            + `data-issue-type="${this.params.column.getId()}">` +
            '<span data-ref="eFilterMenu" class="ag-icon ag-icon-menu" style="margin-right: 4px;"></span>' +
            '<div style="flex-grow: 1;">' +
            '<span data-ref="eText" style=""></span>' +
            '</div>' +
            '<span class="ag-icon ag-icon-sort" style="margin-left: 4px;"></span>' +
            '</div>';

        this.eFilterMenu = this.eGui.querySelector('[data-ref="eFilterMenu"]');
        this.eText = this.eGui.querySelector('[data-ref="eText"]');

        this.filterMenuPresent = this.params.enableFilterButton;

        if (this.filterMenuPresent) {
            this.onMenuClickListener = this.onMenuClick.bind(this);
            this.eFilterMenu.addEventListener('click', this.onMenuClickListener);
        } else {
            this.eFilterMenu.parentNode.removeChild(this.eFilterMenu);
        }

        // Add header click listener to handle sorting
        this.onHeaderClickListener = this.onSortClick.bind(this);
        this.eGui.addEventListener('click', this.onHeaderClickListener);

        // Initialize the sorting state (load default if provided)
        this.sortDirection = this.params.column.getColId() === PostureGridIssueCustomHeader.currentlySortedColumn
            ? this.params.column.getSort() || null
            : null;

        // Add event listener for grid sorting changes
        if (this.params.api) {
            this.params.api.addEventListener('sortChanged', this.onSortChanged.bind(this));
        }

        this.updateHeaderNameText();
        this.updateSortIcon();
    }

    getGui() {
        return this.eGui;
    }

    refresh(params: IHeaderParams) {
        this.params = params;
        const res = this.params.enableFilterButton === this.filterMenuPresent;
        this.updateHeaderNameText();
        this.updateSortIcon();
        return res;
    }

    updateHeaderNameText() {
        const rule_name = this.params.displayName;
        this.eText.innerHTML =
            rule_name.split(' ')[0]?.length + rule_name.split(' ')[1]?.length < 20 && rule_name.split(' ')[2] ?
                `${rule_name.split(' ')[0]} ${rule_name.split(' ')[1]}<br/>
                        ${rule_name.split(' ')[2]}` :
                rule_name.split(' ')[0] + ((rule_name.split(' ')[1]?.length < 3 ? `<span>${rule_name.split(' ')[1]}</span>` : `<span></span>`) + `<br/>`) +
                (rule_name.split(' ')[1]?.length < 3 ? `<span></span>` : `<span>${rule_name.split(' ')[1]}</span>`) +
                (rule_name.split(' ')[2]?.length < 6 ? `${rule_name.split(' ')[2]}` : '');

        this.eText.classList.add('ag-header-cell-text');
    }

    onMenuClick() {
        this.params.showColumnMenu(this.eFilterMenu);
    }

    onSortClick() {
        // Reset sort icon for previously sorted column if it's not the current column
        if (PostureGridIssueCustomHeader.currentlySortedColumn && PostureGridIssueCustomHeader.currentlySortedColumn !== this.params.column.getId()) {
            this.resetSortIcon(PostureGridIssueCustomHeader.currentlySortedColumn);
        }

        this.toggleSort();
        PostureGridIssueCustomHeader.currentlySortedColumn = this.params.column.getId();
    }

    toggleSort() {
        // Toggle sort direction
        this.sortDirection = this.sortDirection === 'desc' ? 'asc' : 'desc';
        this.params.setSort(this.sortDirection);
        this.updateSortIcon();
    }

    updateSortIcon() {
        const sortIcon = this.eGui.querySelector('.ag-icon-sort');
        if (sortIcon) {
            sortIcon.classList.remove('ag-icon-asc', 'ag-icon-desc', 'ag-icon-none');

            if (this.sortDirection) {
                sortIcon.classList.add(this.sortDirection === 'asc' ? 'ag-icon-asc' : 'ag-icon-desc');
            } else {
                sortIcon.classList.add('ag-icon-none');
            }
        }
    }

    resetSortIcon(columnId: string) {
        const list = document.querySelectorAll('.ag-icon-sort');
        list.forEach((sortIcon) => {
            sortIcon.classList.remove('ag-icon-asc');
            sortIcon.classList.remove('ag-icon-desc');
        });
        this.updateSortIcon();
    }

    // Handle grid's sorting change event
    onSortChanged() {
        // Get the current sorted columns
        const sortedColumns = this.params.api.getAllGridColumns().filter(col => col.getSort());

        // Update sort state based on the current sorted column
        const currentColumn = this.params.column.getId();
        const sortedColumn = sortedColumns.find(col => col.getColId() === currentColumn);

        if (sortedColumn) {
            this.sortDirection = sortedColumn.getSort();
        } else {
            this.sortDirection = null;
        }

        // Update the sort icon
        this.updateSortIcon();
    }

    destroy() {
        if (this.onMenuClickListener) {
            this.eFilterMenu.removeEventListener('click', this.onMenuClickListener);
        }
        if (this.onHeaderClickListener) {
            this.eGui.removeEventListener('click', this.onHeaderClickListener);
        }

        if (this.params.api) {
            this.params.api.removeEventListener('sortChanged', this.onSortChanged.bind(this));
        }
    }
}
