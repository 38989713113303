import React, { useEffect, useRef, useState } from "react";
import { Api } from "../../components/Axios";
import CheckboxAutocomplete from "../../components/core/AMAutoComplete/AMAutoComplete";
import "./AmopsRoleManagment.scss";
import { deepCompare, getResolutionMatch, getResolutionMatchFor1920 } from "../../utils/util-methods";
import Draggable from "react-draggable";
import { Box, Dialog, DialogContent, DialogTitle, Fade, Link, Tooltip } from "@mui/material";
import AMMultiSelectGroup from "../../components/core/AMMultiselectGroupable/AMMultiSelect";
import { DashboardResponse } from "../../types/response-types";
import { getIssueNameById } from "../playbooks/helpers/playbook-helper";
import { RuleType } from "../playbooks/constants/Constants";
import { keysMapping } from "../../constants/widgetDataKeyMappings";
import { useToasts } from "../../components/core";
import { AMGenericConfirmPopUp } from "../../components/core/AMGenericConfirmPopUp/AMGenericConfirmPopUp";
import BaseAMAdminGrid from "../../components/core/AMTable/BaseAMAdminGrid";
import { ColDef } from "@ag-grid-community/core";
import { AmOpsCustomHeader } from "./AmOpsCustomHeader";

const AmopsRoleManagment = () => {
  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState("");


  const [widgetsConfig, setWidgetsConfig] = useState({});
  const [defaultWdgetsConfig, setDefaultWidgetsConfig] = useState({});
  const [intialWidgetsConfig, setIntialWidgetsConfig] = useState({});

  const [copyToOtherRoles, setCopyToOtherRoles] = useState(false);
  const { addToast } = useToasts()

  const [openModal, setOpenModal] = useState<string | null>(null);
  const [toggleDropdown, setToggleDropdown] = useState<{
    [widgetId: string]: boolean;
  }>({
    id_access_infra: false,
    shadow_activity: false,
    unauthorised_access: false,
  });
  const [playBookData, setPlayBookData] = useState<any>({});
  const [responseData, setResponseData] = useState<
    DashboardResponse | undefined
  >();

  const [viewType, setViewType] = useState<{ [widgetId: string]: string }>({
    id_access_infra: "issueTypeView",
    shadow_activity: "issueTypeView",
    unauthorised_access: "issueTypeView",
  });

  const [searchKeyword, setSearchKeyword] = useState<any>("");
  const [clearKeyword, setClearKeyword] = useState<any>(false);
  const currentSearchKeywordRef = useRef(searchKeyword);

  let lastIssueId = 0;
  let POLLING_SYNC_FLAG = true;
  let prevAccessFlowsCount = 0;

  const options = [
    { key: "top_new_risky", value: "Top new risky entities" },
    { key: "recent_issues", value: "Recent Accesses" },
    { key: "id_access_infra", value: "ID and Access Infrastructure" },
    { key: "shadow_activity", value: "Shadow Activity" },
    { key: "unauthorised_access", value: "Unauthorized Access" },
    { key: "credentials_risk", value: "Credentials Risk" },
    { key: "unknown_access", value: "Unknown Access" },
    { key: "protocols", value: "Risky Protocols" },
    { key: "issue_trend", value: "Incidents Trend" },
    { key: 'allow_config_change', value: 'Allow users with this role to modify dashboard and posture view settings' }
    // {key:'copy_other',value:'Copy this configuration to other roles'}
  ];

  const [showConfirm, setShowConfirm] = useState(false);

  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [userInput, setUserInput] = useState('');
  const [popUpButtons, setPopUpButtons] = useState({
    no: {
      id: 'no', label: 'No'
    },
    yes: {
      id: 'yes', label: 'Yes'
    }
  });

  useEffect(() => {
    if (showConfirm) {
      const btns = JSON.parse(JSON.stringify(popUpButtons));

      btns.yes.isDisabled = false;

      setPopUpButtons(btns);

    }
  }, [showConfirm])

  const initializeWidgetsConfig = (rolesObj) => {
    const config = options.reduce((widgetAcc, widget) => {
      widgetAcc[widget.key] = rolesObj.reduce((roleAcc, role) => {
        roleAcc[role.key] = {
          visibility: !["recent_issues", "top_new_risky", 'allow_config_change', 'copy_other'].includes(widget.key), // Default visibility
        };
        return roleAcc;
      }, {});
      return widgetAcc;
    }, {});
    // setDefaultWidgetsConfig(config);
    setIntialWidgetsConfig(config);
    //  setWidgetsConfig(config);
  };


  useEffect(() => {
    fetchRoles();

  }, []);


  const updateCols = () => {
    if (roles) {
      const rolesData = roles.map((i: { key: string; value: string }) => {
        return {
          field: i.key,
          headerName: i.value,
          width: getResolutionMatchFor1920(250, 250),
          maxWidth: getResolutionMatchFor1920(250, 250),
          minWidth: getResolutionMatchFor1920(250, 250),
          sortable: false,
          cellClass: 'display_flex_center',
          headerComponent: AmOpsCustomHeader,
          headerComponentParams: {
            role: i, copyConfiguration
          },
          headerClass: 'header-cell-center',
          cellRendererParams: {
            widgetsConfig, defaultWdgetsConfig, defaultSelectedItems, selectedItems
          },
          cellRenderer: (props) => {
            const data = props.data;
            const widgetsConfig = props.widgetsConfig
            const defaultWdgetsConfig = props.defaultWdgetsConfig
            const selectedItems = props.selectedItems
            const defaultSelectedItems = props.defaultSelectedItems
            if (widgetsConfig) {
              const widgetVaue = options.filter(i => i.key === data._id)[0].value
              const isChanged =
                widgetsConfig[data._id]?.[i.key]?.visibility !==
                defaultWdgetsConfig[data._id]?.[i.key]?.visibility || JSON.stringify(selectedItems[i.key]?.[data._id]) !==
                JSON.stringify(defaultSelectedItems[i.key]?.[data._id]);

              // Select all elements with the id 'issue_trend-admin'

              setTimeout(() => {
                const issueTrendDiv = document.querySelector(`#${data._id}-${i.key}`)

                if (issueTrendDiv) {
                  // Find the closest parent with the class 'ag-grid-cell'
                  const parentAgGridCell = issueTrendDiv.closest('.ag-cell');

                  if (parentAgGridCell) {
                    if (isChanged) {
                      parentAgGridCell.classList.add('highlight-cell')
                    } else {
                      parentAgGridCell.classList.remove('highlight-cell')
                    }

                  }
                }
              })

              return (
                <div
                  id={`${data._id}-${i.key}`}
                  key={`${data._id}-${i.key}`}
                  style={{ position: "relative" }}
                /* className={isChanged ? "highlight-cell" : ""} */
                >
                  <div style={{ margin: "0px auto", width: "34px", position: "relative" }}>
                    <input
                      type="checkbox"
                      checked={widgetsConfig[data._id]?.[i.key]?.visibility || false}
                      onChange={(e) =>
                        handleVisibilityChange(data._id, i.key, e)
                      }
                      style={{ float: "left" }}
                    />
                    {["ID and Access Infrastructure", "Shadow Activity", "Unauthorized Access"].includes(
                      widgetVaue
                    ) && (
                        <>
                          <div
                            className='gear-setting-icon setting-icon'
                            style={{
                              pointerEvents: widgetsConfig[data._id]?.[i.key]?.visibility === false ? 'none' : 'auto', float: 'left',
                              opacity: widgetsConfig[data._id]?.[i.key]?.visibility === false ? 0.5 : 1
                            }}
                            title={`Configure View: ${widgetVaue}`}
                            onClick={(event) => handleOpenModal(widgetVaue, i.value, event)}
                          ></div>
                          {/* Draggable Dialog Component */}
                          <Draggable
                            position={controlledPosition}
                            handle='.search-header-container'
                            onStop={onControlledDragStop}
                          >
                            <Dialog className="am-groupable-dialog" open={openModal === widgetVaue + '_' + i.value} slotProps={{
                              backdrop: {
                                onClick: (event) => event.stopPropagation(),
                              },
                            }}>
                              <DialogTitle ref={dragContainerRef} className='search-header-container'>
                                <span className="groupable-title">Settings - <span style={{ textTransform: 'uppercase' }}>{widgetVaue}</span> </span>
                                <Box
                                  sx={{ float: "right", marginTop: "20px", right: '0', cursor: 'pointer' }}
                                  onClick={handleClose}
                                  className="popup-container-close-dashboard"
                                >
                                </Box>
                              </DialogTitle>

                              <DialogContent
                                sx={{
                                  width: "500px",
                                  height: "400px",
                                  overflowX: "hidden",
                                }}
                              >
                                <Box sx={{ marginBottom: '15px' }} className="sub-header">  Select Issue Types and Playbooks to display.  </Box>
                                <AMMultiSelectGroup
                                  groups={groups}
                                  onSelect={(updatedItems) => handleSelect(i.key, data._id, updatedItems)}
                                  handleClearBtn={handleClear}
                                  onDoneClick={handleDoneClick}
                                  onSearchKeyUp={handleOnSearchKeyUp}
                                  doneLabel="Save"
                                  selectedItems={selectedItems[i.key]}
                                  widId={data._id}
                                  widgetData={responseData}
                                  renderGroupLabel={renderGroupLabel}
                                />
                              </DialogContent>
                            </Dialog>
                          </Draggable>
                        </>
                      )}
                  </div>
                </div>)
            }
            return <></>
          }
        }
      });
      rolesData.unshift({
        headerName: 'Widget visibility/permissions',
        field: 'vis_perm',
        width: getResolutionMatchFor1920(750, 750),
        maxWidth: getResolutionMatchFor1920(750, 750),
        minWidth: getResolutionMatchFor1920(750, 750),
        sortable: false,
        cellRenderer: ({ data }) => {
          return options.filter(i => i.key === data._id)[0].value
        }
      })
      setCols(rolesData)
    }
  }

  useEffect(() => {
    updateCols()
  }, [roles, widgetsConfig])

  // Fetch roles from API
  const fetchRoles = () => {
    Api.get("/roles")
      .then((res) => {
        if (res.status === 200) {
          const rolesObj = res.data.map(role => ({
            key: role.toLowerCase().replace(/\s+/g, '_'), // Using role name as the key
            value: role // Capitalize the role name for a user-friendly display
          }))
          setRoles(rolesObj); // Assuming the roles are in res.data
          initializeWidgetsConfig(rolesObj);
          // loadRoleConfig();
        }
      })
      .catch((error) => {
        console.error("Error fetching roles:", error);
      });
  };

  // Handle role change
  const handleRoleChange = (event) => {
    setSelectedRole(event.target.value);
    // Load widget configuration for the selected role
    // loadRoleConfig(event.target.value);
  };

  // Load configuration for selected role
  const loadRoleConfig = () => {
    // Example API call to fetch widget settings for a specific role
    Api.get(`/role_config`)
      .then((res) => {
        if (res.status === 200) {

          const data: any = res.data;
          const { _id, ...restData } = data || {};



          setWidgetsConfig((prevConfig) => {
            const updatedConfig = { ...prevConfig };

            // Iterate over the widget options (available widgets)
            options.forEach((widget) => {
              const widgetKey = widget.key;



              // Initialize widget in updatedConfig if not already present
              if (!updatedConfig[widgetKey]) {
                updatedConfig[widgetKey] = {};
              }

              // Iterate over each role data
              res.data.forEach((roleData) => {
                const { exclude_widgets, role_name } = roleData;
                const key = role_name.toLowerCase().replace(/\s+/g, '_')

                // Set visibility for the widget based on whether it's excluded or not
                const isExcluded = exclude_widgets.includes(widgetKey);
                const visibility = isExcluded ? false : true;
                if (widgetKey !== 'allow_config_change') {
                  // Set visibility for the current role
                  updatedConfig[widgetKey][key] = {
                    visibility,
                  };
                } else {
                  updatedConfig[widgetKey][key] = { visibility: roleData['allow_config_change'] };
                }
              });
            });

            // Optionally, set any default values for widget configurations if needed
            setDefaultWidgetsConfig(updatedConfig);
            const rowData = Object.keys(updatedConfig).map((i) => {
              return { _id: i, ...updatedConfig[i] }
            })
            console.log(rowData)
            setRowData(rowData)
            return updatedConfig;
          });

          //



          const updatedSelectedItems = res.data.reduce((acc, roleData) => {
            const roleName = roleData.role_name.toLowerCase().replace(/\s+/g, '_'); // Format the role name as a key

            acc[roleName] = {
              // Update each widget for the role
              id_access_infra: roleData?.dashboard_playbook_view?.id_access_infra_default_view
                ? setDefaultArray(groups['id_access_infra'])
                : roleData?.dashboard_playbook_view.id_access_infra || [],

              id_access_infra_default_view: roleData?.dashboard_playbook_view?.id_access_infra_default_view
                ? roleData?.dashboard_playbook_view.id_access_infra_default_view : false,

              unauthorised_access: roleData?.dashboard_playbook_view?.unauthorised_access_default_view
                ? setDefaultArray(groups['unauthorised_access'])
                : roleData?.dashboard_playbook_view.unauthorised_access || [],

              unauthorised_access_default_view: roleData?.dashboard_playbook_view?.unauthorised_access_default_view
                ? roleData?.dashboard_playbook_view.unauthorised_access_default_view : false,

              shadow_activity: roleData?.dashboard_playbook_view?.shadow_activity_default_view
                ? setDefaultArray(groups['shadow_activity'])
                : roleData?.dashboard_playbook_view.shadow_activity || [],

              shadow_activity_default_view: roleData?.dashboard_playbook_view?.shadow_activity_default_view
                ? roleData?.dashboard_playbook_view.shadow_activity_default_view : false,
            };

            return acc;
          }, {});

          // Set the updatedSelectedItems to state
          setSelectedItems(updatedSelectedItems);
          setDefaultSelectedItems(updatedSelectedItems);

          // Update the state with the processed data

          // setWidgetsConfig(res.data); // Assuming response contains the widget configuration
          // initializeWidgetsConfig(res.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching widget config:", error);
      });
  };



  const handleVisibilityChange = (widgetKey, roleKey, event) => {
    setWidgetsConfig((prevConfig) => ({
      ...prevConfig,
      [widgetKey]: {
        ...prevConfig[widgetKey],
        [roleKey]: {
          ...prevConfig[widgetKey]?.[roleKey],
          visibility: event.target.checked,
        },
      },
    }));
  };

  useEffect(() => {
    console.log(widgetsConfig)
    if (rowData && Array.isArray(rowData))
      setRowData([...rowData])
  }, [widgetsConfig, defaultWdgetsConfig])

  const handleModifySettingsChange = (widgetKey, roleKey, event) => {
    setWidgetsConfig((prevConfig) => ({
      ...prevConfig,
      [widgetKey]: {
        ...prevConfig[widgetKey],
        [roleKey]: {
          ...prevConfig[widgetKey]?.[roleKey],
          modifySettings: event.target.checked,
        },
      },
    }));
  };

  const [selectedVal, setSelectedVal] = useState<any>([]);
  const handleSelectionChange = (event: any, filteredValue: any) => {
    setSelectedVal(filteredValue);
    setSelectedRole(event.target.value);

  };

  const getItemLabel = (
    option: { key: string; value: string } | { key: string; value: string }[]
  ) => {
    if (Array.isArray(option)) {
      return option[0]?.value ? option[0]?.value : "";
    } else {
      return option?.value ? option?.value : "";
    }
  };


  const isObjectNotEmpty = (obj: any) => {
    if (obj) {
      return Object.keys(obj).length > 0;
    }
  };


  useEffect(() => {
    if (isObjectNotEmpty(playBookData)) setGroupData();
  }, [playBookData]);

  const [groups, setGroup] = useState<{ [widgetId: string]: any[] }>({
    id_access_infra: [],
    shadow_activity: [],
    unauthorised_access: [],
  });

  const setDefaultArray = (inputArray: any) => {
    return inputArray.map((item: any) => ({
      _id: item.name,
      is_issue_type: true
    }));

  }

  const fetchUserSelection = async () => {
    await Api.get("/role_config", {
      timeout: 30000,
    })
      .then((res) => {
        const data: any = res.data.result;
        const { _id, ...restData } = data || {};

        // Loop through each role in the response
        const updatedSelectedItems = Object.keys(restData).reduce((acc, roleId) => {
          const roleData = restData[roleId];

          acc[roleId] = {
            id_access_infra: roleData?.dashboard_playbook_view.id_access_infraid_access_infra_default_view
              ? setDefaultArray(groups['id_access_infra'])
              : roleData?.dashboard_playbook_view.id_access_infraid_access_infra,
            id_access_infra_default_view: roleData?.dashboard_playbook_view.id_access_infraid_access_infra_default_view || false,

            unauthorised_access: roleData?.dashboard_playbook_view.id_access_infraunauthorised_access_default_view
              ? setDefaultArray(groups['unauthorised_access'])
              : roleData?.dashboard_playbook_view.id_access_infraunauthorised_access,
            unauthorised_access_default_view: roleData?.dashboard_playbook_view.id_access_infraunauthorised_access_default_view || false,

            shadow_activity: roleData?.dashboard_playbook_view.id_access_infrashadow_activity_default_view
              ? setDefaultArray(groups['shadow_activity'])
              : roleData?.dashboard_playbook_view.id_access_infrashadow_activity,
            shadow_activity_default_view: roleData?.dashboard_playbook_view.id_access_infrashadow_activity_default_view || false,
          };

          return acc;
        }, {});

        // Update the state with the processed data
        setSelectedItems(updatedSelectedItems);
        initializeWidgetsConfig(data);
      })
      .catch((err) => {
        POLLING_SYNC_FLAG = true;
        if (err.response?.status === 401) {
          window.location.href = "/login";
        }
      });
  };

  useEffect(() => {
    if (groups['id_access_infra'].length > 0 || groups['shadow_activity'].length > 0 || groups['unauthorised_access'].length > 0) {
      //  loadRoleConfig();
    }

  }, [groups])

  const setGroupData = () => {
    const group = ["id_access_infra", "shadow_activity", "unauthorised_access"];

    group.forEach((element) => {
      if (keysMapping) {
        const datakey = keysMapping.get(element);

        if (datakey) {
          setGroup((prevGroup) => {
            // Using the spread operator to create a copy of the previous state
            const updatedGroup = { ...prevGroup };

            // If the group[element] doesn't exist, initialize it as an empty array
            if (!updatedGroup[element]) {
              updatedGroup[element] = [];
            }

            // Push each datakey to the corresponding group
            datakey.forEach((key) => {
              if (playBookData.hasOwnProperty(key)) {
                // Filter playbooks by type matching the element

                let filteredPlaybooks = [];

                if (key === 'Posture Issues') {
                  filteredPlaybooks = playBookData[key].playbooks.filter(
                    (playbook) => playbook.dashboard_widget === element
                  );
                }

                updatedGroup[element].push({
                  name: key,
                  items: key === 'Posture Issues' ? filteredPlaybooks : playBookData[key].playbooks, // Only playbooks matching the element type
                  is_posture_issue: key === 'Posture Issues'
                });

              }
            });

            return updatedGroup;
          });
        }
      }
    });
    loadRoleConfig();
  };

  const [doneClick, setDoneClick] = useState<boolean>(false);
  const [selectedItems, setSelectedItems] = useState<{
    [roleId: string]: {
      [widgetId: string]: any;
    };
  }>({
    admin: {
      shadow_activity: [],
      id_access_infra: [],
      unauthorised_access: [],
      id_access_infra_default_view: true,
      shadow_activity_default_view: true,
      unauthorised_access_default_view: true,
    },
    analyst: {
      shadow_activity: [],
      id_access_infra: [],
      unauthorised_access: [],
      id_access_infra_default_view: true,
      shadow_activity_default_view: true,
      unauthorised_access_default_view: true,
    },
    incident_responder: {
      shadow_activity: [],
      id_access_infra: [],
      unauthorised_access: [],
      id_access_infra_default_view: true,
      shadow_activity_default_view: true,
      unauthorised_access_default_view: true,
    },
    security_operator: {
      shadow_activity: [],
      id_access_infra: [],
      unauthorised_access: [],
      id_access_infra_default_view: true,
      shadow_activity_default_view: true,
      unauthorised_access_default_view: true,
    }

    // Add additional roles as needed
  });

  const [defaultSelectedItems, setDefaultSelectedItems] = useState<{
    [roleId: string]: {
      [widgetId: string]: any;
    };
  }>({
    admin: {
      shadow_activity: [],
      id_access_infra: [],
      unauthorised_access: [],
      id_access_infra_default_view: true,
      shadow_activity_default_view: true,
      unauthorised_access_default_view: true,
    },
    analyst: {
      shadow_activity: [],
      id_access_infra: [],
      unauthorised_access: [],
      id_access_infra_default_view: true,
      shadow_activity_default_view: true,
      unauthorised_access_default_view: true,
    },
    incident_responder: {
      shadow_activity: [],
      id_access_infra: [],
      unauthorised_access: [],
      id_access_infra_default_view: true,
      shadow_activity_default_view: true,
      unauthorised_access_default_view: true,
    },
    security_operator: {
      shadow_activity: [],
      id_access_infra: [],
      unauthorised_access: [],
      id_access_infra_default_view: true,
      shadow_activity_default_view: true,
      unauthorised_access_default_view: true,
    }

    // Add additional roles as needed
  });
  const [Cols, setCols] = useState<Array<ColDef>>();
  const [rowData, setRowData] = useState();
  const [initialSelectedItems, setInitialSelectedItems] = useState<{
    [roleId: string]: {
      [widgetId: string]: any;
    };
  }>({
    admin: {
      shadow_activity: [],
      id_access_infra: [],
      unauthorised_access: [],
      id_access_infra_default_view: true,
      shadow_activity_default_view: true,
      unauthorised_access_default_view: true,
    },
    analyst: {
      shadow_activity: [],
      id_access_infra: [],
      unauthorised_access: [],
      id_access_infra_default_view: true,
      shadow_activity_default_view: true,
      unauthorised_access_default_view: true,
    },
    incident_responder: {
      shadow_activity: [],
      id_access_infra: [],
      unauthorised_access: [],
      id_access_infra_default_view: true,
      shadow_activity_default_view: true,
      unauthorised_access_default_view: true,
    },
    security_operator: {
      shadow_activity: [],
      id_access_infra: [],
      unauthorised_access: [],
      id_access_infra_default_view: true,
      shadow_activity_default_view: true,
      unauthorised_access_default_view: true,
    }

    // Add additional roles as needed
  });


  const handleSelect = (roleId: string, widgetId: string, updatedItems: any[]) => {
    const key = widgetId + '_default_view';
    setSelectedItems((prevState) => ({
      ...prevState,
      [roleId]: {
        ...prevState[roleId],
        [widgetId]: updatedItems[widgetId], // Dynamically update widgetId
        [key]: updatedItems[key], // Dynamically update the key (widgetId_default_view)
      },
    }));
    setDoneClick(true);
    setRowData([...rowData])
  };

  const dragContainerRef = useRef(null);
  const onControlledDrag = (e, position) => {
    let { x, y } = position;
    // top bounds check
    if (y < -10) {
      y = -10;
    }
    // bottom bounds check (leave 13% of container top area outside)
    if (y > window.innerHeight - window.innerHeight * 0.13) {
      y = window.innerHeight - window.innerHeight * 0.13;
    }
    // right bounds check (leave 7% of container right area outside)
    if (
      x + dragContainerRef.current.clientWidth >
      window.innerWidth - window.innerWidth * 0.07
    ) {
      x =
        window.innerWidth -
        dragContainerRef.current.clientWidth -
        window.innerWidth * 0.07;
    }

    // left bounds check
    if (x < -(window.innerWidth - 100)) {
      x = -(window.innerWidth - 100);
    }
    setControlledPosition({ x, y });
  };

  const onControlledDragStop = (e, position) => {
    onControlledDrag(e, position);
  };
  const [controlledPosition, setControlledPosition] = useState({
    x: 0,
    y: 0
  });

  const handleClear = (selectedItems: { [widgetId: string]: string[] }) => {
    setOpenModal('');
    setControlledPosition({ x: 0, y: 0 });
  };

  const getPlayBookSelectionData = () => {
    return selectedItems;
  };

  const updateUserSelection = (data: any) => {

    Api.post("/dashboard/playbookview", data)
      .then((res: { data: any }) => {
      })
      .catch((error: any) => {
        // setLoading(false)
        if (error.response.status === 500) {
          // addToast("Sorry, something went wrong there, try again.", {
          //   appearance: 'error',
          //   autoDismiss: true,
          // })
          // setSErrors(error.response.data)
        } else if (error.response.status === 404) {
          // addToast(error.response.data, {
          //   appearance: 'error',
          //   autoDismiss: true,
          // })
          // setSErrors(error.response.data)
        } else if (error.response.status === 400) {
          // addToast(error.response.data, {
          //   appearance: 'error',
          //   autoDismiss: true,
          // })
          // setSErrors(error.response.data)
        }
      });

  };

  useEffect(() => {
    if (!deepCompare(selectedItems, {
      shadow_activity: [{ "is_issue_type": true, "id": "Exposed Assets" }, { "is_issue_type": true, "id": "Shadow Access" }, { "is_issue_type": true, "id": "Shadow Assets" }, { "is_issue_type": true, "id": "Shadow Directory" }, { "is_issue_type": true, "id": "Suspicious Inbound Access" }, { "is_issue_type": true, "id": "Suspicious Outbound Access" }, { "is_issue_type": true, "id": "Unknown SaaS Access" }],
      id_access_infra: [{ "is_issue_type": true, "id": "Auth Hash Security" }, { "is_issue_type": true, "id": "Lack of MFA" },
      { "is_issue_type": true, "id": RuleType.ASSETS_WITHOUT_MFA },
      { "is_issue_type": true, "id": "Suspected Directory/IdP Bot Attack" }, { "is_issue_type": true, "id": "Suspected Directory/IdP Identity Brute-force Attack" }, { "is_issue_type": true, "id": "Suspected AD NTLM Relay Attack" }, { "is_issue_type": true, "id": "Suspected Attack on Locked AD Account" }, { "is_issue_type": true, "id": "Suspected Directory/IdP Password Spray Attack" }, { "is_issue_type": true, "id": "Suspected Attack on Disabled AD Account" }, { "is_issue_type": true, "id": "Suspected Attack on Expired AD Account" }, { "is_issue_type": true, "id": "Repeated AD Login Attempts from Invalid Device" }, { "is_issue_type": true, "id": "Repeated AD Login Attempts at Invalid Time" }, { "is_issue_type": true, "id": "Enumeration of AD Users" }, { "is_issue_type": true, "id": "Enumeration of AD Admins" }, { "is_issue_type": true, "id": "Auth Hash Quality" }, { "is_issue_type": true, "id": "Suspected AD Golden Ticket Attack" }, { "is_issue_type": true, "id": "Suspected AD Pass-the-Ticket Attack" }],
      unauthorised_access: [{ "is_issue_type": true, "id": "Access to Unauthorized Countries" }, { "is_issue_type": true, "id": "Access from Unauthorized Countries" }],
      id_access_infra_default_view: true,
      shadow_activity_default_view: true,
      unauthorised_access_default_view: true
    })) {
      if (doneClick) {
        //  updateUserSelection(getPlayBookSelectionData());
      }
    }
  }, [selectedItems, doneClick]);

  const handleDoneClick = () => {
    setOpenModal("");
    setControlledPosition({ x: 0, y: 0 });
  };

  const handleDropdownToggle = (widgetId: string) => {
    setToggleDropdown((prevStates) => ({
      ...prevStates,
      [widgetId]: !prevStates[widgetId],
    }));
  };

  const handleViewChange = (widgetId: string, name: string, e: any) => {
    handleDropdownToggle(widgetId);
    setViewType((prevStates) => ({
      ...prevStates,
      [widgetId]: name,
    }));
  };

  const handleOpenModal = (widgetId: string, role, e: any) => {
    e.stopPropagation();
    setOpenModal(widgetId + '_' + role);
    // Set initial values for the modal content based on the widget type
    // setModalContent(getModalContentByWidgetType(widgetId));
  };

  const renderGroupLabel = (groupId) => {
    return getIssueNameById(groupId)
  }

  const handleOnSearchKeyUp = (evt: any, clear: any = false) => {
    localStorage.removeItem("saas_state");
    setSearchKeyword(evt);
    currentSearchKeywordRef.current = evt;
    setClearKeyword(clear);
  };

  const handleClose = () => {
    setOpenModal("");
    setControlledPosition({ x: 0, y: 0 });
  };

  const fetchData = async () => {
    if (POLLING_SYNC_FLAG) {
      POLLING_SYNC_FLAG = false;
      await Api.get("/dashboard?last_issue_id=" + lastIssueId, {
        timeout: 30000,
      })
        .then((res) => {
          //res.data = responseAdapter(res.data);
          if (prevAccessFlowsCount === 0) {
            prevAccessFlowsCount = res.data.past_24_hours_flows.access_flows;
          }
          setResponseData(res.data);
          POLLING_SYNC_FLAG = true;
          lastIssueId =
            res.data.recent_issues.issues[
              res.data.recent_issues.issues.length - 1
            ]._id;
        })
        .catch((err) => {
          POLLING_SYNC_FLAG = true;
          if (err.response?.status === 401) {
            window.location.href = "/login";
          }
        });
    }
  };



  const fetchPlayBookData = async () => {
    await Api.get("/pbviewplaybooks?type=dashboard", {
      timeout: 30000,
    })
      .then((res) => {
        //res.data = responseAdapter(res.data);
        setPlayBookData(res.data);
      })
      .catch((err) => {
        POLLING_SYNC_FLAG = true;
        if (err.response?.status === 401) {
          window.location.href = "/login";
        }
      });
  };


  useEffect(() => {
    // setInterval(() => {
    //     setOdd((prev) => {
    //         const newOdd = prev + 1;
    //         console.log(newOdd);
    //         return newOdd;
    //     });
    // }, 2000);

    fetchData();
    fetchPlayBookData();
    // const pollApi = setInterval(() => {
    //   fetchData();
    // }, 10000);
    return () => {
      // clearInterval(pollApi);
      lastIssueId = 0;
    };
  }, []);

  const roleObj = {
    'admin': 'Admin',
    'analyst': 'Analyst',
    'incident_responder': 'Incident Responder',
    'security_operator': 'Security Operator'
  }


  // const transformData = (
  //     selectedItems: any,
  //     widgetsConfig: any
  // ) => {
  //     return Object.entries(selectedItems).map(([roleName, widgets]) => {
  //         // Calculate `exclude_widgets` for this role based on widgetsConfig
  //         const excludeWidgets = Object.entries(widgetsConfig)
  //     .filter(
  //         ([widgetKey, widgetRoles]) =>
  //             widgetKey !== "allow_config_change" && // Exclude `allow_config_change` explicitly
  //             !widgetRoles[roleName]?.visibility // Exclude widgets where visibility is false or undefined
  //     )
  //     .map(([widgetKey]) => widgetKey);

  //         // Prepare `dashboard_playbook_view` object
  //         const dashboardPlaybookView: Record<string, any> = {};
  //         Object.entries(widgets).forEach(([widgetId, value]) => {
  //             if (widgetId.endsWith("_default_view")) {
  //                 dashboardPlaybookView[widgetId] = value;
  //             } else {
  //                 dashboardPlaybookView[widgetId] = value.length ? value : null;
  //             }
  //         });

  //         return {
  //             allow_config_change: widgetsConfig['allow_config_change'][roleName].visibility , // Default value; modify as needed
  //             dashboard_playbook_view: dashboardPlaybookView,
  //             exclude_widgets: excludeWidgets,
  //             role_name: roleObj[roleName],
  //         };
  //     });
  // };

  const transformData = (
    selectedItems: any,
    widgetsConfig: any
  ) => {
    return Object.entries(selectedItems)
      .filter(([roleName, widgets]) => {
        // Check if data for this role is modified
        const isSelectedItemsModified =
          JSON.stringify(widgets) !== JSON.stringify(defaultSelectedItems[roleName]);
        const isWidgetsConfigModified = Object.entries(widgetsConfig).some(([widgetKey, widgetRoles]) => {
          if (widgetKey === "allow_config_change") {
            return (
              widgetRoles[roleName]?.visibility !==
              defaultWdgetsConfig[widgetKey]?.[roleName]?.visibility
            );
          }
          return (
            widgetRoles[roleName]?.visibility !==
            defaultWdgetsConfig[widgetKey]?.[roleName]?.visibility
          );
        });

        return isSelectedItemsModified || isWidgetsConfigModified;
      })
      .map(([roleName, widgets]) => {
        // Calculate `exclude_widgets` for this role based on widgetsConfig
        const excludeWidgets = Object.entries(widgetsConfig)
          .filter(
            ([widgetKey, widgetRoles]) =>
              widgetKey !== "allow_config_change" && // Exclude `allow_config_change` explicitly
              !widgetRoles[roleName]?.visibility // Exclude widgets where visibility is false or undefined
          )
          .map(([widgetKey]) => widgetKey);

        // Prepare `dashboard_playbook_view` object
        const dashboardPlaybookView: Record<string, any> = {};
        Object.entries(widgets).forEach(([widgetId, value]) => {
          if (widgetId.endsWith("_default_view")) {
            dashboardPlaybookView[widgetId] = value;
          } else {
            dashboardPlaybookView[widgetId] = value.length ? value : null;
          }
        });

        return {
          allow_config_change: widgetsConfig["allow_config_change"][roleName]?.visibility, // Default value; modify as needed
          dashboard_playbook_view: dashboardPlaybookView,
          exclude_widgets: excludeWidgets,
          role_name: roleObj[roleName], // Use the mapped role name
        };
      });
  };



  const handleOnSave = () => {

    const data = transformData(selectedItems, widgetsConfig,);
    Api.post(`/role_config`, data)
      .then((res) => {
        if (res.status === 200) {
          addToast('Changes saved successfully.', {
            appearance: 'success',
            autoDismiss: true,
          })
          setShowConfirm(false);
          setDefaultWidgetsConfig(widgetsConfig);
          setDefaultSelectedItems(selectedItems);

        }
      }).catch((error: any) => {
        setShowConfirm(false);

        if (error?.response?.data) {
          addToast(error.response.data, {
            appearance: 'error',
            autoDismiss: true,
          })
        } else if (error?.response?.status === 500) {
          addToast("Sorry, something went wrong there, try again.", {
            appearance: 'error',
            autoDismiss: true,
          })
        } else if (error?.response?.status === 419) {
          addToast("We encounted validation problem, please correct and try again.", {
            appearance: 'error',
            autoDismiss: true,
          })
        } else if (error?.response?.status === 404) {
          addToast("We are not able to find associated email, please check and try again.", {
            appearance: 'error',
            autoDismiss: true,
          })
        } else if (error?.response?.status == 409) {
          addToast(error.response.data, {
            appearance: 'error',
            autoDismiss: true,
          })
        }
      })


  }


  const handleSave = () => {
    setShowConfirm(true);

  }

  const handleReset = () => {

    const updatedSelectedItems = {
      admin: {
        shadow_activity: setDefaultArray(groups['shadow_activity']),
        id_access_infra: setDefaultArray(groups['id_access_infra']),
        unauthorised_access: setDefaultArray(groups['unauthorised_access']),
        id_access_infra_default_view: true,
        shadow_activity_default_view: true,
        unauthorised_access_default_view: true,
      },
      analyst: {
        shadow_activity: setDefaultArray(groups['shadow_activity']),
        id_access_infra: setDefaultArray(groups['id_access_infra']),
        unauthorised_access: setDefaultArray(groups['unauthorised_access']),
        id_access_infra_default_view: true,
        shadow_activity_default_view: true,
        unauthorised_access_default_view: true,
      },
      incident_responder: {
        shadow_activity: setDefaultArray(groups['shadow_activity']),
        id_access_infra: setDefaultArray(groups['id_access_infra']),
        unauthorised_access: setDefaultArray(groups['unauthorised_access']),
        id_access_infra_default_view: true,
        shadow_activity_default_view: true,
        unauthorised_access_default_view: true,
      },
      security_operator: {
        shadow_activity: setDefaultArray(groups['shadow_activity']),
        id_access_infra: setDefaultArray(groups['id_access_infra']),
        unauthorised_access: setDefaultArray(groups['unauthorised_access']),
        id_access_infra_default_view: true,
        shadow_activity_default_view: true,
        unauthorised_access_default_view: true,
      }
    }



    // Set the updatedSelectedItems to state
    setSelectedItems(updatedSelectedItems);
    setWidgetsConfig(intialWidgetsConfig);
  }

  const getMessageJSX = () => {
    return (
      <>

        {<div className='font14  margintop10 marginbottom10'>
          <span> This action will affect the view configuration for modified role(s). Are you sure you want to proceed?  </span></div>}
      </>
    );
  }





  const copyConfiguration = (sourceRole) => {
    roles.forEach((targetRole) => {
      // Skip copying for the same role
      if (sourceRole.key === targetRole.key) return;

      setWidgetsConfig((prevConfig) => {
        const updatedConfig = { ...prevConfig };

        // Loop through all widgets
        options.forEach((widget) => {
          const widgetKey = widget.key;

          // Initialize the widget in updatedConfig if not already present
          if (!updatedConfig[widgetKey]) {
            updatedConfig[widgetKey] = {};
          }

          // Copy the settings from sourceRole to targetRole
          if (updatedConfig[widgetKey][sourceRole.key]) {
            updatedConfig[widgetKey][targetRole.key] = { ...updatedConfig[widgetKey][sourceRole.key] };
          }
        });

        return updatedConfig;
      });

      // Copy selectedItems configuration
      setSelectedItems((prevSelectedItems) => {
        const updatedSelectedItems = { ...prevSelectedItems };

        if (prevSelectedItems[sourceRole.key]) {
          updatedSelectedItems[targetRole.key] = { ...prevSelectedItems[sourceRole.key] };
        }

        return updatedSelectedItems;
      });
    });
  };




  return (
    <div className="widget-role-config-container">
      <h2 className="font18" style={{ float: 'none', marginBottom: '20px' }}>Dashboard & Posture View Settings per Role</h2>

      {/* Widget configuration for selected role hide this widget for ag grid for now*/}
      {(
        <div className="widget-config-table" style={{ display: 'none' }}
        >
          <table>
            <thead>
              <tr>
                <th style={{ textAlign: 'left' }}>Widget visibility/permissions</th>
                {roles.map((role) => (
                  <th style={{ width: '200px', position: 'relative' }}> {role.value}
                    {/* <button className="posture-column action_button" style={{position:'absolute', right:'10px', top:'10px',visibility:'visible'}}
                      ></button>*/}

                    <Tooltip
                      TransitionComponent={Fade}
                      TransitionProps={{ timeout: 0 }}
                      classes={{ tooltip: 'am-admin-action-content' }}
                      style={{
                        display: 'block', right: '10px',
                        position: 'absolute',
                        top: '10px'
                      }}
                      PopperProps={{ style: { zIndex: 999 } }}
                      title={<div >
                        <ul>
                          {
                            <li>
                              {/* <Link> Allow users with this roles to modify settings</Link> */}
                              <Link style={{ cursor: 'pointer' }} onClick={() => { copyConfiguration(role) }}> Copy this configuration to other roles</Link>
                            </li>

                          }
                        </ul>
                      </div>}>
                      <button className="action_button"></button>
                    </Tooltip>

                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {options.map((widget) => (
                <tr key={widget.key}>
                  <td style={{ textAlign: "left" }}>{widget.value}</td>
                  {roles.map((role) => {
                    const isChanged =
                      widgetsConfig[widget.key]?.[role.key]?.visibility !==
                      defaultWdgetsConfig[widget.key]?.[role.key]?.visibility || JSON.stringify(selectedItems[role.key]?.[widget.key]) !==
                      JSON.stringify(defaultSelectedItems[role.key]?.[widget.key]);

                    return (
                      <td
                        key={`${widget.key}-${role.key}`}
                        style={{ position: "relative" }}
                        className={isChanged ? "highlight-cell" : ""}
                      >
                        <div style={{ margin: "0px auto", width: "34px", position: "relative" }}>
                          <input
                            type="checkbox"
                            checked={widgetsConfig[widget.key]?.[role.key]?.visibility || false}
                            onChange={(e) =>
                              handleVisibilityChange(widget.key, role.key, e)
                            }
                            style={{ float: "left" }}
                          />
                          {["ID and Access Infrastructure", "Shadow Activity", "Unauthorized Access"].includes(
                            widget.value
                          ) && (
                              <>
                                <div
                                  className='gear-setting-icon setting-icon'
                                  style={{
                                    pointerEvents: widgetsConfig[widget.key]?.[role.key]?.visibility === false ? 'none' : 'auto', float: 'left',
                                    opacity: widgetsConfig[widget.key]?.[role.key]?.visibility === false ? 0.5 : 1
                                  }}
                                  title={`Configure View: ${widget.value}`}
                                  onClick={(event) => handleOpenModal(widget.value, role.value, event)}
                                ></div>
                                {/* Draggable Dialog Component */}
                                <Draggable
                                  position={controlledPosition}
                                  handle='.search-header-container'
                                  onStop={onControlledDragStop}
                                >
                                  <Dialog className="am-groupable-dialog" open={openModal === widget.value + '_' + role.value} slotProps={{
                                    backdrop: {
                                      onClick: (event) => event.stopPropagation(),
                                    },
                                  }}>
                                    <DialogTitle ref={dragContainerRef} className='search-header-container'>
                                      <span className="groupable-title">Settings - <span style={{ textTransform: 'uppercase' }}>{widget.value}</span> </span>
                                      <Box
                                        sx={{ float: "right", marginTop: "20px", right: '0', cursor: 'pointer' }}
                                        onClick={handleClose}
                                        className="popup-container-close-dashboard"
                                      >
                                      </Box>
                                    </DialogTitle>

                                    <DialogContent
                                      sx={{
                                        width: "500px",
                                        height: "400px",
                                        overflowX: "hidden",
                                      }}
                                    >
                                      <Box sx={{ marginBottom: '15px' }} className="sub-header">  Select Issue Types and Playbooks to display.  </Box>
                                      <AMMultiSelectGroup
                                        groups={groups}
                                        onSelect={(updatedItems) => handleSelect(role.key, widget.key, updatedItems)}
                                        handleClearBtn={handleClear}
                                        onDoneClick={handleDoneClick}
                                        onSearchKeyUp={handleOnSearchKeyUp}
                                        doneLabel="Save"
                                        selectedItems={selectedItems[role.key]}
                                        widId={widget.key}
                                        widgetData={responseData}
                                        renderGroupLabel={renderGroupLabel}
                                      />
                                    </DialogContent>
                                  </Dialog>
                                </Draggable>
                              </>
                            )}
                        </div>
                      </td>
                    );
                  })}
                </tr>
              ))}
            </tbody>

          </table>
        </div>
      )}

      <BaseAMAdminGrid
        columnDefs={Cols}
        modelType="clientSide"
        rowData={rowData}
        pagination={false}
        classes={{ gridContainerClass: 'widget-config-table' }}
      />

      <div
        className={"float_left btn_styled btn_gray save_btn"}
        onClick={handleReset}>
        Reset to default
      </div>

      <div className="float_right"><button type="button" className=" button_styled" onClick={handleSave}> Save</button></div>

      <AMGenericConfirmPopUp title={'Confirm Save'}
        showConfirm={showConfirm}
        buttons={popUpButtons} onCloseHandler={() => setShowConfirm(false)}
        onProceedHandler={handleOnSave}
      >{loading ? <div className='loader spinner'></div> : getMessageJSX()}
      </AMGenericConfirmPopUp>

    </div>
  );
};

export default AmopsRoleManagment;
