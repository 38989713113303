import React from "react";

const DeviationIdentityActivityView = ({ config: { deviation_identity_params }, params }: any) => {
    if (params?.isPlaybookListing) {
        return <li><span>Detect deviations in identity activity by evaluating access events against the following criteria:
            {deviation_identity_params?.geoLocationFlag && <> Unusual Geolocation (latitude, longitude)</>
                || deviation_identity_params?.cityCountryFlag && <> Unusual City or Country</>
                || deviation_identity_params?.deviceAccessFlag && <> Unusual Device</>
                || deviation_identity_params?.osAccessFlag && <> Unusual Operating System</>
                || deviation_identity_params?.browserAccessFlag && <> Unusual Browser</>
                || deviation_identity_params?.iPCidrFlag && <> Unusual IP Address</>
                || deviation_identity_params?.ispFlag && <> Unusual Internet Service Provider (ISP)</>
                || deviation_identity_params?.appByUserFlag && <> Unfamiliar Applications Accessed</>
                || deviation_identity_params?.hostnameAccessFlag && <> Unusual Hostnames </>
            }
        </span></li>
    } else {
        return <li>
            <span>Detect deviations in identity activity by evaluating access events against the following criteria:</span>
            <ul className='ml-1'>
                {
                    deviation_identity_params.geoLocationFlag &&
                    <li>Unusual Geolocation (latitude, longitude)</li>}
                {
                    deviation_identity_params?.cityCountryFlag &&
                    <li>Unusual City or Country</li>}
                {
                    deviation_identity_params?.deviceAccessFlag &&
                    <li>Unusual Device</li>}
                {
                    deviation_identity_params?.osAccessFlag &&
                    <li>Unusual Operating System</li>}
                {
                    deviation_identity_params?.browserAccessFlag &&
                    <li>Unusual Browser</li>}
                {
                    deviation_identity_params?.iPCidrFlag &&
                    <li>Unusual IP Address</li>}
                {
                    deviation_identity_params?.ispFlag &&
                    <li>Unusual Internet Service Provider (ISP)</li>}
                {
                    deviation_identity_params?.appByUserFlag &&
                    <li>Unfamiliar Applications Accessed</li>}
                {
                    deviation_identity_params?.hostnameAccessFlag &&
                    <li>Unusual Hostnames </li>}
            </ul>
        </li>
    }

}
export default DeviationIdentityActivityView;