import type { CustomCellRendererProps } from "@ag-grid-community/react";
import { Tooltip } from "@mui/material";
import React, { FunctionComponent } from 'react';
import { AMCountryFlag } from "../../../../components/core/AMCountryFlag/AMCountryFlag";
import InfoIcon from '@mui/icons-material/Info';
import { BasePostureActionMenu } from "../../ref/PostureActionMenus/BasePostureActionMenu";
import { IdentitiesObj, RulesDetailObj } from "../../../../types/response-types";
import { isIssueType } from "../../../../utils/util-methods";
import { PostureMenuItem, PostureMenuItemAction } from "../../ref/PostureActionMenus/PostureActionMenu";
import { IdentitiesActionMenu } from "../../constants/IDPostureMenu";


interface IdentityNameCellRendererProps extends CustomCellRendererProps {
    showAdditionalResult,
    handleMouseEnterHost,
    widgetData,
    fetchIdentities,
    items,
    handleMounseLeaveHost
}

export const IdentityNameCell: FunctionComponent<IdentityNameCellRendererProps> = ({ data, node,
    showAdditionalResult, handleMouseEnterHost,
    widgetData, fetchIdentities, items, handleMounseLeaveHost
}: IdentityNameCellRendererProps) => {
    const getIssueCount = (item: IdentitiesObj) => {
        return item.rules
            ? Object.values(item.rules).reduce(
                (prev: RulesDetailObj, i: RulesDetailObj) => ({
                    ...prev,
                    issue_count: prev.issue_count + i.issue_count
                }),
                {
                    issue_count: 0,
                    issue_flow_count: 0,
                    issue_risk: 4,
                    name: ''
                }
            ).issue_count
            : 0;
    };


    const getRules = (item: IdentitiesObj, actionType?: string) => {
        let rule = '';
        if (item?._id?.toLowerCase().includes('scanner') && widgetData) {
            const arr = [
                ...Object.values(widgetData.identity_rules_summary.rules)
            ];
            arr.map((i: any) => {
                rule += i.name + ',';
            });
        } else {
            Object.values(item.rules).map((i: RulesDetailObj) => {
                const isIssue = isIssueType(items, i.name);
                if (isIssue) {
                    if (actionType == 'exception') {

                        if (i.issue_count > 0 && i.entity_type === 'Identity') {
                            rule += i.name + ',';
                        }
                    } else {
                        if (i.issue_count > 0 && i.entity_type === 'Identity') {
                            rule += i.name + ',';
                        }
                    }
                }
            });
        }
        return rule.substring(0, rule.length - 1);
    };

    if (node.group) {
        return <></>
    }
    const item = data;
    const itemName: any = item._id.split(" on ")[1];
    let id_hostsList: any = [];
    if (item.host_detail) {
        item.host_detail.forEach((group: any) => {
            if (group.hostname !== "" && group.hostname !== undefined && group.hostname !== null) {
                if (item.host_detail.length === 1 && group.hostname?.toLowerCase() === "unknown") {
                    id_hostsList.push({
                        "hostname": group.hostname,
                        "city_name": group.city_name,
                        "country_code2": group.country_code2,
                        "country_name": group.country_name,
                        "state_name": group.state_name
                    })
                }
                else {
                    if (group.hostname?.toLowerCase() !== "unknown")
                        id_hostsList.push({
                            "hostname": group.hostname,
                            "city_name": group.city_name,
                            "country_code2": group.country_code2,
                            "country_name": group.country_name,
                            "state_name": group.state_name
                        })
                }
            }
        })
    }
    return node.group ? (
        <span>{node.key}</span> // Display the group key for group rows
    ) : <div style={window.matchMedia("(min-width: 2560px)").matches || !showAdditionalResult ? { width: "100%" } : { width: "100%" }} className='action-btn-cell copy_field'>
        <div className="copy_host_count">
            <span className='droplist'>
                    <Tooltip                        
                    classes={{ tooltip: 'posture-root-column-container scrollbar-container' }}
                    title={<div className="service_list2">

                        {item?.full_name?.toLowerCase() !== item?._id?.toLowerCase() ?
                            <div>
                                <h2 style={{ marginBottom: 0 }} className={(id_hostsList?.length === 0 ? 'no_border_bottom' : '')}>
                                    <span className="ellipsis_idassetname tooltip_ellipses" title={item.full_name?.toString()}>{item.full_name?.toString()}</span>
                                    <div className="copy_idassetname tooltip_copy_title" title="Copy this text to Clipboard" onClick={() => navigator.clipboard.writeText(item.full_name)}></div>
                                </h2>
                                <h2 style={{ marginTop: 0, paddingTop: '6px' }} className={(id_hostsList?.length === 0 ? 'no_border_bottom' : '')}>
                                    <span className="ellipsis_idassetname tooltip_ellipses" title={item._id?.toString()}>{item._id?.toString()}</span>
                                    <div className="copy_idassetname tooltip_copy_title" title="Copy this text to Clipboard" onClick={() => navigator.clipboard.writeText(item._id)}></div>
                                </h2>
                            </div>
                            : <h2 className={(id_hostsList?.length === 0 ? 'no_border_bottom' : '')}>
                                <span className="ellipsis_idassetname tooltip_ellipses" title={item._id?.toString()}>{item._id?.toString()}</span>
                                <div className="copy_idassetname tooltip_copy_title" title="Copy this text to Clipboard" onClick={() => navigator.clipboard.writeText(item._id)}></div>
                            </h2>}

                        {id_hostsList.length > 0 ?
                            <table>
                                <tr>
                                    <th className="align_left">Hosts</th>
                                </tr>
                                {id_hostsList.map((group: any, index: any) => {
                                    if (index < 5) {
                                        return (<tr key={group.hostname + '-' + index}
                                        >
                                            <td className="hostname_td align_left display-flex-column" onMouseOver={(e) => handleMouseEnterHost(index, group.hostname, e)} onMouseLeave={(e) => handleMounseLeaveHost(index, group.hostname, e)}>
                                                <div
                                                    style={{ display: 'flex' }}
                                                >
                                                    {group.country_code2 ?
                                                        <span className="flag_wrapper">
                                                            {group.country_name ?
                                                                <div className="flag_icon" style={{ position: 'relative' }}>
                                                                    <AMCountryFlag countryCode={group.country_code2} />
                                                                </div>
                                                                :
                                                                null}
                                                        </span> : <span className="flag_wrapper">
                                                            {group.country_name ?
                                                                <div className="location_icon">
                                                                </div>
                                                                :
                                                                <div className="flag_icon" style={{ position: 'relative' }}>
                                                                    <div className='no_flag'></div>
                                                                </div>}
                                                        </span>}
                                                    <span className="ellipsis_idassetname id-flag">{group.hostname}</span>
                                                    <div style={{
                                                        flex: 1
                                                    }}>
                                                        <div className="copy_idassetname" title="Copy this text to Clipboard"
                                                            style={{ position: 'relative', flex: 1, marginLeft: 0, float: 'right' }}
                                                            onClick={() => navigator.clipboard.writeText(group.hostname)}></div>
                                                    </div>

                                                </div>
                                                <div className={`hidden-block  paddingLeft20 host-name-list-${index}-${group.hostname}`} >
                                                    {group.hostname ? <li>
                                                        <strong>Hostname</strong>: {group.hostname}</li> : null}
                                                    {group.country_name ? <li><strong>Country</strong>: {group.country_name}</li> : null}
                                                    {group.state_name ? <li><strong>State</strong>: {group.state_name}</li> : null}
                                                    {group.city_name ? <li><strong>City</strong>: {group.city_name}</li> : null}
                                                    {group.ip ? <li><strong>Ip</strong>: {group.ip}</li> : null}
                                                </div>
                                            </td>
                                        </tr>);
                                    }
                                })}
                                {id_hostsList.length > 5 ?
                                    <tr>
                                        <td className="hostname_td align_left">
                                            <span className="ellipsis_idassetname id-flag">...</span>
                                        </td>
                                    </tr> : null}
                            </table>
                            : null}
                    </div>}>
                    <div>
                        <span className={(item.is_known_identity === false && item?.type === "User" && id_hostsList.length > 1) ?
                            "ellipsis_idassetname_host droplist" :
                            "ellipsis_idassetname  droplist"}>
                            {/* <span style={{ display: 'flex' }}> */}
                            {item._id.split(" on ")[0]} {itemName !== undefined ? " on " : ""}
                            <i style={{ marginRight: 2 }}>{itemName !== undefined ? itemName : ""}</i>
                            {/* </span> */}
                        </span>
                        <InfoIcon style={{ fontSize: 14, color: '#d4d8e1', marginTop: 2, marginLeft: 2, transform: 'skewX(-10deg)' }} />
                    </div>
                </Tooltip>

            </span>
            <div className="copy_idassetname copy_idassetname_id" title="Copy this text to Clipboard" onClick={() => navigator.clipboard.writeText(item._id)}></div>
            {
                getRules(item).length > 0 ?
                    <BasePostureActionMenu actionData={item}
                        menuItems={getRules(item).length > 0
                            ? IdentitiesActionMenu :
                            IdentitiesActionMenu.filter((item: PostureMenuItem) => item.action === PostureMenuItemAction.ADD_EX_COPWD_USR)
                        }
                        exceptionData={[`s_name:${item._id}`, `rule_name:` + getRules(item, 'exception')]} classes={"posture action_button"}
                        getContent={() => [`s_name:${item._id}`,
                        `rule_name:` + getRules(item, 'exception')]}
                        incContent={() => [`inc_count:${getIssueCount(item)}`, `s_name:${item._id}`, `rule_name:` + getRules(item)]}
                        closeIncQuery={`{"status":"Open","is_external":"false","s_name":"${item._id}"` + `,"rule_name":"` + getRules(item) + `"}`}
                        issueCount={getIssueCount(item)}
                        reloadList={fetchIdentities}
                        keyName={`identity-${item._id}`}
                    /> : null
            }
            {(item.is_known_identity === false && item?.type === "User" && id_hostsList.length > 1) ? <span className="host_count"><i>(on multiple hosts)</i></span> : null}
        </div>
    </div>;
};