import { MenuItem, Select } from '@mui/material';
import './account-takeover.scss';
import { BaseProps } from '../../constants/types';
import { AccountTakeoverState } from '../../../../types/playbooks-config';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import React from 'react';
import CheckboxAutocomplete from '../../../../components/core/AMAutoComplete/AMAutoComplete';
import { AMToolTipAd } from '../../../../components/core/AMTooltipAd/AMTooltipAd';
import InfoIcon from '@mui/icons-material/Info';

const options = ['miles', 'km'].map((i) => ({ key: i, value: i }))

const AccountTakerConfig = ({ parentState, isSavedOnce, getState, defaultState,
    formType, ruleType }: BaseProps<AccountTakeoverState>) => {
    const [state, setState] = useState<AccountTakeoverState>(defaultState);
    const { register, errors, control, getValues, setValue, reset } = useForm<AccountTakeoverState>();

    useEffect(() => {
        getState(state)
    })

    useEffect(() => {
        setState(defaultState);
        reset(defaultState)
    }, [defaultState]);

    useEffect(() => {
        if (isSavedOnce) {
            setState(parentState);
            reset(parentState);
        }
    }, [isSavedOnce]);

    const handleFormChange = () => {
        const s = getValues() as any;
        if (s.account_takeover_params) {
            s.account_takeover_params.displacementValue = s?.account_takeover_params?.displacementValue ?
                parseInt(s?.account_takeover_params?.displacementValue?.toString()) : null;
            s.account_takeover_params.distanceTraveledValue = s?.account_takeover_params?.distanceTraveledValue ?
                parseInt(s?.account_takeover_params?.distanceTraveledValue?.toString()) : null
        }

        setState(s)
    }

    const getItemLabel = (op) => {
        console.log(op)
        if (op?.hasOwnProperty('key')) {
            if (op?.key) {
                return op.value
            } else {
                return ''
            }
        }
        return op || ''
    }

    return <>
        <form action="/" onChange={handleFormChange}>
            <div className={"account-take-over-container " + (formType == 'view' ? ' disable-config-item' : '')}>
                <span className='playbook-config-title'
                >Detect suspicious access resulting from "Impossible Travel" by analyzing the following criteria:
                </span>
                <div className="account-take-over-flex-item">
                    <input type='checkbox'
                        checked={state?.account_takeover_params?.distanceTraveledFlag}
                        ref={register}
                        name='account_takeover_params.distanceTraveledFlag'
                    ></input>
                    <label className='top15 label-flex'>Unusual Geolocation (latitude, longitude)</label>
                    <AMToolTipAd
                        title={"Detect access originating from a new or unusual geolocation (latitude & longitude)"}>
                        <InfoIcon style={{ fontSize: 14, color: '#d4d8e1', marginTop: -10, marginLeft: 2, transform: 'skewX(-10deg)' }} />
                    </AMToolTipAd>
                </div>
                <div className='and-text-at'>AND</div>
                <div className="account-take-over-flex-item">
                    <input type='checkbox'
                        checked={state?.account_takeover_params?.displacementFlag}
                        name='account_takeover_params.displacementFlag' ref={register}></input>
                    <label className='top15 label-flex'>High Displacement (distance travelled per unit of time)</label>
                    <AMToolTipAd
                        title={"Detect and flag instances of extremely high speed of travel (distance per unit time)"}>
                        <InfoIcon style={{ fontSize: 14, color: '#d4d8e1', marginTop: -10, marginLeft: 2, transform: 'skewX(-10deg)' }} />
                    </AMToolTipAd>
                </div>
                <div className='and-text-at'>AND</div>
                <div className="account-take-over-flex-item">
                    <input type='checkbox' name='account_takeover_params.newIpRangeFlag'
                        checked={state?.account_takeover_params?.newIpRangeFlag}
                        ref={register}></input>
                    <label className='top6'>Unusual IP Address</label>
                    <AMToolTipAd
                        title={"Detect access originating from a new or unusual IP address"}>
                        <InfoIcon style={{ fontSize: 14, color: '#d4d8e1', marginTop: -10, marginLeft: 2, transform: 'skewX(-10deg)' }} />
                    </AMToolTipAd>
                </div>
                <div className='and-text-at'>AND</div>
                <div className="account-take-over-flex-item">
                    <input type='checkbox' name='account_takeover_params.newIspFlag' ref={register}
                        checked={state?.account_takeover_params?.newIspFlag}
                    ></input>
                    <label className='top6'>Unusual Internet Service Provider (ISP)</label>
                    <AMToolTipAd
                        title={"Detect access from a new or unusual Internet Service Provider (ISP)"}>
                        <InfoIcon style={{ fontSize: 14, color: '#d4d8e1', marginTop: -10, marginLeft: 2, transform: 'skewX(-10deg)' }} />
                    </AMToolTipAd>
                </div>
            </div>
        </form>
    </>
}

export default AccountTakerConfig;