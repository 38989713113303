import React from 'react';

export const SuspectedIDPBruteForceView = ({ config }) => {
    return (
        <>
            {config.dir_params.user_auth_fail_count_today && <li>
                {/* <strong>Parameters</strong> - */}
                <span>
                    An account will be marked as under brute-force attack when:
                </span>
                <ul className='ml-1'>
                    {config.dir_params.user_auth_fail_count_today && (
                        <li>
                            Number of authentication failures in the current{' '}
                            <strong>
                                {config.dir_params.param_durations[0].duration}
                            </strong>{' '}
                            for an account is more than{' '}
                            <strong>
                                {config.dir_params.user_auth_fail_count_today}
                            </strong>
                        </li>
                    )}
                </ul>
            </li>
            }
        </>
    );
};
