import React from 'react';

export const ADGoldenTicketView = ({ config }) => {
    return (
        <>
            {(config.dir_params.kb_ticket_max_age || config.dir_params.encryption_algo_checked) && <li>
                {/* <strong>Parameters</strong> - */}
                <span>
                    An AD will be marked as under AD Golden Ticket attack when:
                </span>
                <ul className='ml-1'>
                    {config.dir_params.kb_ticket_max_age && (
                        <li>
                            The same Kerberos ticket has been used for the current account for more than{' '}
                            <strong>
                                {config.dir_params.kb_ticket_max_age}
                            </strong>{' '} hrs
                        </li>
                    )}
                    {config?.dir_params?.encryption_algo_checked && <li><span>Kerberos service ticket requested with weak encryption</span></li>}
                </ul>
            </li>
            }
        </>
    );
};
