import { CustomCellRendererProps } from "@ag-grid-community/react";
import React, { FunctionComponent } from "react";
import { convertToCommaValue } from "../../../../utils/util-methods";
import { useHistory } from "react-router-dom";
import { usePostureStateContext } from "../../../../store/PostureStateContextProvider";
import { getTimeBasedSearchQueryParam } from "../../../../components/core/PostureFilter/posture-filter-utils";
import { PostureSearchTemplate } from "../../constants/PostureSearchTemplate";

interface IdentitySystemIdCellRendererProps extends CustomCellRendererProps {
    tags
}

export const IdentitySystemIdCell: FunctionComponent<IdentitySystemIdCellRendererProps> = ({ data, node,
    tags
}: IdentitySystemIdCellRendererProps) => {
    const item = data;
    const history = useHistory();
    const { PostureSearchState } = usePostureStateContext();
    
    const handleIdAccess = (
        identitiesid: any,
        server: string,
        domain: string,
        host_detail: any,
        isAsset: boolean
    ) => {
        let assetsRef = '';
        host_detail?.forEach((item: any) => {
            assetsRef += item.ip + '-' + item.port + ',';
        });
        const prevState = history?.location?.state as any;
        const prevUrl = history.location.pathname;
        const prevSearch = history.location.search;
        PostureSearchState.setIsAdSearchApplied(false);
        if (server.includes(',')) {
            server = `"${server}"`;
        }

        const timeSearchParam = getTimeBasedSearchQueryParam(PostureSearchTemplate, tags);
        let timeParam = "";
        if (timeSearchParam) {
            timeParam = "%2B" + encodeURIComponent(timeSearchParam)
        }

        history.push(
            '/posture/accesses?page=1&q=dir_name%3A' +
            encodeURIComponent(server) + timeParam +
            '&sort_by=identity&order_by=asc',
            {
                prevWidget: prevState?.breadcrumbId,
                breadcrumbId: 'PostureAccesses',
                prevUrl,
                prevSearch,
                customData: { path: 'identity', directory_name: identitiesid }
            }
        );
    };

    return <>{item.identity_count !== 0 ? (
        <div
            className='shadowbox num_flows'
            onClick={() => {
                handleIdAccess(
                    item.name?.toString(),
                    item.am_hostname,
                    item.domain,
                    item.host_detail,
                    item.is_asset
                );
            }}
            id={item._id?.toString()}
        >
            <span>
                {convertToCommaValue(
                    item.identity_count
                )}
            </span>
        </div>
    ) : (
        <div id={item._id?.toString()}>
            <span>
                {convertToCommaValue(
                    item.identity_count
                )}
            </span>
        </div>
    )}</>
}