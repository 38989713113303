import React, { useEffect, useRef, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { ArrayParam, NumberParam, StringParam, withDefault, withQueryParams } from 'use-query-params';
import { Api } from "../../components/Axios";
import { useToasts } from '../../components/core';
import './SensorDownload.scss'
import { GlobalConsts } from '../../GlobalConst';
import BaseAMAdminGrid from '../../components/core/AMTable/BaseAMAdminGrid';
import { ColDef, RowHeightParams } from '@ag-grid-community/core';
const { REACT_APP_HOST_IP } = process.env

interface Sensor {
    download_type: string;
    versions: string[];
    _id?: string
}

interface ApiResponse {
    data: Sensor[];
}

interface ComponentState {
    sensors: Sensor[];
    loading: boolean;
    error: string | null;
    selectedType: string;
    selectedVersion: string;
}

const initialState: ComponentState = {
    sensors: [],
    loading: true,
    error: null,
    selectedType: '',
    selectedVersion: '',
};

interface DeploymentData {
    sensorType: string;
    url: string;
}

const deploymentGuides: DeploymentData[] = [
    {
        sensorType: "AD Sensor",
        url: `${GlobalConsts.DEPLOYMENT_LINK}/installing-active-directory-sensor?${GlobalConsts.DOC_TOKEN}`,
    },
    {
        sensorType: "Flow Sensor",
        url: `${GlobalConsts.DEPLOYMENT_LINK}/installing-a-flow-sensor?${GlobalConsts.DOC_TOKEN}`,
    },
    {
        sensorType: "Enrichment Sensor",
        url: `${GlobalConsts.DEPLOYMENT_LINK}/installing-an-enrichment-sensor?${GlobalConsts.DOC_TOKEN}`,
    }
];

const handleDownload = async (downloadType: string, version: string) => {
    const datas = window.localStorage.getItem('user');
    if (datas !== null) {
        const user = JSON.parse(datas);
        const downloadUrl = `${REACT_APP_HOST_IP}/api/download?download_type=${encodeURIComponent(downloadType)}&version=${encodeURIComponent(version)}&auth_token=${encodeURIComponent(user.token)}`;
        window.open(downloadUrl);

    } else {
        console.error('Please select both Sensor Type and Version before downloading.');
    }
};


const handleGuideClick = (downloadType: string) => {
    const selectedGuide = deploymentGuides.find((guide) => guide.sensorType.toLowerCase() === downloadType.toLowerCase());

    if (selectedGuide) {
        window.open(selectedGuide.url, '_blank');
    }
};


const DownloadListColumns: Array<ColDef> = [
    {
        headerName: 'Download Type',
        field: 'download_type',
        cellClass: 'align_left width380',
        sortable: false
    },
    {
        headerName: 'Download Links',
        field: 'versions',
        cellClass: 'align_left width380 line-height-25',
        sortable: false,
        autoHeight: true,
        cellRenderer: ({ data }) => {

            return <>{data?.versions.map((version: string, index: number) => (
                <React.Fragment key={version}>
                    {/*eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a href="#"
                        onClick={(e) => {
                            e.preventDefault();
                            handleDownload(data.download_type, version);
                        }}
                        style={{ cursor: 'pointer', textDecoration: 'underline' }}
                        className='link-text'
                    >
                        {version}
                    </a>
                    {index < data.versions.length - 1 && <br />}
                </React.Fragment>
            ))}</>
        }
    },
    {
        headerName: "Deployment Guide",
        sortable: false,
        cellRenderer: ({ data }) => {
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            return <a
                href="#"
                onClick={(e) => {
                    e.preventDefault();
                    handleGuideClick(sensor.download_type);
                }}
                style={{ cursor: 'pointer', textDecoration: 'underline' }}
                className='link-text'
            >
                {data?.download_type} Deployment Guide
            </a>
        }
    }
]

const SensorDownload: React.FC = () => {
    const [listData, setListData] = useState<ComponentState>(initialState);
    const { addToast } = useToasts();
    const childRef = useRef();


    useEffect(() => {
        fetchDownloadList()
    }, []);

    const fetchDownloadList = (queryParams?, paramGrid?) => {
        if (paramGrid) {
            Api.get('/download_list')
                .then((res: ApiResponse) => {
                    setListData({
                        sensors: res.data,
                        loading: false,
                        error: null,
                        selectedType: '',
                        selectedVersion: '',
                    });
                    if (paramGrid) {
                        res.data.forEach((i: Sensor) => i._id = i.download_type)
                        paramGrid.success({
                            rowData: res.data,
                            rowCount: res.data.length
                        });
                        if (childRef?.current?.api) {
                            childRef.current.api.hideOverlay();
                            if (res.data.length === 0) {
                                childRef.current.api.showNoRowsOverlay();
                            }
                        }
                    }
                })
                .catch((error: any) => {
                    if (error.response && error.response.status === 500) {
                        setListData({
                            sensors: [],
                            loading: false,
                            error: 'Internal Server Error',
                            selectedType: '',
                            selectedVersion: '',
                        });
                        addToast("Sorry, something went wrong there, try again.", {
                            appearance: 'error',
                            autoDismiss: true,
                        })
                    } else {
                        setListData({
                            sensors: [],
                            loading: false,
                            error: 'Error fetching data',
                            selectedType: '',
                            selectedVersion: '',
                        });
                        addToast("Error occurred while fetching download information.", {
                            appearance: 'error',
                            autoDismiss: true,
                        })
                    }
                });
        } else {
            childRef?.current?.api?.onFilterChanged()
        }
    }

    const getRowHeightFn = (params: RowHeightParams) => {
        console.log(params)
        if (params?.data) {
            return params.data.versions.length * 27
        }
        return 40
    }



    const renderTable = () => {
        return <>
            <div className="page_title_area">
                <h2 className='font18'>Downloads</h2>
            </div>
            <BaseAMAdminGrid
                columnDefs={DownloadListColumns}
                fetchEntitiesFn={fetchDownloadList}
                gridRef={childRef}
                getRowHeightFn={getRowHeightFn}
                pagination={false}
            />
            {/* <div className="box_grid_layout_sensor_download"> */}
            <div>
                {/*  <table id="table" className="ticket_tb sticky_table_top">
                    <thead>
                        <tr>
                            <th><span className='align_left float_left'>Download Type</span></th>
                            <th><span className='align_left float_left'>Download Links</span></th>
                            <th><span className='align_left float_left'>Deployment Guide</span></th>
                        </tr>
                    </thead>
                    <tbody>
                        {listData && listData.sensors.map((sensor: Sensor, index) => (


                            <tr key={sensor?.download_type}>
                                <td className="align_left">
                                    {sensor?.download_type}
                                </td>
                                <td>
                                    {sensor?.versions.map((version: string, index: number) => (
                                        <React.Fragment key={version}>
                                            <a
                                                href="#"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    handleDownload(sensor.download_type, version);
                                                }}
                                                style={{ cursor: 'pointer', textDecoration: 'underline' }}
                                                className='link-text'
                                            >
                                                {version}
                                            </a>
                                            {index < sensor.versions.length - 1 && <br />}
                                        </React.Fragment>
                                    ))}
                                </td>

                                <td>
                                    <a
                                        href="#"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            handleGuideClick(sensor.download_type);
                                        }}
                                        style={{ cursor: 'pointer', textDecoration: 'underline' }}
                                        className='link-text'
                                    >
                                        {sensor?.download_type} Deployment Guide
                                    </a>
                                </td>

                            </tr>
                        ))}
                    </tbody>
                </table> */}
            </div>
        </>
    }

    return (
        <div className="downloads-container">
            {renderTable()}
        </div>
    )

}

export default withQueryParams({
    filters: withDefault(ArrayParam, []),
    launchApp: StringParam
}, SensorDownload)