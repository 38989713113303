import React, { useState } from "react";
import { PlaybookImportWarning } from "./PlaybookImportWarning";
import './PlaybookImportExport.scss'

export const PlaybookImportExport = ({ onPlaybookImport }) => {
    const [showWarning, setShowWarning] = useState(false);
    const fileInputRef = React.createRef();

    function validateRules(rules) {
        return null;
    }

    const handleFileChange = (event) => {
        setShowWarning(false);
        const file = event.target.files[0];
        event.target.value = null;
        if (file && file.type === 'application/json') {
            const reader = new FileReader();

            reader.onload = (e) => {
                try {
                    const loadedData = JSON.parse(e.target.result);
                    const err = validateRules(loadedData.data);
                    if (err !== null) {
                        // setErrorMsg(err);
                        // setIsFormValid(false);
                    } else {
                        // setValue('filtersArray', loadedData);
                        // setIsFormValid(true);
                        onPlaybookImport(loadedData.data)
                    }
                } catch (error) {
                    // setErrorMsg('Error parsing JSON file.');
                } finally {
                    // setTimeout(() => setErrorMsg(''), 10000);
                }
            };
            reader.readAsText(file);
        } else {
            /*  setErrorMsg('Please select a valid JSON file.');
             setTimeout(() => setErrorMsg(''), 10000); */
        }
    };

    const handleYesImport = () => {
        fileInputRef?.current?.click();
    };

    const triggerFileInput = () => {
        handleYesImport()
    };


    return (
        <>
            {' '}
            <div className='import-export-playbook-container'>
                <input
                    type='file'
                    accept='application/json'
                    onChange={handleFileChange}
                    ref={fileInputRef} // Attach the ref to the input
                    style={{ display: 'none' }} // Hide the input element
                />
                <div
                    className='import-export-btn-filter '
                    title='Import Playbook'
                    onClick={triggerFileInput}
                >
                    <div className='import_search_icon' />
                    <div>Import</div>
                </div>
            </div>
        </>)
}