import { Checkbox, ListItemText, MenuItem, OutlinedInput, Select, SelectChangeEvent } from '@mui/material';
import { AxiosError } from 'axios';
import { userInfo } from 'os';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import Popup from 'reactjs-popup';
import { Api } from '../../../../components/Axios/axios';
import { useToasts } from '../../../../components/core';
import { AMCheckbox } from '../../../../components/core/AMCheckbox/AMCheckbox';
import { UserConfigState, UserData } from '../../../../types/response-types';
import { AdminNotify } from '../../constants/AdminNotify';
import CheckboxAutocomplete from '../../../../components/core/AMAutoComplete/AMAutoComplete';

type Props = {
    openFlag: boolean | undefined,
    updateParentState: (state: any[] | undefined, adminType: any) => void,
    parentState: any | undefined,
    ruleType?: any,
    closeHandler?(): void,
    formType?: string
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const NotifyAdminConfig = ({ openFlag, updateParentState, parentState, ruleType, closeHandler, formType }: Props) => {

    const [isOpen, setIsOpen] = useState<boolean | undefined>(openFlag);
    let history = useHistory();

    const [state, setState] = useState<any>(parentState);

    const [option, setOption] = useState<JSX.Element[] | undefined>();

    const [error, setError] = useState<string | undefined>();
    const [adminList, setAdminList] = useState([]);

    let { addToast } = useToasts();
    const [adminValues, setAdminValues] = useState<any>([]);
    const [allAdmins, setAllAdmins] = useState<any>([]);
    const [notifyTypeValue, setNotifyTypeValue] = useState(0);
    useEffect(() => {
        setIsOpen(openFlag);
    }, [openFlag])

    const handleClose = () => {
        if (closeHandler) {
            closeHandler();
        }
    }

    const handleSave = () => {
        if (validateData()) {
            setIsOpen(false);
            updateParentState(state.admin_ids, AdminNotify(ruleType) ? notifyTypeValue : null);
        }
    }


    const isAmopsUser = (userEmail: string): boolean => {
        if (userEmail === "" || userEmail === undefined || userEmail === null) return false;
        return userEmail.toString().startsWith("amops@") && userEmail.toString().endsWith(".authmind");
    }

    const getItemLabel = (op) => {
        console.log(op)
        if (op?.hasOwnProperty('key')) {
            if (op?.key || op.key == 0) {
                return op.value
            } else {
                return ''
            }
        }
        return op || ''
    }

    const fetchOptions = async () => {
        Api.get("/users", { params: { rpp: 200 } })
            .then(res => {
                if (res.status === 200) {
                    let options = res.data.result.sort((a: any, b: any) => {
                        if (a.name.toLowerCase() < b.name.toLowerCase()) {
                            return -1;
                        }
                        if (a.name.toLowerCase() > b.name.toLowerCase()) {
                            return 1;
                        }
                        return 0
                    }
                    )
                    const op = options.map((user: UserData) => {
                        return {
                            key: user._id, value: user.name
                        }
                    })
                    options = options.map((user: UserData) => {
                        return (
                            <MenuItem key={user._id} value={user._id}
                            >
                                <AMCheckbox checked={adminValues.findIndex((i: string) => i === user._id) >= 0} />
                                <ListItemText primary={user.name}
                                />
                            </MenuItem>
                        )
                    })
                    setOption(options);
                    const data = res.data.result.filter((item: UserData) => { return !item.is_amops });
                    setAllAdmins(data);
                    if (parentState && parentState.admin_ids) setAdminValues(parentState.admin_ids);
                }
            })
            .catch((err: AxiosError) => {
                if (err.response?.status === 401) {
                    window.location.href = '/login'
                }
                else {
                    addToast("Sorry, something went wrong there, try again.", {
                        appearance: 'error',
                        autoDismiss: true,
                    })
                }
            });
    }

    useEffect(() => {
        fetchOptions();
        if (parentState?.notify_admins_option) setNotifyTypeValue(parentState.notify_admins_option);
    }, [])

    const handleChange = (e: SelectChangeEvent<any>, config) => {
        setError(undefined);
        const list = config.map((i) => i.key)
        setAdminValues(list);
        setState({ ...state, admin_ids: list });
    }

    const validateData = () => {
        if (state === undefined || state?.length === 0 || state?.admin_ids?.length == 0) {
            setError("Admin");
            return false;
        }
        return true;
    }

    const renderValue = (selected: any): any => {
        let selectedString = '';
        if (selected && selected.length > 0) {
            selected?.forEach((i: string) => {
                const o = allAdmins.find((item: UserData) => item._id == i) as UserData;
                if (o) {
                    selectedString += o.name + '; ';
                }
            })
        }
        return selectedString.length > 0 ? selectedString : 'Select Admins';
    }

    const handleNotifyChange = (item: any, config) => {
        setNotifyTypeValue(config[0].key);
    }

    const renderNotifyValue = () => {
        return AdminNotify(ruleType).filter((item: any) => item.type == notifyTypeValue)[0]?.value || '';
    }

    const getItemValue = (i) => {
        const item = allAdmins.filter((ite) => ite._id == i)[0];
        if (item) {
            return item.name
        }
        return ''
    }

    const getNotifyAdminValue = (it) => {
        const item = AdminNotify(ruleType).filter((i) => i.key == it)[0];
        if (item) {
            return item.value
        }
        return ''
    }

    return (
        <>
            <Popup
                closeOnDocumentClick={false}
                overlayStyle={{ zIndex: 15001, background: "rgba(227, 242, 253, .6)" }}
                open={isOpen}
                closeOnEscape={false}
                modal
            //lockScroll
            >
                <div className="modal">
                    <div className="close" onClick={handleClose}></div>
                    <div className="header">Notify Admins</div>
                    <div className="content">
                        <label style={{ width: '100%' }} className='notify-admin-label display_flex_center'>
                            <span style={{ width: '40%' }}>Administrators to be notified*:</span>
                            <CheckboxAutocomplete
                                options={allAdmins.map((i) => ({ key: i._id, value: i.name }))}
                                onChange={handleChange}
                                classes={{ root: 'notify-admin-sel' }}
                                getItemLabel={getItemLabel}
                                multiple={true}
                                value={adminValues.map((i) => ({ key: i, value: getItemValue(i) }))}
                                disabled={['view'].includes(formType || '')}
                            />
                            <div className="clrBoth"></div>
                        </label>
                        {AdminNotify(ruleType) ?
                            <label style={{ width: '100%' }} className='notify-admin-type margintop20 display_flex_center paddingTop12'>
                                <span style={{ width: '40%' }}>When to notify:</span>
                                <CheckboxAutocomplete
                                    options={AdminNotify(ruleType)}
                                    value={{ key: notifyTypeValue, value: getNotifyAdminValue(notifyTypeValue) }}
                                    onChange={handleNotifyChange}
                                    getItemLabel={getItemLabel}
                                    multiple={false}
                                    hideClearBtn={true}
                                    classes={{ root: 'notify-admin-sel' }}
                                    disabled={['view'].includes(formType || '')}
                                />
                                <div className="clrBoth"></div>
                            </label> : null
                        }
                    </div>
                    {error ? <span className="error margintop10 marginleft20">* {error} is required</span> : null}
                    <div className="shadow_footer fl"></div>
                    {
                        formType != 'view' && <div className="popup_footer fl">
                            <div className="policy_save_button" style={{ margin: "0 auto", float: "none" }} onClick={handleSave}>Save</div>
                        </div>}
                </div>
            </Popup>
        </>
    )
}

export default React.memo(NotifyAdminConfig);