import React, { useEffect, useState } from 'react';
import { DeploymentGuides } from '../constants/Constants';
import { GlobalConsts } from '../../../GlobalConst';

export const NetflowQRadar = ({
    collectorName,
    collectionMethodData,
    showHeader = true
}) => {
    const [configureStatement, setConfigureStatement] = useState('');
    const deploymentGuides = [
        {
            url: `${GlobalConsts.DEPLOYMENT_LINK}/netflow-logs-collector?${GlobalConsts.DOC_TOKEN}`,
            "collector_data_source": "Generic Netflow & IPFIX Traffic"
        },
    ]

    useEffect(() => {
        if (collectionMethodData.is_direct && collectionMethodData.label.includes("Cloud Direct")) {
            setConfigureStatement(`Configure Generic Netflow & IPFIX Traffic via QRadar`)
        } else {
            setConfigureStatement(`Configure Generic Netflow & IPFIX Traffic to ${collectionMethodData.flow_sensor_name} (${collectionMethodData.flow_sensor_ip}) on UDP Port ${collectionMethodData.flow_sensor_port} via QRadar`)
        }
    }, [collectionMethodData])


    return (
        <div className='collector-configuration-vendor-container'>
            <div className={`form_sub_section`}>
                <div className='form_sub_section_header'>
                    {collectorName} Configuration:
                </div>
                <div className='form_sub_section_content'>
                    <div className='sensor-flex-container'>
                        <div className='sensor-flex-container-item'>
                            <div className='syslog-viewtext'>{configureStatement}
                                <a className="link-text" href={deploymentGuides.find(item => item.collector_data_source === collectorName)?.url} target="_blank" style={{ paddingLeft: "5px" }}>Learn More</a> </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};
