import React from "react";
import {
    WeakPasswordConfigView, CompromisedPasswordConfigView, CompromisedUserConfigView,
    ShadowIdentityConfig, SpInboundAccessConfigView, SpOutboundAccessConfigView, HashQualityConfigView,
    HashSecurityConfigView, ProtocolQualityConfigView, AccessToUnauthCountriesView, DeviationInDailyAssetView, SuspectedDirectoryBotView,
    NTLMRelayAttackView, SuspectedBruteForceView, SuspectedPasswordSprayView, EnumADAdminView, UnauthLoginTimeView, UnauthLoginDeviceView,
    SuspAttackExpiredAccView, EnumAdUserView, SuspAttackDisaledAccView, SuspAttackLockedAccView, MfaConfigView,
    ShadowIdentitySystemConfigView, AccountTakeoverConfigview, SuspectedAccessTokenSharingView
} from "../rules-config";
import { RuleType } from "./Constants";
import DeviationIdentityActivityView from "../rules-config/account-takeover-config/deviation-identity-act-view";
import UnusualUserAccessView from "../rules-config/account-takeover-config/unsual-user-access-view";
import { SuspectedIDPBruteForce } from "../rules-config/directory-config/susp-idp-brute-force";
import { SuspectedIDPBruteForceView } from "../rules-config/directory-config/suspected-idp-brute-force-view";
import { SuspectedADBruteForceView } from "../rules-config/directory-config/suspected-ad-brute-force-view";
import { ADGoldenTicketView } from "../rules-config/directory-config/ad-golden-ticket-view";


export const loadDefaultPlaybookViewOnly = (ruleType: string, props: any, params: any) => {
    return <>{ruleType == RuleType.LACK_OF_MFA && <MfaConfigView {...props} />}
        {ruleType === RuleType.WEAK_PASSWORD && <WeakPasswordConfigView  {...props} />}
        {ruleType === RuleType.COMPROMISED_PASSWORD && <CompromisedPasswordConfigView {...props} />}
        {ruleType === RuleType.COMPROMISED_USER && <CompromisedUserConfigView {...props} />}
        {/* {ruleType === RuleType.SHADOW_INDENTITIES && <ShadowIdentityConfig {...props} />} */}
        {ruleType === RuleType.SUSPICIOUS_INBOUND_ACCESS && <SpInboundAccessConfigView {...props} />}
        {ruleType === RuleType.SUSPICIOUS_OUTBOUND_ACCESS && <SpOutboundAccessConfigView {...props} />}
        {ruleType === RuleType.AUTH_HASH_QUALITY && <HashQualityConfigView {...props} />}
        {ruleType === RuleType.AUTH_HASH_SECURITY && <HashSecurityConfigView {...props} />}
        {ruleType === RuleType.AUTH_PROTOCOL_QUALITY && <ProtocolQualityConfigView {...props} />}
        {ruleType === RuleType.ACCESS_TO_UNAUTH_COUNTRIES && <AccessToUnauthCountriesView {...props} ruleType={ruleType} />}
        {ruleType === RuleType.ACCESS_FROM_UNAUTH_COUNTRIES && <AccessToUnauthCountriesView  {...props} ruleType={ruleType} />}
        {ruleType === RuleType.DEVIATION_IN_DAILY_ASSET && <DeviationInDailyAssetView {...props} />}
        {ruleType === RuleType.SUSPECTED_DIR_BOT && <SuspectedDirectoryBotView {...props} />}
        {ruleType === RuleType.SUSPECTED_BRUTE_FORCE && <SuspectedBruteForceView {...props} />}
        {ruleType === RuleType.SUSPECTED_PASS_SPRAY && <SuspectedPasswordSprayView {...props} />}
        {ruleType === RuleType.NTLM_RELAY_ATTACK && <NTLMRelayAttackView {...props} />}
        {ruleType === RuleType.ENUM_AD_ADMINS && <EnumADAdminView {...props} />}
        {ruleType === RuleType.UNAUTH_LOGIN_TIME && <UnauthLoginTimeView {...props} />}
        {ruleType === RuleType.UNAUTH_LOGIN_DEVICE && <UnauthLoginDeviceView {...props} />}
        {ruleType === RuleType.SUSPECTED_ATT_EXP_ACC && <SuspAttackExpiredAccView {...props} />}
        {ruleType === RuleType.ENUM_AD_USERS && <EnumAdUserView {...props} />}
        {ruleType === RuleType.SUSPECTED_ATT_DIS_ACC && <SuspAttackDisaledAccView {...props} />}
        {ruleType === RuleType.SUSPECTED_ATT_LOCK_ACC && <SuspAttackLockedAccView {...props} />}
        {ruleType === RuleType.ACCOUNT_TAKEOVER && <AccountTakeoverConfigview {...{ ...props, params }} />}
        {ruleType === RuleType.SHADOW_DIRECTORY && <ShadowIdentitySystemConfigView {...{ ...props, params }} />}
        {ruleType === RuleType.SUSPECTED_ACCESS_TOKEN && <SuspectedAccessTokenSharingView {...{ ...props, params }} />}
        {ruleType === RuleType.DEVIATION_IDENTITY_ACTIVITY && <DeviationIdentityActivityView {...{ ...props, params }} />}
        {ruleType === RuleType.UNUSUAL_USER_ACCESS && <UnusualUserAccessView {...{ ...props, params }} />}
        {ruleType === RuleType.SUSPECTED_IDP_BRUTE_FORCE && <SuspectedIDPBruteForceView {...{ ...props, params }} />}
        {ruleType === RuleType.SUSPECTED_AD_BRUTE_FORCE && <SuspectedADBruteForceView {...{ ...props, params }} />}
        {ruleType === RuleType.AD_GOLDEN_TICKET && <ADGoldenTicketView {...{ ...props, params }} />}
    </>
}