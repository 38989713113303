import React, { useState, useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form'
import {
    withQueryParams,
    StringParam,
    NumberParam,
    ArrayParam,
    withDefault,
} from 'use-query-params'
import { useHistory, Link } from "react-router-dom"
import SlidingPanel, { PanelType } from 'react-sliding-side-panel'
import 'react-sliding-side-panel/lib/index.css'
import { Api } from '../../components/Axios'
import { AddIntermediary, EditPanel } from './sub'
import ReactPaginate from 'react-paginate'
import { useToasts } from '../../components/core';
import { ActionMenu } from '../../theme/ActionMenu'
import Popup from 'reactjs-popup';
import purify from 'dompurify';
import { useAuthDetails } from '../../components/Authorization'
import ModalPopup from '../../components/core/AMModalPopup/ModalPopup'
import BaseAMAdminGrid from '../../components/core/AMTable/BaseAMAdminGrid'
import { ColDef } from '@ag-grid-community/core'
import { ITableAction } from '../../components/core/AMTable/AMAdminTable'
import { useBaseAMGrid } from '../../components/core/AMTable/useBaseAMGrid'
// import './listing.css'

type Search = {
    query?: string;
}

type Listing = {
    _id: string;
    name: string;
    fqdn: string;
    ips?: [];
    sensor_id: number;
    internally_added?: boolean
}

type Result = {
    total: number;
    result: Listing[];
    rpp: number;
    sort_by: string;
    order_by: string;
}

type ActionType = {
    type?: string;
    id?: string;
}

interface Params {
    rpp?: number;
    sort_by?: string;
    order_by?: string;
    q?: string;
    page?: number;
}


const IntermediaryTableColumns: ColDef<Listing>[] = [
    {
        headerName: 'Name',
        field: 'name',
        cellClass: "align_left ellipsis",
        sortable: true,
        initialSort: "asc"
    },
    {
        headerName: 'IP Addresses/Host Name/FQDN',
        field: 'ips',
        sortable: true,
        autoHeight: true,
        cellClass: "align_left",
        cellRenderer: ({ data }) => {
            return <div dangerouslySetInnerHTML={{ __html: purify.sanitize(data.ips?.join('<br />')) }} />
        }
    }
];

const InterMediaryTableActions: ITableAction<any>[] = [
    {
        actionId: "edit",
        actionLabel: "Edit",
    },
    {
        actionId: "delete",
        actionLabel: "Delete",
    }
];

const Groups = ({ query, setQuery }: any) => {
    const { register, handleSubmit } = useForm<Search>()
    const { authDetails } = useAuthDetails()!;
    const { q: q, page: page_number, rpp: record_per_page, order_by: order, sort_by: sort } = query
    const [actionType, setActionType] = useState<ActionType>()
    const [openPanel, setOpenPanel] = React.useState<boolean>(false)
    const [panelType, setPanelType] = React.useState<PanelType>('right')
    const [panelSize, setPanelSize] = React.useState<number>(30)
    const [panelTitle, setPanelTitle] = useState<string>("")
    const [showGreenTick, setShowGreenTick] = useState<Boolean>(false)
    const [showGrayAction, setShowGrayAction] = useState<Boolean>(false)
    const [result, setResult] = useState<Result>()
    const { addToast } = useToasts()

    let p: Params = {
        q: q,
        page: page_number,
        rpp: record_per_page || 100,
        order_by: order,
        sort_by: sort
    }

    const [nameSortDir, setNameSortDir] = useState<string | undefined>("tablesort_down");
    const [sensorSortDir, setSensorSortDir] = useState<string | undefined>("tablesort_down");
    const childRef = useRef();
    const { refreshGrid } = useBaseAMGrid(childRef)
    const highlightSortDir = () => {
        if (query.sort_by === "name" && query.order_by === "asc") {
            setNameSortDir("tablesort_up_selected")
        } else if (query.sort_by === "name" && query.order_by === "desc") {
            setNameSortDir("tablesort_down_selected")
        } else if (query.sort_by === "sensor" && query.order_by === "asc") {
            setSensorSortDir("tablesort_up_selected")
        } else if (query.sort_by === "sensor" && query.order_by === "desc") {
            setSensorSortDir("tablesort_down_selected")
        }
    }

    useEffect(() => {
        highlightSortDir();
    }, [query])

    useEffect(() => {
        Listing()
    }, [query])

    const deleteConfirm = () => {
        const headers = { 'Operation': 'DELETE' }
        Api.post('/intermediary/' + actionType?.id, "", { headers: headers })
            .then((res: { data: any }) => {
                addToast("Deleted successfully.", {
                    appearance: 'success',
                    autoDismiss: true,
                })
                p.page = 1
                setQuery(p)
                refreshGrid()
                closeConfirm()
            })
            .catch((error: any) => {
            })
    }

    useEffect(() => {
        switch (actionType?.type) {
            case 'add':
                setPanelTitle("Add Known VPN/Firewalls/Gateways/NAT")
                setOpenPanel(true)
                return

            case 'edit':
                setPanelTitle("Edit Known VPN/Firewalls/Gateways/NAT")
                setOpenPanel(true)
                return

            case 'delete':
                setOpenConfirm(true)
                return

            default:
                return
        }

    }, [actionType])

    const onSubmit = (data: any) => {
        p.q = data.query;
        p.page = 1
        setQuery(p)
    }

    const [openConfirm, setOpenConfirm] = useState(false);
    const closeConfirm = () => setOpenConfirm(false);

    const RightPanel = () => {
        return (
            <SlidingPanel
                type={panelType}
                isOpen={openPanel}
                size={panelSize}
                panelClassName="sidepane fix_width"
            >
                <div className="pane_container">
                    <div className="pane_heading" title={panelTitle}>{panelTitle}</div>
                    <div className="pane_close" onClick={() => setOpenPanel(false)}></div>
                    <div className="hr_ruler"></div>
                    <div className="panel_body">
                        {actionType?.type === "add" &&
                            <AddIntermediary AfterSensorOperation={AfterSensorOperation} />
                        }

                        {actionType?.type === "edit" &&
                            <EditPanel id={actionType.id} AfterSensorOperation={AfterSensorOperation} />
                        }
                    </div>
                </div>
            </SlidingPanel>
        )
    }

    const renderPopup = () => {
        return (
            <ModalPopup
                onCloseHandler={() => setOpenPanel(false)}
                title={panelTitle}
                isOpen={openPanel}
            >
                {actionType?.type === "add" &&
                    <AddIntermediary AfterSensorOperation={AfterSensorOperation} />
                }

                {actionType?.type === "edit" &&
                    <EditPanel id={actionType.id} AfterSensorOperation={AfterSensorOperation} />
                }

            </ModalPopup>
        )
    }

    const Listing = (queryParams?, paramGrid?) => {
        if (paramGrid)
            Api.get('/intermediary', {
                params: queryParams
            })
                .then((res: { data: any }) => {
                    setResult(res.data)
                    if (paramGrid) {
                        paramGrid.success({
                            rowData: res.data.result,
                            rowCount: res.data.total
                        });
                        if (childRef?.current?.api) {
                            childRef.current.api.hideOverlay();
                            if (res.data.total === 0) {
                                childRef.current.api.showNoRowsOverlay();
                            }
                        }
                    }
                })
                .catch((error: any) => {
                })
    }

    InterMediaryTableActions[0].actionCallback = (data?: Listing | any) => {
        setActionType({
            id: data._id,
            type: 'edit'
        })
    }

    InterMediaryTableActions[1].actionCallback = (data?: Listing | any) => {
        setActionType({
            id: data._id,
            type: 'delete'
        })
    }


    const customActionCellRender = (data: Listing, tableAction: Array<ITableAction<any>>) => {
        if (data.internally_added) {
            return <div title="Actions are disabled" className="action_button_gray"></div>
        }
        return null
    }



    const Action = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        setActionType({
            id: e.currentTarget.id,
            type: e.currentTarget.dataset.action
        })
        e.preventDefault()
    }

    const Paginate = (selectedPage: any) => {
        p.page = selectedPage.selected === 0 ? 1 : selectedPage.selected + 1
        setQuery(p)
    }

    const AfterSensorOperation = (action: string) => {
        setOpenPanel(false)
        // console.log("I tek")
        if (action === 'add') {
            p.page = 1
            p.q = ""
            setQuery(p)
            refreshGrid()
        } else {
            refreshGrid()
        }

    }

    const LoadSorting = (field: string) => {
        if (p.sort_by === field) {
            p.order_by = p.order_by === 'asc' ? 'desc' : 'asc'
            setQuery(p)
        } else {
            p.sort_by = field
            p.order_by = 'desc'
            setQuery(p)
        }
    };

    const SortBy = (name: string) => {
        if (p.sort_by === name) {
            return p.order_by === 'desc' ? 'tablesort_down' : 'tablesort_up'
        }
    }

    // const Sorting = (name: string, field: string) => {
    //     const fl = name.toLowerCase()
    //     return (
    //         <Link to="#" data-field={field} onClick={LoadSorting}>
    //             <div className={SortBy(field)}>{name}</div>
    //         </Link>
    //     )
    // }

    const [open, setOpen] = useState(false);
    const closeModal = () => setOpen(false);

    return (
        <>
            {/* <RightPanel /> */}
            {renderPopup()}
            <div className="page_title_area">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div className='font18'>Known VPN/Firewalls/Gateways/NAT</div>
                        <div className="search_container issue_page scrollbar-container marginleft20 admin-page-search-container">
                            <div className="search_white_icon flex_none" style={{ cursor: 'pointer' }}
                                onClick={handleSubmit(onSubmit)}
                            >&nbsp;</div>&nbsp;
                            <input
                                className="search_input flex_none admin-page"
                                defaultValue={p.q}
                                placeholder="Name, Server Name and IP"
                                ref={register}
                                name='query'
                            />
                        </div>
                        {authDetails.permissions.Admin.intermediary !== "readonly" ?
                            <div className="launch_button admin_page_launch_btn" >
                                <button type="button" className="button_main" onClick={() => setActionType({ id: "", type: "add" })}>Add</button>
                            </div>
                            : <div className="launch_button hide-launch-button">
                                <div className="button_main" style={{ background: 'transparent' }}></div>
                            </div>
                        }
                    </div>
                </form>
            </div>

            <BaseAMAdminGrid
                query={query} setQuery={setQuery}
                columnDefs={IntermediaryTableColumns}
                gridRef={childRef}
                fetchEntitiesFn={Listing}
                customActionCellRender={customActionCellRender}
                {...(InterMediaryTableActions?.length > 0 && authDetails.permissions.Admin.intermediary !== "readonly"
                    ? { actions: InterMediaryTableActions } : null)}
            />

            {/* Delete confirmation Popup */}
            <Popup
                open={openConfirm}
                closeOnDocumentClick
                closeOnEscape={false}
                onClose={closeConfirm}
                modal
                overlayStyle={{ zIndex: 15001, background: "rgba(227, 242, 253, .6)" }}
            >
                <div className="modal">
                    <div className="close" onClick={closeConfirm}></div>
                    <div className="header">Delete Known VPN/Firewalls/Gateways/NAT...</div>
                    <div className="content">
                        <div className="font16 margintop20 marginbottom20">Are you sure you want to Delete this Known VPN/Firewalls/Gateways/NAT?</div>
                    </div>
                    <div className="popup_footer">
                        <button style={{ display: 'inline' }} type="button" className="button_gray width25per margintop10" onClick={closeConfirm}>No</button>
                        <button style={{ float: 'right' }} type="button" className="button_main width25per" onClick={deleteConfirm}>Yes</button>
                    </div>
                </div>
            </Popup>
        </>
    )
}

export default withQueryParams({
    q: StringParam,
    page: StringParam,
    rpp: NumberParam,
    sort_by: StringParam,
    order_by: StringParam,
    filters: withDefault(ArrayParam, [])
}, Groups)