import React from "react"
import Popup from "reactjs-popup"

export const CloseIncidents = ({ closeIncPopUp, handleCloseIncPopUp, selectCount, closeFromAction,
    selectAll, tags, disableYes, setIsCloseAll, setStatusComment, confirmPasswordOperation, isCloseAll,
    handleCloseTicket, closeTicket
}) => {
    return <Popup
        closeOnDocumentClick={false}
        overlayStyle={{ zIndex: 15001, background: "rgba(227, 242, 253, .6)" }}
        open={closeIncPopUp}
        closeOnEscape={false}
        modal
    >
        <div className={"modal"}>
            <div className={"close"} onClick={handleCloseIncPopUp}></div>
            <div className={"header"}><div className="pbheader_ellipisis" title={'Close Incidents'}>Close Incident{(selectCount && selectCount == 1) || (closeFromAction) ? '?' : 's?'}</div></div>
            <div className={"content"}>
                <div className="font14">
                    Selected incidents will be closed.
                    Are you sure you want to close <strong>{selectCount && selectCount > 0 ? selectCount : closeFromAction ? 1 : ''}</strong> incident{(selectCount && selectCount == 1) || (closeFromAction) ? '?' : 's?'}
                </div>
                {selectAll && tags.filter(i => !i.includes('status:Open')).length == 0 &&
                    <>
                        {/* <label>Note: This action will close all {selectCount} incidents.</label> */}
                        <div
                            className='width100per'
                            style={{ display: 'flex', alignItems: 'center' }}
                        ><label style={{ width: "35%" }}>To confirm, type "Close": </label><input

                                style={{ width: '40%' }}
                                className={"height35 " + (disableYes ? ' borderError' : ' ')}
                                type='text' onChange={(e) => {
                                    if (e.target.value && e.target.value.trim().toLowerCase() == 'close') {
                                        setIsCloseAll(e.target.value)
                                    } else {
                                        setIsCloseAll(undefined)
                                    }
                                }} /></div>
                    </>
                }
                <div>
                    <label>Comment (optional)</label>
                    <textarea style={{ resize: 'none' }} onChange={(e: any) => setStatusComment(e.target.value)}></textarea>
                </div>
                <div>
                    <input className="margintop10 font14 close-ticket-check" type="checkbox" checked={closeTicket} onChange={handleCloseTicket} />
                    <span className="font14">Close tickets? In addition to closing selected incidents, close any related tickets.</span>
                </div>
                <div>
                </div>
            </div>
            <div className="shadow_footer fl" style={{ marginTop: 0 }}></div>
            <div className="popup_footer fl">
                <div className="policy_defualt_button" onClick={handleCloseIncPopUp}>No</div>
                <div className={"policy_save_button " +
                    (selectAll && tags.filter(i => !i.includes('status:Open')).length === 0 && !isCloseAll ? ' disabled grayscaleOne' : '')
                } onClick={confirmPasswordOperation}
                >Yes</div>
            </div>
        </div>
    </Popup>
}