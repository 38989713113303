import React from "react";

export const NoRowsOverlay = (props) => {
    return (
        <div
            role="presentation"
            className="ag-overlay-loading-center ag-overlay-no-row-container"
        >{props.tableError || `No records found.`}
        </div>
    );
};
