import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import DatePicker from "react-datepicker";
import ReactPaginate from 'react-paginate';
import { ArrayParam, NumberParam, StringParam, withDefault, withQueryParams } from 'use-query-params';
import { Api } from "../../components/Axios";
import { useToasts } from '../../components/core';
import { ServerResponse } from '../../types/response-types';
import './ArchivedData.css';
import InfoIcon from '@mui/icons-material/Info';
import { Tooltip } from '@material-ui/core';
import { ColDef } from '@ag-grid-community/core';
import BaseAMAdminGrid from '../../components/core/AMTable/BaseAMAdminGrid';
import { getResolutionMatchFor1920 } from '../../utils/util-methods';


interface IArchiveData {
    _id: string,
    user_id: string,
    archive_entity: string,
    processing_status: number,
    start_date: string,
    end_date: string,
    created_time: string,
    expire_at: string,
    modified_time: string
}

interface Params {
    rpp?: number;
    sort_by?: string;
    order_by?: string;
    q?: string;
    page?: number;
}


const getDate = (date: Date, numDays: number): Date => {
    if (date) {
        const tempDate = new Date(date);
        tempDate.setDate(date.getDate() + numDays);
        return tempDate;
    }
    return new Date();
}

const ProcessingStatus = {
    0: { label: 'Pending', title: 'Download is disabled as request is pending.' },
    1: { label: 'In Progress', title: 'Download is disabled as request is still in progress.' },
    2: { label: 'Download', title: 'You can download your file now.' },
    3: { label: 'Failed', title: 'Download is disabled as request is failed.' },
    4: { label: 'No Content', title: 'Download is disabled as request has no content.' }
}

type Loading = {
    loading: any,
    setData: any
}


const ArchiveDataCols: Array<ColDef> = [
    {
        headerName: 'Details of Download Request',
        field: '',
        sortable: false,
        cellClass: 'align_left width380',
        cellRenderer: ({ data }) => {
            const item = data;
            return <>Archived {item.archive_entity == 'issues' ? ' Incidents ' : ' Posture'} - &nbsp;
                {moment(item.start_date).format('MMM DD YYYY')} to {moment(item.end_date).format('MMM DD YYYY')}
            </>
        }
    },
    {
        headerName: 'Date of Request',
        field: '',
        sortable: false,
        cellClass: 'align_left width380',
        cellRenderer: ({ data }) => {
            const item = data;
            return <>{moment(item.created_time).format('MMM DD YYYY, hh:mm A')}</>
        }
    },
    {
        headerName: 'Action',
        field: '',
        width: getResolutionMatchFor1920(150, 150),
        sortable: false,
        pinned: 'right',
        headerClass: 'base-am-admin-grid-action-header',
        cellClass: 'align_left width380 flex_align_items_center base-am-admin-grid-action-cell',
        cellRenderer: ({ data }) => {
            const item = data;
            return <button className={"button_styled mini_button archive-container-btn " + (item.processing_status != 2 ? ' disabled' : '')}
                onClick={() => onDownloadClick(item._id, item.archive_entity)}

            >
                {ProcessingStatus[item.processing_status as keyof typeof ProcessingStatus].label}</button>
        }
    }
]

const onDownloadClick = (archive_id: string, archive_type: string) => {
    Api.get(`/archive/${archive_id}?archive_entity=${archive_type}`).then((res: any) => {
        window.open(res.data)
    }).catch();
}

const ArchivedData = ({ query, setQuery }: any) => {
    const { q: q, page: page_number, rpp: record_per_page, order_by: order, sort_by: sort } = query;
    const startDate = getDate(new Date(), -76);
    const issueMaxDate = getDate(startDate, 14);
    const postureMaxDate = getDate(new Date(), 0);
    const minDate = getDate(startDate, -365)
    const [incidentStartDate, setIncidentStartDate] = useState<Date>(startDate);
    const [incidentEndDate, setIncidentEndDate] = useState(getDate(startDate, 14));
    const [postureStartDate, setPostureStartDate] = useState<Date>(startDate);
    const [postureEndDate, setPostureEndDate] = useState(getDate(postureStartDate, 14));
    const [loading, setLoading] = useState<Loading>({ loading: undefined, setData: false });
    const [showError, setShowError] = useState<any>({});
    const [tableData, setTableData] = useState<IArchiveData[] | undefined>(undefined);
    const { addToast } = useToasts();
    const [responseData, setResponseData] = useState<ServerResponse>();
    const [showLoader, setShowLoader] = useState(true);
    let timer: any;
    const gridRef = useRef();
    let p: Params = {
        q: q,
        page: page_number,
        rpp: record_per_page,
        order_by: order,
        sort_by: sort
    }

    const downloadCSV = (archive_entity: string) => {

        if ((archive_entity == 'posture' && (!postureEndDate || !postureStartDate)) ||
            (archive_entity == 'issues' && (!incidentStartDate || !incidentEndDate))
        ) {
            setShowError({ [archive_entity]: '*Please enter start date/end date.' })
            return;
        } else
            setShowError(undefined);

        const payload = {
            "archive_entity": archive_entity,
            "start_date": archive_entity == 'posture' ? moment.utc(new Date(postureStartDate).setDate(postureStartDate.getDate())).unix()
                : moment.utc(new Date(incidentStartDate).setDate(incidentStartDate.getDate())).unix(),
            "end_date": archive_entity == 'posture' ? moment.utc(new Date(postureEndDate).setDate(postureEndDate.getDate())).unix()
                : moment.utc(new Date(incidentEndDate).setDate(incidentEndDate.getDate())).unix()
        }

        const st_dt = moment.unix(payload.start_date).toDate();
        const ed_dt = moment.unix(payload.end_date).toDate();
        const max_date = archive_entity == 'posture' ? postureMaxDate : issueMaxDate;
        max_date.setDate(max_date.getDate() + 1);
        if (st_dt > ed_dt) {
            setShowError({ [archive_entity]: '*Start date cannot be greater than end date.' });
            return;
        } else if (ed_dt > max_date) {
            setShowError({ [archive_entity]: '*End date cannot be greater than max date' });
            return;
        } else if (((ed_dt.getTime() - st_dt.getTime()) / (1000 * 60 * 60 * 24)) >= 15) {
            setShowError({ [archive_entity]: '*Date range should not be more than 15 days' });
            return;
        }

        setShowError(undefined)
        setLoading({ ...loading, loading: archive_entity });
        Api.post('/archive', payload).then(() => {
            fetchArchiveList();
            setLoading({ ...loading, loading: undefined })
            addToast('Archive request has been successfully placed.', {
                appearance: 'success',
                autoDismiss: true,
                hideDuration: 4000
            })
        }).catch();
    }

    useEffect(() => {
        fetchArchiveList();
        return () => {
            if (timer) {
                clearTimeout(timer)
            }
        }
    }, []);

    useEffect(() => {
        setShowError(undefined);
    }, [postureEndDate, postureStartDate, incidentEndDate, incidentStartDate])

    const fetchArchiveList = (queryParams?, paramGrid?) => {
        setShowLoader(true);
        setTableData(undefined)
        if (!paramGrid) {
            gridRef?.current?.api?.onFilterChanged()
        } else
            Api.get('archive', {
                params: p
            }).then((res: any) => {
                setShowLoader(false);
                setTableData(res.data.result);
                if (res?.data?.result) {
                    const pending = res.data.result.filter((item: IArchiveData) => [0].includes(item.processing_status)).length;
                    if (pending > 0) {
                        timer = setTimeout(() => fetchArchiveList(), 20000);
                    }
                    if (paramGrid) {
                        paramGrid.success({
                            rowData: res.data.result,
                            rowCount: res.data.total
                        });
                    }
                }
                if (gridRef?.current) {
                    if (res?.data?.result?.length === 0) {
                        gridRef?.current.api.showNoRowsOverlay()
                    }
                }
                setResponseData(res.data);
            }).catch(() => {
                setShowLoader(false);
                setTableData([])
            })
    }

    const Paginate = (selectedPage: any) => {
        p.page = selectedPage.selected === 0 ? 1 : selectedPage.selected + 1
        setQuery(p)
    }

    useEffect(() => {
        fetchArchiveList();
    }, [query])

    const renderArchivedIncidents = () => {
        return <div className="float_left" style={{ width: '48%' }}>
            <div className="page_title_area width75per">
                <h2>Archived Incidents</h2>
            </div>
            <div className="box_grid_layout">
                <div className='font14'>Select date range (max 15 days) to export archived incidents:</div>
                <div className="clrBoth margintop20"></div>
                <div className='width25per float_left'>
                    <label>From</label>
                </div>
                <div className='width75per float_left'>
                    <DatePicker
                        dateFormat="yyyy-MM-dd"
                        selected={incidentStartDate}
                        onChange={(date: Date) => {
                            setIncidentStartDate(date);
                            setIncidentEndDate(getDate(new Date(date), 14) > issueMaxDate ?
                                issueMaxDate : getDate(new Date(date), 14));
                        }}
                        selectsStart
                        startDate={incidentStartDate}
                        endDate={incidentEndDate}
                        minDate={minDate}
                        maxDate={issueMaxDate}
                    />
                </div>
                <div className='width25per float_left'>
                    <label>To</label>
                </div>

                <div className='width75per float_left'>
                    <DatePicker
                        dateFormat="yyyy-MM-dd"
                        maxDate={
                            getDate(new Date(incidentStartDate), 14) > issueMaxDate ?
                                issueMaxDate : getDate(new Date(incidentStartDate), 14)
                        }
                        selected={incidentEndDate}
                        onChange={(date: Date) => setIncidentEndDate(date)}
                        selectsEnd
                        startDate={incidentStartDate}
                        endDate={incidentEndDate}
                        minDate={incidentStartDate}
                    />
                </div>

                <div className="box_grid_footer_line"></div>
                {showError?.issues ?
                    <div className='width75per float_left red font12' style={{ marginTop: '3%' }}>
                        <label className='width100per margintop0 font13'>{showError.issues}</label>
                    </div> : null}
                <div className="box_grid_footer" style={{ paddingTop: '0' }}>
                    <div className="float_right">
                        <button type={('button')} className={" " + (loading?.loading == 'issues' ? 'loader' : 'button_styled')}
                            onClick={() => downloadCSV('issues')}>Request Download</button>
                    </div>
                </div>
            </div>
        </div>
    }

    const renderArchivedPosture = () => {
        return <div className="float_right" style={{ width: '48%' }}>
            <div className="">
                <h2 style={{ paddingBottom: '20px' }}>Archived Identity Security Posture</h2>
            </div>
            <div className="box_grid_layout">
                <div className='font14'>Select date range (max 15 days) to export archived identities, assets, directories, etc.:</div>
                <div className="clrBoth margintop20"></div>
                <div className='width25per float_left'>
                    <label>From</label>
                </div>
                <div className='width75per float_left'>
                    <DatePicker
                        dateFormat="yyyy-MM-dd"
                        selected={postureStartDate}
                        onChange={(date: Date) => {
                            setPostureStartDate(date);
                            setPostureEndDate(getDate(new Date(date), 14) > postureMaxDate ?
                                postureMaxDate : getDate(new Date(date), 14));
                        }}
                        selectsStart
                        startDate={postureStartDate}
                        endDate={postureEndDate}
                        minDate={minDate}
                        maxDate={postureMaxDate}
                    />
                </div>
                <div className='width25per float_left'>
                    <label>To</label>
                </div>

                <div className='width75per float_left'>
                    <DatePicker
                        dateFormat="yyyy-MM-dd"
                        maxDate={
                            getDate(new Date(postureStartDate), 14) > postureMaxDate ?
                                postureMaxDate : getDate(new Date(postureStartDate), 14)
                        }
                        selected={postureEndDate}
                        onChange={(date: Date) => setPostureEndDate(date)}
                        selectsEnd
                        startDate={postureStartDate}
                        endDate={postureEndDate}
                        minDate={postureStartDate}
                    />
                </div>
                <div className="box_grid_footer_line"></div>
                {showError?.posture ?
                    <div className='width75per float_left red font12'>
                        <label className='width100per margintop0 font13'>{showError.posture}</label>
                    </div> : null}
                <div className="box_grid_footer" style={{ paddingTop: '0' }}>
                    <div className="float_right">
                        <button type={('button')} className={" " + (loading.loading == 'posture' ? 'loader' : 'button_styled')}
                            onClick={() => downloadCSV('posture')}>Request Download</button>
                    </div>
                </div>
            </div>
        </div>
    }


    const renderTable = () => {
        return <div className='archive-data-container-root'>
            <div><h2 style={{ padding: 0 }}>Download Requests</h2>
                <Tooltip classes={{ tooltip: 'sensor-proxy-tooltip' }} title={
                    'Requests older than 7 days will be removed automatically.'
                }>
                    <InfoIcon style={{ fontSize: 14, color: '#d4d8e1', marginTop: 2, transform: 'skewX(-10deg)' }} />
                </Tooltip>
            </div>
            <BaseAMAdminGrid
                columnDefs={ArchiveDataCols}
                query={query}
                setQuery={setQuery}
                fetchEntitiesFn={fetchArchiveList}
                overlayNoRowsTemplate={"No Archive Data Available"}
                gridRef={gridRef}
            />
        </div>
    }

    const renderTableData = () => {
        return TableLoader;
    }

    const TableLoader = React.useMemo(() => {
        return tableData && tableData?.length > 0 && !showLoader ?
            tableData.map((item: IArchiveData) => {
                return <tr key={item._id}>
                    <td key={item._id + '-req'} style={{ textAlign: 'left', verticalAlign: 'middle' }}>
                        Archived {item.archive_entity == 'issues' ? ' Incidents ' : ' Posture'} -&nbsp;
                        {moment(item.start_date).format('MMM DD YYYY')} to {moment(item.end_date).format('MMM DD YYYY')}</td>
                    <td key={item._id + '-reqdt'} style={{ textAlign: 'left', verticalAlign: 'middle' }}>
                        {moment(item.created_time).format('MMM DD YYYY, hh:mm A')}</td>
                    <td key={item._id + '-' + item.processing_status}
                        title={ProcessingStatus[item.processing_status as keyof typeof ProcessingStatus].title}
                    >
                        <button className={"archive-container-btn button_styled mini_button" + (item.processing_status != 2 ? ' disabled' : '')}
                            onClick={() => onDownloadClick(item._id, item.archive_entity)}

                        >
                            {ProcessingStatus[item.processing_status as keyof typeof ProcessingStatus].label}</button></td>
                </tr>
            }) : tableData && tableData?.length == 0 && !showLoader ?
                <tr key={'no-data-archive'}>
                    <td colSpan={13} style={{ textAlign: 'center' }}><div>No Archive Data Available</div></td>
                </tr>
                : <tr key={'loader-spinner'}>
                    <td colSpan={13} style={{ textAlign: 'center' }}><div className='loader spinner'></div></td>
                </tr>
    }, [tableData]);

    return <>
        <div className="archive-container">
            {renderArchivedIncidents()}
            {renderArchivedPosture()}
        </div>
        {renderTable()}
    </>

}

export default withQueryParams({
    q: StringParam,
    page: StringParam,
    rpp: NumberParam,
    sort_by: StringParam,
    order_by: StringParam,
    filters: withDefault(ArrayParam, []),
    launchApp: StringParam
}, ArchivedData)