import React, { useCallback, useEffect, useRef, useState } from "react";
import HighchartsReact from "highcharts-react-official";
import * as Highcharts from "highcharts/highstock";
import { RiskLevelData } from "../../../types/response-types";
import "./UnknownAccess.scss";
import moment from "moment";
import { convertToCommaValue, getBreadCrumbTitleOnRuleType, getCustomLabelForRuleName, getResolutionMatch, getRoundedUnitWithoutDot } from "../../../utils/util-methods";
import { useHistory } from "react-router-dom";
import { useDashboardStateContext } from "../../../store/DashboardStateContextProvider";

type Trend = {
    [epoch_value: string]: number
}
type Props = {
    unknownAccessRules: {
        [rule_name_key: string]: {
            trend: Trend,
            id_count: number,
            incident_count: number
        }
    }
    widgetId: string | undefined | null;
    widgetTitle: string;
};

function getDummyData(data) {
    let objData = [];
    if (data?.length) {
        objData.push({ ...data[2], ruleName: 'Unresolved Access' });
        objData.push({ ...data[2], ruleName: 'Unknown Ext Access' });
        objData.push({ ...data[2], ruleName: 'Asset Anom. Acti.' });
    }
    return objData;
}

function prepareChartData(unknownAccessRules: any) {
    const objList = [];
    try {
        if (unknownAccessRules && (Object.keys(unknownAccessRules) || {}).length > 0) {
            for (const rule in unknownAccessRules) {
                const { trend = [], id_count = 0, incident_count = 0, } = { trend: {}, id_count: 0, incident_count: 0, ...unknownAccessRules[rule] }

                const chartData = [];
                if (Object.keys(trend)?.length > 0) {
                    for (const epoch in trend) {
                        if (trend[epoch] != undefined && trend[epoch] != null && trend[epoch] >= 0) {
                            chartData.push([parseInt(epoch) * 1000, trend[epoch]]);
                        }
                    }
                }
                objList.push({ ruleName: rule, chartData, id_count, incident_count, entityType: unknownAccessRules[rule]?.entity_type });
            }
        }
        return objList;
        //return getDummyData(objList);
    }
    catch (err) {
        return undefined;
    }

}

const getTitleOnEntityType = (eType: string = '') => {
    const entity = eType.trim().toLowerCase();

    switch (entity) {
        case 'identity': return 'identities';
        case 'asset': return 'assets';
        case 'directory': return 'directories';
        default: return 'assets';
    }
}

const UnknownAccess = ({ unknownAccessRules, widgetId, widgetTitle = '' }: Props) => {
    const [chartOptions, setChartOptions] = useState<any>(null);
    const [rulesObjectList, setRulesObjectList] = useState<any>(undefined);
    // const [selectedIndex,setSelectedIndex] = useState(1);
    const history = useHistory();
    const { UnknownAccessState: { selectedOption: selectedIndex, setSelectedOption: setSelectedIndex } } = useDashboardStateContext();

    const chartRef = useRef<{
        chart: Highcharts.Chart;
        container: React.RefObject<HTMLDivElement>;
    }>(null);

    const handleIdCountClick = (evt) => {
        const bread = { breadcrumbId: "ShadowActivityPosture", customData: { pageName: widgetTitle } }
        const ruleType = evt.target.getAttribute('data-ruletype');
        const entityType = evt.target.getAttribute('data-entityType');
        let title = `All ${ruleType}`;
        title = getBreadCrumbTitleOnRuleType(ruleType);

        const id_type = getTitleOnEntityType(entityType);
        switch (id_type) {
            case 'assets': history.push(`/posture/assets?hard_refresh=true&order_by=desc&page=1&q=rule_name%3A${ruleType}&sort_by=flow_count`, bread);
                break;
            case 'identities': history.push(`/posture/identities?hard_refresh=true&order_by=desc&page=1&q=rule_name%3A${ruleType}&sort_by=flow_count`, bread);
                break;
            case 'directories': history.push(`/directory?hard_refresh=true&order_by=desc&page=1&q=rule_name%3A${ruleType}&sort_by=flow_count`, bread);
                break;
            default: history.push(`/posture/assets?hard_refresh=true&order_by=desc&page=1&q=rule_name%3A${ruleType}&sort_by=flow_count`, bread);
        }

    };

    const handleIncidentCountClick = useCallback((evt) => {
        const ruleType = evt.target.getAttribute('data-ruletype');
        let title = `All ${ruleType}`;
        const bread = { breadcrumbId: "ShadowActivityIncidents", customData: { pageName: widgetTitle } };
        if (ruleType === "Suspicious Outbound Access") history.push("/issues?disable_filter=true&page=1&q=rule_name%3A" + ruleType + "%2Bstatus%3AOpen&sort_by=issue_flows_count&order_by=desc", bread);
        else if (ruleType === "Shadow External Access") history.push("/issues?disable_filter=true&page=1&q=rule_name%3A" + ruleType + "%2Bstatus%3AOpen&sort_by=issue_flows_count&order_by=desc", bread);
        else history.push("/issues?disable_filter=true&page=1&q=rule_name%3A" + ruleType + "%2Bstatus%3AOpen&sort_by=issue_flows_count&order_by=desc", bread);
    }, []);

    useEffect(() => {

        const objList = prepareChartData(unknownAccessRules) || [];
        if (objList?.length) {
            if (!selectedIndex) {
                let selIndex = objList.findIndex(p => p?.incident_count > 0);
                if (selIndex < 0) {
                    selIndex = 0;
                }
                setSelectedIndex(selIndex);
            }
            setRulesObjectList(objList);
        }

    }, [unknownAccessRules]);

    const pointClickHandler = (ruleName: any, startTimeEPOCH: any) => {
        try {
            const bread = { breadcrumbId: "UnknownAccess", customData: { pageName: widgetTitle } };
            const endTimeEPOCH = moment(startTimeEPOCH).add(1, 'h').valueOf();
            history.push(`issues?disable_filter=true&page=1&q=status:Open%2Bs_m_time:${startTimeEPOCH / 1000}%2Be_m_time:${endTimeEPOCH / 1000}%2Brule_name:${ruleName}&sort_by=issue_flows_count&order_by=desc`, bread)
        }
        catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        setChartOptions({
            chart: {
                spacingBottom: 2,
                spacingTop: 0,
                type: "line",
                renderTo: "chart",
                style: {
                    fontFamily: "Metropolis-Regular",
                },
                events: {
                    load: () => {
                        setTimeout(() => {
                            let containers = document.getElementsByClassName("unknwon-access-chart");
                            if (containers?.length > 0) {
                                chartRef.current?.chart.setSize(containers[0].clientWidth, getResolutionMatch(120, 60), false);
                            }
                        }, 1);
                    },
                },
            },
            credits: undefined,
            title: {
                align: "left",
                useHTML: true,
                text: "",
            },
            subtitle: {
                text: "",
            },
            legend: {
                enabled: false,
            },
            plotOptions: {
                line: {
                    color: "#fa1262",
                    lineWidth: getResolutionMatch(3, 1.5),
                    states: {
                        hover: {
                            lineWidth: getResolutionMatch(4, 2),
                            fillColor: "#fa1262",
                        },
                    },
                },
                series: {
                    stacking: "normal",
                    pointPadding: 0,
                    groupPadding: 0,
                    borderWidth: 0,
                    shadow: false,
                    dataGrouping: {
                        enabled: false,
                    },
                    cursor: 'pointer',
                    point: {
                        events: {
                            click: function () {
                                pointClickHandler(rulesObjectList[selectedIndex].ruleName, this.x);
                            }
                        }
                    },
                    marker: {
                        enabled: false,
                        radius: getResolutionMatch(3, 1.5),
                        states: {
                            hover: {
                                enabled: true,
                                fillColor: "#fa1262",
                            },
                        },
                    },
                    size: getResolutionMatch(480, 240),
                    innerSize: "100%",
                    center: [80, "50%"],
                },
            },
            xAxis: {
                type: "datetime",
                step: 2,
                tickLength: 0,
                labels: {
                    enabled: false,
                    formatter: function () {
                        const x = this;
                        return `<span class='unknown-access-x-labels'><b>${moment(this.value).format("hh:mm A")}</b><span>`;
                    },
                    overflow: "justify",
                },
            },
            yAxis: {
                gridLineColor: "transparent",
                title: {
                    text: "",
                },
                labels: {
                    enabled: false,
                    style: {
                        fontSize: getResolutionMatch(24, 12),
                    },
                },
            },
            tooltip: {
                shape: "rect",
                // hideDelay: 50000,
                positioner: function (w, h, p) {
                    return {
                        x: Math.min(getResolutionMatch(465, 216), p.plotX),
                        y: p.plotY * getResolutionMatch(0.02, 0.01)
                    };
                },
                useHTML: true,
                padding: 10,
                shadow: false,
                borderWidth: 0,
                backgroundColor: "#ffffff00",
                formatter: function () {                    
                    const header = `<div class='unknown-access-tooltip'>${moment(this.x).format("hh:mm A")} </br> incident count   : ${this.y}</div>`;
                    return header;
                },
                style: {
                    zIndex: 100000
                }
            },
            series: [
                {
                    data: rulesObjectList?.[selectedIndex]?.chartData || []
                },
            ],
        });
    }, [rulesObjectList, selectedIndex])
    return (
        <>
            <div className={`widget-title plain-border`}>{rulesObjectList?.[selectedIndex || 0]?.ruleName}</div>
            <div
                className="widget-content"
                style={{
                    position: "relative",
                    height: "100%",
                    maxHeight: "100%",
                    minHeight: "100%",
                }}
            >
                <div className="unknown-access-container">
                    <div className="unknwon-access-chart" style={{ marginLeft: 0 }}>
                        <HighchartsReact ref={chartRef} highcharts={Highcharts} options={chartOptions} />
                    </div>
                    <div className="unknown-access-list">
                        {rulesObjectList && rulesObjectList?.length && rulesObjectList?.map((rule, index) => {
                            return (
                                <div
                                    className={`unknown-access-item ${
                                        index === selectedIndex && 'selected'
                                    }`}
                                >
                                    <div
                                        onClick={() => {
                                            setSelectedIndex(index);
                                        }}
                                        title={rule.ruleName}
                                        className='unknown-rule-title'
                                    >
                                        {getCustomLabelForRuleName(
                                            rule.ruleName
                                        )}
                                    </div>
                                    <div className='unknown-total'>
                                        <div className='unknown-asset-total' style={{cursor: [
    'Unknown Access',
    'Shadow External Access'
  ].includes(rule?.ruleName?.toString()) ? 'default' : 'pointer'}}>
                                            <div
                                                className='unknown-count'
                                                title={`${convertToCommaValue(
                                                    rule?.id_count
                                                )}`}
                                                data-ruletype={rule.ruleName}
                                                data-entityType={
                                                    rule.entityType
                                                }
                                                onClick={(e) => {
                                                    if (
                                                        rule?.id_count &&
                                                        ![
                                                            'Unknown Access',
                                                            'Shadow External Access'
                                                        ].includes(
                                                            rule?.ruleName?.toString()
                                                        )
                                                    )
                                                        handleIdCountClick(e);
                                                }}
                                            >
                                                {getRoundedUnitWithoutDot(
                                                    rule?.id_count
                                                )}
                                            </div>
                                            <div
                                                data-ruletype={rule.ruleName}
                                                data-entityType={
                                                    rule.entityType
                                                }
                                                style={{cursor: [
                                                    'Unknown Access',
                                                    'Shadow External Access'
                                                  ].includes(rule?.ruleName?.toString()) ? 'default' : 'pointer'}}
                                                onClick={(e) => {
                                                    if (
                                                        rule?.id_count &&
                                                        ![
                                                            'Unknown Access',
                                                            'Shadow External Access'
                                                        ].includes(
                                                            rule?.ruleName?.toString()
                                                        )
                                                    )
                                                        handleIdCountClick(e);
                                                }}
                                            >
                                                assets
                                            </div>
                                        </div>
                                        <div className='unknown-incident-total'>
                                            <div
                                                className='unknown-count'
                                                title={`${convertToCommaValue(
                                                    rule?.incident_count
                                                )}`}
                                                data-ruletype={rule.ruleName}
                                                data-entityType={
                                                    rule.entityType
                                                }
                                                onClick={(e) => {
                                                    if (
                                                        rule?.incident_count &&
                                                        rule?.incident_count > 0
                                                    )
                                                        handleIncidentCountClick(
                                                            e
                                                        );
                                                }}
                                            >
                                                {getRoundedUnitWithoutDot(
                                                    rule?.incident_count
                                                )}
                                            </div>
                                            <div
                                                data-ruletype={rule.ruleName}
                                                data-entityType={
                                                    rule.entityType
                                                }
                                                onClick={(e) => {
                                                    if (
                                                        rule?.incident_count &&
                                                        rule?.incident_count > 0
                                                    )
                                                        handleIncidentCountClick(
                                                            e
                                                        );
                                                }}
                                            >
                                                incidents
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </>
    );
};

export default UnknownAccess;