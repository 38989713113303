import React, { useEffect, useCallback } from 'react';
import { IHeaderGroupParams, Column } from '@ag-grid-community/core';
import './IssueGrid.scss';

interface IssueCustomGroupHeaderProps extends IHeaderGroupParams { }

export const IssueCustomGroupHeader: React.FC<IssueCustomGroupHeaderProps> = (props) => {
    const { gridApi } = props.context;

    // Handle sorting for individual columns
    const handleSort = (col: Column) => {
        const colId = col.getColId();
        const currentSortState = gridApi
            .getColumnState()
            .find((state) => state.colId === colId);
        const newSort = currentSortState?.sort === 'desc' ? 'asc' : 'desc';

        const updatedColumnState = gridApi
            .getColumnState()
            .map((state) => ({
                colId: state.colId,
                sort: state.colId === colId ? newSort : null,
            }));

        gridApi.applyColumnState({ state: updatedColumnState });
    };

    // Update sort icons dynamically
    const updateSortIcons = useCallback(() => {
        const columnStates = gridApi?.getColumnState();
        columnStates?.forEach((state) => {
            const el = document.getElementById(state.colId + '-sorter') as HTMLSpanElement;
            if (!el) return;

            el.classList.remove('ag-icon-asc', 'ag-icon-desc', 'ag-icon-sort', 'ag-icon-none');
            if (state.sort === 'asc') {
                el.classList.add('ag-icon-asc');
            } else if (state.sort === 'desc') {
                el.classList.add('ag-icon-desc');
            } else {
                el.classList.add('ag-icon-sort');
                el.classList.add('ag-icon-none');
            }
        });
    }, [gridApi]);

    // Add or remove sort change listener
    useEffect(() => {
        const onSortChanged = () => {
            updateSortIcons();
        };

        gridApi?.addEventListener('sortChanged', onSortChanged);
        updateSortIcons(); // Initial update

        return () => {
            gridApi?.removeEventListener('sortChanged', onSortChanged);
        };
    }, [gridApi, updateSortIcons]);

    return (
        <div className="issue-custom-header-group">
            {/* Parent Header */}
            <div className="group-title">{props.displayName}</div>

            {/* Child Headers */}
            <div className="issue-custom-child-headers">
                {props.columnGroup.getLeafColumns().map((col) => (
                    <div
                        key={col.getColId()}
                        id={col.getColId() + '_sorter_column'}
                        className={`issue-custom-header ${col.getColDef().customHeaderClass || ''}`}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            cursor: col.getColDef().sortable ? 'pointer' : 'default',
                        }}
                        onClick={() => col.getColDef().sortable && handleSort(col)}
                    >
                        {/* Column Header Text */}
                        <div
                            style={{ flexGrow: 1 }}
                        >
                            <span className="ag-header-cell-text"
                            >
                                {col.getColDef().headerName}
                            </span>
                        </div>

                        {/* Sort Icon */}
                        {
                            col.getColDef().sortable &&
                            <span
                                id={col.getColId() + '-sorter'}
                                className="ag-icon ag-icon-sort"
                                style={{ marginLeft: 4 }}
                            ></span>
                        }

                    </div>
                ))}
            </div>
        </div>
    );
};
