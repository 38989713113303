import React, { useEffect, useState } from "react";
import './directory-config.css';
import { BaseProps } from "../../constants/types";
import { SuspectedIDPBruteForceState, ThresholdType } from "../../../../types/playbooks-config";
import CheckboxAutocomplete from "../../../../components/core/AMAutoComplete/AMAutoComplete";
import { getDefaultConfigState } from "../../helpers/playbook-helper";
import { ThresholdSelect } from "../../ref/ThresholdSelect";

export const SuspectedIDPBruteForce = ({ parentState, isSavedOnce, getState, defaultState, formType, getExpression, ruleType }: BaseProps<SuspectedIDPBruteForceState>) => {
    const [state, setState] = useState<SuspectedIDPBruteForceState>(defaultState);
    const [checked, setChecked] = React.useState<any>({
        num_auth_fail: isSavedOnce ? (parentState.dir_params.user_auth_fail_count_today ? true : false) :
            (state.dir_params.user_auth_fail_count_today ? true : false),
        schedule: true
    });

    useEffect(() => {
        getState({
            dir_params: {
                param_durations: [
                    {
                        param_name: state?.dir_params?.param_durations[0].param_name,
                        duration: state?.dir_params?.param_durations[0].duration
                    }
                ],
                user_auth_fail_count_today: checked?.num_auth_fail ? state?.dir_params?.user_auth_fail_count_today : undefined,
                param_thresholds: state?.dir_params?.param_thresholds
            }
        })
    }, [state, checked])

    useEffect(() => {
        if (defaultState) {
            setState(defaultState);
            setChecked({
                num_auth_fail: (defaultState.dir_params.user_auth_fail_count_today ? true : false),
                schedule: true
            })
        }
    }, [defaultState])

    useEffect(() => {
        if (isSavedOnce) {
            setState(parentState);
            setChecked({
                num_auth_fail: (parentState.dir_params.user_auth_fail_count_today ? true : false),
                schedule: true
            })
        }
    }, [isSavedOnce])

    const handleNumAuthFail = (e: React.ChangeEvent<HTMLInputElement>) => {
        setState({
            dir_params: {
                ...state?.dir_params,
                user_auth_fail_count_today: parseInt(e.target.value)
            }
        })

        setChecked({ ...checked, num_auth_fail: e.target.value ? true : false })
    }

    const onScheduleSelect = (e: React.ChangeEvent<HTMLSelectElement>, config) => {
        const duration = config[0].key;
        if (duration === 'day') {
            setState({
                dir_params: {
                    ...state?.dir_params, user_auth_fail_count_today: 20,
                    param_durations: [
                        {
                            duration: duration,
                            param_name: 'user_auth_fail_count_today'
                        }
                    ]
                }
            })
        } else {
            setState({
                dir_params: {
                    ...state?.dir_params, user_auth_fail_count_today: 12,
                    param_durations: [
                        {
                            duration: duration,
                            param_name: 'user_bad_password_count_curhour'
                        }
                    ]
                }
            })
        }
        setChecked({ ...checked, num_auth_fail: duration ? true : false })

    }

    const onCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>, keyType: string) => {
        setChecked({ ...checked, [keyType]: e.target.checked })
    }

    const getItemLabel = (op) => {
        console.log(op)
        if (op?.hasOwnProperty('key')) {
            if (op?.key) {
                return op.value
            } else {
                return ''
            }
        }
        return op || ''
    }

    const onThresholdSelect = (value) => {
        const defaultSt = getDefaultConfigState(ruleType) as SuspectedIDPBruteForceState;
        let v1 = defaultSt?.dir_params?.user_auth_fail_count_today
        let st;
        if (state?.dir_params.param_thresholds) {
            st = [...state?.dir_params.param_thresholds];
        } else {
            st = [...defaultSt?.dir_params.param_thresholds];
        }
        st[0].threshold_type = value.target.value
        st[1].threshold_type = value.target.value
        if (value.target.value === ThresholdType.AUTOMATIC) {
            st[0].allow_ml_modify = true;
            st[0].threshold_confidence = 'low';
            st[1].allow_ml_modify = true;
            st[1].threshold_confidence = 'low';

        } else {
            st[0].allow_ml_modify = false;
            st[0].threshold_confidence = undefined;
            st[1].allow_ml_modify = false;
            st[1].threshold_confidence = undefined;
        }
        setState({ dir_params: { user_auth_fail_count_today: v1, param_thresholds: st, param_durations: state?.dir_params?.param_durations } });
    }


    return <>
        <div className="dir-main-container dir-brute-force  ">
            <div className="dir-container-row enum-ad-container">
                <label>Detect using: </label>
                <ThresholdSelect
                    classes={{ divClass: "enum-ad-threshold" }}
                    onThresholdSelect={onThresholdSelect}
                    defaultValue={state?.dir_params?.param_thresholds?.[0]?.threshold_type || ThresholdType.MANUAL} />
            </div>
            <div className={(state?.dir_params?.param_thresholds?.[0]?.threshold_type === ThresholdType.AUTOMATIC ? ' disableItems' : '')}>
                <div className="dir-container-row bold-text">
                    <label className="playbook-config-title marginBottom0">
                        An account will be marked as under brute-force attack when:</label>
                </div>
                <div className="dir-container-row brute_force_justify_none_gap_5">
                    <input type="checkbox" id="num-attempts" onChange={(e) => onCheckboxChange(e, 'num_auth_fail')}
                        checked={checked?.num_auth_fail}
                        defaultChecked={checked?.num_auth_fail}
                        className={(['edit', 'view'].includes(formType || '') ? ' disable-config-item margintop20' : 'margintop20')}
                    />
                    <label
                        htmlFor="num-attempts"
                        className={(formType && ['edit', 'view'].includes(formType)) ? 'disable-config-item' : ''}
                    >
                        Number of authentication failures in the current

                        {
                            (formType && ['edit', 'view'].includes(formType)) ? <span className="view-label"> {
                                state?.dir_params?.param_durations[0].duration
                                //state?.dir_params?.time_duration
                            }</span>
                                :
                                <CheckboxAutocomplete
                                    classes={{ root: 'brute-force-sel' }}
                                    options={[{ key: 'day', value: 'day' }, { key: "hour", value: 'hour' }]}
                                    value={{ key: state?.dir_params?.param_durations[0]?.duration, value: state?.dir_params?.param_durations[0]?.duration }}
                                    onChange={onScheduleSelect}
                                    hideClearBtn={true}
                                    getItemLabel={getItemLabel}
                                />} for an account is more than
                    </label>
                    {
                        (formType && ['edit', 'view'].includes(formType)) ? <label className="view-label marginbottom10">
                            {
                                state?.dir_params?.user_auth_fail_count_today || ''
                            }</label> :
                            <input type="number" className="margintop10" onChange={handleNumAuthFail} name="num_auth_fail" value={
                                state?.dir_params?.user_auth_fail_count_today || ''
                            } />}
                </div></div>
        </div>
    </>
}