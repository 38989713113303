import React from "react"
import { PostureCategory } from "../../../identity_asset/ref/PostureCategories/PostureCategory"
import { getDestinationItemIconClass, getItemDestinationTitle } from "./issue-grid-utils"
import { DirectoryCategory } from "../../../identity_asset/ref/PostureCategories/DirectoryCategory"
import InfoIcon from '@mui/icons-material/Info';
import { AMCountryFlag } from "../../../../components/core/AMCountryFlag/AMCountryFlag";
import { AMToolTipAd } from "../../../../components/core/AMTooltipAd/AMTooltipAd";

export const IssueDestination = ({ item, }) => {
    return <div className='issue_last_access_time_cell identity_cell'>
        <div
            style={{ minWidth: window.matchMedia("(min-width: 2560px)").matches ? 50 : 50 }} className="type_col_width issue_last_access_time_cell_item type_cell display-flex">

{
            item.dst_ip_reputation === 3 ?
                <div className="reputation_icon_orange">
                    <div className="titletip">
                        {item.dst_ip_fraud_score ?
                            <span>IP Fraud Score: {item.dst_ip_fraud_score} (out of 100)</span> : null
                        }
                    </div>
                </div>
                : 
            item.dst_ip_reputation === 2 ?
                <div className="reputation_icon_gray">
                    <div className="titletip">
                        {item.dst_ip_fraud_score ?
                            <span>IP Fraud Score: {item.dst_ip_fraud_score} (out of 100)</span> : null
                        }
                    </div>
                </div>
                :
                item.dst_ip_reputation === 4 ?
                    <div className="reputation_icon_red">
                        <div className="titletip">
                            {item.dst_ip_fraud_score ?
                                <span>IP Fraud Score: {item.dst_ip_fraud_score} (out of 100)</span> : null
                            }
                        </div>
                    </div>
                    :  <div className="reputation_icon" style={{width:'14px'}}>
                    
                </div>
            }
            <PostureCategory
                type={item.destination_type}
                title={getItemDestinationTitle(item)}
                categories={item.dst_cat_ids || []}>
                <div className='asset_type_icons'>
                    <div className={getDestinationItemIconClass(item)} >
                        {(item.is_dest_known === false && (item?.destination_type === "User" || item?.destination_type === "User/Agent" || item?.destination_type === "User/Background Browsing")) ? <span>?</span> : null}
                        {(item.is_dest_known === false && (item?.destination_type === "Service" || item?.destination_type === "Service/Application" || item?.destination_type === 'Service/Computer Account' || item?.destination_type === 'Service/Key and Secret' || item?.destination_type === 'Service/Token' || item?.destination_type === 'Service/Service Principal' || item?.destination_type === 'Service/Service Account')) ? <span>?</span> : null}
                    </div>
                </div>
            </PostureCategory>
        </div>
        <div style={{ flexGrow:1}} className='issue_last_access_time_cell_item'>
            <AMToolTipAd title={item?.directory_name ?
                <div className="titletip">
                    Name: {item?.directory_name} <br />
                    {item?.directory_ip ?
                        <span>IP: {item?.directory_ip} <br /></span>
                        : null
                    }

                    {item?.directory_port ?
                        <span>Port: {item?.directory_port} <br /></span>
                        : null
                    }
                    Protocol: {item?.directory_protocol} <br />
                    Hostname: {item?.directory_hostname ? item?.directory_hostname : item?.directory_hostname}<br />
                    <DirectoryCategory categories={item.dir_cat_ids || []} title={'Directory Category'} />
                </div>
                : ''
            }>
                <div className={"dir_icon " + ((item.dir_cat_ids && item.dir_cat_ids?.length > 0 || item?.is_dir_public) ? ' dir_icon_cloud ' : '')}>
                </div>
            </AMToolTipAd>
            <div className="copy_field" style={{ display: 'flex' ,minWidth:window.matchMedia("(min-width: 1901px)").matches ? '95px'   : '95px' }}>
                <div className="ellipsis_ip">
                    <span title={item.destination_name.toString()}>{item.destination_name}</span>
                </div>
                <InfoIcon titleAccess={item.destination_name.toString()} style={{ fontSize: 14, color: '#d4d8e1', marginTop: 2, transform: 'skewX(-10deg)' }} />
                <div className="copy_icon" title="Copy this text to Clipboard" onClick={() => navigator.clipboard.writeText(item.destination_name)}></div>
            </div>
        </div >
        <div style={{  marginLeft:'15px' ,display:'flex'}} className="copy_field issue_last_access_time_cell_item full_width">
            {item?.destination_countrycode2 ?
                <span>
                    {item?.destination_countryname ?
                        <div className="flag_icon">
                            <AMCountryFlag countryCode={item?.destination_countrycode2} />
                            <div className="titletip">
                                {item?.destination_cityname ?
                                    <span>{item?.destination_cityname}, </span> : null
                                }
                                <span>{item?.destination_countryname}</span>
                            </div>
                        </div>
                        :
                        <div className="flag_icon">
                            <div className='no_flag' title='Country details not available'></div>
                        </div>
                    }
                </span>
                :
                <span>
                    {item?.destination_countryname ?
                        <div className="location_icon">
                            <div className="titletip">
                                {item?.destination_cityname ?
                                    <span>{item?.destination_cityname}, </span> : null
                                }
                                <span>{item?.destination_countryname}</span>
                            </div>
                        </div>
                        :
                        <div className="flag_icon">
                            <div className='no_flag' title='Country details not available'></div>
                        </div>
                    }
                </span>
            }
            <AMToolTipAd
                classes={{
                    popper: 'issue-hostname-list-dest-popper'
                }}
                title={
                    <span className='ellips-hostname-issue'>
                        <div className="copy_field">
                            <div className="ellipsis_hostname droplist issue-page">
                                <div className="issue-hostname-list-dest-tool">
                                    {
                                        item.destination_hostname != 'N.A.' && item.destination_hostname != item.destination_ip ?
                                            <h2><strong>Hostname - </strong>{item.destination_hostname}</h2> : null
                                    }

                                    {<h2>
                                        <span className="ellipsis_idassetname-ip"><strong>IP:Port</strong>{
                                            item.destination_ip.startsWith('127.') ? ' -  N.A.' :
                                                ` - ${item.destination_ip.toString()}:${item.destination_port.toString()}`}</span>
                                        <div className="copy_idassetname" style={{ marginLeft: '100%' }} title="Copy this text to Clipboard" onClick={() => navigator.clipboard.writeText(`${item.destination_ip.toString()}:${item.destination_port.toString()}`)}></div>
                                    </h2>
                                    }
                                    {item.dest_mac ?
                                        <h2 title={item.dest_mac.toString()}>
                                            <span className="ellipsis_idassetname"><strong>MAC</strong>{` - ${item.dest_mac.toString()}`}</span>
                                            <div className="copy_idassetname" title="Copy this text to Clipboard" onClick={() => navigator.clipboard.writeText(`${item.dest_mac.toString()}`)}></div>
                                        </h2> : null
                                    }
                                </div></div>
                        </div>
                    </span>
                }
            >
                <span className='ellips-hostname-issue'>
                    <div className="copy_field">
                        <div className="ellipsis_hostname droplist issue-page">
                            {item.destination_hostname ? item.destination_hostname : item.destination_hostname}
                        </div>
                    </div>
                    <InfoIcon style={{ fontSize: 14, color: '#d4d8e1', marginTop: 2, transform: 'skewX(-10deg)' }} />
                </span>
            </AMToolTipAd>
            <span style={{ marginLeft: '2px' }} className="copy_hostname" title="Copy this text to Clipboard" onClick={() => navigator.clipboard.writeText(item.destination_hostname ? item.destination_hostname : '')}></span>
        </div>
    </div >
}

