import { Column, IHeaderParams } from "@ag-grid-community/core";
import React, { useCallback, useEffect } from "react";
import MenuItem from "@mui/material/MenuItem";
import { Menu } from "@mui/material";
import "./AdditionalResultCustom.scss";

interface CustomHeaderProps extends IHeaderParams {
    handleMenuItemClick,
    onFieldSelect,
    fields,
    gridRef
}

export const AdditionalResultsCustomHeader = (props: CustomHeaderProps) => {

    const { displayName, fields, onFieldSelect, gridRef, api, column } = props
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef<HTMLDivElement>(null);
    const [selectedField, setSelectedField] = React.useState(null);
    /* const [fields, setFields] = React.useState([]); */

    React.useEffect(() => {
        document.addEventListener('scroll', handleClose)
        return () => document.removeEventListener('scroll', handleClose)
    }, []);

    const handleClose = (event: Event) => {
        event.stopPropagation();
        if (
            anchorRef.current &&
            anchorRef.current.contains(event.target as HTMLElement)
        ) {
            return;
        }
        setOpen(false);
    };

    const handleToggle = (event) => {
        event.stopPropagation();
        setOpen((prevOpen) => !prevOpen);
    };

    useEffect(() => {
        if (gridRef?.current) {

        }
    }, [fields, gridRef?.current])

    const handleMenuItemClick = (event, field) => {
        event.stopPropagation();
        setSelectedField(field);
        setOpen(false);
        if (onFieldSelect) {
            onFieldSelect(field['dataField'] ? field : null, fields.map(item => item['search_key']))
        }
    };

    const updateSortIcons = useCallback(() => {
        if (api) {
            const columnStates = api?.getColumnState();
            columnStates?.forEach((state) => {
                const el = document.getElementById(state.colId + '-sorter') as HTMLSpanElement;
                if (!el) return;

                el.classList.remove('ag-icon-asc', 'ag-icon-desc', 'ag-icon-sort', 'ag-icon-none');
                if (state.sort === 'asc') {
                    el.classList.add('ag-icon-asc');
                } else if (state.sort === 'desc') {
                    el.classList.add('ag-icon-desc');
                } else {
                    el.classList.add('ag-icon-sort');
                    el.classList.add('ag-icon-none');
                }
            });
        }

    }, [api]);

    useEffect(() => {
        const onSortChanged = () => {
            updateSortIcons();
        };

        api?.addEventListener('sortChanged', onSortChanged);
        updateSortIcons(); // Initial update

        return () => {
            api?.removeEventListener('sortChanged', onSortChanged);
        };
    }, [api, updateSortIcons]);


    const handleSort = () => {
        const colId = column.getColId();
        const currentSortState = api
            .getColumnState()
            .find((state) => state.colId === colId);
        const newSort = currentSortState?.sort === 'desc' ? 'asc' : 'desc';

        const updatedColumnState = api
            .getColumnState()
            .map((state) => ({
                colId: state.colId,
                sort: state.colId === colId ? newSort : null,
            }));

        api.applyColumnState({ state: updatedColumnState });
    };

    return (
        <div className="additiona-result-custom-header-container"
            onClick={handleSort}
            style={{
                display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column',
                cursor: 'pointer'
            }}>
            {/* Column Label */}
            <div
                style={{ flexGrow: 1 }}
            >
                <span className="ag-header-cell-text additiona-result-custom-header-label"
                >
                    {displayName}
                </span>
                {fields.length > 1 &&
                    <div className="additional-result-container">

                        <div style={{ justifyContent: "center" }}>
                            <div className="selected-field ag-header-cell-text">Additional<br />Results<br /></div>
                            <div onClick={(e) => handleToggle(e)} ref={anchorRef} className="additional_results_icon"></div>
                        </div>
                        <Menu
                            anchorEl={anchorRef.current}
                            id="posture-additional-results-menu"
                            open={open}
                            onClose={handleClose}
                            className={"additional-result-menu"}
                            transformOrigin={{ horizontal: 'center', vertical: 'top' }}
                            anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
                        >
                            <MenuItem
                                key={'select_field'}
                                disableRipple={true}
                                className={`additional-result-menu-header`}
                            >
                                <div>Select Field</div>
                            </MenuItem>
                            {fields
                                .map((field) => (
                                    <MenuItem
                                        key={field['search_key']}
                                        onClick={(event) => handleMenuItemClick(event, field)}
                                        disableRipple={true}
                                        className={displayName && displayName === field['value'] ? `additional-result-menu-item additional-result-menu-item-selected` : `additional-result-menu-item`}
                                    >
                                        <div>{field['label']}</div>
                                    </MenuItem>
                                ))}
                        </Menu>
                    </div>
                }
            </div>


            <span
                id={column.getColId() + '-sorter'}
                className="ag-icon ag-icon-sort"
                style={{ marginLeft: 4, position: 'absolute', right: 0 }}
            ></span>

        </div>
    )
}