import React, { useEffect, useState } from "react"
import Popup from "reactjs-popup"
import { getCustomLabelForRuleName } from "../../../utils/util-methods";
import BaseAMAdminGrid from "../../../components/core/AMTable/BaseAMAdminGrid";
import { ColDef } from "@ag-grid-community/core";

interface SuspectedListProps {
    resultArray: [],
    source: string,
    handleClose(): any,
    matchingRule: string
}

const data = [
    {
        "hostname": "purnima-windows-6",
        "flow_id": "ac4fd48d-5834-4411-8d49-8595f592a605",
        "probability": 100,
        "gen_timestamp": "2023-06-01T20:46:53.909Z",
        "is_known_identity": true,
        "username": "abhishek.lokhande@authmind.com",
        "source_ip": "10.16.0.9",
        "frequency": 5384,
        confidence: 'Highest'
    },
    {
        "hostname": "purnima-windows-1",
        "flow_id": "a6940335-b6e4-461a-a0ac-1c2668b2dcbf",
        "probability": 100,
        "gen_timestamp": "2023-06-01T20:31:10.008Z",
        "is_known_identity": true,
        "username": "abhishek.lokhande@authmind.com",
        "source_ip": "10.16.0.3",
        "frequency": 1816,
        confidence: 'High'
    },
    {
        "hostname": "purnima-windows-1",
        "flow_id": "a6940335-b6e4-461a-a0ac-1c2668b2dcbf",
        "probability": 100,
        "gen_timestamp": "2023-06-01T20:31:10.008Z",
        "is_known_identity": true,
        "username": "abhishek.lokhande@authmind.com",
        "source_ip": "10.16.0.3",
        "frequency": 1816,
        confidence: 'Medium'
    },
    {
        "hostname": "purnima-windows-1",
        "flow_id": "a6940335-b6e4-461a-a0ac-1c2668b2dcbf",
        "probability": 100,
        "gen_timestamp": "2023-06-01T20:31:10.008Z",
        "is_known_identity": true,
        "username": "abhishek.lokhande@authmind.com",
        "source_ip": "10.16.0.3",
        "frequency": 1816,
        confidence: 'Low'
    },
    {
        "hostname": "purnima-windows-1",
        "flow_id": "a6940335-b6e4-461a-a0ac-1c2668b2dcbf",
        "probability": 100,
        "gen_timestamp": "2023-06-01T20:31:10.008Z",
        "is_known_identity": true,
        "username": "abhishek.lokhande@authmind.com",
        "source_ip": "10.16.0.3",
        "frequency": 1816,
        confidence: 'Lowest'
    }
] as any;

const SusColumns: Array<ColDef> = [
    {
        field: 'hostname',
        headerName: 'Hostname',

    },
    {
        field: 'source_ip',
        headerName: 'Host IP',
    },
    {
        field: 'username',
        headerName: 'User on the host'

    },

    /*  {
         key: 'frequency',
         label: 'Frequency'
     }, */
    {
        field: 'probability',
        headerName: 'Confidence',
        sort: "desc",
        cellRenderer: ({ data }) => {
            const item = data
            return <>{
                item.probability >= 90 && item.probability <= 100 ? 'Highest' :
                    item.probability >= 80 && item.probability <= 89 ? 'High' :
                        item.probability >= 50 && item.probability <= 79 ? 'Medium' :
                            item.probability >= 20 && item.probability <= 49 ? 'Low' :
                                item.probability === 1 ? 'Absolute' : 'Lowest'
            }</>
        }
    },
    /*    {
           key: 'is_known_identity',
           label: 'Is Known'
       },
       {
           key: 'flow_id',
           label: 'Flow Id'
       } */
]

export const SuspectedList = ({ handleClose, resultArray, source, matchingRule }: SuspectedListProps) => {

    const [selectedColumn, setSelectedColumn] = useState('probability');
    const [currentSort, setCurrentSort] = useState(' tablesort_down tablesort_down_selected ');
    const [result, setResult] = useState(resultArray);

    const LoadSorting = (field: string, selectedColumn: string) => {
        if (selectedColumn === field && currentSort === ' tablesort_down tablesort_down_selected ') {
            setCurrentSort(' tablesort_up tablesort_up_selected ')
            sortList(field, 'desc');
        } else {
            setCurrentSort(' tablesort_down tablesort_down_selected ')
            sortList(field, 'asc');
        }
    };

    const sortList = (column: string, sort_by: string) => {
        result.sort((a: any, b: any) => (sort_by === 'desc' ? (a[column] > b[column]) ? 1 : ((b[column] > a[column]) ? -1 : 0) :
            (a[column] < b[column]) ? 1 : ((b[column] < a[column]) ? -1 : 0)));
    }

    useEffect(() => {
        const res = resultArray.map((i: any) => {
            if (i?.source_ip.startsWith('127.')) {
                i.source_ip = 'NA'
            }
            return i;
        }) as any;
        setResult(res);
    }, [resultArray]);

    const onHeaderClick = (column: any) => {
        LoadSorting(column.key, selectedColumn);
        setSelectedColumn(column.key);
    }

    return <Popup
        closeOnDocumentClick={false}
        overlayStyle={{ zIndex: 15001, background: "rgba(227, 242, 253, .6)" }}
        open={true}
        closeOnEscape={false}
        modal
    >
        <div className={"modal suspected-container"}>
            <div className={"close"} onClick={handleClose}></div>
            <div className={"header"} style={{ fontSize: '22px' }}>
                <div className="sus-title"
                    title={`Suspected sources for ${getCustomLabelForRuleName(matchingRule)} on ${source}`}
                >
                    Suspected sources for {getCustomLabelForRuleName(matchingRule)} on {source}
                </div></div>
            <div className={"content"} style={{
                overflow: 'hidden', paddingTop: '15px', marginTop: 15
            }}>
                <div className="scrollbar-container suspected-container-content" style={{
                    overflowX: 'hidden',
                    overflowY: 'auto'
                }}>

                    <BaseAMAdminGrid
                        columnDefs={SusColumns}
                        modelType="clientSide"
                        rowData={result}
                        pagination={false}
                        classes={{ gridContainerClass: 'non-sticky-header-container' }}
                    />
                </div>
                <div>
                    <><br></br><strong>
                        <div className="margintop5"><i className="font12 ">{
                            result?.length && result[0].probability === 1 ?
                                <span> Note: Confidence value is shown as 'Absolute' based on current available data. For a better estimate, ensure that a flow sensor is present in the same network.</span>
                                : <span>Note: The confidence can change as more data arrives.</span>}</i></div></strong></>
                </div>
            </div>

            <div className="shadow_footer fl" style={{ marginTop: 0 }}></div>
            <div className="popup_footer fl">
                {/* <div className="policy_defualt_button" onClick={handleClose}>No</div> */}
                <div className="policy_defualt_button" style={{ float: 'right' }} onClick={handleClose}>Ok</div>
            </div>
        </div>
    </Popup >
}