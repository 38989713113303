import React, { useState } from 'react';
import TagsInput from 'react-tagsinput';
import 'react-tagsinput/react-tagsinput.css';

const AMTagInput = (props: any) => {
    const [currentInput, setCurrentInput] = useState('');

    const handlePaste = (e: React.ClipboardEvent) => {
        const pastedData = e.clipboardData.getData('Text');
        const tags = pastedData
            .split(/,|\n|\s+/)
            .map(tag => tag.trim())
            .filter(tag => tag);

        if (tags.length > 1) {
            e.preventDefault();
            props.onChange([...props.value, ...tags]);
        }
    };

    return (
        <TagsInput
            {...props}
            value={props.value}
            onChange={props.onChange}
            addKeys={[9, 13, 188, 32, 10]}
            inputProps={{
                value: currentInput,
                onChange: (e: React.ChangeEvent<HTMLInputElement>) => setCurrentInput(e.target.value),
                onPaste: handlePaste,
            }}
        />
    );
};

export default AMTagInput;
