import './PlaybookRules.scss';
import { PlaybookTableActions } from "./PlaybookTableActions";
import { IIdAssetState, IdPopUp } from "../IdPopUp";
import React, { useEffect, useRef, useState } from "react";
import { AssetPatternFencingConfig, AssetPatternFormConfig, IdPatternFencingConfig, IdPatternFormConfig, InterPatternFormConfig } from "../config/IdPatternConfig";
import { getDefaultConfigState, loadFieldsByIssueType } from "../../helpers/playbook-helper";
import { IPatternConfigItem, IPatternPayload } from "../PatternForm/PatternFormGenerator";
import { PlaybookConfig } from './PlaybookConfig';
import { deepCompare, deepCopyFunction, getResolutionMatch } from '../../../../utils/util-methods';
import { usePlaybookConfig } from '../useCustomConfig';
import { renderList } from './PlaybookGeneral';
import { GLOBAL_SERVICE } from '../../../services/GlobalService';
import { PlaybookAdvanceddSearchConfig } from '../../constants/PlaybookAdvancedSearchConfig';
import { Api } from '../../../../components/Axios';
import { IPlaybookRuleState, IPlaybookRulesProps } from './PlaybookTypes';
import { RuleType } from '../../constants/Constants';
import InfoIcon from '@mui/icons-material/Info';

const TableHeaders = ['Parameters', 'Identity/Source', 'Access Control/Directory', 'Asset/Destination'];

const IdAssetConfig = {
    identity: {
        title: 'Identity/Source',
        fieldType: 'source_identity',
        formConfig: IdPatternFormConfig,
        defaultState: { type: ['All Identities'], pattern: {} }
    },
    asset: {
        title: 'Asset/Destination',
        fieldType: 'destination_asset',
        formConfig: AssetPatternFormConfig,
        defaultState: { type: ['All Assets'], pattern: {} }
    },
    intermediary: {
        title: 'Access Control/Directory',
        fieldType: 'directory',
        formConfig: InterPatternFormConfig,
        defaultState: { type: ['Any Access Control'], pattern: {} }
    }
}

const FencingIdAssetConfig = {
    identity: {
        formConfig: IdPatternFencingConfig
    },
    asset: {
        formConfig: AssetPatternFencingConfig
    },
    intermediary: { formConfig: InterPatternFormConfig, }
}

const DirectoryEnabledPb = [RuleType.ENUM_AD_USERS.toString(),
RuleType.ENUM_AD_ADMINS.toString(),
RuleType.UNAUTHORIZED_ASSET_ACCESS.toString(), RuleType.UNAUTHORIZED_IDENTITY_ACCESS.toString(),
RuleType.LACK_OF_MFA]

const IdentityDisabledPb = [RuleType.DEVIATION_IN_DAILY_ASSET.toString()];
const AssetDisabledPb = [RuleType.ACCOUNT_TAKEOVER.toString(), RuleType.DEVIATION_IDENTITY_ACTIVITY.toString(), RuleType.UNUSUAL_USER_ACCESS.toString()];

export const PlaybookRules = ({
    defaultRules = [], ruleType, formType,
    saveRules
}: IPlaybookRulesProps) => {

    const [idAssetFlag, setIdAssetFlag] = useState('');
    const [defaultRule, setDefaultRule] = useState<IPlaybookRuleState>();
    const [rules, setRules] = useState<Array<IPlaybookRuleState>>(defaultRules);
    const [currentUpdateState, setCurrentUpdateState] = useState<IIdAssetState>();
    const [currentUpdateIndex, setCurrentUpdateIndex] = useState<number>(formType == 'view' ? -1 : 0);
    const [currentUpdateConfig, setCurrentUpdateConfig] = useState<any>();
    const rulesRef = useRef<HTMLDivElement>(null);
    const [showConfig, setShowConfig] = useState(false);
    const [isView, setIsView] = useState(false);
    const isDirectoryEnabled = DirectoryEnabledPb.includes(ruleType)
    const isAssetDisabled = AssetDisabledPb.includes(ruleType)
    const isIdentityDisabled = IdentityDisabledPb.includes(ruleType);
    const { loadDefaultPlaybookConfigViewByRuleType } = usePlaybookConfig();

    useEffect(() => {
        const assetType = RuleType.UNAUTHORIZED_ASSET_ACCESS == ruleType ? ['Custom List'] : ['All Assets'];
        const identityType = RuleType.UNAUTHORIZED_IDENTITY_ACCESS == ruleType ? ['Custom List'] : ['All Identities'];
        setDefaultRule({
            asset: { type: assetType, pattern: {} },
            identity: {
                type: identityType, pattern: {}
            },
            intermediary: { type: ['Any Access Control'], pattern: {} },
            config: getDefaultConfigState(ruleType || '')
        });

        if (ruleType == RuleType.ACCOUNT_TAKEOVER || ruleType === RuleType.LACK_OF_MFA || RuleType.DEVIATION_IDENTITY_ACTIVITY == ruleType || RuleType.UNUSUAL_USER_ACCESS == ruleType) {
            IdAssetConfig.intermediary.title = "Access Control"
        }

    }, [ruleType])

    useEffect(() => {
        if (rules?.length > 0 && ruleType)
            for (let i = 0; i < rules?.length || 0; i++) {
                adjustConfigureListing(i)
            }
        setTimeout(() => updateMarginForConfigure());
    }, [rules, ruleType])

    useEffect(() => {
        setTimeout(() => {
            if (rules?.length > 0 && ruleType)
                for (let i = 0; i < rules?.length || 0; i++) {
                    adjustConfigureListing(i)
                    updateMarginForConfigure()
                }
        })
    }, [rules])

    useEffect(() => {
        if (defaultRule && rules.length == 0) {
            setRules([...rules, { ...defaultRule }]);
        }
    }, [JSON.stringify(defaultRule)])

    const handleSaveRule = (data: IIdAssetState) => {
        const _cloneRules = [...rules];
        if (currentUpdateIndex > -1) {
            const rule = _cloneRules[currentUpdateIndex];
            rule[idAssetFlag as keyof typeof rule] = data;
            setRules(_cloneRules);
        }
        setIdAssetFlag('');
        setCurrentUpdateState(undefined);
    }

    useEffect(() => {
        if (!deepCompare(rules, defaultRules)) {
            // Merge missing fields from existing playbooks response from API.
            if (ruleType === RuleType.AUTH_PROTOCOL_QUALITY) {
                const newRules = getAuthProtocolQualityMissingFields(defaultRules, defaultRule)
                setRules(newRules);
            } else {
                setRules(defaultRules);
            }
        }
    }, [defaultRules])

    const getAuthProtocolQualityMissingFields = (defaultRulesInput, defaultRuleStateInput) => {
        return [...defaultRulesInput].map(item => {
            if (item['config'] && item['config']['commonProcValue'] && item['config']['commonProcValue'].length > 0 && defaultRule) {
                item['config']['commonProcValue'] = mergeArraysByKey(defaultRuleStateInput['config']['commonProcValue'], item['config']['commonProcValue'], 'name')
            }
            return item;
        })
    }

    const mergeArraysByKey = (targetArr, sourceArr, key) => {
        let res: Array<string> = [];
        targetArr.forEach(item => {
            let matchFound = false;
            sourceArr.forEach(innerItem => {
                if (innerItem[key] === item[key]) {
                    res.push(innerItem);
                    matchFound = true;
                }
            })
            if (!matchFound)
                res.push(item);
        })
        return res;
    }

    const adjustConfigureListing = (updateIndex: number) => {
        if (rulesRef?.current && updateIndex > -1) {
            const el = rulesRef.current.getElementsByClassName('ad-grid-data-table-row')[updateIndex];
            if (el) {
                const confingEl = el.getElementsByClassName('grid-item-data')[0] //Configurations
                if (confingEl) {
                    const q = confingEl.querySelectorAll('.grid-data-configure li ul li')
                    q.forEach((ele: Element, index: number) => {
                        if (ele.innerHTML == 'More...') {
                            ele.remove();
                        }
                        /*  if (index > 1) { */
                        ele.classList.remove('hidden')
                        /* } */
                    });
                    const refList = confingEl.querySelectorAll('.grid-data-configure ul li ul li')
                    if (refList?.length === 0) {
                        const newRefList = confingEl.querySelector('.grid-data-configure ul li');
                        if (newRefList && newRefList?.textContent && newRefList?.textContent.length > 250) {
                            let newStr = newRefList?.textContent.substring(0, 250);
                            if (newStr.slice(-1) == ',') {
                                newStr = newStr.slice(0, newStr.length - 1);
                            }
                            newRefList.textContent = newStr + '...';
                        }
                    } else {
                        if (refList?.length > 2) {
                            refList.forEach((ele: Element, index: number) => {
                                if (index > 1) {
                                    ele.classList.add('hidden')
                                }
                            });
                            const parentEle = confingEl.querySelector('.grid-data-configure ul li ul');
                            const eleLi = document.createElement('li')
                            eleLi.innerHTML = 'More...';
                            parentEle?.appendChild(eleLi);
                        }
                    }
                }
            }
        }
    }

    const handleSaveRuleConfig = (data: any) => {
        const _cloneRules = [...rules];
        if (currentUpdateIndex > -1) {
            const rule = _cloneRules[currentUpdateIndex];
            rule.config = data;
            setRules(_cloneRules);
            setShowConfig(false);
            setTimeout(() => adjustConfigureListing(currentUpdateIndex), 100);
        }
    }

    useEffect(() => {
        (() => {
            Api.get('issues/uniquefields')
                .then(async (res: any) => {
                    let categoryList = await GLOBAL_SERVICE.Category.GET();
                    // setCategoryList(categoryList);

                    const data = { ...res.data, ...categoryList };
                    data['d_type'] = ['Application',
                        'Device',
                        'Service',
                        'Service/Computer Account',
                        "Service/Key and Secret",
                        'Service/Service Principal',
                        "Service/Token"];
                    data['s_type'] = ['Non Human Identities (NHI)',
                        'Application',
                        'Device',
                        'Service',
                        'Service/Computer Account',
                        "Service/Key and Secret",
                        'Service/Service Principal',
                        "Service/Token",
                        'Users',
                        'User',
                        'User/Agent',
                        'User/Background Browsing'];

                    /* transform data before sending */
                    Object.keys(data).map((s: string) => {
                        const c = PlaybookAdvanceddSearchConfig.find(
                            (i: IPatternConfigItem) => i.id === s
                        );
                        if (c) {
                            c.selectOptions = data[s];
                        }
                    });
                    // setState(JSON.parse(JSON.stringify(state)));
                })
                .catch((er) => console.log(er));
        })();
    }, []);

    const renderListPbRules = (list: Record<string, IPatternPayload>) => {
        return renderList(list);
    }

    const deleteRule = (index: number) => {
        const _cloneRules = [...rules];
        _cloneRules.splice(index, 1);
        setRules(_cloneRules);
        if (index - 1 <= 0 && _cloneRules.length == 1) {
            setCurrentUpdateIndex(0);
        } else if (currentUpdateIndex == index) {
            setCurrentUpdateIndex(-1);
        }
    }

    const copyRule = (index: number) => {
        const _cloneRules = [...rules];
        _cloneRules.push({ ..._cloneRules[index] });
        setRules(_cloneRules);
        setCurrentUpdateIndex(_cloneRules.length - 1);
    }

    const addRules = () => {
        if (defaultRule) {
            const deepDefault = deepCopyFunction(defaultRule)
            setRules([...rules, { ...deepDefault }]);
            setCurrentUpdateIndex(rules.length);
            adjustConfigureListing(rules.length)
        }
    }

    useEffect(() => {
        if (rulesRef?.current) {
            rulesRef.current.scrollTop = rulesRef.current?.scrollHeight;
        }
    }, [rules])

    const EditViewIcon = ({ rule, index, entity }: any) => {
        return <>{
            (currentUpdateIndex == index || rules.length == 1) && entity && rule && formType != 'view' &&
            < div className="edit_cell_icon" onClick={() => {
                // console.log(rule[entity as keyof typeof rule]);
                setCurrentUpdateState(rule[entity as keyof typeof rule]);
                setIdAssetFlag(entity);
                setIsView(false);
                // setCurrentUpdateIndex(index);
            }}></div >
        }
            {
                ((currentUpdateIndex != index && Object.keys(rule[entity as keyof typeof rule].pattern).length >= 5))
                &&
                <div className='shadowbox'
                    onClick={() => {
                        setCurrentUpdateState(rule[entity as keyof typeof rule]);
                        setIdAssetFlag(entity);
                        /* setCurrentUpdateIndex(index); */
                        setIsView(true);
                    }}
                >...</div>
            }
        </>
    }

    useEffect(() => {
        if (rulesRef?.current) {
            const scrollHeight = rulesRef.current.scrollHeight;
            const clientHeight = rulesRef.current.clientHeight;
            const actionColumn: HTMLCollectionOf<Element> = rulesRef.current.getElementsByClassName('grid-data-action-column');
            const elemesArray = Array.from(actionColumn);
            if (clientHeight < scrollHeight) {
                for (let el of elemesArray) {
                    el.classList.remove('grid-data-no-scroll');
                }
            } else {
                for (let el of elemesArray) {
                    el.classList.add('grid-data-no-scroll');
                }
            }
        }
        saveRules(rules)
    }, [rules]);

    const updateMarginForConfigure = () => {
        if (rulesRef?.current) {
            const marginItem = rulesRef.current.querySelectorAll('.grid-item-data div.grid-top-15')[0] as HTMLDivElement;
            if (marginItem?.parentElement) {
                const el = marginItem.parentElement.getElementsByClassName('grid-data-configure')[0];
                const scrollHeight = el.scrollHeight;
                const clientHeight = el.clientHeight;
                if (clientHeight < scrollHeight) {
                    marginItem.style.marginTop = `${getResolutionMatch(30, 15)}px`;
                } else {
                    marginItem.style.marginTop = `0px`;
                }
            }
        }
    }

    useEffect(() => {
        updateMarginForConfigure();
    }, [rules, currentUpdateIndex])

    const showMore = (index: any): boolean => {
        let elemText = (document.querySelector(`#parameter_${index}`) as HTMLHeadingElement)?.innerText;
        if (!elemText) return false;
        return elemText.indexOf("...") > -1;
    }

    useEffect(() => {
        const divEl = document.getElementsByClassName('ad-grid-table-container')[0] as HTMLDivElement;
        divEl.addEventListener('scroll', (e: Event) => {
            if (divEl.scrollTop > 0) {
                divEl.style.marginTop = `${getResolutionMatch(20, 10)}px`
            } else {
                divEl.style.marginTop = `0px`;
            }
        })
    }, []
    )

    const getPlaceholderText = (inputText, inputRuleType) => {
        const PLACEHOLDER_TEXT = ['All Assets', 'All Identities', 'Custom List'];

        if (inputRuleType == ruleType && PLACEHOLDER_TEXT.includes(inputText)) {
            return 'Configure';
        }
        return inputText;
    }

    const getFormConfigByRuleType = (formConfig: any, idAssetFlagLocal: string): any => {
        let res = { ...formConfig };
        if (ruleType === RuleType.UNAUTHORIZED_ASSET_ACCESS && idAssetFlagLocal === "asset") {
            res.fields = [];
        } else if (ruleType === RuleType.UNAUTHORIZED_IDENTITY_ACCESS && idAssetFlagLocal === "identity") {
            res.fields = [];
        }
        return res;
    }

    const showCustomList = (): boolean => {
        let res = true;
        if (ruleType === RuleType.UNAUTHORIZED_ASSET_ACCESS && idAssetFlag === "asset") {
            res = false;
        } else if (ruleType === RuleType.UNAUTHORIZED_IDENTITY_ACCESS && idAssetFlag === "identity") {
            res = false;
        }
        return res;
    }

    return <>
        <hr></hr>
        <div className="adv-pb-rules-section">
            {RuleType.UNAUTHORIZED_ASSET_ACCESS === ruleType
                && <div className='adv-pb-rules-banner'>
                    <InfoIcon style={{ fontSize: '1.5em', color: 'orange', marginBottom: '0.2em' }} />
                    <span className='banner-text'>Note: The rules below define what is allowed and all other user accesses to the specified assets will result in an incident.</span></div>
            }
            {RuleType.UNAUTHORIZED_IDENTITY_ACCESS === ruleType
                && <div className='adv-pb-rules-banner'>
                    <InfoIcon style={{ fontSize: '1.5em', color: 'orange', marginBottom: '0.2em' }} />
                    <span className='banner-text'>Note: The rules below define what accesses or access controls  are allowed by the specified identity(s). Any exceptions will result in an incident.</span></div>
            }
            <div className='margin-top-bottom-8 playbook-rules'><span
                className='section-heading'>Rules</span></div>
            <div className='ad-grid-table-container scrollbar-container' ref={rulesRef}>
                <table className='sticky_table_top ad-grid-table rounded-table'>
                    <thead className="ad-grid-header-table-header">
                        <tr>
                            <th className="grid-header grid-item-header-number" style={{ width: '10px' }}><p>#</p></th>
                            {TableHeaders.map((i: string, index: number) => {
                                if (AssetDisabledPb.includes(ruleType) && i === 'Asset/Destination') {
                                    return null
                                } else if (RuleType.DEVIATION_IN_DAILY_ASSET === ruleType && i === 'Identity/Source') {
                                    return null;
                                } else if ((RuleType.UNAUTHORIZED_ASSET_ACCESS === ruleType || RuleType.UNAUTHORIZED_IDENTITY_ACCESS === ruleType) && i === 'Parameters') {
                                    return null
                                } else if (!isDirectoryEnabled && i === 'Access Control/Directory') {
                                    return null;
                                } else if ((RuleType.LACK_OF_MFA === ruleType) && isDirectoryEnabled
                                    && i === 'Access Control/Directory') {
                                    return <th className={"grid-item-header grid-header align_left"}
                                    ><p>{'Access Control'}</p></th>
                                } else
                                    return <th className={"grid-item-header grid-header align_left"}
                                    ><p>{i}</p></th>
                            }).filter((i: JSX.Element | null) => i)
                            }
                            <th className="grid-item-header-number" style={{ width: '10px' }}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {rules.map((rule: IPlaybookRuleState, index: number) => {
                            return <><tr className="ad-grid-data-table-row">
                                <td className="grid-data grid-item-data-number align_center" style={{ width: '10px' }}><span>{index + 1}</span></td>
                                {RuleType.UNAUTHORIZED_ASSET_ACCESS !== ruleType && RuleType.UNAUTHORIZED_IDENTITY_ACCESS !== ruleType && <td className={"grid-item-data grid-data align_left"}>
                                    <p className='grid-data-configure'>{
                                        !rules[index].config ?
                                            'Configure Parameters' : rules[index].config === 'NA' ?
                                                'No parameters to configure' :
                                                <ul id={`parameter_${index}`}>
                                                    {loadDefaultPlaybookConfigViewByRuleType(ruleType, { rules: { config: rules[index].config || {} } })}
                                                </ul>
                                    }</p>
                                    {
                                        (currentUpdateIndex == index || rules.length ==
                                            1) && formType != 'view' && rules[index].config != 'NA'
                                        &&
                                        <><div className='grid-top-15'></div>
                                            <div className="edit_cell_icon" onClick={() => {
                                                setCurrentUpdateConfig(rules[index].config);
                                                setShowConfig(true);
                                                setIsView(false);
                                            }}></div></>
                                    }
                                    {
                                        ((rules[index].config
                                            && rules[index].config != 'NA' && currentUpdateIndex != index)
                                            || ((rules[index].config
                                                && rules[index].config != 'NA') && formType == 'view')
                                        ) && showMore(index) &&
                                        <div className={'shadowbox' +
                                            ([RuleType.SHADOW_DIRECTORY.toString(), RuleType.COMPROMISED_PASSWORD.toString()
                                            ].includes(ruleType) ?
                                                ' absolute_right_10' : '')
                                        }
                                            onClick={() => {
                                                setCurrentUpdateConfig(rules[index].config);
                                                setShowConfig(true);
                                                setIsView(true);
                                            }}
                                        >...</div>
                                    }
                                    {
                                        !rules[index].config && rules[index].config !== 'NA' && formType !== 'view' &&
                                        <div style={{ color: 'red', position: 'absolute', top: 0, right: 0 }}>*</div>}
                                </td>}
                                {!isIdentityDisabled &&
                                    <td className={"grid-item-data grid-data align_left"}>
                                        <p className={(isDirectoryEnabled ? ' width250' : '')}>{rule?.identity.pattern && Object.keys(rule?.identity?.pattern).length ?
                                            renderListPbRules(rule?.identity.pattern)
                                            : getPlaceholderText(rule?.identity.type[0], RuleType.UNAUTHORIZED_IDENTITY_ACCESS)}</p>
                                        <EditViewIcon rule={rule} index={index} entity={'identity'} />
                                    </td>}
                                {
                                    isDirectoryEnabled &&
                                    <td className={"grid-item-data grid-data align_left"}>
                                        <p className={(isDirectoryEnabled ? ' width250' : '')}>{rule?.intermediary.pattern && Object.keys(rule?.intermediary?.pattern).length ? renderListPbRules(rule?.intermediary.pattern) :
                                            rule?.intermediary.type[0]}</p>
                                        <EditViewIcon rule={rule} index={index} entity={'intermediary'} />
                                    </td>
                                }
                                {!isAssetDisabled && <td className={"grid-item-data grid-data align_left"}>
                                    <p className={(isDirectoryEnabled ? ' width250' : '')}>{rule?.asset.pattern && Object.keys(rule?.asset?.pattern).length ? renderListPbRules(rule?.asset.pattern) :
                                        getPlaceholderText(rule?.asset.type[0], RuleType.UNAUTHORIZED_ASSET_ACCESS)}</p>
                                    <EditViewIcon rule={rule} index={index} entity={'asset'} />
                                </td>}
                                <td className="grid-data grid-item-data-number grid-data-action-column" style={{ width: '10px' }}>
                                    <PlaybookTableActions
                                        actions={[
                                            ...(currentUpdateIndex != index ? [
                                                {
                                                    actionId: 'edit',
                                                    actionLabel: 'Edit', actionCallback: () => {
                                                        setCurrentUpdateIndex(index);
                                                        adjustConfigureListing(index)
                                                    }
                                                }] : []),
                                            {
                                                actionId: 'copy', actionLabel: 'Copy', actionCallback: () => {
                                                    copyRule(index)
                                                }
                                            },
                                            ...(rules.length != 1 ? [{
                                                actionId: 'delete', actionLabel: 'Delete', actionCallback: () => {
                                                    deleteRule(index)
                                                }
                                            }] : [])
                                        ]}
                                        rowData={{}}
                                        formType={formType}
                                    />
                                </td>
                            </tr >
                                {
                                    index != rules.length - 1 && <tr className='pb-rules-or-text'>
                                        <td colSpan={isDirectoryEnabled ? 6 : 5} style={{ borderRight: 'none' }}><div>OR</div></td></tr>
                                }
                            </>
                        })}
                    </tbody>
                </table>
            </div>
            {formType != 'view' &&
                < div className="pattern-form-add-filter" > <span className="pattern-form-add-filter span" onClick={addRules}>+</span></div >}
        </div >
        {
            idAssetFlag && currentUpdateState && <IdPopUp
                popFormConfig={ruleType == RuleType.UNAUTHORIZED_ASSET_ACCESS
                    || ruleType == RuleType.UNAUTHORIZED_IDENTITY_ACCESS ? getFormConfigByRuleType(FencingIdAssetConfig[idAssetFlag as keyof typeof IdAssetConfig].formConfig, idAssetFlag)
                    :
                    IdAssetConfig[idAssetFlag as keyof typeof IdAssetConfig].formConfig}
                patternFormConfig={loadFieldsByIssueType(ruleType, IdAssetConfig[idAssetFlag as keyof typeof IdAssetConfig].fieldType)}
                popUpTitle={IdAssetConfig[idAssetFlag as keyof typeof IdAssetConfig]?.title}
                handleCloseIdAssetPopUp={() => { setIdAssetFlag('') }}
                handleSaveIdAssetPopUp={(data: any) => {
                    handleSaveRule(data);
                }}
                formType={(formType == 'view' || isView) ? 'view' : 'create'}
                state={currentUpdateState}
                issueType={ruleType}
                showCustomList={showCustomList()}
            />
        }
        {
            showConfig && <PlaybookConfig ruleType={ruleType} isConfigSaved={false}
                saveRule={handleSaveRuleConfig} config={currentUpdateConfig}
                closeConfigPopUp={() => setShowConfig(false)}
                formType={(formType == 'view' || isView) ? 'view' : 'create'}
            />
        }
    </>
}