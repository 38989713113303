import React, { useEffect, useRef, useState,useMemo } from "react";
import HighchartsReact from "highcharts-react-official";
import * as Highcharts from "highcharts/highstock";
import { RiskLevelData } from "../../../types/response-types";
import "./RiskyProtocol.scss";
import { useHistory } from "react-router-dom";
import { getRiskLevelFromColor } from "../../../utils/risk-level";
import { critical_0_high_5_medium_8_low_8, critical_2_high_2_medium_4_low_0, critical_2_high_5_medium_8_low_8, critical_2_medium_2, critical_8_high_7_medium_4_low_8 } from "./dummyData";
import { convertToCommaValue, getResolutionMatch } from "../../../utils/util-methods";

type Props = {
    protocols:
        | {
              [protocol_name: string]: RiskLevelData;
          }
        | undefined
        | null;
    widgetId: string | undefined | null;
};

const RiskColor = new Map([
    [4, "#fa1262"],
    [3, "#fd7122"],
    [2, "#fcbf2e"],
    [1, "#cccccc"],
]);

function prepareChartData(protocols) {
    const objList = [];
    if (!protocols) {
        return objList;
    }
    for (const [riskId, color] of RiskColor) {
        Object.keys(protocols).forEach((p) => {
            let vl = protocols[p][riskId];
            objList.push({ name: p, y: vl, color });
        });
    }

    const filler = [...Array(12)].map((p) => {
        return { name: Date.now().toString(), y: 0, color: "transparent" };
    });
    const filtered = objList.filter((p) => p.y > 0);
    if (filtered?.length === 0) {
        return [];
    }
    if(filtered.length<12) {
        const diff = 12 - filtered.length;
        let left = Math.ceil(diff/2);
        let right = Math.floor(diff/2);
        const stuffedList = [];
        while(left){
            stuffedList.push({ name: Date.now().toString(), y: 0, color: "transparent" });
            left--;
        }
        stuffedList.push(...filtered);
        while(right){
            stuffedList.push({ name: Date.now().toString(), y: 0, color: "transparent" });
            right--;
        }
        return stuffedList;
    }
    //const finalList = filtered.concat(filler);
    //finalList.splice(20);
    //return finalList.reverse();
    return filtered;
}

function getElementByClass(selector: string) {
    let elem = document.getElementsByClassName(selector);
    return elem?.length > 0 ? elem[0] : null;
}

function scrollElement(timing, duration, elem, moveFactor,triggerId='') {

    let start = performance.now();
  
    requestAnimationFrame(function animate(time) {
      // timeFraction goes from 0 to 1
      let timeFraction = (time - start) / duration;
      if (timeFraction > 1) timeFraction = 1;
  
      // calculate the current animation state
      let progress = timing(timeFraction)
        if(elem){
            if(moveFactor === -1)
                scrollLeft(elem,progress);
            else
                scrollRight(elem,progress);
        }
      
  
      if (timeFraction < 1) {
        requestAnimationFrame(animate);
      }
    });
  }
  function scrollLeft(elem,progress) {
    const scrollWidth = elem.scrollWidth;
    const elemWidth = elem.clientWidth;
    const grossScrollValue = scrollWidth - elemWidth;
    if(elem.scrollLeft > 0)
    {
        elem.scrollTo( -1 * progress * 3 + elem.scrollLeft,0);
    }
    else {
        //alert('left scroll end');
    }
  }
  function scrollRight(elem,progress){
    const scrollWidth = elem.scrollWidth;
    const elemWidth = elem.clientWidth;
    const grossScrollValue = scrollWidth - elemWidth;
    if(Math.ceil(elem.scrollLeft) < grossScrollValue){
        elem.scrollTo(progress * 3 + elem.scrollLeft,0);
    }else{
        //alert('right scroll end')
    }
  }


const RiskyProtocol = ({ protocols, widgetId }: Props) => {
    const [chartOptions, setChartOptions] = useState<any>(null);
    const [chartData, setChartData] = useState<any>(null);
    const [maxValue,setMaxValue] = useState(5);
    const [totalProtocols,setTotalProtocols] = useState(0);
    const chartRef = useRef<{
        chart: Highcharts.Chart;
        container: React.RefObject<HTMLDivElement>;
    }>(null);

    let history = useHistory();

    const scroll = (e) => {
        const incrFactor = parseInt(e.target.getAttribute('data-incr')) || 1;
        const parentContainer = getElementByClass('risky-protocols-chart');
        const elem = parentContainer?.getElementsByClassName('highcharts-scrolling')[0];
        if(elem?.scrollLeft<=0) {
            getElementByClass('risky-left-scroll')?.setAttribute('class', 'risky-left-scroll disabled')
        }
        else{
            getElementByClass('risky-left-scroll')?.setAttribute('class', 'risky-left-scroll')
        }

        if(Math.ceil(elem?.scrollLeft) >= (elem?.scrollWidth - elem?.clientWidth)) {
            getElementByClass('risky-right-scroll')?.setAttribute('class', 'risky-right-scroll disabled')
        }
        else{
            getElementByClass('risky-right-scroll')?.setAttribute('class', 'risky-right-scroll')
        }
        scrollElement((timeFraction)=>{return timeFraction },500,elem,incrFactor);
    }

    const maxBarCount = useMemo(()=>
    {
        let count =0;
        for(let protObj of Object.values(protocols)){
            const pCount = Object.values(protObj).filter(p=>p>0)?.length || 0;
            count+=pCount;
        }
        return count;
    },[protocols]);
const clickHandler = (name,risk='') => {

    history.push(
        `/issues?disable_filter=true&page=1&q=rule_name:Auth Protocol Quality%2Bd_protocol:${name}%2Bstatus:Open${risk && '%2Brisk:' + risk}&sort_by=issue_flows_count&order_by=desc`,
        { breadcrumbId: "RiskyProtocolsIncidents" }
    );
}

useEffect(()=>{
    const data = prepareChartData(protocols);
    const maxY = Math.max(...data?.map(p=>p?.y||0));
    setMaxValue(maxY);
    setTotalProtocols(Math.max(data?.length,12));
    setChartData(data);
},[protocols]);

    useEffect(() => {
        if (Object.keys(protocols || {})?.length > 0) {
            const scrollWidth = Math.max(maxBarCount || 1,12) * getResolutionMatch(31.6, 15.8);
            const pointWidthNumber =   window.matchMedia("(min-width: 1901px)").matches ? 19 : 17
            setChartOptions({
                chart: {
                    spacingBottom: getResolutionMatch(2,1),
                    type: "column",
                    renderTo: "chart",
                    // panning: true,
                    // panKey: 'shift',
                    scrollablePlotArea: {
                        minWidth: scrollWidth,
                        scrollPositionX: 1,
                        opacity:0.7
                    },
                    style: {
                        fontFamily: "Metropolis-Regular",
                    },
                    events: {
                        load: () => {
                            setTimeout(() => {
                                let containers = document.getElementsByClassName("risky-protocols-chart");
                                if (containers?.length > 0) {
                                    chartRef.current?.chart.setSize(containers[0].clientWidth, containers[0].clientHeight - getResolutionMatch(5,0), false);
                                }
                                chartRef.current.chart.update({
                                    plotOptions: {
                                        series:
                                        {
                                            pointWidth: (containers[0].clientWidth /pointWidthNumber )
                                        }
                                    }
                                });
                            }, 10);
                        },
                    },
                },
                tooltip: {
                    shape: "rect",
                    hideDelay: 50,
                    useHTML: true,
                    padding: getResolutionMatch(20,10),
                    shadow: false,
                    borderWidth: 0,
                    backgroundColor: "#ffffff00",
                    formatter: function () {
                        const header = `
                        <div class='risky-protocol-tooltip-container' style='background-color:${this.color};'>
                        <div class='risky-protocol-value'><span>${this.y}</span></div>
                        <div class='risky-protocol-name'><b>${this.key}</b></div>
                        </div>`;
                        return header;
                    },
                    style: {
                        zIndex: 100000,
                    },
                },
                credits: undefined,
                title: {
                    align: "left",
                    useHTML: true,
                    text: "",
                },
                subtitle: {
                    text: "",
                },
                legend: {
                    enabled: false,
                },
                xAxis: {
                    tickLength: 0,
                    labels: {
                        enabled: false,
                    },
                },
                yAxis: {
                    //max: Math.max(maxValue * 0.4,2),
                    gridLineColor: "#ffffff",
                    type: "logarithmic",
                    title: {
                        text: "",
                    },
                    labels: {
                        enabled: false,
                        tickIntervel: 100,
                    },
                },
                plotOptions: {
                    series: {
                        groupPadding: 0,
                        pointWidth: getResolutionMatch(24, 12),
                        cursor: 'pointer'
                    },
                },
                series: [
                    {
                        data: prepareChartData(protocols),
                        point: {
                            events: {
                                mouseOver: function (event) {
                                    //alert(this?.options.name);
                                    const allLi = document.getElementsByClassName("risky-protocol-list")[0].getElementsByTagName("li");
                                    const el = document.getElementById(`scroll-${widgetId}-${this?.options.name}`);
                                    for (let i = 0; i < allLi?.length || 0; i++) {
                                        allLi[i]?.setAttribute("class", "");
                                    }
                                    el?.setAttribute("class", "active");
                                    setTimeout(()=>{
                                        el?.setAttribute("class", "");
                                    },3000);
                                    setTimeout(() => {
                                        //el.parentNode.scrollTop = el.offsetTop; // jerky behaviour
                                        el?.scrollIntoView({ block: "nearest", behavior: "smooth", inline: "start" });
                                    }, 100);
                                },
                                click: function () {
                                    const name = this?.options?.name;
                                    const risk = getRiskLevelFromColor(
                                        this?.options?.color?.toString()!
                                    );

                                    clickHandler(name,risk);
                                    // history.push(
                                    //     `/issues?disable_filter=true&page=1&q=rule_name:Auth Protocol Quality%2Bd_protocol:${this?.options?.name}%2Bstatus:Open%2Brisk:${getRiskLevelFromColor(
                                    //         this?.options?.color?.toString()!
                                    //     )}&sort_by=issue_flows_count&order_by=desc`,
                                    //     { breadcrumbId: "RiskyProtocolsIncidents" }
                                    // );
                                },
                            },
                        },
                    },
                ],
            });
        }

    }, []);

    return (
        <>
            <div className="risky-protocols-container">
                {chartData && chartData?.length > 0 ? (
                    <>
                        <div className="risky-protocols-chart">
                            <span data-incr="-1" onClick={scroll} className={`risky-left-scroll disabled ${totalProtocols === 12 && "hide"}`}></span>
                            <span data-incr="1" onClick={scroll} className={`risky-right-scroll ${totalProtocols === 12 && "disabled"} ${totalProtocols === 12 && "hide"}`}></span>
                           
                            <HighchartsReact constructorType={"chart"} ref={chartRef} highcharts={Highcharts} options={chartOptions} />
                          
                        </div>
                        <div className="risky-protocol-list">
                            <ul>
                                {chartData &&
                                    chartData?.length > 0 &&
                                    Object.keys(protocols).map((p) => (
                                        <li id={`scroll-${widgetId}-${p}`} onClick={()=>clickHandler(p)}>
                                            <span style={{color: chartData?.find( c => c.name === p)?.color || 'grey'}} className="protocol-list-bullet">&#9632;</span>
                                            {p} ({convertToCommaValue(chartData?.find(c => c.name === p)?.y ?? 0)})

                                        </li>
                                    ))}
                            </ul>
                        </div>
                    </>
                ) : (
                    // <div className="no-risky-protocol-template">
                    //     <span>No risky protocols</span>
                    // </div>
                        <div className="no-risky-protocol-template">
                            <div className="risky-protocols-chart">

                            </div>
                            <div className="risky-protocol-list">
                                No risky protocols
                            </div>
                        </div>
                )}
            </div>
        </>
    );
};

export default RiskyProtocol;
