import { Tooltip } from '@mui/material';
import axios, { AxiosError, CancelTokenSource } from 'axios';
import purify from 'dompurify';
import moment from 'moment';
import * as qs from "qs";
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { CSVLink } from "react-csv";
import { Data } from 'react-csv/components/CommonPropTypes';
import { useHistory } from 'react-router';
import { Link, useLocation } from "react-router-dom";
import ReactTooltip from 'react-tooltip';
import { ArrayParam, NumberParam, StringParam, withDefault, withQueryParams } from 'use-query-params';
import { AddExceptionPopUp } from '../../../common/AddExceptionPopUp/AddExceptionPopUp';
import { ExceptionSearchFields } from '../../../common/AddExceptionPopUp/constant/ExceptionSearchField';
import { PostureMarkKnownPopUp } from '../../../common/MarkKnownPopUp/PostureMarkKnownPopUp';
import { useAuthDetails } from '../../../components/Authorization';
import { Api } from '../../../components/Axios';
import { useToasts } from '../../../components/core';
import { AMBreadcrumb } from '../../../components/core/AMBreadcrump/AMBreadcrumb';
import { AMConfirmPassword } from '../../../components/core/AMConfirmPassword/AMConfirmPassword';
import { IssueObj, IssueParams, MatchedRuleData, ServerResponse } from '../../../types/response-types';
import { getRisk } from '../../../utils/risk-level';
import { convertToCommaValue, getCategories, getExportFileName, getResolutionMatchFor1920, getRoundedUnit, getTimeDiffString, isEmpty, isPrivateIp, trimAfterSecondWord } from '../../../utils/util-methods';
import FlowTrend from '../flow-trend/flow-trend';
import '../issue_prev.css';
import MatchruleDetails from '../matchrule-popup/matchrule';
import MoreActionsPopup from '../more-actions-popup/more-actions';
import { MarkKnownIPPopUp } from '../sub/MarkKnownIPPopUp';
import { SuspectedList } from '../sub/SuspectedList';
import { issueTemplate, notSupportedKeys, supportedOperators } from './constant/issueTemplate';
import { RuleType } from '../../playbooks/constants/Constants';
import { usePostureAction } from '../../identity_asset/ref/Hooks/PostureAction';
import { PostureMenuItemAction } from '../../identity_asset/ref/PostureActionMenus/PostureActionMenu';
import { CategoriesFields } from '../../../constants/Categories';
import { GLOBAL_SERVICE } from '../../services/GlobalService';
import { usePartnerStateContext } from '../../../store/PartnerContextProvider';
import { IPlaybookActions } from '../../playbooks/ref/AdvancedPlaybook/PlaybookTypes';
import PostureFilter from '../../../components/core/PostureFilter/PostureFilter';
import { getSearchFilterObjectFromTags, getTagsForSearchInput, getTagsForTimeBasedSearch, isSupportedTag } from '../../../components/core/PostureFilter/posture-filter-utils';
import TimeBasedSearchContainer from '../../../components/TimeBasedSearch/TimeBasedSearchContainer';
import { AVAILABLE_PRESETS, TIME_BASED_SEARCH_FIELD } from '../../../components/TimeBasedSearch/Constants';
import { useTimeBasedSearchActions } from '../../../components/TimeBasedSearch/TimeBasedSearchActions';
import { getIssueNameById } from '../../playbooks/helpers/playbook-helper';
import { PlaybookModal } from '../../playbooks/ref/AdvancedPlaybook/PlaybookModal';
import { GlobalConsts } from '../../../GlobalConst';
import AMTabs from '../../../components/core/AMTab/AMTab';
import { SearchBox } from './SearchBox/SearchBox';
import { ColDef } from '@ag-grid-community/core';
import BaseAMAdminGrid from '../../../components/core/AMTable/BaseAMAdminGrid';
import EmptyHeaderComponent from '../../../components/core/AMTable/EmptyHeaderComponent';
import { IssueCustomGroupHeader } from './grid/IssueCustomGroupHeader';
import { IssueIdentity } from './grid/IssueIdentity';
import { IssueDestination } from './grid/IssueDestination';
import { ITableAction } from '../../../components/core/AMTable/AMAdminTable';
import { CloseIncidents } from './CloseIncidents';
import { useBaseAMGrid } from '../../../components/core/AMTable/useBaseAMGrid';
import { useIssueGrid } from './grid/useIssueGrid';

const classArray = ['navbar_inner', 'container', 'page_title_area',
    'widget_summary_box', 'inc-ruler', 'flow_table_container'];
const SuspectedPbList = [
    RuleType.SUSPECTED_ATT_DIS_ACC.toString(), RuleType.SUSPECTED_ATT_LOCK_ACC.toString(), RuleType.SUSPECTED_ATT_EXP_ACC.toString(),
    RuleType.SUSPECTED_ATT_LOCK_ACC.toString(), RuleType.SUSPECTED_DIR_BOT.toString(), RuleType.SUSPECTED_PASS_SPRAY.toString(),
    RuleType.ENUM_AD_ADMINS.toString(), RuleType.ENUM_AD_USERS.toString(), RuleType.UNAUTH_LOGIN_DEVICE.toString(),
    RuleType.UNAUTH_LOGIN_TIME.toString(), RuleType.NTLM_RELAY_ATTACK.toString(), RuleType.SUSPECTED_BRUTE_FORCE.toString(),
    RuleType.SUSPECTED_IDP_BRUTE_FORCE.toString(), RuleType.SUSPECTED_AD_BRUTE_FORCE.toString()
]

const dir_names = ["onelogin", "one login", "one-login", "okta", "ping", "pingone", "ping identity", "ping-identity",
    "workspace", "google workspace", "google-workspace", "azure", "azure ad", "azure-ad", "microsoft"]

interface IssueObjProps {
    item: IssueObj
}

const IssuesCore = ({ query, setQuery, issueProps }: any) => {

    const search = useLocation().search;
    const disabled_filter_state: boolean = new URLSearchParams(search).get('disable_filter') === "true" ? true : false;
    const [responseData, setResponseData] = useState<ServerResponse>();
    const [disabledFilter, setDisabledFilter] = useState<boolean>(disabled_filter_state);
    const [selectAll, setSelectAll] = useState<boolean>(false);
    const [selectRow, setSelectRow] = useState<any>({ '0': false });
    const [selectCount, setSelectCount] = useState(0);
    const [selectAllIndeterminate, setSelectAllIndeterminate] = useState(false);
    const [closeTicket, setCloseTicket] = useState(true);
    const [closeIncPopUp, setCloseIncPopUp] = useState(false);
    const [openConfirmPassword, setOpenConfirmPassword] = useState(false);
    const { authDetails } = useAuthDetails()!;
    const [isInitialLoad, setIsInitialLoad] = useState<boolean>(false);
    const [opPassword, setOpPassword] = useState<any>('');
    const [openIssuesCount, setOpenIssuesCount] = useState<any>(0);
    const [openMatchRule, setOpenMatchRule] = useState(false);
    const [openFlow, setOpenFlow] = useState(false);
    const [openMoreDetails, setOpenMoreDetails] = useState(false);
    const [tableFetchError, setTableFetchError] = useState('No records found.');
    const [status_comment, setStatusComment] = useState();
    const [enableFilter, setEnableFilter] = useState(false);
    const [showAddException, setShowAddException] = useState(false);
    const [showAdvanceSearch, setShowAdvanceSearch] = useState(false);
    const [isClearAll, setIsClearAll] = useState(false);
    const [selectedIncidents, setSelectedIncidents] = useState<any>({});
    const [showMarkKnown, setShowMarkKnown] = useState(false);
    const [markKnownData, setMarkKnownData] = useState({});
    const [isMarkKnownQuery, setIsMarkKnownQuery] = useState<any>(undefined);
    const [excludeIncidents, sertExcludeIncidents] = useState<any>([]);
    const [knownIPData, setKnownIPData] = useState<any>(undefined);
    const [exceptionCriteria, setExceptionCriteria] = useState<string[]>([]);
    const [isCloseAll, setIsCloseAll] = useState(undefined);
    const [disableYes, setDisableYes] = useState(false);
    const [context, setContext] = useState({ gridApi: null })
    const [showSuspectedPopUp, setShowSuspectedPopUp] = useState<any>({
        showPopUp: false,
        response: []
    });

    const { isMarkKnownOptionAllowed, isAuthorizedAction } = usePostureAction();
    const [closeFromAction, setCloseFromAction] = useState(false);
    const [incidentToClose, setInidentToclose] = useState('');
    const { PartnerConfigState } = usePartnerStateContext()
    const [isTimeBasedSearchApplied, setIsTimeBasedSearchApplied] = useState(false);

    const { isPresetPeriodOptionSelected, getPresetActualTimeValue } = useTimeBasedSearchActions();
    const [zoomLevel, setZoomLevel] = useState('hourly');
    const updatedExtremesRef = useRef({ min: null, max: null });
    const childRef = useRef(null);
    const [headerCheckboxConfig, setHeaderCheckboxConfig] = useState({});

    const onCurrentPageSelect = () => {
        const res = responseData?.result as Array<IssueObj>;
        resetSelectionState();
        const selectedIncs: any = {};
        for (let i = 0; i < res?.length; i++) {
            if (res[i].status?.toLowerCase() === 'open') {
                const key = res[i]._id.toString();
                selectedIncs[key as keyof typeof selectedIncs] = true;
            }
        }
        setSelectRow(selectedIncs);
        setSelectCount(Object.keys(selectedIncs).length)
    }

    useEffect(() => {
        setHeaderCheckboxConfig({
            indeterminate: selectAllIndeterminate,
            onClick: onSelectAllClick,
            checked: selectAll || false,
            disabled: !openIssuesCount || openIssuesCount < 1
        })
    }, [responseData, selectAll, selectAllIndeterminate, selectCount, selectedIncidents, openIssuesCount])


    const updateCols = (): Array<ColDef> => {
        return [
            {
                headerName: 'Risk',
                headerClass: 'risk-center-header center-header',
                field: 'risk',
                width: getResolutionMatchFor1920(70, 70),
                maxWidth: getResolutionMatchFor1920(70, 70),
                cellRenderer: ({ data }) => {
                    const item = data;
                    return <div style={{ minWidth: getResolutionMatchFor1920(50, 50) }}><span
                        title={getRisk(item.risk)}
                        className={getRisk(item.risk)?.toLowerCase()}>&nbsp;</span></div>
                }
            },
            {
                headerName: '',
                headerGroupComponent: IssueCustomGroupHeader,
                headerClass: 'group_issue_header  incident_number_col',
                width: getResolutionMatchFor1920(100, 100),
                maxWidth: getResolutionMatchFor1920(100, 100),
                minWidth: getResolutionMatchFor1920(100, 100),
                children: [
                    {
                        headerName: 'Incident #',
                        headerClass: 'risk-center-header',
                        field: '_id',
                        headerComponent: EmptyHeaderComponent,
                        flex: 0,
                        sortable: false,
                        width: getResolutionMatchFor1920(0, 0),
                        maxWidth: getResolutionMatchFor1920(0, 0),
                        minWidth: getResolutionMatchFor1920(0, 0),
                        cellRenderer: ({ data }) => (<></>),
                    },
                    {
                        headerName: 'Status',
                        headerComponent: EmptyHeaderComponent,
                        field: 'status',
                        width: getResolutionMatchFor1920(100, 100),
                        maxWidth: getResolutionMatchFor1920(100, 100),
                        sortable: true,
                        minWidth: getResolutionMatchFor1920(100, 100),
                        autoHeight:true,
                        cellRenderer: ({ data }) => {
                            const item = data;
                            return <div className='flex_align_items_center display-flex-column width100per gap5'>
                                <div>
                                    {item._id}
                                </div><div className={""} style={{ textAlign: 'center' }}>
                                    {
                                        item?.status_comment ?
                                            <span
                                                className={'issue-status-column status-' + item.status}
                                                style={{ whiteSpace: 'normal' }}><Tooltip classes={{ tooltip: 'matching-rule-tooltip' }} title={<span>{item.status_comment} </span>}>
                                                    <span>{item.status} {item.closure_reason.length > 0 ? '(' + item.closure_reason + ')' : null}
                                                    </span>
                                                </Tooltip></span>
                                            : <span
                                                className={'issue-status-column status-' + item.status}
                                                style={{ whiteSpace: 'normal' }}>{item.status} {item.closure_reason.length > 0 ? '(' + item.closure_reason + ')' : null}
                                            </span>
                                    } </div>
                            </div>
                        }
                    },
                ]
            },
            {

                headerName: 'Issue Highlights & Playbook',
                field: 'issue',
                headerClass: ' align_center',
                autoHeaderHeight: true,
                wrapHeaderText: true,
                maxWidth: getResolutionMatchFor1920(650, 300),
                width: getResolutionMatchFor1920(650, 300),
                minWidth: getResolutionMatchFor1920(650, 300),
                autoHeight:true,
                cellRenderer: ({ data }) => {
                    const item = data;
                    return <div className="pb_name">
                        <div dangerouslySetInnerHTML={{ __html: purify.sanitize(item.issue || '') }} style={{ textWrap: 'pretty', textAlign: 'left', width: '100%' }} />

                        <div className='pb_name_td full_width' style={{ width: '100%' }}>
                            <div className=''>
                                <div className='marginBottom5' title={item?.playbook_name}>
                                    Playbook :   {item?.playbook_name}
                                </div>

                                {/* <div>[{item?.matching_rule_details.matching_field_name === 'am_destination.Protocol' ? (
                                    <span>Auth Protocol Quality</span>
                                ) : item?.matching_rule_name === 'Authentication Quality' ? (
                                    <span>Auth Hash Quality</span>
                                ) : item?.matching_rule_name === 'Suspected Attack on Expired AD Account' ? (
                                    <span>Suspected Attack on Expired AD Account/Password</span>
                                ) : <span>{getIssueNameById(item?.matching_rule_name)}</span>
                                }]</div> */}
                            </div>

                        </div>
                        <div className="shadowbox width100 more_details"  
                            id={item._id.toString()} onClick={handleMatchruleClick}>More Details
                        </div>


                    </div>
                }
            },

            // {
            //     headerName: 'Matching Playbook',
            //     field: 'matching_rule_name',
            //     maxWidth: getResolutionMatchFor1920(170, 150),
            //     width: getResolutionMatchFor1920(170, 150),
            //     minWidth: getResolutionMatchFor1920(170, 150),
            //     wrapText: true,
            //     autoHeight: true,
            //     autoHeaderHeight: true,
            //     wrapHeaderText: true,
            //     cellRenderer: ({ data }) => {
            //         const item = data;
            //         return <div className='pb_name_td width150'>
            //             <div className='pb_name_width125'>
            //                 <div className='marginBottom5'>
            //                     {item?.playbook_name}
            //                 </div>
            //                 <div>[{item?.matching_rule_details.matching_field_name === 'am_destination.Protocol' ? (
            //                     <span>Auth Protocol Quality</span>
            //                 ) : item?.matching_rule_name === 'Authentication Quality' ? (
            //                     <span>Auth Hash Quality</span>
            //                 ) : item?.matching_rule_name === 'Suspected Attack on Expired AD Account' ? (
            //                     <span>Suspected Attack on Expired AD Account/Password</span>
            //                 ) : <span>{getIssueNameById(item?.matching_rule_name)}</span>
            //                 }]</div>
            //             </div>

            //         </div>
            //     }
            // },



            {
                headerName: 'Identity/Source',
                headerGroupComponent: IssueCustomGroupHeader,
                headerClass: 'group_issue_header',
                customHeaderClass: 'full_width',
                autoHeight: true,
                children: [
                    {
                        headerName: 'Type',
                        field: 'source_type',
                        maxWidth: getResolutionMatchFor1920(220, 210),
                        width: getResolutionMatchFor1920(220, 210),
                        minWidth: getResolutionMatchFor1920(220, 210),
                        autoHeight: true,
                        wrapText: true,
                        colSpan: params => 1,
                        headerClass: 'empty_issue_header',
                        headerComponent: EmptyHeaderComponent,
                        sortable: true,  // Enabling sorting on this column
                        cellRenderer: ({ data }) => {
                            return <IssueIdentity
                                handleMounseLeaveHost={handleMounseLeaveHost}
                                item={data}
                                handleMouseEnterHost={handleMouseEnterHost}
                                handleSuspectedClick={handleSuspectedClick}
                            />
                        },
                    },
                    {
                        headerName: 'Name',
                        field: 'source_name',
                        headerClass: 'empty_issue_header',
                        cellClass: 'empty_ag_cell',
                        headerComponent: EmptyHeaderComponent,
                        sortable: true,  // Enabling sorting on this column
                        cellRenderer: ({ data }) => (<></>),
                        flex: 0,
                        minWidth: getResolutionMatchFor1920(0, 0),
                        width: getResolutionMatchFor1920(0, 0),
                    },
                    {
                        headerName: 'Hostname',
                        field: 'source_hostname',
                        headerClass: 'empty_issue_header',
                        cellClass: 'empty_ag_cell',
                        customHeaderClass: 'full_width',
                        headerComponent: EmptyHeaderComponent,
                        sortable: true,  // Enabling sorting on this column
                        cellRenderer: ({ data }) => (<></>),
                        minWidth: getResolutionMatchFor1920(0, 0),
                        width: getResolutionMatchFor1920(0, 0),
                    }
                ]
            },
            {
                headerName: 'Asset / Destination',
                headerGroupComponent: IssueCustomGroupHeader,
                headerClass: 'group_issue_header ',
                customHeaderClass: 'full_width',
                maxWidth: getResolutionMatchFor1920(220, 220),
                width: getResolutionMatchFor1920(220, 220),
                minWidth: getResolutionMatchFor1920(220, 220),
                colSpan: params => 3,
                autoHeight: true,
                children: [
                    {
                        headerName: 'Type',
                        field: 'destination_type',
                        colSpan: params => 2,
                        maxWidth: getResolutionMatchFor1920(220, 220),
                        width: getResolutionMatchFor1920(220, 220),
                        minWidth: getResolutionMatchFor1920(220, 220),
                        sortable: true,
                        headerClass: 'empty_issue_header',
                        headerComponent: EmptyHeaderComponent,
                        cellRenderer: ({ data }) => {
                            return <IssueDestination item={data} />
                        }
                    },
                    {
                        headerName: 'Name',
                        field: 'destination_name',
                        headerClass: 'empty_issue_header',
                        cellClass: 'empty_ag_cell',
                        headerComponent: EmptyHeaderComponent,
                        sortable: true,  // Enabling sorting on this column
                        cellRenderer: ({ data }) => (<></>),
                        flex: 0,
                        minWidth: getResolutionMatchFor1920(0, 0),
                        width: getResolutionMatchFor1920(0, 0)
                    },
                    {
                        headerName: 'Hostname',
                        field: 'destination_hostname',
                        headerClass: 'empty_issue_header',
                        cellClass: 'empty_ag_cell',
                        customHeaderClass: 'full_width',
                        headerComponent: EmptyHeaderComponent,
                        sortable: true,  // Enabling sorting on this column
                        cellRenderer: ({ data }) => (<></>),
                        minWidth: getResolutionMatchFor1920(0, 0),
                        width: getResolutionMatchFor1920(0, 0)
                    }
                ]
            },
            {
                field: 'destination_protocol',
                headerName: 'Protocol',
                width: getResolutionMatchFor1920(100, 90),
                minWidth: getResolutionMatchFor1920(100, 90),
                headerClass: 'center-header',
                cellRenderer: ({ data }) => {
                    const item = data;
                    return <div style={{ textAlign: 'center', width: '100%' }}><span className='proto_name'>{
                        item.transport_protocol ? <><Tooltip classes={{ tooltip: 'matching-rule-tooltip' }} title={<span>{item.transport_protocol}</span>}><span>{item.destination_protocol}</span></Tooltip></> :
                            item.destination_protocol}</span></div>
                }
            }, {
                headerName: 'Activity',
                headerGroupComponent: IssueCustomGroupHeader,
                headerClass: 'group_issue_header activity_column_header',
                children: [
                    {
                        headerName: 'Last Access Time',
                        field: 'gen_timestamp',
                        headerClass: 'empty_issue_header',
                        customHeaderClass: 'full_width',
                        width: getResolutionMatchFor1920(100, 80),
                        minWidth: getResolutionMatchFor1920(100, 80),
                        maxWidth: getResolutionMatchFor1920(100, 80),
                        initialSort: 'desc',
                        colSpan: () => 3,
                        autoHeight: true,
                        headerComponent: EmptyHeaderComponent,
                        sortable: true,  // Enabling sorting on this column
                        cellRenderer: ({ data }) => (
                            <div className='issue_last_access_time_cell'>
                                <div className='issue_last_access_time_cell_item full_width'>
                                    {moment(data.gen_timestamp).format('MMM DD YYYY,')}&nbsp;
                                    {moment(data.gen_timestamp).format('h:mm A')}
                                </div>
                                <div className='issue_last_access_time_cell_item' style={{ minWidth: '85px' }}>
                                    <div className="type_col_width">
                                        <div>
                                            {data.status?.toLowerCase() !== 'open'
                                                ? 'N.A.'
                                                : moment(data.first_flow_time).fromNow(true)}
                                        </div>
                                    </div>
                                </div>
                                <div className='issue_last_access_time_cell_item'>
                                    <div className="type_col_width"  >
                                        <div
                                            className={`${data.issue_flows_count > 1 && 'shadowbox'
                                                } num_flows`}
                                            style={{ textAlign: 'center' }}
                                            id={data._id.toString()}
                                            onClick={(event) =>
                                                data.issue_flows_count > 1 &&
                                                handleFlowClick(event, {
                                                    ...data,
                                                    id: data._id.toString(),
                                                    risk: getRisk(data.risk)?.toLowerCase(),
                                                })
                                            }
                                        >
                                            {getRoundedUnit(data.issue_flows_count)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ),
                    },
                    {
                        headerName: 'Open Since',
                        field: 'first_flow_time',
                        headerClass: 'empty_issue_header',
                        cellClass: 'empty_ag_cell',
                        headerComponent: EmptyHeaderComponent,
                        width: getResolutionMatchFor1920(60, 50),
                        minWidth: getResolutionMatchFor1920(60, 50),
                        maxWidth: getResolutionMatchFor1920(60, 50),
                        sortable: true,  // Enabling sorting on this column
                        cellRenderer: ({ data }) => (<></>),
                    },
                    {
                        headerName: '# Flows',
                        field: 'issue_flows_count',
                        headerComponent: EmptyHeaderComponent,
                        cellClass: 'empty_ag_cell',
                        /* hide: true, */
                        headerClass: 'empty_issue_header',
                        width: getResolutionMatchFor1920(35, 35),
                        minWidth: getResolutionMatchFor1920(35, 35),
                        maxWidth: getResolutionMatchFor1920(35, 35),
                        sortable: true,  // Enabling sorting on this column
                        cellRenderer: ({ data }) => (<></>),
                    },
                ],
            }
            , {
                headerName: 'Sensor Site Code',
                field: 'sensor_location',
                autoHeaderHeight: true,
                wrapHeaderText: true,
                headerClass: 'center-header',
                maxWidth: getResolutionMatchFor1920(110, 92),
                width: getResolutionMatchFor1920(110, 90),
                minWidth: getResolutionMatchFor1920(110, 90),

                cellRenderer: ({ data }) => {
                    const item = data;
                    return <div style={{ width: '100%', textAlign: 'center' }}>
                        {item?.sensor_location}

                    </div>
                }
            },


        ]
    }

    const [IssueTableCols, setIssueTableCols] = useState<Array<ColDef>>(updateCols());

    useEffect(() => {
        setIssueTableCols(updateCols())
    }, [context, responseData])

    const udpateClosedResolvedCells = useCallback(() => {
        const grid = document.getElementsByClassName('issue-base-am-grid')[0] as HTMLDivElement;
        const leftPinContainer = grid?.getElementsByClassName("ag-pinned-left-cols-container")[0] as HTMLDivElement;
        const colsViewPort = grid?.getElementsByClassName("ag-center-cols-viewport")[0] as HTMLDivElement;
        const rightPinContainer = grid?.getElementsByClassName("ag-pinned-right-cols-container")[0] as HTMLDivElement;
        if (leftPinContainer && rightPinContainer && colsViewPort) {
            const checkboxes = leftPinContainer.querySelectorAll(".ag-row") as NodeListOf<HTMLDivElement>;
            const dataCells = colsViewPort.querySelectorAll(".ag-row") as NodeListOf<HTMLDivElement>;
            const actionCells = rightPinContainer.querySelectorAll(".ag-row") as NodeListOf<HTMLDivElement>;

            dataCells?.forEach((node, index) => {
                if (node.querySelectorAll('.issue-status-column.status-Closed').length > 0 ||
                    node.querySelectorAll('.issue-status-column.status-Resolved').length > 0) {
                    checkboxes[index].classList.add('row-disable-ag')
                    actionCells[index].classList.add('row-disable-ag')
                    node.classList.add('row-disable-ag')
                }
            })
            /*  responseData?.result?.forEach((item: IssueObj, index) => {
                 if ((item.status.toLowerCase() === 'closed' || item.status.toLowerCase() === 'resolved')
                     && dataCells[index] && actionCells[index] && checkboxes[index]
                 ) {
                     dataCells[index].style.background = '#eee'
                     actionCells[index].style.background = '#eee'
                     checkboxes[index].style.background = '#eee'
                 }
             }) */
        }
    }, [responseData])

    useIssueGrid(childRef, udpateClosedResolvedCells, responseData)

    useEffect(() => {
        const signal: CancelTokenSource = axios.CancelToken.source();
        let tempTags: string[] = []
        if (param.q) {
            // tempTags = [...param.q];
            tempTags = q.split("+").map((i) => decodeURIComponent(i));
        }
        if (param.rule_name && !tempTags.includes(param.rule_name)) {
            tempTags.push(param.rule_name);
        }
        if (param.risk && !tempTags.includes(param.risk.toString())) {
            tempTags.push(param.risk.toString());
        }
        if (param.d_protocol && !tempTags.includes(param.d_protocol)) {
            tempTags.push(param.d_protocol);
        }
        if (param.d_name && !tempTags.includes(param.d_name)) {
            tempTags.push(param.d_name);
        }
        if (param.hash && !tempTags.includes(param.hash)) {
            tempTags.push(param.hash);
        }
        if (param.s_time && param.e_time && !tempTags.includes(param.s_time.toString()) && !tempTags.includes(param.e_time.toString())) {
            tempTags.push(param.s_time.toString(), param.e_time.toString());
        }
        // console.log('TEMP TAGS : ', tempTags);
        console.log('Params are ', param);
        if (tempTags.length > 0) {
            setTags(tempTags);
            setTagFlag(false);
            let isExceptioField = false;
            let isAssetName = false;
            if (authDetails.permissions.Issues_and_Prevention?.bulk_close_issues == 'readwrite') {
                setTimeout(() => {
                    tempTags.forEach((item: string) => {
                        if ((ExceptionSearchFields.includes(item.split(':')[0])
                            || ExceptionSearchFields.includes(item.split('~')[0])
                            || ExceptionSearchFields.includes(item.split('|')[0]))
                            && item.split(':')[0] != 'rule_name'
                            && item.split('~')[0] != 'rule_name'
                            && item.split('|')[0] != 'rule_name') {
                            const index = item.indexOf('~') > -1 ? item.indexOf('~') : item.indexOf('|') > -1 ? item.indexOf('|') : item.indexOf(':');
                            const el = document.getElementsByClassName(`tag-${item.substring(0, index)}`)[0];
                            if (el) {
                                el?.classList.add('yellow-border');
                                isExceptioField = true;
                            }
                            if ((item.split(':')[0] == `d_name` || item.split('~')[0] == `d_name` || item.split('|')[0] == `d_name`) && el) {
                                el?.classList.add('asset-name-border');
                                isAssetName = true;
                            }
                        }
                    });

                    const btn = document.getElementById('issue-more-action-btn-grp');
                    if (btn) {
                        if (isExceptioField) {
                            btn?.classList.add('exception-border');
                        } else {
                            btn?.classList.remove('exception-border');
                        }
                    }
                    if (document.getElementById('issue-more-action-btn-grp')) {
                        isAssetName ? document.getElementById('issue-more-action-btn-grp')?.classList.add("asset-border") :
                            document.getElementById('issue-more-action-btn-grp')?.classList.remove("asset-border")
                    }

                }, 500)
            }

        }
        if (!history.location.pathname.includes("matchrule") && !history.location.pathname.includes("flow")) {
            if (!isInitialLoad) {
                /* fetchIssues(signal.token); */
                /* refreshGrid() */
            }
        }
        return () => {
            signal.cancel('Api call cancelled...');
        }
    }, [query])

    const validateLocalIp = (data: Array<IssueObj>) => {
        const res = data.map((i: IssueObj) => {
            if (i?.source_hostnames?.length > 0) {
                const d = i?.source_hostnames?.filter((s: any) => !s?.sourceIp || !s?.source_ip.startsWith('127.')) as [];
                i.source_hostnames = d;
            }
            if (i.directory_ip?.startsWith('127.')) {
                i.directory_ip = 'N.A.'
            }

            if (i?.destination_hostname?.startsWith('127.')) {
                i.destination_hostname = 'N.A.'
            }

            /* if (i?.latest_source_hostname?.startsWith('127.')) {
                i.latest_source_hostname = 'N.A.'
            } */
            return i;
        })
        return res;
    }

    const getCategoryKeyFromValue = (categoriesList: Array<string>) => {
        let categories = getCategories() as any;
        if (categories) {
            const result = []
            categories = JSON.parse(categories);
            for (const key in categories) {
                if (categoriesList.includes(categories[key])) {
                    result.push(parseInt(key));
                }
            }
            return result;
        }
        return [];
    }

    const generateTagsFromSearch = async (data: any) => {
        await GLOBAL_SERVICE.Category.GET();
        const searchTextList = [];
        if (Object.keys(data).length > 0) {
            // update tags array on Apply AdvanceSearch (Merge only)			
            for (let filter in data) {
                let filterValue: any = data[filter]?.value;
                let opr =
                    supportedOperators.get(String(data[filter]?.type)) || '';
                if (['first_flow_time', 'last_flow_time'].includes(filter) && opr == 'last_in') {
                    filterValue[1] = filterValue[1] == 'day(s)' ? 'day' : 'hour'
                }
                if ([TIME_BASED_SEARCH_FIELD].includes(filter)) {
                    filterValue = getPresetActualTimeValue(filterValue);
                }
                if (filter === 'any_field') {
                    filterValue.forEach((p: String) => {
                        if (p) {
                            //addedTags.set(p, p); // set any field value without key. // Change Requirement reverting logic
                            searchTextList.push(p);
                        }
                    });
                    continue;
                } else if (CategoriesFields.includes(filter)) {
                    filterValue = getCategoryKeyFromValue(filterValue);
                }
                //addedTags.set(filter, `${filter}${opr}${filterValue}`); // Change Requirement reverting logic
                searchTextList.push(`${filter}${opr}${filterValue}`);
            }
            if (searchTextList.length > 0) {
                // const merged = Array.from(addedTags.values());// Change Requirement reverting logic
                return searchTextList;
            }
        }
    };

    const fetchIssues = async (queryParams: Record<string, any>, gridParams: any, signal: AbortSignal) => {
        const searchHeaders = { headers: { search_fields: '' } };
        let searchTags: String[] = [];
        if (queryParams.q && queryParams.q?.split('+').length > 0) {
            const tags = queryParams.q?.split('+').map((i) => decodeURIComponent(i));
            searchTags = tags;
        }

        const fields = getSearchFilterObjectFromTags(issueTemplate, searchTags);
        const que = await generateTagsFromSearch(fields);
        if ((searchTags || []).length > 0) {
            const searchFieldsList = getSearchFilterObjectFromTags(issueTemplate, que || [], true);
            searchHeaders.headers.search_fields = encodeURIComponent(JSON.stringify(searchFieldsList));
            // const que = generateTagsFromSearch(searchFieldsList);
            queryParams.q = searchTags?.join('+');
        }
        Api.get("/issues", {
            params: queryParams,
            paramsSerializer: (params) => qs.stringify(params || {}, { arrayFormat: 'repeat' }),
            signal,
            ...searchHeaders
        })
            .then(res => {
                setIsInitialLoad(false);
                if (res.status === 200) {
                    res.data.result.forEach((i: any, index: number) => {
                        if (i.source_hostnames && i.source_hostnames.length > 0) {
                            i.source_hostnames = i.source_hostnames.reverse();
                        }
                    });
                    res.data.result = validateLocalIp(res.data.result);

                    setLastUpdate(moment().format('MMM DD YYYY, hh:mm A'))
                    if (res.data.open_issue_count == 0) {
                        // setTotalCount(0);
                    }
                    setOpenIssuesCount(res.data.open_issue_count);
                    if (gridParams) {
                        gridParams.success({
                            rowData: res.data.result,
                            rowCount: res.data.result?.length > 0 ? res.data.total : 0
                        });

                        gridParams.api.hideOverlay();
                        if (res.data.result?.length === 0) {
                            gridParams.api.showNoRowsOverlay();
                        }

                        setTotalCount(res.data.total);
                        setTotalIssue(res.data.total);
                        setResponseData(res.data);
                    }
                    setQuery({ ...query, ...queryParams })

                    if (param?.disable_filter == false && res.data.result.length == 0) {
                        setTableFetchError('No incidents found. This might be due to the exclude filters.')
                    } else if (res.data.result.length == 0) {
                        setTableFetchError('No records found.')
                    }
                }
            })
            .catch(err => {
                if (axios.isCancel(err)) {
                    return;
                }
                setIsInitialLoad(false);
                setResponseData({ result: 'error' } as ServerResponse);
                addToast("Error while fetching data.", {
                    appearance: 'error',
                    autoDismiss: true,
                });
                setTableFetchError(`An error occurred while processing your request. Please check your input and try again. If the error persists, please reach out to ${PartnerConfigState?.PartnerShortProduct} support.`);
            })
    }

    const reloadIssues = () => {
        reloadRef.current.classList.add('reload_spin');
        param.hard_refresh = true;
        setQuery(param)
        if (!isInitialLoad) {
            /* fetchIssues(); */
            refreshGrid()
        }
        setIsInitialLoad(true);
        outDated();
        setTimeout(() => {
            if (reloadRef?.current?.classList.contains('reload_spin')) {
                reloadRef.current.classList.remove('reload_spin')
            }
        }, 750)
        resetSelectionState();
    }

    const {
        q,
        page: page_number,
        rpp: record_per_page,
        order_by: order,
        sort_by: sort,
        rule_name,
        d_protocol,
        risk,
        hash,
        d_name,
        s_time,
        e_time,
        hard_refresh,
        summary,
        disable_filter,
    } = query;

    const [searchInput, setSearchInput] = useState<string>('');
    const [tags, setTags] = useState<string[]>([]);
    const [tagFlag, setTagFlag] = useState<boolean | undefined>();
    const [flowsTrendResponseData, setFlowsTrendResponseData] = useState<any>(undefined);
    const [flowsChartDetails, setFlowsChartDetails] = useState<any>({ issueId: 0, risk: '', createdDate: null });
    const { totalCount, setTotalCount } = issueProps;
    const [totalIssue, setTotalIssue] = useState(0);
    const [lastUpdate, setLastUpdate] = useState<string>();
    const [matchedRuleData, setMatchedRuleData] = useState<MatchedRuleData>();

    const [ticketId, setTicketId] = useState<number>();

    const { appliedFilterTimestamp } = useTimeBasedSearchActions();
    const { refreshGrid } = useBaseAMGrid(childRef);
    let history = useHistory();
    const { addToast } = useToasts();
    const reloadRef = useRef() as React.MutableRefObject<HTMLDivElement>;

    let param: IssueParams = {
        q: q,
        page: page_number,
        rpp: record_per_page,
        order_by: order,
        sort_by: sort,
        rule_name: rule_name,
        d_protocol: d_protocol,
        risk: risk,
        hash: hash,
        d_name: d_name,
        s_time: s_time,
        e_time: e_time,
        hard_refresh: hard_refresh,
        summary: summary,
        disable_filter: disable_filter,
    };

    moment.updateLocale('en', { relativeTime: { d: '1 day' } })

    function getSearchFields() {
        let timeFilter = appliedFilterTimestamp();
        if (timeFilter.length > 0) {

            let startDate = moment(timeFilter[0]).unix();
            let endDate = moment(timeFilter[1]);

            if (endDate.isAfter(moment())) {
                endDate = moment().unix();
            } else {
                endDate = endDate instanceof moment ? endDate.unix() : moment(endDate).unix();
            }

            // Calculate the time difference in hours
            let diffInHours = (endDate - startDate) / 3600;

            // Determine the interval based on the difference
            let interval;
            if (diffInHours < 12) {
                interval = '1 MINUTE';
                setZoomLevel('1-minute');
            } else if (diffInHours < 48) {
                interval = '10 MINUTE';
                setZoomLevel('10-minute');
            } else {
                interval = '1 HOUR'; // Replace 'default_interval' with your desired default interval.
                setZoomLevel('hourly');
            }

            let searchFields = {
                "any_activity_time": {
                    "value": [startDate, endDate],
                    "type": "between"
                },
                "interval": interval
            };

            // Convert to JSON and encode
            return encodeURIComponent(JSON.stringify(searchFields));

        }
        return null; // Return null or an empty string if timeFilter is empty
    }

    function getSearchFieldsForDate(min, max) {


        let startDate = moment(min).unix();
        let endDate = moment(max);

        if (endDate.isAfter(moment())) {
            endDate = moment().unix();
        } else {
            endDate = endDate instanceof moment ? endDate.unix() : moment(endDate).unix();
        }

        // Calculate the time difference in hours
        let diffInHours = (endDate - startDate) / 3600;

        // Determine the interval based on the difference
        let interval;

        if (diffInHours < 12) {
            interval = '1 MINUTE';
            setZoomLevel('1-minute');
        } else if (diffInHours < 48) {
            interval = '10 MINUTE';
            setZoomLevel('10-minute');
        } else {
            interval = '1 HOUR'; // Replace 'default_interval' with your desired default interval.
            setZoomLevel('hourly');
        }

        let searchFields = {
            "any_activity_time": {
                "value": [startDate, endDate],
                "type": "between"
            },
            "interval": interval
        };

        // Convert to JSON and encode
        return encodeURIComponent(JSON.stringify(searchFields));



    }

    const cancelTokenSourceRef = useRef<any>(null);

    const getChartTitle = (identityName: string, startDate: any = null, endDate: any = null) => {
        let timeUnit = "Hourly";

        if (startDate) {
            if (endDate) {
                const totalDurationHours = moment(endDate).diff(moment(startDate), 'hours');

                if (totalDurationHours < 12) {
                    timeUnit = "Minute-wise";
                } else if (totalDurationHours < 48) {
                    timeUnit = "10-Minute-wise";
                }
                // else timeUnit remains "Hourly"
            }

            return (
                <span
                    title={`${timeUnit} flows trend (${getTimeDiffString(startDate, endDate)}) for Incident: ${identityName}`}
                >{`${timeUnit} flows trend (${getTimeDiffString(startDate, endDate)}) for Incident: ${trimAfterSecondWord(identityName, 35)}`}</span>
            );
        } else {
            return (
                <span
                    title={`Hourly flows trend for Incident: ${identityName}`}
                >{`Hourly flows trend for Incident: ${trimAfterSecondWord(identityName, 35)}`}</span>
            );
        }
    };

    const handleRangeChange = (min: any, max: any) => {

        const searchHeaders = {
            headers: {
                search_fields: getSearchFieldsForDate(min, max)
            }
        };

        updatedExtremesRef.current = { min, max };


        const currentTime = +new Date();

        const data = JSON.parse(sessionStorage.getItem('flowDataIssues'));

        const risk = data?.risk;


        const encodedIdentityName = data ? encodeURIComponent(data?.id) : '';

        const flowTrendURL = `/issues/issueflowcounts/${data.id}`;


        // Cancel the previous request if one exists
        if (cancelTokenSourceRef.current) {
            cancelTokenSourceRef.current.cancel('Operation canceled due to new request.');
        }

        // Create a new cancel token for the new request
        cancelTokenSourceRef.current = axios.CancelToken.source();
        try {
            Api.get(flowTrendURL, {
                ...searchHeaders,
                cancelToken: cancelTokenSourceRef.current.token, // Attach the cancel token
            })
                .then((res: any) => {
                    if (!res?.data?.length || res?.data?.length <= 0) {
                        setFlowsTrendResponseData({
                            flows: [],
                            expiry_time: currentTime + 600000
                        });

                        // Chart header and color
                        // const chartTitle = getChartTitle(identityName);
                        // setFlowsChartDetails({
                        //     chartTitle, itemData: {
                        //         queryData: `identity_name:${encodedIdentityName}`
                        //     }
                        // });
                        return;
                    }

                    let result = JSON.parse(JSON.stringify(res?.data)) || [];
                    result.sort((a: any, b: any) =>
                        a?.time < b?.time ? -1 : 1
                    );
                    const cachedFlows = {
                        flows: result,
                        encodedIdentityName,
                        risk,
                        expiry_time: currentTime + 600000
                    };
                    setFlowsTrendResponseData(cachedFlows);


                    const chartTitle = appliedFilterTimestamp()?.length > 0 ? getChartTitle(
                        data?.id,
                        min,
                        max,

                    ) : max ? getChartTitle(
                        data?.id,
                        min,
                        max

                    ) : getChartTitle(
                        data?.id,
                        min
                    );


                    setFlowsChartDetails({ chartTitle, risk, itemData: { ...data, pageType: 'issue' } });


                })
                .catch((er) => {
                    console.log(er);
                });
        }
        catch (error) {
            if (axios.isCancel(error)) {
                console.log('Previous request canceled.');
            } else {
                console.log(error);
            }
        }
    }

    const handleFlowClick = (event: any, data: any) => {
        setOpenFlow(true);
        setZoomLevel('hourly');
        updatedExtremesRef.current = { min: null, max: null };
        let { id: issueId, risk } = data;
        const currentTime = +new Date();
        const cachedFlowData: any = sessionStorage.getItem(`flow-trend-${issueId}`);
        const flowTrendURL = `/issues/issueflowcounts/${issueId}`;

        const searchHeaders = {
            headers: {
                search_fields: getSearchFields()
            }
        };

        sessionStorage.setItem('flowDataIssues', JSON.stringify(data));


        Api.get(flowTrendURL, searchHeaders).then(((res: any) => {
            if (!res?.data?.length || res?.data?.length <= 0) {
                setFlowsTrendResponseData({ flows: [], expiry_time: currentTime + 600000 });

                // Chart header and color
                const chartTitle = <>{`Incident ${issueId} - Flows Trend`}</>;
                setFlowsChartDetails({ chartTitle, risk, itemData: { ...data, pageType: 'issue' } });

                return;
            }

            let result = JSON.parse(JSON.stringify(res?.data)) || [];
            result.sort((a: any, b: any) => (a?.time < b?.time) ? -1 : 1);
            const cachedFlows = { flows: result, expiry_time: currentTime + 600000 };
            setFlowsTrendResponseData(cachedFlows);

            // Chart header and color
            const startFromDate = Math.max(moment(result?.[0]?.time).valueOf(), moment().subtract(90, 'days').valueOf());
            const chartTitle = <>{`Incident ${issueId} - Flows Trend (${getTimeDiffString(startFromDate)})`}</>;
            setFlowsChartDetails({ chartTitle, risk, itemData: { ...data, pageType: 'issue' } });

            sessionStorage.setItem(`flow-trend-${issueId}`, JSON.stringify(cachedFlows));
        }
        )).catch(er => {
            console.log(er);
        });

    }

    useEffect(() => {
        if (!openFlow) {
            setFlowsTrendResponseData(undefined);
        }
    }, [openFlow])

    const handleMatchruleClick = (e: React.MouseEvent<HTMLDivElement>) => {
        let issueId = e.currentTarget.id;
        let issue = responseData?.result.find((issue: IssueObj) => issue._id === +issueId);
        const flows = responseData?.result.find((issue: IssueObj) => issue._id === +issueId)?.issue_flows;
        let flowId = null;
        if (issue.latest_flow_id) {
            flowId = issue.latest_flow_id;
        } else if (flows && flows.length > 0) {
            flowId = flows[flows.length - 1];
            if (flowId) {
                flowId = flowId.flow_id;
            }
        }
        if (issue) {
            setMatchedRuleData({
                matching_rule_details: issue.matching_rule_details,
                matching_rule_name: issue.matching_rule_name,
                matched_flow: issue.matched_flow,
                issueId: issueId.toString(),
                incident_summary: issue?.incident_summary,
                flowId,
                dest_is_directory: issue?.dest_is_directory,
                playbook: issue.playbook_name,
                status: issue?.status,
                genTimestamp: issue?.gen_timestamp,
                suspected_source_info: issue?.suspected_source_info,
                sourceName: issue?.source_name,
                misc: issue,
                playbook_id: issue.playbook_id,
                closure_reason: issue.closure_reason
            })
        }
        // history.push("/issues/matchrule")
        setOpenMatchRule(true);
    }

    const handleEdit = (e: React.MouseEvent<HTMLDivElement>) => {
        history.push("/tickets/" + e.currentTarget.id);
    }

    const handleCreate = (e: React.MouseEvent<HTMLDivElement>) => {
        setTicketId(parseInt(e.currentTarget.id));
        // history.push("/issues/createissue");
        setOpenMoreDetails(true);
    }

    const handleTicketCreate = () => {
        Api.post("/tickets", {
            issue_id: ticketId
        })
            .then(res => {
                if (res.status === 200) {
                    addToast("Ticket created successfully.", {
                        appearance: 'success',
                        autoDismiss: true
                    });
                    history.push("/tickets/" + ticketId);
                }
            })
            .catch((err: AxiosError) => {
                if (err.response?.status === 500) {
                    addToast("Sorry, something went wrong there, try again.", {
                        appearance: 'error',
                        autoDismiss: true,
                    })
                } else if (err.response?.status === 401) {
                    window.location.href = '/login';
                }
            });
    }

    useEffect(() => {
        ReactTooltip.rebuild();
    });

    const onSelectAllClick = (evt: any) => {
        if (!selectAll && !selectAllIndeterminate) {
            selectDeSelectAll(true);
            setSelectCount(openIssuesCount);
            const resultSet: any = {};
            if (responseData?.result) {
                Object.values(responseData?.result).forEach((item: any) => resultSet[item._id] = item);
                setSelectedIncidents(resultSet);
            }

        } else {
            selectDeSelectAll(false);
            setSelectCount(0);
            setSelectRow({ '0': false });
            setSelectedIncidents({});
        }
        setSelectAll(selectAllIndeterminate ? !selectAllIndeterminate : !selectAll);
        setSelectAllIndeterminate(false);
    }

    const onSelectRowClick = (item: any, isSelected: any) => {
        const evt = item._id
        if (selectAll) {
            setSelectAll(!selectAll);
            setSelectAllIndeterminate(true);
        }
        let selectionArray = { ...selectRow };
        selectionArray[evt] = !getSelectRow(evt, item.status);
        setSelectRow(selectionArray);
        const incident = selectionArray[evt] ? { [evt]: item } : { [evt]: null };
        setSelectedIncidents({ ...selectedIncidents, ...incident })

        if (selectAll || selectAllIndeterminate) {
            const negativeCount = Object.values(selectionArray).filter(el => !el).length - 1;
            const selection = Object.values(selectionArray).filter(el => el).length;
            const count = openIssuesCount - negativeCount;
            setSelectCount(count);
            if (selectAllIndeterminate && count == openIssuesCount) {
                /* Part of https://authmind.atlassian.net/browse/A1Y-1511 */
                // setSelectAll(true);
                // setSelectAllIndeterminate(false);
            } else if (selectAllIndeterminate && count == 0 || selection == 0) {
                setSelectAllIndeterminate(false);
                setSelectAll(false);
                selectDeSelectAll(false);
            } else if (count < 0) {
                setSelectCount(selection);
            }
        } else {
            const select = Object.values(selectionArray).filter(el => el).length;
            setSelectCount(select);
            if (select == openIssuesCount) {
                setSelectAll(true);
            }
        }
        borderMarkKnown();
    }

    const getSelectRow = (id: any, status: any) => {
        if (selectAll) {
            return true;
        } else if (selectAll && status.toLowerCase() == 'open') {
            return true;
        } else if (selectRow.hasOwnProperty(id) && status.toLowerCase() != 'open' && selectAllIndeterminate) {
            return true;
        } else if (selectRow.hasOwnProperty(id) && status.toLowerCase() == 'open') {
            return selectRow[id];
        } else if (selectAllIndeterminate && status.toLowerCase() == 'open') {
            return selectRow[id] || true;
        }
        return false;
        // return (selectAll && status.toLowerCase() != 'open') || (selectRow[id] && status.toLowerCase() != 'open') || false;
    }

    const selectDeSelectAll = (flag: boolean) => {
        if (responseData?.result) {
            let selectionArray = { ...selectRow };
            responseData.result.forEach((item: any) => {
                selectionArray[item._id] = flag
            });
            setSelectRow(selectionArray);
        }
    }

    const isCloseIncEnabled = (): boolean => {
        return (selectCount > 0);
    }

    const handleCloseIncPopUp = () => {
        setCloseIncPopUp(false);
        setCloseFromAction(false);
        setIsCloseAll(undefined)
    }

    const confirmPasswordOperation = (e: any) => {
        if (!closeFromAction) {
            if (selectAll || selectAllIndeterminate) {
                handleAllSelection();
            } else {
                handleManualSelection();
                setCloseIncPopUp(false);
            }
        } else {
            handleIncidentCloseSelection();
            setCloseFromAction(false);
            setCloseIncPopUp(false);
        }
    }

    const handlOkIncPopUp = () => {
        setOpenConfirmPassword(true);
        setCloseIncPopUp(false);
    }

    const handleCloseTicket = () => {
        setCloseTicket(!closeTicket);
    }

    const closeTickets = () => {
        setCloseIncPopUp(true);
        setCloseTicket(true);
    }

    const closeTicketsFromAction = (id: string) => {
        setCloseFromAction(true);
        setInidentToclose(id)
        closeTickets();

    }

    const getSelectRows = () => {
        const selectedRows = [];
        for (const key in selectRow) {
            if (selectRow[key]) {
                selectedRows.push(parseInt(key));
            }
        }
        return selectedRows;
    }

    const getUnSelectRow = () => {
        const unselectedRow = [];
        for (const key in selectRow) {
            if (selectRow[key] == false && key != '0') {
                unselectedRow.push(parseInt(key));
            }
        }
        return unselectedRow;
    }

    const handleAllSelection = () => {
        const queryData = query?.q?.split('+').map((i) => decodeURIComponent(i)) || [];
        let searchFieldsList = {};
        const searchHeaders = {
            headers: {
                search_fields: {}
            }
        }
        if ((queryData.filter(i => i) || []).length > 0) {
            searchFieldsList = getSearchFilterObjectFromTags(issueTemplate, queryData);
            searchHeaders.headers.search_fields = encodeURIComponent(JSON.stringify(searchFieldsList));
        }

        if (searchFieldsList &&
            Object.keys(searchFieldsList).filter((i) => i != 'status').length == 0
            && isCloseAll == undefined && getUnSelectRow().length == 0) {
            setDisableYes(true);
            return
        }

        if (searchFieldsList &&
            Object.keys(searchFieldsList).filter((i) => i != 'status').length == 0
            && isCloseAll && getUnSelectRow().length == 0) {
            searchFieldsList = {};
            searchHeaders.headers.search_fields = encodeURIComponent(JSON.stringify(searchFieldsList));
        }

        setDisableYes(false);

        const data = {
            'close_option': 2,
            'search_query': isEmpty(searchFieldsList) ? '' : encodeURIComponent(JSON.stringify(searchFieldsList)),
            'disable_filter': query?.disable_filter == 'true' || false,
            'issues_id_list': [],
            'unchecked_issues_id_list': getUnSelectRow(),
            'close_ticket': closeTicket,
            status_comment,
            is_close_all: isCloseAll ? true : false
            // 'password': opPassword
        }
        postCloseInc(data, searchHeaders);
        setCloseIncPopUp(false);
        setIsCloseAll(undefined)
    }

    const handleManualSelection = () => {
        setCloseIncPopUp(false);
        const data = {
            'close_option': 1,
            'search_query': '',
            'disable_filter': query?.disable_filter == 'true' || false,
            'issues_id_list': getSelectRows(),
            'unchecked_issues_id_list': [],
            'close_ticket': closeTicket,
            // 'password': opPassword
            status_comment
        }
        postCloseInc(data);

    }

    const handleIncidentCloseSelection = () => {
        setCloseIncPopUp(false);
        const data = {
            'close_option': 1,
            'search_query': '',
            'disable_filter': query?.disable_filter == 'true' || false,
            'issues_id_list': [parseInt(incidentToClose)],
            'unchecked_issues_id_list': [],
            'close_ticket': closeTicket,
            // 'password': opPassword
            status_comment
        }
        postCloseInc(data);

    }

    const postCloseInc = (data: any, headers?: any) => {
        const tableEl = document.getElementsByClassName('tab-panel')[0];
        tableEl?.classList.add('disableItems');
        setOpPassword('');
        Api.post("/issues/bulkclose", data, headers)
            .then(res => {
                if (res.status === 200) {
                    /* addToast("Bulk close incident request sent successfully.",{
                        appearance:'success',
                        autoDismiss: true
                    }); */
                    if (!closeFromAction) {
                        addToast(`Closing of ${selectCount} incident` + (selectCount == 1 ? '' : 's') + ` is in progress and might take up to a few minutes.`, {
                            appearance: 'success',
                            autoDismiss: true
                        });
                    } else {
                        addToast(`Closing of 1 incident is in progress and might take up to a few minutes.`, {
                            appearance: 'success',
                            autoDismiss: true
                        });

                    }
                    param.page = 1;
                    resetSelectionState();
                    reloadIssues();
                    tableEl?.classList.remove('disableItems');
                }
            })
            .catch((err: AxiosError) => {
                tableEl?.classList.remove('disableItems');
                if (err.response?.status === 500) {
                    addToast("Sorry, something went wrong there, try again.", {
                        appearance: 'error',
                        autoDismiss: true,
                    })
                } else if (err.response?.status === 401) {
                    window.location.href = '/login';
                } else if (err.response?.status === 403) {
                    addToast("Bulk close incident can not be performed due to invalid credentials.", {
                        appearance: 'error',
                        autoDismiss: true,
                    })
                } else {
                    addToast("Sorry, something went wrong there, try again.", {
                        appearance: 'error',
                        autoDismiss: true,
                    })
                }
            });
        setOpenConfirmPassword(false)
    }

    const closeConfirmPassword = () => {
        setOpenConfirmPassword(false)
    }

    useEffect(() => {
        updateScreenWidths(true);
    });

    useEffect(() => {
        return () => {
            // Anything in here is fired on component un-mount.
            updateScreenWidths(false);
        }
    }, [])

    const updateScreenWidths = (remove: any) => {
        // if (authDetails.permissions.Issues_and_Prevention?.bulk_close_issues == 'readwrite') {
        //     for (let i = 0; i < classArray.length; i++) {
        //         const item = document.getElementsByClassName(classArray[i])[0];
        //         remove ? item?.classList.add('width1300') : item?.classList.remove('width1300');
        //     }
        // }
    }

    const resetSelectionState = () => {
        setSelectAllIndeterminate(false);
        setSelectAll(false);
        setSelectRow({ '0': false });
        setSelectCount(0);
        setCloseTicket(false);
        setOpPassword('');
        setSelectedIncidents({});
    }

    const isSelected = (inc: string) => {
        if (selectRow && selectRow.hasOwnProperty(inc)) {
            return selectRow[inc];
        }
        return selectAll || selectAllIndeterminate;
    }

    const handleMouseEnterHost = (index: number, hostname: string, e: any) => {
        const ele = e.target.parentElement.parentElement.getElementsByClassName(`host-name-list-${index}-${hostname}`)[0];
        if (ele && ele?.classList) {
            ele.classList.add('show-block');
            ele.classList.remove('hidden-block');
        }
    }

    const handleMounseLeaveHost = (index: number | null, hostname: string | null, e: any) => {
        if (!index && !hostname) {
            console.log('Scrolling')
            const ele = e.target.getElementsByClassName(`host-details-tooltip`);
            if (ele && ele.length)
                Array.from(ele).forEach((it: any) => {
                    it?.classList.add('hidden-block');
                    it?.classList.remove('show-block');
                })
        } else {
            const ele = e.target.getElementsByClassName(`host-name-list-${index}-${hostname}`)[0];
            if (ele && ele?.classList) {
                ele?.classList.add('hidden-block');
                ele?.classList.remove('show-block');
            }
        }
    }

    const Action = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        const action = e.currentTarget.dataset.action?.toString();
        if (action) {
            switch (action) {
                case 'go-to-ticket':
                    history.push("/tickets/" + e.currentTarget.id);
                    break;
                case 'create-ticket':
                    setTicketId(parseInt(e.currentTarget.id));
                    setOpenMoreDetails(true);
                    break;
                default: break;

            }
        }
        e.preventDefault()
    }

    useEffect(() => {
        let filterObj = getSearchFilterObjectFromTags(issueTemplate, tags);
        if (filterObj[TIME_BASED_SEARCH_FIELD]) {
            setIsTimeBasedSearchApplied(true);
        } else {
            setIsTimeBasedSearchApplied(false);
        }
    }, [tags])

    const getItemIconClass = (item) => {
        switch (item.source_type) {
            case "Device":
                return "device_icon";
            case "App":
                return "device_icon";
            case "Service":
                return "gear_icon_issue";
            case "Service/Application":
                return "service_application_account_nhi_icon";
            case "Service/Computer Account":
                return "service_computer_account_nhi_icon";
            case "Service/Key and Secret":
                return "service_key_secret__nhi_icon";
            case "Service/Service Account":
                return "service_service_account_nhi_icon";
            case "Service/Service Principal":
                return "service_service_principal_nhi_icon";
            case "Service/Token":
                return "service_service_token_nhi_icon";
            case "User/Agent":
                return "user_agent_icon";
            case "User/Background Browsing":
                return "user_browsing_icon";
            case "User":
                return "user_interactive_icon";
            default:
                // If identity_category_list exists, return 'settings_icon_cloud', otherwise 'settings_icon'
                return item.identity_category_list ? 'settings_icon_cloud' : 'settings_icon';
        }
    }

    const getDestinationItemIconClass = (item) => {
        switch (item?.destination_type) {
            case "Device":
                return "device_icon";
            case "App":
                return "application_account_icon";
            case "Service":
                return item?.dst_cat_ids && item?.dst_cat_ids?.length > 0 || item?.is_dir_public ? "gear_icon_cloud" : "gear_icon_issue";
            case "Service/Application":
                return item?.dst_cat_ids && item?.dst_cat_ids?.length > 0 || item?.is_dir_public ? "cloud_application_account_icon" : "application_account_icon";
            case "Service/Computer Account":
                return item?.dst_cat_ids && item?.dst_cat_ids?.length > 0 || item?.is_dir_public ? "cloud_computer_account_icon" : "computer_account_icon";
            case "Service/Service Account":
                return item?.dst_cat_ids && item?.dst_cat_ids?.length > 0 || item?.is_dir_public ? "cloud_service_account_icon" : "service_account_icon";
            case "Service/Service Principal":
                return item?.dst_cat_ids && item?.dst_cat_ids?.length > 0 || item?.is_dir_public ? "cloud_service_principal_icon" : "principal_icon";
            case "Service/Key and Secret":
                return item?.dst_cat_ids && item?.dst_cat_ids?.length > 0 || item?.is_dir_public ? "cloud_key_secret__icon" : "key_secret__icon";
            case "Service/Token":
                return item?.dst_cat_ids && item?.dst_cat_ids?.length > 0 || item?.is_dir_public ? "cloud_service_token_icon" : "token_icon";

            default:
                // If identity_category_list exists, return 'settings_icon_cloud', otherwise 'settings_icon'
                return item.identity_category_list ? 'settings_icon_cloud' : 'settings_icon';
        }
    }


    const getItemIconTitle = (item) => {
        switch (item?.source_type) {
            case "Device":
                return !item.is_known_identity ? "Unresolved  NHI-Device" : "NHI-Device";
            case "App":
                return !item.is_known_identity ? "Unresolved  NHI-App" : "NHI-App";
            case "Service":
                return !item.is_known_identity ? "Unresolved  NHI-Service" : "NHI-Service";
            case "Service/Application":
                return !item.is_known_identity ? "Unresolved NHI-Application" : "NHI-Application";
            case "Service/Computer Account":
                return !item.is_known_identity ? "Unresolved NHI-Service/Computer Account" : "NHI-Service/Computer Account";
            case "Service/Service Account":
                return !item.is_known_identity ? "Unresolved NHI-Service" : "NHI-Service";
            case "Service/Service Principal":
                return !item.is_known_identity ? "Unresolved NHI-Service/Service Principal" : "NHI-Service/Service Principal";
            case "Service/Key and Secret":
                return !item.is_known_identity ? "Unresolved NHI-Service/Key and Secret" : "NHI-Service/Key and Secret";
            case "Service/Token":
                return !item.is_known_identity ? "Unresolved NHI-Service/Token" : "NHI-Service/Token";
            case "User/Agent":
                return !item.is_known_identity ? "Unresolved User/Agent" : "User/Agent";
            case "User/Background Browsing":
                return !item.is_known_identity ? "Unresolved User/Background Browsing" : "User/Background Browsing";
            case "User":
                return !item.is_known_identity ? "Unresolved User" : "User";
            default:
                // If identity_category_list exists, return 'settings_icon_cloud', otherwise 'settings_icon'
                return 'N/A';
        }

    }


    const getItemDestinationTitle = (item) => {
        switch (item?.destination_type) {
            case "Device":
                return "Device";
            case "App":
                return "App";
            case "Service":
                return "Service";
            case "Service/Application":
                return item?.dst_cat_ids && item?.dst_cat_ids?.length > 0 || item?.is_dir_public ? "Cloud Service/Application" : "Service/Application";
            case "Service/Computer Account":
                return item?.dst_cat_ids && item?.dst_cat_ids?.length > 0 || item?.is_dir_public ? "Cloud Service/Computer Account" : "Service/Computer Account";
            case "Service/Service Account":
                return item?.dst_cat_ids && item?.dst_cat_ids?.length > 0 || item?.is_dir_public ? "Cloud Service/Service Account" : "Service/Service Account";
            case "Service/Service Principal":
                return item?.dst_cat_ids && item?.dst_cat_ids?.length > 0 || item?.is_dir_public ? "Cloud Service/Service Principal" : "Service/Service Principal";
            case "Service/Key and Secret":
                return item?.dst_cat_ids && item.dst_cat_ids?.length > 0 ? "Cloud Service/Key and Secret" : "Service/Key and Secret";
            case "Service/Token":
                return item?.dst_cat_ids && item.dst_cat_ids?.length > 0 ? "Cloud Service/Token" : "Service/Token";
            case "User/Agent":
                return item?.dst_cat_ids && item?.dst_cat_ids?.length > 0 || item?.is_dir_public ? "Cloud User/Agent" : "User/Agent";
            case "User/Background Browsing":
                return item?.dst_cat_ids && item?.dst_cat_ids?.length > 0 || item?.is_dir_public ? "Cloud User/Background Browsing" : "User/Background Browsing";
            case "User":
                return item?.dst_cat_ids && item?.dst_cat_ids?.length > 0 || item?.is_dir_public ? "Cloud User" : "User";
            default:
                // If identity_category_list exists, return 'settings_icon_cloud', otherwise 'settings_icon'
                return 'N/A';
        }

    }


    const handleSearch = (e: React.KeyboardEvent<HTMLInputElement>) => {
        // param.q=e.currentTarget.value;
        // param.page=1;
        // if(e.key==="Enter"){
        //     setQuery(param);
        // }

        const { key } = e;
        const trimmedInput = searchInput?.trim();


        if ((key === 'Enter') && trimmedInput?.length && !tags?.includes(trimmedInput) && trimmedInput !== "") {
            setTags(prevState => [...prevState, trimmedInput]);
            setSearchInput('');
            setTagFlag(true);
            resetSelectionState();
        }

        else if (key === "Backspace" && tags.length > 0 && trimmedInput.length === 0) {
            let tempTags = [...tags]
            tempTags.pop();
            setTags(tempTags);
            setTagFlag(true);
        }

    }

    const handleLensSearch = () => {
        const trimmedInput = searchInput?.trim();

        if (trimmedInput?.length && !tags?.includes(trimmedInput) && trimmedInput !== "") {
            setTags(prevState => [...prevState, trimmedInput]);
            setSearchInput('');
            setTagFlag(true);
            resetSelectionState();
        }
    }

    useEffect(() => {
        console.log({ tagFlag, tags })
        if ((tagFlag && tags.length >= 0) || isClearAll) {
            param.page = 1;
            param.q = tags.join("+");
            param.d_name = undefined;
            param.risk = undefined;
            param.d_protocol = undefined;
            param.hash = undefined;
            param.e_time = undefined;
            param.s_time = undefined;
            param.rule_name = undefined;
            setQuery(param);
            setIsClearAll(false); // reset clearall status.
        }
    }, [tags, tagFlag])

    const [showOudatedData, setShowOutdatedData] = useState(false);

    const outDated = () => {
        let clearOutdatedDataInterval = null;
        setShowOutdatedData(false);

        let tempTags = getTagsForTimeBasedSearch(tags);

        tempTags = Object.keys(AVAILABLE_PRESETS).filter(item => {
            if (tempTags && tempTags.length > 0)
                return item === tempTags[0][1]
            return false;
        })
        if (tempTags && tempTags.length > 0) {
            if (clearOutdatedDataInterval)
                clearInterval(clearOutdatedDataInterval);
            clearOutdatedDataInterval = setTimeout(() => {
                if (isPresetPeriodOptionSelected()) {
                    setShowOutdatedData(true);
                }
            }, 30000);
        }
        return () => {
            if (clearOutdatedDataInterval)
                clearInterval(clearOutdatedDataInterval);
        }
    }
    useEffect(() => {
        outDated();
    }, [tags])

    const applySearchHandler = useCallback((data: any, clearAll: boolean = false, deletedKeys = []) => {

        let tempFilterObj = getSearchFilterObjectFromTags(issueTemplate, tags);
        let filterObj = {};
        for (let i in tempFilterObj) {
            if (deletedKeys.indexOf(i) == -1)
                filterObj[i] = tempFilterObj[i];
        }

        filterObj = { ...filterObj, ...data }

        if (clearAll) {
            setIsClearAll(clearAll);
            setTags([]);
            return;
        }

        if (filterObj) {
            /* // Changed requirement merge not needed now removing merging logic.
            const addedTags = new Map();
            const textBoxSupportedKeys = notSupportedKeys.map(p => p.search_key);
    
            const allRetainableKeys =
                issueTemplate.map(p => p.search_key)
                    .filter(p => !deletedKeys.some(d => p === d))
                    .concat(textBoxSupportedKeys);
    
            const incidentTags = (tags.length > 0) ? tags : q?.split('+') || [];
            incidentTags?.forEach(p => {
                const [tagName] = p?.split(/([:~|])/) || [];
                if (tagName && allRetainableKeys.indexOf(tagName) > -1) {
                    addedTags.set(tagName, p);
                }
            });*/
            if (Object.keys(filterObj).length > 0) {
                // update tags array on Apply AdvanceSearch (Merge only)
                const searchTextList = [];
                for (let filter in filterObj) {
                    let filterValue: any = filterObj[filter]?.value;
                    let opr = supportedOperators.get(String(filterObj[filter]?.type)) || '';
                    if (filter === 'any_field') {
                        filterValue.forEach((p: String) => {
                            if (p) {
                                //addedTags.set(p, p); // set any field value without key. // Change Requirement reverting logic
                                searchTextList.push(p)
                            }
                        })
                        continue;
                    }
                    //addedTags.set(filter, `${filter}${opr}${filterValue}`); // Change Requirement reverting logic

                    searchTextList.push(`${filter}${opr}${encodeURIComponent(filterValue)}`);

                }
                if (searchTextList.length > 0) {
                    // const merged = Array.from(addedTags.values());// Change Requirement reverting logic
                    console.log({ searchTextList })
                    setTags(searchTextList);
                    setTagFlag(true);
                }
            }
            resetSelectionState();
        }
    }, [tags, q]);



    const applyTimeBasedSearchHandler = useCallback((data, clear) => {
        let filterObj = getSearchFilterObjectFromTags(issueTemplate, tags);
        if (clear) {
            if (filterObj.hasOwnProperty(TIME_BASED_SEARCH_FIELD)) {
                delete filterObj[TIME_BASED_SEARCH_FIELD];
                clear = false;
            }
            clear = Object.keys(filterObj).length === 0 ? true : false;
        } else {
            filterObj = { ...filterObj, ...data }
        }

        if (clear) {
            setIsClearAll(clear);
            setTags([]);
            return;
        }

        if (filterObj) {
            /* // Changed requirement merge not needed now removing merging logic.
            const addedTags = new Map();
            const textBoxSupportedKeys = notSupportedKeys.map(p => p.search_key);
    
            const allRetainableKeys =
                issueTemplate.map(p => p.search_key)
                    .filter(p => !deletedKeys.some(d => p === d))
                    .concat(textBoxSupportedKeys);
    
            const incidentTags = (tags.length > 0) ? tags : q?.split('+') || [];
            incidentTags?.forEach(p => {
                const [tagName] = p?.split(/([:~|])/) || [];
                if (tagName && allRetainableKeys.indexOf(tagName) > -1) {
                    addedTags.set(tagName, p);
                }
            });*/

            if (Object.keys(filterObj).length > 0) {
                // update tags array on Apply AdvanceSearch (Merge only)
                const searchTextList = [];
                for (let filter in filterObj) {
                    let filterValue: any = filterObj[filter]?.value;
                    let opr = supportedOperators.get(String(filterObj[filter]?.type)) || '';
                    if (filter === 'any_field') {
                        filterValue.forEach((p: String) => {
                            if (p) {
                                //addedTags.set(p, p); // set any field value without key. // Change Requirement reverting logic
                                searchTextList.push(p)
                            }
                        })
                        continue;
                    }
                    //addedTags.set(filter, `${filter}${opr}${filterValue}`); // Change Requirement reverting logic
                    searchTextList.push(`${filter}${opr}${filterValue}`);
                }
                if (searchTextList.length > 0) {
                    // const merged = Array.from(addedTags.values());// Change Requirement reverting logic
                    setTags(searchTextList);
                    setTagFlag(true);
                }
            }
        }
    },
        [tags, q]
    )

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchInput(e.target.value)
    }

    const deleteTag = (index: number, tg: string) => {
        const anyActivityTimeTag = tags.filter(item => item.indexOf(TIME_BASED_SEARCH_FIELD) > -1);
        let newTags = getTagsForSearchInput(tags, true).filter((tag, i) => tag !== tg)
        if (anyActivityTimeTag && anyActivityTimeTag.length > 0) {
            newTags = [...newTags, ...anyActivityTimeTag];
        }
        resetSelectionState();
        if (newTags.filter((value) => isSupportedTag(value)).length === 0) {
            param.q = ''
            setQuery(param)
            setTags([])
        } else {
            setTags(newTags);
            setTagFlag(true);
        }
    }

    const headers = [
        { label: "Open Since", key: "open_since" },
        { label: "Last Access Time", key: "gen_timestamp" },
        { label: "# Flows", key: "issue_flows_count" },
        { label: "Incident #", key: "_id" },
        { label: "Matching Playbook", key: "playbook_name" },
        { label: "Matching Issue", key: "matching_rule_name" },
        { label: "Issue Highlights", key: "root_cause_data" },
        { label: "Identity Type", key: "source_type" },
        { label: "Identity Name", key: "source_name" },
        { label: "Identity Hostname", key: "latest_source_hostname" },
        { label: "Identity IP", key: "source_ip" },
        { label: "Identity Port", key: "source_port" },
        { label: "Asset Type", key: "destination_type" },
        { label: "Asset Name", key: "destination_name" },
        { label: "Asset Hostname", key: "destination_hostname" },
        { label: "Asset IP", key: "destination_ip" },
        { label: "Asset Port", key: "destination_port" },
        { label: "Protocol", key: "destination_protocol" },
        { label: "Actions", key: "actions" },
        { label: "Status", key: "status" },
        { label: "Risk", key: "risk" },
        { label: "Sensor Site Code", key: "sensor_location" },
    ];

    const [downData, setDownData] = useState<string | Data>([]);
    const [totalDownloaded, setTotalDownloaded] = useState<number | undefined>(0)
    const [loading, setLoading] = useState({ loading: false, setData: false })
    const currDownData: any = [];
    const csvLinkEl = useRef<any>();

    const getUserList = async (selectedPage?: any) => {
        const searchHeaders = { headers: { search_fields: '' } };
        let searchTags: String[] = tags || [];
        param.page = selectedPage === 0 ? 1 : selectedPage + 1;
        param.rpp = 50;
        if (param.q && param.q?.split('+').length > 0) {
            const tags = param.q?.split('+');
            searchTags = tags;
        }
        const fields = getSearchFilterObjectFromTags(issueTemplate, searchTags);
        const que = await generateTagsFromSearch(fields);
        if ((searchTags || []).length > 0) {
            const searchFieldsList = getSearchFilterObjectFromTags(issueTemplate, que || [], true);
            searchHeaders.headers.search_fields = encodeURIComponent(JSON.stringify(searchFieldsList));
        }
        // if ((searchTags || []).length > 0) {
        //     const searchFieldsList = getSearchFilterObjectFromTags(issueTemplate, searchTags, true);
        //     searchHeaders.headers.search_fields = encodeURIComponent(JSON.stringify(searchFieldsList));
        // }
        return Api.get("/issues", {
            params: { ...param, is_export_csv: true },
            paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
            ...searchHeaders
        })
            .then(res => {
                res.data.result.map((item: any, index: any) => {
                    item.open_since = moment(item.first_flow_time).fromNow(true);
                    const wrapper = document.createElement('div');
                    wrapper.innerHTML = item.issue;
                    item.root_cause_data = wrapper.textContent;
                    item.destination_ip = item.destination_ip.startsWith('127.') ? 'N.A.' : item.destination_ip
                    item.source_ip = item.source_ip.startsWith('127.') ? 'N.A.' : item.source_ip
                    item.matching_rule_name = getIssueNameById(item?.matching_rule_name)
                    currDownData.push(item)
                });
                console.log(currDownData);
                return currDownData;
            }).catch((error: any) => {
                setLoading({ loading: false, setData: false })
                if (error.response.status === 500 || error.response.status === 524) {
                    addToast("Sorry, something went wrong there, try again.", {
                        appearance: 'error',
                        autoDismiss: true,
                    })
                } else if (error.response.status === 404) {
                    addToast("We are not able to find associated email, please check and try again.", {
                        appearance: 'error',
                        autoDismiss: true,
                    })
                }
            });
    }

    const downloadCSV = async () => {

        setLoading({ loading: true, setData: false })
        let i = 0;
        do {
            const downData = await getUserList(i);
            setTotalDownloaded(i * 50);
            if (i > Math.floor(totalIssue! / 50)) {
                setDownData(downData);
                setTimeout(() => {
                    setLoading({ loading: false, setData: true })
                    if (csvLinkEl && csvLinkEl?.current && csvLinkEl?.current?.link) {
                        csvLinkEl?.current?.link?.click();
                    }
                });
            }
            i = i + 1;
        } while (i <= Math.floor(totalIssue! / 50) + 1)

    }

    const handleGotoPage = () => {
        let pageValue = (document.getElementsByName("page_no")[0] as HTMLTextAreaElement).value;
        pageValue = (pageValue !== "") ? pageValue : "1";
        let currentUrlParams = new URLSearchParams(window.location.search);
        currentUrlParams.set('page', pageValue);
        (document.getElementsByName("page_no")[0] as HTMLTextAreaElement).value = "";
        history.push(window.location.pathname + "?" + currentUrlParams.toString());
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }

    const isAddExceptionEnabled = () => {
        if (!isAuthorizedAction(PostureMenuItemAction.ADD_EXCEPTION.toString())) return false;
        let isExceptionSearchFieldExist = false;
        let isFreeKeyword = false;
        let criteriaCount = 0;
        let isRuleName = false;
        tags.forEach((item: any) => {
            const index = item.indexOf('~') > -1 ? item.indexOf('~') : item.indexOf('|') > -1 ? item.indexOf('|') : item.indexOf(':');
            let key = undefined;
            if (index > -1) {
                key = item.substr(0, index);
            } else {
                isFreeKeyword = true;
            }
            if (key && ExceptionSearchFields.includes(key)) {
                isExceptionSearchFieldExist = true;
                criteriaCount++;
                if (key == 'rule_name') {
                    isRuleName = true;
                }
            }
        });
        if (isRuleName && criteriaCount == 1) {
            return false;
        }

        if (authDetails.permissions.Issues_and_Prevention?.bulk_close_issues == 'readwrite') {
            setTimeout(() => {
                if (document.getElementById('issue-more-action-btn-grp')) {
                    const btn = document.getElementById('issue-more-action-btn-grp');
                    if (isExceptionSearchFieldExist) {
                        btn?.classList.add('exception-border');
                    } else {
                        btn?.classList.remove('exception-border');
                    }
                }
            }, 500)

            return selectAll && isExceptionSearchFieldExist && !isFreeKeyword;
        }
    }

    const addException = () => {
        setShowAddException(true);
    }

    const getTagName = (item: string) => {
        return item.indexOf('~') > -1 ? item.split('~')[0] : item.indexOf('|') > -1 ? item.split('|')[0] : item.split(':')[0];
    }

    const getTagValue = (item: string) => {
        const index = item.indexOf('~') > -1 ? item.indexOf('~') : item.indexOf('|') > -1 ? item.indexOf('|') : item.indexOf(':');
        return item.slice(index + 1);
    }

    useEffect(() => {
        getExceptionCriteria();
    }, [selectedIncidents, selectAll])

    const getExceptionCriteria = () => {
        let isDirExist = false, index = -1;
        const t = tags.map((i: string, ind: number) => {
            if (getTagName(i) === 'dir_hostname') {
                isDirExist = true;
                index = ind;
            }
            return i;
        });
        if (isDirExist) {
            const i = Object.values(selectedIncidents)[0] as IssueObj;
            if (i && !isPrivateIp(i.directory_ip)) {
                t[index] = `dir_names:` + i?.directory_name;
            }
        }
        setExceptionCriteria(t || []);
    }

    const handleConfirmAddException = () => {
        setShowAddException(false);
        let isDirExist = false, index = -1;
        const t = tags.map((i: string, ind: number) => {
            if (getTagName(i) === 'dir_hostname') {
                isDirExist = true;
                index = ind;
            }
            return i;
        });
        if (isDirExist) {
            const i = Object.values(selectedIncidents)[0] as IssueObj;
            if (!isPrivateIp(i.directory_ip)) {
                t[index] = `dir_names:` + i?.directory_name;
            }
        }
        localStorage.setItem('addException', JSON.stringify(t));
        history.push('/exceptions/add');
    }

    const onAdvanceSearchClick = () => {
        setShowAdvanceSearch(true);
    }

    const onCloseAdvanceSearch = useCallback(() => {
        setShowAdvanceSearch(false);
    }, [])

    const onIssueFlowClick = () => {
        setSelectAll(false);
        setSelectCount(0);
        setSelectRow({});
        setSelectAllIndeterminate(false);
        setSelectedIncidents({});
    }

    const isMarkKnownEnabled = () => {
        if (!isMarkKnownOptionAllowed()) return false;
        const selectedIssues = selectedIncidents;
        let isAssetNameExists = false;
        tags.forEach((item: string) => {
            const i = item.indexOf('~') > -1 ? item.split('~')[0] : item.indexOf('|') > -1 ? item.split('|')[0] : item.split(':')[0];
            if (i == 'd_name') {
                isAssetNameExists = true;
            }
        });

        borderMarkKnown();

        if ((selectAll && isAssetNameExists) || (selectCount > 0 && isAssetNameExists)) {
            return true;
        }
        return false;
    }

    const borderMarkKnown = () => {
        let isAssetNameExists = false;
        tags.forEach((item: string) => {
            const i = item.indexOf('~') > -1 ? item.split('~')[0] : item.indexOf('|') > -1 ? item.split('|')[0] : item.split(':')[0];
            if (i == 'd_name') {
                isAssetNameExists = true;
            }
        });

        if (authDetails.permissions.Issues_and_Prevention?.bulk_close_issues == 'readwrite') {
            setTimeout(() => {
                if (document.getElementById('issue-more-action-btn-grp')) {
                    const btn = document.getElementById('issue-more-action-btn-grp');
                    if (isAssetNameExists) {
                        btn?.classList.add("asset-border");
                    } else {
                        btn?.classList.remove('asset-border');
                    }
                }
            })
        }
    }

    const markKnown = () => {
        if (selectAll || selectAllIndeterminate) {
            let _id = '';
            tags.forEach((item: string) => {
                const i = item.indexOf('~') > -1 ? item.split('~')[0] : item.indexOf('|') > -1 ? item.split('|')[0] : item.split(':')[0];
                if (i == 'd_name') {
                    let a = item.indexOf('~') > -1 ? item.split('~') : item.indexOf('|') > -1 ? item.split('|') : item.split(':');
                    if (a.length > 2) {
                        a.splice(0, 1);
                        _id = a.join(':');
                    } else {
                        _id = a[1];
                    }
                }
            });

            const queryData = query?.q?.split('+') || [];
            let searchFieldsList = {};
            if ((queryData || []).length > 0) {
                searchFieldsList = getSearchFilterObjectFromTags(issueTemplate, queryData);
            }
            setMarkKnownData({ _id });
            setIsMarkKnownQuery(searchFieldsList);
            sertExcludeIncidents(getUnSelectRow());
        } else {
            const markKnownObject = { _id: '', host_detail: [] };
            const host_detail: any = [];
            if (Object.values(selectedIncidents).length > 0) {
                Object.values(selectedIncidents).forEach((item: any) => {
                    if (item) {
                        host_detail.push({ ip: item.destination_ip, hostname: item.destination_hostname });
                        markKnownObject._id = item.destination_name;
                    }
                });
                markKnownObject.host_detail = host_detail || [];
                setMarkKnownData(markKnownObject);
            }
        }
        setShowMarkKnown(true);
    }

    const isKnownIPEnabled = (): any => {
        if (!isAuthorizedAction(PostureMenuItemAction.ADD_KNOWN_PUBLIC.toString())) return false;
        const src_ips: any[] = [];
        const dst_ips: any[] = [];
        const src_host: any[] = [];
        const dst_host: any[] = [];
        Object.keys(selectedIncidents).map((i: String) => {
            const o = selectedIncidents[i as keyof typeof selectedIncidents];
            if (o) {
                src_ips.push(o.source_ip);
                if (o?.source_hostnames) {
                    src_host.push(o.source_hostnames[o?.source_hostnames?.length - 1]?.name || '');
                }
                dst_ips.push(o.destination_ip);
                dst_host.push(o.destination_hostname);
            }
        });
        const src_ip_pre = calculatePrefix(src_ips);
        const dst_ip_pre = calculatePrefix(dst_ips);
        const src_host_pre = calculatePrefix(src_host);
        const dst_host_pre = calculatePrefix(dst_host);
        const prefixData = [{ id: src_ip_pre, label: 'Source Ips', ips: Array.from(new Set(src_ips)) },
        { id: dst_ip_pre, ips: Array.from(new Set(dst_ips)), label: 'Destination Ips' },
            // { id: dst_host_pre, label: 'Destination Hostnames', ips: Array.from(new Set(dst_host)) },
            // { id: src_host_pre, label: 'Source Hostnames', ips: Array.from(new Set(src_host)) }
        ];
        const isEnabled = src_ip_pre || dst_ip_pre || src_host_pre || dst_host_pre;
        return { isEnabled, prefixData };
    }

    const calculatePrefix = (ips: string[]): string | undefined => {
        let prefixIndex: any = undefined;
        if (ips.length > 1) {
            const f = ips[0] as string;
            const isAllSame = ips.every((el: string) => el == f);
            if (isAllSame) {
                /* Check if all same */
                const len = Array.from(new Set(ips)).length;
                if (len > 1) {
                    prefixIndex = f;
                }
            } else if (f.indexOf('.') >= 0) {
                let ind = f.indexOf('.');
                for (let i = 1; i < 4; i++) {
                    const isTrue = ips.slice(1).every((el: string) => el.slice(0, ind) == f.slice(0, ind));
                    if (i == 1 && f.slice(0, ind) == '10') {
                        /* if private ip */
                        prefixIndex = undefined;
                        break;
                    }
                    if (isTrue) {
                        prefixIndex = f.slice(0, ind) + '.*';
                        ind = f.indexOf('.', ind + 1);
                    }
                }
            }
        }
        return prefixIndex;
    }

    const MoreActionsOptions: IPlaybookActions<Array<any>> = [{
        actionCallback: closeTickets,
        actionId: 'close-incident',
        isDisabled: ((!isCloseIncEnabled() || showAdvanceSearch || isTimeBasedSearchApplied)),
        actionLabel: "Close " + (selectCount == 1 ? '1 Incident' : (selectCount > 1 ? `${selectCount} Incidents` : 'Incidents')),
        isApplicable: authDetails.permissions.Issues_and_Prevention.bulk_close_issues == 'readwrite'
    },

    {
        actionCallback: markKnown,
        actionId: 'mark-knwon',
        isDisabled: !isMarkKnownEnabled(),
        actionLabel: 'Mark Asset as Known',
        classes: document.getElementById('issue-more-action-btn-grp')?.classList.contains('asset-border') ? 'asset-name-border-menu' : '',
        isApplicable: authDetails.permissions.Admin.knownapps == 'readwrite'
    },
    {
        actionCallback: addException,
        actionId: 'add-exception',
        isDisabled: !isAddExceptionEnabled(),
        actionLabel: 'Add Exception',
        classes: document.getElementById('issue-more-action-btn-grp')?.classList.contains('exception-border') ? 'yellow-border' : '',
        isApplicable: authDetails.permissions.Admin.exception == 'readwrite'
    },
    {
        actionCallback: function () {
            setKnownIPData(MoreActionsOptions[3].customData);
        },
        actionId: 'add-known',
        isDisabled: !isKnownIPEnabled().isEnabled,
        actionLabel: 'Add to Known Internal Public IPs',
        customData: isKnownIPEnabled().prefixData,
        isApplicable: authDetails.permissions.Admin.known_public_ip == 'readwrite'
    }
    ];

    const onKnownIPDataProceed = (data: any) => {
        const ob = {
            comment: data.id,
            ips: data.ips
        }
        setKnownIPData(undefined);
        sessionStorage.setItem('known_public_ip', JSON.stringify(ob));
        history.push('/known_public_ip/add');
    }

    const handleSuspectedClick = (issue_id: string, source_name: string, suspected_source_info: any, matching_rule_name: string) => {
        setShowSuspectedPopUp({
            showPopUp: true,
            response: suspected_source_info,
            sourceName: source_name,
            matching_rule_name
        });
    }

    const preSetMetaDataHandler = res => {
        // Sort playbook names
        if (res.data.pb_name) {
            res.data.pb_name = res.data.pb_name.sort((a, b) => a.localeCompare(b));
        }
        res.data.dir_type = ['On-premise', 'Cloud IDP'];
        res.data.identity_is_public = ['true', 'false'];
        res.data.asset_is_directory = ['true', 'false'];
        res.data.asset_is_public = ['true', 'false'];
        res.data.asset_is_saas = ['true', 'false'];
        res.data.asset_is_known = ['true', 'false'];
        res.data.identity_is_known = ['true', 'false'];
        res.data.first_flow_time = ['hour(s)', 'day(s)'];
        res.data.last_flow_time = ['hour(s)', 'day(s)'];
        res.data.id_attrs_change_timestamp = ['hour(s)', 'day(s)'];
        res.data.rule_name = res.data.rule_name.map((i) => ({ key: i, value: getIssueNameById(i) || i }))
        res.data.risk = res.data.risk.map((i) => ({ key: i, value: GlobalConsts.RISK[i] }))
    }

    const preMetaDataHandler = (setMetaData) => {
        const fetchFilterMetaData = () => {
            return Api.get('/issues/uniquefields');
        };
        fetchFilterMetaData()
            .then(async (res) => {
                preSetMetaDataHandler(res);
                const arr = await GLOBAL_SERVICE.Category.GET();
                const data = { ...res.data, ...arr };
                data['d_type'] = ['Application',
                    'Device',
                    'Service',
                    'Service/Computer Account',
                    "Service/Key and Secret",
                    'Service/Service Principal',
                    "Service/Token"];
                data['s_type'] = ['Non Human Identities (NHI)',
                    'Application',
                    'Device',
                    'Service',
                    'Service/Computer Account',
                    "Service/Key and Secret",
                    'Service/Service Principal',
                    "Service/Token",
                    'Users',
                    'User',
                    'User/Agent',
                    'User/Background Browsing'];

                setMetaData(data);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const [openPlayBookModal, setOpenPlayBookModal] = useState(false);
    const [playbookId, setPlaybookId] = useState('');

    const customGridReady = (params) => {
        setContext({ gridApi: params.api });
    }

    const getCheckboxParams = (item: IssueObj) => {
        return {
            checked: getSelectRow(item._id, item.status),
            disabled: item?.status.toLowerCase() !== 'open'
        }
    }

    const customActionCellRender = (item: IssueObj, tableActions) => {
        const a: Array<ITableAction<IssueObj>> = [];

        item?.actions?.filter((i: string) => i != 'Create Ticket').forEach((i: string) => {
            const action: ITableAction<IssueObj> = {
                actionId: i,
                actionLabel: i,
                class: "disableItems",
                actionCallback: () => { }
            }
            a.push(action)
        })


        if (item.is_ticket_exist) {
            const action: ITableAction<IssueObj> = {
                actionId: 'go-to-ticket',
                actionLabel: 'Go To Ticket',
                actionCallback: (data) => {
                    history.push("/tickets/" + item._id);
                }
            }
            a.push(action)
        } else {
            const action: ITableAction<IssueObj> = {
                actionId: 'create-ticket',
                actionLabel: 'Create Ticket',
                actionCallback: (data) => {
                    setTicketId(parseInt(item._id.toString()));
                    setOpenMoreDetails(true);
                }
            }
            a.push(action)
        }

        if (item.closure_reason !== 'Playbook deleted') {
            const action: ITableAction<IssueObj> = {
                actionId: 'playbook-action',
                actionLabel: 'View Playbook',
                actionCallback: (data) => {
                    setOpenPlayBookModal(true);
                    setPlaybookId(item.playbook_id.toString());
                }
            }
            a.push(action)
        }

        if (authDetails.permissions.Issues_and_Prevention.bulk_close_issues === 'readwrite' && item.status !== "Closed") {
            const action: ITableAction<IssueObj> = {
                actionId: 'close-ticket',
                actionLabel: 'Close Incident',
                actionCallback: (data) => {
                    closeTicketsFromAction(item._id.toString())
                },
                class: `${isTimeBasedSearchApplied && 'disableItems'}`
            }
            a.push(action)
        }
        return a;
    }

    const getRowHeightFn = (params: RowHeightParams) => {

        return getResolutionMatchFor1920(100, 140);
    }

    return (
        <>
            <PlaybookModal
                isOpen={openPlayBookModal}
                onCloseHandler={() => {
                    setOpenPlayBookModal(false);
                }}
                playbookId={playbookId}
                title="View Playbook"
                formType='view' playbookImportConfig={undefined}
            />
            {/* {showAdvanceSearch && <Filter filterTemplate={issueTemplate} notSupportedKeys={notSupportedKeys} filters={tags} isOpen={showAdvanceSearch} onApplyFilter={applySearchHandler} onClose={onCloseAdvanceSearch} />} */}
            {showAdvanceSearch && <PostureFilter
                filterTemplate={issueTemplate.filter(item => item.search_key !== TIME_BASED_SEARCH_FIELD)}
                notSupportedKeys={notSupportedKeys}
                filters={tags} isOpen={showAdvanceSearch}
                onApplyFilter={applySearchHandler} onClose={onCloseAdvanceSearch}
                disableCreatePlaybookBtn={true}
                entityType={''}
                matchingPlaybookFoundFn={() => { }}
                query={query}
                showCreatePlaybookBtn={() => { }}
                showAllFields={true}
                preMetaDataHandler={preMetaDataHandler}
                isTimeBasedSearchApplied={isTimeBasedSearchApplied}
                apiUrl={'/issues/createdperday'} pageType={'incidents'}
                PartnerConfigState={PartnerConfigState}
            />}
            <div className="">
                <div className="flow_table_container incidents-container">
                    <div>
                        <div className="search_section" style={{ float: 'left' }}>
                        </div>
                        <div style={{ float: 'right' }}>
                            <TimeBasedSearchContainer onApply={applyTimeBasedSearchHandler} filters={getTagsForTimeBasedSearch(tags)} apiUrl={'/issues/createdperday'} pageType={'incidents'}></TimeBasedSearchContainer>
                        </div>
                    </div>

                    <div id="tab1" className="tab-panel">
                        <SearchBox
                            reloadRef={reloadRef}
                            reloadEntity={reloadIssues} lastUpdate={lastUpdate}
                            showOudatedData={showOudatedData}
                            MoreActionsOptions={MoreActionsOptions} selectCount={selectCount}
                            showAdvanceSearch={showAdvanceSearch} isTimeBasedSearchApplied={isTimeBasedSearchApplied}
                            onAdvanceSearchClick={onAdvanceSearchClick} handleLensSearch={handleLensSearch} tags={tags}
                            deleteTag={deleteTag} searchInput={searchInput} handleSearchChange={handleSearchChange}
                            handleSearch={handleSearch} setTags={setTags} setTagFlag={setTagFlag}
                        />
                        <BaseAMAdminGrid
                            gridRef={childRef}
                            columnDefs={IssueTableCols}
                            fetchEntitiesFn={fetchIssues}
                            query={query}
                            setQuery={setQuery}
                            context={context}
                            customGridReady={customGridReady}
                            classes={{ gridContainerClass: 'issue-base-am-grid' }}
                            actions={[{}]}
                            onCurrentPageSelect={onCurrentPageSelect}
                            onSelectAllRow={onSelectAllClick}
                            onSelectRow={onSelectRowClick}
                            {...(authDetails?.permissions?.Issues_and_Prevention?.bulk_close_issues === "readwrite"
                                ? { showCheckbox: true } : null)}
                            headerCheckBoxConfig={headerCheckboxConfig}
                            getCheckboxParams={getCheckboxParams}
                            onBeforeActionCellRender={customActionCellRender}
                            getRowHeightFn={getRowHeightFn}
                        />
                        <div className="clrBoth"></div>
                    </div>
                    <div className="download_section">
                        <button type={('button')} onClick={downloadCSV} className={"width180 " + (loading.loading ? 'loader export_loader' : 'button_styled')}>Export CSV</button>

                        {loading.loading ?
                            <div className='loading_info'>
                                Received <span ><b>{totalDownloaded}</b></span> of <span ><b>{totalCount}</b></span> Issues
                            </div>
                            : null}
                        {PartnerConfigState?.PartnerShortProduct && <CSVLink
                            headers={headers}
                            // filename={`${PartnerConfigState?.PartnerShortProduct?.replaceAll(" ", "_")} _Issues.csv`}
                            filename={getExportFileName(PartnerConfigState?.PartnerShortProduct, 'Issues')}

                            data={downData!}
                            ref={csvLinkEl}
                        />}
                        <div style={{ marginLeft: '1rem', marginTop: '1rem' }}>
                            <Link to={'/archivedata'} className='link-text font12'>Incidents inactive for more than 60 days are archived here.</Link>
                        </div>
                    </div>
                </div>
            </div>
            <CloseIncidents closeIncPopUp={closeIncPopUp}
                handleCloseIncPopUp={handleCloseIncPopUp}
                selectCount={selectCount}
                closeFromAction={closeFromAction}
                selectAll={selectAll}
                tags={tags}
                disableYes={disableYes}
                setIsCloseAll={setIsCloseAll}
                setStatusComment={setStatusComment}
                confirmPasswordOperation={confirmPasswordOperation}
                isCloseAll={isCloseAll}
                handleCloseTicket={handleCloseTicket}
                closeTicket={closeTicket} />
            <AMConfirmPassword
                title="Confirm Close Incidents"
                content="Enter your password to confirm this operation"
                openConfirmPassword={openConfirmPassword}
                confirmPasswordOperation={confirmPasswordOperation}
                closeConfirmPassword={closeConfirmPassword}
                onPasswordEnter={(evt: any) => setOpPassword(evt)}
            />
            {openMatchRule ? <MatchruleDetails data={matchedRuleData} closeMatchRule={() => setOpenMatchRule(false)} /> : ''}
            {openFlow ? <FlowTrend data={flowsTrendResponseData} chartDetails={flowsChartDetails} closeFlowPopup={() => setOpenFlow(false)} onRangeChange={handleRangeChange} zoomLevel={zoomLevel} updatedExtremes={updatedExtremesRef} showZoom={true} /> : ''}
            {openMoreDetails ? <MoreActionsPopup handleTicketCreateNew={handleTicketCreate} closeMoreDetails={() => setOpenMoreDetails(false)} /> : ''}
            {showAddException ? <AddExceptionPopUp handleClose={() => setShowAddException(false)} handleConfirmAddException={handleConfirmAddException} criteria={exceptionCriteria} /> : null}
            {showMarkKnown ? <PostureMarkKnownPopUp onClose={() => {
                setShowMarkKnown(false);
                setIsMarkKnownQuery(undefined);
            }} data={markKnownData} query={isMarkKnownQuery} exclude_incidents_list={excludeIncidents} /> : null
            }
            {knownIPData ? <MarkKnownIPPopUp knownIPData={knownIPData} onClose={() => setKnownIPData(undefined)} onProceed={onKnownIPDataProceed} /> : null}
            {showSuspectedPopUp?.showPopUp && <SuspectedList
                handleClose={() => setShowSuspectedPopUp(false)}
                resultArray={showSuspectedPopUp?.response}
                source={showSuspectedPopUp?.sourceName}
                matchingRule={showSuspectedPopUp?.matching_rule_name}
            />}
        </>
    )
}

const IssueTab = React.memo(withQueryParams({
    q: StringParam,
    page: StringParam,
    rpp: NumberParam,
    sort_by: StringParam,
    order_by: StringParam,
    rule_name: StringParam,
    d_protocol: StringParam,
    risk: NumberParam,
    hash: StringParam,
    d_name: StringParam,
    s_time: NumberParam,
    e_time: NumberParam,
    disable_filter: StringParam,
    hard_refresh: StringParam,
    summary: StringParam,
    filters: withDefault(ArrayParam, [])
}, IssuesCore));


const Issues = ({ query, setQuery }: any) => {
    const [totalCount, setTotalCount] = useState<number>();
    const issueProps = {
        totalCount, setTotalCount
    }

    const [tabs, setTabs] = useState([
        {
            label: "INCIDENTS",
            route: "/issues",
            selected: true,
            title: "Incidents",
            Component: <IssueTab issueProps={issueProps} />
        }
    ])


    useEffect(() => {
        const t = [...tabs];
        t[0].label = totalCount ? `INCIDENTS (${convertToCommaValue(totalCount)})` : `INCIDENTS`
        setTabs(t)
    }, [totalCount])

    return <>
        <div className='marginBottom35'>
            <AMBreadcrumb classes={{ containerClass: ' bradcrumb-animation' }} />
        </div>
        <AMTabs
            isPopupBased={true}
            tabs={tabs}
            onTabClick={() => { }}
        />
    </>
}

export default React.memo(withQueryParams({
    q: StringParam,
    page: StringParam,
    rpp: NumberParam,
    sort_by: StringParam,
    order_by: StringParam,
    rule_name: StringParam,
    d_protocol: StringParam,
    risk: NumberParam,
    hash: StringParam,
    d_name: StringParam,
    s_time: NumberParam,
    e_time: NumberParam,
    disable_filter: StringParam,
    hard_refresh: StringParam,
    summary: StringParam,
    filters: withDefault(ArrayParam, [])
}, Issues))