import { CustomCellRendererProps } from "@ag-grid-community/react";
import React, { FunctionComponent } from "react";
import { Tooltip } from "@mui/material";
import { AssetsObj, RulesDetailObj } from "../../../../types/response-types";
import InfoIcon from '@mui/icons-material/Info';
import { BasePostureActionMenu } from "../../ref/PostureActionMenus/BasePostureActionMenu";
import { AssetColumnMarkKnownOptions, AssetsActionMenu } from "../../constants/IDPostureMenu";
import { PostureMenuItem, PostureMenuItemAction } from "../../ref/PostureActionMenus/PostureActionMenu";
import { isIssueType } from "../../../../utils/util-methods";

interface AssetNameCellRendererProps extends CustomCellRendererProps {
    fetchAssets,
    items
}

const getIssueCount = (item: AssetsObj) => {
    return item.rules
        ? Object.values(item.rules).reduce(
            (prev: RulesDetailObj, i: RulesDetailObj) => ({
                ...prev,
                issue_count: prev.issue_count + i.issue_count
            }),
            {
                issue_count: 0,
                issue_flow_count: 0,
                issue_risk: 4,
                name: ''
            }
        ).issue_count
        : 0;
};


export const AssetNameCell: FunctionComponent<AssetNameCellRendererProps> = ({ data, fetchAssets, items }) => {
    const item: AssetsObj = data
    const itemName: any = item._id.split(' on ')[1];
    let asset_hostsList: any = [];

    const getRules = (item: AssetsObj, actionType?: string) => {
        let rule = '';
        Object.values(item.rules).map((i: RulesDetailObj) => {
            const isIssue = isIssueType(items, i.name);
            if (isIssue) {
                if (actionType === 'exception') {
                    if (i.issue_count > 0 && i.entity_type === 'Asset') {
                        rule += i.name + ',';
                    }
                } else {
                    if (i.issue_count > 0 && i.entity_type === 'Asset') {
                        rule += i.name + ',';
                    }
                }
            }
        });
        return rule.substring(0, rule.length - 1);
    };

    item.host_detail && item.host_detail.forEach((group: any) => {
        if (group.hostname !== '' && group.hostname !== undefined && group.hostname !== null &&
            group.ip_port !== '' && group.ip_port !== undefined && group.ip_port !== null) {
            if (item.host_detail.length === 1 && group.hostname?.toLowerCase() === 'unknown') {
                asset_hostsList.push({
                    hostname: group.hostname,
                    ip_port: group.ip_port,
                    city_name: group.city_name,
                    country_code2: group.country_code2,
                    country_name: group.country_name
                });
            } else {
                if (group.hostname?.toLowerCase() !== 'unknown')
                    asset_hostsList.push({
                        hostname: group.hostname,
                        ip_port: group.ip_port,
                        city_name: group.city_name,
                        country_code2: group.country_code2,
                        country_name: group.country_name
                    });
            }
        }
    });

    return <div
        className='action-btn-cell' style={{flexGrow:'1'}}
    >
        <div className='copy_field copy_host_count'>
            <span className='droplist'>
                <Tooltip
                    classes={{
                        tooltip:
                            'posture-root-column-container scrollbar-container'
                    }}
                    title={
                        <div
                            className={
                                'service_list2 ' +
                                (asset_hostsList?.length ===
                                    0
                                    ? 'no_border_bottom'
                                    : '')
                            }
                        >
                            <h2>
                                <span
                                    className='ellipsis_idassetname tooltip_ellipses'
                                    title={item._id.toString()}
                                >
                                    {item._id.toString()}
                                </span>
                                <div
                                    className='copy_idassetname tooltip_copy_title'
                                    title='Copy this text to Clipboard'
                                    onClick={() =>
                                        navigator.clipboard.writeText(
                                            item._id
                                        )
                                    }
                                ></div>
                            </h2>
                            {asset_hostsList.length > 0 ? (
                                <table>
                                    <tr>
                                        <th className='align_left'>
                                            Hostname
                                        </th>
                                        <th className='align_left'>
                                            IP:Port
                                        </th>
                                    </tr>
                                    {asset_hostsList.map(
                                        (
                                            group: any,
                                            index: any
                                        ) => {
                                            if (index < 5) {
                                                return (
                                                    <tr>
                                                        <td
                                                            title={
                                                                group.hostname
                                                            }
                                                            className='hostname_td align_left'
                                                        >
                                                            <span className='ellipsis_idassetname'>
                                                                {
                                                                    group.hostname
                                                                }
                                                            </span>
                                                            <div
                                                                className='copy_idassetname float-right-margin-none'
                                                                title='Copy this text to Clipboard'
                                                                onClick={() =>
                                                                    navigator.clipboard.writeText(
                                                                        group.hostname
                                                                    )
                                                                }
                                                            ></div>
                                                        </td>
                                                        <td className='align_left'>
                                                            {
                                                                group.ip_port
                                                            }
                                                        </td>
                                                    </tr>
                                                );
                                            }
                                        }
                                    )}
                                    {asset_hostsList.length >
                                        5 ? (
                                        <tr>
                                            <td
                                                colSpan={2}
                                                className='hostname_td align_left'
                                            >
                                                <span className='ellipsis_idassetname'>
                                                    ...
                                                </span>
                                            </td>
                                        </tr>
                                    ) : null}
                                </table>
                            ) : null}
                        </div>
                    }
                >
                    <div>
                        <span className='ellipsis_idassetname droplist'>
                            {item._id.split(' on ')[0]}{' '}
                            {itemName !== undefined
                                ? ' on '
                                : ''}
                            <i style={{ padding: '0px 3px' }}>
                                {itemName !== undefined
                                    ? itemName
                                    : ''}
                            </i>
                        </span>
                        <InfoIcon
                            style={{
                                fontSize: 14,
                                color: '#d4d8e1',
                                marginTop: 2,
                                marginLeft: 2,
                                transform: 'skewX(-10deg)'
                            }}
                        />
                    </div>
                </Tooltip>
            </span>
            <div
                className='assets copy_idassetname'
                title='Copy this text to Clipboard'
                onClick={() =>
                    navigator.clipboard.writeText(item._id)
                }
            ></div>
            {
                <BasePostureActionMenu
                    actionData={item}
                    menuItems={
                        getRules(item).length > 0
                            ? AssetsActionMenu
                            : AssetsActionMenu.filter(
                                (
                                    item: PostureMenuItem
                                ) =>
                                    item.action ==
                                    PostureMenuItemAction.MARK_KNOWN
                            )
                    }
                    exceptionData={[
                        `d_name:${item._id} `,
                        `rule_name: ` +
                        getRules(item, 'exception')
                    ]}
                    classes={'assets action_button'}
                    getContent={() => [
                        `d_name:${item._id} `,
                        `rule_name: ` +
                        getRules(item, 'exception')
                    ]}
                    incContent={() => [
                        `inc_count:${getIssueCount(item)} `,
                        `d_name:${item._id} `,
                        `rule_name: ` + getRules(item)
                    ]}
                    closeIncQuery={
                        `{
"status": "Open", "is_external": "false", "d_name": "${item._id}"` +
                        `, "rule_name": "` +
                        getRules(item) +
                        `"}`
                    }
                    issueCount={getIssueCount(item)}
                    reloadList={fetchAssets}
                    markKnownOptions={{
                        options:
                            AssetColumnMarkKnownOptions,
                        title: 'Classify this asset as…'
                    }
                    }
                    config={{
                        isSaaSApp:
                            item.rules[
                                'Unknown SaaS Access'
                            ]?.issue_count > 0
                                ? true
                                : false
                    }
                    }
                    keyName={`asset-${item._id}`
                    }
                />
            }
        </div >
    </div >
}