import { ColDef, IServerSideGetRowsParams } from '@ag-grid-community/core';
import { Chip } from '@mui/material';
import axios, { CancelTokenSource } from 'axios';
import moment from 'moment';
import * as qs from "qs";
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { CSVLink } from "react-csv";
import { Data } from 'react-csv/components/CommonPropTypes';
import { useHistory } from 'react-router';
import { Link, useLocation } from "react-router-dom";
import ReactTooltip from 'react-tooltip';
import { ArrayParam, NumberParam, StringParam, withDefault, withQueryParams } from 'use-query-params';
import { IDScore } from '../../../common/IDScore/IDScore';
import { useAuthDetails } from '../../../components/Authorization';
import { Api } from '../../../components/Axios';
import { useToasts } from '../../../components/core';
import BaseAMAdminGrid from '../../../components/core/AMTable/BaseAMAdminGrid';
import { getSearchFilterObjectFromTags, getTagsForSearchInput, getTagsForTimeBasedSearch, getTimeBasedSearchQueryParam } from '../../../components/core/PostureFilter/posture-filter-utils';
import PostureFilter from '../../../components/core/PostureFilter/PostureFilter';
import { FilterWidget } from '../../../components/core/PostureFilter/PostureFilterWidget';
import { AVAILABLE_PRESETS, TIME_BASED_SEARCH_FIELD } from '../../../components/TimeBasedSearch/Constants';
import { useTimeBasedSearchActions } from '../../../components/TimeBasedSearch/TimeBasedSearchActions';
import TimeBasedSearchContainer from '../../../components/TimeBasedSearch/TimeBasedSearchContainer';
import { usePostureStateContext } from '../../../store/PostureStateContextProvider';
import { AccessesObj, IssueFilters, IssueParams, RulesDetailObj, ServerResponse } from '../../../types/response-types';
import { getExportFileName, getResolutionMatchFor1920, getTimeDiffString, trimAfterSecondWord } from '../../../utils/util-methods';
import FlowTrend from '../../issue_prev/flow-trend/flow-trend';
import Tags from '../../issue_prev/issues/tags/tags';
import { PlaybookTableActions } from '../../playbooks/ref/AdvancedPlaybook/PlaybookTableActions';
import { IPlaybookActions } from '../../playbooks/ref/AdvancedPlaybook/PlaybookTypes';
import { ENTITIES, POSTURE_ISSUE } from '../constants/Constants';
import { AccessColumnMarkKnownOptions, AccessesActionMenu } from '../constants/IDPostureMenu';
import { PostureSearchTemplate, notSupportedKeys } from '../constants/PostureSearchTemplate';
import '../identity_asset.scss';
import '../posture_root_tooltip.css';
import { useAdditionalResults, useLensSearch, usePostureAdvanceSearch, usePostureArchival, validateLocalIps } from '../ref/Hooks/Posture';
import { useValidatePostureIssuesSearchResult } from '../ref/Hooks/PostureIssues';
import { IApiResponse, PostureService } from '../ref/Hooks/PostureService';
import { PostureMenuItem, PostureMenuItemAction } from '../ref/PostureActionMenus/PostureActionMenu';
import { IPostureActionButtonsRef, PostureActionButtons, PostureArchiveTooltip } from '../ref/PostureArchive/PostureArchive';
import { LatestTimeCell } from '../ref/PostureGrid/grid-common-cells/LastestTimeCell';
import { PostureIssueCountCell } from '../ref/PostureGrid/grid-common-cells/PostureIssueCountCell';
import { AccessAssetCell, AccessAssetTypeCell, AccessDirectoryCell, AccessFlowCountCell, AccessIdCell, AccessIdTypeTypeCell, DirectoryHeaderComponent } from './grid';
import MatchRule from './matchrule/matchrule';
import RuleMatch from './rule-match/rulematch';
import { FailedDirPopUp } from './sub/FailedDirPopUp';


const Accesses = ({ query, setQuery }: any) => {
    const location = useLocation();
    const search = useLocation().search;
    const hard_refresh_state: boolean = new URLSearchParams(search).get('hard_refresh') === "true" ? true : false;
    const [tableFetchError, setTableFetchError] = useState('No records found.');
    const [showRuleMatch, setShowRuleMatch] = useState(false);
    const [showMatchRule, setShowMatchRule] = useState(false);
    const [selectedColumn, setSelectedColumn] = useState(query.sort_by || '');
    const [currentSort, setCurrentSort] = useState(query.order_by == 'asc' ?
        ' tablesort_up tablesort_up_selected ' : ' tablesort_down tablesort_down_selected ');
    const [searchInput, setSearchInput] = useState<string>('');
    const [tagFlag, setTagFlag] = useState<boolean | undefined>();

    const [openPanelFilterWidgets, setOpenPanelFilterWidgets] = useState(false);
    const [filterData, setFilterData] = useState<IssueFilters | undefined>();
    const disabled_filter_state: boolean = new URLSearchParams(search).get('disable_filter') === "true" ? true : false;
    const [disabledFilter, setDisabledFilter] = useState<boolean>(disabled_filter_state);
    const {
        handleApplyAdvancedSearch,
        handleManualSearch,
        getSearchHeaders, PartnerConfigState,
        preMetaDataHandler
    } = usePostureAdvanceSearch();
    const { PostureSearchState, PostureChartState } = usePostureStateContext();
    const { setTags, tags } = PostureSearchState;
    const { handleLensSearchFn, handleSearchFn } = useLensSearch(searchInput, setTags, setSearchInput, setTagFlag);
    const [isClearAll, setIsClearAll] = useState(false);
    const [failedDir, setFailedDir] = useState<AccessesObj>();
    const [zoomLevel, setZoomLevel] = useState('hourly');
    const updatedExtremesRef = useRef({ min: null, max: null });

    const {
        selectAll,
        onSetSelectRow,
        getSelectRow,
        onSelectAllClick,
        selectAllIndeterminate,
        setArchivalData,
        archivalData,
        setOpenIssuesCount,
        onArchive,
        selectCount,
        isIncidentsOpen,
        onCurrentPageClick,
        resetSelectionState,
        currentPage,
        onArchiveEstimate
    } = usePostureArchival('Access');
    const {
        responseData, setResponseData,
        showAdvanceSearch, setShowAdvanceSearch,
        setIdWidget,
        setRuleWidget,
        setRuleRootIdentity,
        widgetData, setWidgetData,
        totalCount, setTotalCount
    } = PostureChartState;
    const { PostureSummary } = PostureService();
    const { authDetails } = useAuthDetails()!;
    const { isValidResultCountThreshold, matchingPlaybookFound } = useValidatePostureIssuesSearchResult();
    const [selectedField, setSelectedField] = useState(null);
    const [showAdditionalResult, setShowAdditionalResult] = useState(false);
    const [additionalFields, setAdditionalFields] = useState([]);
    const [additionalResultHighlightClass, setAdditionalResultHighlightClass] = useState('');
    const { isPresetPeriodOptionSelected } = useTimeBasedSearchActions();
    const archiveBtnRef = useRef<IPostureActionButtonsRef>(null);
    const MoreActionsOptions: IPlaybookActions<Array<any>> = [
        {
            actionCallback: () => {
                if (archiveBtnRef?.current?.onArchiveBtnClick)
                    archiveBtnRef?.current?.onArchiveBtnClick()
            },
            actionId: 'mark-archive',
            actionLabel: 'Archive',
            isDisabled: selectCount == 0,
            isApplicable: authDetails?.permissions?.Posture?.manual_archive == 'readwrite'
        }
    ];
    const searchParams = new URLSearchParams(location.search);
    const hasReportType = searchParams.has('reportType') && searchParams.get('reportType')?.trim() !== '' ||
        searchParams.has('ruleType') && searchParams.get('ruleType')?.trim() !== '';


    const highlightSortDir = () => {
        if (query.order_by === 'asc') {
            setCurrentSort(' tablesort_up tablesort_up_selected ');
        } else if (query.order_by === 'desc') {
            setCurrentSort(' tablesort_down tablesort_down_selected ');
        }
    };


    useEffect(() => {
        setShowAdditionalResult(true)
    }, [query.q]);

    useEffect(() => {
        let tempTags: string[] = [];
        if (param.q) {
            // tempTags = [...param.q];
            tempTags = q.split('+').map((i) => decodeURIComponent(i));;
        }
        if (param.rule_name && !tempTags.includes(param.rule_name)) {
            tempTags.push(param.rule_name);
        }
        if (param.risk && !tempTags.includes(param.risk?.toString())) {
            tempTags.push(param.risk?.toString());
        }
        if (param.d_protocol && !tempTags.includes(param.d_protocol)) {
            tempTags.push(param.d_protocol);
        }
        if (param.d_name && !tempTags.includes(param.d_name)) {
            tempTags.push(param.d_name);
        }
        if (param.hash && !tempTags.includes(param.hash)) {
            tempTags.push(param.hash);
        }
        if (
            param.s_time &&
            param.e_time &&
            !tempTags.includes(param.s_time?.toString()) &&
            !tempTags.includes(param.e_time?.toString())
        ) {
            tempTags.push(param.s_time?.toString(), param.e_time?.toString());
        }

        if (tempTags.length > 0) {
            setTags(tempTags);
            setTagFlag(false);
        }

        highlightSortDir();
        const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();
        if (
            !history.location.pathname.includes('matchrule') &&
            !history.location.pathname.includes('flow')
        ) {

            /* fetchIdentities(cancelTokenSource.token); */
        }
        return () => {
            cancelTokenSource.cancel('Operation canceled due to component unmounting.');
        };

    }, [query]);

    useEffect(() => {
        fetchWidget();
    }, []);

    const fetchIdentities = async (queryParams: Record<string, any>, gridParams: IServerSideGetRowsParams, signal: AbortSignal) => {
        setTableFetchError('No records found.');
        const searchHeaders = await getSearchHeaders(queryParams);
        setLoading(true);
        Api.get('/accesses', {
            params: queryParams,
            paramsSerializer: (params) =>
                qs.stringify(params, { arrayFormat: 'repeat' }),
            signal,
            ...searchHeaders
        })
            .then((res) => {
                setLoading(false);
                if (res.status === 200) {
                    setTotalCount(res.data.total);
                    setResponseData(res.data);
                    setArchivalData(res.data);
                    setOpenIssuesCount(res.data.total);
                    setLastUpdate(moment().format('MMM DD YYYY, hh:mm A'));
                    if (currentPage && selectCount > 0 && selectAllIndeterminate) {
                        resetSelectionState()
                    }
                    if (gridParams) {
                        gridParams.success({
                            rowData: res.data.result,
                            rowCount: res.data.total
                        });
                        if (gridRef?.current?.api) {
                            gridRef.current.api.hideOverlay();
                            if (res.data.total === 0) {
                                gridRef.current.api.showNoRowsOverlay();
                            }
                        }
                        setQuery({ ...query, ...queryParams })
                    }
                }
            })
            .catch((err) => {
                console.log(err);
                if (err.code === 'ERR_CANCELED') {
                    return
                }
                setLoading(false);
                setResponseData({ result: 'error' } as ServerResponse);
                addToast('Error while fetching data.', {
                    appearance: 'error',
                    autoDismiss: true
                });
                setTableFetchError(`An error occurred while processing your request. Please check your input and try again. If the error persists, please reach out to ${PartnerConfigState?.PartnerShortProduct} support.`);
            });
    };

    const reloadIdentities = () => {
        reloadRef.current.classList.add('reload_spin');
        param.hard_refresh = true;
        setQuery(param);

        /* fetchIdentities(); */
        outDated();
        fetchWidget();
        setTimeout(() => {
            if (reloadRef.current.classList.contains('reload_spin')) {
                reloadRef.current.classList.remove('reload_spin');
            }
        }, 750);
    };

    const {
        q: q,
        page: page_number,
        rpp: record_per_page,
        order_by: order,
        sort_by: sort,
        rule_name: rule_name,
        d_protocol: d_protocol,
        risk: risk,
        hash: hash,
        d_name: d_name,
        s_time: s_time,
        e_time: e_time,
        hard_refresh: hard_refresh
    } = query;

    const [ruleMatch, setRuleMatch] = useState<AccessesObj | undefined>();
    const [matchRuleData, setMatchRuleData] = useState<AccessesObj | undefined>();
    const [prevUrl, setPrevUrl] = useState<string>(
        window.location.pathname + window.location.search
    );
    const [lastUpdate, setLastUpdate] = useState<string>();

    const [loading, setLoading] = useState(false);
    const [showUnsuccessAuth, setShowUnsuccessAuth] = useState(false);
    const [openFlow, setOpenFlow] = useState<boolean>(false);
    const [flowsTrendResponseData, setFlowsTrendResponseData] =
        useState<any>(undefined);
    const [flowsChartDetails, setFlowsChartDetails] = useState<any>({
        chartTitle: ''
    });

    const { appliedFilterTimestamp } = useTimeBasedSearchActions();

    let history = useHistory();
    const { addToast } = useToasts();
    const reloadRef = useRef() as React.MutableRefObject<HTMLDivElement>;

    let param: IssueParams = {
        q: q,
        page: page_number,
        rpp: record_per_page,
        order_by: order,
        sort_by: sort,
        rule_name: rule_name,
        d_protocol: d_protocol,
        risk: risk,
        hash: hash,
        d_name: d_name,
        s_time: s_time,
        e_time: e_time,
        hard_refresh: hard_refresh
    };
    const [isTimeBasedSearchApplied, setIsTimeBasedSearchApplied] = useState(false);

    useEffect(() => {
        const directory = tags.find((item: string) =>
            item.includes('num_of_auth_failed_flow')
        );
        if (directory) {
            setShowUnsuccessAuth(true);
        } else {
            setShowUnsuccessAuth(false);
        }
    }, [tags]);

    const LoadSorting = (field: string) => {
        if (param.sort_by === field) {
            param.order_by = param.order_by === 'asc' ? 'desc' : 'asc';
            setQuery(param);
        } else {
            param.sort_by = field;
            param.order_by = 'desc';
            setQuery(param);
        }
    };




    const getChartTitle = (identityName: string, assetName: string, startDate: any = null, endDate: any = null) => {
        let timeUnit = "Hourly";

        if (startDate) {
            if (endDate) {
                const totalDurationHours = moment(endDate).diff(moment(startDate), 'hours');

                if (totalDurationHours < 12) {
                    timeUnit = "Minute-wise";
                } else if (totalDurationHours < 48) {
                    timeUnit = "10-Minute-wise";
                }
                // else timeUnit remains "Hourly"
            }

            return (
                <span
                    title={`${timeUnit} flows trend (${getTimeDiffString(startDate, endDate)}) for Access: ${identityName} → ${assetName}`}
                >{`${timeUnit} flows trend (${getTimeDiffString(startDate, endDate)})  for Access: ${trimAfterSecondWord(
                    identityName + ' → ' + assetName,
                    35
                )}`}</span>
            );
        } else {
            return (
                <span
                    title={`Hourly flows trend for Access: ${identityName} → ${assetName}`}
                >{`Hourly flows trend for Access: ${trimAfterSecondWord(
                    identityName + ' → ' + assetName,
                    35
                )}`}</span>
            );
        }
    };

    // const handleFlowClick = (e: React.MouseEvent<HTMLDivElement>) => {
    //     setOpen(true);
    //     let issueId = e.currentTarget.id?.toString();
    //     let flows = responseData?.result.find((item: AccessesObj) => item._id === issueId);
    //     setIssueFlow(flows);
    //     history.push("/accesses/flow")
    // }




    function getSearchFieldsForDate(min, max) {


        let startDate = moment(min).unix();
        let endDate = moment(max);

        if (endDate.isAfter(moment())) {
            endDate = moment().unix();
        } else {
            endDate = endDate instanceof moment ? endDate.unix() : moment(endDate).unix();
        }

        // Calculate the time difference in hours
        let diffInHours = (endDate - startDate) / 3600;

        // Determine the interval based on the difference
        let interval;
        if (diffInHours < 12) {
            interval = '1 MINUTE';
            setZoomLevel('1-minute');
        } else if (diffInHours < 48) {
            interval = '10 MINUTE';
            setZoomLevel('10-minute');
        } else {
            interval = '1 HOUR'; // Replace 'default_interval' with your desired default interval.
            setZoomLevel('hourly');
        }

        let searchFields = {
            "any_activity_time": {
                "value": [startDate, endDate],
                "type": "between"
            },
            "interval": interval
        };



        // Convert to JSON and encode
        return encodeURIComponent(JSON.stringify(searchFields));



    }


    const cancelTokenSourceRef = useRef<any>(null);

    const handleRangeChange = (min: any, max: any) => {

        const searchHeaders = {
            headers: {
                search_fields: getSearchFieldsForDate(min, max)
            }
        };

        updatedExtremesRef.current = { min, max };
        const currentTime = +new Date();
        const data = JSON.parse(sessionStorage.getItem('flowDataAccess'));


        const encodedIdentityName = encodeURIComponent(data.identityName);
        const encodedAssetName = encodeURIComponent(data.assetName);

        const dirParams = `&directory_name=${encodeURIComponent(data.directory_name)}&is_directory_public=${encodeURIComponent(data.is_directory_public)}`;
        const flowTrendURL = `/assets/flowcounts?search_type=access&search_value1=${encodedIdentityName}&search_value2=${encodedAssetName}${dirParams}&asset_type=${encodeURIComponent(data.asset_type)}&identity_type=${encodeURIComponent(data.identity_type)}`;




        if (cancelTokenSourceRef.current) {
            cancelTokenSourceRef.current.cancel('Operation canceled due to new request.');
        }

        // Create a new cancel token for the new request
        cancelTokenSourceRef.current = axios.CancelToken.source();

        try {

            Api.get(flowTrendURL, searchHeaders)
                .then((res: any) => {
                    if (!res?.data?.length || res?.data?.length <= 0) {
                        setFlowsTrendResponseData({
                            flows: [],
                            expiry_time: currentTime + 600000
                        });

                        // Chart header and color
                        // const chartTitle = getChartTitle(identityName);
                        // setFlowsChartDetails({
                        //     chartTitle, itemData: {
                        //         queryData: `identity_name:${encodedIdentityName}`
                        //     }
                        // });
                        return;
                    }

                    let result = JSON.parse(JSON.stringify(res?.data)) || [];
                    result.sort((a: any, b: any) =>
                        a?.time < b?.time ? -1 : 1
                    );
                    const cachedFlows = {
                        flows: result,
                        encodedIdentityName,
                        risk,
                        expiry_time: currentTime + 600000
                    };
                    setFlowsTrendResponseData(cachedFlows);


                    const chartTitle = appliedFilterTimestamp()?.length > 0 ? getChartTitle(
                        data?.identityName,
                        data.assetName,
                        min,
                        max,

                    ) : max ? getChartTitle(
                        data?.identityName,
                        data?.assetName,
                        min,
                        max

                    ) : getChartTitle(
                        data?.identityName,
                        data?.assetName,
                        min

                    );
                    setFlowsChartDetails({
                        chartTitle, itemData: {
                            queryData: `identity_name:${encodedIdentityName}`
                        }
                    });


                })
                .catch((er) => {
                    console.log(er);
                });
        } catch (error) {
            if (axios.isCancel(error)) {
                console.log('Previous request canceled.');
            } else {
                console.log(error);
            }
        }
    }

    useEffect(() => {
        if (!openFlow) {
            setFlowsTrendResponseData(undefined);
        }
    }, [openFlow]);

    const handleIssueFilterClick = (e: string, k, v, item) => {
        const prevState = history?.location?.state as any;
        const prevUrl = history.location.pathname;
        const prevSearch = history.location.search;
        let idx = e.toString();
        let hostDetails = responseData?.result.find(
            (item: AccessesObj) => item._id === idx
        );
        const isDirectory = hostDetails.is_flow_for_directory;
        let currHostDetails = hostDetails.asset_host_detail;
        const s_Type = hostDetails?.identity_type.replace('Service/Application', 'Application')
            .replace('Service/Service Account', 'Service');
        const d_type = hostDetails?.asset_type.replace('Service/Application', 'Application')
            .replace('Service/Service Account', 'Service');
        let assetsRef = '';
        currHostDetails.map((item: any, index: any) => {
            if (index < 100) assetsRef += item.ip + '-' + item.port + ',';
        });

        let dir_host = hostDetails?.directory_hostname;

        if (dir_host.includes(',')) {
            dir_host = `"${dir_host}"`;
        }

        let host_id = hostDetails.identity

        if (host_id.includes(',')) {
            host_id = `"${host_id}"`;
        }

        PostureSearchState.setIsAdSearchApplied(false);

        const timeSearchParam = getTimeBasedSearchQueryParam(PostureSearchTemplate, tags);

        if (timeSearchParam === "") {
            if (isDirectory) {
                if (dir_host) {
                    history.push(
                        '/issues?disable_filter=true&hard_refresh=true&order_by=desc&sort_by=gen_timestamp&page=1&q=s_name:' +
                        encodeURIComponent(hostDetails.identity) +
                        '%2Bdir_protocol:' +
                        encodeURIComponent(hostDetails.asset_protocol) +
                        '%2Bdir_hostname:' +
                        encodeURIComponent(dir_host) +
                        `%2Brule_name^Shadow External Access` +
                        '%2Bstatus:Open%2Bs_type:' +
                        s_Type,
                        {
                            prevWidget: prevState?.breadcrumbId,
                            breadcrumbId: 'AccessIncidents',
                            prevUrl,
                            prevSearch
                        }
                    );
                } else {
                    history.push(
                        '/issues?disable_filter=true&hard_refresh=true&order_by=desc&sort_by=gen_timestamp&page=1&q=s_name:' +
                        encodeURIComponent(hostDetails.identity) +
                        '%2Bdir_protocol:' +
                        encodeURIComponent(hostDetails.asset_protocol) +
                        '%2Bdir_hostname:' +
                        encodeURIComponent(hostDetails?.asset) +
                        `%2Brule_name^Shadow External Access` +
                        '%2Bstatus:Open%2Bs_type:' +
                        s_Type,
                        {
                            prevWidget: prevState?.breadcrumbId,
                            breadcrumbId: 'AccessIncidents',
                            prevUrl,
                            prevSearch
                        }
                    );
                }
            } else {
                history.push(
                    '/issues?disable_filter=true&hard_refresh=true&order_by=desc&sort_by=gen_timestamp&page=1&q=s_name:' +
                    encodeURIComponent(hostDetails.identity) +
                    '%2Bd_type:' +
                    d_type +
                    '%2Bd_name:' +
                    encodeURIComponent(hostDetails?.asset) +
                    `%2Brule_name^Shadow External Access` +
                    '%2Bstatus:Open%2Bs_type:' +
                    s_Type,
                    {
                        prevWidget: prevState?.breadcrumbId,
                        breadcrumbId: 'AccessIncidents',
                        prevUrl,
                        prevSearch
                    }
                );
            }

        } else {
            if (isDirectory) {
                if (dir_host) {
                    history.push(
                        '/issues?disable_filter=true&hard_refresh=true&order_by=desc&sort_by=gen_timestamp&page=1&q=s_name:' +
                        encodeURIComponent(hostDetails.identity) +
                        '%2Bdir_protocol:' +
                        encodeURIComponent(hostDetails.asset_protocol) +
                        '%2Bdir_hostname:' +
                        encodeURIComponent(dir_host) +
                        `%2Brule_name^Shadow External Access` +
                        '%2Bstatus:Open%2Bs_type:' +
                        s_Type + '%2B' + encodeURIComponent(timeSearchParam),
                        {
                            prevWidget: prevState?.breadcrumbId,
                            breadcrumbId: 'AccessIncidents',
                            prevUrl,
                            prevSearch
                        }
                    );
                } else {
                    history.push(
                        '/issues?disable_filter=true&hard_refresh=true&order_by=desc&sort_by=gen_timestamp&page=1&q=s_name:' +
                        encodeURIComponent(hostDetails.identity) +
                        '%2Bdir_protocol:' +
                        encodeURIComponent(hostDetails.asset_protocol) +
                        '%2Bdir_hostname:' +
                        encodeURIComponent(hostDetails?.asset) +
                        `%2Brule_name^Shadow External Access` +
                        '%2Bstatus:Open%2Bs_type:' +
                        s_Type + '%2B' + encodeURIComponent(timeSearchParam),
                        {
                            prevWidget: prevState?.breadcrumbId,
                            breadcrumbId: 'AccessIncidents',
                            prevUrl,
                            prevSearch
                        }
                    );
                }
            } else {
                history.push(
                    '/issues?disable_filter=true&hard_refresh=true&order_by=desc&sort_by=gen_timestamp&page=1&q=s_name:' +
                    encodeURIComponent(hostDetails.identity) +
                    '%2Bd_type:' +
                    d_type +
                    '%2Bd_name:' +
                    encodeURIComponent(hostDetails?.asset) +
                    `%2Brule_name^Shadow External Access` +
                    '%2Bstatus:Open%2Bs_type:' +
                    s_Type + '%2B' + encodeURIComponent(timeSearchParam),
                    {
                        prevWidget: prevState?.breadcrumbId,
                        breadcrumbId: 'AccessIncidents',
                        prevUrl,
                        prevSearch
                    }
                );
            }
        }
    };

    useEffect(() => {
        ReactTooltip.rebuild();
    });

    const getExceptionData = (item: AccessesObj) => {
        return [
            `d_name:${item.asset}`,
            `dst_hostnames:` +
            item.asset_host_detail
                ?.map((item: any) => item.hostname)
                .join(',') || '',
            `dst_ips:` +
            item.asset_host_detail?.map((item: any) => item.ip).join(',') ||
            '',
            // `src_ips:` + item.identity_host_detail?.map((item: any) => item.ip).join(',') || '',
            // `src_hostnames:` + item.identity_host_detail?.map((item: any) => item.hostname).join(',') || '',
            `s_name:${item.identity}`,
            `rule_name:` + getRules(item)
        ];
    };

    const getIncCloseQuery = (item: AccessesObj) => {
        return (
            `{"is_external":"false","s_name":"` +
            item.identity +
            `","d_protocol":"` +
            item.asset_protocol +
            `","d_name":"` +
            item?.asset +
            `","status":"Open"}`
        );
    };

    const getRules = (item: AccessesObj) => {
        let rule = '';
        Object.values(item.rules).map((i: RulesDetailObj) => {
            if (i.issue_count > 0) {
                rule += i.name + ',';
            }
        });
        return rule.substring(0, rule.length - 1);
    };

    const loadBasePostureActionProps = (item: AccessesObj) => {
        item.host_detail = [
            ...(item?.asset_host_detail?.length ? item?.asset_host_detail : []),
            ...(item?.identity_host_detail?.length
                ? item?.identity_host_detail
                : [])
        ] as any;
        return {
            actionData: { ...item, _id: item.asset },
            menuItems:
                item.issue_count > 0
                    ? AccessesActionMenu
                    : AccessesActionMenu.filter(
                        (item: PostureMenuItem) =>
                            item.action == PostureMenuItemAction.MARK_KNOWN
                    ),
            getContent: () => [
                `d_name:${item.asset}`,
                `s_name:${item.identity}`
            ],
            exceptionData: getExceptionData(item),
            classes: 'posture-column action_button',
            incContent: () => [
                `inc_count:${item.issue_count}`,
                `s_name:${item.identity}`,
                `d_name:${item.asset}`
            ],
            closeIncQuery: getIncCloseQuery(item),
            issueCount: item.issue_count,
            reloadList: fetchIdentities,
            markKnownOptions: {
                options: AccessColumnMarkKnownOptions
            },
            keyName: `access-${item._id}`,
            showId: rule_name
        };
    };

    const handleMouseEnterHost = (index: number, hostname: string, e: any) => {
        const ele = e.target.parentElement.parentElement.getElementsByClassName(
            `host-name-list-${index}-${hostname}`
        )[0];
        if (ele && ele.classList) {
            ele.classList.add('show-block');
            ele.classList.remove('hidden-block');
        }
    };

    const handleMounseLeaveHost = (index: number, hostname: string, e: any) => {
        const ele = e.target.getElementsByClassName(
            `host-name-list-${index}-${hostname}`
        )[0];
        if (ele && ele.classList) {
            ele.classList.add('hidden-block');
            ele.classList.remove('show-block');
        }
    };

    const handleFilterWidget = (widgetState: any) => {
        if (filterData) {
            setOpenPanelFilterWidgets(widgetState);
        } else {
            addToast(
                'Unable to apply filters, please retry in few minutes or contact administrator.',
                { appearance: 'error' }
            );
        }
    };

    const handleFilterDisabled = (widgetState: any) => {
        setDisabledFilter(widgetState);
        // reloadIssuesDisabled(widgetState);
    };

    const onCloseAdvanceSearch = useCallback(() => {
        setShowAdvanceSearch(false);
    }, []);

    const applySearchHandler = useCallback(
        (data: any, clearAll: boolean = false, deletedKeys = []) => {
            let tempFilterObj = getSearchFilterObjectFromTags(PostureSearchTemplate, tags);
            let filterObj = {};
            for (let i in tempFilterObj) {
                if (deletedKeys.indexOf(i) == -1)
                    filterObj[i] = tempFilterObj[i];
            }

            filterObj = { ...filterObj, ...data }

            handleApplyAdvancedSearch(
                filterObj,
                clearAll,
                setIsClearAll,
                setTags,
                setTagFlag,
                ENTITIES.ACCESSES.pageType
            );
            resetSelectionState();
        },
        [tags, q]
    );

    const applyTimeBasedSearchHandler = ((data, clear) => {
        let filterObj = getSearchFilterObjectFromTags(PostureSearchTemplate, tags);
        if (clear) {
            if (filterObj.hasOwnProperty(TIME_BASED_SEARCH_FIELD)) {
                delete filterObj[TIME_BASED_SEARCH_FIELD];
                clear = false;
            }
            clear = Object.keys(filterObj).length === 0 ? true : false;
        } else {
            filterObj = { ...filterObj, ...data }
        }
        handleApplyAdvancedSearch(
            filterObj,
            clear,
            setIsClearAll,
            setTags,
            setTagFlag,
            ENTITIES.IDENTITY.pageType
        );
    })

    const onAdvanceSearchClick = () => {
        setShowAdvanceSearch(true);
    }

    useEffect(() => {
        handleManualSearch(tags, ENTITIES.ACCESSES.pageType)
    }, [tags])



    useEffect(() => {
        if (additionalResultHighlightClass !== "" && showAdditionalResult) {
            setTimeout(() => {
                setAdditionalResultHighlightClass("");
            }, 15000)
        }
    }, [additionalResultHighlightClass])

    useEffect(() => {
        let filterObj = getSearchFilterObjectFromTags(PostureSearchTemplate, tags);
        if (filterObj[TIME_BASED_SEARCH_FIELD]) {
            setIsTimeBasedSearchApplied(true);
        } else {
            setIsTimeBasedSearchApplied(false);
        }
    }, [tags])







    const clickIcon = (item) => {
        setShowMatchRule(true);
        setMatchRuleData(item);
    }


    const handleSearch = (e: React.KeyboardEvent<HTMLInputElement>) => {
        handleSearchFn(e, tags)
    }

    const handleLensSearch = () => {
        handleLensSearchFn(tags);
    }


    useEffect(() => {
        if (tagFlag && tags.length >= 0 || isClearAll) {
            param.page = 1;
            param.q = tags.join("+");
            param.d_name = undefined;
            param.risk = undefined;
            param.d_protocol = undefined;
            param.hash = undefined;
            param.e_time = undefined;
            param.s_time = undefined;
            param.rule_name = undefined;
            if (param.q === '') {
                param.reportType = undefined;
                param.ruleType = undefined;
            }
            setQuery(param);
            setIsClearAll(false); // reset clearall status.
            setTagFlag(false);
        }
    }, [tags, tagFlag])

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchInput(e.target.value)
    }

    const deleteTag = (index: number) => {
        const anyActivityTimeTag = tags.filter(item => item.indexOf(TIME_BASED_SEARCH_FIELD) > -1);
        let newTags = getTagsForSearchInput(tags).filter((tag, i) => i !== index)
        if (anyActivityTimeTag && anyActivityTimeTag.length > 0) {
            newTags = [...newTags, ...anyActivityTimeTag];
        }

        setTags(newTags);

        setTagFlag(true);
        PostureSearchState.setIsAdSearchApplied(false);
    }


    const compareElements = (a: any, b: any) => {
        if (a.element_count > b.element_count) {
            return -1;
        }
        if (a.element_count < b.element_count) {
            return 1;
        }
        return 0;
    }

    const fetchWidget = () => {
        setLoading(true);
        const queryParams = {
            ...(hard_refresh_state && { 'hard_refresh': 'true' })
        }

        PostureSummary.GET(queryParams, (res: IApiResponse) => {
            setLoading(false);
            if (res.status === 200) {
                setWidgetData(undefined);
                setTimeout(() => setWidgetData(res.data))
                res.data.access_summary.sort(compareElements)
                let n = 5
                if (res.data.access_summary.length < n) {
                    n = res.data.access_summary.length
                }
                let slicedArray = res.data.access_summary.slice(0, n);
                if (res.data.access_summary.length > n) {
                    let extraArray = res.data.access_summary.slice(n, res.data.access_summary.length);
                    let extra = {
                        element_count: 0,
                        element_count_internal: 0,
                        element_count_with_issues: 0,
                        element_count_with_issues_internal: 0,
                        type: "Other Accesses"
                    }
                    // extra.element_count = 0
                    // extra.element_count_internal = 0
                    // extra.element_count_with_issues = 0
                    // extra.element_count_with_issues_internal = 0
                    // extra.type = "Other Accesses"
                    let element_count = 0,
                        element_count_internal = 0,
                        element_count_with_issues = 0,
                        element_count_with_issues_internal = 0;
                    extraArray.map((item: any) => {
                        element_count += item.element_count
                        element_count_internal += item.element_count_internal
                        element_count_with_issues += item.element_count_with_issues
                        element_count_with_issues_internal += item.element_count_with_issues_internal
                    })

                    extra.element_count = element_count
                    extra.element_count_internal = element_count_internal
                    extra.element_count_with_issues = element_count_with_issues
                    extra.element_count_with_issues_internal = element_count_with_issues_internal
                    slicedArray.push(extra)
                }
                //Set ID widget 
                setIdWidget(slicedArray)
                setRuleWidget(res.data.access_protocol_summary.protocols)

                const rulesRoot: any = [];
                //SetRuleRootCount Data
                setRuleRootIdentity(rulesRoot);
            }
        }, (err: any) => {
            console.log(err);
            setLoading(false);
            setResponseData(undefined);
        })
    }


    //Download CSV
    const headers = [
        { label: "Identity Name", key: "identity" },
        { label: "Identity Type", key: "identity_type" },
        { label: "Host 1", key: "identity_host_detail[0].hostname" },
        { label: "Host 2", key: "identity_host_detail[1].hostname" },
        { label: "Host 3", key: "identity_host_detail[2].hostname" },
        { label: "Host 4", key: "identity_host_detail[3].hostname" },
        { label: "Host 5", key: "identity_host_detail[4].hostname" },
        { label: "Asset Name", key: "asset" },
        { label: "Asset Directory Status", key: "directory_flow_status" },
        { label: "Asset Type", key: "asset_type" },
        { label: "Host 1", key: "asset_host_detail[0].hostname" },
        { label: "Host 2", key: "asset_host_detail[1].hostname" },
        { label: "Host 3", key: "asset_host_detail[2].hostname" },
        { label: "Host 4", key: "asset_host_detail[3].hostname" },
        { label: "Host 5", key: "asset_host_detail[4].hostname" },
        { label: "IP:Port 1", key: "asset_host_detail[0].ip_port" },
        { label: "IP:Port 2", key: "asset_host_detail[1].ip_port" },
        { label: "IP:Port 3", key: "asset_host_detail[2].ip_port" },
        { label: "IP:Port 4", key: "asset_host_detail[3].ip_port" },
        { label: "IP:Port 5", key: "asset_host_detail[4].ip_port" },
        { label: "Protocol", key: "asset_protocol" },
        { label: "Access Issued - Unknown Access", key: "rules.unknown_access.issue_count" },
        { label: "# Flows", key: "flow_count" },
        { label: 'Directory Fail Count', key: 'directory_flow_fail_count' },
        { label: "Unsuccessful Authentication Percent", key: "auth_failed_percent" },
        { label: "Overall # Incidents", key: "issue_count" },
        { label: "Latest Activity", key: "latest_time" },
        { label: "Score", key: "score" }
    ];

    const [downData, setDownData] = useState<string | Data>([]);
    const [totalDownloaded, setTotalDownloaded] = useState<number | undefined>(0)
    const [loadingCSV, setLoadingCSV] = useState({ loading: false, setData: false })
    const currDownData: any = [];
    const csvLinkEl = useRef<any>();


    const getUserList = async (selectedPage?: any) => {
        param.page = selectedPage === 0 ? 1 : selectedPage + 1;
        param.rpp = 100;
        const searchHeaders = await getSearchHeaders(param)
        return Api.get("/accesses", {
            params: { ...param, is_export_csv: true },
            paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
            ...searchHeaders
        })
            .then(res => {
                res.data = validateLocalIps(res.data, 'identity_host_detail');
                res.data = validateLocalIps(res.data, 'asset_host_detail');
                res.data.result.map((item: any) => { currDownData.push(item) });
                //console.log(currDownData);
                return currDownData;
            }).catch((error: any) => {
                setLoadingCSV({ loading: false, setData: false })
                if (error.response.status === 500 || error.response.status === 524) {
                    addToast("Sorry, something went wrong there, try again.", {
                        appearance: 'error',
                        autoDismiss: true,
                    })
                } else if (error.response.status === 404) {
                    addToast("We are not able to find associated email, please check and try again.", {
                        appearance: 'error',
                        autoDismiss: true,
                    })
                }
            });
    }

    const downloadCSV = async () => {
        setTotalDownloaded(0);
        setLoadingCSV({ loading: true, setData: false })
        let i = 0;
        do {
            const downData = await getUserList(i);
            setTotalDownloaded(i * 100);
            if (i > Math.floor(totalCount! / 100)) {
                setDownData(downData);
                setTimeout(() => {
                    setLoadingCSV({ loading: false, setData: true })
                    if (csvLinkEl && csvLinkEl?.current && csvLinkEl?.current?.link) {
                        csvLinkEl?.current?.link.click();
                    }
                });
            }
            i = i + 1;
        } while (i <= Math.floor(totalCount! / 100) + 1)

    }


    useEffect(() => {
        if (PostureSearchState.isAdvancedSearchOpen) {
            setShowAdvanceSearch(true)
        }
    }, [])

    const [showOudatedData, setShowOutdatedData] = useState(false);

    const outDated = () => {
        let clearOutdatedDataInterval = null;
        setShowOutdatedData(false);

        let tempTags = getTagsForTimeBasedSearch(tags);

        tempTags = Object.keys(AVAILABLE_PRESETS).filter(item => {
            if (tempTags && tempTags.length > 0)
                return item === tempTags[0][1]
            return false;
        })
        if (tempTags && tempTags.length > 0) {
            if (clearOutdatedDataInterval)
                clearInterval(clearOutdatedDataInterval);
            clearOutdatedDataInterval = setTimeout(() => {
                if (isPresetPeriodOptionSelected()) {
                    setShowOutdatedData(true);
                }
            }, 30000);
        }
        return () => {
            if (clearOutdatedDataInterval)
                clearInterval(clearOutdatedDataInterval);
        }
    }
    useEffect(() => {
        outDated();
    }, [tags])

    useEffect(() => {
        PostureSearchState.setIsAdvancedSearchOpen(showAdvanceSearch);
    }, [showAdvanceSearch])

    const matchingPlaybookFoundFn = () => {
        const newParams = { ...query };
        newParams.entity_type = ENTITIES.ACCESSES.id;
        setQuery(newParams);
        matchingPlaybookFound(newParams, getSearchHeaders)
    }




    const gridRef = useRef(null);
    const containerRef = useRef(null)
    const [headerCheckboxConfig, setHeaderCheckboxConfig] = useState({});

    useEffect(() => {
        setHeaderCheckboxConfig({
            indeterminate: selectAllIndeterminate,
            onClick: onCurrentPageClick,
            checked: selectAll || false,
        })
    }, [responseData, selectAll, selectAllIndeterminate, selectCount, archivalData])


    const getCheckboxParams = (item: AccessesObj) => {
        return {
            checked: getSelectRow(item),
        }
    }


    const [gridCols, setGridCols] = useState<Array<ColDef> | null>(null)

    useEffect(() => {
        setGridCols(updateColDefs())
    }, [responseData])

    const handleAddtionalResultColumn = (val) => {
        setShowAdditionalResult(val);
        if (additionalResultHighlightClass === "")
            setAdditionalResultHighlightClass("glowing-animation");
    }

    const { getAdditionalFields } = useAdditionalResults({
        options: tags, filterTemplate: PostureSearchTemplate,
        notSupportedKeys: notSupportedKeys,
        pageType: ENTITIES.ACCESSES.pageType,
        columnDefs: gridCols, setColumnDefs: setGridCols, handleAddtionalResultColumn
    })


    const updateColDefs = (): ColDef[] => {
        return [
            {
                field: "identity",
                headerName: "Identity",
                suppressMenu: true,
                menuTabs: [],
                pinned: "left",
                children: [
                    {
                        field: "identity",
                        /* width: getResolutionMatchFor1920(showAdditionalResult ? 440 : 460, 300), */
                        /* minWidth: getResolutionMatchFor1920(400, 300), */
                        minWidth: getResolutionMatchFor1920(420, 270),
                        maxWidth: getResolutionMatchFor1920(460, 270),
                        headerName: "Name",
                        suppressMenu: true,
                        menuTabs: [],
                        cellClass: 'pad-r-l-5 access-dot',
                        cellRendererParams: {
                            handleMouseEnterHost,
                            handleMounseLeaveHost
                        },
                        cellRenderer: AccessIdCell
                    },
                    {
                        field: "identity_type",
                        width: getResolutionMatchFor1920(100, 100),
                        maxWidth: getResolutionMatchFor1920(100, 100),
                        headerName: "Type",
                        suppressMenu: true,
                        menuTabs: [],
                        cellClass: 'pad-r-l-5 access-dot',
                        cellRenderer: AccessIdTypeTypeCell
                    }
                ]
            },
            {
                field: "asset",
                headerName: "Asset",
                suppressMenu: true,
                menuTabs: [],
                pinned: "left",
                children: [
                    {
                        field: "asset",
                        width: getResolutionMatchFor1920(450, 270),
                        minWidth: getResolutionMatchFor1920(450, 270),
                        headerName: "Name",
                        suppressMenu: true,
                        cellClass: 'pad-r-l-5 access-dot',
                        menuTabs: [],
                        cellRendererParams: {
                            handleMouseEnterHost,
                            handleMounseLeaveHost
                        },
                        cellRenderer: AccessAssetCell
                    },
                    {
                        field: "is_directory_present",
                        width: getResolutionMatchFor1920(110, 100),
                        minWidth: getResolutionMatchFor1920(110, 100),
                        maxWidth: getResolutionMatchFor1920(110, 100),
                        cellClass: 'access-dot',
                        headerName: "Identity System",
                        wrapHeaderText: true,
                        suppressMenu: true,
                        menuTabs: [],
                        cellRenderer: AccessDirectoryCell,
                        sortable: true
                    },
                    {
                        field: "asset_type",
                        width: getResolutionMatchFor1920(80, 80),
                        maxWidth: getResolutionMatchFor1920(80, 80),
                        cellClass: 'access-dot',
                        headerName: "Type",
                        suppressMenu: true,
                        menuTabs: [],
                        cellRenderer: AccessAssetTypeCell,
                        cellRendererParams: {
                            loadBasePostureActionProps, isTimeBasedSearchApplied
                        }
                    }
                ]
            },
            {

                field: "asset_protocol",
                headerName: "Protocol",
                width: getResolutionMatchFor1920(100, 100),
                maxWidth: getResolutionMatchFor1920(100, 100),
                suppressMenu: true,
                menuTabs: [],
                wrapHeaderText: true,
                cellRenderer: ({ data }) => {
                    const item = data;
                    return <div className="ellipsis_name_accesses" title={item.asset_protocol} id={item._id.toString()}>
                        {item.asset_protocol}
                    </div>
                }
            },
            {

                field: "flow_count",
                headerName: "Overall Number of Flows",
                width: getResolutionMatchFor1920(130, 60),
                maxWidth: getResolutionMatchFor1920(130, 100),
                suppressMenu: true,
                cellClass: 'display_flex_center',
                menuTabs: [],
                /* headerClass: 'posture-ag-grid-header-cls', */
                /* maxWidth: 100, */
                wrapHeaderText: true,
                cellRendererParams: {
                    widgetData, setOpenFlow, setZoomLevel, updatedExtremesRef, appliedFilterTimestamp, getChartTitle,
                    setFlowsTrendResponseData, setFlowsChartDetails, risk
                },
                cellRenderer: AccessFlowCountCell
            },
            /*  {
                 field: "auth_failed_percent",
                 headerName: "% Unsuccessful Auth",
                 maxWidth: getResolutionMatchFor1920(100, 100),
                 flex: 0,
                 autoHeight: true,
                 headerClass: 'align_center',
                 suppressMenu: true,
                 menuTabs: [],
                 autoHeaderHeight: true,
                 wrapHeaderText: true,
                 hide: true,
                 cellRenderer: ({ data }) => {
                     const item = data;
                     return < div className="" id={item.auth_failed_percent} >
                         {Number(item?.auth_failed_percent).toFixed(1)} %
                     </div >
 
                 },
             }, */
            {
                field: "issue_count",
                headerName: "Overall Number of Incidents",
                maxWidth: getResolutionMatchFor1920(130, 100),
                autoHeight: true,
                headerClass: 'align_center',
                suppressMenu: true,
                menuTabs: [],
                autoHeaderHeight: true,
                wrapHeaderText: true,
                cellRenderer: PostureIssueCountCell,
                cellRendererParams: {
                    handleIssueFilterClick,
                    responseData
                }
            },
            ...getAdditionalFields({ pinned: null }),
            {
                field: "latest_time",
                autoHeight: true,
                suppressMenu: true,
                menuTabs: [],
                headerClass: 'posture-header-column-cls',
                width: getResolutionMatchFor1920(160, 158),
                minWidth: getResolutionMatchFor1920(160, 158),
                cellStyle: { whiteSpace: 'normal', height: 50 }, // Allows text to wrap          
                cellClass: 'text_center_line_height_normal padding-top-15 pad-r-l-5 access-last-time',
                wrapHeaderText: true,
                headerName: "Latest Activity",
                cellRenderer: LatestTimeCell,
                cellRendererParams: {
                    onClick: clickIcon
                }
            },
            {
                field: "score",
                width: getResolutionMatchFor1920(100, 80),
                maxWidth: getResolutionMatchFor1920(100, 80),
                suppressMenu: true,
                menuTabs: [],
                headerName: "Score",
                initialSort: "desc",
                cellRenderer: ({ data }) => {
                    /*  if (node.group) {
                         return <span>{ }</span>; // Display group key for grouped rows
                     } */
                    return <IDScore data={data} />;
                },
            }
        ]
    }


    return (
        <>
            <div className="clrBoth margintop10"></div>
            <div className='hide-table-border-top'></div>
            <div className="">
                <div className="flow_table_container">
                    <div className={showAdditionalResult ? "posture_search_section posture_search_section_additional_col" : "posture_search_section posture_search_section_no_additional_col"}>
                        <TimeBasedSearchContainer onApply={applyTimeBasedSearchHandler} filters={getTagsForTimeBasedSearch(tags)} apiUrl={'/posture/daily_flow_count?entity_type=access'} pageType={'accesses'}></TimeBasedSearchContainer>
                        <PostureArchiveTooltip numberOfDays={widgetData?.posture_view_date_range} />
                        <PostureActionButtons
                            selectAll={selectAll}
                            onArchiveClick={onArchive.bind(null, reloadIdentities)} selectCount={selectCount}
                            isOpenIncidents={isIncidentsOpen}
                            onArchiveEstimate={onArchiveEstimate.bind(null)}
                            entity='accesses'
                            isHidden={true} ref={archiveBtnRef}
                        />
                        {showAdvanceSearch && <PostureFilter
                            filterTemplate={PostureSearchTemplate.filter(item => item.search_key !== TIME_BASED_SEARCH_FIELD)}
                            notSupportedKeys={notSupportedKeys} filters={tags}
                            isOpen={showAdvanceSearch} onApplyFilter={applySearchHandler}
                            onClose={onCloseAdvanceSearch}
                            disableCreatePlaybookBtn={isValidResultCountThreshold(responseData)}
                            matchingPlaybookFoundFn={matchingPlaybookFoundFn}
                            entityType={ENTITIES.ACCESSES.id}
                            query={query}
                            showCreatePlaybookBtn={query && query['playbook_type'] === POSTURE_ISSUE.type ? false : true}
                            preMetaDataHandler={preMetaDataHandler}
                            isTimeBasedSearchApplied={isTimeBasedSearchApplied}
                            apiUrl={'/posture/daily_flow_count?entity_type=access'} pageType={'accesses'}
                            PartnerConfigState={PartnerConfigState}
                        />}
                        {
                            openPanelFilterWidgets ?
                                <FilterWidget toggleWidget={handleFilterWidget}
                                    disabledState={handleFilterDisabled}
                                    updateAfterFilter={reloadIdentities} issueFiltersData={filterData} /> : ""
                        }
                    </div>
                    <div className='flex-space-between'>
                        <div>
                            <div className="reload_container">
                                <div ref={reloadRef} className="reload_color_icon" onClick={reloadIdentities}></div>
                            </div>

                            <div className="table_title">Last Updated: {lastUpdate}</div>
                            {showOudatedData ? <Chip label="Data could be outdated, reload to update" color="error" className='outdated-data-msg' /> : <></>}
                        </div>
                        {/* <div className="search_white_icon">&nbsp;</div> */}
                        {/* <input type="text" className="table_search clear_search" onKeyUp={handleSearch} placeholder="Searched item" title=""/> */}
                        <div className='maxWidth550 display-flex'>
                            <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                                <div className={`search_container ${hasReportType ? 'active-report-type' : ''}`}>
                                    <div className="search_white_icon"
                                        style={{ cursor: 'pointer' }}
                                        onClick={handleLensSearch}
                                    >&nbsp;</div>&nbsp;
                                    <Tags tags={getTagsForSearchInput(tags)} deleteTag={deleteTag} />&nbsp;
                                    {/* {tags?.map((tag, index) => <div key={tag + index} title={tag?.toString()} className="search_tag">{tag}&nbsp;<span className="search_close_btn" onClick={() => deleteTag(tag)}>X</span></div>)}&nbsp; */}
                                    <input
                                        className="search_input"
                                        value={searchInput}
                                        placeholder="Search..."
                                        onKeyDown={handleSearch}
                                        onChange={handleSearchChange}
                                    />
                                    {getTagsForSearchInput(tags)?.length > 0 ? <span style={{ cursor: 'pointer' }} onClick={() => {
                                        setTagFlag(true);
                                        PostureSearchState.setIsAdSearchApplied(false)
                                        if (isTimeBasedSearchApplied) {
                                            const tempTags = tags.filter(item => item.indexOf(TIME_BASED_SEARCH_FIELD) > -1);
                                            setTags(tempTags);
                                        } else {
                                            setTags([])
                                        }
                                    }}>&nbsp;X</span> : null}
                                </div>
                            </div>
                            <div className='issue-advance-search-btn-con  display_flex_center accesses-btn'
                                style={{ float: 'right' }}
                            ><button onClick={onAdvanceSearchClick} className={'button_styled issue-advance-search margin-right-10'} >Advanced Search</button>
                                <PlaybookTableActions actions={MoreActionsOptions} rowData={{}}
                                    isDisabled={selectCount == 0 || showAdvanceSearch || isTimeBasedSearchApplied}
                                />
                            </div>
                        </div>
                    </div>

                    <BaseAMAdminGrid
                        containerRef={containerRef}
                        gridRef={gridRef}
                        columnDefs={gridCols}
                        fetchEntitiesFn={fetchIdentities}
                        query={query}
                        setQuery={setQuery}
                        classes={{ gridContainerClass: 'asset-base-am-grid base-am-access-grid' }}
                        onCurrentPageSelect={onCurrentPageClick}
                        onSelectAllRow={onSelectAllClick}
                        onSelectRow={onSetSelectRow}
                        {...(authDetails?.permissions?.Posture?.manual_archive !== "readonly"
                            ? { showCheckbox: true } : null)}
                        headerCheckBoxConfig={headerCheckboxConfig}
                        getCheckboxParams={getCheckboxParams}
                    />
                    <div className="download_section">
                        <button type={('button')} onClick={downloadCSV} className={"width180 " + (loadingCSV.loading ? 'loader export_loader' : 'button_styled')}>Export CSV</button>
                        {loadingCSV.loading ?
                            <div className='loading_info'>
                                Received <span ><b>{totalDownloaded}</b></span> of <span ><b>{totalCount}</b></span> Accesses
                            </div>
                            : null}
                        {PartnerConfigState?.PartnerShortProduct && <CSVLink
                            headers={headers}
                            // filename={`${PartnerConfigState?.PartnerShortProduct?.replaceAll(" ", "_")}_Accesses.csv`}
                            filename={getExportFileName(PartnerConfigState?.PartnerShortProduct, 'Accesses')}
                            data={downData!}
                            ref={csvLinkEl}
                        />}
                        <div style={{ marginLeft: '1rem', marginTop: '1rem' }}>
                            <Link to={'/archivedata'} className='link-text font12'>Accesses inactive for more than 60 days are archived here.</Link>
                        </div>
                    </div>

                </div>
            </div>
            {showRuleMatch ? <RuleMatch data={ruleMatch} prevURL={prevUrl} handleRuleMatchClose={() => setShowRuleMatch(false)} /> : null}
            {showMatchRule ? <MatchRule data={matchRuleData} handleRuleMatchClose={() => setShowMatchRule(false)} /> : null}
            {openFlow ? <FlowTrend data={flowsTrendResponseData} chartDetails={flowsChartDetails} closeFlowPopup={() => setOpenFlow(false)} onRangeChange={handleRangeChange} zoomLevel={zoomLevel} updatedExtremes={updatedExtremesRef} showZoom={true} /> : ''}
            {failedDir && <FailedDirPopUp data={failedDir} onClose={() => setFailedDir(undefined)} />}

        </>
    )
}

export default React.memo(withQueryParams({
    q: StringParam,
    page: StringParam,
    rpp: NumberParam,
    sort_by: StringParam,
    order_by: StringParam,
    rule_name: StringParam,
    d_protocol: StringParam,
    risk: NumberParam,
    hash: StringParam,
    d_name: StringParam,
    s_time: NumberParam,
    e_time: NumberParam,
    hard_refresh: StringParam,
    filters: withDefault(ArrayParam, []),
    entity_type: StringParam,
    playbook_type: StringParam
}, Accesses));