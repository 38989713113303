/* Skeleton Component */

import React, { useCallback, useEffect } from 'react';
// import { IHeaderParams, IHeaderReactComp } from 'ag-grid-community/core';
import { IHeaderComp, IHeaderParams } from '@ag-grid-community/core';
// import { IHeaderReactComp } from 'ag-grid-react';
import InfoIcon from '@mui/icons-material/Info';

interface CustomHeaderProps extends IHeaderParams {
    gridRef, showInfoIcon?, handleOnInfoClick?
}

const CustomHeader: React.FC<CustomHeaderProps & IHeaderComp> = (props) => {
    const { displayName, enableSorting, enableMenu, column, setSort, api, gridRef, showInfoIcon, handleOnInfoClick } = props;

    useEffect(() => {
        console.log(gridRef)
    }, [gridRef])

    // Handle sorting logic
    const handleSort = () => {
        if (!enableSorting) return;
        const currentSort = column.getSort();
        const nextSort = currentSort === 'asc' ? 'desc' : currentSort === 'desc' ? 'asc' : 'desc';
        setSort(nextSort!, false); // Apply new sort
        api.refreshCells(); // Refresh the grid
    };

    // Handle menu logic
    const handleMenu = () => {
        if (!enableMenu) return;
        props.showColumnMenu(props.column);
    };


    const updateSortIcons = useCallback(() => {
        const columnStates = api?.getColumnState();
        columnStates?.forEach((state) => {
            const el = document.getElementById(state.colId + '-sorter') as HTMLSpanElement;
            if (!el) return;

            el.classList.remove('ag-icon-asc', 'ag-icon-desc', 'ag-icon-sort', 'ag-icon-none');
            if (state.sort === 'asc') {
                el.classList.add('ag-icon-asc');
            } else if (state.sort === 'desc') {
                el.classList.add('ag-icon-desc');
            } else {
                el.classList.add('ag-icon-sort');
                el.classList.add('ag-icon-none');
            }
        });
    }, [api]);

    useEffect(() => {
        const onSortChanged = () => {
            updateSortIcons();
        };

        api?.addEventListener('sortChanged', onSortChanged);
        updateSortIcons(); // Initial update

        return () => {
            api?.removeEventListener('sortChanged', onSortChanged);
        };
    }, [api, updateSortIcons]);


    return (
        <div className="am-grid-default-custom-header" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            {/* Column Label */}
            <span className="am-grid-default-custom-header-label" onClick={handleSort} style={{ cursor: enableSorting ? 'pointer' : 'default' }}>
                {displayName}
                {showInfoIcon &&
                    <InfoIcon style={{ fontSize: 12, transform: 'skewX(-10deg)' }}
                        onClick={handleOnInfoClick}
                    />}
                {/* Sort Icon */}
                {
                    column.getColDef().sortable &&
                    <span
                        id={column.getColId() + '-sorter'}
                        className="ag-icon ag-icon-sort"
                        style={{ marginLeft: 4 }}
                    ></span>
                }
            </span>

            {/* Menu Button */}
            {enableMenu && (
                <button onClick={handleMenu} style={{ marginLeft: '8px' }}>
                    ☰
                </button>
            )}
        </div>
    );
};

export default CustomHeader;
