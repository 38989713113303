import React, { useState, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useToasts } from '../../../components/core';

import AMTagInput from '../../../components/core/AMTagInput/AMTagInput';
import 'react-tagsinput/react-tagsinput.css'

import { Api } from '../../../components/Axios'
import './edit-mfa-server.scss';
import { AMModalError } from '../../../components/core/AMModalPopup/AMModalError';
import { MFAErrors } from './MFAErrors';
import { CATALOG_LIST } from '../Constants';
import { Autocomplete, TextField } from '@mui/material';
import { isValidIp } from '../../../utils/util-methods';
import CheckboxAutocomplete from '../../../components/core/AMAutoComplete/AMAutoComplete';

type MfaServer = {
    name?: string;
    fqdn?: string;
    ips?: [];
    type?: string;
    is_provider?: boolean;
    factors?: factor[];
    mfa_provider_info?: MFAproviderinfo;
    isChecked: boolean;
}

interface Props {
    id?: string;
    AfterMfaServerOperation?: any;
    onPanelClose: any;
}

type factor = {
    name: string,
    isChecked: boolean;
}

type State = {
    factors: factor[];
}

type MFAproviderinfo = {
    endpoint?: string;
    client_id?: string;
    client_secret?: string;
    admin_api_hostname?: string;
    admin_integration_key?: string;
    admin_secret_key?: string;
    auth_api_hostname?: string;
    auth_integration_key?: string;
    auth_secret_key?: string;
}

export const EditPanel = ({ id, AfterMfaServerOperation, onPanelClose }: Props) => {
    const { register, handleSubmit, errors, setValue, setError, clearErrors, control } = useForm<MfaServer>()
    const [loading, setLoading] = useState<Boolean>(false)
    const [mfaServer, setMfaServer] = useState<MfaServer>()
    const [showmfadetails, setShowMfaDetails] = useState<Boolean>(false)
    const [vendorType, setVendorType] = useState<string | undefined>();
    const [ips, setIps] = useState([])
    const [mfaProviderCheck, setMfaProviderCheck] = useState<boolean>();
    const [mfaproviderinfo, setMfaproviderinfo] = useState<MFAproviderinfo>()
    const { addToast } = useToasts()
    const [errorFQDN, setErrorFQDN] = useState('');
    const [selectedCatalog, setSelectedCatalog] = useState<any>(null);
    const [state, setState] = useState<State>(
        {
            factors: [
                {
                    name: 'OTP - Email',
                    isChecked: false
                },
                {
                    name: 'OTP - SMS',
                    isChecked: false
                },
                {
                    name: 'OTP - All',
                    isChecked: false
                },
                {
                    name: 'Push',
                    isChecked: false
                },
                {
                    name: 'Push-with-Device-PIN',
                    isChecked: false
                },
                {
                    name: 'Push-with-Device-Biometric',
                    isChecked: false
                }
            ]
        }
    );

    const onUpdateMfaServer = (data: any) => {

        if (!ips || ips.length === 0) {
            setError('ips', { type: 'required' });
            return;
        } else {
            clearErrors('ips')
        }

        let temp = state.factors.map(f => {
            if (f.isChecked) {
                return f.name
            }
        });
        let tf = temp.filter(
            (name) =>
                name != null
        );
        let request = {
            name: data.name,
            ips: data.ips,
            is_provider: data.is_provider,
            type: data.type,
            status: data.status,
            factors: tf,
            mfa_provider_info: {
                endpoint: data.endpoint,
                client_id: data.client_id,
                client_secret: data.client_secret,
                admin_integration_key: data.admin_integration_key,
                admin_secret_key: data.admin_secret_key,
                admin_api_hostname: data.admin_api_hostname,
                auth_integration_key: data.auth_integration_key,
                auth_secret_key: data.auth_secret_key,
                auth_api_hostname: data.auth_api_hostname
            }
        }

        setLoading(true)
        request['ips'] = ips
        //  request['factors'] = tf
        request['catalog_entry_name'] = selectedCatalog ? selectedCatalog : "NA";
        console.log(request);
        const headers = { 'Operation': 'PUT' }
        Api.post('/mfa/' + id, request, { headers: headers })
            .then((res: { data: any }) => {
                setLoading(false)
                AfterMfaServerOperation('edit')
                addToast("Mfa server updated successfully.", {
                    appearance: 'success',
                    autoDismiss: true,
                })
            })
            .catch((error: any) => {
                setLoading(false)
                if (error?.response?.data) {
                    addToast(error.response.data, {
                        appearance: 'error',
                        autoDismiss: true,
                    })
                } else if (error.response.status === 500) {
                    addToast("Sorry, something went wrong there, try again.", {
                        appearance: 'error',
                        autoDismiss: true,
                    })
                } else if (error.response.status === 419) {
                    addToast("We encounted validation problem, please correct and try again.", {
                        appearance: 'error',
                        autoDismiss: true,
                    })
                } else if (error.response.status === 404) {
                    addToast("We are not able to find associated email, please check and try again.", {
                        appearance: 'error',
                        autoDismiss: true,
                    })
                }
            })
    }

    const handleMfaProviderChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.stopPropagation();
        if (e.target.checked) {
            setMfaProviderCheck(true);
            setShowMfaDetails(true)
        }
        else {
            setMfaProviderCheck(false);
            setShowMfaDetails(false)
        }
    }

    useEffect(() => {
        Api.get('/mfa/' + id)
            .then((res: { data: any }) => {
                // console.log(res.data)
                setValue("name", res.data.name)
                setValue("fqdn", res.data.fqdn)
                setVendorType(res.data.type)
                //  setValue("type", res.data.type)
                if (res.data.ips) {
                    setIps(res.data.ips)
                }
                if (res.data.catalog_entry_name) {
                    setValue("catalog", CATALOG_LIST.filter(item => item.key === res.data.catalog_entry_name).map(item => ({key: item.key, value: item.label})))
                    setSelectedCatalog(res.data.catalog_entry_name)
                }
                setValue("is_provider", res.data.is_provider)
                if (res.data.is_provider === true) {
                    setMfaProviderCheck(true);
                    setShowMfaDetails(true)
                }
                // console.log("Provider info...", res.data.mfa_provider_info)
                setMfaproviderinfo(res.data.mfa_provider_info)
                if (res.data.mfa_provider_info) {
                    setMfaproviderinfo(res.data.mfaproviderinfo)
                }
                // Manage state for Factors already selected 
                res.data.factors.map((item: any) => {
                    let obj = state.factors.find((factor: factor) => {
                        if (factor.name === item) {
                            factor.isChecked = true;
                            setState({
                                ...state,
                                factors: state.factors
                            })
                            return true; // stop searching
                        }
                    });
                });

                setValue("endpoint", res.data.mfa_provider_info?.endpoint)
                setValue("client_id", res.data.mfa_provider_info?.client_id)
                setValue("client_secret", res.data.mfa_provider_info?.client_secret)

                setValue("admin_integration_key", res.data.mfa_provider_info?.admin_integration_key)
                setValue("admin_secret_key", res.data.mfa_provider_info?.admin_secret_key)
                setValue("admin_api_hostname", res.data.mfa_provider_info?.admin_api_hostname)

                setValue("auth_integration_key", res.data.mfa_provider_info?.auth_integration_key)
                setValue("auth_secret_key", res.data.mfa_provider_info?.auth_secret_key)
                setValue("auth_api_hostname", res.data.mfa_provider_info?.auth_api_hostname)

            })
            .catch((error: any) => {
            })
    }, [])

    const AddTags = (tag: any) => {
        deselectCatalogBasedOnIps(tag);
        if (!isValidFQDN(tag)) {
            setErrorFQDN("Invalid IP address");
            return;
        }
        setIps(removeDuplicateIps(tag))
        setErrorFQDN('');
    }

    const validTags = (tag: any) => {
        // setErrorFQDN('Invalid Hostname/FQDN format');
        setError('ips', { type: 'invalidTag' })
    }

    const removeTagError = (tag: any) => {
        setErrorFQDN('');
    }

    useEffect(
        () => {
            setVendorType(vendorType)
        }, [vendorType]
    )

    useEffect(() => {
        register('ips')
    }, [])

    const handleSelectChange = (newValue) => {
        if (newValue && newValue.length > 0) {
            const catalogObj = CATALOG_LIST.find(item => item.key === newValue[0].key);
            if (catalogObj && catalogObj.fqdns) {
                let newTags = [...ips];
                if (selectedCatalog) {
                    newTags = removeIpsBasedOnCatalogChange(newTags);
                }
                newTags.push(...catalogObj.fqdns);
                setIps(removeDuplicateIps(newTags));
            }
            setSelectedCatalog(newValue[0].key);
        } else {
            if (selectedCatalog) {
                let newTags = [...ips];
                newTags = removeIpsBasedOnCatalogChange(newTags);
                setIps(removeDuplicateIps(newTags));
            }
            setSelectedCatalog('');
        }
    };

    const removeDuplicateIps = (arr) => { 
        let res = [];
        if (arr.length === 0) { 
            return res;
        }
        arr.forEach(item => { 
            if (!res.includes(item)) res.push(item);
        })
        return res;
    }

    const removeIpsBasedOnCatalogChange = (newTags) => { 
        const tempObj = CATALOG_LIST.find(item => item.key === selectedCatalog)
        return newTags.filter(item => !tempObj.fqdns.includes(item));
    }

    const deselectCatalogBasedOnIps = (tag) => {
        if (selectedCatalog && fromCatalog(selectedCatalog)) {
            const catalogObj = CATALOG_LIST.find(item => item.key === selectedCatalog);
            const newTag = tag.filter(item => catalogObj.fqdns.includes(item));
            const matchFound = catalogObj.fqdns.every(item => newTag.includes(item));
            !matchFound && setSelectedCatalog('');
        }
    }

    const fromCatalog = (item) => CATALOG_LIST.some(item => item.key === selectedCatalog);

    const getOptionLabelWithSpacing = (option: any) => {
        return `  ${option.label}`; 
    };

    const isValidFQDN = (tag) => {
        let res = true;
        tag.forEach(item => {
            if (!res) { 
                return;
            }
            if (!Number.isNaN(parseInt(item, 10)) && !isValidIp(item)) {
                res = false;
            }
        })
        return res;
    }

    const getItemLabel = (
        option: { key: string; value: string } | { key: string; value: string }[]
      ) => {
        if (Array.isArray(option)) {
          return option[0]?.value ? option[0]?.value : "";
        } else {
          return option?.value ? option?.value : "";
        }
    };
    
    return (
        <form onSubmit={handleSubmit(onUpdateMfaServer)}>
            <div className="edit-mfaserver-form-container">
                <div className="form-control-group-left">
                    <div className="form-control">
                        <label  className="font-bold">Name*</label>
                        <input
                            type="text"
                            placeholder=""
                            name="name"
                            ref={register({
                                required: true,
                                pattern: /^[^'"]*$/
                            })}
                            className={(errors.name ? "error" : "")}
                        />
                    </div>
                    <div className='form-control'>
                        <div className='label-div'>
                            <label className='margin-10 font-bold'>
                            IP Addresses/Host Name/FQDNs*
                            </label>
                        </div>
                        <div className='group-container'>
                                <label
                                    htmlFor='selection_catalog_identity'
                                    className='float_none'
                                >
                                {'Select from list of known Catalog (optional)'}
                            </label>
                            <div className="select-catalog-dropdown-container" style={{marginTop: "1%", width: "40%"}}>
                                <Controller
                                    name="catalog"
                                    control={control}
                                    defaultValue={[]}
                                    ref={register({
                                    required: true,
                                    })}
                                    render={({ onChange, value = "" }) => (
                                    <CheckboxAutocomplete
                                        label="Select options"
                                        options={CATALOG_LIST.map(item => ({key: item.key, value: item.label}))}
                                        value={value}
                                        onChange={(event, newValue) => {
                                            onChange(newValue)
                                            handleSelectChange(newValue)
                                        }}
                                        getItemLabel={getItemLabel}
                                        multiple={false}
                                        name="catalog"
                                        formType="edit"
                                    />
                                    )}
                                />
                            </div>
                                {/* <div>
                                <Autocomplete
                                    size='small'
                                    className='mfa-catalog-container font12'
                                    value={
                                        CATALOG_LIST.find(
                                            (item) =>
                                                item.key === selectedCatalog
                                        ) || null
                                    }
                                    options={CATALOG_LIST}
                                    sx={{ width: 300 }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant='standard'
                                            defaultValue=' --Select an option--'
                                            placeholder=' --Select an option--'
                                        />
                                    )}
                                    isOptionEqualToValue={(option, value) =>
                                        option.key === (value ? value.key : '')
                                    }
                                    onChange={(_, newValue) =>
                                        handleSelectChange(newValue)
                                    }
                                    noOptionsText='No options'
                                    getOptionLabel={getOptionLabelWithSpacing}
                                />

                            </div> */}
                                <div>
									<AMTagInput
										value={ips}
										onChange={AddTags}
                                        renderLayout={(tagElements, inputElement) => {
                                            return (
                                                <span>
                                                    <div
                                                        className="scrollbar-container directory-tag-container">
                                                        {tagElements}
                                                    </div>
                                                    <div>
                                                    {inputElement}
                                                    </div>
                                                </span>
                                            )
                                        }}
										inputProps={{ placeholder: "Add IP Address, etc." }}
										// validationRegex={(/^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\-][^'"]*[a-zA-Z0-9][^'"])\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9\-]*[A-Za-z0-9])*([/0-9]*)$/)}
										// validationRegex={/^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/}
										// validationRegex={/^(?:\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}|[A-Za-z0-9][A-Za-z0-9\-]*)$/}
										// validationRegex={(/^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\-]*[a-zA-Z0-9])\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9\-]*[A-Za-z0-9])$/)}
										onValidationReject={validTags}
									/>
									
									<div className="error">Note: Hit the Enter/Return, Tab, Space, or Comma key after entering or copy-pasting one or more IPs (separated by comma, space, or newline).</div>
                                </div>
                            {/* </div> */}
                        </div>
                </div>
                </div>
            </div>
            {errorFQDN && (
                <div className='add-directory-errors-list-container'>
                        <div
                            className={`error marginleft40per dot`}
                        >
                            {errorFQDN}
                        </div>

                </div>
            )}


            {/* <div className="clrBoth"></div>
            <label className="auto_width paddingright20">Use this MFA Server in the <br />Add MFA <strong><i>action</i></strong>  in Playbooks</label>
            <input
                name="is_provider"
                type="checkbox"
                className={("margintop20")}
                checked={mfaProviderCheck ? mfaProviderCheck : false}
                onChange={handleMfaProviderChange}
                ref={register}
            />
            <div className="clrBoth margintop20"></div>
            {showmfadetails ?
                <div className="form_sub_section">
                    <h5><strong>MFA Server Details:</strong></h5>
                    <label>Vendor*</label>
                    <select
                        name="type"
                        className={"form_drodown" + (errors.type ? "error" : " ")}
                        ref={register({
                            required: true,
                        })}
                        value={vendorType}
                        onChange={(e) => { setVendorType(e.currentTarget.value); }}
                    >
                        <option value="OpenMFA">OpenMFA</option>
                        <option value="CiscoDuo">Cisco Duo</option>
                    </select>
                    {errors.type && errors.type.type === 'required' && <div className="error marginleft40per">Please select type.</div>}
                    {errors.type && errors.type.type !== 'required' && <div className="error marginleft40per">{errors.type.message}</div>}

                    {vendorType === 'OpenMFA' ?
                        <div>
                            <label>Factors*</label>
                            <div className="clrBoth margintop20">&nbsp;</div>

                            <div className="factors_grid">
                                <ul>
                                    {state?.factors?.map((factor: factor) => {
                                        return (
                                            <li>
                                                <div className="">
                                                    <React.Fragment key={factor.name}>
                                                        <input type="checkbox" id={factor.name} name={factor.name} checked={factor.isChecked}
                                                            onChange={(e) => {
                                                                if (e.target.checked) {
                                                                    factor.isChecked = true
                                                                }
                                                                else {
                                                                    factor.isChecked = false
                                                                }
                                                                setState({
                                                                    ...state,
                                                                    factors: state.factors
                                                                })
                                                            }}
                                                        />
                                                        <label htmlFor={factor.name}>{factor.name}</label>
                                                    </React.Fragment>
                                                </div>
                                            </li>
                                        );
                                    })
                                    }
                                </ul>
                            </div>

                            <label>API Endpoint URL*</label>
                            <input
                                type="text"
                                placeholder="Enter API Endpoint URL"
                                name="endpoint"
                                ref={register({
                                    required: true,
                                })}
                            />
                            <label>Client ID*</label>
                            <input
                                type="text"
                                placeholder="Enter Client ID"
                                name="client_id"
                                ref={register({
                                    required: true,
                                })}
                            />

                            <label>Client Secret*</label>
                            <input
                                type="text"
                                placeholder="Enter Client Secret"
                                name="client_secret"
                                ref={register({
                                    required: true,
                                })}
                            />

                        </div> : null
                    }

                    {vendorType === 'CiscoDuo' ?
                        <div>
                            <label>Factors*</label>
                            {state?.factors?.map((factor: factor) => {
                                return (
                                    <React.Fragment key={factor.name}>
                                        {factor.name === "Push" ?
                                            <div>
                                                <input type="checkbox" id={factor.name} name={factor.name} checked={factor.isChecked}
                                                    className="float_left margintop20"
                                                    onChange={(e) => {
                                                        if (e.target.checked) {
                                                            factor.isChecked = true
                                                        }
                                                        else {
                                                            factor.isChecked = false
                                                        }
                                                        setState({
                                                            ...state,
                                                            factors: state.factors
                                                        })
                                                    }}
                                                />
                                                <label className="auto_width" htmlFor={factor.name}>{factor.name}</label>
                                            </div>
                                            : null
                                        }
                                    </React.Fragment>
                                );
                            })
                            }
                            <div className="clrBoth"></div>
                            <h5 className="margintop20"><strong>Admin API:</strong></h5><br />

                            <label>Integration Key*</label>
                            <input
                                type="text"
                                ref={register({
                                    required: true,
                                })}
                                placeholder="Enter Integration Key"
                                name="admin_integration_key"
                                className={(errors.mfa_provider_info ? "error" : "")}
                            />

                            <label>Secret Key*</label>
                            <input
                                type="text"
                                ref={register({
                                    required: true,
                                })}
                                placeholder="Enter secret key"
                                name="admin_secret_key"
                                className={(errors.mfa_provider_info ? "error" : "")}
                            />

                            <label>API Hostname*</label>
                            <input
                                type="text"
                                ref={register({
                                    required: true,
                                })}
                                placeholder="Enter API hostname"
                                name="admin_api_hostname"
                                className={(errors.mfa_provider_info ? "error" : "")}
                            />

                            <div className="clrBoth"></div>
                            <h5 className="margintop20"><strong>Auth API:</strong></h5><br />

                            <label>Integration Key*</label>
                            <input
                                type="text"
                                ref={register({
                                    required: true,
                                })}
                                placeholder="Enter integration key"
                                name="auth_integration_key"
                                className={(errors.mfa_provider_info ? "error" : "")}
                            />

                            <label>Secret Key*</label>
                            <input
                                type="text"
                                ref={register({
                                    required: true,
                                })}
                                placeholder="Enter secret key"
                                name="auth_secret_key"
                                className={(errors.mfa_provider_info ? "error" : "")}
                            />

                            <label>API Hostname*</label>
                            <input
                                type="text"
                                ref={register({
                                    required: true,
                                })}
                                placeholder="Enter API hostname"
                                name="auth_api_hostname"
                                className={(errors.mfa_provider_info ? "error" : "")}
                            />

                        </div> : null
                    }

                </div> : null
            } */}
            <AMModalError errors={errors} errorMap={MFAErrors} />
            <div className="add-edit-action-buttons mfa-action-buttons dialog-button">
            <button type={('button')}
                            onClick={onPanelClose}
                            className="button_styled ghost"
                        >Cancel</button>
                <button type={loading ? "button" : "submit"} className={"float_right " + (loading ? "loader" : "add-edit-submit")}>
                    Save
                </button>
            </div>

            {/* <div className="footer_line"></div>
            <div className="pane_footer">
                <div className="launch_button">
                    <button type={(loading ? 'button' : 'submit')} className={"float_right " + (loading ? 'loader' : 'button_styled')}>Save</button>
                </div>
            </div> */}
        </form>
    )
}