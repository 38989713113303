import React from "react"
import Popup from "reactjs-popup"
import CloseIcon from '@mui/icons-material/Close';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';


interface IUserRoleDisplayProps {
    closeConfirm(): any
}

const popperProps = {
    style: {
      zIndex: 10000000,
      maxWidth: '28%',
    },
};

export const UserRoleDisplay = ({ closeConfirm }: IUserRoleDisplayProps) => {
    return <Popup
        open={true}
        closeOnDocumentClick={false}
        closeOnEscape={false}
        overlayStyle={{ zIndex: 15001, background: "rgba(227, 242, 253, .6)" }}
        onClose={closeConfirm}
        modal
    >
        <div className="modal role-display-modal-container"
            id="scrollable-content">
            {/* <div className="close" onClick={closeConfirm}></div>                              
        <div className="header" style={{ position: "fixed", width: "100%" }}>
          <div title={"User Detail's"}>User Detail's</div>
        </div> */}
            <div className="close" onClick={closeConfirm}></div>
            <div className="header" style={{ marginBottom: '2%' }}>User Role Permission</div>
            <div className="content role-display-main-container">

                <div className="role-display-table-container scrollbar-container">
                    <table id="table" className="ticket_tb sticky_table_top rounded-table"
                    // className={styles.playbook_table + ' sticky_table_top'}
                    >

                        <thead>
                            <tr>
                                <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="User Role" PopperProps={popperProps}>
                                  <th>
                                    User Role
                                  </th>
                                </Tooltip>

                                <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="Sensors" PopperProps={popperProps}>
                                  <th>
                                    Sensors
                                  </th>
                                </Tooltip>

                                <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="Registered Users" PopperProps={popperProps}>
                                    <th>
                                      Registered Users
                                    </th>
                                </Tooltip>  

                                <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="Known Directory Servers & IDPs" PopperProps={popperProps}>
                                    <th>
                                      Known Directory..
                                    </th>
                                </Tooltip>

                                <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="Known Applications (Exposed to Public)" PopperProps={popperProps}>
                                    <th>
                                      Known Applications..
                                    </th>
                                </Tooltip>

                                <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="Known VPN / Firewalls / Gateways / NAT" PopperProps={popperProps}>
                                    <th>
                                      Known VPN..
                                    </th>
                                </Tooltip>

                                <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="Known Internal Public IPs" PopperProps={popperProps}>
                                    <th>
                                      Known Internal..
                                    </th>
                                </Tooltip>

                                <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="Known MFA Servers" PopperProps={popperProps}>
                                    <th>
                                      Known MFA..
                                    </th>
                                </Tooltip>

                                <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="Directory Query Parameters" PopperProps={popperProps}>
                                    <th>
                                     Directory Query..
                                    </th>
                                </Tooltip>
                                
                                <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="Exceptions" PopperProps={popperProps}>
                                    <th>
                                      Exceptions
                                    </th>
                                </Tooltip>

                                <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="Proxies" PopperProps={popperProps}>
                                    <th>
                                      Proxies
                                    </th>
                                </Tooltip>

                                <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="Audit Log" PopperProps={popperProps}>
                                    <th>
                                      Audit Log
                                    </th>
                                </Tooltip>
                                
                                <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="Archived Data" PopperProps={popperProps}>
                                    <th>
                                      Archived Data
                                    </th>
                                </Tooltip>
                                           
                                <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="General Setting" PopperProps={popperProps}>
                                    <th>
                                      General Setting
                                    </th>
                                </Tooltip>

                                <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="Posture" PopperProps={popperProps}>
                                    <th>
                                      Posture
                                    </th>
                                </Tooltip>

                                <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="Incidents" PopperProps={popperProps}>
                                    <th>
                                      Incidents
                                    </th>
                                </Tooltip>

                                <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="Tickets" PopperProps={popperProps}>
                                    <th>
                                      Tickets
                                    </th>
                                </Tooltip>

                                <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="Playbooks" PopperProps={popperProps}>
                                    <th>
                                      Playbooks
                                    </th>
                                </Tooltip>
                            </tr>
                        </thead>
                        <tbody>

                            <tr>
                                <td>Admin</td>
                                <td className="grid-container">
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="Create"
                                      PopperProps={popperProps}
                                    >
                                     <div><span className="square c585858"><div className="shortcode-width">C</div></span></div>
                                    </Tooltip>

                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="Edit"
                                      PopperProps={popperProps}
                                    >
                                    <div><span className="square c787878"><div className="shortcode-width">E</div></span></div>
                                    </Tooltip>

                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="Delete"
                                      PopperProps={popperProps}
                                    >
                                    <div><span className="square c989898"><div className="shortcode-width">D</div></span></div>
                                    </Tooltip>

                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="View"
                                      PopperProps={popperProps}
                                    >
                                    <div><span className="square cB8B8B8"><div className="shortcode-width">V</div></span></div>
                                    </Tooltip>
                                </td>
                                <td className="grid-container">
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="Create"
                                      PopperProps={popperProps}
                                    >
                                     <div><span className="square c585858"><div className="shortcode-width">C</div></span></div>
                                    </Tooltip>
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="Edit"
                                      PopperProps={popperProps}
                                    >
                                    <div><span className="square c787878"><div className="shortcode-width">E</div></span></div>
                                    </Tooltip>
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="Delete"
                                      PopperProps={popperProps}
                                    >
                                    <div><span className="square c989898"><div className="shortcode-width">D</div></span></div>
                                    </Tooltip>
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="View"
                                      PopperProps={popperProps}
                                    >
                                    <div><span className="square cB8B8B8"><div className="shortcode-width">V</div></span></div>
                                    </Tooltip>
                                    
                                </td>
                                <td className="grid-container">
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="Create"
                                      PopperProps={popperProps}
                                    >
                                     <div><span className="square c585858"><div className="shortcode-width">C</div></span></div>
                                    </Tooltip>
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="Edit"
                                      PopperProps={popperProps}
                                    >
                                    <div><span className="square c787878"><div className="shortcode-width">E</div></span></div>
                                    </Tooltip>
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="Delete"
                                      PopperProps={popperProps}
                                    >
                                    <div><span className="square c989898"><div className="shortcode-width">D</div></span></div>
                                    </Tooltip>
                                    
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="View"
                                      PopperProps={popperProps}
                                    >
                                    <div><span className="square cB8B8B8"><div className="shortcode-width">V</div></span></div>
                                    </Tooltip>
                                </td>
                                <td className="grid-container">
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="Create"
                                      PopperProps={popperProps}
                                    >
                                     <div><span className="square c585858"><div className="shortcode-width">C</div></span></div>
                                    </Tooltip>
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="Edit"
                                      PopperProps={popperProps}
                                    >
                                    <div><span className="square c787878"><div className="shortcode-width">E</div></span></div>
                                    </Tooltip>
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="Delete"
                                      PopperProps={popperProps}
                                    >
                                    <div><span className="square c989898"><div className="shortcode-width">D</div></span></div>
                                    </Tooltip>
                                    
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="View"
                                      PopperProps={popperProps}
                                    >
                                    <div><span className="square cB8B8B8"><div className="shortcode-width">V</div></span></div>
                                    </Tooltip>
                                    
                                </td>
                                <td className="grid-container">
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="Create"
                                      PopperProps={popperProps}
                                    >
                                     <div><span className="square c585858"><div className="shortcode-width">C</div></span></div>
                                    </Tooltip>
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="Edit"
                                      PopperProps={popperProps}
                                    >
                                    <div><span className="square c787878"><div className="shortcode-width">E</div></span></div>
                                    </Tooltip>
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="Delete"
                                      PopperProps={popperProps}
                                    >
                                    <div><span className="square c989898"><div className="shortcode-width">D</div></span></div>
                                    </Tooltip>
                                    
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="View"
                                      PopperProps={popperProps}
                                    >
                                    <div><span className="square cB8B8B8"><div className="shortcode-width">V</div></span></div>
                                    </Tooltip>
                                    
                                </td>
                                <td className="grid-container">
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="Create"
                                      PopperProps={popperProps}
                                    >
                                     <div><span className="square c585858"><div className="shortcode-width">C</div></span></div>
                                    </Tooltip>
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="Edit"
                                      PopperProps={popperProps}
                                    >
                                    <div><span className="square c787878"><div className="shortcode-width">E</div></span></div>
                                    </Tooltip>
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="Delete"
                                      PopperProps={popperProps}
                                    >
                                    <div><span className="square c989898"><div className="shortcode-width">D</div></span></div>
                                    </Tooltip>
                                    
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="View"
                                      PopperProps={popperProps}
                                    >
                                    <div><span className="square cB8B8B8"><div className="shortcode-width">V</div></span></div>
                                    </Tooltip>
                                    
                                </td>
                                <td className="grid-container">
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="Create"
                                      PopperProps={popperProps}
                                    >
                                     <div><span className="square c585858"><div className="shortcode-width">C</div></span></div>
                                    </Tooltip>
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="Edit"
                                      PopperProps={popperProps}
                                    >
                                    <div><span className="square c787878"><div className="shortcode-width">E</div></span></div>
                                    </Tooltip>
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="Delete"
                                      PopperProps={popperProps}
                                    >
                                    <div><span className="square c989898"><div className="shortcode-width">D</div></span></div>
                                    </Tooltip>
                                    
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="View"
                                      PopperProps={popperProps}
                                    >
                                    <div><span className="square cB8B8B8"><div className="shortcode-width">V</div></span></div>
                                    </Tooltip>
                                    
                                </td>
                                <td className="grid-container">
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="Create"
                                      PopperProps={popperProps}
                                    >
                                     <div><span className="square c585858"><div className="shortcode-width">C</div></span></div>
                                    </Tooltip>
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="Edit"
                                      PopperProps={popperProps}
                                    >
                                    <div><span className="square c787878"><div className="shortcode-width">E</div></span></div>
                                    </Tooltip>
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="Delete"
                                      PopperProps={popperProps}
                                    >
                                    <div><span className="square c989898"><div className="shortcode-width">D</div></span></div>
                                    </Tooltip>
                                    
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="View"
                                      PopperProps={popperProps}
                                    >
                                    <div><span className="square cB8B8B8"><div className="shortcode-width">V</div></span></div>
                                    </Tooltip>
                                    
                                </td>
                                <td className="grid-container">
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="Create"
                                      PopperProps={popperProps}
                                    >
                                     <div><span className="square c585858"><div className="shortcode-width">C</div></span></div>
                                    </Tooltip>
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="Edit"
                                      PopperProps={popperProps}
                                    >
                                    <div><span className="square c787878"><div className="shortcode-width">E</div></span></div>
                                    </Tooltip>
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="Delete"
                                      PopperProps={popperProps}
                                    >
                                    <div><span className="square c989898"><div className="shortcode-width">D</div></span></div>
                                    </Tooltip>
                                    
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="View"
                                      PopperProps={popperProps}
                                    >
                                    <div><span className="square cB8B8B8"><div className="shortcode-width">V</div></span></div>
                                    </Tooltip>
                                    
                                </td>
                                <td className="grid-container">
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="Create"
                                      PopperProps={popperProps}
                                    >
                                     <div><span className="square c585858"><div className="shortcode-width">C</div></span></div>
                                    </Tooltip>
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="Edit"
                                      PopperProps={popperProps}
                                    >
                                    <div><span className="square c787878"><div className="shortcode-width">E</div></span></div>
                                    </Tooltip>
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="Delete"
                                      PopperProps={popperProps}
                                    >
                                    <div><span className="square c989898"><div className="shortcode-width">D</div></span></div>
                                    </Tooltip>
                                    
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="View"
                                      PopperProps={popperProps}
                                    >
                                    <div><span className="square cB8B8B8"><div className="shortcode-width">V</div></span></div>
                                    </Tooltip>
                                    
                                </td>

                                <td>
                                <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="Download"
                                      PopperProps={popperProps}
                                    >
                                  <div><span className="square-width c787878"><div className="shortcode-width">DW</div></span></div>
                                  </Tooltip>    
                                </td>
                                                            
                                <td>
                                <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="Download" PopperProps={popperProps} >
                                  <div><span className="square-width c787878"><div className="shortcode-width">DW</div></span></div>
                                  </Tooltip>   
                                </td>
                                                          
                                <td>
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="Edit"
                                      PopperProps={popperProps}
                                    >
                                    <div><span className="square c787878"><div className="shortcode-width">E</div></span></div>
                                    </Tooltip>
                                </td>

                                <td className="grid-container">
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="Add Exception"
                                      PopperProps={popperProps}
                                    >
                                        <div><span className="square-width c585858"><div className="shortcode">AE</div></span></div>
                                    </Tooltip>
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="Close Incident"
                                      PopperProps={popperProps}
                                    >
                                    <div><span className="square-width c707070"><div className="shortcode">CI</div></span></div>
                                    </Tooltip>                                 
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="Add to Known Internal Public IPs"
                                      PopperProps={popperProps}
                                    >                                  
                                    <div><span className="square-width c888888"><div className="shortcode">AP</div></span></div>
                                    </Tooltip>                                      
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="Add to Known App"
                                      PopperProps={popperProps}
                                    >                                       
                                    <div><span className="square-width cA0A0A0"><div className="shortcode">AA</div></span></div>
                                    </Tooltip>                            
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="Add to Known Directory"
                                      PopperProps={popperProps}
                                    >                              
                                    <div><span className="square-width cB8B8B8"><div className="shortcode">AD</div></span></div>
                                    </Tooltip>                                    
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="Add to Known MFA Server"
                                      PopperProps={popperProps}
                                    >                                      
                                    <div><span className="square-width cD0D0D0"><div className="shortcode">AM</div></span></div>
                                    </Tooltip>                                  
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="Add to Known Access Control (VPN/gateway/proxy/NAT/firewall/etc.)"
                                     PopperProps={popperProps}
                                    >                                    
                                    <div><span className="square-width cE0E0E0"><div className="shortcode">AI</div></span></div>
                                    </Tooltip>
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="Posture Archive"
                                      PopperProps={popperProps}
                                    >
                                    <div><span className="square-width cC0C0C0"><div className="shortcode-width">&nbsp;PA</div></span></div>
                                    </Tooltip>                                    
                                </td>
                                <td className="grid-container">
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="Add Exception"
                                      PopperProps={popperProps}
                                    >
                                        <div><span className="square-width c585858"><div className="shortcode">AE</div></span></div>
                                    </Tooltip>
                                    
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="Close Incident"
                                      PopperProps={popperProps}
                                    >
                                    <div><span className="square-width c707070"><div className="shortcode">CI</div></span></div>
                                    </Tooltip>                                    
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="Add to Known Internal Public IPs"
                                      PopperProps={popperProps}
                                    >                                  
                                    <div><span className="square-width c888888"><div className="shortcode">AP</div></span></div>
                                    </Tooltip>                                    
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="Add to Known App"
                                      PopperProps={popperProps}
                                    >                                       
                                    <div><span className="square-width cA0A0A0"><div className="shortcode">AA</div></span></div>
                                    </Tooltip>                                    
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="Add to Known Directory"
                                      PopperProps={popperProps}
                                    >                              
                                    <div><span className="square-width cB8B8B8"><div className="shortcode">AD</div></span></div>
                                    </Tooltip>                                    
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="Add to Known MFA Server"
                                      PopperProps={popperProps}
                                    >                                      
                                    <div><span className="square-width cD0D0D0"><div className="shortcode">AM</div></span></div>
                                    </Tooltip>                                    
                                      <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="Add to Known Access Control (VPN/gateway/proxy/NAT/firewall/etc.)"
                                        PopperProps={popperProps}
                                    >                                    
                                    <div><span className="square-width cE0E0E0"><div className="shortcode">AI</div></span></div>
                                    </Tooltip>                                   
                                </td>
                                <td className="grid-container">
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="Create"
                                      PopperProps={popperProps}
                                    >
                                     <div><span className="square c585858"><div className="shortcode-width">C</div></span></div>
                                    </Tooltip>
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="Assign"
                                      PopperProps={popperProps}
                                    >
                                    <div><span className="square-width c787878"><div className="shortcode">AS</div></span></div>
                                    </Tooltip>                               
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="Close Ticket"
                                      PopperProps={popperProps}
                                    >                                
                                    <div><span className="square-width c989898"><div className="shortcode">CT</div></span></div>
                                    </Tooltip>                                   
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="View"
                                      PopperProps={popperProps}
                                    >
                                    <div><span className="square cB8B8B8"><div className="shortcode-width">V</div></span></div>
                                    </Tooltip>
                                    
                                </td>
                                <td className="grid-container">
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="Create"
                                      PopperProps={popperProps}
                                    >
                                     <div><span className="square c585858"><div className="shortcode-width">C</div></span></div>
                                    </Tooltip>
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="Edit"
                                      PopperProps={popperProps}
                                    >
                                    <div><span className="square c787878"><div className="shortcode-width">E</div></span></div>
                                    </Tooltip>
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="Delete"
                                      PopperProps={popperProps}
                                    >
                                    <div><span className="square c989898"><div className="shortcode-width">D</div></span></div>
                                    </Tooltip>
                                    
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="View"
                                      PopperProps={popperProps}
                                    >
                                    <div><span className="square cB8B8B8"><div className="shortcode-width">V</div></span></div>
                                    </Tooltip>
                                    
                                </td>
                            </tr>

                            <tr>
                                <td>Analyst</td>
                                <td className="grid-container">
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="View"
                                      PopperProps={popperProps}
                                    >
                                    <div><span className="square cB8B8B8"><div className="shortcode-width">V</div></span></div>
                                    </Tooltip>
                                    
                                </td>
                                <td>X</td>
                                <td className="grid-container">
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="View"
                                      PopperProps={popperProps}
                                    >
                                    <div><span className="square cB8B8B8"><div className="shortcode-width">V</div></span></div>
                                    </Tooltip>
                                    
                                </td>
                                <td className="grid-container">
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="View"
                                      PopperProps={popperProps}
                                    >
                                    <div><span className="square cB8B8B8"><div className="shortcode-width">V</div></span></div>
                                    </Tooltip>
                                    
                                </td>
                                <td className="grid-container">
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="View"
                                      PopperProps={popperProps}
                                    >
                                    <div><span className="square cB8B8B8"><div className="shortcode-width">V</div></span></div>
                                    </Tooltip>
                                    
                                </td>
                                <td className="grid-container">
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="View"
                                      PopperProps={popperProps}
                                    >
                                    <div><span className="square cB8B8B8"><div className="shortcode-width">V</div></span></div>
                                    </Tooltip>
                                    
                                </td>
                                <td className="grid-container">
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="View"
                                      PopperProps={popperProps}
                                    >
                                    <div><span className="square cB8B8B8"><div className="shortcode-width">V</div></span></div>
                                    </Tooltip>
                                    
                                </td>
                                <td className="grid-container">
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="View"
                                      PopperProps={popperProps}
                                    >
                                    <div><span className="square cB8B8B8"><div className="shortcode-width">V</div></span></div>
                                    </Tooltip>
                                    
                                </td>
                                <td className="grid-container">
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="View"
                                      PopperProps={popperProps}
                                    >
                                    <div><span className="square cB8B8B8"><div className="shortcode-width">V</div></span></div>
                                    </Tooltip>
                                    
                                </td>
                                <td>X</td>
                                <td>X</td>
                                <td>X</td>
                                <td>X</td>
                                <td>X</td>
                                <td>X</td>
                                <td className="grid-container">
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="Create"
                                      PopperProps={popperProps}
                                    >
                                     <div><span className="square c585858"><div className="shortcode-width">C</div></span></div>
                                    </Tooltip>
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="Assign"
                                      PopperProps={popperProps}
                                    >
                                    <div><span className="square-width c787878"><div className="shortcode">AS</div></span></div>
                                    </Tooltip>                                   
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="Close Ticket"
                                      PopperProps={popperProps}
                                    >                                
                                    <div><span className="square-width c989898"><div className="shortcode">CT</div></span></div>
                                    </Tooltip>                                   
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="View"
                                      PopperProps={popperProps}
                                    >
                                    <div><span className="square cB8B8B8"><div className="shortcode-width">V</div></span></div>
                                    </Tooltip>
                                    
                                </td>
                                <td className="grid-container">
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="View"
                                      PopperProps={popperProps}
                                    >
                                    <div><span className="square cB8B8B8"><div className="shortcode-width">V</div></span></div>
                                    </Tooltip>
                                    
                                </td>
                            </tr>

                            <tr>
                                <td>Security Operator</td>
                                <td className="grid-container">
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="View"
                                      PopperProps={popperProps}
                                    >
                                    <div><span className="square cB8B8B8"><div className="shortcode-width">V</div></span></div>
                                    </Tooltip>
                                    
                                </td>
                                <td>X</td>
                                <td className="grid-container">
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="Create"
                                      PopperProps={popperProps}
                                    >
                                     <div><span className="square c585858"><div className="shortcode-width">C</div></span></div>
                                    </Tooltip>
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="Edit"
                                      PopperProps={popperProps}
                                    >
                                    <div><span className="square c787878"><div className="shortcode-width">E</div></span></div>
                                    </Tooltip>
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="Delete"
                                      PopperProps={popperProps}
                                    >
                                    <div><span className="square c989898"><div className="shortcode-width">D</div></span></div>
                                    </Tooltip>
                                    
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="View"
                                      PopperProps={popperProps}
                                    >
                                    <div><span className="square cB8B8B8"><div className="shortcode-width">V</div></span></div>
                                    </Tooltip>
                                    
                                </td>
                                <td className="grid-container">
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="Create"
                                      PopperProps={popperProps}
                                    >
                                     <div><span className="square c585858"><div className="shortcode-width">C</div></span></div>
                                    </Tooltip>
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="Edit"
                                      PopperProps={popperProps}
                                    >
                                    <div><span className="square c787878"><div className="shortcode-width">E</div></span></div>
                                    </Tooltip>
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="Delete"
                                      PopperProps={popperProps}
                                    >
                                    <div><span className="square c989898"><div className="shortcode-width">D</div></span></div>
                                    </Tooltip>
                                    
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="View"
                                      PopperProps={popperProps}
                                    >
                                    <div><span className="square cB8B8B8"><div className="shortcode-width">V</div></span></div>
                                    </Tooltip>
                                    
                                </td>
                                <td className="grid-container">
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="Create"
                                      PopperProps={popperProps}
                                    >
                                     <div><span className="square c585858"><div className="shortcode-width">C</div></span></div>
                                    </Tooltip>
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="Edit"
                                      PopperProps={popperProps}
                                    >
                                    <div><span className="square c787878"><div className="shortcode-width">E</div></span></div>
                                    </Tooltip>
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="Delete"
                                      PopperProps={popperProps}
                                    >
                                    <div><span className="square c989898"><div className="shortcode-width">D</div></span></div>
                                    </Tooltip>
                                    
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="View"
                                      PopperProps={popperProps}
                                    >
                                    <div><span className="square cB8B8B8"><div className="shortcode-width">V</div></span></div>
                                    </Tooltip>
                                    
                                </td>
                                <td className="grid-container">
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="Create"
                                      PopperProps={popperProps}
                                    >
                                     <div><span className="square c585858"><div className="shortcode-width">C</div></span></div>
                                    </Tooltip>
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="Edit"
                                      PopperProps={popperProps}
                                    >
                                    <div><span className="square c787878"><div className="shortcode-width">E</div></span></div>
                                    </Tooltip>
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="Delete"
                                      PopperProps={popperProps}
                                    >
                                    <div><span className="square c989898"><div className="shortcode-width">D</div></span></div>
                                    </Tooltip>
                                    
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="View"
                                      PopperProps={popperProps}
                                    >
                                    <div><span className="square cB8B8B8"><div className="shortcode-width">V</div></span></div>
                                    </Tooltip>
                                    
                                </td>
                                <td className="grid-container">
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="Create"
                                      PopperProps={popperProps}
                                    >
                                     <div><span className="square c585858"><div className="shortcode-width">C</div></span></div>
                                    </Tooltip>
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="Edit"
                                      PopperProps={popperProps}
                                    >
                                    <div><span className="square c787878"><div className="shortcode-width">E</div></span></div>
                                    </Tooltip>
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="Delete"
                                      PopperProps={popperProps}
                                    >
                                    <div><span className="square c989898"><div className="shortcode-width">D</div></span></div>
                                    </Tooltip>
                                    
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="View"
                                      PopperProps={popperProps}
                                    >
                                    <div><span className="square cB8B8B8"><div className="shortcode-width">V</div></span></div>
                                    </Tooltip>
                                    
                                </td>
                                <td>X</td>
                                <td className="grid-container">
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="Create"
                                      PopperProps={popperProps}
                                    >
                                     <div><span className="square c585858"><div className="shortcode-width">C</div></span></div>
                                    </Tooltip>
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="Edit"
                                      PopperProps={popperProps}
                                    >
                                    <div><span className="square c787878"><div className="shortcode-width">E</div></span></div>
                                    </Tooltip>
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="Delete"
                                      PopperProps={popperProps}
                                    >
                                    <div><span className="square c989898"><div className="shortcode-width">D</div></span></div>
                                    </Tooltip>
                                    
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="View"
                                      PopperProps={popperProps}
                                    >
                                    <div><span className="square cB8B8B8"><div className="shortcode-width">V</div></span></div>
                                    </Tooltip>
                                    
                                </td>
                                <td>X</td>
                                <td>X</td>
                                <td>X</td>
                                <td>X</td>
                                <td className="grid-container">
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="Add Exception"
                                      PopperProps={popperProps}
                                    >
                                        <div><span className="square-width c585858"><div className="shortcode">AE</div></span></div>
                                    </Tooltip>
                                    
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="Close Incident"
                                      PopperProps={popperProps}
                                    >
                                    <div><span className="square-width c707070"><div className="shortcode">CI</div></span></div>
                                    </Tooltip>                                    
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="Add to Known Internal Public IPs"
                                      PopperProps={popperProps}
                                    >                                  
                                    <div><span className="square-width c888888"><div className="shortcode">AP</div></span></div>
                                    </Tooltip>                                    
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="Add to Known App"
                                      PopperProps={popperProps}
                                    >                                       
                                    <div><span className="square-width cA0A0A0"><div className="shortcode">AA</div></span></div>
                                    </Tooltip>                                    
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="Add to Known Directory"
                                      PopperProps={popperProps}
                                    >                              
                                    <div><span className="square-width cB8B8B8"><div className="shortcode">AD</div></span></div>
                                    </Tooltip>                                    
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="Add to Known MFA Server"
                                      PopperProps={popperProps}
                                    >                                      
                                    <div><span className="square-width cD0D0D0"><div className="shortcode">AM</div></span></div>
                                    </Tooltip>                                    
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="Add to Known Access Control (VPN/gateway/proxy/NAT/firewall/etc.)"
                                      PopperProps={popperProps}
                                    >                                    
                                    <div><span className="square-width cE0E0E0"><div className="shortcode">AI</div></span></div>
                                    </Tooltip>                                   
                                      <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="Posture Archive"
                                      PopperProps={popperProps}
                                    >
                                    <div><span className="square-width cC0C0C0"><div className="shortcode-width">&nbsp;PA</div></span></div>
                                    </Tooltip> 
                                </td>
                                <td className="grid-container">
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="Add Exception"
                                      PopperProps={popperProps}
                                    >
                                        <div><span className="square-width c585858"><div className="shortcode">AE</div></span></div>
                                    </Tooltip>
                                    
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="Close Incident"
                                      PopperProps={popperProps}
                                    >
                                    <div><span className="square-width c707070"><div className="shortcode">CI</div></span></div>
                                    </Tooltip>                                    
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="Add to Known Internal Public IPs"
                                      PopperProps={popperProps}
                                    >                                  
                                    <div><span className="square-width c888888"><div className="shortcode">AP</div></span></div>
                                    </Tooltip>                                    
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="Add to Known App"
                                      PopperProps={popperProps}
                                    >                                       
                                    <div><span className="square-width cA0A0A0"><div className="shortcode">AA</div></span></div>
                                    </Tooltip>                                    
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="Add to Known Directory"
                                      PopperProps={popperProps}
                                    >                              
                                    <div><span className="square-width cB8B8B8"><div className="shortcode">AD</div></span></div>
                                    </Tooltip>                                    
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="Add to Known MFA Server"
                                      PopperProps={popperProps}
                                    >                                      
                                    <div><span className="square-width cD0D0D0"><div className="shortcode">AM</div></span></div>
                                    </Tooltip>                                    
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="Add to Known Access Control (VPN/gateway/proxy/NAT/firewall/etc.)"
                                      PopperProps={popperProps}
                                    >                                    
                                    <div><span className="square-width cE0E0E0"><div className="shortcode">AI</div></span></div>
                                    </Tooltip>                                   
                                </td>
                                <td className="grid-container">
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="Create"
                                      PopperProps={popperProps}
                                    >
                                     <div><span className="square c585858"><div className="shortcode-width">C</div></span></div>
                                    </Tooltip>
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="Assign"
                                      PopperProps={popperProps}
                                    >
                                    <div><span className="square-width c787878"><div className="shortcode">AS</div></span></div>
                                    </Tooltip>                                   
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="Close Ticket"
                                      PopperProps={popperProps}
                                    >                                
                                    <div><span className="square-width c989898"><div className="shortcode">CT</div></span></div>
                                    </Tooltip>                                   
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="View"
                                      PopperProps={popperProps}
                                    >
                                    <div><span className="square cB8B8B8"><div className="shortcode-width">V</div></span></div>
                                    </Tooltip>
                                    
                                </td>
                                <td className="grid-container">
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="Create"
                                      PopperProps={popperProps}
                                    >
                                     <div><span className="square c585858"><div className="shortcode-width">C</div></span></div>
                                    </Tooltip>
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="Edit"
                                      PopperProps={popperProps}
                                    >
                                    <div><span className="square c787878"><div className="shortcode-width">E</div></span></div>
                                    </Tooltip>
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="Delete"
                                      PopperProps={popperProps}
                                    >
                                    <div><span className="square c989898"><div className="shortcode-width">D</div></span></div>
                                    </Tooltip>
                                    
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="View"
                                      PopperProps={popperProps}
                                    >
                                    <div><span className="square cB8B8B8"><div className="shortcode-width">V</div></span></div>
                                    </Tooltip>
                                    
                                </td>
                            </tr>

                            <tr>
                                <td>Incident Responder</td>
                                <td className="grid-container">
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="View"
                                      PopperProps={popperProps}
                                    >
                                    <div><span className="square cB8B8B8"><div className="shortcode-width">V</div></span></div>
                                    </Tooltip>
                                    
                                </td>
                                <td>X</td>
                                <td className="grid-container">
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="View"
                                      PopperProps={popperProps}
                                    >
                                    <div><span className="square cB8B8B8"><div className="shortcode-width">V</div></span></div>
                                    </Tooltip>
                                    
                                </td>
                                <td className="grid-container">
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="View"
                                      PopperProps={popperProps}
                                    >
                                    <div><span className="square cB8B8B8"><div className="shortcode-width">V</div></span></div>
                                    </Tooltip>
                                    
                                </td>
                                <td className="grid-container">
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="View"
                                      PopperProps={popperProps}
                                    >
                                    <div><span className="square cB8B8B8"><div className="shortcode-width">V</div></span></div>
                                    </Tooltip>
                                    
                                </td>
                                <td className="grid-container">
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="View"
                                      PopperProps={popperProps}
                                    >
                                    <div><span className="square cB8B8B8"><div className="shortcode-width">V</div></span></div>
                                    </Tooltip>
                                    
                                </td>
                                <td className="grid-container">
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="View"
                                      PopperProps={popperProps}
                                    >
                                    <div><span className="square cB8B8B8"><div className="shortcode-width">V</div></span></div>
                                    </Tooltip>
                                    
                                </td>
                                <td>X</td>
                                <td className="grid-container">
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="View"
                                      PopperProps={popperProps}
                                    >
                                    <div><span className="square cB8B8B8"><div className="shortcode-width">V</div></span></div>
                                    </Tooltip>
                                    
                                </td>
                                <td>X</td>
                                <td>X</td>
                                <td>X</td>
                                <td>X</td>
                                <td className="grid-container">
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="Close Incident"
                                      PopperProps={popperProps}
                                    >
                                    <div><span className="square-width c707070"><div className="shortcode">CI</div></span></div>
                                    </Tooltip>                                    
                                </td>
                                <td className="grid-container">
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="Close Incident"
                                      PopperProps={popperProps}
                                    >
                                    <div><span className="square-width c707070"><div className="shortcode">CI</div></span></div>
                                    </Tooltip>                                    
                                </td>
                                <td className="grid-container">
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="Create"
                                      PopperProps={popperProps}
                                    >
                                     <div><span className="square c585858"><div className="shortcode-width">C</div></span></div>
                                    </Tooltip>
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="Assign"
                                      PopperProps={popperProps}
                                    >
                                    <div><span className="square-width c787878"><div className="shortcode">AS</div></span></div>
                                    </Tooltip>                                   
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="Close Ticket"
                                      PopperProps={popperProps}
                                    >                                
                                    <div><span className="square-width c989898"><div className="shortcode">CT</div></span></div>
                                    </Tooltip>                                   
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="View"
                                      PopperProps={popperProps}
                                    >
                                    <div><span className="square cB8B8B8"><div className="shortcode-width">V</div></span></div>
                                    </Tooltip>
                                    
                                </td>
                                <td className="grid-container">
                                    <Tooltip classes={{tooltip:'generic-tooltip-popper'}} title="View"
                                      PopperProps={popperProps}
                                    >
                                    <div><span className="square cB8B8B8"><div className="shortcode-width">V</div></span></div>
                                    </Tooltip>
                                    
                                </td>
                            </tr>

                        </tbody>

                        {/* </div> */}
                    </table>
                </div>

                <div className="notes">
                    <strong>Legends:</strong>
                    <Grid container rowSpacing={1} columnSpacing={4} style={{ display: 'flex', flexDirection: 'column' }}>
                        <Grid item container spacing={1} xs={12}>
                            <Grid item xs={3}>
                                <div className="action-list">
                                    <li>
                                        <span className="square c585858"><div className="legends-lable">C</div></span>
                                        - Create</li>
                                    <li>
                                        <span className="square c989898"><div className="legends-lable">D</div></span>
                                        - Delete</li>
                                    <li>
                                        <span className="square cB8B8B8"><div className="legends-lable">V</div></span>
                                        - View</li> 
                                    <li>
                                        <span className="square c787878"><div className="legends-lable">E</div></span>
                                        - Edit</li>
                                </div>

                            </Grid>

                            <Grid item xs={3}>
                                <div className="action-list">

                                    <li>
                                        <span className="square-width c585858"><div className="legends-lable-width">AE</div></span>
                                        - Add Exception</li>
                                    <li>
                                        <span className="square-width c707070"><div className="legends-lable-width">CI</div></span>
                                        - Close Incident
                                    </li>
                                    <li>
                                        <span className="square-width cA0A0A0"><div className="legends-lable-width">AA</div></span>
                                        - Add to Known App
                                    </li>
                                    <li>
                                        <span className="square-width c888888"><div className="legends-lable-width">AP</div></span>
                                        - Add to Known Internal Public IPs
                                    </li>
                                </div>
                            </Grid>
                            <br />

                            <Grid item xs={3}>
                                <div className="action-list">
                                    <li>
                                        <span className="square-width cB8B8B8"><div className="legends-lable-width">AD</div></span>
                                        - Add to Known Directory
                                    </li>
                                    <li>
                                        <span className="square-width cD0D0D0"><div className="legends-lable-width">AM</div></span>
                                        - Add to Known MFA Server
                                    </li>
                                    <li>
                                        <span className="square-width c787878"><div className="legends-lable-width">AS</div></span>
                                        - Assign
                                    </li>
                                    <li>
                                        <span className="square-width c989898"><div className="legends-lable-width">CT</div></span>
                                        - Close Ticket
                                    </li>
                                </div>
                            </Grid>
                            <Grid item xs={3}>

                                <div className="action-list">
                                    <li>
                                        <span className="square-width cE0E0E0"><div className="legends-lable-width">AI</div></span>
                                        - Add to Known Access Control (VPN/gateway/proxy/NAT/firewall/etc.)
                                    </li>
                                    <li>
                                        <span className="square-width c787878"><div className="legends-lable-width">DW</div></span>
                                        - Download
                                    </li>
                                    <li>
                                        <span className="square-width cC0C0C0"><div className="legends-lable-width">PA</div></span>
                                        - Posture Archive
                                    </li>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </div>

            <div className="popup_footer">
                {/* <button style={{display:'inline'}} type="button" className="button_gray width25per margintop10" onClick={closeConfirm}>No</button> */}
                {/* <div style={{ position: "fixed", width: "80%" }}> */}
                <button style={{ float: 'right' }} type="button" className="button_main width25per" onClick={closeConfirm}>Close</button>
                {/* </div> */}
            </div>
        </div>
    </Popup>
}