import { CustomCellRendererProps } from "@ag-grid-community/react";
import { FunctionComponent } from "react";

import React from "react";
import { findDifferenceInDays } from "../../../../utils/util-methods";
import { Api } from "../../../../components/Axios";
import moment from "moment";

interface IdentitySystemFlowCountCellRendererProps extends CustomCellRendererProps {
    widgetData, setFlowsTrendResponseData, setFlowsChartDetails,
    setOpenFlow, getSearchFields, appliedFilterTimestamp,
    setZoomLevel, updatedExtremesRef, risk, getChartTitle
}

export const IdentitySystemFlowCountCell: FunctionComponent<IdentitySystemFlowCountCellRendererProps> =
    ({ widgetData, data, setFlowsTrendResponseData, setFlowsChartDetails,
        setOpenFlow, getSearchFields, appliedFilterTimestamp, getChartTitle,
        setZoomLevel, updatedExtremesRef, risk }) => {
        const item = data;

        const handleFlowClick = (event: any, data: any) => {
            setOpenFlow(true);
            setZoomLevel('hourly');
            updatedExtremesRef.current = { min: null, max: null };
            let { id: identityName, is_public = false } = data;
            const currentTime = +new Date();
            const cachedFlowData: any = sessionStorage.getItem(
                `directory-flow-trend-${identityName}`
            );

            const encodedIdentityName = encodeURIComponent(identityName);

            const directoryParams = `&is_directory_public=${is_public}`;
            sessionStorage.setItem('flowDataDirectory', JSON.stringify(data));
            const flowTrendURL = `/assets/flowcounts?search_type=directory&search_value1=${encodedIdentityName}${directoryParams}`;


            const searchHeaders = {
                headers: {
                    search_fields: getSearchFields()
                }
            };

            Api.get(flowTrendURL)
                .then((res: any) => {
                    if (!res?.data?.length || res?.data?.length <= 0) {
                        setFlowsTrendResponseData({
                            flows: [],
                            expiry_time: currentTime + 600000
                        });

                        // Chart header and color
                        const chartTitle = getChartTitle(identityName);
                        setFlowsChartDetails({
                            chartTitle, itemData: {
                                queryData: `dir_name:` + encodedIdentityName
                            }
                        });

                        return;
                    }

                    let result = JSON.parse(JSON.stringify(res?.data)) || [];
                    result.sort((a: any, b: any) =>
                        a?.time < b?.time ? -1 : 1
                    );
                    const cachedFlows = {
                        flows: result,
                        identityName,
                        risk,
                        expiry_time: currentTime + 600000
                    };
                    setFlowsTrendResponseData(cachedFlows);

                    // Chart header and color
                    const startFromDate = Math.max(
                        moment(result?.[0]?.time).valueOf(),
                        moment().subtract(90, 'days').valueOf()
                    );


                    const chartTitle = appliedFilterTimestamp()?.length > 0 ? getChartTitle(
                        identityName,
                        appliedFilterTimestamp()[0],
                        appliedFilterTimestamp()[1],

                    ) : getChartTitle(
                        identityName,
                        startFromDate
                    );

                    setFlowsChartDetails({
                        chartTitle, itemData: {
                            queryData: `dir_name:` + encodedIdentityName
                        }
                    });

                    sessionStorage.setItem(
                        `directory-flow-trend-${identityName}`,
                        JSON.stringify(cachedFlows)
                    );
                })
                .catch((er) => {
                    console.log(er);
                });
        };


        return <>
            {item.flow_count === 0 ? (
                <div style={{ display: 'flex', justifyContent: 'center' }}>1</div>
            ) : (
                (findDifferenceInDays(item.latest_time) <= (widgetData?.posture_view_date_range || 60)) ?
                    <div
                        style={{ display: 'flex', justifyContent: 'center' }}
                        className={`${item.flow_count > 1 && 'shadowbox'
                            }`}
                        id={item._id?.toString()}
                        onClick={(event) => {
                            if (item.flow_count > 1) {
                                handleFlowClick(event, {
                                    id: item._id?.toString(),
                                    risk: undefined,
                                    is_public: item?.is_public
                                });
                            }
                        }}
                    >
                        {item.flow_count
                            ?.toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    </div> : <div id={item._id?.toString()}
                        style={{ display: 'flex', justifyContent: 'center' }}
                    >
                        {item.flow_count
                            ?.toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    </div>
            )}</>
    }