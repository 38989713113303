import type { CustomCellRendererProps } from "@ag-grid-community/react";
import { Tooltip } from "@mui/material";
import React, { FunctionComponent } from 'react';
import InfoIcon from '@mui/icons-material/Info';


interface AccessAssetCellRendererProps extends CustomCellRendererProps { }

export const AccessAssetCell: FunctionComponent<AccessAssetCellRendererProps> = ({ data
}: AccessAssetCellRendererProps) => {
    let asset_hostsList: any = [];
    const item = data;
    item.asset_host_detail && item.asset_host_detail.forEach((group: any) => {
        if (group.hostname !== "" && group.hostname !== undefined && group.hostname !== null &&
            group.ip_port !== "" && group.ip_port !== undefined && group.ip_port !== null) {
            const ip_port = group?.ip && group.ip.startsWith('127.') ? 'N.A.' : group.ip_port;
            const hostname = group.hostname.startsWith('127.') ? 'N.A.' : group.hostname;
            if (item.asset_host_detail.length === 1 && group.hostname?.toLowerCase() === "unknown") {
                asset_hostsList.push({ "hostname": hostname, "ip_port": ip_port })
            }
            else {
                if (group.hostname?.toLowerCase() !== "unknown")
                    asset_hostsList.push({ "hostname": hostname, "ip_port": ip_port })
            }
        }
    })

    return <>
        <div className="copy_field width100 dot_outer" style={{ display: 'flex' }}>
            <Tooltip
                classes={{ tooltip: 'posture-root-column-container scrollbar-container' }}
                title={
                    <div className="service_list2">
                        <h2>
                            <span className="ellipsis_idassetname tooltip_ellipses" title={item.asset?.toString()}>{item.asset?.toString()}</span>
                            <div className="copy_idassetname tooltip_copy_title" title="Copy this text to Clipboard" onClick={() => navigator.clipboard.writeText(item.asset)}></div>
                        </h2>
                        {asset_hostsList.length > 0 ?
                            <table>
                                <tr>
                                    <th className="align_left">Hostname</th>
                                    <th className="align_left">IP:Port</th>
                                </tr>
                                {asset_hostsList.map((group: any, index: any) => {
                                    if (index < 5) {
                                        return (<tr>
                                            <td title={group.hostname} className="hostname_td align_left">
                                                <span className="ellipsis_idassetname">{group.hostname}</span>
                                                <div className="copy_idassetname float-right-margin-none" title="Copy this text to Clipboard" onClick={() => navigator.clipboard.writeText(group.hostname)}></div>
                                            </td>
                                            <td className="align_left">{group.ip_port}</td>
                                        </tr>)
                                    }
                                })}
                                {asset_hostsList.length > 5 ?
                                    <tr>
                                        <td colSpan={2} className="hostname_td align_left">
                                            <span className="ellipsis_idassetname">...</span>
                                        </td>
                                    </tr> : null}
                            </table>
                            : null
                        }
                    </div>
                }>
                <div className="ellipsis_access_asset droplist align_left">
                    <span style={{ display: 'inline-block', maxWidth: window.matchMedia("(min-width: 1901px)").matches ? '410px' : '250px', textOverflow: 'ellipsis', overflow: 'hidden' }}>{item.asset}</span>
                    <span><InfoIcon style={{ fontSize: 14, color: '#d4d8e1', marginTop: 2, marginLeft: 2, transform: 'skewX(-10deg)' }} /></span>
                </div>
            </Tooltip>
            <div className="copy_access_asset" title="Copy this text to Clipboard" onClick={() => navigator.clipboard.writeText(item.asset)}></div>
        </div></>
}