import { Tooltip } from '@mui/material';
import './AMTooltipAd.scss';
import { CSSProperties, ReactNode, useEffect } from 'react';
import { TooltipProps } from 'react-tooltip';
import React from 'react';

interface IAMTooltipAdProps {
    children: React.ReactElement<any, any>,
    title: NonNullable<ReactNode>,
    classes?: {
        tooltip?: string,
        popper?: string
    }

}

interface IAMLabelProps {
    children: ReactNode,
    title: string,
    labelClasses?: 'headingLabel' | 'textLabel',
    customLabelClass?: string,
    style?: CSSProperties,
    labelContainerProps?: {
        classes?: string
    }
}

export const AMLabel = ({ children, title, labelClasses, customLabelClass, style, labelContainerProps }: IAMLabelProps) => {
    return <Tooltip
        classes={{ tooltip: 'am-label-generic-tooltip-popper scrollbar-container ' }}
        PopperProps={{
            className: 'popper-class', style: { zIndex: 100000000, maxWidth: '28%' }
        }}
        title={<span>{title}</span>}
    >
        <div className={'am-label-container ' + labelContainerProps?.classes}>
            <span className={customLabelClass + ' ' + labelClasses}
                style={style}
            >{children}</span></div>
    </Tooltip >
}

export const AMToolTipAd = ({ children, title, classes }: IAMTooltipAdProps) => {
    return <Tooltip    
        classes={{ tooltip: 'am-label-generic-tooltip-popper scrollbar-container ' + classes?.tooltip, popper: ' ' + classes?.popper }}
        PopperProps={{
            className: 'popper-class ', style: { zIndex: 10000000, maxWidth: '28%' }
        }}
        title={title}
    >{children}
    </Tooltip >
}