import ModalPopup from '../AMModalPopup/ModalPopup';

export const ImportWarning = ({ handleYes, handleNo }) => {
	return (
		<ModalPopup
			onCloseHandler={handleNo}
			title={'Import Search Filters'}
			isOpen={true}
			zIndexCustom={10001}
		>
			<div className='margintop10' style={{ fontSize: 14 }}>
				Import search filters will overwrite any existing search filters
				that you have added. <br></br>
				<br></br>Do you want to continue ?
			</div>
			<div className='margintop10 popup-container-button-container dialog-button'>
				<button
					type='button'
					onClick={handleNo}
					className={'button_gray marginbottom10imp margintop20'}
					style={{ display: 'inline-block', margin: 0, width: '20%' }}
				>
					No
				</button>
				<button
					style={{ display: 'inline-block', margin: 0, width: '20%' }}
					onClick={handleYes}
					type='button'
					className={
						'float_right button_styled marginbottom10imp margintop20'
					}
				>
					Yes
				</button>
			</div>
		</ModalPopup>
	);
};
