import React from "react";
import { AccountTakeoverState } from "../../../../types/playbooks-config"

const AccountTakeoverConfigview = ({ config: { account_takeover_params }, params }: any) => {
    if (params?.isPlaybookListing) {
        return <li><span>Detect suspicious access resulting from "Impossible Travel" by analyzing the following criteria:
            {account_takeover_params?.displacementFlag && <> Unusual Geolocation (latitude, longitude)</>
                || account_takeover_params?.distanceTraveledFlag && <>High Displacement (distance travelled per unit of time)</>
                || account_takeover_params?.newIpRangeFlag && <>Unusual IP Address</>
                || account_takeover_params?.newIspFlag && <> Unusual Internet Service Provider (ISP)</>
            }
        </span></li>
    } else {
        return <li>
            <span>Detect suspicious access resulting from "Impossible Travel" by analyzing the following criteria:</span>
            <ul className='ml-1'>
                {
                    account_takeover_params?.displacementFlag &&
                    <li>Unusual Geolocation (latitude, longitude)</li>}
                {
                    account_takeover_params?.distanceTraveledFlag &&
                    <li>High Displacement (distance travelled per unit of time)</li>}
                {
                    account_takeover_params?.newIpRangeFlag &&
                    <li>Unusual IP Address</li>}
                {
                    account_takeover_params?.newIspFlag && <li>Unusual Internet Service Provider (ISP)</li>}

            </ul>
        </li>
    }

}
export default AccountTakeoverConfigview;