import React, { useEffect, useState } from "react";
import './directory-config.css';
import { BaseProps } from "../../constants/types";
import { ADGoldenTicketState } from "../../../../types/playbooks-config";

export const ADGoldenTicket = ({ parentState, isSavedOnce, getState, defaultState, formType, getExpression, ruleType }: BaseProps<ADGoldenTicketState>) => {
    const [state, setState] = useState<ADGoldenTicketState>(defaultState);
    const [checked, setChecked] = React.useState<any>({
        kb_ticket_max_age: isSavedOnce ? (parentState.dir_params.kb_ticket_max_age ? true : false) :
            (state.dir_params.kb_ticket_max_age ? true : false),
        encryption_algo_checked: isSavedOnce ? (parentState.dir_params.encryption_algo_checked) : (state.dir_params.kb_ticket_max_age)
    });

    useEffect(() => {
        getState({
            dir_params: {
                kb_ticket_max_age: checked?.kb_ticket_max_age ? state?.dir_params?.kb_ticket_max_age : undefined,
                param_thresholds: state?.dir_params?.param_thresholds,
                encryption_algo_checked: checked?.encryption_algo_checked
            }
        })
    }, [state, checked])

    useEffect(() => {
        if (defaultState) {
            setState(defaultState);
            setChecked({
                kb_ticket_max_age: (defaultState.dir_params.kb_ticket_max_age ? true : false),
                encryption_algo_checked: (defaultState.dir_params.encryption_algo_checked ? true : false),
            })
        }
    }, [defaultState])

    useEffect(() => {
        if (isSavedOnce) {
            setState(parentState);
            setChecked({
                kb_ticket_max_age: (parentState.dir_params.kb_ticket_max_age ? true : false),
                encryption_algo_checked: (parentState.dir_params.encryption_algo_checked ? true : false),
            })
        }
    }, [isSavedOnce])

    const handleNumAuthFail = (e: React.ChangeEvent<HTMLInputElement>) => {
        setState({
            dir_params: {
                ...state?.dir_params,
                kb_ticket_max_age: parseInt(e.target.value)
            }
        })
        setChecked({ ...checked, kb_ticket_max_age: e.target.value ? true : false })
    }


    const onCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>, keyType: string) => {
        setChecked({ ...checked, [keyType]: e.target.checked })
    }

    return <>
        <div className="dir-main-container dir-brute-force  ">
            <div >
                <div className="dir-container-row bold-text">
                    <label className="playbook-config-title marginBottom0">
                        An AD will be marked as under AD Golden Ticket attack when:</label>
                </div>
                <div className="dir-container-row brute_force_justify_none_gap_5" style={{ gap: 10 }}>
                    <input type="checkbox" id="num-attempts" onChange={(e) => onCheckboxChange(e, 'kb_ticket_max_age')}
                        checked={checked?.kb_ticket_max_age}
                        defaultChecked={checked?.kb_ticket_max_age}
                        className={(['edit', 'view'].includes(formType || '') ? ' disable-config-item margintop20' : 'margintop20')}
                    />
                    <label
                        htmlFor="num-attempts"
                        className={(formType && ['edit', 'view'].includes(formType)) ? 'disable-config-item' : ''}
                    >
                        The same Kerberos ticket has been used for the current account for more than
                    </label>
                    {
                        (formType && ['edit', 'view'].includes(formType)) ? <label className="view-label marginbottom10">
                            {
                                state?.dir_params?.kb_ticket_max_age || ''
                            }</label> :
                            <input type="number" className="margintop10" onChange={handleNumAuthFail} name="kb_ticket_max_age" value={
                                state?.dir_params?.kb_ticket_max_age || ''
                            } />}
                    <label>hrs</label>
                </div>
                <div style={{ textAlign: 'center', fontWeight: 'bold' }} className="font14 margintop20">OR</div>
                <div className="dir-container-row brute_force_justify_none_gap_5">
                    <input type="checkbox" id="num-attempts-weak-encrytion" onChange={(e) => onCheckboxChange(e, 'encryption_algo_checked')}
                        checked={checked?.encryption_algo_checked}
                        defaultChecked={checked?.encryption_algo_checked}
                        className={(['edit', 'view'].includes(formType || '') ? ' disable-config-item margintop20' : 'margintop20')}
                    />
                    <label
                        htmlFor="num-attempts-weak-encrytion"
                        className={(formType && ['edit', 'view'].includes(formType)) ? 'disable-config-item' : ''}
                    >
                        Kerberos service ticket requested with weak encryption
                    </label>
                </div>
            </div>
        </div>
    </>
}