import { useEffect, useState } from "react";
import { IssueObj } from "../../../../types/response-types";

export const useIssueGrid = (gridRef, udpateClosedResolvedCells, responseData) => {

    useEffect(() => {
        const gridApi = gridRef?.current?.api;
        if (gridApi) {
            // Attach event listeners to check for data changes
            gridApi.addEventListener("firstDataRendered", udpateClosedResolvedCells);
            gridApi.addEventListener("modelUpdated", udpateClosedResolvedCells);
            gridApi.addEventListener("paginationChanged", udpateClosedResolvedCells);
            gridApi.addEventListener("sortChanged", udpateClosedResolvedCells);
            gridApi.addEventListener("filterChanged", udpateClosedResolvedCells);

            // Cleanup event listeners when component unmounts
            return () => {
                gridApi.removeEventListener("firstDataRendered", udpateClosedResolvedCells);
                gridApi.removeEventListener("modelUpdated", udpateClosedResolvedCells);
                gridApi.removeEventListener("paginationChanged", udpateClosedResolvedCells);
                gridApi.removeEventListener("sortChanged", udpateClosedResolvedCells);
                gridApi.removeEventListener("filterChanged", udpateClosedResolvedCells);
            };
        }
    }, [gridRef?.current?.api, responseData]);
}