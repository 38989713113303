import { CredentialQualityConfigProtocol } from "../../../types/cred-quality-config-types";
import {
    AccessToUnauthCountriesState, CredentialQualityConfigState, DailyDeviationConfig,
    DailyDeviationState, EnumAdAdminState, EnumAdUserState, LackOfMFAConfigState, NTLMRelayAttackState,
    ShadowDirectoryState, SusAttackDisabledState, SusAttackExpiredState, SusAttackLockedState, SuspectedBruteForceState,
    SuspectedDirBotAttackState, SuspectedPassSprayState, UnauthLoginDeviceState, UnauthLoginTimeState, AccountTakeoverState, SuspectedAccountSharingState,
    UnusualUserAccess
} from "../../../types/playbooks-config";
import { CompromisedPasswordConfigState, Feed, WeakPasswordConfigState } from "../../../types/playbooks-config";
import { ProtocolQualityConfigState } from "../../../types/protocol-quality-config-types";
import { RuleType } from "../constants/Constants";
import { IPlaybookRuleState } from "../ref/AdvancedPlaybook/PlaybookTypes";
import { IUnauthPayloadRuleConfig } from "../rules-config/fencing-config/unauthorized-asset-access/UnauthorizedAssetAccessConfig";

const RuleConfigValidate = {
    [RuleType.WEAK_PASSWORD]: (weakPassConfigState: WeakPasswordConfigState) => {
        if (!weakPassConfigState.isTimeChecked && !weakPassConfigState.isDollarChecked) {
            return 'Please select atleast one option in Weak password issue config.';
        }
        else if ((weakPassConfigState.isTimeChecked && (weakPassConfigState.unit === undefined || weakPassConfigState.duration === undefined))) {
            return 'Please enter time to crack in Weak password issue config.';
        }
        else if ((weakPassConfigState.isDollarChecked && weakPassConfigState.dollar === undefined)) {
            return 'Please enter dollars to crack in Weak password issue config.';
        }
        else if (((weakPassConfigState.isDollarChecked && weakPassConfigState.dollar === 0) ||
            (weakPassConfigState.isTimeChecked && weakPassConfigState.unit !== undefined && weakPassConfigState.duration === 0))) {
            return 'Please enter a value greater than 0 in Weak password issue config.';
        }
        return null;
    },
    [RuleType.COMPROMISED_PASSWORD]: (compPassConfigState: CompromisedPasswordConfigState) => {
        if ((!compPassConfigState.commonPasswordFlag && !compPassConfigState.feedPasswordFlag && !compPassConfigState.hashFlag)) {
            return 'Please select atleast one option in Compromised Password issue config.';

        }
        if (compPassConfigState.feedPasswordFlag && compPassConfigState?.feeds) {
            let flagStatus = false;
            for (let i = 0; i < compPassConfigState?.feeds?.length || 0; i++) {
                if (compPassConfigState?.feeds[i].isChecked) {
                    flagStatus = true;
                    break;
                }
            }
            if (flagStatus === false) {
                return 'Please select atleast one option in Compromised Password issue config.';
            }
        }
        if (compPassConfigState.hashFlag && compPassConfigState.hashFeeds) {
            let flagStatus = false;
            for (let i = 0; i < compPassConfigState.hashFeeds.length; i++) {
                if (compPassConfigState.hashFeeds[i].isChecked) {
                    flagStatus = true;
                    break;
                }
            }
            if (flagStatus === false) {
                return 'Please select atleast one option in Compromised Password issue config.';
            }
        }
        return null;
    },
    [RuleType.COMPROMISED_USER]: (compUserConfigState: CompromisedPasswordConfigState) => {
        if (compUserConfigState && compUserConfigState?.feeds) {
            if (compUserConfigState?.feeds?.findIndex((feed: Feed) => feed.isChecked) === -1) {
                return 'Please select atleast one feed in Compromised User issue config.';
            }
        }
        return null;
    },
    [RuleType.AUTH_PROTOCOL_QUALITY]: (state: Partial<ProtocolQualityConfigState>) => {
        if (!state?.commonProcFlag && !state?.legacyProcFlag && !state?.apq_ignore_private_ip) {
            return `Please select atleast one config`
        } else {
            if (state?.commonProcFlag) {
                const isSelected = state?.commonProcValue?.filter((i: CredentialQualityConfigProtocol) => i.isChecked).length;
                if (isSelected == 0) {
                    return `Please select atleast one common protocol`
                }
            }
            if (state?.legacyProcFlag) {
                const isSelected = state?.legacyProcValue?.filter((i: CredentialQualityConfigProtocol) => i.isChecked).length;
                if (isSelected == 0) {
                    return `Please select atleast one legacy protocol`
                }
            }
        }
        return null
    },
    [RuleType.AUTHENTICATION_QUALITY]: (credQualConfigState: CredentialQualityConfigState) => {
        if (!credQualConfigState?.hashFlag && !credQualConfigState?.saltFlag && !credQualConfigState?.algoFlag
            && !credQualConfigState?.legacyProcFlag && !credQualConfigState?.commonProcFlag) {
            return 'Please select atleast one option in Authentication Quality issue config.';

        }
        if (credQualConfigState && credQualConfigState.hashFlag && credQualConfigState.hashValue === undefined) {
            return 'Please enter hash value in Authentication Quality issue config.';

        }
        if (credQualConfigState && credQualConfigState.hashFlag && credQualConfigState.hashValue === 0) {
            return 'Please enter hash value greater than 0 in Authentication Quality issue config.';

        }
        if (credQualConfigState && credQualConfigState.saltFlag && credQualConfigState.saltValue === undefined) {
            return 'Please enter salt value in Authentication Quality issue config.';

        }
        if (credQualConfigState && credQualConfigState.saltFlag && credQualConfigState.saltValue === 0) {
            return 'Please enter salt value greater than 0 in Authentication Quality issue config.';

        }
        if (credQualConfigState && credQualConfigState.algoFlag && credQualConfigState.algoValue) {
            let flagStatus = false;
            for (let i = 0; i < credQualConfigState.algoValue.length; i++) {
                if (credQualConfigState.algoValue[i].isChecked) {
                    flagStatus = true;
                    break;
                }
            }
            if (flagStatus === false) {
                return 'Please select atleast one option from Weak Hash Algorithm in Authentication Quality issue config.';

            }
        }
        if (credQualConfigState && credQualConfigState.legacyProcFlag && credQualConfigState.legacyProcValue) {
            let flagStatus = false;
            for (let i = 0; i < credQualConfigState.legacyProcValue.length; i++) {
                if (credQualConfigState.legacyProcValue[i].isChecked) {
                    flagStatus = true;
                    break;
                }
            }
            if (flagStatus === false) {
                return 'Please select atleast one option from Legacy Protocols in Authentication Quality issue config.';

            }
        }
        if (credQualConfigState && credQualConfigState.commonProcFlag && credQualConfigState.commonProcValue) {
            let flagStatus = false;
            for (let i = 0; i < credQualConfigState.commonProcValue.length; i++) {
                if (credQualConfigState.commonProcValue[i].isChecked) {
                    flagStatus = true;
                    break;
                }
            }
            if (flagStatus === false) {
                return 'Please select atleast one option from Common Protocols in Authentication Quality issue config.';

            }
        }
        return null;
    },
    [RuleType.LACK_OF_MFA]: (mfaConfigState: LackOfMFAConfigState) => {
        if (mfaConfigState && mfaConfigState?.mfa_servers?.length == 0) {
            return 'Please select atleast one MFA server in Lack of MFA rule config';
        }
        return null;
    },
    [RuleType.ACCESS_FROM_UNAUTH_COUNTRIES]: (state: AccessToUnauthCountriesState) => {
        if (!state) {
            return 'Issue config is empty.'
        } else if (state && state?.unauthorized_countries?.countries?.length == 0) {
            return 'Please select atleast one country in access from unauthorized countries rule config'
        }
        return null;
    },
    [RuleType.ACCESS_TO_UNAUTH_COUNTRIES]: (state: AccessToUnauthCountriesState) => {
        if (!state) {
            return 'Issue config is empty.'
        } else if (state && state?.unauthorized_countries?.countries?.length == 0) {
            return 'Please select atleast one country in access to unauthorized countries rule config'
        }
        return null;
    },
    [RuleType.DEVIATION_IN_DAILY_ASSET]: (state: DailyDeviationState) => {
        let isTrue = false, isValueSelected = true, negValue = false;
        if (state) {
            Object.keys(state).forEach((i: string) => {
                const o = state[i as keyof typeof state] as DailyDeviationConfig;
                if (o && o.is_checked) {
                    isTrue = true;
                    if (!o.percent && !o.value) {
                        isValueSelected = false
                    } else if (o?.percent || o?.value) {
                        let val = o?.percent || o?.value;
                        if (val && parseInt(val.toString()) < 0) {
                            negValue = true;
                        }
                    }
                }
            });
        }
        if (!isTrue || !state)
            return 'Please select atleast one option from the deviation in daily asset activity config.';
        else if (!isValueSelected)
            return `Value is not entered for the selected config.`
        else if (negValue)
            return `Config has negative value provided.`
        return null;
    },
    [RuleType.UNAUTHORIZED_ASSET_ACCESS]: (state: Array<IUnauthPayloadRuleConfig>, validateAll?: boolean) => {
        /*    const errorList = [] as Array<string>;
           const validateDuplicate = () => {
               for (let i = 0; i < state.length; i++) {
                   for (let j = i + 1; j < state.length; j++) {
                       if (deepCompare(state[i], state[j])) {
                           return false;
                       }
                   }
               }
               return true;
           }
   
           if (!state || state?.length === 0) { return `Playbook config is empty.`; }
           else if (state?.length > 0) {
               if (!validateDuplicate()) {
                   return `Duplicate rule configuration found.`;
               }
               for (let i = 0; i < state.length; i++) {
                   const item = state[i];
                   if (validateAll && item?.destination[0] === 'No Assets' && (
                       (item?.dest_port && item?.dest_port?.length > 0) || (item?.src_port && item?.src_port?.length > 0) ||
                       (item?.access_protocol && item?.access_protocol?.length > 0)
                   )) {
                       errorList.push(`Row ${i + 1} - 'No Assets' config should not have any parameters set.`)
                   }
                   else if (validateAll && item?.destination[0] === 'No Assets' && item?.source[0] === 'No Identities' && (
                       item.dest_port?.length === 0 || item.src_port?.length === 0 ||
                       item?.access_protocol?.length === 0
                   )) {
                       errorList.push(`Row ${i + 1} - Invalid configuration provided.`)
                       return errorList;
                   }
                   else if (['No Assets', 'All Assets'].includes(item?.destination[0]) && 'No Identities' == item?.source[0]) {
                       if (validateAll) {
                           errorList.push(`Row ${i + 1} - Both identities and assets cannot be set to 'No Identities' & 'All Assets' or 'No Assets' respectively.`)
                       } else
                           return `Both identities and assets cannot be set to 'No Identities' & 'All Assets' or 'No Assets' respectively.`;
                   } else if (item?.destination[0] === 'No Assets' &&
                       item?.source[0] === 'All Identities'
                   ) {
                       if (validateAll) {
                           errorList.push(`Row ${i + 1} - 'All Identities' cannot have assets set to 'No Assets'.`)
                       } else
                           return `'All Identities' cannot have assets set to 'No Assets'.`
                   } else if (item?.destination[0] === 'No Assets' || item?.destination[0] !== 'All Assets') {
                       continue;
                   } else if ((item?.src_port?.length === 0) &&
                       (item?.dest_port?.length === 0) && (item?.access_protocol?.length === 0)
                       && ['All Assets'].includes(item?.destination[0]) && item?.access_time &&
                       Object.values(item?.access_time).filter((i: any) => i.is_selected).length < 1
                       && !validateAll
                   ) {
                       return `Playbook config is empty for row ${i + 1}.`
                   } else if (validateAll && !item.src_port && !item?.dest_port && !item?.access_protocol) {
                       errorList.push(`Row ${i + 1} - Playbook config is empty.`)
                   }
               }
           }
           if (validateAll) {
               return errorList;
           } */
        return null;
    },
    [RuleType.UNAUTHORIZED_IDENTITY_ACCESS]: (state: Array<IUnauthPayloadRuleConfig>, validateAll?: boolean) => {
        return null;
    },
    [RuleType.ENUM_AD_ADMINS]: (state: EnumAdAdminState) => {
        if (!state || !state?.dir_params?.dir_bad_username_count_curhour
            && !state?.dir_params?.dir_bad_username_nadmin_curhour) {
            return `Please enter rule config value`;
        } else if (!state?.dir_params?.dir_bad_username_count_curhour
            || state?.dir_params?.dir_bad_username_count_curhour < 0) {
            return `Please enter valid number of attempts rule config value`
        } else if (!state?.dir_params?.dir_bad_username_nadmin_curhour
            || state?.dir_params?.dir_bad_username_nadmin_curhour < 0) {
            return `Please enter valid number of admin accounts rule config value`
        }
        return null;
    },
    [RuleType.SUSPECTED_BRUTE_FORCE]: (state: SuspectedBruteForceState) => {
        if (!state || !state?.dir_params?.dir_bad_password_nuser_curhour && !state?.dir_params?.user_auth_fail_count_today) {
            return `Please select and enter atleast one rule config`;
        } else if ((state?.dir_params?.dir_bad_password_nuser_curhour && state?.dir_params?.dir_bad_password_nuser_curhour < 0)) {
            return `Please enter valid number of accounts with bad password value`
        } else if ((state?.dir_params?.user_auth_fail_count_today && state?.dir_params?.user_auth_fail_count_today < 0)) {
            return `Please enter valid number of authentication failure value`
        }
        return null;
    },
    [RuleType.SUSPECTED_DIR_BOT]: (state: SuspectedDirBotAttackState) => {
        if (!state || (!state?.dir_params?.user_auth_fail_count_today && !state?.dir_params?.user_auth_fail_nhosts_today)) {
            return `Please enter rule config value`;
        } else if (!state?.dir_params?.user_auth_fail_count_today || state?.dir_params?.user_auth_fail_count_today < 0) {
            return `Please enter valid number of authentication failure value`;
        } else if (!state?.dir_params?.user_auth_fail_nhosts_today || state?.dir_params?.user_auth_fail_nhosts_today < 0) {
            return `Please enter valid number of separate hosts value`;
        }
        return null;
    },
    [RuleType.NTLM_RELAY_ATTACK]: (state: NTLMRelayAttackState) => {
        if (!state || (!state?.dir_params?.dir_ntlm_relay_count_today)) {
            return `Please enter rule config value.`
        } else if (state?.dir_params?.dir_ntlm_relay_count_today < 0) {
            return `Please enter valid number of attempts value`
        }
        return null;
    },
    [RuleType.ENUM_AD_USERS]: (state: EnumAdUserState) => {
        if (!state || (!state?.dir_params?.dir_bad_username_count_curhour && !state?.dir_params?.dir_bad_username_nuser_curhour)) {
            return `Please enter rule config value`;
        } else if (!state?.dir_params?.dir_bad_username_count_curhour || state?.dir_params?.dir_bad_username_count_curhour < 0) {
            return `Please enter valid number of attempts value`
        } else if (!state?.dir_params?.dir_bad_username_nuser_curhour || state?.dir_params?.dir_bad_username_nuser_curhour < 0) {
            return `Please enter valid number of user accounts value`
        }
        return null;
    },
    [RuleType.SUSPECTED_PASS_SPRAY]: (state: SuspectedPassSprayState) => {
        if (!state || (!state?.dir_params?.dir_bad_password_nuser_curhour)) {
            return `Please enter rule config value`
        } else if (state?.dir_params?.dir_bad_password_nuser_curhour < 0) {
            return `Please enter valid number of accounts with bad passwords value`
        }
        return null;
    },
    [RuleType.SUSPECTED_ATT_DIS_ACC]: (state: SusAttackDisabledState) => {
        if (!state || (!state?.dir_params?.user_disabled_logon_count_curhour)) {
            return `Please enter rule config value`
        } else if (state?.dir_params?.user_disabled_logon_count_curhour < 0) {
            return `Please enter valid number of login attempts value`
        }
        return null;
    },
    [RuleType.SUSPECTED_ATT_EXP_ACC]: (state: SusAttackExpiredState) => {
        if (!state || (!state?.dir_params?.user_expired_logon_count_curhour)) {
            return `Please enter rule config value`
        } else if (state?.dir_params?.user_expired_logon_count_curhour < 0) {
            return `Please enter valid number of login attempts value`
        }
        return null;
    },
    [RuleType.SUSPECTED_ATT_LOCK_ACC]: (state: SusAttackLockedState) => {
        if (!state || (!state?.dir_params?.user_locked_logon_count_curhour)) {
            return `Please enter rule config value`
        } else if (state?.dir_params?.user_locked_logon_count_curhour < 0) {
            return `Please enter valid number of login attempts value`
        }
        return null;
    },
    [RuleType.UNAUTH_LOGIN_DEVICE]: (state: UnauthLoginDeviceState) => {
        if (!state || (!state?.dir_params?.user_unauthorized_device_count_curhour)) {
            return `Please enter rule config value`
        } else if (state?.dir_params?.user_unauthorized_device_count_curhour < 0) {
            return `Please enter valid number of unauthorized attempts value`
        }
        return null;
    },
    [RuleType.UNAUTH_LOGIN_TIME]: (state: UnauthLoginTimeState) => {
        if (!state || (!state?.dir_params?.user_unauthorized_time_count_curhour)) {
            return `Please enter rule config value`
        } else if (state?.dir_params?.user_unauthorized_time_count_curhour < 0) {
            return `Please enter valid number of unauthorized attempts value`
        }
        return null;
    },
    [RuleType.ACCOUNT_TAKEOVER]: (state: AccountTakeoverState) => {
        /*  if (!state || (!state?.account_takeover_params?.displacementFlag && !state?.account_takeover_params?.distanceTraveledFlag
             && !state?.account_takeover_params?.newIpRangeFlag && !state?.account_takeover_params?.newIspFlag
         )) {
             return `Please select atleast one parameter`
         } else if (state?.account_takeover_params?.displacementFlag && !state?.account_takeover_params?.displacementValue) {
             return `Please enter displacement value`
         } else if (state?.account_takeover_params?.displacementFlag && state?.account_takeover_params?.displacementValue
             && parseInt(state?.account_takeover_params?.displacementValue.toString()) < 10
         ) {
             return `Displacement value can not be less than 10`
         } else if (state?.account_takeover_params?.distanceTraveledFlag && state?.account_takeover_params?.distanceTraveledValue
             && parseInt(state?.account_takeover_params?.distanceTraveledValue.toString()) < 10
         ) {
             return `Distance traveled value can not be less than 10`
         } else if (state?.account_takeover_params?.distanceTraveledFlag && !state?.account_takeover_params?.distanceTraveledValue) {
             return `Please enter distance traveled value`
         } */
        return null;
    },
    [RuleType.SHADOW_DIRECTORY]: (state: ShadowDirectoryState) => {
        if (!state || (!state?.IdentitySystemCategories)) {
            return `Please enter rule config value`
        } else if (state?.IdentitySystemCategories?.length === 0) {
            return `Please enter valid number of categories value`
        }
        return null;
    },
    [RuleType.SUSPECTED_ACCESS_TOKEN]: (state: SuspectedAccountSharingState, validateAll: any, idAssetConfig: IPlaybookRuleState) => {
        if (idAssetConfig?.asset?.pattern && !idAssetConfig?.asset?.type.includes('All Assets') &&
            Object.keys(idAssetConfig?.asset?.pattern)?.filter(s => ['d_ip', 'd_hostname', 'd_name'].includes(s)).length == 0) {
            return `Please provide an asset hostname, name, or IP address before saving.`
        } else if (!state || !state?.session_token_params?.assertion_timeout) {
            return `Please enter an assertion timeout period`
        } else if (state?.session_token_params?.assertion_timeout && !isNaN(state?.session_token_params?.assertion_timeout)) {
            const timeOut = parseInt(state?.session_token_params?.assertion_timeout.toString());
            const unit = state?.session_token_params?.period_timeout_unit;
            if (unit == 'hours' && (timeOut < 1 || timeOut > 4)) {
                return `Please enter hours value between 1 and 4`
            }
            if (unit == 'minutes' && (timeOut < 5 || timeOut > 240)) {
                return `Please enter minutes value between 5 and 240`
            }
        }
        return null;
    },
    [RuleType.UNUSUAL_USER_ACCESS]: (state: UnusualUserAccess) => {
        const deviationCount = state?.unusual_user_params.deviationsCount;
        const matchedCount = Object.keys(state.unusual_user_params).filter((i) => i !== 'deviationsCount' && state.unusual_user_params[i]).length;
        if (deviationCount > matchedCount) {
            return `Deviations count is greater than actual params selected.`
        }
        return null;
    }
}

export const validate = (ruleType: string, data: any, validateAll?: boolean, idAssetConfig?: any) => {
    if (ruleType && ruleType in RuleConfigValidate) {
        return RuleConfigValidate[ruleType as keyof typeof RuleConfigValidate](data, validateAll, idAssetConfig);
    }
    return null;
}