import React, { useEffect, useState } from 'react'
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { TicketPerRuleType } from '../../../types/response-types';
import { getRoundedUnit } from '../../../utils/util-methods';

type Props = {
	ticketRuleType: TicketPerRuleType | undefined
}

export const ReportByRuleType = ({ ticketRuleType }: Props) => {

	const options: Highcharts.Options = {
		chart: {
			height: window.matchMedia("(min-width: 2560px)").matches ? 230 : 230,
			marginTop: window.matchMedia("(min-width: 2560px)").matches ? 30 : 30,
			// marginBottom: 0,
			// spacingLeft: 0,
			// spacingRight: 0,
			// marginLeft: 0,
			// marginRight: 0
		},
		tooltip: {
			useHTML: true,
			backgroundColor: undefined,
			borderWidth: 0,
			shadow: false
		},
		plotOptions: {
			pie: {
				borderWidth: 1,
				borderColor: "#f5f5f5",
				dataLabels: {
					enabled: false
				},
				size: "100%",
				innerSize: "60%",
				tooltip: {
					headerFormat: "",
					pointFormatter: function () {
						let perc = Math.round((this.y! / this.total!) * 100);
						if (this.y && this.y > 0) {
							return (
								'<span style="background-color:' +
								this.color +
								`;color: ${this.color === '#cccccc' || this.color === '#fcbf2e' ? '#555555' : '#fff'};` + 'z-index: 12000" class="tooltip-value"> ' +
								"<b>" +
								// `${this.y}` +
								`${perc < 1 ? 1 : perc}` +
								"</b>" +
								"%" +
								"</span>"
								+ `<span class="tooltip-label">${this.name}</span>`
							);
						}
						return '';
					}
				},
				cursor: 'pointer'
			}
		},
		credits: {
			enabled: false
		}
	};

	let lowData: Highcharts.PointOptionsObject[] = [];
	let medData: Highcharts.PointOptionsObject[] = [];
	let highData: Highcharts.PointOptionsObject[] = [];
	let criData: Highcharts.PointOptionsObject[] = [];
	const [totalTickets, setTotalTickets] = useState(0);
	const [ticketData, setTicketData] = useState<Highcharts.PointOptionsObject[]>([]);
	const [chartOptions, setChartOptions] = useState<Highcharts.Options>(options);

	useEffect(() => {
		if (ticketRuleType) {
			calcData(ticketRuleType);
		}
	}, [ticketRuleType])

	useEffect(() => {
		setChartOptions({
			...chartOptions,
			title: {
				text: `<span class="font14"><strong>${getRoundedUnit(totalTickets)}</strong></span><br><span class="hc-title-text">Tickets</span>`,
				style: {
					fontSize: '100%',
					fontFamily: "Metropolis-Regular"
				},
				x: 0,
				y: window.matchMedia("(min-width: 2560px)").matches ? 110 : 110
			},
			series: [
				{
					type: "pie",
					states: {
						hover: {
							halo: null
						}
					},
					data: ticketData
				}
			]
		})
	}, [totalTickets, ticketData])

	const calcData = (ticketRuleType: TicketPerRuleType) => {
		let total = 0;
		for (let ob in ticketRuleType) {
			lowData.push({
				name: ob,
				y: ticketRuleType[ob][1],
				z: 1,
				color: "#cccccc"
			});
			medData.push({
				name: ob,
				y: ticketRuleType[ob][2],
				z: 1,
				color: "#fcbf2e"
			});
			highData.push({
				name: ob,
				y: ticketRuleType[ob][3],
				z: 1,
				color: "#fd7122"
			});
			criData.push({
				name: ob,
				y: ticketRuleType[ob][4],
				z: 1,
				color: "#fa1262"
			});
			total += ticketRuleType[ob][1] + ticketRuleType[ob][2] + ticketRuleType[ob][3] + ticketRuleType[ob][4];
		}
		setTotalTickets(total);
		setTicketData([
			...lowData,
			...medData,
			...highData,
			...criData
		]);
	}


	return (
		<>
			{ticketRuleType === undefined ?
				<div className="empty_rule_type">&nbsp;</div>
				:
				<div className="shadow_box tickets_rule_chart">
					<div className="ticket_chart_title">Open Tickets by Risk & Issue</div>
					<div style={{marginTop:'5px'}}>
					<HighchartsReact highcharts={Highcharts} options={chartOptions} />
					</div>
					<div className="rule_type_legend">
						<ul>
							<li>Low</li>
							<li>Medium</li>
							<li>High</li>
							<li>Critical</li>
						</ul>
					</div>
				</div>
			}
		</>
	)
}