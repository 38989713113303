import { RuleType } from "../pages/playbooks/constants/Constants";

export const keysMapping = new Map([
    ["shadow_activity", ["Exposed Assets", "Posture Issues", "Shadow Access", "Shadow Assets", "Shadow Identity Systems", "Suspicious Inbound Access", "Suspicious Outbound Access", "Unknown SaaS Access"]],
    ["id_access_infra", ['Auth Hash Quality', 'Auth Hash Security', 'Enumeration of AD Admins', 'Enumeration of AD Users', 'Lack of MFA', "Posture Issues",
        'Suspected Directory/IdP Bot Attack', RuleType.SUSPECTED_AD_BRUTE_FORCE, RuleType.SUSPECTED_IDP_BRUTE_FORCE, 'Suspected AD NTLM Relay Attack',
        RuleType.ASSETS_WITHOUT_MFA,
        'Suspected Attack on Locked AD Account', 'Suspected Directory/IdP Password Spray Attack', 'Suspected Attack on Disabled AD Account', 'Suspected Attack on Expired AD Account', RuleType.AD_GOLDEN_TICKET, RuleType.AD_PASS_THE_TICKET, 'Repeated AD Login Attempts from Invalid Device', 'Repeated AD Login Attempts at Invalid Time', 'Posture Issues']],
    ["unauthorised_access", ["Access to Unauthorized Countries", "Access from Unauthorized Countries",
        "Access to Public VPN", "Access from Public VPN",
        "Access from Anonymous IP", "Access to Anonymous IP", RuleType.ACCOUNT_TAKEOVER, RuleType.DEVIATION_IDENTITY_ACTIVITY,        
        'Posture Issues', RuleType.SUSPECTED_ACCESS_TOKEN, RuleType.UNUSUAL_USER_ACCESS,
        "Unauthorized Asset Access", RuleType.UNAUTHORIZED_IDENTITY_ACCESS]],
    ["total_issues", ["total", "critical", "high", "low", "medium"]],
    ["credentials_risk", ["Compromised Password", "Compromised User", "Weak Password"]],
    ["unknown_access", ["Unknown Access", "Shadow External Access", "Deviation in Daily Asset Activity"]]
]);
