export const PostureSearchTemplate = [
	{
		"id": "destination/asset",
		"label": "Asset/Destination",
		"isGroupRoot": true,
		"value": "Asset/Destination"
	},
	{
		"label": "Asset/Destination Country",
		"operators": [
			{
				"id": "equals",
				"label": "equals",
				"valueType": "array-autocomplete"
			},
			{
				"id": "not_equals",
				"label": "not equals",
				"valueType": "array-autocomplete"
			}
		],
		"placeholder": "Select country",
		"search_key": "asset_countries",
		"validations": [
			"required"
		],
		"pageType": [
			"Assets",
			"Accesses"
		],
		"key": "asset_countries",
		"value": "Asset/Destination Country",
		"groupBy": "Asset/Destination"
	},
	{
		"label": "Asset/Destination Hostname",
		"operators": [
			{
				"id": "equals",
				"label": "equals",
				"valueType": "text-array"
			},
			{
				"id": "not_equals",
				"label": "not equals",
				"valueType": "text-array"
			},
			{
				"id": "matches",
				"label": "matches",
				"valueType": "text-array"
			},
			{
				"id": "not_matches",
				"label": "not matches",
				"valueType": "text-array"
			}
		],
		"placeholder": "Enter comma-separated values",
		"search_key": "asset_hostname",
		"validations": [
			"required"
		],
		"pageType": [
			"Assets",
			"Accesses"
		],
		"key": "asset_hostname",
		"value": "Asset/Destination Hostname",
		"groupBy": "Asset/Destination"
	},
	{
		"label": "Asset/Destination State",
		"operators": [
			{
				"id": "equals",
				"label": "equals",
				"valueType": "text-array"
			},
			{
				"id": "not_equals",
				"label": "not equals",
				"valueType": "text-array"
			},
			{
				"id": "matches",
				"label": "matches",
				"valueType": "text-array"
			},
			{
				"id": "not_matches",
				"label": "not matches",
				"valueType": "text-array"
			}
		],
		"placeholder": "Enter comma-separated values",
		"search_key": "asset_state",
		"validations": [
			"required"
		],
		"pageType": [
			"Assets",
			"Accesses"
		],
		"key": "asset_state",
		"value": "Asset/Destination State",
		"groupBy": "Asset/Destination"
	},
	{
		"label": "Asset/Destination City",
		"operators": [
			{
				"id": "equals",
				"label": "equals",
				"valueType": "text-array"
			},
			{
				"id": "not_equals",
				"label": "not equals",
				"valueType": "text-array"
			},
			{
				"id": "matches",
				"label": "matches",
				"valueType": "text-array"
			},
			{
				"id": "not_matches",
				"label": "not matches",
				"valueType": "text-array"
			}
		],
		"placeholder": "Enter comma-separated values",
		"search_key": "asset_city",
		"validations": [
			"required"
		],
		"pageType": [
			"Assets",
			"Accesses"
		],
		"key": "asset_city",
		"value": "Asset/Destination City",
		"groupBy": "Asset/Destination"
	},
	{
		"label": "Asset/Destination IP",
		"operators": [
			{
				"id": "equals",
				"label": "equals",
				"valueType": "text-array"
			},
			{
				"id": "not_equals",
				"label": "not equals",
				"valueType": "text-array"
			},
			{
				"id": "matches",
				"label": "matches",
				"valueType": "text-array"
			},
			{
				"id": "not_matches",
				"label": "not matches",
				"valueType": "text-array"
			}
		],
		"placeholder": "Enter comma-separated values",
		"search_key": "asset_ip",
		"validations": [
			"required"
		],
		"pageType": [
			"Assets",
			"Accesses"
		],
		"key": "asset_ip",
		"value": "Asset/Destination IP",
		"groupBy": "Asset/Destination"
	},
	{
		"label": "Asset/Destination MAC",
		"operators": [
			{
				"id": "equals",
				"label": "equals",
				"valueType": "text-array"
			},
			{
				"id": "not_equals",
				"label": "not equals",
				"valueType": "text-array"
			},
			{
				"id": "matches",
				"label": "matches",
				"valueType": "text-array"
			},
			{
				"id": "not_matches",
				"label": "not matches",
				"valueType": "text-array"
			}
		],
		"placeholder": "Enter comma-separated values",
		"search_key": "asset_mac_address",
		"validations": [
			"required"
		],
		"pageType": [
			"Assets",
			"Accesses"
		],
		"key": "asset_mac_address",
		"value": "Asset/Destination MAC",
		"groupBy": "Asset/Destination"
	},
	{
		"label": "Asset/Destination Name",
		"operators": [
			{
				"id": "equals",
				"label": "equals",
				"valueType": "text-array"
			},
			{
				"id": "not_equals",
				"label": "not equals",
				"valueType": "text-array"
			},
			{
				"id": "matches",
				"label": "matches",
				"valueType": "text-array"
			},
			{
				"id": "not_matches",
				"label": "not matches",
				"valueType": "text-array"
			}
		],
		"placeholder": "Enter comma-separated values",
		"search_key": "asset_name",
		"validations": [
			"required"
		],
		"pageType": [
			"Assets",
			"Accesses"
		],
		"key": "asset_name",
		"value": "Asset/Destination Name",
		"groupBy": "Asset/Destination"
	},
	{
		"label": "Asset/Destination Type",
		"operators": [
			{
				"id": "equals",
				"label": "equals",
				"valueType": "multi-select"
			},
			{
				"id": "not_equals",
				"label": "not equals",
				"valueType": "multi-select"
			}
		],
		"search_key": "asset_type",
		"validations": [
			"required"
		],
		"pageType": [
			"Assets",
			"Accesses"
		],
		"key": "asset_type",
		"value": "Asset/Destination Type",
		"groupBy": "Asset/Destination",
		placeholder: "Select asset/destination type"
	},
	{
		"label": "Asset/Destination Port",
		"operators": [
			{
				"id": "=",
				"label": "=",
				"valueType": "number-array",
				"placeholder": "Enter comma-separated numeric values",
				"validations": [
					"required",
					"multipleNumbersWithRange",
					"max_65535"
				]
			},
			{
				"id": "!=",
				"label": "!=",
				"valueType": "number-array",
				"placeholder": "Enter comma-separated numeric values",
				"validations": [
					"required",
					"multipleNumbersWithRange",
					"max_65535"
				]
			},
			{
				"id": "<",
				"label": "<",
				"valueType": "number",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"positiveNumbersOnly",
					"max_65535"
				]
			},
			{
				"id": "<=",
				"label": "<=",
				"valueType": "number",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"positiveNumbersOnly",
					"max_65535"
				]
			},
			{
				"id": ">",
				"label": ">",
				"valueType": "number",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"positiveNumbersOnly",
					"max_65535"
				]
			},
			{
				"id": ">=",
				"label": ">=",
				"valueType": "number",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"positiveNumbersOnly",
					"max_65535"
				]
			},
			{
				"id": "between",
				"label": "between",
				"valueType": "number-range",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"positiveRange",
					"max_65535"
				]
			}
		],
		"placeholder": "Enter comma-separated numeric values",
		"search_key": "asset_port",
		"validations": [
			"required",
			"positiveNumbersOnly"
		],
		"pageType": [
			"Assets",
			"Accesses"
		],
		"key": "asset_port",
		"value": "Asset/Destination Port",
		"groupBy": "Asset/Destination"
	},
	{
		"label": "Protocol",
		"operators": [
			{
				"id": "equals",
				"label": "equals",
				"valueType": "array-autocomplete"
			},
			{
				"id": "not_equals",
				"label": "not equals",
				"valueType": "array-autocomplete"
			}
		],
		"placeholder": "Select protocol",
		"search_key": "asset_protocol",
		"validations": [
			"required"
		],
		"pageType": [
			"Assets",
			"Accesses"
		],
		"key": "asset_protocol",
		"value": "Protocol",
		"groupBy": "Asset/Destination"
	},
	{
		"label": "Asset Is Public",
		"operators": [
			{
				"id": "equals",
				"label": "equals",
				"valueType": "select"
			}
		],
		"search_key": "asset_is_public",
		"validations": [
			"required"
		],
		"pageType": [
			"Assets",
			"Accesses"
		],
		"key": "asset_is_public",
		"value": "Asset Is Public",
		"groupBy": "Asset/Destination"
	},
	{
		"label": "Asset Is Saas",
		"operators": [
			{
				"id": "equals",
				"label": "equals",
				"valueType": "select"
			}
		],
		"search_key": "asset_is_saas",
		"validations": [
			"required"
		],
		"pageType": [
			"Assets",
			"Accesses"
		],
		"key": "asset_is_saas",
		"value": "Asset Is Saas",
		"groupBy": "Asset/Destination"
	},
	{
		"label": "Asset Is Known",
		"operators": [
			{
				"id": "equals",
				"label": "equals",
				"valueType": "select"
			}
		],
		"search_key": "asset_is_known",
		"validations": [
			"required"
		],
		"pageType": [
			"Assets",
			"Accesses"
		],
		"key": "asset_is_known",
		"value": "Asset Is Known",
		"groupBy": "Asset/Destination"
	},
	{
		"casing": "camel",
		"label": "Asset/Destination Category",
		"operators": [
			{
				"id": "equals",
				"label": "equals",
				"valueType": "multi-select"
			},
			{
				"id": "not_equals",
				"label": "not equals",
				"valueType": "multi-select"
			}
		],
		"search_key": "dst_category",
		"validations": [
			"required"
		],
		"pageType": [
			"Assets",
			"Accesses"
		],
		"key": "dst_category",
		"value": "Asset/Destination Category",
		"groupBy": "Asset/Destination",
		placeholder: "Select asset/destination category"
	},
	{
		"label": "Asset is an Identity System",
		"operators": [
			{
				"id": "equals",
				"label": "equals",
				"valueType": "select"
			}
		],
		"search_key": "asset_is_directory",
		"validations": [
			"required"
		],
		"pageType": [
			"Assets",
			"Accesses"
		],
		"key": "asset_is_directory",
		"value": "Asset is an Identity System",
		"groupBy": "Asset/Destination"
	},
	{
		"label": "Number of Accesses by a Service",
		"operators": [
			{
				"id": "=",
				"label": "=",
				"valueType": "number",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"numbersWithRange"
				]
			},
			{
				"id": "<",
				"label": "<",
				"valueType": "number",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"positiveNumbersOnly",
					"greaterThanZero"
				]
			},
			{
				"id": "<=",
				"label": "<=",
				"valueType": "number",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"positiveNumbersOnly"
				]
			},
			{
				"id": ">",
				"label": ">",
				"valueType": "number",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"positiveNumbersOnly"
				]
			},
			{
				"id": ">=",
				"label": ">=",
				"valueType": "number",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"positiveNumbersOnly"
				]
			},
			{
				"id": "between",
				"label": "between",
				"valueType": "number-range",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"positiveRange"
				]
			}
		],
		"placeholder": "Enter numeric value",
		"search_key": "access_count_identity_as_service",
		"validations": [
			"required"
		],
		"pageType": [
			"Assets"
		],
		"page": "Assets",
		"dataField": {
			"Assets": {
				"id": "access_count_identity_as_service",
				"type": "NUMBER"
			}
		},
		"key": "access_count_identity_as_service",
		"value": "Number of Accesses by a Service",
		"groupBy": "Asset/Destination"
	},
	{
		"label": "Number of Accesses by a User",
		"operators": [
			{
				"id": "=",
				"label": "=",
				"valueType": "number",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"numbersWithRange"
				]
			},
			{
				"id": "<",
				"label": "<",
				"valueType": "number",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"positiveNumbersOnly",
					"greaterThanZero"
				]
			},
			{
				"id": "<=",
				"label": "<=",
				"valueType": "number",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"positiveNumbersOnly"
				]
			},
			{
				"id": ">",
				"label": ">",
				"valueType": "number",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"positiveNumbersOnly"
				]
			},
			{
				"id": ">=",
				"label": ">=",
				"valueType": "number",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"positiveNumbersOnly"
				]
			},
			{
				"id": "between",
				"label": "between",
				"valueType": "number-range",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"positiveRange"
				]
			}
		],
		"placeholder": "Enter numeric value",
		"search_key": "access_count_identity_as_user",
		"validations": [
			"required"
		],
		"pageType": [
			"Assets"
		],
		"page": "Assets",
		"dataField": {
			"Assets": {
				"id": "access_count_identity_as_user",
				"type": "NUMBER"
			}
		},
		"key": "access_count_identity_as_user",
		"value": "Number of Accesses by a User",
		"groupBy": "Asset/Destination"
	},
	{
		"label": "Number of Identities Accessing",
		"operators": [
			{
				"id": "=",
				"label": "=",
				"valueType": "number",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"numbersWithRange"
				]
			},
			{
				"id": "<",
				"label": "<",
				"valueType": "number",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"positiveNumbersOnly",
					"greaterThanZero"
				]
			},
			{
				"id": "<=",
				"label": "<=",
				"valueType": "number",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"positiveNumbersOnly"
				]
			},
			{
				"id": ">",
				"label": ">",
				"valueType": "number",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"positiveNumbersOnly"
				]
			},
			{
				"id": ">=",
				"label": ">=",
				"valueType": "number",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"positiveNumbersOnly"
				]
			},
			{
				"id": "between",
				"label": "between",
				"valueType": "number-range",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"positiveRange"
				]
			}
		],
		"placeholder": "Enter numeric value",
		"search_key": "identity_count",
		"validations": [
			"required"
		],
		"pageType": [
			"Assets"
		],
		"key": "identity_count",
		"value": "Number of Identities Accessing",
		"groupBy": "Asset/Destination"
	},
	{
		"id": "source/identity",
		"label": "Identity/Source",
		"isGroupRoot": true,
		"value": "Identity/Source"
	},
	{
		"label": "Identity/Source Country",
		"operators": [
			{
				"id": "equals",
				"label": "equals",
				"valueType": "array-autocomplete"
			},
			{
				"id": "not_equals",
				"label": "not equals",
				"valueType": "array-autocomplete"
			}
		],
		"placeholder": "Select country",
		"search_key": "identity_countries",
		"validations": [
			"required"
		],
		"pageType": [
			"Identities",
			"Accesses"
		],
		"key": "identity_countries",
		"value": "Identity/Source Country",
		"groupBy": "Identity/Source"
	},
	{
		"label": "Identity/Source Hostname",
		"operators": [
			{
				"id": "equals",
				"label": "equals",
				"valueType": "text-array"
			},
			{
				"id": "not_equals",
				"label": "not equals",
				"valueType": "text-array"
			},
			{
				"id": "matches",
				"label": "matches",
				"valueType": "text-array"
			},
			{
				"id": "not_matches",
				"label": "not matches",
				"valueType": "text-array"
			}
		],
		"placeholder": "Enter comma-separated values",
		"search_key": "identity_hostname",
		"validations": [
			"required"
		],
		"pageType": [
			"Identities",
			"Accesses"
		],
		"key": "identity_hostname",
		"value": "Identity/Source Hostname",
		"groupBy": "Identity/Source"
	},
	{
		"id": "identity_groups",
		"type": "text",
		"operators": [
			{
				"id": "equals",
				"label": "equals",
				"valueType": "text-array"
			},
			{
				"id": "not equals",
				"label": "not equals",
				"valueType": "text-array"
			},
			{
				"id": "matches",
				"label": "matches",
				"valueType": "text-array"
			},
			{
				"id": "not matches",
				"label": "not matches",
				"valueType": "text-array"
			}
		],
		"pageType": [
			"Identities",
			"Accesses"
		],
		"validations": [
			"required"
		],
		"label": "Identity/Source Groups",
		"placeholder": "Enter comma separated identity/source groups",
		"defaultOperator": "equals",
		"valueType": "string-array",
		"key": "identity_groups",
		"value": "identity_groups",
		"groupBy": "Identity/Source",
		"search_key": "identity_groups",
	},
	{
		"label": "Identity/Source State",
		"operators": [
			{
				"id": "equals",
				"label": "equals",
				"valueType": "text-array"
			},
			{
				"id": "not_equals",
				"label": "not equals",
				"valueType": "text-array"
			},
			{
				"id": "matches",
				"label": "matches",
				"valueType": "text-array"
			},
			{
				"id": "not_matches",
				"label": "not matches",
				"valueType": "text-array"
			}
		],
		"placeholder": "Enter comma-separated values",
		"search_key": "identity_state",
		"validations": [
			"required"
		],
		"pageType": [
			"Identities",
			"Accesses"
		],
		"key": "identity_state",
		"value": "Identity/Source State",
		"groupBy": "Identity/Source"
	},
	{
		"label": "Identity/Source City",
		"operators": [
			{
				"id": "equals",
				"label": "equals",
				"valueType": "text-array"
			},
			{
				"id": "not_equals",
				"label": "not equals",
				"valueType": "text-array"
			},
			{
				"id": "matches",
				"label": "matches",
				"valueType": "text-array"
			},
			{
				"id": "not_matches",
				"label": "not matches",
				"valueType": "text-array"
			}
		],
		"placeholder": "Enter comma-separated values",
		"search_key": "identity_city",
		"validations": [
			"required"
		],
		"pageType": [
			"Identities",
			"Accesses"
		],
		"key": "identity_city",
		"value": "Identity/Source City",
		"groupBy": "Identity/Source"
	},
	{
		"label": "Identity/Source IP",
		"operators": [
			{
				"id": "equals",
				"label": "equals",
				"valueType": "text-array"
			},
			{
				"id": "not_equals",
				"label": "not equals",
				"valueType": "text-array"
			},
			{
				"id": "matches",
				"label": "matches",
				"valueType": "text-array"
			},
			{
				"id": "not_matches",
				"label": "not matches",
				"valueType": "text-array"
			}
		],
		"placeholder": "Enter comma-separated values",
		"search_key": "identity_ip",
		"validations": [
			"required"
		],
		"pageType": [
			"Identities",
			"Accesses"
		],
		"key": "identity_ip",
		"value": "Identity/Source IP",
		"groupBy": "Identity/Source"
	},
	{
		"label": "Identity/Source MAC",
		"operators": [
			{
				"id": "equals",
				"label": "equals",
				"valueType": "text-array"
			},
			{
				"id": "not_equals",
				"label": "not equals",
				"valueType": "text-array"
			},
			{
				"id": "matches",
				"label": "matches",
				"valueType": "text-array"
			},
			{
				"id": "not_matches",
				"label": "not matches",
				"valueType": "text-array"
			}
		],
		"placeholder": "Enter comma-separated values",
		"search_key": "identity_mac_address",
		"validations": [
			"required"
		],
		"pageType": [
			"Identities",
			"Accesses"
		],
		"key": "identity_mac_address",
		"value": "Identity/Source MAC",
		"groupBy": "Identity/Source"
	},
	{
		"label": "Identity/Source Name",
		"operators": [
			{
				"id": "equals",
				"label": "equals",
				"valueType": "text-array"
			},
			{
				"id": "not_equals",
				"label": "not equals",
				"valueType": "text-array"
			},
			{
				"id": "matches",
				"label": "matches",
				"valueType": "text-array"
			},
			{
				"id": "not_matches",
				"label": "not matches",
				"valueType": "text-array"
			}
		],
		"placeholder": "Enter comma-separated values",
		"search_key": "identity_name",
		"validations": [
			"required"
		],
		"pageType": [
			"Identities",
			"Accesses"
		],
		"key": "identity_name",
		"value": "Identity/Source Name",
		"groupBy": "Identity/Source"
	},
	{
		"label": "Identity/Source Port",
		"operators": [
			{
				"id": "=",
				"label": "=",
				"valueType": "number-array",
				"placeholder": "Enter comma-separated numeric values",
				"validations": [
					"required",
					"multipleNumbersWithRange",
					"max_65535"
				]
			},
			{
				"id": "!=",
				"label": "!=",
				"valueType": "number-array",
				"placeholder": "Enter comma-separated numeric values",
				"validations": [
					"required",
					"multipleNumbersWithRange",
					"max_65535"
				]
			},
			{
				"id": "<",
				"label": "<",
				"valueType": "number",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"positiveNumbersOnly",
					"max_65535"
				]
			},
			{
				"id": "<=",
				"label": "<=",
				"valueType": "number",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"positiveNumbersOnly",
					"max_65535"
				]
			},
			{
				"id": ">",
				"label": ">",
				"valueType": "number",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"positiveNumbersOnly",
					"max_65535"
				]
			},
			{
				"id": ">=",
				"label": ">=",
				"valueType": "number",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"positiveNumbersOnly",
					"max_65535"
				]
			},
			{
				"id": "between",
				"label": "between",
				"valueType": "number-range",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"positiveRange",
					"max_65535"
				]
			}
		],
		"placeholder": "Enter comma-separated numeric values",
		"search_key": "identity_port",
		"validations": [
			"required"
		],
		"pageType": [
			"Identities",
			"Accesses"
		],
		"key": "identity_port",
		"value": "Identity/Source Port",
		"groupBy": "Identity/Source"
	},
	{
		"label": "Identity/Source Type",
		"operators": [
			{
				"id": "equals",
				"label": "equals",
				"valueType": "multi-select"
			},
			{
				"id": "not_equals",
				"label": "not equals",
				"valueType": "multi-select"
			}
		],
		"search_key": "identity_type",
		"validations": [
			"required"
		],
		"pageType": [
			"Identities",
			"Accesses"
		],
		"key": "identity_type",
		"value": "Identity/Source Type",
		"groupBy": "Identity/Source",
		placeholder: "Select identity/source type"
	},
	{
		"label": "Identity/Source Status",
		"operators": [
			{
				"id": "equals",
				"label": "equals",
				"valueType": "multi-select"
			},
			{
				"id": "not_equals",
				"label": "not equals",
				"valueType": "multi-select"
			}
		],
		"search_key": "identity_status",
		"validations": [
			"required"
		],
		"pageType": [
			"Identities",
			"Accesses"
		],
		"key": "identity_status",
		"value": "Identity Status",
		"groupBy": "Identity/Source",
		placeholder: "Select Identity/Source Status "
	},
	{
		"label": "Identity/Source Attributes Last Modified Time",
		"operators": [
			{
				"id": "<",
				"label": "<",
				"valueType": "datetime",
				"validations": [
					"required"
				]
			},
			{
				"id": "<=",
				"label": "<=",
				"valueType": "datetime",
				"validations": [
					"required"
				]
			},
			{
				"id": ">",
				"label": ">",
				"valueType": "datetime",
				"validations": [
					"required"
				]
			},
			{
				"id": ">=",
				"label": ">=",
				"valueType": "datetime",
				"validations": [
					"required"
				]
			},
			{
				"id": "between",
				"label": "between",
				"valueType": "datetime-range",
				"validations": [
					"required",
					"dateRange"
				]
			},
			{
				"id": "last_in",
				"label": "in last",
				"valueType": "time-number",
				"validations": [
					"required",
					"positiveNumbersLastIn"
				],
				"placeholder": "Enter numeric value"
			}
		],
		"search_key": "id_attrs_change_timestamp",
		"validations": [
			"required",
			"dateRange"
		],
		"placeholder": "Enter numeric value",
		"pageType": [
			"Identities",
			"Accesses"
		],
		"key": "id_attrs_change_timestamp",
		"value": "Identity/Source Attribute's Last Modified Time",
		"groupBy": "Identity/Source"
	},
	{
		"label": "Identity/Source Password Last Set",
		"operators": [
			{
				"id": "<",
				"label": "<",
				"valueType": "datetime",
				"validations": [
					"required"
				]
			},
			{
				"id": "<=",
				"label": "<=",
				"valueType": "datetime",
				"validations": [
					"required"
				]
			},
			{
				"id": ">",
				"label": ">",
				"valueType": "datetime",
				"validations": [
					"required"
				]
			},
			{
				"id": ">=",
				"label": ">=",
				"valueType": "datetime",
				"validations": [
					"required"
				]
			},
			{
				"id": "between",
				"label": "between",
				"valueType": "datetime-range",
				"validations": [
					"required",
					"dateRange"
				]
			},
			{
				"id": "last_in",
				"label": "in last",
				"valueType": "time-number",
				"validations": [
					"required",
					"positiveNumbersLastIn"
				],
				"placeholder": "Enter numeric value"
			}
		],
		"search_key": "identity_pwd_last_set",
		"validations": [
			"required",
			"dateRange"
		],
		"placeholder": "Enter numeric value",
		"pageType": [
			"Identities",
			"Accesses"
		],
		"key": "identity_pwd_last_set",
		"value": "Identity/Source Password Last Set",
		"groupBy": "Identity/Source"
	},
	{
		"label": "Identity Is Public",
		"operators": [
			{
				"id": "equals",
				"label": "equals",
				"valueType": "select"
			}
		],
		"search_key": "identity_is_public",
		"validations": [
			"required"
		],
		"pageType": [
			"Identities",
			"Accesses"
		],
		"key": "identity_is_public",
		"value": "Identity Is Public",
		"groupBy": "Identity/Source"
	},
	{
		"label": "Identity Is Known",
		"operators": [
			{
				"id": "equals",
				"label": "equals",
				"valueType": "select"
			}
		],
		"search_key": "identity_is_known",
		"validations": [
			"required"
		],
		"pageType": [
			"Identities",
			"Accesses"
		],
		"key": "identity_is_known",
		"value": "Identity Is Known",
		"groupBy": "Identity/Source"
	},
	{
		"casing": "camel",
		"label": "Identity/Source Category",
		"operators": [
			{
				"id": "equals",
				"label": "equals",
				"valueType": "multi-select"
			},
			{
				"id": "not_equals",
				"label": "not equals",
				"valueType": "multi-select"
			}
		],
		"search_key": "src_category",
		"validations": [
			"required"
		],
		"pageType": [
			"Identities",
			"Accesses"
		],
		"key": "src_category",
		"value": "Identity/Source Category",
		"groupBy": "Identity/Source",
		placeholder: "Select identity/source category"
	},
	{
		"label": "Number of Accesses as a User",
		"operators": [
			{
				"id": "=",
				"label": "=",
				"valueType": "number",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"numbersWithRange"
				]
			},
			{
				"id": "<",
				"label": "<",
				"valueType": "number",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"positiveNumbersOnly",
					"greaterThanZero"
				]
			},
			{
				"id": "<=",
				"label": "<=",
				"valueType": "number",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"positiveNumbersOnly"
				]
			},
			{
				"id": ">",
				"label": ">",
				"valueType": "number",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"positiveNumbersOnly"
				]
			},
			{
				"id": ">=",
				"label": ">=",
				"valueType": "number",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"positiveNumbersOnly"
				]
			},
			{
				"id": "between",
				"label": "between",
				"valueType": "number-range",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"positiveRange"
				]
			}
		],
		"placeholder": "Enter numeric value",
		"search_key": "access_count_identity_as_user",
		"validations": [
			"required"
		],
		"pageType": [
			"Identities"
		],
		"page": "Identities",
		"dataField": {
			"Identities": {
				"id": "access_count_identity_as_user",
				"type": "NUMBER"
			}
		},
		"key": "access_count_identity_as_user",
		"value": "Number of Accesses as a User",
		"groupBy": "Identity/Source"
	},
	{
		"label": "Number of Accesses as a Service",
		"operators": [
			{
				"id": "=",
				"label": "=",
				"valueType": "number",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"numbersWithRange"
				]
			},
			{
				"id": "<",
				"label": "<",
				"valueType": "number",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"positiveNumbersOnly",
					"greaterThanZero"
				]
			},
			{
				"id": "<=",
				"label": "<=",
				"valueType": "number",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"positiveNumbersOnly"
				]
			},
			{
				"id": ">",
				"label": ">",
				"valueType": "number",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"positiveNumbersOnly"
				]
			},
			{
				"id": ">=",
				"label": ">=",
				"valueType": "number",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"positiveNumbersOnly"
				]
			},
			{
				"id": "between",
				"label": "between",
				"valueType": "number-range",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"positiveRange"
				]
			}
		],
		"placeholder": "Enter numeric value",
		"search_key": "access_count_identity_as_service",
		"validations": [
			"required"
		],
		"pageType": [
			"Identities"
		],
		"page": "Identities",
		"dataField": {
			"Identities": {
				"id": "access_count_identity_as_service",
				"type": "NUMBER"
			}
		},
		"key": "access_count_identity_as_service",
		"value": "Number of Accesses as a Service",
		"groupBy": "Identity/Source"
	},
	{
		"label": "Number of Assets Accessed",
		"operators": [
			{
				"id": "=",
				"label": "=",
				"valueType": "number",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"numbersWithRange"
				]
			},
			{
				"id": "<",
				"label": "<",
				"valueType": "number",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"positiveNumbersOnly",
					"greaterThanZero"
				]
			},
			{
				"id": "<=",
				"label": "<=",
				"valueType": "number",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"positiveNumbersOnly"
				]
			},
			{
				"id": ">",
				"label": ">",
				"valueType": "number",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"positiveNumbersOnly"
				]
			},
			{
				"id": ">=",
				"label": ">=",
				"valueType": "number",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"positiveNumbersOnly"
				]
			},
			{
				"id": "between",
				"label": "between",
				"valueType": "number-range",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"positiveRange"
				]
			}
		],
		"placeholder": "Enter numeric value",
		"search_key": "asset_count",
		"validations": [
			"required"
		],
		"pageType": [
			"Identities"
		],
		"key": "asset_count",
		"value": "Number of Assets Accessed",
		"groupBy": "Identity/Source"
	},
	{
		"label": "Number of Hosts Accessed",
		"operators": [
			{
				"id": "=",
				"label": "=",
				"valueType": "number",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"numbersWithRange"
				]
			},
			{
				"id": "<",
				"label": "<",
				"valueType": "number",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"positiveNumbersOnly",
					"greaterThanZero"
				]
			},
			{
				"id": "<=",
				"label": "<=",
				"valueType": "number",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"positiveNumbersOnly"
				]
			},
			{
				"id": ">",
				"label": ">",
				"valueType": "number",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"positiveNumbersOnly"
				]
			},
			{
				"id": ">=",
				"label": ">=",
				"valueType": "number",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"positiveNumbersOnly"
				]
			},
			{
				"id": "between",
				"label": "between",
				"valueType": "number-range",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"positiveRange"
				]
			}
		],
		"placeholder": "Enter numeric value",
		"search_key": "asset_host_count",
		"validations": [
			"required"
		],
		"pageType": [
			"Identities"
		],
		"dataField": {
			"Identities": {
				"id": "asset_host_count",
				"type": "NUMBER"
			}
		},
		"key": "asset_host_count",
		"value": "Number of Hosts Accessed",
		"groupBy": "Identity/Source"
	},
	{
		"label": "Number of Identity Hosts",
		"operators": [
			{
				"id": "=",
				"label": "=",
				"valueType": "number",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"numbersWithRange"
				]
			},
			{
				"id": "<",
				"label": "<",
				"valueType": "number",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"positiveNumbersOnly",
					"greaterThanZero"
				]
			},
			{
				"id": "<=",
				"label": "<=",
				"valueType": "number",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"positiveNumbersOnly"
				]
			},
			{
				"id": ">",
				"label": ">",
				"valueType": "number",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"positiveNumbersOnly"
				]
			},
			{
				"id": ">=",
				"label": ">=",
				"valueType": "number",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"positiveNumbersOnly"
				]
			},
			{
				"id": "between",
				"label": "between",
				"valueType": "number-range",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"positiveRange"
				]
			}
		],
		"placeholder": "Enter numeric value",
		"search_key": "identity_host_count",
		"validations": [
			"required"
		],
		"pageType": [
			"Identities"
		],
		"dataField": {
			"Identities": {
				"id": "identity_host_count",
				"type": "NUMBER"
			}
		},
		"key": "identity_host_count",
		"value": "Number of Identity Hosts",
		"groupBy": "Identity/Source"
	},
	{
		"id": "directory",
		"label": "Directory",
		"isGroupRoot": true,
		"value": "Directory"
	},
	{
		"label": "Directory Server Name",
		"operators": [
			{
				"id": "equals",
				"label": "equals",
				"valueType": "text-array"
			},
			{
				"id": "not_equals",
				"label": "not equals",
				"valueType": "text-array"
			},
			{
				"id": "matches",
				"label": "matches",
				"valueType": "text-array"
			},
			{
				"id": "not_matches",
				"label": "not matches",
				"valueType": "text-array"
			}
		],
		"placeholder": "Enter comma-separated values",
		"search_key": "dir_server_name",
		"validations": [
			"required"
		],
		"pageType": [
			"Directory",
			"Accesses"
		],
		"key": "dir_server_name",
		"value": "Directory Server Name",
		"groupBy": "Directory"
	},
	{
		"label": "Directory Hostname",
		"operators": [
			{
				"id": "equals",
				"label": "equals",
				"valueType": "text-array"
			},
			{
				"id": "not_equals",
				"label": "not equals",
				"valueType": "text-array"
			},
			{
				"id": "matches",
				"label": "matches",
				"valueType": "text-array"
			},
			{
				"id": "not_matches",
				"label": "not matches",
				"valueType": "text-array"
			}
		],
		"placeholder": "Enter comma-separated values",
		"search_key": "dir_host_name",
		"validations": [
			"required"
		],
		"pageType": [
			"Directory",
			"Accesses"
		],
		"key": "dir_host_name",
		"value": "Directory Hostname",
		"groupBy": "Directory"
	},
	{
		"label": "Directory Name",
		"operators": [
			{
				"id": "equals",
				"label": "equals",
				"valueType": "text-array"
			},
			{
				"id": "not_equals",
				"label": "not equals",
				"valueType": "text-array"
			},
			{
				"id": "matches",
				"label": "matches",
				"valueType": "text-array"
			},
			{
				"id": "not_matches",
				"label": "not matches",
				"valueType": "text-array"
			}
		],
		"placeholder": "Enter comma-separated values",
		"search_key": "dir_name",
		"validations": [
			"required"
		],
		"pageType": [
			"Directory",
			"Accesses"
		],
		"key": "dir_name",
		"value": "Directory Name",
		"groupBy": "Directory"
	},
	{
		"label": "Directory Domain",
		"operators": [
			{
				"id": "equals",
				"label": "equals",
				"valueType": "text-array"
			},
			{
				"id": "not_equals",
				"label": "not equals",
				"valueType": "text-array"
			},
			{
				"id": "matches",
				"label": "matches",
				"valueType": "text-array"
			},
			{
				"id": "not_matches",
				"label": "not matches",
				"valueType": "text-array"
			}
		],
		"placeholder": "Enter comma-separated values",
		"search_key": "domain",
		"validations": [
			"required"
		],
		"pageType": [
			"Directory",
			"Accesses"
		],
		"key": "domain",
		"value": "Directory Domain",
		"groupBy": "Directory"
	},
	{
		"label": "Directory Type",
		"operators": [
			{
				"id": "equals",
				"label": "equals",
				"valueType": "select"
			}
		],
		"search_key": "dir_type",
		"validations": [
			"required"
		],
		"pageType": [
			"Directory",
			"Accesses"
		],
		"key": "dir_type",
		"value": "Directory Type",
		"groupBy": "Directory"
	},
	{
		"label": "Directory Services (Protocol)",
		"operators": [
			{
				"id": "equals",
				"label": "equals",
				"valueType": "array-autocomplete"
			},
			{
				"id": "not_equals",
				"label": "not equals",
				"valueType": "array-autocomplete"
			}
		],
		"search_key": "dir_services",
		"validations": [
			"required"
		],
		"pageType": [
			"Directory",
			"Accesses"
		],
		"key": "dir_services",
		"value": "Directory Services (Protocol)",
		"groupBy": "Directory"
	},
	{
		"label": "Directory Server IP",
		"operators": [
			{
				"id": "equals",
				"label": "equals",
				"valueType": "text-array"
			},
			{
				"id": "not_equals",
				"label": "not equals",
				"valueType": "text-array"
			},
			{
				"id": "matches",
				"label": "matches",
				"valueType": "text-array"
			},
			{
				"id": "not_matches",
				"label": "not matches",
				"valueType": "text-array"
			}
		],
		"placeholder": "Enter comma-separated values",
		"search_key": "dir_ip",
		"validations": [
			"required"
		],
		"pageType": [
			"Directory",
			"Accesses"
		],
		"key": "dir_ip",
		"value": "Directory Server IP",
		"groupBy": "Directory"
	},
	{
		"label": "Directory Auth Flow Count",
		"operators": [
			{
				"id": "=",
				"label": "=",
				"valueType": "number",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"numbersWithRange"
				]
			},
			{
				"id": "<",
				"label": "<",
				"valueType": "number",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"positiveNumbersOnly",
					"greaterThanZero"
				]
			},
			{
				"id": "<=",
				"label": "<=",
				"valueType": "number",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"positiveNumbersOnly"
				]
			},
			{
				"id": ">",
				"label": ">",
				"valueType": "number",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"positiveNumbersOnly"
				]
			},
			{
				"id": ">=",
				"label": ">=",
				"valueType": "number",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"positiveNumbersOnly"
				]
			},
			{
				"id": "between",
				"label": "between",
				"valueType": "number-range",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"positiveRange"
				]
			}
		],
		"search_key": "num_of_auth_flow",
		"validations": [
			"required",
			"positiveRange"
		],
		"placeholder": "Enter numeric value",
		"pageType": [
			"Directory",
			"Accesses"
		],
		"dataField": {
			"Accesses": {
				"id": "directory_flow_unknown_count",
				"type": "NUMBER"
			}
		},
		"key": "num_of_auth_flow",
		"value": "Directory Auth Flow Count",
		"groupBy": "Directory"
	},
	{
		"label": "Directory Auth Success Flow Count",
		"operators": [
			{
				"id": "=",
				"label": "=",
				"valueType": "number",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"numbersWithRange"
				]
			},
			{
				"id": "<",
				"label": "<",
				"valueType": "number",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"positiveNumbersOnly",
					"greaterThanZero"
				]
			},
			{
				"id": "<=",
				"label": "<=",
				"valueType": "number",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"positiveNumbersOnly"
				]
			},
			{
				"id": ">",
				"label": ">",
				"valueType": "number",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"positiveNumbersOnly"
				]
			},
			{
				"id": ">=",
				"label": ">=",
				"valueType": "number",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"positiveNumbersOnly"
				]
			},
			{
				"id": "between",
				"label": "between",
				"valueType": "number-range",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"positiveRange"
				]
			}
		],
		"search_key": "num_of_auth_success_flow",
		"validations": [
			"required",
			"positiveRange"
		],
		"placeholder": "Enter numeric value",
		"pageType": [
			"Directory",
			"Accesses"
		],
		"dataField": {
			"Accesses": {
				"id": "directory_flow_success_count",
				"type": "NUMBER"
			},
			"Directory": {
				"id": "flow_success_count",
				"type": "NUMBER"
			}
		},
		"key": "num_of_auth_success_flow",
		"value": "Directory Auth Success Flow Count",
		"groupBy": "Directory"
	},
	{
		"label": "Directory Auth Failure Flow Count",
		"operators": [
			{
				"id": "=",
				"label": "=",
				"valueType": "number",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"numbersWithRange"
				]
			},
			{
				"id": "<",
				"label": "<",
				"valueType": "number",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"positiveNumbersOnly",
					"greaterThanZero"
				]
			},
			{
				"id": "<=",
				"label": "<=",
				"valueType": "number",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"positiveNumbersOnly"
				]
			},
			{
				"id": ">",
				"label": ">",
				"valueType": "number",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"positiveNumbersOnly"
				]
			},
			{
				"id": ">=",
				"label": ">=",
				"valueType": "number",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"positiveNumbersOnly"
				]
			},
			{
				"id": "between",
				"label": "between",
				"valueType": "number-range",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"positiveRange"
				]
			}
		],
		"search_key": "num_of_auth_failed_flow",
		"validations": [
			"required",
			"positiveRange"
		],
		"placeholder": "Enter numeric value",
		"pageType": [
			"Directory",
			"Accesses"
		],
		"dataField": {
			"Accesses": {
				"id": "directory_flow_fail_count",
				"type": "NUMBER"
			},
			"Directory": {
				"id": "flow_fail_count",
				"type": "NUMBER"
			}
		},
		"key": "num_of_auth_failed_flow",
		"value": "Directory Auth Failure Flow Count",
		"groupBy": "Directory"
	},
	{
		"casing": "camel",
		"label": "Directory Category",
		"operators": [
			{
				"id": "equals",
				"label": "equals",
				"valueType": "multi-select"
			},
			{
				"id": "not_equals",
				"label": "not equals",
				"valueType": "multi-select"
			}
		],
		"search_key": "dir_category",
		"validations": [
			"required"
		],
		"pageType": [
			"Accesses",
			"Directory"
		],
		"key": "dir_category",
		"value": "Directory Category",
		"groupBy": "Directory",
		placeholder: "Select directory category"
	},
	{
		"label": "Number of Identities authenticated using directory",
		"operators": [
			{
				"id": "=",
				"label": "=",
				"valueType": "number",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"numbersWithRange"
				]
			},
			{
				"id": "<",
				"label": "<",
				"valueType": "number",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"positiveNumbersOnly",
					"greaterThanZero"
				]
			},
			{
				"id": "<=",
				"label": "<=",
				"valueType": "number",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"positiveNumbersOnly"
				]
			},
			{
				"id": ">",
				"label": ">",
				"valueType": "number",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"positiveNumbersOnly"
				]
			},
			{
				"id": ">=",
				"label": ">=",
				"valueType": "number",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"positiveNumbersOnly"
				]
			},
			{
				"id": "between",
				"label": "between",
				"valueType": "number-range",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"positiveRange"
				]
			}
		],
		"search_key": "num_of_identities_authenticated",
		"validations": [
			"required",
			"positiveRange"
		],
		"placeholder": "Enter numeric value",
		"pageType": [
			"Directory"
		],
		"key": "num_of_identities_authenticated",
		"value": "Number of Identities authenticated using directory",
		"groupBy": "Directory"
	},
	{
		"label": "Number of Assets authenticated using directory",
		"operators": [
			{
				"id": "=",
				"label": "=",
				"valueType": "number",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"numbersWithRange"
				]
			},
			{
				"id": "<",
				"label": "<",
				"valueType": "number",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"positiveNumbersOnly",
					"greaterThanZero"
				]
			},
			{
				"id": "<=",
				"label": "<=",
				"valueType": "number",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"positiveNumbersOnly"
				]
			},
			{
				"id": ">",
				"label": ">",
				"valueType": "number",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"positiveNumbersOnly"
				]
			},
			{
				"id": ">=",
				"label": ">=",
				"valueType": "number",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"positiveNumbersOnly"
				]
			},
			{
				"id": "between",
				"label": "between",
				"valueType": "number-range",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"positiveRange"
				]
			}
		],
		"search_key": "num_of_assets_authenticated",
		"validations": [
			"required",
			"positiveRange"
		],
		"placeholder": "Enter numeric value",
		"pageType": [
			"Directory"
		],
		"key": "num_of_assets_authenticated",
		"value": "Number of Assets authenticated using directory",
		"groupBy": "Directory"
	},
	{
		"label": "Number of Service Authentications",
		"operators": [
			{
				"id": "=",
				"label": "=",
				"valueType": "number",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"numbersWithRange"
				]
			},
			{
				"id": "<",
				"label": "<",
				"valueType": "number",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"positiveNumbersOnly",
					"greaterThanZero"
				]
			},
			{
				"id": "<=",
				"label": "<=",
				"valueType": "number",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"positiveNumbersOnly"
				]
			},
			{
				"id": ">",
				"label": ">",
				"valueType": "number",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"positiveNumbersOnly"
				]
			},
			{
				"id": ">=",
				"label": ">=",
				"valueType": "number",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"positiveNumbersOnly"
				]
			},
			{
				"id": "between",
				"label": "between",
				"valueType": "number-range",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"positiveRange"
				]
			}
		],
		"placeholder": "Enter numeric value",
		"search_key": "access_count_identity_as_service",
		"validations": [
			"required"
		],
		"pageType": [
			"Directory"
		],
		"page": "Directory",
		"dataField": {
			"Directory": {
				"id": "access_count_identity_as_service",
				"type": "NUMBER"
			}
		},
		"key": "access_count_identity_as_service",
		"value": "Number of Service Authentications",
		"groupBy": "Directory"
	},
	{
		"label": "Number of User Authentications",
		"operators": [
			{
				"id": "=",
				"label": "=",
				"valueType": "number",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"numbersWithRange"
				]
			},
			{
				"id": "<",
				"label": "<",
				"valueType": "number",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"positiveNumbersOnly",
					"greaterThanZero"
				]
			},
			{
				"id": "<=",
				"label": "<=",
				"valueType": "number",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"positiveNumbersOnly"
				]
			},
			{
				"id": ">",
				"label": ">",
				"valueType": "number",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"positiveNumbersOnly"
				]
			},
			{
				"id": ">=",
				"label": ">=",
				"valueType": "number",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"positiveNumbersOnly"
				]
			},
			{
				"id": "between",
				"label": "between",
				"valueType": "number-range",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"positiveRange"
				]
			}
		],
		"placeholder": "Enter numeric value",
		"search_key": "access_count_identity_as_user",
		"validations": [
			"required"
		],
		"pageType": [
			"Directory"
		],
		"page": "Directory",
		"dataField": {
			"Directory": {
				"id": "access_count_identity_as_user",
				"type": "NUMBER"
			}
		},
		"key": "access_count_identity_as_user",
		"value": "Number of User Authentications",
		"groupBy": "Directory"
	},
	{
		"id": "misc",
		"label": "Miscellaneous",
		"isGroupRoot": true,
		"value": "Miscellaneous"
	},
	{
		"label": "Any Field",
		"operators": [
			{
				"id": "matches",
				"label": "matches",
				"valueType": "text-array"
			}
		],
		"placeholder": "Enter comma-separated values",
		"search_key": "any_field",
		"validations": [
			"required"
		],
		"pageType": [
			"All"
		],
		"key": "any_field",
		"value": "Any Field",
		"groupBy": "Miscellaneous"
	},
	{
		"label": "Auth Failure %",
		"operators": [
			{
				"id": "=",
				"label": "=",
				"valueType": "number",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"percentWithRange"
				]
			},
			{
				"id": "<",
				"label": "<",
				"valueType": "number",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"positiveNumbersOnly",
					"numbersBetweenOneToHundred"
				]
			},
			{
				"id": "<=",
				"label": "<=",
				"valueType": "number",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"positiveNumbersOnly",
					"numbersBetweenZeroHundred"
				]
			},
			{
				"id": ">",
				"label": ">",
				"valueType": "number",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"positiveNumbersOnly",
					"numbersBetweenZeroNightyNine"
				]
			},
			{
				"id": ">=",
				"label": ">=",
				"valueType": "number",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"positiveNumbersOnly",
					"numbersBetweenZeroHundred"
				]
			},
			{
				"id": "between",
				"label": "between",
				"valueType": "number-range",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"positiveRange"
				]
			}
		],
		"placeholder": "Enter numeric value",
		"search_key": "auth_failed_percent",
		"key": "auth_failed_percent",
		"validations": [
			"required"
		],
		"pageType": [
			"All"
		],
		"dataField": {
			"Accesses": {
				"id": "auth_failed_percent",
				"type": "PERCENT"
			},
			"Assets": {
				"id": "auth_failed_percent",
				"type": "PERCENT"
			},
			"Identities": {
				"id": "auth_failed_percent",
				"type": "PERCENT"
			}
		},
		"value": "Auth Failure %",
		"groupBy": "Miscellaneous"
	},
	{
		"label": "First Activity Time",
		"operators": [
			{
				"id": "<",
				"label": "<",
				"valueType": "datetime",
				"validations": [
					"required"
				]
			},
			{
				"id": "<=",
				"label": "<=",
				"valueType": "datetime",
				"validations": [
					"required"
				]
			},
			{
				"id": ">",
				"label": ">",
				"valueType": "datetime",
				"validations": [
					"required"
				]
			},
			{
				"id": ">=",
				"label": ">=",
				"valueType": "datetime",
				"validations": [
					"required"
				]
			},
			{
				"id": "between",
				"label": "between",
				"valueType": "datetime-range",
				"validations": [
					"required",
					"dateRange"
				]
			},
			{
				"id": "last_in",
				"label": "in last",
				"valueType": "time-number",
				"validations": [
					"required",
					"positiveNumbersLastIn",
					"maxSixtyDays"
				],
				"placeholder": "Enter numeric value"
			}
		],
		"search_key": "first_activity_time",
		"validations": [
			"required",
			"dateRange"
		],
		"placeholder": "Enter numeric value",
		"pageType": [
			"All"
		],
		"dataField": {
			"Accesses": {
				"id": "first_activity_time",
				"type": "DATE"
			},
			"Assets": {
				"id": "first_activity_time",
				"type": "DATE"
			},
			"Directory": {
				"id": "first_activity_time",
				"type": "DATE"
			},
			"Identities": {
				"id": "first_activity_time",
				"type": "DATE"
			}
		},
		"key": "first_activity_time",
		"value": "First Activity Time",
		"groupBy": "Miscellaneous"
	},
	{
		"label": "Latest Activity Time",
		"operators": [
			{
				"id": "<",
				"label": "<",
				"valueType": "datetime",
				"validations": [
					"required"
				]
			},
			{
				"id": "<=",
				"label": "<=",
				"valueType": "datetime",
				"validations": [
					"required"
				]
			},
			{
				"id": ">",
				"label": ">",
				"valueType": "datetime",
				"validations": [
					"required"
				]
			},
			{
				"id": ">=",
				"label": ">=",
				"valueType": "datetime",
				"validations": [
					"required"
				]
			},
			{
				"id": "between",
				"label": "between",
				"valueType": "datetime-range",
				"validations": [
					"required",
					"dateRange"
				]
			},
			{
				"id": "last_in",
				"label": "in last",
				"valueType": "time-number",
				"validations": [
					"required",
					"positiveNumbersLastIn"
				],
				"placeholder": "Enter numeric value"
			}
		],
		"search_key": "latest_activity_time",
		"validations": [
			"required",
			"dateRange"
		],
		"placeholder": "Enter numeric value",
		"pageType": [
			"All"
		],
		"key": "latest_activity_time",
		"value": "Latest Activity Time",
		"groupBy": "Miscellaneous"
	},
	{
		"label": "Matching Issue",
		operators: [
			{
				id: 'equals',
				label: 'equals',
				valueType: 'array-autocomplete'
			},
			{
				id: 'not_equals',
				label: 'not equals',
				valueType: 'array-autocomplete'
			}
		],
		"search_key": "rule_name",
		"validations": [
			"required"
		],
		"pageType": [
			"All"
		],
		"key": "rule_name",
		"value": "Matching Issue",
		"groupBy": "Miscellaneous"
	},
	{
		"label": "Number of Flows",
		"operators": [
			{
				"id": "=",
				"label": "=",
				"valueType": "number",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"numbersWithRange"
				]
			},
			{
				"id": "<",
				"label": "<",
				"valueType": "number",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"positiveNumbersOnly",
					"greaterThanZero"
				]
			},
			{
				"id": "<=",
				"label": "<=",
				"valueType": "number",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"positiveNumbersOnly"
				]
			},
			{
				"id": ">",
				"label": ">",
				"valueType": "number",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"positiveNumbersOnly"
				]
			},
			{
				"id": ">=",
				"label": ">=",
				"valueType": "number",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"positiveNumbersOnly"
				]
			},
			{
				"id": "between",
				"label": "between",
				"valueType": "number-range",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"positiveRange"
				]
			}
		],
		"search_key": "num_of_flows",
		"validations": [
			"required"
		],
		"placeholder": "Enter numeric value",
		"pageType": [
			"All"
		],
		"key": "num_of_flows",
		"value": "Number of Flows",
		"groupBy": "Miscellaneous"
	},
	{
		"label": "Number of Incidents",
		"operators": [
			{
				"id": "=",
				"label": "=",
				"valueType": "number",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"numbersWithRange"
				]
			},
			{
				"id": "<",
				"label": "<",
				"valueType": "number",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"positiveNumbersOnly"
				]
			},
			{
				"id": "<=",
				"label": "<=",
				"valueType": "number",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"positiveNumbersOnly"
				]
			},
			{
				"id": ">",
				"label": ">",
				"valueType": "number",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"positiveNumbersOnly"
				]
			},
			{
				"id": ">=",
				"label": ">=",
				"valueType": "number",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"positiveNumbersOnly"
				]
			},
			{
				"id": "between",
				"label": "between",
				"valueType": "number-range",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"positiveRange"
				]
			}
		],
		"search_key": "num_of_incidents",
		"validations": [
			"required",
			"positiveRange"
		],
		"placeholder": "Enter numeric value",
		"pageType": [
			"All"
		],
		"key": "num_of_incidents",
		"value": "Number of Incidents",
		"groupBy": "Miscellaneous"
	},
	{
		"label": "Playbook Names",
		"operators": [
			{
				"id": "equals",
				"label": "equals",
				"valueType": "multi-select"
			},
			{
				"id": "not_equals",
				"label": "not equals",
				"valueType": "multi-select"
			}
		],
		"search_key": "identity_playbooks",
		"validations": [
			"required"
		],
		"pageType": [
			"Identities"
		],
		"key": "identity_playbooks",
		"value": "Playbook Names",
		"groupBy": "Miscellaneous"
	},
	{
		"label": "Playbook Names",
		"operators": [
			{
				"id": "equals",
				"label": "equals",
				"valueType": "multi-select"
			},
			{
				"id": "not_equals",
				"label": "not equals",
				"valueType": "multi-select"
			}
		],
		"search_key": "access_playbooks",
		"validations": [
			"required"
		],
		"pageType": [
			"Accesses"
		],
		"key": "access_playbooks",
		"value": "Playbook Names",
		"groupBy": "Miscellaneous"
	},
	{
		"label": "Playbook Names",
		"operators": [
			{
				"id": "equals",
				"label": "equals",
				"valueType": "multi-select"
			},
			{
				"id": "not_equals",
				"label": "not equals",
				"valueType": "multi-select"
			}
		],
		"search_key": "asset_playbooks",
		"validations": [
			"required"
		],
		"pageType": [
			"Assets"
		],
		"key": "asset_playbooks",
		"value": "Playbook Names",
		"groupBy": "Miscellaneous"
	},
	{
		"label": "Playbook Names",
		"operators": [
			{
				"id": "equals",
				"label": "equals",
				"valueType": "multi-select"
			},
			{
				"id": "not_equals",
				"label": "not equals",
				"valueType": "multi-select"
			}
		],
		"search_key": "directory_playbooks",
		"validations": [
			"required"
		],
		"pageType": [
			"Directory"
		],
		"key": "directory_playbooks",
		"value": "Playbook Names",
		"groupBy": "Miscellaneous"
	},
	{
		"casing": "camel",
		"label": "Risk",
		"operators": [
			{
				"id": "equals",
				"label": "equals",
				"valueType": "multi-select"
			}
		],
		"search_key": "risk",
		"validations": [
			"required"
		],
		"pageType": [
			"All"
		],
		"key": "risk",
		"value": "Risk",
		"groupBy": "Miscellaneous"
	},
	{
		"label": "Sensor Site Code",
		"operators": [
			{
				"id": "equals",
				"label": "equals",
				"valueType": "multi-select"
			},
			{
				"id": "not_equals",
				"label": "not equals",
				"valueType": "multi-select"
			}
		],
		"search_key": "loc",
		"validations": [
			"required"
		],
		"pageType": [
			"Identities",
			"Accesses",
			"Assets"
		],
		"key": "loc",
		"value": "Sensor Site Code",
		"groupBy": "Miscellaneous",
		placeholder: "Select sensor site code"
	},
	{
		label: 'Time Based Search Preset',
		operators: [
			{
				id: 'equals',
				label: 'equals',
				valueType: 'select'
			}
		],
		search_key: 'any_activity_time',
		validations: ['required'],
		pageType: ['All']
	}
];

export const notSupportedKeys = [
	{
		operator: "equals", // default for textbox search
		search_key: "any_activity_time",
		valueType: "select",
	},
	{
		operator: "equals", // default for textbox search
		search_key: "e_time",
		valueType: "datetime",
	},
	{
		operator: "equals", // default for textbox search
		search_key: "s_time",
		valueType: "datetime",
	},
	{
		operator: "equals", // default for textbox search
		search_key: "is_external",
		valueType: "bool",
	},
	{
		operator: "equals", // default for textbox search
		search_key: "root_issue_id",
		valueType: "number",
	},
	{
		operator: "equals", // default for textbox search
		search_key: "hash",
		valueType: "text",
	},
	{
		operator: "equals", // default for textbox search
		search_key: "exception_id",
		valueType: "text",
	},
	{
		operator: "equals", // default for textbox search
		search_key: "dir_protocol",
		valueType: "text",
	},
	{
		operator: "equals", // default for textbox search
		search_key: "dir_present",
		valueType: "text",
	},
	{
		operator: "equals", // default for textbox search
		search_key: "directories",
		valueType: "text",
	},
	{
		operator: "equals", // default for textbox search
		search_key: "s_sub_type",
		valueType: "text",
	},
	{
		operator: "equals", // default for textbox search
		search_key: "t_protocol",
		valueType: "text",
	},
	{
		operator: "equals", // default for textbox search
		search_key: "e_m_time",
		valueType: "datetime",
	},
	{
		operator: "equals", // default for textbox search
		search_key: "s_m_time",
		valueType: "datetime",
	}
];
