import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { useToasts } from "../../components/core";
import { Api } from "../../components/Axios";
import { AMCIDRHelpText } from "../ref/AMCIDRHelpText";
import { KnownPublicIpsCollection, KnownPublicIpsFormProps } from "./KnownPublicIpTypes";
import './known-public-ip-form.scss';
import AMTagInput from "../../components/core/AMTagInput/AMTagInput";

export const KnownPublicIpForm = ({ knownPublicData, onFormActionSuccess }: KnownPublicIpsFormProps) => {
    const [loading, setLoading] = useState<Boolean>(false);
    const [ips, setIps] = useState<string[]>([]);
    const { addToast } = useToasts();
    const { register, handleSubmit, errors, setValue, setError } = useForm<KnownPublicIpsCollection>();
    const [errorState, setErrorState] = useState("");

    useEffect(() => {
        if (knownPublicData) {
            setValue("comment", knownPublicData.comment);
            setValue("ips", knownPublicData.ips);
            setIps(knownPublicData.ips);
        }
    }, []);

    const updateKnownPublicIps = (data: any) => {
        if (!ips || ips.length == 0) {
            addToast("Please add ips.", {
                appearance: "error",
                autoDismiss: true,
            });
            return;
        }
        setLoading(true);
        data["ips"] = ips;
        const headers = { Operation: "PUT" };
        Api.post(`/knownpublicip/${knownPublicData?._id}`, data, { headers })
            .then((res: { data: any }) => {
                onFormActionSuccess();
                setLoading(false);
                addToast("Known public ips updated successfully.", {
                    appearance: "success",
                    autoDismiss: true,
                });
            })
            .catch((error: any) => {
                handleError(error);
            });
    };

    const addKnownPublicIps = (data: any) => {
        if (!ips || ips.length == 0) {
            addToast("Please add ips.", {
                appearance: "error",
                autoDismiss: true,
            });
            return;
        }
        setLoading(true);
        data["ips"] = ips;
        Api.post("/knownpublicip", data)
            .then((res: { data: any }) => {
                setLoading(false);
                onFormActionSuccess();
                addToast("Known public ips added successfully.", {
                    appearance: "success",
                    autoDismiss: true,
                });
            })
            .catch((error: any) => {
                handleError(error);
            });
    };

    const handleError = (error: any) => {
        setLoading(false);
        if (error?.response?.data) {
            addToast(error.response.data, {
                appearance: "error",
                autoDismiss: true,
            });
        } else if (error?.response?.status === 500) {
            addToast("Sorry, something went wrong there, try again.", {
                appearance: "error",
                autoDismiss: true,
            });
        } else if (error?.response?.status === 419) {
            addToast("We encounted validation problem, please correct and try again.", {
                appearance: "error",
                autoDismiss: true,
            });
        } else if (error?.response?.status === 404) {
            addToast("We are not able to find associated email, please check and try again.", {
                appearance: "error",
                autoDismiss: true,
            });
        } else if (error?.response?.status == 409) {
            addToast(error.response.data, {
                appearance: "error",
                autoDismiss: true,
            });
        }
    };

    const onAddPublicKnownIps = (data: any) => {
        if (knownPublicData?._id) {
            updateKnownPublicIps(data);
        } else {
            addKnownPublicIps(data);
        }
    };

    const AddTags = (tag: any) => {
        const tempTags = tag.filter((item: string) => item && item.trim().length > 0);
        setIps(tempTags);
        setErrorState("");
    };

    const onValidationReject = () => {
        setErrorState("Invalid Public IPs/IP Ranges/FQDNs/Patterns.");
    };

    return (
        <form onSubmit={handleSubmit(onAddPublicKnownIps)}>
            <div className="known-public-ip-form-container">
                <div className="form-control-group-left">
                    <div className="form-control">
                        <label>Comment</label>
                        <input type="text" placeholder="Enter comment" name="comment" ref={register} className={errors.comment ? "error" : ""} />
                    </div>
                    <div className="form-control">
                        <label className="margin-10" style={{ display: "flex" }}>
                            Public IPs/IP Ranges/FQDNs/Patterns
                            <AMCIDRHelpText />
                        </label>
                        <AMTagInput
                            value={ips}
                            onChange={AddTags}
                            renderLayout={(tagElements, inputElement) => {
                                return (
                                    <span>
                                        <div
                                            className="scrollbar-container directory-tag-container">
                                            {tagElements}
                                        </div>
                                        <div>
                                        {inputElement}
                                        </div>
                                    </span>
                                )
                            }}
                            inputProps={{ placeholder: "Add IP address, etc." }}
                            onValidationReject={onValidationReject}
                            // validationRegex={(/^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\-][^'"]*[a-zA-Z0-9])\.)*([\*]|[A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9\-]*[A-Za-z0-9])*([/0-9]*)$/)}
                            validationRegex={/^(([\*]|[a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\-][^'"]*[a-zA-Z0-9]*[^'"])\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9\-]*[A-Za-z0-9])*([/0-9]*)$/}
                        />
                        <div className="marginleft40per error">Note: Hit the Enter/Return, Tab, Space, or Comma key after entering or copy-pasting one or more IPs (separated by comma, space, or newline).</div>
                    </div>
                </div>
            </div>

            {Object.keys(errors || {}).length > 0 && (
                <div className="errors-list-container">

                    {errorState && <div className={`error marginleft40per ${errorState && "dot"}`}>{errorState}</div>}

                </div>
            )}

            <div className="action-buttons action-button dialog-button">
                    <button type={loading ? "button" : "submit"} className={"float_right " + (loading ? "loader" : "button_styled")}>
                        Save
                    </button>
            </div>
        </form>
    );
};
