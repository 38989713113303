import InfoIcon from '@mui/icons-material/Info';
import { Chip, Tooltip } from '@mui/material';
import moment from 'moment';
import * as qs from "qs";
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { CSVLink } from "react-csv";
import { Data } from 'react-csv/components/CommonPropTypes';
import ReactPaginate from 'react-paginate';
import { Route, Switch, useHistory } from 'react-router';
import { Link, useLocation } from "react-router-dom";
import ReactTooltip from 'react-tooltip';
import { ArrayParam, NumberParam, StringParam, withDefault, withQueryParams } from 'use-query-params';
import { IDScore } from '../../../common/IDScore/IDScore';
import { Api } from '../../../components/Axios';
import { useToasts } from '../../../components/core';
import { AMBreadcrumb } from '../../../components/core/AMBreadcrump/AMBreadcrumb';
import { DirectoriesObj, IdAssetWidget, IdentitiesMappingObj, IssueFilters, IssueParams, RulesDetailObj, ServerResponse } from '../../../types/response-types';
import { convertToCommaValue, findDifferenceInDays, deepCompare, findNameById, getTimeDiffString, isIssueType, trimAfterSecondWord, getExportFileName, getCustomLabelForRuleName, getResolutionMatchFor1920 } from '../../../utils/util-methods';
import FlowTrend from '../../issue_prev/flow-trend/flow-trend';
import { DirectoriesActionMenu, DirectoriesColumnActionMenu } from '../constants/IDPostureMenu';
import '../identity_asset.scss';
import '../posture_root_tooltip.css';
import { BasePostureActionMenu } from '../ref/PostureActionMenus/BasePostureActionMenu';
import { PostureMenuItem, PostureMenuItemAction } from '../ref/PostureActionMenus/PostureActionMenu';
import { formatCSVData, getHeaders, useAdditionalResults, useLensSearch, usePostureAdvanceSearch, usePostureArchival, useScrollIssue, validateLocalIps } from '../ref/Hooks/Posture';
import { ScrollIssueData, ScrollIssueHeader, ScrollIssueHeaderRef } from '../ref/ScrollIssue/ScrollIssue';
import { ReportByIdAsset, RuleRootWidget, SummaryWidget } from '../sub';
import AssetAccess from './asset-access-popup/assetaccess';
import IdAccess from './id-access-popup/idaccess';
import RuleMatch from './rule-match/rulematch';
import PostureFilter from '../../../components/core/PostureFilter/PostureFilter';
import { FilterWidget } from '../../../components/core/PostureFilter/PostureFilterWidget';
import { getAdditionalResultCellValue, getSearchFilterObjectFromTags, getTagsForSearchInput, getTagsForTimeBasedSearch, getTimeBasedSearchQueryParam } from '../../../components/core/PostureFilter/posture-filter-utils';
import axios, { AxiosError, CancelToken, CancelTokenSource } from 'axios';
import { usePostureStateContext } from '../../../store/PostureStateContextProvider';
import Tags from '../../issue_prev/issues/tags/tags';
import { PostureTabTooltip } from '../ref/PostureTabTooltip';
import { AMCheckbox } from '../../../components/core/AMCheckbox/AMCheckbox';
import AMMultiCheckbox from '../../../components/core/AMMultiCheckbox/AMMultiCheckbox';
import { PostureActionButtons, PostureArchiveTooltip, IPostureActionButtonsRef } from '../ref/PostureArchive/PostureArchive';
import { IApiResponse, PostureService } from '../ref/Hooks/PostureService';
import { useAuthDetails } from '../../../components/Authorization';
import { PostureCategory } from '../ref/PostureCategories/PostureCategory';
import { PlayBookView } from '../sub/PlayBookView';
import { ENTITIES, POSTURE_ISSUE } from '../constants/Constants';
import { useValidatePostureIssuesSearchResult } from '../ref/Hooks/PostureIssues';
import AdditionalResult from '../sub/AdditionalResult';
import { PlaybookTableActions } from '../../playbooks/ref/AdvancedPlaybook/PlaybookTableActions';
import { IPlaybookActions } from '../../playbooks/ref/AdvancedPlaybook/PlaybookTypes';
import { PostureSearchTemplate, notSupportedKeys } from '../constants/PostureSearchTemplate';
import TimeBasedSearchContainer from '../../../components/TimeBasedSearch/TimeBasedSearchContainer';
import { AVAILABLE_PRESETS, TIME_BASED_SEARCH_FIELD } from '../../../components/TimeBasedSearch/Constants';
import { getIssueNameById } from '../../playbooks/helpers/playbook-helper';
import { useTimeBasedSearchActions } from '../../../components/TimeBasedSearch/TimeBasedSearchActions';
import { IdentitySystemNameCell, IdentitySystemFlowCountCell, IdentitySystemDomainCell, IdentitySystemTypeAuthFailCell } from './grid';
import { IdentitySystemTypeCell } from './grid/IdentitySystemTypeCell';
import { PostureIssueGroupHeader } from '../ref/PostureGrid/PostureIssueGroupHeader';
import { findPbNameById } from '../ref/posture-utils';
import { PostureGridIssueCustomHeader } from '../ref/PostureGrid/PostureGridIssueCustomHeader';
import { ScrollIssueGridRenderer } from '../ref/ScrollIssue/ScrollIssueGrid';
import { PostureIssueCountCell } from '../ref/PostureGrid/grid-common-cells/PostureIssueCountCell';
import { LatestTimeCell } from '../ref/PostureGrid/grid-common-cells/LastestTimeCell';
import { ColDef, IServerSideGetRowsParams } from '@ag-grid-community/core';
import { IdentitySystemIdCell } from './grid/IdentitySystemIdCell';
import { IdentitySystemAssetCell } from './grid/IdentitySystemAssetCell';
import BaseAMAdminGrid from '../../../components/core/AMTable/BaseAMAdminGrid';

const Directory = ({ query, setQuery }: any) => {
	const checkFlag = false;
	const search = useLocation().search;
	const location = useLocation();
	const hard_refresh_state: any = new URLSearchParams(search).get('hard_refresh') === "true" ? true : false;
	const [tableFetchError, setTableFetchError] = useState('No records found.');
	const [showIDAccess, setShowIDAccess] = useState(false);
	const [showAssetAccess, setShowAssetAccess] = useState(false);
	const [issueHeader, setIssueHeaders] = useState<Array<string>>([]);
	const scrollIssueRef = useRef<ScrollIssueHeaderRef>(null);
	const [showAnimation, setShowAnimation] = useState(false);
	const { updateColumnEffect, scrollToIssueColumnFn } = useScrollIssue();
	const [selectedColumn, setSelectedColumn] = useState(query.sort_by || '');
	const [currentSort, setCurrentSort] = useState(query.order_by == 'asc' ?
		' tablesort_up tablesort_up_selected ' : ' tablesort_down tablesort_down_selected ');
	const [searchInput, setSearchInput] = useState<string>('');
	const [tagFlag, setTagFlag] = useState<boolean | undefined>();
	const [openPanelFilterWidgets, setOpenPanelFilterWidgets] = useState(false);
	const [filterData, setFilterData] = useState<IssueFilters | undefined>();
	const disabled_filter_state: boolean = new URLSearchParams(search).get('disable_filter') === "true" ? true : false;
	const [disabledFilter, setDisabledFilter] = useState<boolean>(disabled_filter_state);
	const { handleApplyAdvancedSearch, handleManualSearch, getSearchHeaders,
		preMetaDataHandler,
		PartnerConfigState } = usePostureAdvanceSearch();
	const { PostureSearchState, PostureChartState } = usePostureStateContext();
	const { tags, setTags } = PostureSearchState;
	const { handleLensSearchFn, handleSearchFn } = useLensSearch(searchInput, setTags, setSearchInput, setTagFlag);
	const [isClearAll, setIsClearAll] = useState(false);
	const [additionalResultHighlightClass, setAdditionalResultHighlightClass] = useState('');
	const {
		selectAll,
		onSetSelectRow,
		getSelectRow,
		onSelectAllClick,
		selectAllIndeterminate,
		setArchivalData,
		setOpenIssuesCount,
		onArchive,
		selectCount,
		isIncidentsOpen,
		archivalData,
		onCurrentPageClick,
		resetSelectionState,
		setCurrentPage,
		currentPage,
		onArchiveEstimate,
		onBulkDirectoriesAdd
	} = usePostureArchival('Directory');
	const {
		responseData, setResponseData,
		showAdvanceSearch, setShowAdvanceSearch,
		idWidget, setIdWidget,
		ruleWidget, setRuleWidget,
		ruleRootIdentity, setRuleRootIdentity,
		showGraph, setShowGraph,
		selectedItems, setSelectedItems,
		items, setItems,
		widgetData, setWidgetData,
		totalCount, setTotalCount
	} = PostureChartState;
	const { PostureSummary } = PostureService();
	const { authDetails } = useAuthDetails()!;
	const { isValidResultCountThreshold, matchingPlaybookFound } = useValidatePostureIssuesSearchResult();
	const [selectedField, setSelectedField] = useState(null);
	const [additionalFields, setAdditionalFields] = useState([]);
	const [showAdditionalResult, setShowAdditionalResult] = useState(false);
	const { isPresetPeriodOptionSelected } = useTimeBasedSearchActions();
	const archiveBtnRef = useRef<IPostureActionButtonsRef>(null);
	const MoreActionsOptions: IPlaybookActions<Array<any>> = [{
		actionCallback: () => {
			onBulkDirectoriesAdd()
		},
		actionId: 'mark-knwon',
		actionLabel: 'Add to Known Identity Systems',
		isDisabled: selectCount == 0 || (selectAll && !currentPage) || (!currentPage && selectAllIndeterminate && !selectAll),
		isApplicable: authDetails?.permissions?.Admin?.directories == 'readwrite'
	},
	{
		actionCallback: () => {
			if (archiveBtnRef?.current?.onArchiveBtnClick)
				archiveBtnRef?.current?.onArchiveBtnClick()
		},
		actionId: 'mark-archive',
		actionLabel: 'Archive',
		isDisabled: selectCount == 0,
		isApplicable: authDetails?.permissions?.Posture?.manual_archive == 'readwrite'
	}
	];

	const [zoomLevel, setZoomLevel] = useState('hourly');
	const updatedExtremesRef = useRef({ min: null, max: null });
	const searchParams = new URLSearchParams(location.search);
	const hasReportType = searchParams.has('reportType') && searchParams.get('reportType')?.trim() !== '' ||
		searchParams.has('ruleType') && searchParams.get('ruleType')?.trim() !== '';
	const [editConfig, setEditConfig] = useState(false);

	const highlightSortDir = () => {
		if (query.order_by === 'asc') {
			setCurrentSort(' tablesort_up tablesort_up_selected ');
		} else if (query.order_by === 'desc') {
			setCurrentSort(' tablesort_down tablesort_down_selected ');
		}
	};

	useEffect(() => {
		setShowAdditionalResult(true);
	}, [query.q]);

	useEffect(() => {
		let tempTags: string[] = [];
		if (param.q) {
			// tempTags = [...param.q];
			tempTags = q.split('+').map((i) => decodeURIComponent(i));;
		}
		if (param.rule_name && !tempTags.includes(param.rule_name)) {
			tempTags.push(param.rule_name);
		}
		if (param.risk && !tempTags.includes(param.risk?.toString())) {
			tempTags.push(param.risk?.toString());
		}
		if (param.d_protocol && !tempTags.includes(param.d_protocol)) {
			tempTags.push(param.d_protocol);
		}
		if (param.d_name && !tempTags.includes(param.d_name)) {
			tempTags.push(param.d_name);
		}
		if (param.hash && !tempTags.includes(param.hash)) {
			tempTags.push(param.hash);
		}
		if (
			param.s_time &&
			param.e_time &&
			!tempTags.includes(param.s_time?.toString()) &&
			!tempTags.includes(param.e_time?.toString())
		) {
			tempTags.push(param.s_time?.toString(), param.e_time?.toString());
		}

		if (tempTags.length > 0) {
			setTags(tempTags);
			setTagFlag(false);
		} else {
			setTags([]);
		}

		highlightSortDir();
		const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();
		if (
			!history.location.pathname.includes('matchrule') &&
			!history.location.pathname.includes('flow')
		) {
			/* fetchIdentities(cancelTokenSource.token); */
		}

		return () => {
			cancelTokenSource.cancel('Operation canceled due to component unmounting.');
		};
	}, [query]);

	// useEffect(() => {
	// 	fetchWidget();
	// }, []);

	const scrollToIssueColumn = () => {
		scrollToIssueColumnFn(query, q, scrollIssueRef, 'directories');
	};

	const fetchIdentities = async (queryParams: Record<string, any>, gridParams: IServerSideGetRowsParams, signal: AbortSignal) => {
		setLoading(true);
		setTableFetchError('No records found.');
		const searchHeaders = await getSearchHeaders(queryParams);
		Api.get('/assetsdirectories', {
			params: queryParams,
			paramsSerializer: (params) =>
				qs.stringify(params, { arrayFormat: 'repeat' }),
			signal,
			...searchHeaders
		})
			.then((res) => {
				setLoading(false);
				if (res.status === 200) {
					setTotalCount(res.data.total);
					const d = validateLocalIps(res.data, 'host_detail');
					setResponseData(d);
					setArchivalData(d);
					setOpenIssuesCount(res.data.total);
					setLastUpdate(moment().format('MMM DD YYYY, hh:mm A'));
					if (currentPage) {
						resetSelectionState();
					}
					setCurrentPage(false);
					if (gridParams) {
						const tempData = res.data.result.map((i) => {
							Object.keys(i.rules).forEach((rule) => {
								i[rule] = i.rules[rule]
							})
							return i
						})
						gridParams.success({
							rowData: tempData,
							rowCount: res.data.total
						});
						if (gridRef?.current?.api) {
							gridRef.current.api.hideOverlay();
							if (res.data.total === 0) {
								gridRef.current.api.showNoRowsOverlay();
							}
						}
						setQuery({ ...query, ...queryParams })
					}
				}
			})
			.catch((err: AxiosError) => {
				console.log(err);
				if (err.code === 'ERR_CANCELED') {
					return
				}
				setLoading(false);
				setResponseData({ result: 'error' } as ServerResponse);
				addToast('Error while fetching data.', {
					appearance: 'error',
					autoDismiss: true
				});
				setTableFetchError(`An error occurred while processing your request. Please check your input and try again. If the error persists, please reach out to ${PartnerConfigState?.PartnerShortProduct} support.`);
			});
	};

	const reloadIdentities = () => {
		reloadRef.current.classList.add('reload_spin');
		param.hard_refresh = true;
		setQuery(param);

		/* fetchIdentities(); */

		const fetchData = async () => {
			try {
				const b = await fetchWidget();
				//  const c =   await setTimeout(()=>{fetchUserSelection()},300);


			} catch (error) {
				console.error('Error fetching data:', error);
			}
		};

		fetchData();
		outDated();
		setTimeout(() => {
			if (reloadRef?.current?.classList.contains('reload_spin')) {
				reloadRef?.current?.classList.remove('reload_spin');
			}
		}, 750);
	};

	const {
		q: q,
		page: page_number,
		rpp: record_per_page,
		order_by: order,
		sort_by: sort,
		rule_name: rule_name,
		d_protocol: d_protocol,
		risk: risk,
		hash: hash,
		d_name: d_name,
		s_time: s_time,
		e_time: e_time,
		hard_refresh: hard_refresh
	} = query;

	//Auth Fail Redirect Popup
	const [openRedirect, setOpenRedirect] = useState<boolean>(false);
	const [ruleMatch, setRuleMatch] = useState<DirectoriesObj | undefined>();
	const [idAccess, setIdAccess] = useState<
		IdentitiesMappingObj[] | undefined
	>();
	const [prevUrl, setPrevUrl] = useState<string>(
		window.location.pathname + window.location.search
	);
	const [asset_name, setAname] = useState<string>('');
	const [asset_url, setAssetURL] = useState<any>();
	const [lastUpdate, setLastUpdate] = useState<string>();
	const [loading, setLoading] = useState(false);
	const [openFlow, setOpenFlow] = useState<boolean>(false);
	const [flowsTrendResponseData, setFlowsTrendResponseData] =
		useState<any>(undefined);
	const [flowsChartDetails, setFlowsChartDetails] = useState<any>({
		chartTitle: ''
	});

	let history = useHistory();
	const { addToast } = useToasts();
	const reloadRef = useRef() as React.MutableRefObject<HTMLDivElement>;

	const { appliedFilterTimestamp } = useTimeBasedSearchActions();

	let param: IssueParams = {
		q: q,
		page: page_number,
		rpp: record_per_page,
		order_by: order,
		sort_by: sort,
		rule_name: rule_name,
		d_protocol: d_protocol,
		risk: risk,
		hash: hash,
		d_name: d_name,
		s_time: s_time,
		e_time: e_time,
		hard_refresh: hard_refresh
	};
	const [isTimeBasedSearchApplied, setIsTimeBasedSearchApplied] = useState(false);

	const LoadSorting = (field: string) => {
		if (param.sort_by === field) {
			param.order_by = param.order_by === 'asc' ? 'desc' : 'asc';
			setQuery(param);
		} else {
			param.sort_by = field;
			param.order_by = 'desc';
			setQuery(param);
		}
	};

	const SortBy = (name: string) => {
		if (param.sort_by === name) {
			return param.order_by === 'desc'
				? 'tablesort_down'
				: 'tablesort_up';
		}
	};

	const handleSort = (e: React.MouseEvent) => {
		const col = e.currentTarget.getAttribute('data-headerName');
		setSelectedColumn(col);
		LoadSorting(col || '');
	};

	const getChartTitle = (identityName: string, startDate: any = null, endDate: any = null) => {
		let timeUnit = "Hourly";

		if (startDate) {
			if (endDate) {
				const totalDurationHours = moment(endDate).diff(moment(startDate), 'hours');

				if (totalDurationHours < 12) {
					timeUnit = "Minute-wise";
				} else if (totalDurationHours < 48) {
					timeUnit = "10-Minute-wise";
				}
				// else timeUnit remains "Hourly"
			}

			return (
				<span
					title={`${timeUnit} flows trend (${getTimeDiffString(startDate, endDate)}) for Directory: ${identityName}`}
				>{`${timeUnit} flows trend (${getTimeDiffString(startDate, endDate)}) for Directory: ${trimAfterSecondWord(identityName, 35)}`}</span>
			);
		} else {
			return (
				<span
					title={`Hourly flows trend for Directory: ${identityName}`}
				>{`Hourly flows trend for Directory: ${trimAfterSecondWord(identityName, 35)}`}</span>
			);
		}
	};

	function getSearchFields() {
		let timeFilter = appliedFilterTimestamp();
		if (timeFilter.length > 0) {

			let startDate = moment(timeFilter[0]).unix();
			let endDate = moment(timeFilter[1]);

			if (endDate.isAfter(moment())) {
				endDate = moment().unix();
			} else {
				endDate = endDate instanceof moment ? endDate.unix() : moment(endDate).unix();
			}

			// Calculate the time difference in hours
			let diffInHours = (endDate - startDate) / 3600;

			// Determine the interval based on the difference
			let interval;
			if (diffInHours < 12) {
				interval = '1 MINUTE';
				setZoomLevel('1-minute');
			} else if (diffInHours < 48) {
				interval = '10 MINUTE';
				setZoomLevel('10-minute');
			} else {
				interval = '1 HOUR'; // Replace 'default_interval' with your desired default interval.
				setZoomLevel('hourly');
			}

			let searchFields = {
				"any_activity_time": {
					"value": [startDate, endDate],
					"type": "between"
				},
				"interval": interval
			};

			// Convert to JSON and encode
			return encodeURIComponent(JSON.stringify(searchFields));

		}
		return null; // Return null or an empty string if timeFilter is empty
	}


	function getSearchFieldsForDate(min, max) {


		let startDate = moment(min).unix();
		let endDate = moment(max);

		if (endDate.isAfter(moment())) {
			endDate = moment().unix();
		} else {
			endDate = endDate instanceof moment ? endDate.unix() : moment(endDate).unix();
		}

		// Calculate the time difference in hours
		let diffInHours = (endDate - startDate) / 3600;

		// Determine the interval based on the difference
		let interval;
		if (diffInHours < 12) {
			interval = '1 MINUTE';
			setZoomLevel('1-minute');
		} else if (diffInHours < 48) {
			interval = '10 MINUTE';
			setZoomLevel('10-minute');
		} else {
			interval = '1 HOUR'; // Replace 'default_interval' with your desired default interval.
			setZoomLevel('hourly');
		}

		let searchFields = {
			"any_activity_time": {
				"value": [startDate, endDate],
				"type": "between"
			},
			"interval": interval
		};

		// Convert to JSON and encode
		return encodeURIComponent(JSON.stringify(searchFields));



	}

	const cancelTokenSourceRef = useRef<any>(null);

	const handleRangeChange = (min: any, max: any) => {

		const searchHeaders = {
			headers: {
				search_fields: getSearchFieldsForDate(min, max)
			}
		};

		updatedExtremesRef.current = { min, max };


		const currentTime = +new Date();

		const data = JSON.parse(sessionStorage.getItem('flowDataDirectory'));


		const encodedIdentityName = data ? encodeURIComponent(data?.id) : '';
		const directoryParams = `&is_directory_public=${data?.is_public}`;

		const flowTrendURL = `/assets/flowcounts?search_type=directory&search_value1=${encodedIdentityName}${directoryParams}`;

		if (cancelTokenSourceRef.current) {
			cancelTokenSourceRef.current.cancel('Operation canceled due to new request.');
		}

		// Create a new cancel token for the new request
		cancelTokenSourceRef.current = axios.CancelToken.source();


		try {

			Api.get(flowTrendURL, searchHeaders)
				.then((res: any) => {
					if (!res?.data?.length || res?.data?.length <= 0) {
						setFlowsTrendResponseData({
							flows: [],
							expiry_time: currentTime + 600000
						});

						// Chart header and color
						// const chartTitle = getChartTitle(identityName);
						// setFlowsChartDetails({
						//     chartTitle, itemData: {
						//         queryData: `identity_name:${encodedIdentityName}`
						//     }
						// });
						return;
					}

					let result = JSON.parse(JSON.stringify(res?.data)) || [];
					result.sort((a: any, b: any) =>
						a?.time < b?.time ? -1 : 1
					);
					const cachedFlows = {
						flows: result,
						encodedIdentityName,
						risk,
						expiry_time: currentTime + 600000
					};
					setFlowsTrendResponseData(cachedFlows);


					const chartTitle = appliedFilterTimestamp()?.length > 0 ? getChartTitle(
						data?.id,
						min,
						max,

					) : max ? getChartTitle(
						data?.id,
						min,
						max

					) : getChartTitle(
						data?.id,
						min
					);
					setFlowsChartDetails({
						chartTitle, itemData: {
							queryData: `dir_name:` + encodedIdentityName
						}
					});


				})
				.catch((er) => {
					console.log(er);
				});
		} catch (error) {
			if (axios.isCancel(error)) {
				console.log('Previous request canceled.');
			} else {
				console.log(error);
			}
		}
	}


	const handleFlowClick = (event: any, data: any) => {
		setOpenFlow(true);
		setZoomLevel('hourly');
		updatedExtremesRef.current = { min: null, max: null };
		let { id: identityName, is_public = false } = data;
		const currentTime = +new Date();
		const cachedFlowData: any = sessionStorage.getItem(
			`directory-flow-trend-${identityName}`
		);

		const encodedIdentityName = encodeURIComponent(identityName);

		const directoryParams = `&is_directory_public=${is_public}`;
		sessionStorage.setItem('flowDataDirectory', JSON.stringify(data));
		const flowTrendURL = `/assets/flowcounts?search_type=directory&search_value1=${encodedIdentityName}${directoryParams}`;



		console.log('searchField', getSearchFields());
		const searchHeaders = {
			headers: {
				search_fields: getSearchFields()
			}
		};

		Api.get(flowTrendURL)
			.then((res: any) => {
				if (!res?.data?.length || res?.data?.length <= 0) {
					setFlowsTrendResponseData({
						flows: [],
						expiry_time: currentTime + 600000
					});

					// Chart header and color
					const chartTitle = getChartTitle(identityName);
					setFlowsChartDetails({
						chartTitle, itemData: {
							queryData: `dir_name:` + encodedIdentityName
						}
					});

					return;
				}

				let result = JSON.parse(JSON.stringify(res?.data)) || [];
				result.sort((a: any, b: any) =>
					a?.time < b?.time ? -1 : 1
				);
				const cachedFlows = {
					flows: result,
					identityName,
					risk,
					expiry_time: currentTime + 600000
				};
				setFlowsTrendResponseData(cachedFlows);

				// Chart header and color
				const startFromDate = Math.max(
					moment(result?.[0]?.time).valueOf(),
					moment().subtract(90, 'days').valueOf()
				);


				const chartTitle = appliedFilterTimestamp()?.length > 0 ? getChartTitle(
					identityName,
					appliedFilterTimestamp()[0],
					appliedFilterTimestamp()[1],

				) : getChartTitle(
					identityName,
					startFromDate
				);

				setFlowsChartDetails({
					chartTitle, itemData: {
						queryData: `dir_name:` + encodedIdentityName
					}
				});

				sessionStorage.setItem(
					`directory-flow-trend-${identityName}`,
					JSON.stringify(cachedFlows)
				);
			})
			.catch((er) => {
				console.log(er);
			});
	};

	useEffect(() => {
		if (!openFlow) {
			setFlowsTrendResponseData(undefined);
		}
	}, [openFlow]);


	const handleRuleFilterClick = (e: React.MouseEvent<HTMLDivElement>) => {
		let idx = e.currentTarget.id?.toString();
		let ruleName = e.currentTarget.getAttribute('data-rulename');
		const isIssue = isIssueType(items, ruleName);
		ruleName = findNameById(items, ruleName);
		let hostDetails = responseData?.result.find(
			(item: DirectoriesObj) => item._id === idx
		);
		const isAsset = hostDetails.is_asset;
		let currHostDetails = hostDetails.host_detail;
		let assetsRef = '';
		currHostDetails?.map((item: any, index: any) => {
			if (index < 100) assetsRef += item.ip + '-' + item.port + ',';
		});
		const prevState = history?.location?.state as any;
		const prevUrl = history.location.pathname;
		const prevSearch = history.location.search;
		if (idx.includes(',')) {
			idx = `"${idx}"`;
		}

		const timeSearchParam = getTimeBasedSearchQueryParam(PostureSearchTemplate, tags);

		if (timeSearchParam === "") {
			isIssue ? history.push(
				'/issues?disable_filter=true&order_by=desc&page=1&q=rule_name:' +
				ruleName +
				'%2Bdir_name:' +
				encodeURIComponent(idx) +
				'%2Bstatus:Open&sort_by=issue_flows_count',
				{
					prevWidget: prevState?.breadcrumbId,
					breadcrumbId: 'DirectoryIncidents',
					prevUrl,
					prevSearch
				}
			) : history.push(
				'/issues?disable_filter=true&order_by=desc&page=1&q=pb_name:' +
				encodeURIComponent(ruleName) +
				'%2Bdir_name:' +
				encodeURIComponent(idx) +
				'%2Bstatus:Open&sort_by=issue_flows_count',
				{
					prevWidget: prevState?.breadcrumbId,
					breadcrumbId: 'DirectoryIncidents',
					prevUrl,
					prevSearch
				}
			);
		} else {
			isIssue ? history.push(
				'/issues?disable_filter=true&order_by=desc&page=1&q=rule_name:' +
				ruleName +
				'%2Bdir_name:' +
				encodeURIComponent(idx) +
				'%2Bstatus:Open%2B' + encodeURIComponent(timeSearchParam) + '&sort_by=issue_flows_count',
				{
					prevWidget: prevState?.breadcrumbId,
					breadcrumbId: 'DirectoryIncidents',
					prevUrl,
					prevSearch
				}
			) : history.push(
				'/issues?disable_filter=true&order_by=desc&page=1&q=pb_name:' +
				encodeURIComponent(ruleName) +
				'%2Bdir_name:' +
				encodeURIComponent(idx) +
				'%2Bstatus:Open%2B' + encodeURIComponent(timeSearchParam) + '&sort_by=issue_flows_count',
				{
					prevWidget: prevState?.breadcrumbId,
					breadcrumbId: 'DirectoryIncidents',
					prevUrl,
					prevSearch
				}
			);
		}

	};

	const handleIssueFilterClick = (e: string) => {
		let idx = e.toString();
		let hostDetails = responseData?.result.find(
			(item: DirectoriesObj) => item._id === idx
		);
		const isAsset = hostDetails.is_asset;
		let currHostDetails = hostDetails.host_detail;
		let assetsRef = '';
		currHostDetails?.map((item: any, index: any) => {
			if (index < 100) assetsRef += item.ip + '-' + item.port + ',';
		});
		const prevState = history?.location?.state as any;
		const prevUrl = history.location.pathname;
		const prevSearch = history.location.search;
		if (idx.includes(',')) {
			idx = `"${idx}"`;
		}

		const timeSearchParam = getTimeBasedSearchQueryParam(PostureSearchTemplate, tags);

		if (timeSearchParam === "") {
			history.push(
				'/issues?disable_filter=true&hard_refresh=true&order_by=desc&page=1&q=dir_name:' +
				encodeURIComponent(idx) +
				`%2Brule_name^Shadow External Access` +
				'%2Bstatus:Open&sort_by=gen_timestamp',
				{
					prevWidget: prevState?.breadcrumbId,
					breadcrumbId: 'DirectoryIncidents',
					prevUrl,
					prevSearch
				}
			);
		} else {
			history.push(
				'/issues?disable_filter=true&hard_refresh=true&order_by=desc&page=1&q=dir_name:' +
				encodeURIComponent(idx) +
				`%2Brule_name^Shadow External Access` +
				'%2Bstatus:Open%2B' + encodeURIComponent(timeSearchParam) + '&sort_by=gen_timestamp',
				{
					prevWidget: prevState?.breadcrumbId,
					breadcrumbId: 'DirectoryIncidents',
					prevUrl,
					prevSearch
				}
			);
		}
	};

	const handleIdAccess = (
		identitiesid: any,
		server: string,
		domain: string,
		host_detail: any,
		isAsset: boolean
	) => {
		let assetsRef = '';
		host_detail?.map((item: any) => {
			assetsRef += item.ip + '-' + item.port + ',';
		});
		const prevState = history?.location?.state as any;
		const prevUrl = history.location.pathname;
		const prevSearch = history.location.search;
		PostureSearchState.setIsAdSearchApplied(false);
		if (server.includes(',')) {
			server = `"${server}"`;
		}

		const timeSearchParam = getTimeBasedSearchQueryParam(PostureSearchTemplate, tags);
		let timeParam = "";
		if (timeSearchParam) {
			timeParam = "%2B" + encodeURIComponent(timeSearchParam)
		}

		history.push(
			'/posture/accesses?page=1&q=dir_name%3A' +
			encodeURIComponent(server) + timeParam +
			'&sort_by=identity&order_by=asc',
			{
				prevWidget: prevState?.breadcrumbId,
				breadcrumbId: 'PostureAccesses',
				prevUrl,
				prevSearch,
				customData: { path: 'identity', directory_name: identitiesid }
			}
		);
	};

	const handleDirectoryAsset = (
		identitiesid: any,
		server: string,
		domain: string,
		host_detail: any,
		isAsset: boolean
	) => {
		let assetsRef = '';
		host_detail?.map((item: any) => {
			assetsRef += item.ip + '-' + item.port + ',';
		});
		// fetchAssetsMapping(identitiesid, server, domain, assetsRef.slice(0, -1), isAsset);

		const prevState = history?.location?.state as any;
		const prevUrl = history.location.pathname;
		const prevSearch = history.location.search;
		PostureSearchState.setIsAdSearchApplied(false);
		if (server.includes(',')) {
			server = `"${server}"`;
		}

		const timeSearchParam = getTimeBasedSearchQueryParam(PostureSearchTemplate, tags);
		let timeParam = "";
		if (timeSearchParam) {
			timeParam = "%2B" + encodeURIComponent(timeSearchParam)
		}

		history.push(
			'/posture/accesses?page=1&q=dir_name%3A' +
			encodeURIComponent(server) + timeParam +
			'&sort_by=asset&order_by=asc',
			{
				prevWidget: prevState?.breadcrumbId,
				breadcrumbId: 'PostureAccesses',
				prevUrl,
				prevSearch,
				customData: { path: 'asset', directory_name: identitiesid }
			}
		);
	};

	useEffect(() => {
		ReactTooltip.rebuild();
	});

	const getExceptionData = (item: DirectoriesObj, rule_name: string) => {
		const dir_name = !item?.is_public
			? `dir_hostname:${item.am_hostname}`
			: `dir_names:${item?._id}`;
		return [
			dir_name,
			`dst_hostnames:` +
			item.host_detail?.map((item: any) => item.hostname).join(',') ||
			'',
			`dst_ips:` +
			item.host_detail?.map((item: any) => item.ip).join(',') || '',
			`rule_name:` + rule_name
		];
	};

	const loadBasePostureActionProps = (
		item: DirectoriesObj,
		rule_name: string,
		issue_count: number
	) => {
		const config = {
			actionData: item,
			menuItems: loadColumnActionLabels(rule_name),
			getContent: () => [
				!item?.is_public
					? `dir_hostname:${item.am_hostname}`
					: `dir_names:${item?._id}`,
				`rule_name:` + rule_name
			],
			exceptionData: getExceptionData(item, rule_name),
			classes: 'posture-column action_button',
			incContent: () => [
				`inc_count:${issue_count}`,
				`dir:${item._id}`,
				`rule_name:${rule_name}`
			],
			closeIncQuery: `{"status":"Open","is_external":"false","dir_hostname":"${item._id}","rule_name":"${rule_name}"}`,
			issueCount: issue_count,
			reloadList: fetchIdentities,
			keyName: `directory-${item._id}`,
			showId: rule_name
		} as any;

		if (rule_name == 'Shadow Identity Systems') {
			config.config = {
				hideMarkKnown: true,
				markKnownCallback: markKnownCallback.bind(null, item)
			};
		}
		return config;
	};

	const markKnownCallback = (item: DirectoriesObj) => {
		const name = item._id;
		const result: string[] = [];
		item.host_detail.forEach((item: any) => {
			if (item?.ip?.length > 0) result.push(item.ip);
			if (item?.hostname?.length > 0) result.push(item.hostname);
		});
		if (result.length == 0) {
			result.push(item._id);
		}
		const resultSet = Array.from(new Set(result));
		const req = {
			fqdn: resultSet,
			name
		};
		localStorage.setItem('addDirectory', JSON.stringify(req));
		history.push('/directories/add');
	};

	const loadColumnActionLabels = (rule_name: String) => {
		const IDCopy = JSON.parse(
			JSON.stringify(DirectoriesColumnActionMenu)
		) as any;
		const exceptionAction = IDCopy.find(
			(item: PostureMenuItem) =>
				item.action == PostureMenuItemAction.ADD_EXCEPTION
		);
		const markKnownAction = IDCopy.find(
			(item: PostureMenuItem) =>
				item.action == PostureMenuItemAction.MARK_KNOWN
		);
		const closeIncident = IDCopy.find(
			(item: PostureMenuItem) =>
				item.action == PostureMenuItemAction.CLOSE_INCIDENT
		);
		if (rule_name == 'Shadow Identity Systems') {
			// rule_name = 'Shadow Directory';
			if (markKnownAction) {
				markKnownAction.label =
					'Add this system to known Identity Systems';
			}
			const index = IDCopy.findIndex(
				(item: PostureMenuItem) =>
					item.action == PostureMenuItemAction.ADD_EXCEPTION
			);
			IDCopy.splice(index, 1);
		} else {
			const index = IDCopy.findIndex(
				(item: PostureMenuItem) =>
					item.action == PostureMenuItemAction.MARK_KNOWN
			);
			IDCopy.splice(index, 1);
		}

		if (exceptionAction) {
			switch (rule_name) {
				case 'Auth Hash Security':
					exceptionAction.label = `Ignore hash security issues for this directory (Add Exception)`;
					break;
				case 'Auth Hash Quality':
					exceptionAction.label = `Ignore hash quality issues for this directory (Add Exception)`;
					break;
				case 'Auth Protocol Quality':
					exceptionAction.label =
						'Ignore protocol quality issues for this directory (Add Exception)';
					break;
				default:
					exceptionAction.label = `Ignore ${findNameById(items, rule_name)} issues for this directory (Add Exception)`;
			}
			// exceptionAction.label = `Add Exception for ${rule_name} rule and this directory`;
		}
		if (closeIncident) {
			const ruleLabel = getIssueNameById(findNameById(items, rule_name))
			closeIncident.label = `Close existing '${ruleLabel}' issues for this directory`;
		}
		return IDCopy;
	};

	const getRules = (item: DirectoriesObj) => {
		let rule = '';
		Object.values(item.rules).map((i: RulesDetailObj) => {
			const isIssue = isIssueType(items, i.name);
			if (isIssue) {
				if (i.issue_count > 0 && i.entity_type === 'Directory') {
					rule += i.name + ',';
				}
			}
		});
		return rule.substring(0, rule.length - 1);
	};

	const getIssueCount = (item: DirectoriesObj) => {
		return item.rules
			? Object.values(item.rules).reduce(
				(prev: RulesDetailObj, i: RulesDetailObj) => ({
					...prev,
					issue_count: prev.issue_count + i.issue_count
				}),
				{
					issue_count: 0,
					issue_flow_count: 0,
					issue_risk: 4,
					name: ''
				}
			).issue_count
			: 0;
	};

	const handleAsstRedirect = (item: DirectoriesObj) => {
		PostureSearchState.setIsAdSearchApplied(false);
		const timeSearchParam = getTimeBasedSearchQueryParam(PostureSearchTemplate, tags);
		let timeParam = "";
		if (timeSearchParam) {
			timeParam = "%2B" + encodeURIComponent(timeSearchParam)
		}
		if (item.is_public) {
			history.push(
				`/posture/assets?order_by=desc&page=1&q=asset_name:${item._id}` + timeParam + "&sort_by=flow_count",
				{ breadcrumbId: 'DirectoryAssetServices' }
			);
		} else {
			history.push(
				`/posture/assets?order_by=desc&page=1&q=asset_hostname:${item._id}` + timeParam + `&sort_by=flow_count`,
				{ breadcrumbId: 'DirectoryAssetServices' }
			);
		}
	};

	const handleAddtionalResultColumn = (val) => {
		setShowAdditionalResult(val);
		if (additionalResultHighlightClass === "")
			setAdditionalResultHighlightClass("glowing-animation");
	}

	useEffect(() => {
		if (additionalResultHighlightClass !== "" && showAdditionalResult) {
			setTimeout(() => {
				setAdditionalResultHighlightClass("");
			}, 15000)
		}
	}, [additionalResultHighlightClass])

	const [showOudatedData, setShowOutdatedData] = useState(false);

	const outDated = () => {
		let clearOutdatedDataInterval = null;
		setShowOutdatedData(false);

		let tempTags = getTagsForTimeBasedSearch(tags);

		tempTags = Object.keys(AVAILABLE_PRESETS).filter(item => {
			if (tempTags && tempTags.length > 0)
				return item === tempTags[0][1]
			return false;
		})
		if (tempTags && tempTags.length > 0) {
			if (clearOutdatedDataInterval)
				clearInterval(clearOutdatedDataInterval);
			clearOutdatedDataInterval = setTimeout(() => {
				if (isPresetPeriodOptionSelected()) {
					setShowOutdatedData(true);
				}
			}, 30000);
		}
		return () => {
			if (clearOutdatedDataInterval)
				clearInterval(clearOutdatedDataInterval);
		}
	}
	useEffect(() => {
		outDated();
	}, [tags])

	const [headerCheckboxConfig, setHeaderCheckboxConfig] = useState({});

	const containerRef = useRef(null)
	const gridRef = useRef(null)

	const getCheckboxParams = (item: DirectoriesObj) => {
		return {
			checked: getSelectRow(item),
		}
	}

	useEffect(() => {
		setHeaderCheckboxConfig({
			indeterminate: selectAllIndeterminate,
			onClick: onCurrentPageClick,
			checked: selectAll || false,
		})
	}, [responseData, selectAll, selectAllIndeterminate, selectCount, archivalData])

	const [gridCols, setGridCols] = useState<ColDef[] | null>(null)

	const { getAdditionalFields } = useAdditionalResults({
		options: tags, filterTemplate: PostureSearchTemplate,
		notSupportedKeys: notSupportedKeys,
		pageType: ENTITIES.IDENTITY_SYSTEMS.pageType,
		columnDefs: gridCols, setColumnDefs: setGridCols, handleAddtionalResultColumn
	})

	useEffect(() => {
		console.log("Updates", gridCols)
	}, [gridCols])

	const updateCols = (): ColDef[] => {
		if (!issueHeader || !issueHeader?.length) {
			return []
		}
		return [{
			field: "_id",
			width: getResolutionMatchFor1920(390, 300),
			headerName: "Identity System",
			sortField: "_id",
			pinned: "left",
			suppressMenu: true,
			menuTabs: [],
			cellRendererParams: {
				fetchIdentities, items, markKnownCallback
			},
			cellRenderer: IdentitySystemNameCell
		},
		{
			field: "domain",
			headerName: "Domain",
			width: getResolutionMatchFor1920(160, 110),
			suppressMenu: true,
			menuTabs: [],
			pinned: "left",
			wrapHeaderText: true,
			cellRenderer: IdentitySystemDomainCell
		},
		{
			field: "type",
			headerName: "Type",
			width: getResolutionMatchFor1920(90, 50),
			minWidth: getResolutionMatchFor1920(90, 70),
			suppressMenu: true,
			menuTabs: [],
			pinned: "left",
			wrapHeaderText: true,
			cellRenderer: IdentitySystemTypeCell
		},
		{
			headerName: "Issues",
			headerClass: 'issue-header-column ',
			/* marryChildren: true, */
			width: getResolutionMatchFor1920(100, 100),
			maxWidth: getResolutionMatchFor1920(100, 100),
			headerGroupComponent: PostureIssueGroupHeader,
			headerGroupComponentParams: {
				gridRef, containerRef, type: 'directory'
			},
			wrapHeaderText: true,
			suppressMenu: true,
			menuTabs: [],
			children: issueHeader?.map((i: string) => {
				let rule_name = getCustomLabelForRuleName(i);
				rule_name = items ? findPbNameById(rule_name, items) : rule_name;
				const toolTipText = isIssueType(items, i) ? getIssueNameById(i) : getIssueNameById(rule_name)
				return {
					headerName: rule_name,
					headerComponent: PostureGridIssueCustomHeader,
					cellClass: 'issue-data-column-value',
					headerClass: 'issue-header-column-issue-list',
					width: getResolutionMatchFor1920(55, 55),
					minWidth: getResolutionMatchFor1920(55, 55),
					field: i,
					headerTooltip: toolTipText,
					wrapHeaderText: true,
					cellRenderer: ({ data, node }) => {
						const item = data;
						/* if (node.group) {
							return <span>{ }</span>; // Display group key for grouped rows
						} */
						return item[i] ? <ScrollIssueGridRenderer
							ruleName={i}
							ruleIssueData={item[i]}
							data={item.rules}
							handleRuleFilterClick={
								handleRuleFilterClick
							}
							loadBasePostureActionProps={
								loadBasePostureActionProps
							}
							issueList={issueHeader}
							postureObject={item}
							classes={{ containerClass: 'asset-page' }}
							selectedItems={items}
							isAdditionalResultColVisible={showAdditionalResult}
							tableWidth={"307px"}
							isTimeBasedSearchApplied={isTimeBasedSearchApplied}
						/> : <></>
					}
				}
			})
		},
		{
			field: "flow_count",
			headerName: "Number of Auth Flows",
			width: getResolutionMatchFor1920(105, 85),
			maxWidth: getResolutionMatchFor1920(105, 85),
			pinned: "right",
			suppressMenu: true,
			menuTabs: [],
			autoHeaderHeight: true,
			wrapHeaderText: true,
			autoHeight: true,
			headerClass: 'align_center',
			cellClass: 'pad-r-l-5',
			cellRenderer: IdentitySystemFlowCountCell,
			cellRendererParams: {
				widgetData, setFlowsTrendResponseData, setFlowsChartDetails,
				setOpenFlow, getSearchFields, appliedFilterTimestamp, getChartTitle,
				setZoomLevel, updatedExtremesRef, risk
			}
		},
		{
			field: "auth_failed_percent",
			pinned: "right",
			headerName: "Auth Failure %",
			maxWidth: getResolutionMatchFor1920(105, 85),
			headerClass: 'align_center',
			cellClass: 'pad-r-l-5',
			suppressMenu: true,
			menuTabs: [],
			autoHeaderHeight: true,
			autoHeight: true,
			wrapHeaderText: true,
			cellRenderer: IdentitySystemTypeAuthFailCell,
			cellRendererParams: {
				responseData, tags
			}
		},
		{
			field: "issue_count",
			pinned: "right",
			headerName: "Overall Number of Incidents",
			maxWidth: getResolutionMatchFor1920(105, 95),
			headerClass: 'align_center',
			cellClass: 'pad-r-l-5',
			suppressMenu: true,
			menuTabs: [],
			autoHeaderHeight: true,
			autoHeight: true,
			wrapHeaderText: true,
			cellRenderer: PostureIssueCountCell,
			cellRendererParams: {
				handleIssueFilterClick, responseData
			}
		},
		{
			field: "identity_count",
			pinned: "right",
			cellClass: 'pad-r-l-5',
			headerName: "Number of Identities Auth.",
			headerClass: 'text_center_line_height_normal align_center',
			maxWidth: getResolutionMatchFor1920(105, 85),
			autoHeight: true,
			suppressMenu: true,
			menuTabs: [],
			wrapHeaderText: true,
			cellRenderer: IdentitySystemIdCell,
			cellRendererParams: {
				tags
			}
		},
		{
			field: "asset_count",
			pinned: "right",
			cellClass: 'pad-r-l-5',
			headerName: "Number of Assets Auth.",
			headerClass: 'text_center_line_height_normal align_center',
			maxWidth: getResolutionMatchFor1920(105, 85),
			autoHeight: true,
			suppressMenu: true,
			menuTabs: [],
			wrapHeaderText: true,
			cellRenderer: IdentitySystemAssetCell,
			cellRendererParams: {
				tags
			}
		},
		...getAdditionalFields().filter((i) => i.field !== 'auth_failed_percent'),
		{
			field: "latest_time",
			pinned: "right",
			suppressMenu: true,
			menuTabs: [],
			headerClass: 'posture-header-column-cls',
			width: getResolutionMatchFor1920(110, 110),
			cellStyle: { whiteSpace: 'normal', height: 50 }, // Allows text to wrap          
			cellClass: 'text_center_line_height_normal padding-top-15',
			wrapHeaderText: true,
			headerName: "Latest Activity",
			cellRenderer: LatestTimeCell
		},
		{
			field: "score",
			width: getResolutionMatchFor1920(80, 80),
			minWidth: getResolutionMatchFor1920(80, 80),
			pinned: "right",
			suppressMenu: true,
			menuTabs: [],
			headerName: "Score",
			initialSort: "desc",
			cellRenderer: ({ data, node }) => {
				return <IDScore data={data} />;
			},
		},
		]
	}



	useEffect(() => {
		setGridCols(updateCols())
	}, [selectedItems, issueHeader, responseData])

	const handlePaginate = (selectedPage: any) => {
		param.page =
			selectedPage.selected === 0 ? 1 : selectedPage.selected + 1;
		setQuery(param);
	};

	const handleSearch = (e: React.KeyboardEvent<HTMLInputElement>) => {
		handleSearchFn(e, tags);
	};

	const handleLensSearch = () => {
		handleLensSearchFn(tags);
	};

	useEffect(() => {
		if ((tagFlag && tags.length >= 0) || isClearAll) {
			param.page = 1;
			param.q = tags.join('+');
			param.d_name = undefined;
			param.risk = undefined;
			param.d_protocol = undefined;
			param.hash = undefined;
			param.e_time = undefined;
			param.s_time = undefined;
			param.rule_name = undefined;
			if (param.q === '') {
				param.reportType = undefined;
				param.ruleType = undefined;
			}
			setQuery(param);
			setIsClearAll(false); // reset clearall status.
			setTagFlag(false);
		}
	}, [tags, tagFlag]);

	const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchInput(e.target.value);
	};

	const deleteTag = (index: number) => {
		const anyActivityTimeTag = tags.filter(item => item.indexOf(TIME_BASED_SEARCH_FIELD) > -1);
		let newTags = getTagsForSearchInput(tags).filter((tag, i) => i !== index)
		if (anyActivityTimeTag && anyActivityTimeTag.length > 0) {
			newTags = [...newTags, ...anyActivityTimeTag];
		}

		setTags(newTags);

		setTagFlag(true);
		PostureSearchState.setIsAdSearchApplied(false);
	};

	const [openModal, setOpenModal] = useState<boolean>(false);

	const handleClose = () => {
		setOpenModal(false);
	}

	const openSettingModal = () => {
		setOpenModal(true);
	}

	const getPlayBookData = (updatedItems: any) => {
		setShowGraph(false);
		updatedItems = updatedItems.filter((item) => !(item.is_issue_type && item.name === 'Posture Issues'))

		// Create another array with _id property as value
		setSelectedItems(updatedItems);
		const newArray = updatedItems.map(item => item._id);
		// Sort the new array based on the order property value
		newArray.sort((a, b) => {
			const orderA = updatedItems.find(item => item._id === a).order;
			const orderB = updatedItems.find(item => item._id === b).order;
			return orderA - orderB;
		});
		setIssueHeaders(newArray);
		if (JSON.parse(localStorage.getItem('directoryRule'))) {

			const filteredData = JSON.parse(localStorage.getItem('directoryRule'))?.filter(item => newArray.includes(item.name));
			setRuleWidget(filteredData);

		}
	}

	useEffect(() => {
		if (ruleWidget) {
			setShowGraph(true);
		}
	}, [ruleWidget])

	useEffect(() => {
		let filterObj = getSearchFilterObjectFromTags(PostureSearchTemplate, tags);
		if (filterObj[TIME_BASED_SEARCH_FIELD]) {
			setIsTimeBasedSearchApplied(true);
		} else {
			setIsTimeBasedSearchApplied(false);
		}
	}, [tags])

	const fetchPlayBookData = async () => {
		await Api.get("/pbviewplaybooks?type=directory", {
			timeout: 30000,
		})
			.then((res) => {
				//res.data = responseAdapter(res.data);
				setItems(res.data);
				setEditConfig(localStorage.getItem('canEdit') === 'false' ? false : true);
			})
			.catch((err) => {
				if (err.response?.status === 401) {
					window.location.href = "/login";
				}
			});
	};

	useEffect(() => {

		const fetchData = async () => {
			try {
				const a = await fetchPlayBookData();
				const b = await fetchWidget();
				// const c =   await setTimeout(()=>{fetchUserSelection()},500);


			} catch (error) {
				console.error('Error fetching data:', error);
			}
		};

		fetchData();

	}, []);

	const fetchWidget = () => {
		setLoading(true);
		const queryParams = {
			...(hard_refresh_state && { hard_refresh: 'true' })
		};
		PostureSummary.GET(
			queryParams,
			(res: IApiResponse) => {
				if (res.status === 200) {
					setLoading(false);
					setWidgetData(undefined);
					setTimeout(() => setWidgetData(res.data));
					//setIssueHeaders(res.data.directory_rule_types);
					//Set ID widget
					setIdWidget(res.data.directory_summary);
					//Set Asset Widget Data
					const rulesArray: any = [];
					//Set All rule root data
					rulesArray.push(
						...Object.values(res.data.directory_rules_summary.rules)
					);
					//setRuleWidget(rulesArray);
					//setRuleWidgetCopy(rulesArray);
					localStorage.setItem('directoryRule', JSON.stringify(rulesArray));

					const rulesRoot: any = [];
					rulesRoot.push(
						...Object.values(
							res.data.directory_rules_summary.root_cause_detail
						)
					);
					//SetRuleRootCount Data
					setRuleRootIdentity(
						res.data.directory_rules_summary.root_cause_detail
					);
				}
			},
			() => { }
		);
	};

	const handleGotoPage = () => {
		let pageValue = (
			document.getElementsByName('page_no')[0] as HTMLTextAreaElement
		).value;
		pageValue = pageValue !== '' ? pageValue : '1';
		let currentUrlParams = new URLSearchParams(window.location.search);
		currentUrlParams.set('page', pageValue);
		(
			document.getElementsByName('page_no')[0] as HTMLTextAreaElement
		).value = '';
		history.push(
			window.location.pathname + '?' + currentUrlParams?.toString()
		);
		window.scroll({
			top: 0,
			left: 0,
			behavior: 'smooth'
		});
	};

	const handleFailRedirect = (e: React.MouseEvent<HTMLDivElement>) => {
		//Redirect to AccessPage
		let directory = e.currentTarget.id?.toString();
		const data = responseData?.result.find(
			(i: DirectoriesObj) => i._id === directory
		) as DirectoriesObj;
		let searchStr = '';
		if (!tags.includes('num_of_auth_failed_flow')) {
			searchStr = `num_of_auth_failed_flow|1,${data.flow_fail_count}`;
		}
		const dir_name =
			e.currentTarget.getAttribute('data-dirname')?.toString() || '';
		const prevState = history?.location?.state as any;
		const prevUrl = history.location.pathname;
		const prevSearch = history.location.search;
		PostureSearchState.setIsAdSearchApplied(false);
		if (directory.includes(',')) {
			directory = `"${directory}"`;
		}
		const timeSearchParam = getTimeBasedSearchQueryParam(PostureSearchTemplate, tags);

		if (timeSearchParam === "") {
			history.push(
				'/posture/accesses?order_by=desc&sort_by=auth_failed_percent&page=1&q=' +
				searchStr +
				'%2Bdir_name:' +
				encodeURIComponent(directory),
				{
					prevWidget: prevState?.breadcrumbId,
					breadcrumbId: 'PostureAccesses',
					prevUrl,
					prevSearch,
					customData: { path: 'failDir', directory_name: dir_name }
				}
			);
		} else {
			history.push(
				'/posture/accesses?order_by=desc&sort_by=auth_failed_percent&page=1&q=' +
				searchStr +
				'%2Bdir_name:' +
				encodeURIComponent(directory) + '%2B' + encodeURIComponent(timeSearchParam),
				{
					prevWidget: prevState?.breadcrumbId,
					breadcrumbId: 'PostureAccesses',
					prevUrl,
					prevSearch,
					customData: { path: 'failDir', directory_name: dir_name }
				}
			);
		}
	};

	const handleNo = () => {
		setOpenRedirect(false);
	};

	//Download CSV
	const headers = [
		{ label: 'Identity System', key: 'name' },
		{ label: 'Directory Services 1', key: 'orignal_names[0]' },
		{ label: 'Directory Services 2', key: 'orignal_names[1]' },
		{ label: 'Directory Services 3', key: 'orignal_names[2]' },
		{ label: 'Directory Services 4', key: 'orignal_names[3]' },
		{ label: 'Directory Services 5', key: 'orignal_names[4]' },
		{ label: 'Other Services 1', key: 'orignal_unknown_names[0]' },
		{ label: 'Other Services 2', key: 'orignal_unknown_names[1]' },
		{ label: 'Other Services 3', key: 'orignal_unknown_names[2]' },
		{ label: 'Other Services 4', key: 'orignal_unknown_names[3]' },
		{ label: 'Other Services 5', key: 'orignal_unknown_names[4]' },
		{ label: 'IP Address 1', key: 'host_detail[0].ip' },
		{ label: 'IP Address 2', key: 'host_detail[1].ip' },
		{ label: 'IP Address 3', key: 'host_detail[2].ip' },
		{ label: 'IP Address 4', key: 'host_detail[3].ip' },
		{ label: 'IP Address 5', key: 'host_detail[4].ip' },
		{ label: 'Domain Name', key: 'domain' },
		{ label: 'Type - Cloud IDP', key: 'is_public' },
		{ label: '# Auth Flows', key: 'flow_count' },
		{ label: 'Unsuccessful Authentication', key: 'auth_failed_percent' },
		{ label: 'Overall # Incidents', key: 'issue_count' },
		{ label: '# Identities Authenticated', key: 'identity_count' },
		{ label: '# Assets Authenticated', key: 'asset_count' },
		{ label: 'Latest Activity', key: 'latest_time' },
		{ label: 'Score', key: 'score' }
	];

	const [downData, setDownData] = useState<string | Data>([]);
	const [totalDownloaded, setTotalDownloaded] = useState<number | undefined>(
		0
	);
	const [loadingCSV, setLoadingCSV] = useState({
		loading: false,
		setData: false
	});
	const currDownData: any = [];
	const csvLinkEl = useRef<any>();

	const getUserList = async (selectedPage?: any) => {
		param.page = selectedPage === 0 ? 1 : selectedPage + 1;
		param.rpp = 100;
		const searchHeaders = await getSearchHeaders(param)
		return Api.get('/assetsdirectories', {
			params: { ...param, is_export_csv: true },
			paramsSerializer: (params) =>
				qs.stringify(params, { arrayFormat: 'repeat' }),
			...searchHeaders
		})
			.then((res) => {
				res.data = validateLocalIps(res.data, 'host_detail');
				currDownData.push(...formatCSVData(res));
				return currDownData;
			})
			.catch((error: any) => {
				setLoadingCSV({ loading: false, setData: false });
				if (error.response.status === 500 || error.response.status === 524) {
					addToast('Sorry, something went wrong there, try again.', {
						appearance: 'error',
						autoDismiss: true
					});
				} else if (error.response.status === 404) {
					addToast(
						'We are not able to find associated email, please check and try again.',
						{
							appearance: 'error',
							autoDismiss: true
						}
					);
				}
			});
	};

	const downloadCSV = async () => {
		setTotalDownloaded(0);
		setLoadingCSV({ loading: true, setData: false });
		let i = 0;
		do {
			const downData = await getUserList(i);
			setTotalDownloaded(i * 100);
			if (i > Math.floor(totalCount! / 100)) {
				setDownData(downData);
				setTimeout(() => {
					setLoadingCSV({ loading: false, setData: true });
					if (csvLinkEl && csvLinkEl?.current && csvLinkEl?.current?.link) {
						csvLinkEl?.current?.link?.click();
					}
				});
			}
			i = i + 1;
		} while (i <= Math.floor(totalCount! / 100) + 1);
	};

	useEffect(() => {
		if (issueHeader?.length > 0 && responseData?.result?.length) {
			scrollToIssueColumn();
			// scrollIssueRef.current?.reAlignTableData()
			setShowAnimation(true);
		}
	}, [responseData, issueHeader]);

	useEffect(() => {
		updateColumnEffect(showAnimation, history, q, 'directories')
	}, [showAnimation]);

	const handleFilterWidget = (widgetState: any) => {
		if (filterData) {
			setOpenPanelFilterWidgets(widgetState);
		} else {
			addToast(
				'Unable to apply filters, please retry in few minutes or contact administrator.',
				{ appearance: 'error' }
			);
		}
	};

	const handleFilterDisabled = (widgetState: any) => {
		setDisabledFilter(widgetState);
		// reloadIssuesDisabled(widgetState);
	};

	const onCloseAdvanceSearch = useCallback(() => {
		setShowAdvanceSearch(false);
	}, []);

	const applySearchHandler = useCallback(
		(data: any, clearAll: boolean = false, deletedKeys = []) => {
			let tempFilterObj = getSearchFilterObjectFromTags(PostureSearchTemplate, tags);
			let filterObj = {};
			for (let i in tempFilterObj) {
				if (deletedKeys.indexOf(i) == -1)
					filterObj[i] = tempFilterObj[i];
			}

			filterObj = { ...filterObj, ...data }

			handleApplyAdvancedSearch(
				filterObj,
				clearAll,
				setIsClearAll,
				setTags,
				setTagFlag,
				ENTITIES.IDENTITY_SYSTEMS.pageType
			);
			resetSelectionState();
		},
		[tags, q]
	);

	const applyTimeBasedSearchHandler = ((data, clear) => {
		let filterObj = getSearchFilterObjectFromTags(PostureSearchTemplate, tags);
		if (clear) {
			if (filterObj.hasOwnProperty(TIME_BASED_SEARCH_FIELD)) {
				delete filterObj[TIME_BASED_SEARCH_FIELD];
				clear = false;
			}
			clear = Object.keys(filterObj).length === 0 ? true : false;
		} else {
			filterObj = { ...filterObj, ...data }
		}

		handleApplyAdvancedSearch(
			filterObj,
			clear,
			setIsClearAll,
			setTags,
			setTagFlag,
			ENTITIES.IDENTITY.pageType
		);
	})


	useEffect(() => {
		handleManualSearch(tags, ENTITIES.IDENTITY_SYSTEMS.pageType)
	}, [tags])

	const onAdvanceSearchClick = () => {
		setShowAdvanceSearch(true);
	}

	const handleTabClick = (to: string) => {
		if (PostureSearchState.isAdSearchApplied) {
			const tempTags = tags.map(item => encodeURIComponent(item));
			history.push(`/${to}?order_by=desc&sort_by=score&q=${tempTags.join('%2B')}`)
		} else {
			history.push(`/${to}?order_by=desc&sort_by=score`);
		}
	}

	useEffect(() => {
		if (PostureSearchState.isAdvancedSearchOpen) {
			setShowAdvanceSearch(true)
		}
	}, [])

	useEffect(() => {
		PostureSearchState.setIsAdvancedSearchOpen(showAdvanceSearch);
	}, [showAdvanceSearch])

	const matchingPlaybookFoundFn = () => {
		const newParams = { ...query };
		newParams.entity_type = ENTITIES.IDENTITY_SYSTEMS.id;
		setQuery(newParams);
		matchingPlaybookFound(newParams, getSearchHeaders)
	}

	const onFieldSelect = (field, fields) => {
		setSelectedField(field);
		setAdditionalFields(fields);
	}

	return (
		<>

			<Switch>
				<Route path="/posture/directory/rulematch" render={() => <RuleMatch data={ruleMatch} prevURL={prevUrl} />} />
				{/* <Route path="/directory/idaccess" render={()=><IdAccess data={idAccess} prevURL={prevUrl} aname={asset_name} asset_url={asset_url}/>}/> */}
				{/* <Route path="/directory/assetaccess" render={()=><AssetAccess data={idAccess} prevURL={prevUrl} aname={asset_name} asset_url={asset_url}/>}/> */}
			</Switch>
			<div className="clrBoth margintop10"></div>
			<div className='hide-table-border-top'></div>
			<div className="">
				<div className="flow_table_container">
					<div className={showAdditionalResult ? "posture_search_section posture_search_section_additional_col" : "posture_search_section posture_search_section_no_additional_col"}>
						<TimeBasedSearchContainer onApply={applyTimeBasedSearchHandler} filters={getTagsForTimeBasedSearch(tags)} apiUrl={'/posture/daily_flow_count?entity_type=directory'} pageType={'directories'}></TimeBasedSearchContainer>
						<PostureArchiveTooltip numberOfDays={widgetData?.posture_view_date_range} />
						<PostureActionButtons
							entity='directories' selectAll={selectAll}
							onArchiveClick={onArchive.bind(null, reloadIdentities)}
							selectCount={selectCount} isOpenIncidents={isIncidentsOpen}
							onArchiveEstimate={onArchiveEstimate.bind(null)}
							ref={archiveBtnRef} isHidden={true}
						/>
						{showAdvanceSearch && <PostureFilter
							filterTemplate={PostureSearchTemplate.filter(item => item.search_key !== TIME_BASED_SEARCH_FIELD)}
							notSupportedKeys={notSupportedKeys} filters={tags}
							isOpen={showAdvanceSearch} onApplyFilter={applySearchHandler}
							onClose={onCloseAdvanceSearch}
							disableCreatePlaybookBtn={isValidResultCountThreshold(responseData)}
							matchingPlaybookFoundFn={matchingPlaybookFoundFn}
							entityType={ENTITIES.IDENTITY_SYSTEMS.id}
							query={query}
							showCreatePlaybookBtn={query && query['playbook_type'] === POSTURE_ISSUE.type ? false : true}
							preMetaDataHandler={preMetaDataHandler}
							isTimeBasedSearchApplied={isTimeBasedSearchApplied}
							apiUrl={'/posture/daily_flow_count?entity_type=directory'} pageType={'directories'}
							PartnerConfigState={PartnerConfigState}
						/>}
						{
							openPanelFilterWidgets ?
								<FilterWidget toggleWidget={handleFilterWidget}
									disabledState={handleFilterDisabled}
									updateAfterFilter={reloadIdentities} issueFiltersData={filterData} /> : ""
						}
					</div>

					<div className="" style={{ background: "#ffffff", color: "#4e5267", paddingRight: 0 }} >
						<div className='flex-space-between'>
							<div>
								<div className="reload_container">
									<div ref={reloadRef} className="reload_color_icon" onClick={reloadIdentities}></div>
								</div>
								<div className="table_title">Last Updated: {lastUpdate}</div>
								{showOudatedData ? <Chip label="Data could be outdated, reload to update" color="error" className='outdated-data-msg' /> : <></>}
							</div>
							{/* <div className="search_white_icon">&nbsp;</div> */}
							{/* <input type="text" className="table_search clear_search" onKeyUp={handleSearch} placeholder="Searched item" title=""/> */}
							<div className='maxWidth550 display-flex'>
								<div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
									<div className={`search_container ${hasReportType ? 'active-report-type' : ''}`}>
										<div className="search_white_icon"
											style={{ cursor: 'pointer' }}
											onClick={handleLensSearch}
										>&nbsp;</div>&nbsp;
										<Tags tags={getTagsForSearchInput(tags)} deleteTag={deleteTag} />&nbsp;
										{/* {tags?.map((tag, index) => <div key={tag + index} title={tag?.toString()} className="search_tag">{tag}&nbsp;<span className="search_close_btn" onClick={() => deleteTag(tag)}>X</span></div>)}&nbsp; */}
										<input
											className="search_input"
											value={searchInput}
											placeholder="Search..."
											onKeyDown={handleSearch}
											onChange={handleSearchChange}
										/>
										{getTagsForSearchInput(tags)?.length > 0 ? <span style={{ cursor: 'pointer' }} onClick={() => {
											setTagFlag(true);
											if (isTimeBasedSearchApplied) {
												const tempTags = tags.filter(item => item.indexOf(TIME_BASED_SEARCH_FIELD) > -1);
												setTags(tempTags);
											} else {
												setTags([])
											}
											PostureSearchState.setIsAdSearchApplied(false)
										}}>&nbsp;X</span> : null}
									</div>
								</div>
								<div className='issue-advance-search-btn-con display_flex_center '
									style={{ float: 'right' }}
								><button style={{ float: 'left' }} onClick={onAdvanceSearchClick} className={'button_styled issue-advance-search'} >Advanced Search</button>


									{editConfig && <Tooltip classes={{ tooltip: 'playbook-level-widget-tooltip' }} title="Configure View : Identity Systems Issue">
										<div style={{ float: 'right' }}
											className=" gear-setting-icon setting-identity-icon"
											onClick={openSettingModal}>
										</div>
									</Tooltip>
									}
									{!editConfig && <Tooltip classes={{ tooltip: 'playbook-level-widget-tooltip' }} title={'You do not have permission to change this settings. Please contact your administrator for assistance.'}>
										<div className="gear-setting-icon setting-identity-icon" style={{ opacity: 0.5, cursor: 'default' }}>

										</div>
									</Tooltip>
									}

									<PlaybookTableActions actions={MoreActionsOptions} rowData={{}}
										isDisabled={selectCount == 0 || showAdvanceSearch || isTimeBasedSearchApplied}
									/>
								</div>
							</div>
						</div>
					</div>

					<BaseAMAdminGrid
						containerRef={containerRef}
						gridRef={gridRef}
						columnDefs={gridCols}
						fetchEntitiesFn={fetchIdentities}
						query={query}
						setQuery={setQuery}
						classes={{ gridContainerClass: 'asset-base-am-grid directories-base-am-grid' + (showAdditionalResult ? " directories-page-posture-grid-am-additional-col" : " directories-page-posture-grid-am-no-additional-col") }}
						onCurrentPageSelect={onCurrentPageClick}
						onSelectAllRow={onSelectAllClick}
						onSelectRow={onSetSelectRow}
						{...(authDetails?.permissions?.Posture?.manual_archive !== "readonly"
							? { showCheckbox: true } : null)}
						headerCheckBoxConfig={headerCheckboxConfig}
						getCheckboxParams={getCheckboxParams}
					/>
					<div className="download_section">
						<button type={('button')} onClick={downloadCSV} className={"width180 " + (loadingCSV.loading ? 'loader export_loader' : 'button_styled')}>Export CSV</button>

						{loadingCSV.loading ?
							<div className='loading_info'>
								Received <span ><b>{totalDownloaded}</b></span> of <span ><b>{totalCount}</b></span> Directories
							</div>
							: null}
						{PartnerConfigState?.PartnerShortProduct && <CSVLink
							headers={getHeaders(headers, issueHeader, 'Issues')}
							// filename={`${PartnerConfigState?.PartnerShortProduct?.replaceAll(" ", "_")}_Directories.csv`}
							filename={getExportFileName(PartnerConfigState?.PartnerShortProduct, 'Identity_Systems')}
							data={downData!}
							ref={csvLinkEl}
						/>}
						<div style={{ marginLeft: '1rem', marginTop: '1rem' }}>
							<Link to={'/archivedata'} className='link-text font12'>Directories inactive for more than 60 days are archived here.</Link>
						</div>
					</div>
				</div>
			</div>
			{showIDAccess ? <IdAccess data={idAccess} prevURL={prevUrl} aname={asset_name} asset_url={asset_url} handleIDAccessClose={() => setShowIDAccess(false)} /> : null}
			{showAssetAccess ? <AssetAccess data={idAccess} prevURL={prevUrl} aname={asset_name} asset_url={asset_url} handleAssetAccessClose={() => setShowAssetAccess(false)} /> : null}
			{openFlow ? <FlowTrend data={flowsTrendResponseData} chartDetails={flowsChartDetails} onRangeChange={handleRangeChange} closeFlowPopup={() => setOpenFlow(false)} zoomLevel={zoomLevel} showZoom={true} updatedExtremes={updatedExtremesRef} /> : ''}

			<PlayBookView widId='directory' openModal={openModal} handleClose={handleClose} getData={getPlayBookData}></PlayBookView>

		</>
	)
}

export default React.memo(withQueryParams({
	q: StringParam,
	page: StringParam,
	rpp: NumberParam,
	sort_by: StringParam,
	order_by: StringParam,
	rule_name: StringParam,
	d_protocol: StringParam,
	risk: NumberParam,
	hash: StringParam,
	d_name: StringParam,
	s_time: NumberParam,
	e_time: NumberParam,
	hard_refresh: StringParam,
	filters: withDefault(ArrayParam, []),
	entity_type: StringParam,
	playbook_type: StringParam
}, Directory));
