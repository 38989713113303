import React, { useState, useEffect } from "react"
import './PlaybookActions.scss'
import { ActionType, DISABLE_NOTIFY_ADMINS, POSTURE_ISSUE, RuleActionConfig } from "../../constants/Constants"
import { CreateIncConfig, NotifyAdminConfig } from "../../action-config"
import { UserConfigState } from "../../../../types/response-types"
import { IPlaybookActionsProps, IPlaybookActionsState } from "./PlaybookTypes"

export const PlaybookActions = ({ formType, ruleType, saveActions, defaultActions = [] }: IPlaybookActionsProps) => {
    const [playbookActionsState, setPlaybookActionsState] = useState<Array<IPlaybookActionsState>>(defaultActions);
    const [showConfig, setShowConfig] = useState('');

    const onCheckboxClick = (isChecked: boolean, actionType: string, config?: unknown) => {
        if (formType == 'view') return;
        const _cloneActions = JSON.parse(JSON.stringify(playbookActionsState)) as Array<IPlaybookActionsState>;
        const index = _cloneActions.findIndex((value: IPlaybookActionsState) => value.name == actionType);
        if (isChecked) {
            if (index > -1) {
                const item = _cloneActions[index] as IPlaybookActionsState;
                if (config) {
                    item.config = config;
                }
                setPlaybookActionsState(_cloneActions)
            } else {
                const item: IPlaybookActionsState = { name: actionType, ...(config as Object && { config: config }) };
                _cloneActions.push(item);
                setPlaybookActionsState(_cloneActions)
            }
        } else {
            _cloneActions.splice(index, 1);
            setPlaybookActionsState(_cloneActions);
        }
    }

    useEffect(() => {
        // console.log(playbookActionsState);
    }, [playbookActionsState])


    const updateNotifyAdminState = (state: Array<any> | undefined, notifyType: string) => {
        const stateData = { admin_ids: state } as any;
        if (notifyType != null) {
            stateData.notify_admins_option = notifyType;
        }
        onCheckboxClick(true, ActionType.NOTIFY_ADMIN.toString(), stateData);
        setShowConfig('');
    }

    const updateTicketState = (state: UserConfigState | undefined) => {
        onCheckboxClick(true, ActionType.CRATE_TICKET.toString(), state);
        setShowConfig('');
    }


    const getConfigData = (actionType: string): any => {
        return playbookActionsState.find((value: IPlaybookActionsState) => value.name == actionType)?.config || undefined;
    }

    const handleConfigClick = (actionType: ActionType) => {
        if (getConfigData(actionType)) {
            setShowConfig(actionType.toString());
        }
    }

    const configCloseHandler = (actionType: ActionType) => {
        const config = getConfigData(actionType);
        if (!config) {
            onCheckboxClick(false, actionType, undefined);
        }
        setShowConfig('');
    }

    const isChecked = (actionType: ActionType) => {
        const _cloneActions = JSON.parse(JSON.stringify(playbookActionsState)) as Array<IPlaybookActionsState>;
        const index = _cloneActions.findIndex((value: IPlaybookActionsState) => value.name == actionType);
        return index > -1;
    }

    useEffect(() => {
        saveActions(playbookActionsState);
    }, [playbookActionsState])

    useEffect(() => {
        if (playbookActionsState.length == 0 && defaultActions.length > 0)
            setPlaybookActionsState(defaultActions);
    }, [defaultActions])

    return <>
        <div className="playbook-actions-root-container">
            <hr></hr>
            <div
                className="playbook-actions-header margin-top-bottom-8" ><span className="section-heading">Actions</span></div>
            <span className="action-note">By default, Playbooks will always create incidents. In addition, the following actions can be optionally configured:</span>
            <div className="playbook-actions-container">
                {/* <div><input type="checkbox"
                    checked={isChecked(ActionType.DASHBOARD_VIS)}
                    onClick={(e) => onCheckboxClick(e.currentTarget.checked, ActionType.DASHBOARD_VIS.toString())}
                ></input><span className="action-label">Dashboard Visibility</span></div> */}
                <div>
                    <input type="checkbox"
                        checked={isChecked(ActionType.NOTIFY_ADMIN)}
                        onClick={(e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
                            if (formType == 'view') return;
                            onCheckboxClick(e.currentTarget.checked, ActionType.NOTIFY_ADMIN.toString());
                            if (e.currentTarget.checked)
                                setShowConfig(ActionType.NOTIFY_ADMIN.toString());
                        }
                        }
                    ></input><span className={"action-label" + (getConfigData(ActionType.NOTIFY_ADMIN) ? ' active-action' : '')}
                        onClick={() => handleConfigClick(ActionType.NOTIFY_ADMIN)}
                    >Notify Admins (email)</span></div>
                {ruleType && ruleType !== POSTURE_ISSUE.type && !DISABLE_NOTIFY_ADMINS[ruleType] &&
                    <div ><input
                        checked={isChecked(ActionType.NOTIFY_USER)}
                        type="checkbox"
                        onClick={(e) => onCheckboxClick(e.currentTarget.checked, ActionType.NOTIFY_USER.toString())}
                    ></input><span className={"action-label "}
                    >Notify Users (email)</span></div>}
                <div ><input type="checkbox"
                    checked={isChecked(ActionType.CRATE_TICKET)}
                    onClick={(e) => {
                        if (formType == 'view') return;
                        onCheckboxClick(e.currentTarget.checked, ActionType.CRATE_TICKET.toString());
                        if (e.currentTarget.checked)
                            setShowConfig(ActionType.CRATE_TICKET.toString());
                    }}
                ></input><span className={"action-label"
                    + (getConfigData(ActionType.CRATE_TICKET) ? ' active-action' : '')}
                    onClick={() => handleConfigClick(ActionType.CRATE_TICKET)}
                >Create Ticket</span></div>
            </div>
        </div >
        {
            showConfig == ActionType.NOTIFY_ADMIN.toString() &&
            <NotifyAdminConfig openFlag={true}
                updateParentState={updateNotifyAdminState}
                parentState={getConfigData(ActionType.NOTIFY_ADMIN)} ruleType={ruleType}
                closeHandler={() => configCloseHandler(ActionType.NOTIFY_ADMIN)}
                formType={formType}
            />
        }
        {
            showConfig == ActionType.CRATE_TICKET.toString() &&
            <CreateIncConfig openFlag={true} updateParentState={updateTicketState}
                parentState={getConfigData(ActionType.CRATE_TICKET)}
                closeHandler={() => configCloseHandler(ActionType.CRATE_TICKET)}
                formType={formType}
            />
        }
    </>
}