import React, { useState, useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form'
import {
    withQueryParams,
    StringParam,
    NumberParam,
    ArrayParam,
    withDefault,
} from 'use-query-params'
import { useHistory, Link } from "react-router-dom"
import SlidingPanel, { PanelType } from 'react-sliding-side-panel'
import 'react-sliding-side-panel/lib/index.css'
import { Api } from '../../components/Axios'
import { AddPanel, EditPanel } from './sub'
import ReactPaginate from 'react-paginate'
import { useToasts } from '../../components/core'
import { ActionMenu } from '../../theme/ActionMenu'
import purify from 'dompurify';
import { useAuthDetails } from '../../components/Authorization'
import { SensorListPopUp } from './reference/SensorListPopUp'
import ModalPopup from '../../components/core/AMModalPopup/ModalPopup'
import { ColDef } from '@ag-grid-community/core'
import { ITableAction } from '../../components/core/AMTable/AMAdminTable'
import BaseAMAdminGrid, { getQueryParams } from '../../components/core/AMTable/BaseAMAdminGrid'
import { useBaseAMGrid } from '../../components/core/AMTable/useBaseAMGrid'

// import './listing.css'

type Search = {
    query?: string;
}

type Listing = {
    user_id?: string;
    company_id?: string;
    _id?: string;
    name?: string;
    ip?: string;
    port?: string;
    ssl?: string;
    basic_userid?: string;
    basic_pwd?: string;
    ntlm_userid?: string;
    ntlm_pwd?: string;
    is_ssl?: boolean;
    is_basic?: boolean;
    is_ntlm?: boolean;
}

type Result = {
    total: number;
    result: Listing[];
    rpp: number;
    sort_by: string;
    order_by: string;
}

type ActionType = {
    type?: string;
    id?: string;
    name?: string
}

interface Params {
    rpp?: number;
    sort_by?: string;
    order_by?: string;
    q?: string;
    page?: number;
}

const ProxyDataCols: Array<ColDef> = [
    {
        headerName: 'Name',
        field: 'name',
        sortable: true,
        sort: "asc",
        cellClass: 'align_left width380'
    },
    {
        headerName: 'IP / Host Name / FQDN',
        field: 'ip',
        sortable: true,
        cellClass: 'align_left width380'
    },
    {
        headerName: 'Port',
        field: 'port',
        sortable: true,
        cellClass: 'align_left width380',
    },
    {
        headerName: 'Authentication',
        sortable: false,
        cellClass: 'align_left width380',
        autoHeight: true,
        cellRenderer: ({ data }) => {
            const u = data;
            return <div>
                {u.is_ssl ? <div>SSL</div> : null}
                {u.is_ntlm ? <div>NTLM/Negotiate</div> : null}
                {u.is_basic ? <div>Basic</div> : null}
                {!u.is_ssl && !u.is_ntlm && !u.is_basic ? <div>None</div> : null}
            </div>
        }
    }
]

const ProxyTableActions: ITableAction<any>[] = [
    {
        actionId: "edit",
        actionLabel: "Edit",
    },
    {
        actionId: "delete",
        actionLabel: "Delete",
    }
];


const Groups = ({ query, setQuery }: any) => {
    const { register, handleSubmit } = useForm<Search>()
    const { authDetails } = useAuthDetails()!;
    const { q: q, page: page_number, rpp: record_per_page, order_by: order, sort_by: sort } = query
    const [actionType, setActionType] = useState<ActionType>()
    const [openPanel, setOpenPanel] = React.useState<boolean>(false)
    const [panelType, setPanelType] = React.useState<PanelType>('right')
    const [panelSize, setPanelSize] = React.useState<number>(30)
    const [panelTitle, setPanelTitle] = useState<string>("")
    const [result, setResult] = useState<Result>()
    const { addToast } = useToasts();
    const [showSensorList, setShowSensorList] = useState(false);
    const [sensorList, setSensorList] = useState([]);
    const [deletePopUp, setDeletePopUp] = useState({ show: false, name: '' });
    const childRef = useRef();
    const { refreshGrid } = useBaseAMGrid(childRef)
    let p: Params = {
        q: q,
        page: page_number,
        rpp: record_per_page || 100,
        order_by: order,
        sort_by: sort
    }

    const [nameSortDir, setNameSortDir] = useState<string | undefined>("tablesort_down");
    const [ipSortDir, setIPSortDir] = useState<string | undefined>("tablesort_down");
    const [portSortDir, setPortSortDir] = useState<string | undefined>("tablesort_down");

    const highlightSortDir = () => {
        if (query.sort_by === "name" && query.order_by === "asc") {
            setNameSortDir("tablesort_up_selected")
        } else if (query.sort_by === "name" && query.order_by === "desc") {
            setNameSortDir("tablesort_down_selected")
        } else if (query.sort_by === "ip" && query.order_by === "asc") {
            setIPSortDir("tablesort_up_selected")
        } else if (query.sort_by === "ip" && query.order_by === "desc") {
            setIPSortDir("tablesort_down_selected")
        } else if (query.sort_by === "port" && query.order_by === "asc") {
            setPortSortDir("tablesort_up_selected")
        } else if (query.sort_by === "port" && query.order_by === "desc") {
            setPortSortDir("tablesort_down_selected")
        }
    }


    useEffect(() => {
        console.log(query)
        /* Listing() */
    }, [query])

    useEffect(() => {
        switch (actionType?.type) {
            case 'add':
                setPanelTitle("Add Proxy")
                setOpenPanel(true)
                return

            case 'edit':
                setPanelTitle("Edit Proxy")
                setOpenPanel(true)
                return

            case 'delete':
                setDeletePopUp({ show: true, name: actionType?.name || '' });
                return

            default:
                return
        }

    }, [actionType])

    const onSubmit = (data: any) => {
        p.q = data.query;
        p.page = 1
        setQuery(p)
    }

    const handleDelete = () => {
        const headers = { 'Operation': 'DELETE' }
        Api.post('/proxies/' + actionType?.id, "", { headers: headers })
            .then((res: { data: any }) => {
                setDeletePopUp({ show: false, name: '' })
                addToast("Proxy deleted successfully.", {
                    appearance: 'success',
                    autoDismiss: true,
                })
                p.page = 1
                setQuery(p)
                refreshGrid()
            })
            .catch((error: any) => {
                if (error.response.status === 500) {
                    addToast("Sorry, something went wrong there, try again.", {
                        appearance: 'error',
                        autoDismiss: true,
                    })
                } else if (error.response.status === 419) {
                    addToast("We encounted validation problem, please correct and try again.", {
                        appearance: 'error',
                        autoDismiss: true,
                    })
                } else if (error.response.status === 404) {
                    addToast("We are not able to find associated email, please check and try again.", {
                        appearance: 'error',
                        autoDismiss: true,
                    })
                }
                else if (error.response.status === 403) {
                    addToast("Proxy can not be deleted, it is being used in a sensor. ", {
                        appearance: 'error',
                        autoDismiss: true,
                    })
                }
            })
    }

    const RightPanel = () => {
        return (
            <SlidingPanel
                type={panelType}
                isOpen={openPanel}
                size={panelSize}
                panelClassName="sidepane fix_width"
            >
                <div className="pane_container">
                    <div className="pane_heading">{panelTitle}</div>
                    <div className="pane_close" onClick={() => setOpenPanel(false)}></div>
                    <div className="hr_ruler"></div>
                    <div className="panel_body">
                        {actionType?.type === "add" &&
                            <AddPanel AfterProxiesOperation={AfterProxiesOperation} />
                        }

                        {actionType?.type === "edit" &&
                            <EditPanel id={actionType.id} AfterProxiesOperation={AfterProxiesOperation} />
                        }
                    </div>
                </div>
            </SlidingPanel>
        )
    }

    const renderPopup = () => {
        return (
            <ModalPopup
                onCloseHandler={() => setOpenPanel(false)}
                title={panelTitle}
                isOpen={openPanel}
            >
                {actionType?.type === "add" &&
                    <AddPanel AfterProxiesOperation={AfterProxiesOperation} />
                }

                {actionType?.type === "edit" &&
                    <EditPanel id={actionType.id} AfterProxiesOperation={AfterProxiesOperation} />
                }

            </ModalPopup>
        )
    }

    const Listing = (queryParams?, paramGrid?) => {
        if (paramGrid)
            Api.get('/proxies', { params: queryParams })
                .then((res: { data: any }) => {
                    setResult(res.data)
                    if (paramGrid) {
                        paramGrid.success({
                            rowData: res.data.result,
                            rowCount: res.data.total
                        });
                    }
                    setQuery({ ...query, ...queryParams })
                    if (childRef?.current?.api) {
                        childRef.current.api.hideOverlay();
                        if (res.data.total === 0) {
                            childRef.current.api.showNoRowsOverlay();
                        }
                    }
                })
                .catch((error: any) => {
                })
    }

    ProxyTableActions[0].actionCallback = (e) => {
        setActionType({
            id: e._id,
            type: "edit",
            name: e.name || ''
        })
    }

    ProxyTableActions[1].actionCallback = (e) => {
        setActionType({
            id: e._id,
            type: "delete",
            name: e.name || ''
        })
    }

    const Action = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        setActionType({
            id: e.currentTarget.id,
            type: e.currentTarget.dataset.action,
            name: e.currentTarget.dataset?.name || ''
        })
        e.preventDefault()
    }

    const Paginate = (selectedPage: any) => {
        p.page = selectedPage.selected === 0 ? 1 : selectedPage.selected + 1
        setQuery(p)
    }

    const AfterProxiesOperation = (action: string, response?: any) => {
        setOpenPanel(false)
        if (action === 'add') {
            p.page = 1
            p.q = ""
            setQuery(p)
            refreshGrid()
        } else {
            refreshGrid()
        }

        if (action == 'edit' && response && response['sensors list']?.length > 0) {
            setShowSensorList(true);
            setSensorList(response['sensors list'] || []);
        }

    }

    const LoadSorting = (field: string) => {
        if (p.sort_by === field) {
            p.order_by = p.order_by === 'asc' ? 'desc' : 'asc'
            setQuery(p)
        } else {
            p.sort_by = field
            p.order_by = 'desc'
            setQuery(p)
        }
    };

    const SortBy = (name: string) => {
        if (p.sort_by === name) {
            return p.order_by === 'desc' ? 'tablesort_down' : 'tablesort_up'
        }
    }

    return (
        <>
            {/* <RightPanel /> */}
            {renderPopup()}
            <div className="page_title_area">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div className='font18'>Proxies</div>
                        <div className="search_container issue_page scrollbar-container marginleft20 admin-page-search-container">
                            <div className="search_white_icon flex_none" style={{ cursor: 'pointer' }}
                                onClick={handleSubmit(onSubmit)}
                            >&nbsp;</div>&nbsp;
                            <input
                                className="search_input flex_none admin-page"
                                defaultValue={p.q}
                                placeholder="Name, Host Name and IP"
                                ref={register}
                                name='query'
                            />
                        </div>
                        {authDetails.permissions.Admin.proxies !== "readonly" ?
                            <div className="launch_button admin_page_launch_btn" >
                                <button type="button" className="button_main" onClick={() => setActionType({ id: "", type: "add" })}>Add</button>
                            </div>
                            : <div className="launch_button hide-launch-button">
                                <div className="button_main" style={{ background: 'transparent' }}></div>
                            </div>
                        }
                    </div>
                </form>
            </div>
            <BaseAMAdminGrid
                columnDefs={ProxyDataCols}
                query={query}
                setQuery={setQuery}
                fetchEntitiesFn={Listing}
                actions={ProxyTableActions}
                gridRef={childRef}
            />
            {showSensorList ?
                <SensorListPopUp data={sensorList} showPopUp={showSensorList} closeSensorList={() => setShowSensorList(false)} /> : null}
            {deletePopUp?.show && <DeleteProxy onCloseHandler={() => setDeletePopUp({ name: '', show: false })}
                onYesClick={handleDelete} name={deletePopUp.name}
            />}
        </>
    )
}

export default withQueryParams({
    q: StringParam,
    page: StringParam,
    rpp: NumberParam,
    sort_by: StringParam,
    order_by: StringParam,
    filters: withDefault(ArrayParam, [])
}, Groups)

interface IDeleteProxyProps {
    onYesClick(): any;
    onCloseHandler(): any,
    name: string
}

const DeleteProxy = ({ onYesClick, onCloseHandler, name }: IDeleteProxyProps) => {
    return <ModalPopup title='Delete Proxy' isOpen={true} onCloseHandler={onCloseHandler}>
        <span>Are you sure you want to delete proxy: <strong>{name}</strong></span>
        <div className="popup-container-button-container dialog-button">
            <button type="button" onClick={onCloseHandler} className={"float_right button_styled button_gray"}>
                No
            </button>
            <button type={"button"} className={"float_right button_styled"} onClick={onYesClick}>
                Yes
            </button>
        </div>
    </ModalPopup>
}