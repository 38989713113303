import { useEffect, useState } from "react";


export const useBaseAMGrid = (gridRef) => {
    const refreshGrid = () => {
        if (gridRef?.current) {
            gridRef?.current.api?.onFilterChanged()
        }
    }

    return { refreshGrid }
}