import { CustomCellRendererProps } from "@ag-grid-community/react";
import { FunctionComponent } from "react";
import { PostureCategory } from "../../ref/PostureCategories/PostureCategory";
import React from "react";
import { DirectoriesObj } from "../../../../types/response-types";
import { useHistory } from "react-router-dom";
import { usePostureStateContext } from "../../../../store/PostureStateContextProvider";
import { PostureSearchTemplate } from "../../constants/PostureSearchTemplate";
import { getTimeBasedSearchQueryParam } from "../../../../components/core/PostureFilter/posture-filter-utils";

interface IdentitySystemTypeAuthFailPerRendererProps extends CustomCellRendererProps {
    responseData, tags
}

export const IdentitySystemTypeAuthFailCell: FunctionComponent<IdentitySystemTypeAuthFailPerRendererProps>
    = ({ data, responseData, tags }) => {

        const item = data;
        const history = useHistory();
        const { PostureSearchState } = usePostureStateContext()
        const flow_fail_per = item.auth_failed_percent
            ? item.auth_failed_percent
            : 0;

        const handleFailRedirect = (e: React.MouseEvent<HTMLDivElement>) => {
            //Redirect to AccessPage
            let directory = e.currentTarget.id?.toString();
            const data = responseData?.result.find(
                (i: DirectoriesObj) => i._id === directory
            ) as DirectoriesObj;
            let searchStr = '';
            if (!tags.includes('num_of_auth_failed_flow')) {
                searchStr = `num_of_auth_failed_flow|1,${data.flow_fail_count}`;
            }
            const dir_name =
                e.currentTarget.getAttribute('data-dirname')?.toString() || '';
            const prevState = history?.location?.state as any;
            const prevUrl = history.location.pathname;
            const prevSearch = history.location.search;
            PostureSearchState.setIsAdSearchApplied(false);
            if (directory.includes(',')) {
                directory = `"${directory}"`;
            }
            const timeSearchParam = getTimeBasedSearchQueryParam(PostureSearchTemplate, tags);

            if (timeSearchParam === "") {
                history.push(
                    '/posture/accesses?order_by=desc&sort_by=auth_failed_percent&page=1&q=' +
                    searchStr +
                    '%2Bdir_name:' +
                    encodeURIComponent(directory),
                    {
                        prevWidget: prevState?.breadcrumbId,
                        breadcrumbId: 'PostureAccesses',
                        prevUrl,
                        prevSearch,
                        customData: { path: 'failDir', directory_name: dir_name }
                    }
                );
            } else {
                history.push(
                    '/posture/accesses?order_by=desc&sort_by=auth_failed_percent&page=1&q=' +
                    searchStr +
                    '%2Bdir_name:' +
                    encodeURIComponent(directory) + '%2B' + encodeURIComponent(timeSearchParam),
                    {
                        prevWidget: prevState?.breadcrumbId,
                        breadcrumbId: 'PostureAccesses',
                        prevUrl,
                        prevSearch,
                        customData: { path: 'failDir', directory_name: dir_name }
                    }
                );
            }
        };

        return <>{flow_fail_per > 3 ? (
            <div
                className='shadowbox num_flows'
                onClick={handleFailRedirect}
                id={item?.am_hostname?.toString()}
                data-dirname={item.name?.toString()}
            >
                <span style={{ color: '#FA1262' }}>
                    {' '}
                    {flow_fail_per.toFixed(1)}%
                </span>
            </div>
        ) : flow_fail_per > 0 && flow_fail_per < 0.5 ? (
            <div
                className='shadowbox num_flows'
                onClick={handleFailRedirect}
                id={item?.am_hostname?.toString()}
                data-dirname={item.name?.toString()}
            >
                <span>{0.1}%</span>
            </div>
        ) : flow_fail_per <= 0 ? (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <span>0%</span>
            </div>
        ) : (
            <div
                className='shadowbox num_flows'
                onClick={handleFailRedirect}
                id={item?.am_hostname?.toString()}
                data-dirname={item.name?.toString()}
            >
                <span >
                    {Math.round(flow_fail_per).toFixed(1) +
                        '%'}
                </span>
            </div>
        )}</>
    }
