import React, { useEffect, useState } from "react"
import './directory-config.css';
import { BaseProps } from "../../constants/types";
import { SuspectedADBruteForceState, ThresholdType } from "../../../../types/playbooks-config";
import { Tooltip } from "@mui/material"
import InfoIcon from '@mui/icons-material/Info';
import { usePartnerStateContext } from "../../../../store/PartnerContextProvider";
import CheckboxAutocomplete from "../../../../components/core/AMAutoComplete/AMAutoComplete";
import { getDefaultConfigState } from "../../helpers/playbook-helper";
import { ThresholdSelect } from "../../ref/ThresholdSelect";

export const SuspectedADBruteForce = ({ parentState, isSavedOnce, getState, defaultState, formType, getExpression, ruleType }: BaseProps<SuspectedADBruteForceState>) => {
    const { PartnerConfigState } = usePartnerStateContext();
    const [state, setState] = useState<SuspectedADBruteForceState>(defaultState);
    const [checked, setChecked] = React.useState<any>({      
        num_acc_bad_pass: isSavedOnce ? (parentState.dir_params.dir_bad_password_nuser_curhour ? true : false)
            : (state?.dir_params.dir_bad_password_nuser_curhour ? true : false),
        schedule: true
    });

    useEffect(() => {
        getState({
            dir_params: {
                dir_bad_password_nuser_curhour: checked?.num_acc_bad_pass ?
                    state?.dir_params?.dir_bad_password_nuser_curhour : undefined,
                param_thresholds: state?.dir_params?.param_thresholds
            }
        })
    }, [state, checked])

    useEffect(() => {
        if (defaultState) {
            setState(defaultState);
            setChecked({
                num_acc_bad_pass: (defaultState.dir_params.dir_bad_password_nuser_curhour ? true : false),
                schedule: true
            })
        }
    }, [defaultState])

    useEffect(() => {
        if (isSavedOnce) {
            setState(parentState);
            setChecked({
                num_acc_bad_pass: (parentState.dir_params.dir_bad_password_nuser_curhour ? true : false),
                schedule: true
            })
        }
    }, [isSavedOnce])


    const handleAccBadPass = (e: React.ChangeEvent<HTMLInputElement>) => {
        setState({ dir_params: { ...state?.dir_params, dir_bad_password_nuser_curhour: parseInt(e.target.value) } })
        setChecked({ ...checked, num_acc_bad_pass: e.target.value ? true : false })

    }

    const onCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>, keyType: string) => {
        setChecked({ ...checked, [keyType]: e.target.checked })
    }

    const onThresholdSelect = (value) => {
        const defaultSt = getDefaultConfigState(ruleType) as SuspectedADBruteForceState;
        let v2 = defaultSt?.dir_params?.dir_bad_password_nuser_curhour
        let st;
        if (state?.dir_params.param_thresholds) {
            st = [...state?.dir_params.param_thresholds];
        } else {
            st = [...defaultSt?.dir_params.param_thresholds];
        }
        st[0].threshold_type = value.target.value
        st[1].threshold_type = value.target.value
        if (value.target.value === ThresholdType.AUTOMATIC) {
            st[0].allow_ml_modify = true;
            st[0].threshold_confidence = 'low';
            st[1].allow_ml_modify = true;
            st[1].threshold_confidence = 'low';

        } else {
            st[0].allow_ml_modify = false;
            st[0].threshold_confidence = undefined;
            st[1].allow_ml_modify = false;
            st[1].threshold_confidence = undefined;
        }
        setState({ dir_params: { dir_bad_password_nuser_curhour: v2, param_thresholds: st } });
    }


    return <>
        <div className="dir-main-container dir-brute-force  ">
            <div className="dir-container-row enum-ad-container">
                <label>Detect using: </label>
                <ThresholdSelect
                    classes={{ divClass: "enum-ad-threshold" }}
                    onThresholdSelect={onThresholdSelect}
                    defaultValue={state?.dir_params?.param_thresholds?.[0]?.threshold_type || ThresholdType.MANUAL} />
            </div>
            <div className={(state?.dir_params?.param_thresholds?.[0]?.threshold_type === ThresholdType.AUTOMATIC ? ' disableItems' : '')}>

                <div className="dir-container-row bold-text">
                    <label className="playbook-config-title marginBottom0">
                        An Active Directory (AD) will be marked as under brute-force attack when:</label>
                </div>
                <div className="dir-container-row brute_force_justify_none_gap_5">
                    <input type="checkbox" id="num-admin-attempts" onChange={(e) => onCheckboxChange(e, 'num_acc_bad_pass')}
                        checked={checked?.num_acc_bad_pass}
                        defaultChecked={checked?.num_acc_bad_pass}
                        className={(['edit', 'view'].includes(formType || '') ? ' disable-config-item margintop20' : ' margintop20')}
                    />
                    <label htmlFor="num-admin-attempts"
                        className={(formType && ['edit', 'view'].includes(formType)) ? 'disable-config-item' : ''}
                    >
                        <Tooltip
                            classes={{ tooltip: 'generic-tooltip-popper' }}
                            PopperProps={{
                                style: { zIndex: 999999 }
                            }}
                            title={<div className="width250">
                                {/* This parameter is used only for an Active Directory with an {`${PartnerConfigState.PartnerProductName}`} AD sensor deployed. */}
                                This parameter is used only for an Active Directory with an AuthMind AD sensor deployed.
                            </div>}
                        ><span style={{ marginLeft: '0.1em' }}><InfoIcon style={{ fontSize: 14, color: '#d4d8e1', transform: 'skewX(-10deg)' }} /></span></Tooltip>
                        Number of AD accounts in the current hour with 'bad password' error is more than
                    </label>
                    {
                        (formType && ['edit', 'view'].includes(formType)) ? <label className="view-label">
                            {state?.dir_params.dir_bad_password_nuser_curhour}</label> :
                            <input type="number" onChange={handleAccBadPass} name="num_acc_bad_pass" className="margintop10"
                                value={state?.dir_params?.dir_bad_password_nuser_curhour || ''} />
                    }
                </div>
            </div>
        </div>
    </>
}