import { Chip, Tooltip } from '@mui/material';
import moment from 'moment';
import * as qs from "qs";
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { CSVLink } from "react-csv";
import { Data } from 'react-csv/components/CommonPropTypes';
import { useHistory } from 'react-router';
import { Link, useLocation } from "react-router-dom";
import SwitchButton from "react-switch";
import ReactTooltip from 'react-tooltip';
import { ArrayParam, NumberParam, StringParam, withDefault, withQueryParams } from 'use-query-params';
import { IDScore } from '../../../common/IDScore/IDScore';
import { Api } from '../../../components/Axios';
import { useToasts } from '../../../components/core';
import { AssetsObj, IdentitiesMappingObj, IssueFilters, IssueParams, RulesDetailObj, ServerResponse } from '../../../types/response-types';
import { findNameById, getTimeDiffString, isIssueType, trimAfterSecondWord, getExportFileName, getCustomLabelForRuleName, getResolutionMatchFor1920 } from '../../../utils/util-methods';
import FlowTrend from '../../issue_prev/flow-trend/flow-trend';
import { AssetsActionColumnMenu, ExposedAssetColumnMarkKnownOptions } from '../constants/IDPostureMenu';
import '../identity_asset.scss';
import '../posture_root_tooltip.css';
import { PostureMenuItem, PostureMenuItemAction } from '../ref/PostureActionMenus/PostureActionMenu';
import { ScrollIssueHeaderRef } from '../ref/ScrollIssue/ScrollIssue';
import IdAccess from './id-access-popup/idaccess';
import RuleMatch from './rule-match/rulematch';
import PostureFilter from '../../../components/core/PostureFilter/PostureFilter';
import { FilterWidget } from '../../../components/core/PostureFilter/PostureFilterWidget';
import { getSearchFilterObjectFromTags, getTagsForSearchInput, getTagsForTimeBasedSearch, getTimeBasedSearchQueryParam } from '../../../components/core/PostureFilter/posture-filter-utils';
import {
	formatCSVData, getHeaders, useScrollIssue, useLensSearch,
	usePostureAdvanceSearch, validateLocalIps, usePostureArchival,
	useAdditionalResults
} from '../ref/Hooks/Posture';
import axios, { CancelTokenSource } from 'axios';
import { usePostureStateContext } from '../../../store/PostureStateContextProvider';
import Tags from '../../issue_prev/issues/tags/tags';
import { IPostureActionButtonsRef, PostureActionButtons, PostureArchiveTooltip } from '../ref/PostureArchive/PostureArchive';
import { IApiResponse, PostureService } from '../ref/Hooks/PostureService';
import { useAuthDetails } from '../../../components/Authorization';
import { PlayBookView } from '../sub/PlayBookView';
import { ENTITIES, POSTURE_ISSUE } from '../constants/Constants';
import { useValidatePostureIssuesSearchResult } from '../ref/Hooks/PostureIssues';
import { IPlaybookActions } from '../../playbooks/ref/AdvancedPlaybook/PlaybookTypes';
import { PlaybookTableActions } from '../../playbooks/ref/AdvancedPlaybook/PlaybookTableActions';
import { PostureSearchTemplate, notSupportedKeys } from '../constants/PostureSearchTemplate';
import { AVAILABLE_PRESETS, TIME_BASED_SEARCH_FIELD } from '../../../components/TimeBasedSearch/Constants';
import TimeBasedSearchContainer from '../../../components/TimeBasedSearch/TimeBasedSearchContainer';
import { getIssueNameById } from '../../playbooks/helpers/playbook-helper';
import { useTimeBasedSearchActions } from '../../../components/TimeBasedSearch/TimeBasedSearchActions';
import { ColDef, IServerSideGetRowsParams } from '@ag-grid-community/core';
import { AssetNameCell, AssetIdentityCountCell, AssetTypeCell } from './grid';
import { findPbNameById } from '../ref/posture-utils';
import { PostureGridIssueCustomHeader } from '../ref/PostureGrid/PostureGridIssueCustomHeader';
import { ScrollIssueGridRenderer } from '../ref/ScrollIssue/ScrollIssueGrid';
import { AssetFlowCountCell } from './grid/AssetFlowCountCell';
import { PostureIssueCountCell } from '../ref/PostureGrid/grid-common-cells/PostureIssueCountCell';
import { LatestTimeCell } from '../ref/PostureGrid/grid-common-cells/LastestTimeCell';
import BaseAMAdminGrid from '../../../components/core/AMTable/BaseAMAdminGrid';
import { useBaseAMGrid } from '../../../components/core/AMTable/useBaseAMGrid';
import { PostureIssueGroupHeader } from '../ref/PostureGrid/PostureIssueGroupHeader';

const Assets = ({ query, setQuery }: any) => {

	const [checkFlag, setCheckFlag] = useState(false);
	const [prevUrl, setPrevUrl] = useState<string>(window.location.pathname + window.location.search);
	const search = useLocation().search;
	const location = useLocation();
	const hard_refresh_state: boolean = new URLSearchParams(search).get('hard_refresh') === "true" ? true : false;
	const [tableFetchError, setTableFetchError] = useState('No records found.');
	const [showRuleMatch, setShowRuleMatch] = useState(false);
	const [showIDAccess, setShowIDAccess] = useState(false);
	const [issueHeader, setIssueHeaders] = useState<Array<string>>([]);
	const [searchInput, setSearchInput] = useState<string>('');

	const [tagFlag, setTagFlag] = useState<boolean | undefined>();
	const scrollIssueRef = useRef<ScrollIssueHeaderRef>(null);
	const [showAnimation, setShowAnimation] = useState(false);
	const { updateColumnEffect, scrollToIssueColumnFn } = useScrollIssue();
	const [selectedColumn, setSelectedColumn] = useState(query.sort_by || '');
	const [currentSort, setCurrentSort] = useState(query.order_by == 'asc' ?
		' tablesort_up tablesort_up_selected ' : ' tablesort_down tablesort_down_selected ');
	const [openPanelFilterWidgets, setOpenPanelFilterWidgets] = useState(false);
	const [filterData, setFilterData] = useState<IssueFilters | undefined>();
	const disabled_filter_state: boolean = new URLSearchParams(search).get('disable_filter') === "true" ? true : false;
	const [disabledFilter, setDisabledFilter] = useState<boolean>(disabled_filter_state);
	const { handleApplyAdvancedSearch, handleManualSearch, getSearchHeaders, PartnerConfigState, preMetaDataHandler
	} = usePostureAdvanceSearch();
	const { PostureSearchState, PostureChartState } = usePostureStateContext();
	const [isClearAll, setIsClearAll] = useState(false);
	const [additionalResultHighlightClass, setAdditionalResultHighlightClass] = useState('');
	const {
		selectAll,
		onSetSelectRow,
		getSelectRow,
		onSelectAllClick,
		selectAllIndeterminate,
		setArchivalData,
		setOpenIssuesCount,
		onArchive,
		selectCount,
		isIncidentsOpen,
		onCurrentPageClick,
		resetSelectionState,
		onArchiveEstimate,
		setCurrentPage,
		currentPage
	} = usePostureArchival('Asset');

	const { tags, setTags } = PostureSearchState;
	const { handleLensSearchFn, handleSearchFn } = useLensSearch(searchInput, setTags, setSearchInput, setTagFlag);
	const [headerCheckboxConfig, setHeaderCheckboxConfig] = useState({});
	const {
		responseData, setResponseData,
		showAdvanceSearch, setShowAdvanceSearch,
		idWidget, setIdWidget,
		ruleWidget, setRuleWidget,
		ruleRootIdentity, setRuleRootIdentity,
		showGraph, setShowGraph,
		selectedItems, setSelectedItems,
		items, setItems,
		widgetData, setWidgetData,
		totalCount, setTotalCount
	} = PostureChartState;
	const { PostureSummary } = PostureService();
	const { authDetails } = useAuthDetails()!;
	const { isValidResultCountThreshold, matchingPlaybookFound } = useValidatePostureIssuesSearchResult();
	const [selectedField, setSelectedField] = useState(null);
	const [showAdditionalResult, setShowAdditionalResult] = useState(false);
	const [additionalFields, setAdditionalFields] = useState([]);
	const { isPresetPeriodOptionSelected } = useTimeBasedSearchActions();
	const archiveBtnRef = useRef<IPostureActionButtonsRef>(null);
	const MoreActionsOptions: IPlaybookActions<Array<any>> = [{
		actionCallback: () => {
			if (archiveBtnRef?.current?.onArchiveBtnClick)
				archiveBtnRef?.current?.onArchiveBtnClick()
		},
		actionId: 'mark-archive',
		actionLabel: 'Archive',
		isDisabled: selectCount == 0,
		isApplicable: authDetails?.permissions?.Posture?.manual_archive == 'readwrite'
	}
	];
	const [isTimeBasedSearchApplied, setIsTimeBasedSearchApplied] = useState(false);
	const highlightSortDir = () => {
		if (query.order_by === 'asc') {
			setCurrentSort(' tablesort_up tablesort_up_selected ');
		} else if (query.order_by === 'desc') {
			setCurrentSort(' tablesort_down tablesort_down_selected ');
		}
	};

	const [zoomLevel, setZoomLevel] = useState('hourly');
	const updatedExtremesRef = useRef({ min: null, max: null });
	const searchParams = new URLSearchParams(location.search);
	const hasReportType = searchParams.has('reportType') && searchParams.get('reportType')?.trim() !== '' ||
		searchParams.has('ruleType') && searchParams.get('ruleType')?.trim() !== '';

	const getDestinationItemIconClass = (item) => {



		switch (item.type) {
			case "Device":
				return "device_icon";
			case "App":
				return "application_account_icon";
			case "Service":
				return item?.asset_category_list && item.asset_category_list?.length > 0 ? "gear_icon_cloud" : "gear_icon_issue";
			case "Service/Application":
				return item?.asset_category_list && item.asset_category_list?.length > 0 ? "cloud_application_account_icon" : "application_account_icon";
			case "Service/Computer Account":
				return item?.asset_category_list && item.asset_category_list?.length > 0 ? "cloud_computer_account_icon" : "computer_account_icon";
			case "Service/Service Account":
				return item?.asset_category_list && item.asset_category_list?.length > 0 ? "cloud_service_account_icon" : "service_account_icon";
			case "Service/Service Principal":
				return item?.asset_category_list && item.asset_category_list?.length > 0 ? "cloud_service_principal_icon" : "principal_icon";
			case "Service/Key and Secret":
				return item?.asset_category_list && item.asset_category_list?.length > 0 ? "cloud_key_secret__icon" : "key_secret__icon";
			case "Service/Token":
				return item?.asset_category_list && item.asset_category_list?.length > 0 ? "cloud_service_token_icon" : "token_icon";
			default:
				// If identity_category_list exists, return 'settings_icon_cloud', otherwise 'settings_icon'
				return item.identity_category_list ? 'settings_icon_cloud' : 'settings_icon';
		}
	}



	const getItemDestinationTitle = (item) => {
		switch (item?.type) {
			case "Device":
				return "Device";
			case "App":
				return "App";
			case "Service":
				return "Service";
			case "Service/Application":
				return item?.asset_category_list && item.asset_category_list?.length > 0 ? "Cloud Application" : "Application";
			case "Service/Computer Account":
				return item?.asset_category_list && item.asset_category_list?.length > 0 ? "Cloud Service/Computer Account" : "Service/Computer Account";
			case "Service/Service Account":
				return item?.asset_category_list && item.asset_category_list?.length > 0 ? "Cloud Service" : "Service";
			case "Service/Service Principal":
				return item?.asset_category_list && item.asset_category_list?.length > 0 ? "Cloud Service/Service Principal" : "Service/Service Principal";
			case "Service/Key and Secret":
				return item?.asset_category_list && item.asset_category_list?.length > 0 || item?.is_dir_public ? "Cloud Service/Key and Secret" : "Service/Key and Secret";
			case "Service/Token":
				return item?.asset_category_list && item.asset_category_list?.length > 0 || item?.is_dir_public ? "Cloud Service/Token" : "Service/Token";
			case "User/Agent":
				return item?.asset_category_list && item.asset_category_list?.length > 0 ? "Cloud User/Agent" : "User/Agent";
			case "User/Background Browsing":
				return item?.asset_category_list && item.asset_category_list?.length > 0 ? "Cloud User/Background Browsing" : "User/Background Browsing";
			case "User":
				return item?.asset_category_list && item.asset_category_list?.length > 0 ? "Cloud User" : "User";
			default:
				// If identity_category_list exists, return 'settings_icon_cloud', otherwise 'settings_icon'
				return 'N/A';
		}

	}

	useEffect(() => {
		setShowAdditionalResult(true)
	}, [query.q]);

	useEffect(() => {
		let tempTags: string[] = [];
		if (param.q) {
			// tempTags = [...param.q];
			tempTags = q.split('+').map((i) => decodeURIComponent(i));
		}
		if (param.rule_name && !tempTags.includes(param.rule_name)) {
			tempTags.push(param.rule_name);
		}
		if (param.risk && !tempTags.includes(param.risk.toString())) {
			tempTags.push(param.risk.toString());
		}
		if (param.d_protocol && !tempTags.includes(param.d_protocol)) {
			tempTags.push(param.d_protocol);
		}
		if (param.d_name && !tempTags.includes(param.d_name)) {
			tempTags.push(param.d_name);
		}
		if (param.hash && !tempTags.includes(param.hash)) {
			tempTags.push(param.hash);
		}
		if (
			param.s_time &&
			param.e_time &&
			!tempTags.includes(param.s_time.toString()) &&
			!tempTags.includes(param.e_time.toString())
		) {
			tempTags.push(param.s_time.toString(), param.e_time.toString());
		}

		if (tempTags.length > 0) {
			setTags(tempTags);
			setTagFlag(false);
		} else {
			setTags([]);
		}
		const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();
		highlightSortDir();
		if (
			!history.location.pathname.includes('matchrule') &&
			!history.location.pathname.includes('flow')
		) {
			/* fetchAssets(cancelTokenSource.token); */
		}
		return () => {
			cancelTokenSource.cancel('Operation canceled due to component unmounting.');
		};
	}, [query, checkFlag]);

	// useEffect(() => {
	// 	fetchWidget();
	// }, []);




	const fetchAssets = async (queryParams: Record<string, any>, gridParams: IServerSideGetRowsParams, signal: AbortSignal) => {
		setLoading(true);
		setTableFetchError('No records found.');
		const searchHeaders = await getSearchHeaders(queryParams);
		Api.get(`/assets?is_external_not_allowed=${checkFlag}`, {
			params: queryParams,
			paramsSerializer: (params) => qs.stringify(params || {}, { arrayFormat: 'repeat' }),
			/* signal, */
			...searchHeaders
		})
			.then((res) => {
				setLoading(false);
				if (res.status === 200) {
					setTotalCount(res.data.total);
					const d = validateLocalIps(res.data, 'host_detail');
					setResponseData(d);
					const tempData = res.data.result.map((i) => {
						Object.keys(i.rules).forEach((rule) => {
							i[rule] = i.rules[rule]
						})
						return i
					})
					setArchivalData(d);
					setOpenIssuesCount(res.data.total);
					setLastUpdate(moment().format('MMM DD YYYY, hh:mm A'));
					scrollToIssueColumn();
					if (currentPage) {
						resetSelectionState();
					}
					setCurrentPage(false);
					if (gridParams) {
						gridParams.success({
							rowData: tempData,
							rowCount: res.data.total
						});
						if (gridRef?.current?.api) {
							gridRef.current.api.hideOverlay();
							if (res.data.total === 0) {
								gridRef.current.api.showNoRowsOverlay();
							}
						}
						setQuery({ ...query, ...queryParams })
					}
				}
			})
			.catch((err) => {
				console.log(err);
				if (err.code === 'ERR_CANCELED') {
					return
				}
				setLoading(false);
				setResponseData({ result: 'error' } as ServerResponse);
				addToast('Error while fetching data.', {
					appearance: 'error',
					autoDismiss: true
				});
				setTableFetchError(`An error occurred while processing your request. Please check your input and try again. If the error persists, please reach out to ${PartnerConfigState?.PartnerShortProduct} support.`);
			});
	};

	useEffect(() => {
		setHeaderCheckboxConfig({
			indeterminate: selectAllIndeterminate,
			onClick: onCurrentPageClick,
			checked: selectAll || false,
		})
	}, [responseData, selectAll, selectAllIndeterminate, selectCount])

	const reloadAssets = () => {
		reloadRef.current.classList.add('reload_spin');
		param.hard_refresh = true;
		setQuery(param);

		/* fetchAssets(); */

		const fetchData = async () => {
			try {
				const b = await fetchWidget();
				//   const c =   await setTimeout(()=>{fetchUserSelection()},300);


			} catch (error) {
				console.error('Error fetching data:', error);
			}
		};

		fetchData();
		outDated();
		setTimeout(() => {
			if (reloadRef.current.classList.contains('reload_spin')) {
				reloadRef.current.classList.remove('reload_spin');
			}
		}, 750);
	};

	const {
		q: q,
		page: page_number,
		rpp: record_per_page,
		order_by: order,
		sort_by: sort,
		rule_name: rule_name,
		d_protocol: d_protocol,
		risk: risk,
		hash: hash,
		d_name: d_name,
		s_time: s_time,
		e_time: e_time,
		hard_refresh: hard_refresh
	} = query;

	const [ruleMatch, setRuleMatch] = useState<AssetsObj | undefined>();
	const [idAccess, setIdAccess] = useState<
		IdentitiesMappingObj[] | undefined
	>();
	const [asset_name, setAname] = useState<string>('');
	const [lastUpdate, setLastUpdate] = useState<string>();
	const [openFlow, setOpenFlow] = useState<boolean>(false);
	const [flowsTrendResponseData, setFlowsTrendResponseData] =
		useState<any>(undefined);
	const [flowsChartDetails, setFlowsChartDetails] = useState<any>({
		chartTitle: ''
	});

	let history = useHistory();
	const { addToast } = useToasts();
	const reloadRef = useRef() as React.MutableRefObject<HTMLDivElement>;
	const { appliedFilterTimestamp } = useTimeBasedSearchActions();
	const [editConfig, setEditConfig] = useState(false);

	let param: IssueParams = {
		q: q,
		page: page_number,
		rpp: record_per_page,
		order_by: order,
		sort_by: sort,
		rule_name: rule_name,
		d_protocol: d_protocol,
		risk: risk,
		hash: hash,
		d_name: d_name,
		s_time: s_time,
		e_time: e_time,
		hard_refresh: hard_refresh
	};

	const LoadSorting = (field: string) => {
		if (param.sort_by === field) {
			param.order_by = param.order_by === 'asc' ? 'desc' : 'asc';
			setQuery(param);
		} else {
			param.sort_by = field;
			param.order_by = 'desc';
			setQuery(param);
		}
	};

	const handleSort = (e: React.MouseEvent) => {
		const col = e.currentTarget.getAttribute('data-headerName');
		setSelectedColumn(col);
		LoadSorting(col || '');
	};


	const handleRuleFilterClick = (e: React.MouseEvent<HTMLDivElement>) => {
		let idx = e.currentTarget.id.toString();
		let ruleName = e.currentTarget.getAttribute('data-rulename');
		const isIssue = isIssueType(items, ruleName);
		ruleName = findNameById(items, ruleName);
		let hostDetails = responseData?.result.find(
			(item: AssetsObj) => item._id === idx
		);
		let currHostDetails = hostDetails.host_detail;
		let type = hostDetails?.type.replace('Service/Application', 'Application')
			.replace('Service/Service Account', 'Service');
		let assetsRef = '';
		currHostDetails.map((item: any, index: any) => {
			if (index < 100) assetsRef += item.ip + '-' + item.port + ',';
		});
		const prevState = history?.location?.state as any;
		const prevUrl = history.location.pathname;
		const prevSearch = history.location.search;

		const timeSearchParam = getTimeBasedSearchQueryParam(PostureSearchTemplate, tags);

		if (timeSearchParam === "") {
			isIssue ? history.push(
				'/issues?disable_filter=true&order_by=desc&page=1&q=d_name:' +
				encodeURIComponent(idx) +
				'%2Bstatus:Open%2Brule_name:' +
				ruleName +
				'%2Bd_type:' +
				type +
				'&sort_by=issue_flows_count',
				{
					prevWidget: prevState?.breadcrumbId,
					breadcrumbId: 'AssetsIncidents',
					prevUrl,
					prevSearch
				}
			) : history.push(
				'/issues?disable_filter=true&order_by=desc&page=1&q=d_name:' +
				encodeURIComponent(idx) +
				'%2Bstatus:Open%2Bpb_name:' +
				encodeURIComponent(ruleName) +
				'%2Bd_type:' +
				type +
				'&sort_by=issue_flows_count',
				{
					prevWidget: prevState?.breadcrumbId,
					breadcrumbId: 'AssetsIncidents',
					prevUrl,
					prevSearch
				}
			);
		} else {
			isIssue ? history.push(
				'/issues?disable_filter=true&order_by=desc&page=1&q=d_name:' +
				encodeURIComponent(idx) +
				'%2Bstatus:Open%2Brule_name:' +
				ruleName +
				'%2Bd_type:' +
				type + '%2B' + encodeURIComponent(timeSearchParam) +
				'&sort_by=issue_flows_count',
				{
					prevWidget: prevState?.breadcrumbId,
					breadcrumbId: 'AssetsIncidents',
					prevUrl,
					prevSearch
				}
			) : history.push(
				'/issues?disable_filter=true&order_by=desc&page=1&q=d_name:' +
				encodeURIComponent(idx) +
				'%2Bstatus:Open%2Bpb_name:' +
				encodeURIComponent(ruleName) +
				'%2Bd_type:' +
				type + '%2B' + encodeURIComponent(timeSearchParam) +
				'&sort_by=issue_flows_count',
				{
					prevWidget: prevState?.breadcrumbId,
					breadcrumbId: 'AssetsIncidents',
					prevUrl,
					prevSearch
				}
			);
		}
	};



	const getChartTitle = (identityName: string, startDate: any = null, endDate: any = null) => {
		let timeUnit = "Hourly";

		if (startDate) {
			if (endDate) {
				const totalDurationHours = moment(endDate).diff(moment(startDate), 'hours');

				if (totalDurationHours < 12) {
					timeUnit = "Minute-wise";
				} else if (totalDurationHours < 48) {
					timeUnit = "10-Minute-wise";
				}
				// else timeUnit remains "Hourly"
			}

			return (
				<span
					title={`${timeUnit} flows trend (${getTimeDiffString(startDate, endDate)}) for Asset: ${identityName}`}
				>{`${timeUnit} flows trend (${getTimeDiffString(startDate, endDate)}) for Asset: ${trimAfterSecondWord(identityName, 35)}`}</span>
			);
		} else {
			return (
				<span
					title={`Hourly flows trend for Asset: ${identityName}`}
				>{`Hourly flows trend for Asset: ${trimAfterSecondWord(identityName, 35)}`}</span>
			);
		}
	};

	function getSearchFields() {
		let timeFilter = appliedFilterTimestamp();
		if (timeFilter.length > 0) {

			let startDate = moment(timeFilter[0]).unix();
			let endDate = moment(timeFilter[1]);

			if (endDate.isAfter(moment())) {
				endDate = moment().unix();
			} else {
				endDate = endDate instanceof moment ? endDate.unix() : moment(endDate).unix();
			}

			// Calculate the time difference in hours
			let diffInHours = (endDate - startDate) / 3600;

			// Determine the interval based on the difference
			let interval;
			if (diffInHours < 12) {
				interval = '1 MINUTE';
				setZoomLevel('1-minute');
			} else if (diffInHours < 48) {
				interval = '10 MINUTE';
				setZoomLevel('10-minute');
			} else {
				interval = '1 HOUR'; // Replace 'default_interval' with your desired default interval.
				setZoomLevel('hourly');
			}

			let searchFields = {
				"any_activity_time": {
					"value": [startDate, endDate],
					"type": "between"
				},
				"interval": interval
			};

			// Convert to JSON and encode
			return encodeURIComponent(JSON.stringify(searchFields));

		}
		return null; // Return null or an empty string if timeFilter is empty
	}



	function getSearchFieldsForDate(min, max) {


		let startDate = moment(min).unix();
		let endDate = moment(max);

		if (endDate.isAfter(moment())) {
			endDate = moment().unix();
		} else {
			endDate = endDate instanceof moment ? endDate.unix() : moment(endDate).unix();
		}

		// Calculate the time difference in hours
		let diffInHours = (endDate - startDate) / 3600;

		// Determine the interval based on the difference
		let interval;
		if (diffInHours < 12) {
			interval = '1 MINUTE';
			setZoomLevel('1-minute');
		} else if (diffInHours < 48) {
			interval = '10 MINUTE';
			setZoomLevel('10-minute');
		} else {
			interval = '1 HOUR'; // Replace 'default_interval' with your desired default interval.
			setZoomLevel('hourly');
		}

		let searchFields = {
			"any_activity_time": {
				"value": [startDate, endDate],
				"type": "between"
			},
			"interval": interval
		};

		// Convert to JSON and encode
		return encodeURIComponent(JSON.stringify(searchFields));



	}

	const cancelTokenSourceRef = useRef<any>(null);

	const handleRangeChange = (min: any, max: any) => {

		const searchHeaders = {
			headers: {
				search_fields: getSearchFieldsForDate(min, max)
			}
		};

		updatedExtremesRef.current = { min, max };


		const currentTime = +new Date();

		const data = JSON.parse(sessionStorage.getItem('flowDataAsset'));


		const encodedIdentityName = data ? encodeURIComponent(data?.id) : '';

		const encodedAssetType = encodeURIComponent(data?.asset_type);

		const flowTrendURL = `/assets/flowcounts?search_type=asset&search_value1=${encodedIdentityName}&asset_type=${encodedAssetType}`;

		// Cancel the previous request if one exists
		if (cancelTokenSourceRef.current) {
			cancelTokenSourceRef.current.cancel('Operation canceled due to new request.');
		}

		// Create a new cancel token for the new request
		cancelTokenSourceRef.current = axios.CancelToken.source();
		try {
			Api.get(flowTrendURL, {
				...searchHeaders,
				cancelToken: cancelTokenSourceRef.current.token, // Attach the cancel token
			})
				.then((res: any) => {
					if (!res?.data?.length || res?.data?.length <= 0) {
						setFlowsTrendResponseData({
							flows: [],
							expiry_time: currentTime + 600000
						});

						// Chart header and color
						// const chartTitle = getChartTitle(identityName);
						// setFlowsChartDetails({
						//     chartTitle, itemData: {
						//         queryData: `identity_name:${encodedIdentityName}`
						//     }
						// });
						return;
					}

					let result = JSON.parse(JSON.stringify(res?.data)) || [];
					result.sort((a: any, b: any) =>
						a?.time < b?.time ? -1 : 1
					);
					const cachedFlows = {
						flows: result,
						encodedIdentityName,
						risk,
						expiry_time: currentTime + 600000
					};
					setFlowsTrendResponseData(cachedFlows);


					const chartTitle = appliedFilterTimestamp()?.length > 0 ? getChartTitle(
						data?.id,
						min,
						max,

					) : max ? getChartTitle(
						data?.id,
						min,
						max

					) : getChartTitle(
						data?.id,
						min
					);
					setFlowsChartDetails({
						chartTitle, itemData: {
							queryData: `asset_name:${encodedIdentityName}`
						}
					});


				})
				.catch((er) => {
					console.log(er);
				});
		} catch (error) {
			if (axios.isCancel(error)) {
				console.log('Previous request canceled.');
			} else {
				console.log(error);
			}
		}
	}

	const handleFlowClick = (event: any, data: any) => {
		setOpenFlow(true);
		setZoomLevel('hourly');
		updatedExtremesRef.current = { min: null, max: null };
		let { id: identityName, asset_type } = data;
		const currentTime = +new Date();
		const cachedFlowData: any = sessionStorage.getItem(
			`assets-flow-trend-${identityName}-${asset_type}`
		);

		const encodedIdentityName = encodeURIComponent(identityName);
		const encodedAssetType = encodeURIComponent(asset_type);

		sessionStorage.setItem('flowDataAsset', JSON.stringify(data));

		const flowTrendURL = `/assets/flowcounts?search_type=asset&search_value1=${encodedIdentityName}&asset_type=${encodedAssetType}`;


		Api.get(flowTrendURL)
			.then((res: any) => {
				if (!res?.data?.length || res?.data?.length <= 0) {
					setFlowsTrendResponseData({
						flows: [],
						expiry_time: currentTime + 600000
					});

					// Chart header and color
					const chartTitle = getChartTitle(identityName);
					setFlowsChartDetails({
						chartTitle, itemData: {
							queryData: `asset_name:` + encodedIdentityName
						}
					});

					return;
				}

				let result = JSON.parse(JSON.stringify(res?.data)) || [];
				result.sort((a: any, b: any) =>
					a?.time < b?.time ? -1 : 1
				);
				const cachedFlows = {
					flows: result,
					identityName,
					risk,
					expiry_time: currentTime + 600000
				};
				setFlowsTrendResponseData(cachedFlows);

				// Chart header and color
				const startFromDate = Math.max(
					moment(result?.[0]?.time).valueOf(),
					moment().subtract(90, 'days').valueOf()
				);


				const chartTitle = appliedFilterTimestamp()?.length > 0 ? getChartTitle(
					identityName,
					appliedFilterTimestamp()[0],
					appliedFilterTimestamp()[1],

				) : getChartTitle(
					identityName,
					startFromDate
				);
				setFlowsChartDetails({
					chartTitle, itemData: {
						queryData: `asset_name:` + identityName
					}
				});

				sessionStorage.setItem(
					`assets-flow-trend-${identityName}-${asset_type}`,
					JSON.stringify(cachedFlows)
				);
			})
			.catch((er) => {
				console.log(er);
			});
	};

	useEffect(() => {
		if (!openFlow) {
			setFlowsTrendResponseData(undefined);
		}
	}, [openFlow]);

	const handleIssueFilterClick = (idx: any, type: string): void => {
		let hostDetails = responseData?.result.find(
			(item: AssetsObj) => item._id === idx
		);
		let currHostDetails = hostDetails.host_detail;
		let assetsRef = '';
		currHostDetails.map((item: any, index: any) => {
			if (index < 100) assetsRef += item.ip + '-' + item.port + ',';
		});
		const prevState = history?.location?.state as any;
		const prevUrl = history.location.pathname;
		const prevSearch = history.location.search;
		if (idx.includes(',')) {
			idx = `"${idx}"`;
		}

		const timeSearchParam = getTimeBasedSearchQueryParam(PostureSearchTemplate, tags);

		if (timeSearchParam === "") {
			history.push(
				'/issues?disable_filter=true&hard_refresh=true&order_by=desc&page=1&q=d_name:' +
				encodeURIComponent(idx) +
				`%2Brule_name^Shadow External Access` +
				'%2Bd_type:' +
				type +
				'%2Bstatus:Open&sort_by=gen_timestamp',
				{
					prevWidget: prevState?.breadcrumbId,
					breadcrumbId: 'AssetsIncidents',
					prevUrl,
					prevSearch
				}
			);
		} else {
			history.push(
				'/issues?disable_filter=true&hard_refresh=true&order_by=desc&page=1&q=d_name:' +
				encodeURIComponent(idx) +
				`%2Brule_name^Shadow External Access` +
				'%2Bd_type:' +
				type +
				'%2Bstatus:Open%2B' + encodeURIComponent(timeSearchParam) + '&sort_by=gen_timestamp',
				{
					prevWidget: prevState?.breadcrumbId,
					breadcrumbId: 'AssetsIncidents',
					prevUrl,
					prevSearch
				}
			);
		}
	};

	const handleIdAccess = (identitiesid: any, asset_type: string) => {
		// fetchIdentitiesMapping(identitiesid);
		const prevState = history?.location?.state as any;
		const prevUrl = history.location.pathname;
		const prevSearch = history.location.search;
		PostureSearchState.setIsAdSearchApplied(false);
		if (identitiesid.includes(',')) {
			identitiesid = `"${identitiesid}"`;
		}

		const timeSearchParam = getTimeBasedSearchQueryParam(PostureSearchTemplate, tags);

		if (timeSearchParam === "") {
			history.push(
				'/posture/accesses?page=1&q=asset_name%3A' +
				encodeURIComponent(identitiesid) +
				'%2Basset_type:' +
				asset_type +
				'&sort_by=flow_count&order_by=desc',
				{
					prevWidget: prevState?.breadcrumbId,
					breadcrumbId: 'PostureAccesses',
					prevUrl,
					prevSearch
				}
			);
		} else {
			history.push(
				'/posture/accesses?page=1&q=asset_name%3A' +
				encodeURIComponent(identitiesid) +
				'%2Basset_type:' +
				asset_type + "%2B" + encodeURIComponent(timeSearchParam) +
				'&sort_by=flow_count&order_by=desc',
				{
					prevWidget: prevState?.breadcrumbId,
					breadcrumbId: 'PostureAccesses',
					prevUrl,
					prevSearch
				}
			);
		}
	};

	useEffect(() => {
		ReactTooltip.rebuild();
	});

	const getExceptionData = (item: AssetsObj, rule_name: string) => {
		return [
			`d_name: ${item._id}`,
			`dst_hostnames: ` +
			item.host_detail?.map((item: any) => item.hostname).join(',') ||
			'',
			`dst_ips: ` +
			item.host_detail?.map((item: any) => item.ip).join(',') || '',
			`rule_name: ` + rule_name
		];
	};

	const loadBasePostureActionProps = (
		item: AssetsObj,
		rule_name: string,
		issueCount: number
	) => {
		const basePostureObject = {
			actionData: item,
			menuItems: loadColumnActionLabels(rule_name),
			getContent: () => [`d_name: ${item._id}`, `rule_name: ${rule_name}`],
			exceptionData: getExceptionData(item, rule_name),
			classes: 'posture-column action_button',
			incContent: () => [
				`inc_count: ${issueCount}`,
				`rule_name: ${rule_name}`,
				`d_name: ${item._id}`
			],
			closeIncQuery: `{ "is_external": "false", "d_name": "${item._id}", "status": "Open", "rule_name": "${rule_name}" }`,
			issueCount: issueCount,
			reloadList: fetchAssets,
			markKnownOptions: undefined,
			keyName: `asset - ${item._id}`,
			showId: rule_name,
			config: {
				isSaaSApp: rule_name == 'Unknown SaaS Access' ? true : false
			}
		} as any;

		if (rule_name == 'Exposed Assets') {
			try {
				basePostureObject.markKnownOptions = {
					options: ExposedAssetColumnMarkKnownOptions
				};
			} catch (exception: any) {
				console.log(exception);
			}
		} else if (rule_name == 'Unknown SaaS Access') {
			basePostureObject.markKnownOptions = {
				options: ExposedAssetColumnMarkKnownOptions,
				hidePopUp: true,
				defaultAction: 'known_app'
			};
		}

		return basePostureObject;
	};

	const getRules = (item: AssetsObj, actionType?: string) => {
		let rule = '';
		Object.values(item.rules).map((i: RulesDetailObj) => {
			const isIssue = isIssueType(items, i.name);
			if (isIssue) {
				if (actionType == 'exception') {
					if (i.issue_count > 0 && i.entity_type === 'Asset') {
						rule += i.name + ',';
					}
				} else {
					if (i.issue_count > 0 && i.entity_type === 'Asset') {
						rule += i.name + ',';
					}
				}
			}
		});
		return rule.substring(0, rule.length - 1);
	};

	const getIssueCount = (item: AssetsObj) => {
		return item.rules
			? Object.values(item.rules).reduce(
				(prev: RulesDetailObj, i: RulesDetailObj) => ({
					...prev,
					issue_count: prev.issue_count + i.issue_count
				}),
				{
					issue_count: 0,
					issue_flow_count: 0,
					issue_risk: 4,
					name: ''
				}
			).issue_count
			: 0;
	};

	const loadColumnActionLabels = (rule_name: string) => {
		const IDCopy = JSON.parse(
			JSON.stringify(AssetsActionColumnMenu)
		) as any;
		const exceptionAction = IDCopy.find(
			(item: PostureMenuItem) =>
				item.action == PostureMenuItemAction.ADD_EXCEPTION
		);
		if (exceptionAction) {
			switch (rule_name) {
				case 'Suspicious Inbound Access':
					exceptionAction.label = `Ignore all suspicious inbound accesses to this asset(Add Exception)`;
					break;
				case 'Exposed Assets':
					exceptionAction.label = `Ignore all exposed asset issues to this asset(Add Exception)`;
					IDCopy.splice(1, 0, {
						id: 'mark-known-asset',
						action: PostureMenuItemAction.MARK_KNOWN,
						label: 'Mark asset as known to be exposed…'
					});
					break;
				case 'Shadow Assets':
					exceptionAction.label = `Authorize - this asset can be unmanaged / shadow(Add Exception)`;
					break;
				case 'Shadow Access':
					exceptionAction.label = `Authorize - this asset can have unmanaged / shadow accesses(Add Exception)`;
					break;
				case 'Lack of MFA':
					exceptionAction.label = `Authorize - this asset can lack MFA(Add Exception)`;
					break;
				case 'Auth Protocol Quality':
					exceptionAction.label = `Ignore protocol quality issues for this asset(Add Exception)`;
					break;
				case 'Unknown SaaS Access':
					exceptionAction.label = `Ignore Unknown SaaS issues for this asset(Add Exception)`;
					IDCopy.splice(1, 0, {
						id: 'mark-known-asset',
						action: PostureMenuItemAction.MARK_KNOWN,
						label: 'Mark this asset as a Known (Corporate) SaaS App'
					});
					break;
			}
		}

		const closeIncident = IDCopy.find(
			(item: PostureMenuItem) =>
				item.action == PostureMenuItemAction.CLOSE_INCIDENT
		);
		if (closeIncident) {
			const ruleLabel = getIssueNameById(findNameById(items, rule_name))
			closeIncident.label = `Close existing '${ruleLabel}' issues for this asset`;
		}

		return IDCopy;
	};

	const handleAddtionalResultColumn = (val) => {
		setShowAdditionalResult(val);
		if (additionalResultHighlightClass === "")
			setAdditionalResultHighlightClass("glowing-animation");
	}

	useEffect(() => {
		if (additionalResultHighlightClass !== "" && showAdditionalResult) {
			setTimeout(() => {
				setAdditionalResultHighlightClass("");
			}, 15000)
		}
	}, [additionalResultHighlightClass])

	useEffect(() => {
		let filterObj = getSearchFilterObjectFromTags(PostureSearchTemplate, tags);
		if (filterObj[TIME_BASED_SEARCH_FIELD]) {
			setIsTimeBasedSearchApplied(true);
		} else {
			setIsTimeBasedSearchApplied(false);
		}
	}, [tags])


	const gridRef = useRef(null);
	const containerRef = useRef(null)
	const { refreshGrid } = useBaseAMGrid(gridRef);
	const [assetCols, setAssetCols] = useState(null)
	const { getAdditionalFields } = useAdditionalResults({
		options: tags, filterTemplate: PostureSearchTemplate,
		notSupportedKeys: notSupportedKeys,
		pageType: ENTITIES.ASSETS.pageType,
		columnDefs: assetCols, setColumnDefs: setAssetCols, handleAddtionalResultColumn
	})

	const updateAssetCols = (): ColDef[] => {
		if (!issueHeader || !issueHeader?.length) {
			return []
		}
		return [
			{
				field: "_id",
				width: getResolutionMatchFor1920(565, 370),
				headerName: "Asset Name",
				sortField: "_id",
				pinned: "left",
				suppressMenu: true,
				menuTabs: [],
				cellRendererParams: {
					fetchAssets, items
				},
				cellRenderer: AssetNameCell
			},
			{
				field: "type",
				headerName: "Type",
				width: getResolutionMatchFor1920(80, 80),
				minWidth: getResolutionMatchFor1920(80, 80),
				suppressMenu: true,
				menuTabs: [],
				pinned: "left",
				wrapHeaderText: true,
				cellRenderer: AssetTypeCell
			},
			{
				headerName: "Asset Issues",
				headerClass: 'issue-header-column ',
				width: getResolutionMatchFor1920(510, 490),
				headerGroupComponent: PostureIssueGroupHeader,
				headerGroupComponentParams: {
					gridRef, containerRef, type: 'assets'
				},
				wrapHeaderText: true,
				suppressMenu: true,
				menuTabs: [],
				children: issueHeader?.map((i) => {
					let rule_name = getCustomLabelForRuleName(i);
					rule_name = items ? findPbNameById(rule_name, items) : rule_name;
					const toolTipText = isIssueType(items, i) ? getIssueNameById(i) : getIssueNameById(rule_name);
					return {
						headerName: rule_name,
						headerComponent: PostureGridIssueCustomHeader,
						headerClass: 'issue-header-column-issue-list',
						width: getResolutionMatchFor1920(55, 55),
						minWidth: getResolutionMatchFor1920(55, 55),
						field: i,
						headerTooltip: toolTipText,
						cellClass: 'issue-data-column-value',
						wrapHeaderText: true,
						cellRenderer: ({ data }) => {
							const item = data;
							return item[i] ? (
								<ScrollIssueGridRenderer
									ruleName={i}
									ruleIssueData={item[i]}
									data={item.rules}
									handleRuleFilterClick={handleRuleFilterClick}
									loadBasePostureActionProps={loadBasePostureActionProps}
									issueList={issueHeader}
									postureObject={item}
									classes={{ containerClass: 'asset-page' }}
									selectedItems={items}
									isAdditionalResultColVisible={showAdditionalResult}
									tableWidth={"307px"}
									isTimeBasedSearchApplied={isTimeBasedSearchApplied}
								/>
							) : <></>;
						}
					};
				})
			},
			{
				field: "flow_count",
				headerName: "Overall Number of Flows",
				width: getResolutionMatchFor1920(100, 100),
				maxWidth: getResolutionMatchFor1920(200, 200),
				pinned: "right",
				suppressMenu: true,
				menuTabs: [],
				autoHeaderHeight: true,
				wrapHeaderText: true,
				autoHeight: true,
				headerClass: 'align_center ',
				cellClass: 'display_flex_center',
				cellRenderer: AssetFlowCountCell,
				cellRendererParams: {
					widgetData, setOpenFlow, setZoomLevel,
					setFlowsTrendResponseData, updatedExtremesRef, setFlowsChartDetails, risk
				}
			},
			{
				field: "issue_count",
				pinned: "right",
				headerName: "Overall Number of Incidents",
				width: getResolutionMatchFor1920(100, 100),
				minWidth: getResolutionMatchFor1920(100, 100),
				headerClass: 'align_center',
				suppressMenu: true,
				menuTabs: [],
				autoHeaderHeight: true,
				autoHeight: true,
				wrapHeaderText: true,
				cellRenderer: PostureIssueCountCell,
				cellRendererParams: {
					handleIssueFilterClick, responseData
				}
			},
			{
				field: "identity_count",
				pinned: "right",
				headerName: "Number of Identities Accessed",
				headerClass: 'text_center_line_height_normal align_center',
				width: getResolutionMatchFor1920(100, 100),
				minWidth: getResolutionMatchFor1920(100, 100),
				autoHeight: true,
				suppressMenu: true,
				menuTabs: [],
				wrapHeaderText: true,
				cellRenderer: AssetIdentityCountCell,
				cellRendererParams: {
					handleIdAccess
				}
			},
			...getAdditionalFields(),
			{
				field: "latest_time",
				pinned: "right",
				suppressMenu: true,
				menuTabs: [],
				headerClass: 'posture-header-column-cls',
				width: getResolutionMatchFor1920(110, 110),
				cellStyle: { whiteSpace: 'normal', height: 50 },
				cellClass: 'text_center_line_height_normal padding-top-15',
				wrapHeaderText: true,
				headerName: "Latest Activity",
				cellRenderer: LatestTimeCell
			},
			{
				field: "score",
				width: getResolutionMatchFor1920(90, 90),
				pinned: "right",
				suppressMenu: true,
				menuTabs: [],
				headerName: "Score",
				initialSort: "desc",
				cellRenderer: ({ data }) => <IDScore data={data} />
			}
		]
	}

	useEffect(() => {
		if (assetCols?.length > 0) {
			/* refreshGrid(); */
		}
	}, [assetCols])

	const getCheckboxParams = (item: AssetsObj) => {
		return {
			checked: getSelectRow(item),
		}
	}

	useEffect(() => {
		setAssetCols(updateAssetCols())
	}, [selectedItems, issueHeader])

	const handlePaginate = (selectedPage: any) => {
		param.page =
			selectedPage.selected === 0 ? 1 : selectedPage.selected + 1;
		setQuery(param);
	};

	const handleSearch = (e: React.KeyboardEvent<HTMLInputElement>) => {
		handleSearchFn(e, tags);
	};

	const handleLensSearch = () => {
		handleLensSearchFn(tags);
	};

	useEffect(() => {
		if ((tagFlag && tags.length >= 0) || isClearAll) {
			param.page = 1;
			param.q = tags.join('+');
			param.d_name = undefined;
			param.risk = undefined;
			param.d_protocol = undefined;
			param.hash = undefined;
			param.e_time = undefined;
			param.s_time = undefined;
			param.rule_name = undefined;
			if (param.q === '') {
				param.reportType = undefined;
				param.ruleType = undefined;
			}
			setQuery(param);
			setIsClearAll(false); // reset clearall status.
			setTagFlag(false);
		}
	}, [tags, tagFlag]);

	const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchInput(e.target.value);
	};

	const deleteTag = (index: number) => {
		const anyActivityTimeTag = tags.filter(item => item.indexOf(TIME_BASED_SEARCH_FIELD) > -1);
		let newTags = getTagsForSearchInput(tags).filter((tag, i) => i !== index)
		if (anyActivityTimeTag && anyActivityTimeTag.length > 0) {
			newTags = [...newTags, ...anyActivityTimeTag];
		}

		setTags(newTags);

		setTagFlag(true);
		PostureSearchState.setIsAdSearchApplied(false);
	};

	const [loading, setLoading] = useState(false);

	const handleCheck = () => {
		setCheckFlag((flag) => !flag);
	};

	const [openModal, setOpenModal] = useState<boolean>(false);

	const handleClose = () => {
		setOpenModal(false);
	}

	const openSettingModal = () => {
		setOpenModal(true);
	}

	const getPlayBookData = (updatedItems: any) => {
		setShowGraph(false);

		updatedItems = updatedItems.filter((item) => !(item.is_issue_type && item.name === 'Posture Issues'));
		setSelectedItems(updatedItems);

		// Create another array with _id property as value
		const newArray = updatedItems.map(item => item._id);
		// Sort the new array based on the order property value
		newArray.sort((a, b) => {
			const orderA = updatedItems.find(item => item._id === a).order;
			const orderB = updatedItems.find(item => item._id === b).order;
			return orderA - orderB;
		});
		setIssueHeaders(newArray);


		if (JSON.parse(localStorage.getItem('assetsRule'))) {

			const filteredData = JSON.parse(localStorage.getItem('assetsRule'))?.filter(item => newArray.includes(item.name));
			setRuleWidget(filteredData);

		}


	}

	useEffect(() => {
		if (ruleWidget) {
			setShowGraph(true);
		}
	}, [ruleWidget])


	const fetchPlayBookData = async () => {
		await Api.get("/pbviewplaybooks?type=asset", {
			timeout: 30000,
		})
			.then((res) => {
				//res.data = responseAdapter(res.data);
				setItems(res.data);
				setEditConfig(localStorage.getItem('canEdit') === 'false' ? false : true);
			})
			.catch((err) => {
				if (err.response?.status === 401) {
					window.location.href = "/login";
				}
			});
	};

	useEffect(() => {

		const fetchData = async () => {
			try {
				const a = await fetchPlayBookData();
				const b = await fetchWidget();
				//  const c =   await setTimeout(()=>{fetchUserSelection()},300);


			} catch (error) {
				console.error('Error fetching data:', error);
			}
		};

		fetchData();

	}, []);


	const fetchWidget = () => {
		setLoading(true);
		const queryParams = {
			...(hard_refresh_state && { hard_refresh: 'true' })
		};
		PostureSummary.GET(
			queryParams,
			(res: IApiResponse) => {
				if (res.status === 200) {
					setWidgetData(undefined);
					setLoading(false);
					setTimeout(() => setWidgetData(res.data));
					//setIssueHeaders(res.data.asset_rule_types);
					//Set ID widget
					setIdWidget(res.data.asset_summary);
					const rulesArray: any = [];
					//Set All rule root data
					rulesArray.push(
						...Object.values(res.data.asset_rules_summary.rules)
					);
					//Set Asset Widget Data
					// setRuleWidget(rulesArray);
					// setRuleWidgetCopy(rulesArray);
					localStorage.setItem('assetsRule', JSON.stringify(rulesArray));

					const rulesRoot: any = [];
					rulesRoot.push(
						...Object.values(
							res.data.asset_rules_summary.root_cause_detail
						)
					);
					//SetRuleRootCount Data
					setRuleRootIdentity(
						res.data.asset_rules_summary.root_cause_detail
					);
					scrollToIssueColumn();
				}
			},
			(err: any) => { }
		);
	};

	const handleGotoPage = () => {
		let pageValue = (
			document.getElementsByName('page_no')[0] as HTMLTextAreaElement
		).value;
		pageValue = pageValue !== '' ? pageValue : '1';
		let currentUrlParams = new URLSearchParams(window.location.search);
		currentUrlParams.set('page', pageValue);
		(
			document.getElementsByName('page_no')[0] as HTMLTextAreaElement
		).value = '';
		history.push(
			window.location.pathname + '?' + currentUrlParams.toString()
		);
		window.scroll({
			top: 0,
			left: 0,
			behavior: 'smooth'
		});
	};

	//Download CSV
	const headers = [
		{ label: 'Asset Name', key: '_id' },
		{ label: 'Asset Type', key: 'type' },
		{ label: 'Host 1', key: 'host_detail[0].hostname' },
		{ label: 'Host 2', key: 'host_detail[1].hostname' },
		{ label: 'Host 3', key: 'host_detail[2].hostname' },
		{ label: 'Host 4', key: 'host_detail[3].hostname' },
		{ label: 'Host 5', key: 'host_detail[4].hostname' },
		{ label: 'IP:Port 1', key: 'host_detail[0].ip_port' },
		{ label: 'IP:Port 2', key: 'host_detail[1].ip_port' },
		{ label: 'IP:Port 3', key: 'host_detail[2].ip_port' },
		{ label: 'IP:Port 4', key: 'host_detail[3].ip_port' },
		{ label: 'IP:Port 5', key: 'host_detail[4].ip_port' },
		{ label: '# Flows', key: 'flow_count' },
		// { label: "Overall # Issues Matched", key: "rules_count" },
		{ label: 'Overall # Incidents', key: 'issue_count' },
		{ label: '# Identites Accessed', key: 'identity_count' },
		{ label: 'Latest Activity', key: 'latest_time' },
		{ label: 'Score', key: 'score' }
	];

	const [downData, setDownData] = useState<string | Data>([]);
	const [totalDownloaded, setTotalDownloaded] = useState<number | undefined>(
		0
	);
	const [loadingCSV, setLoadingCSV] = useState({
		loading: false,
		setData: false
	});
	const currDownData: any = [];
	const csvLinkEl = useRef<any>();

	const getUserList = async (selectedPage?: any) => {
		param.page = selectedPage === 0 ? 1 : selectedPage + 1;
		param.rpp = 100;
		const searchHeaders = await getSearchHeaders(param)
		return Api.get('/assets?is_external_not_allowed=true', {
			params: { ...param, is_export_csv: true },
			paramsSerializer: (params) =>
				qs.stringify(params, { arrayFormat: 'repeat' }),
			...searchHeaders
		})
			.then((res) => {
				res.data = validateLocalIps(res.data, 'host_detail');
				currDownData.push(...formatCSVData(res));
				return currDownData;
			})
			.catch((error: any) => {
				setLoadingCSV({ loading: false, setData: false });
				if (error.response.status === 500 || error.response.status === 524) {
					addToast('Sorry, something went wrong there, try again.', {
						appearance: 'error',
						autoDismiss: true
					});
				} else if (error.response.status === 404) {
					addToast(
						'We are not able to find associated email, please check and try again.',
						{
							appearance: 'error',
							autoDismiss: true
						}
					);
				}
			});
	};

	const downloadCSV = async () => {
		setTotalDownloaded(0);
		setLoadingCSV({ loading: true, setData: false });
		let i = 0;
		do {
			const downData = await getUserList(i);
			setTotalDownloaded(i * 100);
			if (i > Math.floor(totalCount! / 100)) {
				setDownData(downData);
				setTimeout(() => {
					setLoadingCSV({ loading: false, setData: true });
					if (csvLinkEl && csvLinkEl?.current && csvLinkEl?.current?.link) {
						csvLinkEl?.current?.link?.click();
					}

				});
			}
			i = i + 1;
		} while (i <= Math.floor(totalCount! / 100) + 1);
	};

	useEffect(() => {
		if (issueHeader?.length > 0 && responseData?.result?.length) {
			setShowAnimation(true);
		}
	}, [responseData, issueHeader]);

	const scrollToIssueColumn = () => {
		scrollToIssueColumnFn(query, q, scrollIssueRef, 'assets');
	};

	useEffect(() => {
		updateColumnEffect(showAnimation, history, q, 'assets');
	}, [showAnimation]);

	const handleFilterWidget = (widgetState: any) => {
		if (filterData) {
			setOpenPanelFilterWidgets(widgetState);
		} else {
			addToast(
				'Unable to apply filters, please retry in few minutes or contact administrator.',
				{ appearance: 'error' }
			);
		}
	};

	const handleFilterDisabled = (widgetState: any) => {
		setDisabledFilter(widgetState);
		// reloadIssuesDisabled(widgetState);
	};

	const onCloseAdvanceSearch = useCallback(() => {
		setShowAdvanceSearch(false);
	}, []);

	const applySearchHandler = useCallback(
		(data: any, clearAll: boolean = false, deletedKeys = []) => {
			let tempFilterObj = getSearchFilterObjectFromTags(PostureSearchTemplate, tags);
			let filterObj = {};
			for (let i in tempFilterObj) {
				if (deletedKeys.indexOf(i) == -1)
					filterObj[i] = tempFilterObj[i];
			}

			filterObj = { ...filterObj, ...data }

			handleApplyAdvancedSearch(
				filterObj,
				clearAll,
				setIsClearAll,
				setTags,
				setTagFlag,
				ENTITIES.ASSETS.pageType
			);
			resetSelectionState();
		},
		[tags, q]
	);

	const [showOudatedData, setShowOutdatedData] = useState(false);

	const outDated = () => {
		let clearOutdatedDataInterval = null;
		setShowOutdatedData(false);

		let tempTags = getTagsForTimeBasedSearch(tags);

		tempTags = Object.keys(AVAILABLE_PRESETS).filter(item => {
			if (tempTags && tempTags.length > 0)
				return item === tempTags[0][1]
			return false;
		})
		if (tempTags && tempTags.length > 0) {
			if (clearOutdatedDataInterval)
				clearInterval(clearOutdatedDataInterval);
			clearOutdatedDataInterval = setTimeout(() => {
				if (isPresetPeriodOptionSelected()) {
					setShowOutdatedData(true);
				}
			}, 30000);
		}
		return () => {
			if (clearOutdatedDataInterval)
				clearInterval(clearOutdatedDataInterval);
		}
	}
	useEffect(() => {
		outDated();
	}, [tags])

	const applyTimeBasedSearchHandler = ((data, clear) => {
		let filterObj = getSearchFilterObjectFromTags(PostureSearchTemplate, tags);
		if (clear) {
			if (filterObj.hasOwnProperty(TIME_BASED_SEARCH_FIELD)) {
				delete filterObj[TIME_BASED_SEARCH_FIELD];
				clear = false;
			}
			clear = Object.keys(filterObj).length === 0 ? true : false;
		} else {
			filterObj = { ...filterObj, ...data }
		}
		handleApplyAdvancedSearch(
			filterObj,
			clear,
			setIsClearAll,
			setTags,
			setTagFlag,
			ENTITIES.IDENTITY.pageType
		);
	})

	useEffect(() => {
		handleManualSearch(tags, ENTITIES.ASSETS.pageType)
	}, [tags])


	const onAdvanceSearchClick = () => {
		setShowAdvanceSearch(true);
	}

	const handleTabClick = (to: string) => {
		if (PostureSearchState.isAdSearchApplied) {
			const tempTags = tags.map(item => encodeURIComponent(item));
			history.push(`/${to}?order_by=desc&sort_by=score&q=${tempTags.join('%2B')}`)
		} else {
			history.push(`/${to}?order_by=desc&sort_by=score`);
		}
	}

	useEffect(() => {
		if (PostureSearchState.isAdvancedSearchOpen) {
			setShowAdvanceSearch(true)
		}
	}, [])

	useEffect(() => {
		PostureSearchState.setIsAdvancedSearchOpen(showAdvanceSearch);
	}, [showAdvanceSearch])

	const matchingPlaybookFoundFn = () => {
		const newParams = { ...query };
		newParams.entity_type = ENTITIES.ASSETS.id;
		setQuery(newParams);
		matchingPlaybookFound(newParams, getSearchHeaders)
	}

	const onFieldSelect = (field, fields) => {
		setSelectedField(field);
		setAdditionalFields(fields);
	}

	return (
		<>
			<div className="clrBoth margintop10"></div>
			<div className='hide-table-border-top'></div>
			<div className="">
				<div className="flow_table_container">

					<div style={{ "display": "none" }} className="idassetSwitch">
						Hide Excluded Assets&nbsp; &nbsp;<SwitchButton onChange={handleCheck} checked={checkFlag} />
					</div>

					<div className={showAdditionalResult ? "posture_search_section posture_search_section_additional_col" : "posture_search_section posture_search_section_no_additional_col"}>
						<TimeBasedSearchContainer onApply={applyTimeBasedSearchHandler} filters={getTagsForTimeBasedSearch(tags)} apiUrl={'/posture/daily_flow_count?entity_type=asset'} pageType={'assets'}></TimeBasedSearchContainer>
						<PostureArchiveTooltip numberOfDays={widgetData?.posture_view_date_range} />
						<PostureActionButtons
							entity='assets' selectAll={selectAll}
							onArchiveClick={onArchive.bind(null, reloadAssets)}
							selectCount={selectCount}
							isOpenIncidents={isIncidentsOpen} onArchiveEstimate={onArchiveEstimate.bind(null)}
							ref={archiveBtnRef} isHidden={true} />
						{showAdvanceSearch && <PostureFilter
							filterTemplate={PostureSearchTemplate.filter(item => item.search_key !== TIME_BASED_SEARCH_FIELD)}
							notSupportedKeys={notSupportedKeys} filters={tags}
							isOpen={showAdvanceSearch} onApplyFilter={applySearchHandler}
							onClose={onCloseAdvanceSearch}
							disableCreatePlaybookBtn={isValidResultCountThreshold(responseData)}
							matchingPlaybookFoundFn={matchingPlaybookFoundFn}
							entityType={ENTITIES.ASSETS.id}
							query={query}
							showCreatePlaybookBtn={query && query['playbook_type'] === POSTURE_ISSUE.type ? false : true}
							preMetaDataHandler={preMetaDataHandler}
							isTimeBasedSearchApplied={isTimeBasedSearchApplied}
							apiUrl={'/posture/daily_flow_count?entity_type=asset'} pageType={'assets'}
							PartnerConfigState={PartnerConfigState}
						/>}
						{
							openPanelFilterWidgets ?
								<FilterWidget toggleWidget={handleFilterWidget}
									disabledState={handleFilterDisabled}
									updateAfterFilter={reloadAssets} issueFiltersData={filterData} /> : ""
						}
					</div>
					<div colSpan={13} className="" style={{ background: "#ffffff", color: "#4e5267", paddingRight: 0 }} >
						<div className='flex-space-between'>
							<div>
								<div className="reload_container">
									<div ref={reloadRef} className="reload_color_icon" onClick={reloadAssets}></div>
								</div>

								<div className="table_title">Last Updated: {lastUpdate}</div>
								{showOudatedData ? <Chip label="Data could be outdated, reload to update" color="error" className='outdated-data-msg' /> : <></>}
							</div>
							{/* <div className="search_white_icon">&nbsp;</div> */}
							{/* <input type="text" className="table_search clear_search" onKeyUp={handleSearch} placeholder="Searched item" title=""/> */}
							<div className='maxWidth550 display-flex'>
								<div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
									<div className={`search_container ${hasReportType ? 'active-report-type' : ''}`}>
										<div className="search_white_icon"
											style={{ cursor: 'pointer' }}
											onClick={handleLensSearch}
										>&nbsp;</div>&nbsp;
										<Tags tags={getTagsForSearchInput(tags)} deleteTag={deleteTag} />&nbsp;
										{/* {tags?.map((tag, index) => <div key={tag + index} title={tag.toString()} className="search_tag">{tag}&nbsp;<span className="search_close_btn" onClick={() => deleteTag(tag)}>X</span></div>)}&nbsp; */}
										<input
											className="search_input"
											value={searchInput}
											placeholder="Search..."
											onKeyDown={handleSearch}
											onChange={handleSearchChange}
										/>
										{getTagsForSearchInput(tags)?.length > 0 ? <span style={{ cursor: 'pointer' }} onClick={() => {
											setTagFlag(true);
											PostureSearchState.setIsAdSearchApplied(false)
											if (isTimeBasedSearchApplied) {
												const tempTags = tags.filter(item => item.indexOf(TIME_BASED_SEARCH_FIELD) > -1);
												setTags(tempTags);
											} else {
												setTags([])
											}
										}}>&nbsp;X</span> : null}
									</div>
								</div>
								<div className='issue-advance-search-btn-con display_flex_center'
									style={{ float: 'right' }}
								><button style={{ float: 'left' }} onClick={onAdvanceSearchClick} className={'button_styled issue-advance-search'} >Advanced Search</button>


									{editConfig && <Tooltip classes={{ tooltip: 'playbook-level-widget-tooltip' }} title="Configure View : Asset Issue">
										<div style={{ float: 'right' }}
											className=" gear-setting-icon setting-identity-icon"
											onClick={openSettingModal}>
										</div>
									</Tooltip>
									}
									{!editConfig && <Tooltip classes={{ tooltip: 'playbook-level-widget-tooltip' }} title={'You do not have permission to change this settings. Please contact your administrator for assistance.'}>
										<div className="gear-setting-icon setting-identity-icon" style={{ opacity: 0.5, cursor: 'default' }}>

										</div>
									</Tooltip>
									}
									<PlaybookTableActions actions={MoreActionsOptions} rowData={{}}
										isDisabled={selectCount == 0 || showAdvanceSearch || isTimeBasedSearchApplied}
									/>
								</div>

							</div>
						</div>
					</div>
					{/* <div id="tab1" className="tab-panel">
						<table id="table" className={showAdditionalResult ? "id_asset_tb sticky_table_top posture-table-width" : "id_asset_tb sticky_table_top"}>
							<thead>
								<tr>
								</tr>
								<tr>
									{
										authDetails?.permissions?.Posture?.manual_archive == 'readwrite' &&
										<th className={showAdditionalResult ? "posture-table-checkbox-column" : ''} style={{ minWidth: window.matchMedia("(min-width: 2560px)").matches ? 30 : 30, padding: '0px' }}>
											<AMMultiCheckbox
												indeterminate={selectAllIndeterminate}
												menuItems={PostureMultiSelectCheckboxItems}
												onClick={PostureMultiSelectCheckboxItems[0].callback}
												// checked={selectAll || false}
												checked={selectAll}
												// disabled={!openIssuesCount || openIssuesCount < 1}
												disabled={false}
											/>
										</th>
									}
									<th style={{ width: window.matchMedia("(min-width: 2560px)").matches ? 415 : 415 }} data-headerName="_id" className={(selectedColumn == '_id' ? currentSort : " tablesort_down")} onClick={handleSort}><span className='align_left float_left'>Asset Name</span></th>
									<th style={{ minWidth: window.matchMedia("(min-width: 2560px)").matches ? 50 : 50 }} data-headerName="type" className={(selectedColumn == 'type' ? currentSort : " tablesort_down")} onClick={handleSort}>Type</th>
									<th className="no_padding" style={{ width: window.matchMedia("(min-width: 2560px)").matches ? 50 : 50 }}>
										<ScrollIssueHeader issueList={issueHeader} title='Asset Issue' query={query} handleSort={handleSort} ref={scrollIssueRef} selectedItems={items} isAdditionalResultColVisible={showAdditionalResult} tableWidth={"307px"} />
									</th>
									<th style={{ width: window.matchMedia("(min-width: 2560px)").matches ? 58 : 58 }} data-headerName="flow_count" className={(selectedColumn == 'flow_count' ? currentSort : " tablesort_down")} onClick={handleSort}>Overall Number of Flows</th>
									{/* <th style={{ width: window.matchMedia("(min-width: 2560px)").matches ? 120 : 60 }} data-headerName="Overall Number of Issues Matched" className={rulesCountSort} onClick={handleSort}>Overall<br />Number<br />of<br />Issues<br />Matched</th> 
									<th style={{ width: window.matchMedia("(min-width: 2560px)").matches ? 60 : 60 }} data-headerName="issue_count" className={(selectedColumn == 'issue_count' ? currentSort : " tablesort_down")} onClick={handleSort}>Overall<br />Number<br />of<br />Incidents</th>
									<th style={{ width: window.matchMedia("(min-width: 2560px)").matches ? 56 : 56 }} data-headerName="identity_count" className={(selectedColumn == 'identity_count' ? currentSort : " tablesort_down")} onClick={handleSort}>Number<br />of<br />Identities<br />Accessed</th>
									{showAdditionalResult && (
										selectedField !== null ? (
											<th style={{ width: window.matchMedia("(min-width: 2560px)").matches ? 110 : 110 }} data-headerName={selectedField['search_key']} className={additionalResultHighlightClass + ' ' + (additionalFields.includes(selectedColumn) ? currentSort : " tablesort_down") + (additionalFields.length > 1 ? ' additional_result_sort_icon' : '')} onClick={handleSort}>
												<div className='additional-results-col'>
													<AdditionalResult options={tags} filterTemplate={PostureSearchTemplate} notSupportedKeys={notSupportedKeys} pageType={ENTITIES.ASSETS.pageType} onFieldSelect={onFieldSelect} handleAddtionalResultColumn={handleAddtionalResultColumn} />
												</div>
											</th>)
											: (
												<th className={additionalResultHighlightClass} style={{ width: window.matchMedia("(min-width: 2560px)").matches ? 110 : 110 }}>
													<div className='additional-results-col'>
														<AdditionalResult options={tags} filterTemplate={PostureSearchTemplate} notSupportedKeys={notSupportedKeys} pageType={ENTITIES.ASSETS.pageType} onFieldSelect={onFieldSelect} handleAddtionalResultColumn={handleAddtionalResultColumn} />
													</div>
												</th>
											)
									)
									}
									<th style={{ width: window.matchMedia("(min-width: 2560px)").matches ? 110 : 100 }} data-headerName="latest_time" className={(selectedColumn == 'latest_time' ? currentSort : " tablesort_down")} onClick={handleSort}>Latest Activity</th>
									<th style={{ width: window.matchMedia("(min-width: 2560px)").matches ? 55 : 55 }} data-headerName="score" className={(selectedColumn == 'score' ? currentSort : " tablesort_down")} onClick={handleSort}>Score</th>
								</tr>
							</thead>

							<tbody>
								{
									loading ?
										<tr>
											<td colSpan={13} style={{ textAlign: 'center' }}><div className='loader spinner'></div></td>
										</tr> :
										responseData?.result == 'error' || responseData?.result.length == 0 ?
											<tr>
												<td colSpan={13} style={{ textAlign: 'center' }}>{tableFetchError}</td>
											</tr>
											:
											responseData && responseData.result !== null && responseData.result.length > 0 ? renderData(responseData.result) :
												<tr>
													<td colSpan={13} style={{ textAlign: 'center' }}><div className='loader spinner'></div></td>
												</tr>
								}
							</tbody>

						</table>
						<div className="clrBoth"></div>
					</div> */}
					<BaseAMAdminGrid
						containerRef={containerRef}
						gridRef={gridRef}
						columnDefs={assetCols}
						fetchEntitiesFn={fetchAssets}
						query={query}
						setQuery={setQuery}
						classes={{ gridContainerClass: 'asset-base-am-grid' + (showAdditionalResult ? " assets-page-posture-grid-am-additional-col" : " assets-page-posture-grid-am-no-additional-col") }}
						onCurrentPageSelect={onCurrentPageClick}
						onSelectAllRow={onSelectAllClick}
						onSelectRow={onSetSelectRow}
						{...(authDetails?.permissions?.Posture?.manual_archive !== "readonly"
							? { showCheckbox: true } : null)}
						headerCheckBoxConfig={headerCheckboxConfig}
						getCheckboxParams={getCheckboxParams}
					/>
					<div className="download_section">
						<button type={('button')} onClick={downloadCSV} className={"width180 " + (loadingCSV.loading ? 'loader export_loader' : 'button_styled')}>Export CSV</button>

						{loadingCSV.loading ?
							<div className='loading_info'>
								Received <span ><b>{totalDownloaded}</b></span> of <span ><b>{totalCount}</b></span> Assets
							</div>
							: null}
						{PartnerConfigState?.PartnerShortProduct && <CSVLink
							headers={getHeaders(headers, issueHeader, 'Asset Issues')}
							// filename={`${PartnerConfigState?.PartnerShortProduct?.replaceAll(" ", "_")}_Assets.csv`}
							filename={getExportFileName(PartnerConfigState?.PartnerShortProduct, 'Assets')}
							data={downData!}
							ref={csvLinkEl}
						/>}
						<div style={{ marginLeft: '1rem', marginTop: '1rem' }}>
							<Link to={'/archivedata'} className='link-text font12'>Assets inactive for more than 60 days are archived here.</Link>
						</div>
					</div>
				</div>
			</div >
			{showRuleMatch ? <RuleMatch prevURL={prevUrl} data={ruleMatch} handleRuleMatchClose={() => setShowRuleMatch(false)} /> : null
			}
			{showIDAccess ? <IdAccess data={idAccess} prevURL={prevUrl} aname={asset_name} handleIDAccessClose={() => setShowIDAccess(false)} /> : null}
			{openFlow ? <FlowTrend data={flowsTrendResponseData} chartDetails={flowsChartDetails} closeFlowPopup={() => setOpenFlow(false)} onRangeChange={handleRangeChange} zoomLevel={zoomLevel} updatedExtremes={updatedExtremesRef} showZoom={true} /> : ''}
			<PlayBookView widId='asset' openModal={openModal} handleClose={handleClose} getData={getPlayBookData}></PlayBookView>

		</>
	)
}

export default React.memo(withQueryParams({
	q: StringParam,
	page: StringParam,
	rpp: NumberParam,
	sort_by: StringParam,
	order_by: StringParam,
	rule_name: StringParam,
	d_protocol: StringParam,
	risk: NumberParam,
	hash: StringParam,
	d_name: StringParam,
	s_time: NumberParam,
	e_time: NumberParam,
	hard_refresh: StringParam,
	persist_active_flg: StringParam,
	filters: withDefault(ArrayParam, []),
	entity_type: StringParam,
	playbook_type: StringParam
}, Assets));