import { RuleType } from "../../../playbooks/constants/Constants";

export const SuspectedPbList = [
    RuleType.SUSPECTED_ATT_DIS_ACC.toString(), RuleType.SUSPECTED_ATT_LOCK_ACC.toString(), RuleType.SUSPECTED_ATT_EXP_ACC.toString(),
    RuleType.SUSPECTED_ATT_LOCK_ACC.toString(), RuleType.SUSPECTED_DIR_BOT.toString(), RuleType.SUSPECTED_PASS_SPRAY.toString(),
    RuleType.ENUM_AD_ADMINS.toString(), RuleType.ENUM_AD_USERS.toString(), RuleType.UNAUTH_LOGIN_DEVICE.toString(),
    RuleType.UNAUTH_LOGIN_TIME.toString(), RuleType.NTLM_RELAY_ATTACK.toString(), RuleType.SUSPECTED_BRUTE_FORCE.toString() , RuleType.SUSPECTED_IDP_BRUTE_FORCE.toString(), 
    RuleType.SUSPECTED_AD_BRUTE_FORCE.toString()
]

export const dir_names = ["onelogin", "one login", "one-login", "okta", "ping", "pingone", "ping identity", "ping-identity",
    "workspace", "google workspace", "google-workspace", "azure", "azure ad", "azure-ad", "microsoft"]
export const getItemIconClass = (item) => {
    switch (item.source_type) {
        case "Device":
            return "device_icon";
        case "App":
            return "device_icon";
        case "Service":
            return "gear_icon_issue";
        case "Service/Application":
            return "service_application_account_nhi_icon";
        case "Service/Computer Account":
            return "service_computer_account_nhi_icon";
        case "Service/Key and Secret":
            return "service_key_secret__nhi_icon";
        case "Service/Service Account":
            return "service_service_account_nhi_icon";
        case "Service/Service Principal":
            return "service_service_principal_nhi_icon";
        case "Service/Token":
            return "service_service_token_nhi_icon";
        case "User/Agent":
            return "user_agent_icon";
        case "User/Background Browsing":
            return "user_browsing_icon";
        case "User":
            return "user_interactive_icon";
        default:
            // If identity_category_list exists, return 'settings_icon_cloud', otherwise 'settings_icon'
            return item.identity_category_list ? 'settings_icon_cloud' : 'settings_icon';
    }
}

export const getDestinationItemIconClass = (item) => {
    switch (item?.destination_type) {
        case "Device":
            return "device_icon";
        case "App":
            return "application_account_icon";
        case "Service":
            return item?.dst_cat_ids && item?.dst_cat_ids?.length > 0 || item?.is_dir_public ? "gear_icon_cloud" : "gear_icon_issue";
        case "Service/Application":
            return item?.dst_cat_ids && item?.dst_cat_ids?.length > 0 || item?.is_dir_public ? "cloud_application_account_icon" : "application_account_icon";
        case "Service/Computer Account":
            return item?.dst_cat_ids && item?.dst_cat_ids?.length > 0 || item?.is_dir_public ? "cloud_computer_account_icon" : "computer_account_icon";
        case "Service/Service Account":
            return item?.dst_cat_ids && item?.dst_cat_ids?.length > 0 || item?.is_dir_public ? "cloud_service_account_icon" : "service_account_icon";
        case "Service/Service Principal":
            return item?.dst_cat_ids && item?.dst_cat_ids?.length > 0 || item?.is_dir_public ? "cloud_service_principal_icon" : "principal_icon";
        case "Service/Key and Secret":
            return item?.dst_cat_ids && item?.dst_cat_ids?.length > 0 || item?.is_dir_public ? "cloud_key_secret__icon" : "key_secret__icon";
        case "Service/Token":
            return item?.dst_cat_ids && item?.dst_cat_ids?.length > 0 || item?.is_dir_public ? "cloud_service_token_icon" : "token_icon";

        default:
            // If identity_category_list exists, return 'settings_icon_cloud', otherwise 'settings_icon'
            return item.identity_category_list ? 'settings_icon_cloud' : 'settings_icon';
    }
}


export const getItemIconTitle = (item) => {
    switch (item?.source_type) {
        case "Device":
            return !item.is_known_identity ? "Unresolved  NHI-Device" : "NHI-Device";
        case "App":
            return !item.is_known_identity ? "Unresolved  NHI-App" : "NHI-App";
        case "Service":
            return !item.is_known_identity ? "Unresolved  NHI-Service" : "NHI-Service";
        case "Service/Application":
            return !item.is_known_identity ? "Unresolved NHI-Application" : "NHI-Application";
        case "Service/Computer Account":
            return !item.is_known_identity ? "Unresolved NHI-Service/Computer Account" : "NHI-Service/Computer Account";
        case "Service/Service Account":
            return !item.is_known_identity ? "Unresolved NHI-Service" : "NHI-Service";
        case "Service/Service Principal":
            return !item.is_known_identity ? "Unresolved NHI-Service/Service Principal" : "NHI-Service/Service Principal";
        case "Service/Key and Secret":
            return !item.is_known_identity ? "Unresolved NHI-Service/Key and Secret" : "NHI-Service/Key and Secret";
        case "Service/Token":
            return !item.is_known_identity ? "Unresolved NHI-Service/Token" : "NHI-Service/Token";
        case "User/Agent":
            return !item.is_known_identity ? "Unresolved User/Agent" : "User/Agent";
        case "User/Background Browsing":
            return !item.is_known_identity ? "Unresolved User/Background Browsing" : "User/Background Browsing";
        case "User":
            return !item.is_known_identity ? "Unresolved User" : "User";
        default:
            // If identity_category_list exists, return 'settings_icon_cloud', otherwise 'settings_icon'
            return 'N/A';
    }

}


export const getItemDestinationTitle = (item) => {
    switch (item?.destination_type) {
        case "Device":
            return "Device";
        case "App":
            return "App";
        case "Service":
            return "Service";
        case "Service/Application":
            return item?.dst_cat_ids && item?.dst_cat_ids?.length > 0 || item?.is_dir_public ? "Cloud Service/Application" : "Service/Application";
        case "Service/Computer Account":
            return item?.dst_cat_ids && item?.dst_cat_ids?.length > 0 || item?.is_dir_public ? "Cloud Service/Computer Account" : "Service/Computer Account";
        case "Service/Service Account":
            return item?.dst_cat_ids && item?.dst_cat_ids?.length > 0 || item?.is_dir_public ? "Cloud Service/Service Account" : "Service/Service Account";
        case "Service/Service Principal":
            return item?.dst_cat_ids && item?.dst_cat_ids?.length > 0 || item?.is_dir_public ? "Cloud Service/Service Principal" : "Service/Service Principal";
        case "Service/Key and Secret":
            return item?.dst_cat_ids && item.dst_cat_ids?.length > 0 ? "Cloud Service/Key and Secret" : "Service/Key and Secret";
        case "Service/Token":
            return item?.dst_cat_ids && item.dst_cat_ids?.length > 0 ? "Cloud Service/Token" : "Service/Token";
        case "User/Agent":
            return item?.dst_cat_ids && item?.dst_cat_ids?.length > 0 || item?.is_dir_public ? "Cloud User/Agent" : "User/Agent";
        case "User/Background Browsing":
            return item?.dst_cat_ids && item?.dst_cat_ids?.length > 0 || item?.is_dir_public ? "Cloud User/Background Browsing" : "User/Background Browsing";
        case "User":
            return item?.dst_cat_ids && item?.dst_cat_ids?.length > 0 || item?.is_dir_public ? "Cloud User" : "User";
        default:
            // If identity_category_list exists, return 'settings_icon_cloud', otherwise 'settings_icon'
            return 'N/A';
    }

}
