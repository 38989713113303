import { Box } from "@mui/material";
import React, { SyntheticEvent, useEffect, useState } from "react";
import AMTabs, { TabData } from "../../components/core/AMTab/AMTab";

import { useHistory, useLocation } from "react-router-dom";
import { NumberParam, StringParam, withQueryParams } from "use-query-params";
import { AMBreadcrumb } from "../../components/core/AMBreadcrump/AMBreadcrumb";
import { usePostureStateContext } from "../../store/PostureStateContextProvider";
import { arrayOfObjectDeepCompare, convertToCommaValue } from "../../utils/util-methods";
import './PostureTab.scss';
import Accesses from "./accesses/accesses";
import Assets from "./assets/assets";
import Directories from "./directories/directories";
import { PostureCharts } from "./ref/PostureCharts/PostureCharts";
import Identities from "./identities/identities";

const TabMap = {
    0: 'IDENTITY',
    1: 'ASSET',
    2: 'ACCESS',
    3: 'DIRECTORY'
}

const defaultTabs: TabData[] = [
    {
        label: "IDENTITIES",
        Component: <Identities />,
        route: "/posture/identities",
        selected: false,
        title: "Identities",
        customData: {
            searchKey: 'identities'
        }
    },
    {
        label: "ASSETS",
        Component: <Assets />,
        route: "/posture/assets",
        selected: false,
        title: "Assets",
        customData: {
            searchKey: 'assets'
        }
    },
    {
        label: "Accesses",
        Component: <Accesses />,
        route: "/posture/accesses",
        selected: false,
        title: "Accesses",
        customData: {
            searchKey: 'accesses'
        }
    },
    {
        label: "IDENTITY SYSTEMS",
        Component: <Directories />,
        route: "/posture/directory",
        selected: false,
        title: "Identity Systems",
        customData: {
            searchKey: 'directory'
        }
    },

];

const PostureTab: React.FC = ({ query, setQuery }: any) => {
    const [tabs, setTabs] = useState<TabData[]>(defaultTabs)
    const { totalCount, items, responseData, setWidgetData, setResponseData, setTotalCount, setRuleWidget } = usePostureStateContext().PostureChartState;
    const { activeTabs, isAdSearchApplied, tags } = usePostureStateContext().PostureSearchState;
    const [selectedIndex, setSelectedIndex] = useState(0);
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        let index = tabs.findIndex((i) => i.route == location.pathname);
        const label = defaultTabs[index].label + ` (${convertToCommaValue(totalCount)})`;
        const t = tabs.map((i, index) => {
            const activeTab = i?.customData?.searchKey || '';
            return {
                ...i,
                label: defaultTabs[index].label,
                title: !(activeTabs?.[activeTab]) ? `To enable this tab, please clear the search.` : i.title,
                isDisabled: !(activeTabs?.[activeTab])
            }
        })
        t[index].label = label
        if (!arrayOfObjectDeepCompare(t, tabs)) {
            setTabs(t);
        }
        if (index !== selectedIndex) {
            setSelectedIndex(index)
        }
    }, [totalCount, selectedIndex, isAdSearchApplied, responseData, tabs, location.pathname, activeTabs])

    const tabClick = (val: string, event?: SyntheticEvent<Element, Event> | undefined) => {
        setSelectedIndex(parseInt(val));
        setWidgetData(undefined)
        setRuleWidget(undefined)
        setTotalCount(0)
        setResponseData(undefined)
        handleTabClick(parseInt(val), event);
    }

    const handleTabClick = (index: number, event?: SyntheticEvent<Element, Event> | undefined) => {
        if (event) {
            event.preventDefault()
            event.stopPropagation();
        }

        const to = tabs?.[index]?.customData?.searchKey;
        const orderBy = query?.order_by || 'desc';
        const sortBy = 'score';

        if (isAdSearchApplied) {
            history.push(`/posture/${to}?order_by=${orderBy}&sort_by=${sortBy}&q=${tags.join('%2B')}`);
        } else {
            if (query?.q) {
                history.push(`/posture/${to}?order_by=${orderBy}&sort_by=${sortBy}&q=` + encodeURIComponent(query.q));
            } else {
                history.push(`/posture/${to}?order_by=${orderBy}&sort_by=${sortBy}`);
            }
        }
    }

    return (
        <>
            <PostureCharts entityType={TabMap[selectedIndex]} />
            <div className="clrBoth margintop10"></div>
            <AMBreadcrumb selectedItems={items} />
            <Box style={{ marginTop: '20px' }}>
                <AMTabs tabs={tabs} onTabClick={tabClick}
                    isCustomRedirect={true}
                    classes={{ boxClass: 'posture-am-tab-container' }}
                />
            </Box>
        </>
    );
};

export default React.memo(withQueryParams({
    q: StringParam,
    page: StringParam,
    rpp: NumberParam,
    sort_by: StringParam,
    order_by: StringParam,
    hard_refresh: StringParam,
}, PostureTab));