import React from "react";

const UnusualUserAccessView = ({ config: { unusual_user_params }, params }: any) => {
    if (params?.isPlaybookListing) {
        return <li><span>Create incidents for unusual user access if a minimum of {unusual_user_params?.deviationsCount} of the following criteria are met:
            {unusual_user_params?.geoLocationFlag && <> Unusual Geolocation (latitude, longitude)</>
                || unusual_user_params?.cityCountryFlag && <> Unusual City or Country</>
                || unusual_user_params?.deviceAccessFlag && <> Unusual Device</>
                || unusual_user_params?.osAccessFlag && <> Unusual Operating System</>
                || unusual_user_params?.browserAccessFlag && <> Unusual Browser</>
                || unusual_user_params?.iPCidrFlag && <> Unusual IP Address</>
                || unusual_user_params?.ispFlag && <> Unusual Internet Service Provider (ISP)</>
                || unusual_user_params?.appByUserFlag && <> Unfamiliar Applications Accessed</>
                || unusual_user_params?.hostnameAccessFlag && <> Unusual Hostnames </>
            }
        </span></li>
    } else {
        return <li>
            <span>Create incidents for unusual user access if a minimum of {unusual_user_params?.deviationsCount} of the following criteria are met:</span>
            <ul className='ml-1'>
                {
                    unusual_user_params.geoLocationFlag &&
                    <li>Unusual Geolocation (latitude, longitude)</li>}
                {
                    unusual_user_params?.cityCountryFlag &&
                    <li>Unusual City or Country</li>}
                {
                    unusual_user_params?.deviceAccessFlag &&
                    <li>Unusual Device</li>}
                {
                    unusual_user_params?.osAccessFlag &&
                    <li>Unusual Operating System</li>}
                {
                    unusual_user_params?.browserAccessFlag &&
                    <li>Unusual Browser</li>}
                {
                    unusual_user_params?.iPCidrFlag &&
                    <li>Unusual IP Address</li>}
                {
                    unusual_user_params?.ispFlag &&
                    <li>Unusual Internet Service Provider (ISP)</li>}
                {
                    unusual_user_params?.appByUserFlag &&
                    <li>Unfamiliar Applications Accessed</li>}
                {
                    unusual_user_params?.hostnameAccessFlag &&
                    <li>Unusual Hostnames </li>}
            </ul>
        </li>
    }
}
export default UnusualUserAccessView;
