import React, { ReactNode, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import SlidingPanel from "react-sliding-side-panel"
import Popup from "reactjs-popup"
import { AMGenericConfirmPopUp } from "../../components/core/AMGenericConfirmPopUp/AMGenericConfirmPopUp"
import { Api } from "../../components/Axios"
import { useToasts } from "../../components/core";

type IAMAdminRightPanel = {
    openPanel: boolean,
    panelTitle: string,
    handleOnClosed(): any,
    children: any
}

export const AMAdminRightPanel = ({ openPanel, panelTitle, handleOnClosed, children }: IAMAdminRightPanel) => {
    return (
        <SlidingPanel
            type={'right'}
            isOpen={openPanel}
            size={30}
            panelClassName="sidepane fix_width"
            onClosing={handleOnClosed}
        >
            <div className="pane_container">
                <div className="pane_heading">{panelTitle}</div>
                <div className="pane_close" onClick={handleOnClosed}></div>
                <div className="hr_ruler"></div>
                <div className="panel_body">
                    {children}
                </div>
            </div>
        </SlidingPanel>
    )
}

export type IAMAdminPopUpActions = {
    primary: {
        label: string,
        action(): any,
        className?: string
    },
    secondary: {
        label: string,
        action(): any,
        className?: string
    }
}

type IAdminPopUp = {
    popUpTitle: string,
    popUpButtons: IAMAdminPopUpActions,
    handleClose(): any,
    children: ReactNode,
    rootClass?: string,
    subTitle?: React.JSX.Element
}

export const AMAdminPopUp = ({ popUpTitle, popUpButtons, handleClose, children, rootClass, subTitle }: IAdminPopUp) => {
    return <Popup overlayStyle={{ zIndex: 15001, background: 'rgba(227, 242, 253, .6)' }}
        open={true}
        closeOnDocumentClick={false}
        modal={true}
        closeOnEscape={false}
        className={rootClass}
    >
        <div className={"modal"}>
            <div className={"close"} onClick={handleClose}></div>
            <div className={"header"}>
                <span className="">{popUpTitle}</span>
                {subTitle ? subTitle : <></>}
            </div>
            <div className={"content"}>
                <div className="">
                    {children}
                    <div className="clrBoth"></div>
                </div>
            </div>
            <div className="popup_footer fl">
                {popUpButtons?.secondary && popUpButtons?.secondary?.action && <div className={`policy_defualt_button ${popUpButtons?.secondary.className}`} onClick={popUpButtons?.secondary?.action}>{popUpButtons?.secondary?.label}</div>}
                {popUpButtons?.primary && popUpButtons?.primary?.action && <div className={`policy_save_button ${popUpButtons?.primary.className}`} onClick={popUpButtons?.primary?.action}>{popUpButtons?.primary?.label}</div>}
            </div>
        </div>
    </Popup>
}


type IAdminTableSearch = {
    query?: string;
}

export type IAMAdminSearchTableRef = {
    setSearchValue(q: string): any
}

type IAMAdminSearchTableProps = {
    query: string,
    searchTitle: string,
    searchPlaceHolder: string,
    onSearchValue(data: any): any,
    action?: {
        label: string,
        onClickHandler(e: any): any
    },
    searchSubHeader?: React.JSX.Element,
    classes?: {
        root?: string
    }
    showUpgrade ?: boolean
}

export const AMAdminSearchTable = React.forwardRef(({ query, searchTitle, searchPlaceHolder, onSearchValue, action, searchSubHeader, classes ,showUpgrade }: IAMAdminSearchTableProps, ref: any) => {
    React.useImperativeHandle(ref, () => ({
        setSearchValue(data: string) {
            return onSetSearchValue(data);
        }
    } as IAMAdminSearchTableRef));

    const { register, handleSubmit, setValue, getValues } = useForm<IAdminTableSearch>();

    const onSubmit = (data: any) => {
        onSearchValue(data);
    }

    const onSetSearchValue = (q: string) => {
        setValue('query', q);
    }
    const { addToast } = useToasts()

    const OPEN_INCIDENT_MESSAGE = <span>You are about to  upgrade all flow sensors.</span>;
    const [showConfirm, setShowConfirm] = useState(false);
  
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [userInput, setUserInput] = useState('');
    const [popUpButtons, setPopUpButtons] = useState({
          no: {
              id: 'no', label: 'No'
          },
          yes: {
              id: 'yes', label: 'Yes'
          }
      });
  
  
  
  
  const onUpgradeClick = ()=>{

        
        setLoading(true)
        Api.post('/sensors/syncallfs')
          .then((res: { data: any }) => {
            setLoading(false)
            //  AfterUserOperation('add')
            addToast("Sensors Upgradation started successfully.", {
              appearance: 'success',
              autoDismiss: true,
            })
          })
          .catch((error: any) => {
            setLoading(false)
            if (error.response.status === 500) {
              addToast("Sorry, something went wrong there, try again.", {
                appearance: 'error',
                autoDismiss: true,
              })
            } else if (error.response.status === 404) {
              addToast(error.response.data, {
                appearance: 'error',
                autoDismiss: true,
              })
            } else if (error.response.status === 400) {
              addToast(error.response.data, {
                appearance: 'error',
                autoDismiss: true,
              })
            }
          })
      }


  
  
  
  const handleOnArchiveProceed = () => {
    setShowConfirm(false)
    onUpgradeClick();
  }
  
  useEffect(() => {
    if (showConfirm ) {
            const btns = JSON.parse(JSON.stringify(popUpButtons));
         
                btns.yes.isDisabled = true;
           
            setPopUpButtons(btns);
       
    }
  }, [showConfirm])
  
  const getMessageJSX = () => {
    return (
        <>
            {<span className='font14 inlineBlock margintop10'>
                {OPEN_INCIDENT_MESSAGE}</span>}
            {<div className='font14  margintop20 marginbottom10'>
            This upgrade will take <strong>a few minutes</strong>. 
            <span>&nbsp;Are you sure you want to proceed? </span></div>}
            {  <div className='font14'>
                <br></br>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span style={{ width: '50%' }}>To confirm, please type "upgrade":</span>
                 
                    <input style={{ height: '2.5em', marginLeft: '-20%', width: '30%' }} type='text'
                        onChange={(e) => {
                            setUserInput(e.target.value);
                            const btns = JSON.parse(JSON.stringify(popUpButtons));
                            if (e?.target?.value && e?.target?.value?.toLowerCase() == 'upgrade') {
                                btns.yes.isDisabled = false;
                            } else {
                                btns.yes.isDisabled = true;
                            }
                            setPopUpButtons(btns);
                        }}
                    />
                </div>
            </div>}
        </>
    );
  }
  

    return <div className={"page_title_area " + (searchSubHeader ? ' auto-height ' : '') + classes?.root}>
        <form onSubmit={handleSubmit(onSubmit)}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <div className='font18'>{searchTitle}</div>
                <div className="search_container issue_page scrollbar-container marginleft20 admin-page-search-container">
                    <div className="search_white_icon flex_none" style={{ cursor: 'pointer' }}
                        onClick={handleSubmit(onSubmit)}
                    >&nbsp;</div>&nbsp;
                    <input
                        className="search_input flex_none admin-page"
                        placeholder={searchPlaceHolder}
                        ref={register}
                        name='query'
                        defaultValue={getValues('query') || query || ''}
                    />
                </div>
                <div className={"launch_button " + (action ? " admin_page_launch_btn" : ' hide-launch-button')}>
                    {
                        action ? <button type="button" className="button_main" onClick={action.onClickHandler}>{action.label}</button> :
                            <div className="launch_button button_main" style={{ background: 'transparent' }}></div>
                    }
                </div>
                <AMGenericConfirmPopUp title={'Confirm Upgrade'}
                    showConfirm={showConfirm}
                    buttons={popUpButtons} onCloseHandler={() => setShowConfirm(false)}
                    onProceedHandler={handleOnArchiveProceed}
                >{loading ? <div className='loader spinner'></div> : getMessageJSX()}
                </AMGenericConfirmPopUp>

         { showUpgrade &&

                        <div className="launch_button  admin_page_launch_btn float_right"><button onClick={()=>{setShowConfirm(true)}} type="button" className="button_main">
                        Upgrade All Flow Sensors
                        </button>
                        </div>
}
            </div>
            {searchSubHeader ? <>{searchSubHeader}</> : <></>}
        </form>
    </div>
})