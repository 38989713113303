import React from 'react';
import ModalPopup from '../../components/core/AMModalPopup/ModalPopup';

export const ExportWarning = ({ handleYes, handleNo, title }) => {
    return (
        <ModalPopup
            onCloseHandler={handleNo}

            title={title}
            isOpen={true}
            open={true}
            zIndexCustom={10001}
        >
            <div className='margintop10' style={{
                height: 50, fontSize: 14, display: 'flex', alignItems: 'center', gap: 10
            }}>
                <div className='excl_warning' style={{ backgroundSize: 20, height: 20, width: 20 }}></div>
                <div style={{}}>IMPORTANT: The file will remain unencrypted. It is recommended to protect it from unintended modifications.</div>
            </div>
            <div className='margintop10 popup-container-button-container dialog-button'>
                <button
                    style={{ display: 'inline-block', margin: 0, width: '20%' }}
                    onClick={handleYes}
                    type='button'
                    className={
                        'float_right button_styled marginbottom10imp margintop20'
                    }
                >
                    Ok
                </button>
            </div>
        </ModalPopup>
    );
};
