import { CustomCellRendererProps } from "@ag-grid-community/react";
import React from "react";
import { FunctionComponent } from "react";
import { findDifferenceInDays, getTimeDiffString, trimAfterSecondWord } from "../../../../utils/util-methods";
import { Api } from "../../../../components/Axios";
import moment from "moment";
import { useTimeBasedSearchActions } from "../../../../components/TimeBasedSearch/TimeBasedSearchActions";

interface AssetFlowCountCellRendererProps extends CustomCellRendererProps {
    widgetData, setOpenFlow, setZoomLevel, updatedExtremesRef,
    setFlowsTrendResponseData, setFlowsChartDetails, risk
}

export const AssetFlowCountCell: FunctionComponent<AssetFlowCountCellRendererProps> = ({ data, widgetData, setOpenFlow, setZoomLevel,
    setFlowsTrendResponseData, updatedExtremesRef, setFlowsChartDetails, risk }) => {
    const item = data;
    const { appliedFilterTimestamp } = useTimeBasedSearchActions();

    const getChartTitle = (identityName: string, startDate: any = null, endDate: any = null) => {
        let timeUnit = "Hourly";

        if (startDate) {
            if (endDate) {
                const totalDurationHours = moment(endDate).diff(moment(startDate), 'hours');

                if (totalDurationHours < 12) {
                    timeUnit = "Minute-wise";
                } else if (totalDurationHours < 48) {
                    timeUnit = "10-Minute-wise";
                }
                // else timeUnit remains "Hourly"
            }

            return (
                <span
                    title={`${timeUnit} flows trend (${getTimeDiffString(startDate, endDate)}) for Asset: ${identityName}`}
                >{`${timeUnit} flows trend (${getTimeDiffString(startDate, endDate)}) for Asset: ${trimAfterSecondWord(identityName, 35)}`}</span>
            );
        } else {
            return (
                <span
                    title={`Hourly flows trend for Asset: ${identityName}`}
                >{`Hourly flows trend for Asset: ${trimAfterSecondWord(identityName, 35)}`}</span>
            );
        }
    };

    const handleFlowClick = (event: any, data: any) => {
        setOpenFlow(true);
        setZoomLevel('hourly');
        updatedExtremesRef.current = { min: null, max: null };
        let { id: identityName, asset_type } = data;
        const currentTime = +new Date();
        const cachedFlowData: any = sessionStorage.getItem(
            `assets-flow-trend-${identityName}-${asset_type}`
        );

        const encodedIdentityName = encodeURIComponent(identityName);
        const encodedAssetType = encodeURIComponent(asset_type);

        sessionStorage.setItem('flowDataAsset', JSON.stringify(data));

        const flowTrendURL = `/assets/flowcounts?search_type=asset&search_value1=${encodedIdentityName}&asset_type=${encodedAssetType}`;
        Api.get(flowTrendURL)
            .then((res: any) => {
                if (!res?.data?.length || res?.data?.length <= 0) {
                    setFlowsTrendResponseData({
                        flows: [],
                        expiry_time: currentTime + 600000
                    });

                    // Chart header and color
                    const chartTitle = getChartTitle(identityName);
                    setFlowsChartDetails({
                        chartTitle, itemData: {
                            queryData: `asset_name:` + encodedIdentityName
                        }
                    });

                    return;
                }

                let result = JSON.parse(JSON.stringify(res?.data)) || [];
                result.sort((a: any, b: any) =>
                    a?.time < b?.time ? -1 : 1
                );
                const cachedFlows = {
                    flows: result,
                    identityName,
                    risk,
                    expiry_time: currentTime + 600000
                };
                setFlowsTrendResponseData(cachedFlows);

                // Chart header and color
                const startFromDate = Math.max(
                    moment(result?.[0]?.time).valueOf(),
                    moment().subtract(90, 'days').valueOf()
                );


                const chartTitle = appliedFilterTimestamp()?.length > 0 ? getChartTitle(
                    identityName,
                    appliedFilterTimestamp()[0],
                    appliedFilterTimestamp()[1],

                ) : getChartTitle(
                    identityName,
                    startFromDate
                );
                setFlowsChartDetails({
                    chartTitle, itemData: {
                        queryData: `asset_name:` + identityName
                    }
                });

                sessionStorage.setItem(
                    `assets-flow-trend-${identityName}-${asset_type}`,
                    JSON.stringify(cachedFlows)
                );
            })
            .catch((er) => {
                console.log(er);
            });
    };

    return <div
        style={{
            width: window.matchMedia('(min-width: 2560px)')
                .matches
                ? 60
                : 60
        }}
    >
        {item.flow_count === 0 ? (
            <div className="display_flex_center">1</div>
        ) : (
            (findDifferenceInDays(item.latest_time) <= (widgetData?.posture_view_date_range || 60)) ?
                <div
                    className={`${item.flow_count > 1 && 'shadowbox'
                        }`}
                    id={item._id.toString()}
                    onClick={(event) => {
                        if (item.flow_count > 1) {
                            handleFlowClick(event, {
                                id: item._id.toString(),
                                risk: undefined,
                                asset_type: item.type
                            });
                        }
                    }}
                >
                    {item.flow_count
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                </div> :
                    <div
                        className="display_flex_center"
                    id={item._id.toString()}
                >
                    {item.flow_count
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                </div>
        )}
    </div>
}