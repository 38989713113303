import './account-takeover.scss';
import { BaseProps } from '../../constants/types';
import { UnusualUserAccess } from '../../../../types/playbooks-config';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import React from 'react';
import AMMultiCheckbox from '../../../../components/core/AMMultiCheckbox/AMMultiCheckbox';
import CheckboxAutocomplete from '../../../../components/core/AMAutoComplete/AMAutoComplete';
import { AMToolTipAd } from '../../../../components/core/AMTooltipAd/AMTooltipAd';
import InfoIcon from '@mui/icons-material/Info';

const options = ['miles', 'km'].map((i) => ({ key: i, value: i }))
const DeviationOptions = Array.from({ length: 9 }, (_, i) => ({ key: i + 1, value: i + 1 }));


const UnusualUserAccessPl = ({ parentState, isSavedOnce, getState, defaultState,
    formType, ruleType }: BaseProps<UnusualUserAccess>) => {
    const [state, setState] = useState<UnusualUserAccess>(defaultState);
    const { register, errors, control, getValues, setValue, reset } = useForm<UnusualUserAccess>();

    useEffect(() => {
        getState(state)
    })

    useEffect(() => {
        setState(defaultState);
        reset(defaultState)
    }, [defaultState]);

    useEffect(() => {
        if (isSavedOnce) {
            setState(parentState);
            reset(parentState);
        }
    }, [isSavedOnce]);

    const handleFormChange = () => {
        const s = getValues() as UnusualUserAccess;
        s.unusual_user_params.deviationsCount = state.unusual_user_params.deviationsCount
        setState(s)
    }


    const getItemLabel = (
        option, options
    ) => {
        if (Array.isArray(option)) {
            return option[0]?.value ? option[0]?.value : "";
        } else if (typeof option == 'string') {
            return option
        } else {
            let label = option?.value ? option?.value : "";
            if (option?.key && options?.length) {
                const l = options.filter((i) => i?.key == option.key)[0]
                if (l?.value) {
                    return l.value
                }
            }
            return label;
        }
    };

    const onChange = (evt, config) => {
        setState({ unusual_user_params: { ...state.unusual_user_params, deviationsCount: config[0].key } })
    }

    return <>
        <form action="/" onChange={handleFormChange}>
            <div className={"account-take-over-container unusual-access-user-container " + (formType === 'view' ? ' disable-config-item' : '')}>
                <div className="account-take-over-flex-item"
                    style={{ marginTop: 25, marginBottom: 10 }}
                >
                    <label className='top15 label-flex'
                        style={{ fontWeight: 'bold', fontSize: 15 }}
                    ><span>Create incidents for unusual user access if a minimum of
                        </span>
                        <CheckboxAutocomplete
                            options={DeviationOptions}
                            value={{ key: state?.unusual_user_params?.deviationsCount, value: state?.unusual_user_params?.deviationsCount }}
                            onChange={onChange}
                            getItemLabel={getItemLabel}
                            multiple={false}
                            showSeparator={true}
                            classes={{
                                popper: 'unusual-deviationpb-item-popper',
                                root: 'unusual-deviationpb-item-root'
                            }}
                        />
                        <span>of the following criteria are met:</span></label>
                </div>
                <div className="account-take-over-flex-item">
                    <input type='checkbox'
                        checked={state?.unusual_user_params?.geoLocationFlag}
                        ref={register}
                        name='unusual_user_params.geoLocationFlag'
                    ></input>
                    <label className='top15 label-flex'>Access from unusual GeoLocation (Latitude, Longitude)</label>
                    <AMToolTipAd
                        title={"Detect access originating from a new or unusual geolocation (latitude & longitude)."}>
                        <InfoIcon style={{ fontSize: 14, color: '#d4d8e1', marginTop: -10, marginLeft: 2, transform: 'skewX(-10deg)' }} />
                    </AMToolTipAd>
                </div>
                {/* <div className='and-text-at'>AND</div> */}
                <div className="account-take-over-flex-item">
                    <input type='checkbox'
                        checked={state?.unusual_user_params?.cityCountryFlag}
                        name='unusual_user_params.cityCountryFlag' ref={register}></input>
                    <label className='top15 label-flex'>Unusual City or Country</label>
                    <AMToolTipAd
                        title={"Detect access from a new or unusual city and/or country."}>
                        <InfoIcon style={{ fontSize: 14, color: '#d4d8e1', marginTop: -10, marginLeft: 2, transform: 'skewX(-10deg)' }} />
                    </AMToolTipAd>
                </div>
                {/* <div className='and-text-at'>AND</div> */}
                <div className="account-take-over-flex-item">
                    <input type='checkbox' name='unusual_user_params.iPCidrFlag'
                        checked={state?.unusual_user_params?.iPCidrFlag}
                        ref={register}></input>
                    <label className='top6'>Unusual IP Address</label>
                    <AMToolTipAd
                        title={"Detect access originating from a new or unusual IP address."}>
                        <InfoIcon style={{ fontSize: 14, color: '#d4d8e1', marginTop: -10, marginLeft: 2, transform: 'skewX(-10deg)' }} />
                    </AMToolTipAd>
                </div>
                {/* <div className='and-text-at'>AND</div> */}
                <div className="account-take-over-flex-item">
                    <input type='checkbox' name='unusual_user_params.ispFlag' ref={register}
                        checked={state?.unusual_user_params?.ispFlag}
                    ></input>
                    <label className='top6'>Unusual Internet Service Provider (ISP)</label>
                    <AMToolTipAd
                        title={"Detect access from a new or unusual Internet Service Provider (ISP)."}>
                        <InfoIcon style={{ fontSize: 14, color: '#d4d8e1', marginTop: -10, marginLeft: 2, transform: 'skewX(-10deg)' }} />
                    </AMToolTipAd>
                </div>
                {/* <div className='and-text-at'>AND</div> */}
                <div className="account-take-over-flex-item">
                    <input type='checkbox' name='unusual_user_params.hostnameAccessFlag' ref={register}
                        checked={state?.unusual_user_params?.hostnameAccessFlag}
                    ></input>
                    <label className='top6'>Unusual Hostnames</label>
                    <AMToolTipAd
                        title={"Detect access originating from a new or unusual Hostname."}>
                        <InfoIcon style={{ fontSize: 14, color: '#d4d8e1', marginTop: -10, marginLeft: 2, transform: 'skewX(-10deg)' }} />
                    </AMToolTipAd>
                </div>
                {/* <div className='and-text-at'>AND</div> */}
                <div className="account-take-over-flex-item">
                    <input type='checkbox' name='unusual_user_params.appByUserFlag' ref={register}
                        checked={state?.unusual_user_params?.appByUserFlag}
                    ></input>
                    <label className='top6'>Unfamiliar Applications Accessed</label>
                    <AMToolTipAd
                        title={"Detect access to a new or unusual Application."}>
                        <InfoIcon style={{ fontSize: 14, color: '#d4d8e1', marginTop: -10, marginLeft: 2, transform: 'skewX(-10deg)' }} />
                    </AMToolTipAd>
                </div>
                {/* <div className='and-text-at'>AND</div> */}
                <div className="account-take-over-flex-item">
                    <input type='checkbox' name='unusual_user_params.deviceAccessFlag' ref={register}
                        checked={state?.unusual_user_params?.deviceAccessFlag}
                    ></input>
                    <label className='top6'>Unusual Device</label>
                    <AMToolTipAd
                        classes={{ tooltip: 'deviation-tooltip-pb' }}
                        title={<span>Detect access from a new or unusual device (device brand and model). <br /><br />Note: This field might not be available in all the events/flows. If selected in combination with other parameters, it can result in skipping the creation of the incident.</span>}>
                        <InfoIcon style={{ fontSize: 14, color: '#d4d8e1', marginTop: -10, marginLeft: 2, transform: 'skewX(-10deg)' }} />
                    </AMToolTipAd>
                </div>
                {/* <div className='and-text-at'>AND</div> */}
                <div className="account-take-over-flex-item">
                    <input type='checkbox' name='unusual_user_params.osAccessFlag' ref={register}
                        checked={state?.unusual_user_params?.osAccessFlag}
                    ></input>
                    <label className='top6'>Unusual Operating System</label>
                    <AMToolTipAd
                        classes={{ tooltip: 'deviation-tooltip-pb' }}
                        title={<span>Detect access from a new or unusual operating system (OS name and version). <br></br><br />Note: This field might not be available in all the events/flows. If selected in combination with other parameters, it can result in skipping the creation of the incident.</span>}>
                        <InfoIcon style={{ fontSize: 14, color: '#d4d8e1', marginTop: -10, marginLeft: 2, transform: 'skewX(-10deg)' }} />
                    </AMToolTipAd>
                </div>
                {/* <div className='and-text-at'>AND</div> */}
                <div className="account-take-over-flex-item">
                    <input type='checkbox' name='unusual_user_params.browserAccessFlag' ref={register}
                        checked={state?.unusual_user_params?.browserAccessFlag}
                    ></input>
                    <label className='top6'>Unusual Browser</label>
                    <AMToolTipAd
                        classes={{ tooltip: 'deviation-tooltip-pb' }}
                        title={<span>Detect access from a new or unusual browser (browser name and version). <br /><br />Note: This field might not be available in all the events/flows. If selected in combination with other parameters, it can result in skipping the creation of the incident.</span>}>
                        <InfoIcon style={{ fontSize: 14, color: '#d4d8e1', marginTop: -10, marginLeft: 2, transform: 'skewX(-10deg)' }} />
                    </AMToolTipAd>
                </div>
            </div>
        </form>
    </>
}

export default UnusualUserAccessPl;
