import { CustomCellRendererProps } from "@ag-grid-community/react";
import { FunctionComponent } from "react";
import { findDifferenceInDays, getTimeDiffString, trimAfterSecondWord } from "../../../../utils/util-methods";
import React from "react";
import moment from "moment";
import { Api } from "../../../../components/Axios";
import { useTimeBasedSearchActions } from "../../../../components/TimeBasedSearch/TimeBasedSearchActions";

interface IdentityFlowCountCellRendererProps extends CustomCellRendererProps {
    widgetData, setOpenFlow, setZoomLevel, updatedExtremesRef, getSearchFields,
    setFlowsTrendResponseData, risk, setFlowsChartDetails
}
export const IdentityFlowCountCell: FunctionComponent<IdentityFlowCountCellRendererProps> = ({ data, node,
    widgetData, setOpenFlow, setZoomLevel, updatedExtremesRef, getSearchFields,
    setFlowsTrendResponseData, risk, setFlowsChartDetails
}: IdentityFlowCountCellRendererProps) => {

    const { appliedFilterTimestamp } = useTimeBasedSearchActions();

    const getChartTitle = (identityName: string, startDate: any = null, endDate: any = null) => {
        let timeUnit = "Hourly";

        if (startDate) {
            if (endDate) {
                const totalDurationHours = moment(endDate).diff(moment(startDate), 'hours');

                if (totalDurationHours < 12) {
                    timeUnit = "Minute-wise";
                } else if (totalDurationHours < 48) {
                    timeUnit = "10-Minute-wise";
                }
                // else timeUnit remains "Hourly"
            }

            return (
                <span
                    title={`${timeUnit} flows trend (${getTimeDiffString(startDate, endDate)}) for Identity: ${identityName}`}
                >{`${timeUnit} flows trend (${getTimeDiffString(startDate, endDate)}) for Identity: ${trimAfterSecondWord(identityName, 35)}`}</span>
            );
        } else {
            return (
                <span
                    title={`Hourly flows trend for Identity: ${identityName}`}
                >{`Hourly flows trend for Identity: ${trimAfterSecondWord(identityName, 35)}`}</span>
            );
        }
    };

    const handleFlowClick = (event: any, data: any) => {
        setOpenFlow(true);
        setZoomLevel('hourly');
        updatedExtremesRef.current = { min: null, max: null };
        let { id: identityName } = data;
        const currentTime = +new Date();
        const cachedFlowData: any = sessionStorage.getItem(
            `identities-flow-trend-${identityName}`
        );

        sessionStorage.setItem('flowData', JSON.stringify(data));

        const encodedIdentityName = encodeURIComponent(identityName);

        const flowTrendURL = `/assets/flowcounts?search_type=identity&search_value1=${encodedIdentityName}`;

        if (
            !cachedFlowData ||
            JSON.parse(cachedFlowData)?.expiry_time < currentTime
        ) {


            const searchHeaders = {
                headers: {
                    search_fields: getSearchFields()
                }
            };

            // searchHeaders.headers.search_fields = ;
            Api.get(flowTrendURL, searchHeaders)
                .then((res: any) => {
                    if (!res?.data?.length || res?.data?.length <= 0) {
                        setFlowsTrendResponseData({
                            flows: [],
                            expiry_time: currentTime + 600000
                        });

                        // Chart header and color
                        const chartTitle = getChartTitle(identityName);
                        setFlowsChartDetails({
                            chartTitle, itemData: {
                                queryData: `identity_name:${encodedIdentityName}`
                            }
                        });
                        return;
                    }

                    let result = JSON.parse(JSON.stringify(res?.data)) || [];
                    result.sort((a: any, b: any) =>
                        a?.time < b?.time ? -1 : 1
                    );
                    const cachedFlows = {
                        flows: result,
                        encodedIdentityName,
                        risk,
                        expiry_time: currentTime + 600000
                    };
                    setFlowsTrendResponseData(cachedFlows);

                    // Chart header and color
                    const startFromDate = Math.max(
                        moment(result?.[0]?.time).valueOf(),
                        moment().subtract(90, 'days').valueOf()
                    );



                    const chartTitle = appliedFilterTimestamp()?.length > 0 ? getChartTitle(
                        identityName,
                        appliedFilterTimestamp()[0],
                        appliedFilterTimestamp()[1],

                    ) : getChartTitle(
                        identityName,
                        startFromDate
                    );
                    setFlowsChartDetails({
                        chartTitle, itemData: {
                            queryData: `identity_name:${encodedIdentityName}`
                        }
                    });

                    sessionStorage.setItem(
                        `identities-flow-trend-${encodedIdentityName}`,
                        JSON.stringify(cachedFlows)
                    );
                })
                .catch((er) => {
                    console.log(er);
                });
        } else {
            const parsedData = JSON.parse(cachedFlowData);
            setFlowsTrendResponseData(parsedData);

            // Chart header and color
            const { flows } = parsedData;
            const startFromDate = Math.max(
                moment(flows?.[0]?.time).valueOf(),
                moment().subtract(90, 'days').valueOf()
            );
            const chartTitle = getChartTitle(identityName, startFromDate);
            setFlowsChartDetails({
                chartTitle, itemData: {
                    queryData: `identity_name:${encodedIdentityName}`
                }
            });
        }
    };


    const item = data;
    /* if (node.group) {
        return <span>{ }</span>; // Display group key for grouped rows
    } */
    return item.flow_count === 0 ?
        <div>1</div>
        : (findDifferenceInDays(item.latest_time) <= (widgetData?.posture_view_date_range || 60)) ?
            <div className={`${item.flow_count > 1 ? ' shadowbox ' : ' align_center'}`} id={item._id?.toString()}
                onClick={(event) => { if (item.flow_count > 1) { handleFlowClick(event, { id: item._id?.toString(), risk: undefined }) } }}>
                {item.flow_count?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </div>
            :
            <div style={{ textAlign: 'center' }} id={item._id?.toString()}>
                {item.flow_count?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </div>
}