import { CustomCellRendererProps } from "@ag-grid-community/react"
import React from "react"
import { FunctionComponent } from "react"

interface IdentitySystemDomainCellRendererProps extends CustomCellRendererProps {
}
export const IdentitySystemDomainCell: FunctionComponent<IdentitySystemDomainCellRendererProps> =
    ({ data }) => {
        const item = data;
        return <div className='copy_field flex_align_items_center'>
            <div
                className='ellipsis_name align_left'
                title={item?.domain?.toString()}
            >
                {item.domain ? item.domain : '<unknown>'}
            </div>
            <div
                className='copy_dir_domain'
                title='Copy this text to Clipboard'
                onClick={() =>
                    navigator.clipboard.writeText(
                        item.domain
                    )
                }
            ></div>
        </div>
    }