import { ColDef, GridReadyEvent, IRowNode, IServerSideDatasource, IServerSideGetRowsParams, LoadSuccessParams, PaginationChangedEvent, RowHeightParams, RowModelType, SortChangedEvent } from "@ag-grid-community/core";
import React, { useEffect, useMemo, useRef, useState } from "react";
import './BaseAMAdminGrid.scss';
import { BaseAMGrid } from "../AMGrid/AMBaseGrid";
import { Fade, Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import { ITableAction } from "./AMAdminTable";
import AMMultiCheckbox from "../AMMultiCheckbox/AMMultiCheckbox";
import { AMCheckbox } from "../AMCheckbox/AMCheckbox";
import { NoRowsOverlay } from "./NoRowsOverlay";
import ReactPaginate from "react-paginate";
import { usePartnerStateContext } from "../../../store/PartnerContextProvider";
import { generateTableError } from "./Messages";
import { Api } from "../../Axios";

export const getQueryParams = (): Record<string, string | null> => {
    const urlParams = new URLSearchParams(window.location.search);
    const params: Record<string, string | null> = {};
    urlParams.forEach((value, key) => {
        params[key] = value;
    });
    return params;
};


interface IBaseAMAdminGrid {
    columnDefs: Array<ColDef>;
    fetchEntitiesFn?: (params: Record<string, any>, gridParams: any, signal: AbortSignal) => void;
    pageSize?: number;
    actions?: Array<ITableAction<any>>;
    query?: Record<string, any>;
    setQuery?: (query: Record<string, any>) => void;
    gridRef?: React.RefObject<any>;
    getRowHeightFn?: (params: RowHeightParams) => number;
    pagination?: boolean;
    overlayNoRowsTemplate?: string;
    onBeforeActionCellRender?(data: any, node?): Array<ITableAction<any>>;
    rpp?: number, customActionCellRender?: (data: any, tableAction: Array<ITableAction<any>>) => void,
    modelType?: RowModelType, rowData?: any, context?, totalCount?,
    customGridReady?, classes?: {
        gridContainerClass
    }, onCurrentPageSelect?, onSelectAllRow?, onSelectRow?,
    containerRef?, showCheckbox?: boolean,
    headerCheckBoxConfig?: {
        indeterminate: boolean,
        menuItems?: () => void,
        onClick: (e) => void,
        checked: boolean,
        disabled: boolean,
    }, noRowsOverlayComponentParams?, noRowsOverlayComponent?,
    getCheckboxParams?: (data) => any,
    tableIndex?: number,
    enableErrorMsg?: boolean
}


const BaseAMAdminGrid = <T extends unknown>({ columnDefs, actions, fetchEntitiesFn,
    noRowsOverlayComponentParams, noRowsOverlayComponent,
    tableIndex = 0, pageSize = 20, rpp = 20, query, setQuery, gridRef, getRowHeightFn, customActionCellRender, customGridReady,
    pagination = true, overlayNoRowsTemplate = "", onBeforeActionCellRender, modelType = 'serverSide', rowData, context, classes,
    onCurrentPageSelect, onSelectAllRow, onSelectRow, showCheckbox = false, headerCheckBoxConfig, getCheckboxParams, containerRef, totalCount,
    enableErrorMsg = true
}: IBaseAMAdminGrid) => {
    const abortRef = useRef<any>(null);
    const [selectedRows, setSelectedRows] = useState([]);
    const [colDefinitions, setColDefinitions] = useState<Array<ColDef>>([]);
    const [totCount, setTotCount] = useState<number | undefined>(0);
    const inputGoToRef = useRef<HTMLInputElement>(null)
    const [tableError, setTableError] = useState('')
    const { PartnerConfigState } = usePartnerStateContext()

    const defaultColDef = useMemo<ColDef>(() => {
        return {
            flex: 1,
            minWidth: 80,
            resizable: false,
            autoHeaderHeight: true,
            suppressMenu: true
        };
    }, []);

    const getMainMenuItems = () => {
        const currentPage = {
            id: 'current-page',
            label: 'Current Page',
            callback: () => {
                selectCurrentPageRows()
            },
            icon: '<i class="fas fa-cogs"></i>',
        };

        const allPage = {
            label: 'All Pages',
            id: 'all-pages',
            callback: onSelectAll,
            icon: '<i class="fas fa-cogs"></i>',
        };
        return [currentPage, allPage];
    };

    const selectCurrentPageRows = () => {
        onCurrentPageSelect()
    };
    const onSelectAll = (e?) => {
        onSelectAllRow();
    };

    const onGridReadyFn = (params: GridReadyEvent<T>) => {
        if (modelType === 'serverSide') {
            params.api.setGridOption('serverSideDatasource', createServerSideDatasource())
        }

        params.api.addEventListener('paginationChanged', () => {
            const allRowNodes = params.api.getRenderedNodes();
            allRowNodes?.forEach((node: IRowNode) => {
                if (node?.data && selectedRows.filter((row: any) => row._id === node.data._id).length) {
                    node.setSelected(true);
                }
            });
        });

        if (customGridReady) {
            customGridReady(params)
        }
    }

    const getRowHeightCallback = (params: RowHeightParams) => {
        if (getRowHeightFn) {
            return getRowHeightFn(params);
        }
        return 40;
    }

    useEffect(() => {
        if (query) {
            if (query?.hasOwnProperty('q') && (((!query?.hasOwnProperty('tableIndex')) ||
                (query?.hasOwnProperty('tableIndex') && query?.tableIndex === tableIndex)))) {
                gridRef?.current?.api?.onFilterChanged()
            }
        }
    }, [query?.q, query?.tableIndex])

    const onSelectRowClicked = (params) => {
        onSelectRow(params.node.data, params.node.isSelected())
    }

    useEffect(() => {
        /* console.log("Col Defs Updated ", colDefinitions) */
    }, [colDefinitions])

    useEffect(() => {
        if (!columnDefs) return
        const newColDefs = [...columnDefs]
        if (actions?.length > 0) {
            const actionCol: ColDef = {
                field: '',
                headerName: 'Action',
                sortable: false,
                width: 60,
                maxWidth: 100,
                headerClass: 'base-am-admin-grid-action-header',
                cellClass: 'base-am-admin-grid-action-cell',
                pinned: "right",
                cellRenderer: ({ data, node }) => {
                    const item = data;
                    let tableActions: ITableAction<any>[] = actions;
                    if (onBeforeActionCellRender) {
                        tableActions = onBeforeActionCellRender(data, node)
                    }
                    let render;
                    if (customActionCellRender) {
                        render = customActionCellRender(data, tableActions)
                    }

                    return render || <Tooltip
                        TransitionComponent={Fade}
                        TransitionProps={{ timeout: 0 }}
                        classes={{ tooltip: 'am-admin-action-content' }}
                        style={{ display: 'block' }}
                        PopperProps={{ style: { zIndex: 10000 } }}
                        title={<div >
                            <ul>
                                {
                                    tableActions?.filter(action => action.shouldDisplay ? action.shouldDisplay(item) : true)
                                        .map((i: ITableAction<T>, ind: number) => {
                                            return <li key={`${i.actionId}-${ind}`}
                                                className={i.class || ''}
                                                onClick={(e: React.MouseEvent<HTMLElement>) => {
                                                    if (i.actionCallback)
                                                        i?.actionCallback(item, e)
                                                }}>
                                                <Link to="#" onClick={(e) => e.preventDefault()}>{i.actionLabel}</Link>
                                            </li>
                                        })

                                }
                            </ul>
                        </div>}>
                        <button className="action_button"></button>
                    </Tooltip>
                }
            }
            newColDefs.push(actionCol)
        }
        if (showCheckbox) {
            if (!headerCheckBoxConfig) {
                console.error("Header checkbox config not provided")
            } else {
                const HeaderCheckbox: ColDef = {
                    headerCheckboxSelection: false,
                    /* checkboxSelection: true, */
                    width: 50,
                    minWidth: 50,
                    maxWidth: 50,
                    pinned: "left",
                    field: "",
                    headerName: " ",
                    headerClass: 'admin-grid-checkbox-cell',
                    headerCheckboxSelectionFilteredOnly: false,
                    suppressMenu: true,
                    cellClass: 'admin-grid-checkbox-cell-data',
                    headerComponent: AMMultiCheckbox,
                    headerComponentParams: {
                        menuItems: headerCheckBoxConfig?.menuItems || getMainMenuItems(),
                        onClick: selectCurrentPageRows,
                        indeterminate: headerCheckBoxConfig.indeterminate,
                        checked: headerCheckBoxConfig.checked,
                        disabled: headerCheckBoxConfig.disabled
                    },
                    cellRenderer: ({ data }) => {
                        let disabled = false, checked = false;
                        if (getCheckboxParams) {
                            const output = getCheckboxParams(data);
                            disabled = output.disabled;
                            checked = output.checked;
                        }
                        return <AMCheckbox disabled={disabled} checked={checked} onClick={(e) => {
                            onSelectRowClicked({ node: { data, isSelected: () => false } })
                        }} />
                    },
                }
                newColDefs.unshift(HeaderCheckbox)
            }
        }
        if (newColDefs?.length > 1) {
            const colDefsMap = preUpdateCols(newColDefs)
            setColDefinitions(colDefsMap)
        }

    }, [columnDefs, showCheckbox, headerCheckBoxConfig])

    const preUpdateCols = (newColDefs) => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const sortBy = urlSearchParams.get("sort_by");
        const orderBy = urlSearchParams.get("order_by") || "asc";
        return newColDefs.map((item) => {
            if (!item?.sortable || !item?.sortable === false) {
                item.unSortIcon = true
            }
            if (item?.children?.length > 0) {
                item?.children?.forEach((child) => {
                    if (sortBy === child.field) {
                        child.initialSort = orderBy
                    } else {
                        child.initialSort = null
                    }

                    if (!child?.sortable || !child?.sortable === false) {
                        child.unSortIcon = true
                    }
                })
            } else {
                if (sortBy === item.field) {
                    item.initialSort = orderBy
                } else {
                    item.initialSort = null
                }
            }
            return item
        })
    }


    Api.interceptors.response.use(function (response) {
        return response;
    }, function (error) {
        updateTableError()
        return Promise.reject(error);
    });


    const createServerSideDatasource: () => IServerSideDatasource = () => {
        return {
            getRows: (gridParams) => {
                if (fetchEntitiesFn) {
                    const newQueryParams: Record<string, string | number | null> = { ...getQueryParams() || {} };
                    const sortColumn = gridParams.request.sortModel[0];
                    newQueryParams.rpp = newQueryParams?.rpp || rpp
                    if (sortColumn) {
                        newQueryParams.sort_by = sortColumn.colId;
                        newQueryParams.order_by = sortColumn.sort || "desc";
                    }
                    /* newQueryParams.page = gridParams.api.paginationGetCurrentPage() + 1;*/
                    if (abortRef?.current) {
                        abortRef?.current.abort("Cancelling previous request")
                    }
                    const controller = new AbortController()
                    const originalSuccess = gridParams.success;

                    gridParams.success = (params: LoadSuccessParams) => {
                        if (enableErrorMsg) {
                            const container = document.getElementsByClassName('ag-body')[tableIndex || 0] as HTMLDivElement;
                            if (container.style.display === 'none') {
                                container.style.display = ''
                            }
                        }

                        originalSuccess(params);
                        updateTotalCount(params, gridParams)
                    };


                    const originalFail = gridParams.fail;

                    gridParams.fail = () => {
                        originalFail()
                        updateTableError(gridParams)
                    }

                    fetchEntitiesFn(newQueryParams, gridParams, controller.signal);
                    abortRef.current = controller;
                    updateSortedColumn(gridParams)
                }
            },
        };
    };

    const updateTotalCount = (params: LoadSuccessParams, gridParams: IServerSideGetRowsParams<any, any>) => {
        const { rowData: data, rowCount: count } = { ...params };
        setTotCount(count)
        setTimeout(() => {
            // Sometimes cols are getting updated even after no showing this overlay
            gridParams.api.hideOverlay();
            if (rowData?.length === 0 || count === 0) {
                setTableError(generateTableError("NO_RECS"))
                gridParams.api.showNoRowsOverlay();
            }
        }, 500)
    }

    const updateTableError = (gridParams?: IServerSideGetRowsParams<any, any>) => {
        setTimeout(() => {
            let api = gridParams?.api || gridRef?.current?.api
            if (api && (totCount == undefined || totCount < 1) && enableErrorMsg) {
                const container = document.getElementsByClassName('ag-body')[tableIndex || 0] as HTMLDivElement;
                container.style.display = 'none'
                setTableError(generateTableError("TABLE_ERR", PartnerConfigState?.PartnerShortProduct))
                api.hideOverlay();
                api.showNoRowsOverlay();
            }
        }, 600)

    }

    const onSortChanged = (params: SortChangedEvent<any, any>) => {
        const newParams = { ...query || {} };
        if (params?.columns?.[0]) {
            newParams.sort_by = params.columns[params.columns.length - 1]?.colId
            newParams.order_by = params.columns[params.columns.length - 1]?.sort || "desc"
        }
        updateSortedColumn(params);
    }

    const updateSortedColumn = (params) => {
        if (params?.api && query?.tableIndex === tableIndex) {
            const columnApi = params.api;
            columnApi.getAllGridColumns().forEach((colDef) => {
                const headerCell = document.querySelector(
                    `[col-id='${colDef.colId}']`);
                const headerCell2 = document.getElementById(`${colDef.colId}_sorter_column`)

                if (headerCell) {
                    if (colDef.sort) {
                        headerCell.classList.add("sorted-column");
                    } else {
                        headerCell.classList.remove("sorted-column");
                    }
                }

                if (headerCell2) {
                    if (colDef.sort) {
                        headerCell2.classList.add("sorted-column");
                    } else {
                        headerCell2.classList.remove("sorted-column");
                    }
                }
            });
        }
    }

    const onPaginationChanged = (event: PaginationChangedEvent<any, any>) => {
        if (event.api && event.newPage) {
            const newParams = { ...query || {} };
            const currentPage = event.api.paginationGetCurrentPage();
            newParams.page = currentPage + 1
        }
    }

    const onColumnHeaderClicked = (params) => { }

    const onFirstDataRendered = (params) => {
        getTotalRowCount()
    }

    const Paginate = (selectedPage: any) => {
        if (gridRef?.current) {
            gridRef?.current?.api?.paginationGoToPage(selectedPage.selected);
            if (setQuery) {
                setQuery({ ...query, page: selectedPage.selected + 1 })
            }
        }
    }


    const getTotalRowCount = () => {
        if (gridRef?.current) {
            const totalRowCount = gridRef?.current?.api?.paginationGetRowCount();
            setTotCount(totalRowCount)
        }
    }

    const handleGotoPage = () => {
        if (inputGoToRef?.current) {
            let selectedValue = inputGoToRef?.current.value;
            if (isNaN(parseInt(selectedValue)))
                return
            if (parseInt(selectedValue) < 1 || parseInt(selectedValue) > (Math.ceil((totCount || 0) / (rpp || 20))))
                return
            const selected = parseInt(selectedValue) - 1
            Paginate({ selected })
        }
    }


    return <div className={"admin-am-grid-root-container " + (classes?.gridContainerClass || '')}
        style={{ position: 'relative' }}
        ref={containerRef}>
        <BaseAMGrid<T>
            gridRef={gridRef}
            enableRangeSelection={true} // Allows selecting ranges for copy
            clipboardDeliminator="\t"  // Sets the delimiter for copied data
            columnDefs={colDefinitions}
            {...(rowData ? { rowData } : {})}
            suppressMovableColumns={true}
            /* getRowId={(data) => (data.data._id + Math.floor(Math.random() * 100000))} */
            onGridReady={onGridReadyFn}
            defaultColDef={defaultColDef}
            getRowHeight={getRowHeightCallback}
            pagination={pagination}
            paginationPageSize={rpp || pageSize}
            paginationPageSizeSelector={false}
            rowModelType={modelType || 'serverSide'}
            cacheBlockSize={rpp || 20}
            serverSideSortAllLevels={true}
            serverSideEnableClientSideSort={false}
            onSortChanged={onSortChanged}
            sortingOrder={["desc", "asc"]}
            onPaginationChanged={onPaginationChanged}
            onColumnHeaderClicked={onColumnHeaderClicked}
            /* suppressServerSideFullWidthLoadingRow={true} */
            onFirstDataRendered={onFirstDataRendered}
            suppressHorizontalScroll={true}
            suppressMultiSort={true}
            context={context}
            rowSelection="multiple"
            onRowSelected={onSelectRowClicked}
            getMainMenuItems={() => []}
            suppressAnimationFrame={true}
            suppressMenuHide={false}
            getContextMenuItems={() => []}
            animateRows={true}
            totalCount={totalCount}
            suppressRowClickSelection={true}
            /* suppressServerSideInfiniteScroll={true} */
            enableCellTextSelection={true}
            ensureDomOrder={true}
            noRowsOverlayComponent={noRowsOverlayComponent || NoRowsOverlay}
            noRowsOverlayComponentParams={noRowsOverlayComponentParams || {
                totCount, tableError
            }}
            suppressPaginationPanel={true}
        /* debug={true} */
        />
        {pagination && <div
            className="am-grid-paginate-container"
        ><ReactPaginate
                previousLabel={"← Previous"}
                nextLabel={"Next →"}
                pageCount={(Math.ceil((totCount || 0) / (rpp || 20)))}
                onPageChange={Paginate}
                containerClassName={"pagination"}
                previousLinkClassName={"pagination__link"}
                nextLinkClassName={"pagination__link"}
                disabledClassName={"pagination__link--disabled"}
                activeClassName={"pagination__link--active"}
                forcePage={query?.page !== undefined ? (query?.page - 1) : 0}
                pageRangeDisplayed={(totCount || 1000) > 2000 ? 2 : 5}
                marginPagesDisplayed={2}
            />

            <div className="float_right goto_page">Go to page:
                <input
                    type="text"
                    name="page_no"
                    className=""
                    defaultValue=""
                    placeholder="No."
                    ref={inputGoToRef}
                />
                <button type="button" onClick={handleGotoPage} className="button_gray float_right">Go</button>
            </div></div>}
    </div>
}

export default BaseAMAdminGrid;