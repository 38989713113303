import { Box, Tooltip } from "@mui/material";
import axios, { CancelTokenSource } from "axios";
import moment from "moment";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  ArrayParam,
  NumberParam,
  StringParam,
  withDefault,
  withQueryParams,
} from "use-query-params";
import { useAuthDetails } from "../../components/Authorization";
import { Api } from "../../components/Axios";
import { useToasts } from "../../components/core";
import AMAdminTable, {
  ITableAction,
  ITableColumn,
  ITableReference,
  ITableResult,
} from "../../components/core/AMTable/AMAdminTable";
import {
  convertToCommaValue,
  deepCompare,
  formatDate,
  getResolutionMatchFor1920,
  getTimeDiffString,
  isAmopsUser,
  trimAfterSecondWord,
} from "../../utils/util-methods";
import {
  AMAdminPopUp,
  AMAdminSearchTable,
  IAMAdminSearchTableRef,
} from "../ref/AMAdminCommonComponents";
import {
  FLOW_SENSOR,
  POPUP_SUB_TITLE_META
} from "./constants/sensor-labels";
import { SensorPopup } from "./SensorPopup";
import { CollectorConfigurationContainer } from "./sub/CollectorConfigurationContainer";
import { FlowSensorContainer } from "./sub/FlowSensorContainer";
import { SiemConfiguration } from "./sub/SiemConfiguration";
import { GlobalConsts } from "../../GlobalConst";
import FlowTrend from "../issue_prev/flow-trend/flow-trend";
import { ColDef } from "@ag-grid-community/core";
import BaseAMAdminGrid from "../../components/core/AMTable/BaseAMAdminGrid";
import AMTableEmpty from "../../components/core/AMTable/AMAdminTableEmpty";
import { useCollectorHook } from "./hook/useCollector";
import { useBaseAMGrid } from "../../components/core/AMTable/useBaseAMGrid";

interface CollectorCollection {
  _id: string;
  category: string;
  collection_method: any;
  collector_config: any | null;
  collector_data_source: string;
  collector_sensor_id: string;
  collectors_count: number;
  created_date: string; // Date string format
  flows: number;
  flows_count_custom?: number;
  last_flow_time: string; // Date string format
  last_heart_beat: string; // Date string format
  modified_date: string; // Date string format
  name: string;
  sensor_collector_key: string;
  site_code: string;
  vendor_name: string;
}

interface IPopUp {
  type: string;
  data: CollectorCollection | any;
}

const is4K = window.matchMedia("(min-width: 2560px)").matches ? 1 : 1;

const AnimatedDots = () => {
  return (
    <div className="animated-dots">
      <span className="dots">.</span>
    </div>
  );
};

const renderHeartbeatFlow = (data: any) => {
  if (
    data.fs_last_heart_beat &&
    moment(data.fs_last_heart_beat).isBefore("2021-01-01T00:00:00Z") && !data.sensor_settings
  ) {
    return (
      <div className="heartbeat_flow heartbeat_flow-120" title="Please wait. It can take up to 15 minutes to provision & deploy the collector, configure the data source, and for the source to start sending the data.">
        {"Please wait. It can take up to 15 minutes..."}
        {/* <div style={{ float: "left", marginRight: "3px" }}>Configuring</div>{" "}
        <AnimatedDots /> */}
      </div>
    );
  } else if (
    data.last_flow_time &&
    moment(data.last_flow_time).isAfter("2021-12-31T01:14:00Z")
  ) {
    return (
      <div className="heartbeat_flow">{formatDate(data.last_flow_time)}</div>
    );
  } else {
    return (
      <div className="heartbeat_flow heartbeat_flow-120" title="No Flows Collected Yet">
        {"No Flows Collected Yet"}
      </div>
    );
  }
};

const renderHeartbeatDot = (data: any) => {
  if (
    data.fs_last_heart_beat &&
    moment(data.fs_last_heart_beat).isBefore("2021-01-01T00:00:00Z") && !data.sensor_settings
  ) {
    return (
      <span
        style={{
          position: "relative",
          transform: "translateX(-30%)",
          fontSize: "30px",
          color: "#CCC",
          float: 'none'
        }}
      >
        &#9679;
      </span>
    );
  } else if (data.last_flow_time) {
    const hoursDifference = moment
      .duration(moment().diff(moment(data.last_flow_time)))
      .asHours();
    if (hoursDifference < 1) {
      return (
        <span
          style={{
            position: "relative",
            transform: "translateX(-30%)",
            fontSize: "30px",
            float: 'none'
          }}
          className="heartbeat_green_dot"
        >
          &#9679;
        </span>
      );
    } else if (hoursDifference >= 1 && hoursDifference < 2) {
      return (
        <Tooltip classes={{ tooltip: "generic-tooltip-popper" }} title='Data has not been received for > 1 hour.'>
          <span
            style={{
              position: "relative",
              transform: "translateX(-30%)",
              fontSize: "30px",
              float: 'none'
            }}
            className="heartbeat_orange_dot"
          >
            &#9679;
          </span>
        </Tooltip>
      );
    } else {
      return (
        <Tooltip classes={{ tooltip: "generic-tooltip-popper" }} title='Data has not been received for > 2 hour.'>
          <span
            style={{
              position: "relative",
              transform: "translateX(-30%)",
              fontSize: "30px",
              float: 'none'
            }}
            className="heartbeat_red_dot"
          >
            &#9679;
          </span>
        </Tooltip>
      );
    }
  } else {
    return (
      <Tooltip title='Data have not been received in > 2 hour.'>
        <span
          style={{
            position: "relative",
            transform: "translateX(-30%)",
            fontSize: "30px",
            float: 'none'
          }}
          className="heartbeat_red_dot"
        >
          &#9679;
        </span>
      </Tooltip>
    );
  }
};

const DEPL_TYPE = {
  ACTIVE_AD: { type: "Active Directory & Cloud IdPs", index: 0 },
  NETWORK_AC: { type: "Network Access", index: 1 },
  CLOUD_ON_PREM: { type: "Cloud & On-Premise Infrastructure", index: 2 }
}

const CollectorsList = ({ query, setQuery }: any) => {
  const history = useHistory();
  const adchildRef = useRef();
  const networkchildRef = useRef();
  const onpremchildRef = useRef();
  useCollectorHook(adchildRef, networkchildRef, onpremchildRef)
  const [addata, setAddata] = useState<ITableResult<CollectorCollection | any> | any>();
  const [networkdata, setNetworkdata] = useState<ITableResult<CollectorCollection | any> | any>();
  const [onpremdata, setOnpremdata] = useState<ITableResult<CollectorCollection | any> | any>();
  const [showPopUp, setShowPopUp] = useState<IPopUp>({ type: "", data: null });
  const { addToast } = useToasts();
  const { authDetails } = useAuthDetails()!;
  const searchRef = useRef<IAMAdminSearchTableRef>();
  const inputRef = useRef<HTMLInputElement>(null);
  const [showFlowSensor, setShowFlowSensor] = useState(false);
  const [showSiemVendor, setShowSiemVendor] = useState(false);
  const [showCollectorConfiguration, setShowCollectorConfiguration] = useState(false);
  const abortAdControllerRef = useRef<CancelTokenSource | null>(null);
  const abortNetworkControllerRef = useRef<CancelTokenSource | null>(null);
  const abortOnPremControllerRef = useRef<CancelTokenSource | null>(null);
  const [openFlow, setOpenFlow] = useState<boolean>(false);
  const [flowsTrendResponseData, setFlowsTrendResponseData] = useState<any>(undefined);
  const [flowsChartDetails, setFlowsChartDetails] = useState<any>({ chartTitle: '' });
  const [searchQueryState, setSearchQueryState] = useState({});
  const updatedExtremesRef = useRef({ min: null, max: null });
  const [zoomLevel, setZoomLevel] = useState('hourly');
  const { refreshGrid: adRefreshGrid } = useBaseAMGrid(adchildRef)
  const { refreshGrid: networkRefreshGrid } = useBaseAMGrid(networkchildRef)
  const { refreshGrid: onPremRefreshGrid } = useBaseAMGrid(onpremchildRef)

  const [CollectorTableColumns] = useState<ColDef<CollectorCollection>[]>([
    {
      headerName: "Collector Type",
      field: "collector_data_source",
      cellClass: "align_left ellipsis",
      sortable: true,
      width: getResolutionMatchFor1920(430, 380),
      maxWidth: getResolutionMatchFor1920(430, 380),
      minWidth: getResolutionMatchFor1920(430, 380),
      autoHeight: true,
      cellRenderer: ({ data }) => {
        return (
          <div>
            <div
              className={
                data.vendor_name.replace(/\s+/g, "_").toLowerCase() +
                " collector-logo logo-size"
              }
            ></div>
            <Tooltip
              classes={{ tooltip: "generic-tooltip-popper" }}
              title={data.collector_data_source}
            >
              <div className="text-ellipsis vendor-name">
                {data.collector_data_source}
              </div>
            </Tooltip>
          </div>
        );
      },
    },
    {
      headerName: "Collector Name",
      field: "name",
      cellClass: "align_left ellipsis vert_middle",
      sortable: true,
      initialSort: "desc",
      width: getResolutionMatchFor1920(300, 190),
      minWidth: getResolutionMatchFor1920(300, 190),
      cellRenderer: ({ data }) => {
        return (
          <div style={{ display: "flex" }} className="ellipsis ">
            <Tooltip
              classes={{ tooltip: "generic-tooltip-popper" }}
              title={data.name}
            >
              <div
                style={{
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }}
              >
                {data.name}
              </div>
            </Tooltip>
          </div>
        );
      },
    },
    {
      headerName: "Collection Method",
      field: "collection_method",
      sortable: true,
      width: getResolutionMatchFor1920(350, 180),
      minWidth: getResolutionMatchFor1920(350, 180),
      cellClass: "align_left vert_middle",
      cellRenderer: ({ data }) => {
        let label = ``;
        if (['Microsoft AD (Domain Controller) Agent', 'Microsoft AD-LDS (Domain Controller) Agent', 'Microsoft AD (Windows Event Forwarding) Agent', 'Microsoft AD-LDS (Windows Event Forwarding) Agent'].includes(data.collector_data_source) && data.collection_method.flow_sensor_name) {
          label = data.collection_method.flow_sensor_name;
        } else {
          label = isAmopsUser(authDetails.email) ? data.collection_method.label_amops ? data.collection_method.label_amops : data.collection_method.label : data.collection_method.label;
        }
        return (
          <Tooltip
            classes={{ tooltip: "generic-tooltip-popper" }}
            title={label}
          >
            <div
              className="heartbeat_flow text-ellipsis "
              style={{ minWidth: "50px", width: getResolutionMatchFor1920(330, 180) }}
            >
              {label}
            </div>
          </Tooltip>
        );
      },
    },
    {
      headerName: "Site Code",
      field: "site_code",
      cellClass: "align_left  vert_middle   text_align_center",
      sortable: true,
      width: getResolutionMatchFor1920(100, 100),
      minWidth: getResolutionMatchFor1920(100, 100)
    },
    {
      headerName: "Collectors",
      field: "collectors_count",
      width: getResolutionMatchFor1920(100, 100),
      minWidth: getResolutionMatchFor1920(100, 100),
      cellClass: "vert_middle display_flex_center",
      headerClass: "flow-column-center",
      sortable: false,
      cellRenderer: ({ data }) => {
        return data.collectors_count > 0 ? (
          <a
            className="shadowbox"
            onClick={() => {
              navigateToCollectors(data);
            }}
          >
            {data.collectors_count}
          </a>
        ) : (
          <div className="display_flex_center "> {data.collectors_count}</div>
        );
      },
    },
    {
      headerName: "Status",
      field: "last_flow_time",
      sortable: false,
      headerClass: "flow-column-center",
      width: getResolutionMatchFor1920(80, 80),
      cellClass: "align_center vert_middle pad-t10 ",
      cellRenderer: ({ data }) => {
        return renderHeartbeatDot(data)
      }
    },
    {
      headerName: "Flows",
      field: "flows",
      maxWidth: getResolutionMatchFor1920(120, 120),
      sortable: false,
      headerClass: "flow-column-center ",
      cellClass: "vert_middle display_flex_center flow-column-center flow-column-center  ",
      cellRenderer: ({ data }) => {
        /*
        *   Commenting below condition until confirmation from business for this change.
        */
        // if (data.flows_count_custom) {
        //   return data.flows_count_custom > 0 ? (
        //     <a
        //     className="shadowbox"
        //     onClick={() => {
        //       onFlowsClick(data);
        //     }}
        //   >
        //     {convertToCommaValue(data.flows_count_custom)}
        //   </a>) : (<span>{convertToCommaValue(data.flows_count_custom)}</span>)
        // } else { 
        return data.flows > 0 ? (
          <a
            className="shadowbox"
            onClick={() => {
              onFlowsClick(data);
            }}
          >
            {convertToCommaValue(data.flows)}
          </a>) : (<div className="display_flex_center">{convertToCommaValue(data.flows)}</div>)
        // }
      },
    },
    {
      headerName: "Latest Flow",
      field: "last_flow_time",
      width: getResolutionMatchFor1920(200, 180),
      minWidth: getResolutionMatchFor1920(200, 180),
      sortable: false,
      cellClass: "align_left vert_middle",
      cellRenderer: ({ data }) => {
        return renderHeartbeatFlow(data);
      },
    },
  ])

  const handleClose = () => {
    setShowPopUp({ type: "", data: null });
  };

  const onFlowsClick = (data) => {
    setOpenFlow(true);
    let { collector_data_source: entity, collector_sensor_id } = data;
    const currentTime = +new Date();
    const cachedFlowData: any = sessionStorage.getItem(
      `deployment-summary-flow-trend-${entity}`
    );

    const encodedEntity = encodeURIComponent(entity);

    const flowTrendURL = `/collectors/flowcounts?search_type=collector_all&sensor_id=${collector_sensor_id}&search_value=${encodedEntity}`;

    if (
      !cachedFlowData ||
      JSON.parse(cachedFlowData)?.expiry_time < currentTime
    ) {
      Api.get(flowTrendURL)
        .then((res: any) => {
          if (!res?.data?.length || res?.data?.length <= 0) {
            setFlowsTrendResponseData({
              flows: [],
              expiry_time: currentTime + 600000
            });

            // Chart header and color
            const chartTitle = getChartTitle(entity);
            setFlowsChartDetails({
              chartTitle
            });
            return;
          }

          let result = JSON.parse(JSON.stringify(res?.data)) || [];
          result.sort((a: any, b: any) =>
            a?.time < b?.time ? -1 : 1
          );
          const cachedFlows = {
            flows: result,
            encodedEntity,
            risk: null,
            expiry_time: currentTime + 600000
          };
          setFlowsTrendResponseData(cachedFlows);

          // Chart header and color
          const startFromDate = Math.max(
            moment(result?.[0]?.time).valueOf(),
            moment().subtract(90, 'days').valueOf()
          );
          const chartTitle = getChartTitle(
            entity,
            startFromDate
          );
          console.log(chartTitle)
          setFlowsChartDetails({
            chartTitle
          });

          sessionStorage.setItem(
            `deployment-summary-flow-trend-${encodedEntity}`,
            JSON.stringify(cachedFlows)
          );
        })
        .catch((er) => {
          console.log(er);
        });
    } else {
      const parsedData = JSON.parse(cachedFlowData);
      setFlowsTrendResponseData(parsedData);

      // Chart header and color
      const { flows } = parsedData;
      const startFromDate = Math.max(
        moment(flows?.[0]?.time).valueOf(),
        moment().subtract(90, 'days').valueOf()
      );
      const chartTitle = getChartTitle(entity, startFromDate);
      console.log(chartTitle)
      setFlowsChartDetails({
        chartTitle
      });
    }

  }

  useEffect(() => {
    if (!openFlow) {
      setFlowsTrendResponseData(undefined);
    }
  }, [openFlow]);

  const getChartTitle = (entity: string, startDate: any = null) => {
    if (startDate)
      return (
        <span
          title={`Hourly flows trend (${getTimeDiffString(
            startDate
          )}) for Collector: ${entity}`}
        >{`Hourly flows trend (${getTimeDiffString(
          startDate
        )}) for Collector: ${trimAfterSecondWord(
          entity,
          35
        )}`}</span>
      );
    else
      return (
        <span
          title={`Hourly flows trend for Collector: ${entity}`}
        >{`Hourly flows trend for Collector: ${trimAfterSecondWord(
          entity,
          35
        )}`}</span>
      );
  };

  const navigateToCollectors = (data: CollectorCollection) => {
    sessionStorage.setItem(
      "sensor",
      JSON.stringify({
        id: data?.collector_sensor_id,
        name: data?.collection_method?.label,
        typedata: "manageSensor",
        data_src: data.collector_data_source,
      })
    );
    history.push(`/deploy/collector?order_by=asc&sort_by=name&rpp=20`);
  };



  const [NetworkCollectorTableColumns] = useState<ColDef[]>([
    {
      headerName: "Collector Type",
      field: "collector_data_source",
      cellClass: "align_left ellipsis",
      sortable: true,
      width: getResolutionMatchFor1920(430, 380),
      maxWidth: getResolutionMatchFor1920(430, 380),
      minWidth: getResolutionMatchFor1920(430, 380),
      autoHeight: true,
      cellRenderer: ({ data }) => {
        return (
          <div>
            <div
              className={
                data.vendor_name.replace(/\s+/g, "_").toLowerCase() +
                " collector-logo logo-size"
              }
            ></div>

            <Tooltip
              classes={{ tooltip: "generic-tooltip-popper" }}
              title={data.collector_data_source}
            >
              <div className="text-ellipsis vendor-name">
                {data.collector_data_source}
              </div>
            </Tooltip>
          </div>
        );
      },
    },

    {
      headerName: "Collector Name",
      field: "name",
      cellClass: "align_left ellipsis vert_middle",
      sortable: true,
      width: getResolutionMatchFor1920(300, 190),
      minWidth: getResolutionMatchFor1920(300, 190),
      cellRenderer: ({ data }) => {
        return (
          <div style={{ display: "flex" }} className="ellipsis ">
            <Tooltip
              classes={{ tooltip: "generic-tooltip-popper" }}
              title={data.name}
            >
              <div
                style={{
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }}
              >
                {data.name}
              </div>
            </Tooltip>
          </div>
        );
      }
    },
    {
      headerName: "Collection Method",
      field: "collection_method",
      width: getResolutionMatchFor1920(350, 180),
      minWidth: getResolutionMatchFor1920(350, 180),
      sortable: true,
      cellClass: "align_left vert_middle",
      cellRenderer: ({ data }) => {
        return (
          <Tooltip
            classes={{ tooltip: "generic-tooltip-popper" }}
            title={isAmopsUser(authDetails.email) ? data.collection_method.label_amops ? data.collection_method.label_amops : data.collection_method.label : data.collection_method.label}
          >
            <div
              className="heartbeat_flow text-ellipsis  "
              style={{ minWidth: "50px", width: getResolutionMatchFor1920(330, 180) }}
            >
              {isAmopsUser(authDetails.email) ? data.collection_method.label_amops ? data.collection_method.label_amops : data.collection_method.label : data.collection_method.label}
            </div>
          </Tooltip>
        );
      },
    },
    {
      headerName: "Site Code",
      field: "site_code",
      cellClass: "align_left  vert_middle   text_align_center",
      sortable: true,
      width: getResolutionMatchFor1920(100, 100),
      minWidth: getResolutionMatchFor1920(100, 100)
    },
    {
      headerName: "Collectors",
      field: "collectors_count",
      sortable: false,
      headerClass: "flow-column-center",
      width: getResolutionMatchFor1920(100, 100),
      minWidth: getResolutionMatchFor1920(100, 100),
      cellClass: "vert_middle display_flex_center",
      cellRenderer: ({ data }) => {
        return data.collectors_count > 0 ? (
          <a
            className="shadowbox"
            onClick={() => {
              navigateToCollectors(data);
            }}
          >
            {data.collectors_count}
          </a>
        ) : (
          <div className="display_flex_center "> {data.collectors_count}</div>
        );
      },
    },
    {
      headerName: "Status",
      field: "last_flow_time",
      sortable: false,
      headerClass: "flow-column-center",
      width: getResolutionMatchFor1920(80, 80),
      cellClass: "align_center vert_middle pad-t10 ",
      cellRenderer: ({ data }) => {
        return renderHeartbeatDot(data)
      },
    },
    {
      headerName: "Flows",
      field: "flows",
      sortable: false,
      maxWidth: getResolutionMatchFor1920(120, 120),
      headerClass: "flow-column-center ",
      cellClass: "vert_middle display_flex_center flow-column-center ",
      cellRenderer: ({ data }) => {
        if (data.flows_count_custom) {
          return data.flows_count_custom > 0 ? (
            <a
              className="shadowbox"
              onClick={() => {
                onFlowsClick(data);
              }}
            >
              {convertToCommaValue(data.flows_count_custom)}
            </a>) : (<div className="display_flex_center">{convertToCommaValue(data.flows_count_custom)}</div>)
        } else {
          return data.flows > 0 ? (
            <a
              className="shadowbox"
              onClick={() => {
                onFlowsClick(data);
              }}
            >
              {convertToCommaValue(data.flows)}
            </a>) : (<div className="display_flex_center">{convertToCommaValue(data.flows)}</div>)
        }
      },
    },
    {
      headerName: "Latest Flow",
      field: "last_flow_time",
      sortable: false,
      width: getResolutionMatchFor1920(200, 150),
      minWidth: getResolutionMatchFor1920(200, 150),
      cellClass: "align_left vert_middle",
      cellRenderer: ({ data }) => {
        return renderHeartbeatFlow(data);
      },
    },
  ])

  const CollectorActions: ITableAction<CollectorCollection | unknown>[] = [
    {
      actionId: "edit-collector-name",
      actionLabel: "Manage",
      shouldDisplay: (item: any) => true,
    },
    {
      actionId: "view-collector",
      actionLabel: "View Collectors",
      shouldDisplay: (item: any) => true,
    },
    {
      actionId: "delete-collector",
      actionLabel: "Delete",
      shouldDisplay: (item: any) => {
        return !(
          item.collector_data_source === "Microsoft AD (Domain Controller Agent)" ||
          item.collector_data_source === "Microsoft AD-LDS (Domain Controller Agent)"
        );
      },
    },
  ];

  const CollectorNWActions: ITableAction<CollectorCollection | unknown>[] = [
    {
      actionId: "edit-collector-name",
      actionLabel: "Manage",
      shouldDisplay: (item: any) => true,
    },
    {
      actionId: "view-collector",
      actionLabel: "View Collectors",
      shouldDisplay: (item: any) => true,
    },
    {
      actionId: "delete-collector",
      actionLabel: "Delete",
      shouldDisplay: (item: any) => true,
    },
  ];

  const proceedDelete = () => {
    const headers = { Operation: "DELETE" };
    Api.post(
      `/collectors/managed/${showPopUp.data._id}`,
      {
        collector_sensor_id: showPopUp.data.collector_sensor_id,
      },
      { headers }
    )
      .then((res: { data: any }) => {
        setQuery({ ...query, hard_refresh: "true" });
        afterDeleteCollectorOperation(showPopUp.data.category);
        handleClose();
        addToast("Collecter has been deleted successfully.", {
          appearance: "success",
          autoDismiss: true,
        });
        adRefreshGrid()
        onPremRefreshGrid()
        networkRefreshGrid()
      })
      .catch((error: any) => {
        handleError(error);
      });
  };

  const proceedUpdate = (evt?: any) => {
    const headers = { Operation: "PUT" };
    if (evt.key && evt.key != "Enter") {
      return;
    }

    if (evt.key && evt.key == "Enter") {
      evt.preventDefault();
    }

    Api.post(
      "/sensors/collectors",
      {
        sensor_id: showPopUp.data.collector_sensor_id,
        collector_id: showPopUp.data.collector_id,
        collector_name: inputRef.current?.value,
      },
      { headers }
    )
      .then((res: { data: any }) => {
        handleClose();
        addToast("Collecter has been updated successfully.", {
          appearance: "success",
          autoDismiss: true,
        });
        setQuery({ ...query, hard_refresh: "true" });
        adRefreshGrid()
        onPremRefreshGrid()
        networkRefreshGrid()
        // fetchCollectorList();
      })
      .catch((error: any) => {
        handleError(error);
      });
  };

  const deletePopUpButtons = {
    primary: {
      label: "Yes",
      action: proceedDelete,
    },
    secondary: {
      label: "No",
      action: handleClose,
    },
  };

  const updatePopUpButtons = {
    primary: {
      label: "Save",
      action: proceedUpdate,
    },
    secondary: {
      label: "Cancel",
      action: handleClose,
    },
  };

  const onSubmit = (data: any, index: number) => {
    switch (index) {
      case 0:
        setQuery({ ...query, q: data.query, page: 1, tableIndex: index })
        break;
      case 1:
        setQuery({ ...query, q: data.query, page: 1, tableIndex: index })
        break;
      case 2:
        setQuery({ ...query, q: data.query, page: 1, tableIndex: index })
        break;
    }
  }

  const fetchAdCollectorList = (queryParams?, paramGrid?) => {
    if (abortAdControllerRef.current) {
      abortAdControllerRef.current.cancel();
    }
    abortAdControllerRef.current = axios.CancelToken.source();
    Api.get(`/collectors/managed?category=ad-cloud-idp`, {
      params: queryParams || {},
      cancelToken: abortAdControllerRef.current?.token
    })
      .then((resp: any) => {
        const data = resp.data as ITableResult<CollectorCollection>;
        setAddata(data);
        if (paramGrid) {
          paramGrid.success({
            rowData: resp.data.result,
            rowCount: resp.data.total
          });
          if (adchildRef?.current?.api) {
            adchildRef.current.api.hideOverlay();
            if (resp.data.total === 0) {
              adchildRef.current.api.showNoRowsOverlay();
            }
          }
        }
        setQuery({ ...query, ...queryParams })
      })
      .catch(() => {
        /* adchildRef?.current?.onSetTableError("Error occurred while fetching table data.") */
      }
      );
  };

  const fetchNetworkCollectorList = (queryParams?, paramGrid?) => {
    if (abortNetworkControllerRef.current) {
      abortNetworkControllerRef.current.cancel();
    }
    abortNetworkControllerRef.current = axios.CancelToken.source();
    Api.get(`/collectors/managed?category=network-access`, {
      params: queryParams || {},
      cancelToken: abortNetworkControllerRef.current.token
    })
      .then((resp: any) => {
        const data = resp.data as ITableResult<CollectorCollection>;
        if (isAmopsUser(authDetails.email)) {
          setNetworkdata(resp.data);
        } else {
          const filteredResult = resp.data.result.filter(
            (item: any) =>
              item.collector_data_source !== "Evernorth Control Center"
          );
          const filteredData = {
            ...resp.data,
            result: filteredResult,
          };
          setNetworkdata(filteredData);
        }
        if (paramGrid) {
          paramGrid.success({
            rowData: resp.data.result,
            rowCount: resp.data.total
          });
          if (networkchildRef?.current?.api) {
            networkchildRef.current.api.hideOverlay();
            if (resp.data.total === 0) {
              networkchildRef.current.api.showNoRowsOverlay();
            }
          }
        }
        setQuery({ ...query, ...queryParams })
      })
      .catch(() => {
        /*  networkchildRef?.current?.onSetTableError(
           "Error occurred while fetching table data."
         ) */
      }

      );
  };

  const fetchOnPremCollectorList = (queryParams?, paramGrid?) => {
    if (abortOnPremControllerRef.current) {
      abortOnPremControllerRef.current.cancel();
    }
    abortOnPremControllerRef.current = axios.CancelToken.source();
    Api.get(`/collectors/managed?category=cloud-infra`, {
      params: {
        ...queryParams || {}
      },
      cancelToken: abortOnPremControllerRef.current?.token
    })
      .then((resp: any) => {
        const data = resp.data as ITableResult<CollectorCollection>;
        setOnpremdata(data);

        const timeoutScroll = setTimeout(() => {
          if (query.hasOwnProperty('tableIndex') && document.getElementById(`table_header_${query?.tableIndex}`)) {
            document.getElementById(`table_header_${query?.tableIndex}`).scrollIntoView();
          }
        }, 500)

        if (paramGrid) {
          paramGrid.success({
            rowData: resp.data.result,
            rowCount: resp.data.total
          });
          if (onpremchildRef?.current?.api) {
            onpremchildRef.current.api.hideOverlay();
            if (resp.data.total === 0) {
              onpremchildRef.current.api.showNoRowsOverlay();
            }
          }
        }
        setQuery({ ...query, ...queryParams })
        return () => {
          clearTimeout(timeoutScroll);
        }
      })
      .catch(() => {
        /*  onpremchildRef?.current?.onSetTableError(
           "Error occurred while fetching table data."
         ) */
      }

      );
  };

  CollectorActions[2].actionCallback = function (
    i: CollectorCollection | unknown,
    e: any
  ) {
    setShowPopUp({ data: i, type: "DeleteCollector" });
  };

  CollectorActions[1].actionCallback = function (
    i: CollectorCollection | unknown,
    e: any
  ) {
    sessionStorage.setItem(
      "sensor",
      JSON.stringify({
        id: i?.collector_sensor_id,
        name: i?.collection_method?.label,
        typedata: "manageSensor",
        data_src: i.collector_data_source,
      })
    );
    history.push(`/deploy/collector?order_by=asc&sort_by=name&rpp=20`);
  };

  CollectorActions[0].actionCallback = function (
    i: CollectorCollection | any,
    e: any
  ) {
    let type = "UpdateCollector";
    let data = { ...i };
    if (i.collector_data_source && i.collector_data_source === 'Microsoft AD (Domain Controller) Agent') {
      type = 'UpdateADSensor';
      data.sensorType = 1;
    } else if (i.collector_data_source && i.collector_data_source === 'Microsoft AD-LDS (Domain Controller) Agent') {
      type = 'UpdateADSensor';
      data.sensorType = 17;
    } else if (i.collector_data_source && i.collector_data_source === 'Microsoft AD (Windows Event Forwarding) Agent') {
      type = 'UpdateADSensor';
      data.sensorType = 18;
    } else if (i.collector_data_source && i.collector_data_source === 'Microsoft AD-LDS (Windows Event Forwarding) Agent') {
      type = 'UpdateADSensor';
      data.sensorType = 19;
    }

    setShowPopUp({ data: data, type: type });
  };

  useEffect(() => {
    if (
      onpremdata?.result?.length === 0 &&
      networkdata?.result?.length === 0 &&
      addata?.result?.length === 0
    ) {
      setHideDiv(false);
    }
  }, [onpremdata, networkdata, addata]);

  CollectorNWActions[2].actionCallback = function (
    i: CollectorCollection | unknown,
    e: any
  ) {
    setShowPopUp({ data: i, type: "DeleteCollector" });
  };

  CollectorNWActions[1].actionCallback = function (
    i: CollectorCollection | unknown,
    e: any
  ) {
    sessionStorage.setItem(
      "sensor",
      JSON.stringify({
        id: i?.collector_sensor_id,
        name: i?.collection_method?.label,
        typedata: "manageSensor",
        data_src: i.collector_data_source,
      })
    );
    history.push(`/deploy/collector?order_by=asc&sort_by=name&rpp=20`);
  };

  CollectorNWActions[0].actionCallback = function (
    i: CollectorCollection | any,
    e: any
  ) {
    setShowPopUp({ data: i, type: "UpdateCollector" });
  };

  const updateTables = (index) => {
    switch (index) {
      case 0: fetchAdCollectorList();
        break
      case 1: fetchNetworkCollectorList();
        break;
      case 2: fetchOnPremCollectorList();
        break;
    }
  }

  useEffect(() => {
    const tableIndex = query?.tableIndex || 0;
    const newState = { ...searchQueryState, [tableIndex]: { query: query?.q || '', page: query?.page } };
    if (!deepCompare(newState, searchQueryState)) {
      setSearchQueryState(newState)
    }
    if (!query?.q) {
      updateTables(tableIndex);
    }
  }, [query]);


  useEffect(() => {
    const tableIndex = query?.tableIndex || 0;
    updateTables(tableIndex)
  }, [searchQueryState])

  useEffect(() => {
    fetchAdCollectorList();
    fetchNetworkCollectorList();
    fetchOnPremCollectorList();
  }, [])

  const preSortingHandler = (p, tableIndex) => {
    p.q = searchQueryState[tableIndex]?.query || '';
    p.page = searchQueryState[tableIndex]?.page || 1
  }

  const handleError = (error: any) => {
    handleClose();
    if (error?.response?.status === 500) {
      addToast(error.response.data, {
        appearance: "error",
        autoDismiss: true,
      });
    } else if (error?.response?.status === 419) {
      addToast(
        "We encounted validation problem, please correct and try again.",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    } else if (error?.response?.status === 404) {
      addToast(
        "We are not able to find associated email, please check and try again.",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    } else if (error?.response?.status === 409 || error?.response?.data) {
      addToast(error.response.data, {
        appearance: "error",
        autoDismiss: true,
      });
    } else {
      addToast("Sorry, something went wrong there, try again.", {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };

  const SearchTable = ({ index }) => {
    return (
      <AMAdminSearchTable
        classes={{ root: 'manage-collector-root' }}
        searchTitle=""
        searchPlaceHolder="Data Source Name, Type, Method, Site Code"
        query={searchQueryState[index]?.query}
        ref={searchRef}
        onSearchValue={(data) => onSubmit(data, index)}
      />
    );
  };

  const DeleteCollectorPopUp = ({
    collectorData,
    popUpButtons,
  }: any | null) => {
    return (
      <AMAdminPopUp
        popUpTitle={"Delete Collector"}
        popUpButtons={popUpButtons}
        handleClose={handleClose}
      >
        <span className="font14">
          Are you sure you want to delete the collector{" "}
          <strong>{collectorData?.name}</strong>?{" "}
        </span>
      </AMAdminPopUp>
    );
  };

  const UpdateCollectorPopUp = ({
    collectorData,
    popUpButtons,
  }: any | null) => {
    return (
      <AMAdminPopUp
        popUpTitle={"Edit Collector Name"}
        popUpButtons={popUpButtons}
        handleClose={handleClose}
      >
        <form>
          <label className="font14" htmlFor="collector_name">
            Collector Name:{" "}
          </label>
          <input
            id="collector_name"
            type="text"
            defaultValue={collectorData.name}
            onKeyPress={(e) => proceedUpdate(e)}
            ref={inputRef}
          />
        </form>
      </AMAdminPopUp>
    );
  };

  const handleAddButtonClick = (category: string) => {
    // Logic to handle the button click
    if (category === FLOW_SENSOR) {
      setShowFlowSensor(true);
    } else {
      history.push(
        `/deploy/supportedCollector?category=${encodeURIComponent(category)}`
      );
    }
  };

  const [hide, setHide] = useState(false);
  const [hideDiv, setHideDiv] = useState(true);

  const afterDeleteCollectorOperation = (collectorCategory) => {
    switch (collectorCategory) {
      case "Active Directory & Cloud IdPs":
        fetchAdCollectorList();
        break;
      case "Network Access":
        fetchNetworkCollectorList();
        break;
      case "Cloud & On-Premise Infrastructure":
        fetchOnPremCollectorList();
        break;
    }
  };

  const getTitleForAdd = (sensorType) => {
    switch (sensorType) {
      case '1':
        return "Add AD Sensor";
      case '10':
        return "Add Enrichment Sensor";
      case '17':
        return "Add AD LDS Sensor";
      case '3':
      case '100':
        return "Add Flow Sensor";
      case '14':
        return "Add SIEM Sensor";
      default:
        return "Choose Sensor Type";
    }
  };

  const getTitleForEdit = (sensorType) => {
    switch (sensorType.toString()) {
      case '1':
        return "Manage AD Sensor";
      case '17':
        return "Manage AD  LDS Sensor";
      case '10':
        return "Manage Enrichment Sensor";
      case '3':
      case '100':
        return "Manage Flow Sensor";
      case '14':
        return "Manage SIEM Sensor";
      default:
        return "Choose Sensor Type";
    }
  };

  const getTitle = (actionType, selectedSensorType, showNextSetUp) => {
    if (actionType.type === "add" && showNextSetUp) {
      return getTitleForAdd(selectedSensorType);
    } else if (actionType.type === "edit") {
      return getTitleForEdit(selectedSensorType);
    } else {
      return "Choose Sensor Type";
    }
  };

  const userDetails = localStorage.getItem('user') as string;
  const user = JSON.parse(userDetails);


  const getSubtitleForAdd = (sensorType) => {
    switch (sensorType.toString()) {
      case '1':
        return "Leverage an AuthMind AD Sensor to collect events and insights from a Microsoft AD Directory.";
      case '17':
        return "Leverage an AuthMind AD  LDS Sensor to collect events and insights from a Microsoft AD LDS Directory.";
      case '18':
        return "Leverage an AuthMind AD Windows Event Forwarding to collect events and insights from a Microsoft AD Directory.";
      case '19':
        return "Leverage an AuthMind AD LDS Windows event forwarding to collect events and insights from a Microsoft AD LDS Directory.";
      case '10':
        return "Enrichment Sensors are an alternative to a local Flow Sensor to enrich host details";
      case '3':
      case '100':
        return "Leverage a Flow Sensor to collect from one or more local data sources and/or local SIEMs.";
      case '14':
        return "Integrate with an existing SIEM to collect one or more data sources to streamline deployments.";
      default:
        return "Select from one of the following sensors to be used to collect data from your environment.";
    }
  };

  const getSubtitle = (actionType, selectedSensorType, showNextSetUp) => {
    if (showNextSetUp || actionType.type === 'edit') {
      return getSubtitleForAdd(selectedSensorType);
    } else {
      return "Select from one of the following sensors to be used to collect data from your environment.";
    }
  };


  const deploymentGuides = [
    {
      sensorType: "1",
      url: `${GlobalConsts.DEPLOYMENT_LINK}/installing-active-directory-sensor?${GlobalConsts.DOC_TOKEN}`,
    },
    {
      sensorType: "17",
      url: `${GlobalConsts.DEPLOYMENT_LINK}/installing-active-directory-sensor?${GlobalConsts.DOC_TOKEN}`,
    },
    {
      sensorType: "3",
      url: `${GlobalConsts.DEPLOYMENT_LINK}/installing-a-flow-sensor?${GlobalConsts.DOC_TOKEN}`,
    },
    {
      sensorType: "10",
      url: `${GlobalConsts.DEPLOYMENT_LINK}/installing-an-enrichment-sensor?${GlobalConsts.DOC_TOKEN}`,
    },
    {
      sensorType: "Hosted Flow Sensor",
      url: `${GlobalConsts.DEPLOYMENT_LINK}/installing-a-flow-sensor?${GlobalConsts.DOC_TOKEN}`,
    },
    {
      sensorType: "14",
      url: `${GlobalConsts.DEPLOYMENT_LINK}/collect-from-siem?${GlobalConsts.DOC_TOKEN}`,
    },
  ];
  const getGuideLink = (selectedSensorType, showNextSetUp) => {
    return showNextSetUp ? (deploymentGuides.find((obj) => obj.sensorType === selectedSensorType?.toString()))?.url : `${GlobalConsts.DEPLOYMENT_LINK}/deployment-overview?${GlobalConsts.DOC_TOKEN}`;
  }

  // useEffect(() => {
  //   const timeoutScroll = setTimeout(() => { 
  //     if (query.hasOwnProperty('tableIndex') && document.getElementById(`table_header_${query?.tableIndex}`)) { 
  //       document.getElementById(`table_header_${query?.tableIndex}`).scrollIntoView();
  //     }
  //   }, 500)

  //   return () => { 
  //     clearTimeout(timeoutScroll);
  //   }
  // }, [])


  return (
    <>
      <div
        className={
          !hideDiv ? "page_title_area marginbottom20" : "marginTop-30"
        }
      >
        {!hideDiv && (
          <div>
            <div
              className="launch_button "
              style={{ overflow: "hidden" }}
            >
              {hide ? (
                <h4
                  style={{ cursor: "pointer" }}
                  onClick={() => setHide((prev) => !prev)}
                >
                  Show &#9660;
                </h4>
              ) : (
                <h4
                  style={{ cursor: "pointer" }}
                  onClick={() => setHide((prev) => !prev)}
                >
                  Hide &#9650;{" "}
                </h4>
              )}
            </div>

            {!hide && (
              <>
                <Box
                  className="small-button-container banner"
                  style={{ overflow: "hidden", clear: "both" }}
                >
                  <div className="float-left">
                    <div
                      className="status float-left"
                      style={{
                        height: "24px",
                        width: "24px",
                        display: "inline-block",
                        marginRight: "10px",
                      }}
                    ></div>
                    <div className="float-left font-text">
                      To start your deployment, you must first either setup a
                      Flow Sensor or configure a SIEM Sensor to collect events.
                      <a
                        className="link-text"
                        style={{ paddingLeft: "5px" }}
                        href="https://docs.authmind.com/authmind-deployment-guide/Working-version/deployment-overview"
                      >
                        Learn More
                      </a>
                    </div>
                  </div>
                  <div className="launch_button" style={{ marginLeft: "auto" }}>
                    <button
                      type="button"
                      className="button_main button_small"
                      id="new_playbook"
                      onClick={() => handleAddButtonClick("SIEM")}
                    >
                      Add SIEM
                    </button>
                  </div>
                  <div className="launch_button">
                    <button
                      type="button"
                      className="button_main button_small"
                      id="new_playbook"
                      onClick={() => handleAddButtonClick(FLOW_SENSOR)}
                    >
                      Add Flow Sensor
                    </button>
                  </div>
                </Box>
              </>
            )}
          </div>
        )}
      </div>

      {addata?.result.length >= 0 && (
        <>
          <div className="flow_table_container sensor-collector-container ">
            <div className="table-distance collector-header">
              {" "}
              <div className="float-left  width380" id="table_header_0">
                Active Directory & Cloud IdPs{" "}
              </div>{" "}
              <SearchTable index={0} />
              <div className="launch_button marginleft10">
                {authDetails.permissions.Admin.sensors !== "readonly" && (
                  <button
                    type="button"
                    className="button_main"
                    id="new_playbook"
                    onClick={() =>
                      handleAddButtonClick("Active Directory & Cloud IdPs")
                    }
                  >
                    Add
                  </button>
                )}
              </div>
            </div>
            <BaseAMAdminGrid
              columnDefs={CollectorTableColumns}
              enableErrorMsg={false}
              gridRef={adchildRef}
              {...(CollectorActions?.length > 0 &&
                authDetails.permissions.Admin.sensors !== "readonly"
                ? { actions: CollectorActions }
                : null)}
              query={query}
              setQuery={setQuery}
              noRowsOverlayComponent={AMTableEmpty}
              noRowsOverlayComponentParams={{
                buttonClick: () => { handleAddButtonClick("Active Directory & Cloud IdPs") },
                imageClasses:
                  [
                    "microsoft",
                    "aws",
                    "google",
                    "ibm",
                    "okta",
                    "ping_identity",
                  ],
                title: "Active Directory & Cloud IdP",
                subTitle: "Collecting Identity System Events is the first step in gaining visibility into Identity Activities."
              }}
              fetchEntitiesFn={fetchAdCollectorList}
            /* preSortingHandler={preSortingHandler}
            showEmptyTable={true}
            isQueryBasedRefresh={true}
            handleButtonClick={() =>
              handleAddButtonClick("Active Directory & Cloud IdPs")
            }
            imageClasses={[
              "microsoft",
              "aws",
              "google",
              "ibm",
              "okta",
              "ping_identity",
            ]}
            title="Active Directory & Cloud IdP"
            subTitle="Collecting Identity System Events is the first step in gaining visibility into Identity Activities."
            showPagination={true} */
            />
          </div>
        </>
      )}

      <>
        <div className="flow_table_container sensor-collector-container ">
          <div className="table-distance collector-header">
            <div className="float-left  width380" id="table_header_1">Network Access </div>
            <SearchTable index={1} />
            <div className="launch_button marginleft10">
              {authDetails.permissions.Admin.sensors !== "readonly" &&
                (addata?.result.length === 0 && !isAmopsUser(authDetails.email) ? (
                  <Tooltip
                    classes={{ tooltip: "generic-tooltip-popper" }}
                    title="To add a Network Access collector, first add Active Directory or Cloud IDP."
                  >
                    <button
                      type="button"
                      id="new_playbook"
                      style={{ opacity: 0.5, cursor: 'default' }}
                      className="button_main"
                    >
                      Add
                    </button>
                  </Tooltip>
                ) : (
                  <button
                    type="button"
                    id="new_playbook"
                    onClick={() => handleAddButtonClick("Network Access")}
                    className="button_main"
                  >
                    Add
                  </button>
                ))}
            </div>
          </div>
          <BaseAMAdminGrid
            columnDefs={NetworkCollectorTableColumns}
            gridRef={networkchildRef}
            enableErrorMsg={false}
            {...(CollectorNWActions?.length > 0 &&
              authDetails.permissions.Admin.sensors !== "readonly"
              ? { actions: CollectorNWActions }
              : null)}
            query={query}
            tableIndex={1}
            setQuery={setQuery}
            noRowsOverlayComponent={AMTableEmpty}
            noRowsOverlayComponentParams={{
              buttonClick: () => handleAddButtonClick("Network Access"),
              imageClasses:
                [
                  "cisco",
                  "zscaler",
                  "checkpoint",
                  "palo_alto",
                  "fortinet",
                  "sonicwall",
                  "cato",
                ],
              title: "Network Access",
              subTitle: "To enable visibility of identity activity to/from the Enterprise, it is essential to collect events from the Network Access Infrastructure."
            }}
            fetchEntitiesFn={fetchNetworkCollectorList}
          /* showEmptyTable={true}
          isQueryBasedRefresh={true}
          preSortingHandler={preSortingHandler}
          handleButtonClick={() => handleAddButtonClick("Network Access")}
          imageClasses={[
            "cisco",
            "zscaler",
            "checkpoint",
            "palo_alto",
            "fortinet",
            "sonicwall",
            "cato",
          ]}
          title="Network Access"
          subTitle="To enable visibility of identity activity to/from the Enterprise, it is essential to collect events from the Network Access Infrastructure."
          showPagination={true} */
          />
        </div>
      </>

      {onpremdata?.result.length >= 0 && (
        <>
          <div
            className="flow_table_container sensor-collector-container "
            style={{ marginBottom: "24px" }}
          >
            <div className="table-distance collector-header">
              <div className="float-left  width380" id="table_header_2">
                {" "}
                Cloud & On-Premise Infrastructure{" "}
              </div>{" "}
              <SearchTable index={2} />
              <div className="launch_button marginleft10">


                {authDetails.permissions.Admin.sensors !== "readonly" &&
                  (networkdata?.result.length === 0 && !isAmopsUser(authDetails.email) ? (
                    <Tooltip
                      classes={{ tooltip: "generic-tooltip-popper" }}
                      title="To add a Cloud & On-Premise collector, first add a Network Access collector"
                    >
                      <button
                        type="button"
                        id="new_playbook"
                        disabled
                        className="button_main"
                        style={{ opacity: 0.5, cursor: 'default' }}
                      >
                        Add
                      </button>
                    </Tooltip>
                  ) : (
                    <button
                      type="button"
                      id="new_playbook"
                      onClick={() =>
                        handleAddButtonClick(
                          "Cloud & On-Premise Infrastructure"
                        )
                      }
                      className="button_main"
                    >
                      Add
                    </button>
                  ))}
              </div>
            </div>
            <BaseAMAdminGrid
              /* tableIndex={2} */
              columnDefs={NetworkCollectorTableColumns}
              enableErrorMsg={false}
              /* data={onpremdata} */
              gridRef={onpremchildRef}
              fetchEntitiesFn={fetchOnPremCollectorList}
              {...(CollectorNWActions?.length > 0 &&
                authDetails.permissions.Admin.sensors !== "readonly"
                ? { actions: CollectorNWActions }
                : null)}
              tableIndex={2}
              query={query}
              setQuery={setQuery}
              noRowsOverlayComponent={AMTableEmpty}
              noRowsOverlayComponentParams={{
                buttonClick: () => handleAddButtonClick("Cloud & On-Premise Infrastructure"),
                imageClasses: ["aws", "azure", "cisco", "gcp", "vmware"],
                title: "Cloud & On-Premise Infrastructure",
                subTitle: "To enable visibility of identity activity across the Enterprise, it is essential to collect events from the Cloud & On-Premise Infrastructure."
              }}
            /*  preSortingHandler={preSortingHandler}
            refresh={fetchOnPremCollectorList}
            showEmptyTable={true}
            isQueryBasedRefresh={true}
            handleButtonClick={() =>
              handleAddButtonClick("Cloud & On-Premise Infrastructure")
            }
            imageClasses={["aws", "azure", "cisco", "gcp", "vmware"]}
            title="Cloud & On-Premise Infrastructure"
            subTitle="To enable visibility of identity activity across the Enterprise, it is essential to collect events from the Cloud & On-Premise Infrastructure."
            showPagination={true} */
            />
          </div>
        </>
      )}

      {showPopUp.type == "DeleteCollector" ? (
        <DeleteCollectorPopUp
          collectorData={showPopUp.data}
          popUpButtons={deletePopUpButtons}
        />
      ) : null}
      {showPopUp.type == "UpdateCollector" ? (
        <SensorPopup
          isOpen={true}
          onCloseHandler={handleClose}
          title={`Manage ${showPopUp?.data?.collector_data_source} collector`}
          classNames="sensor-modal-popup-container"
          logoClass={showPopUp?.data?.vendor_name
            .replace(/\s+/g, "_")
            .toLowerCase()}
        >
          <CollectorConfigurationContainer
            onCloseHandler={handleClose}
            collectorName={showPopUp?.data.collector_data_source}
            id={showPopUp?.data?._id}
            data={showPopUp.data}
            setShowFlowSensor={setShowFlowSensor}
            afterSaveCollectorOperation={afterDeleteCollectorOperation}
          />
        </SensorPopup>
      ) : // <UpdateCollectorPopUp
        //   collectorData={showPopUp.data}
        //   popUpButtons={updatePopUpButtons}
        // />
        null}

      {showPopUp.type == "UpdateADSensor" && <SensorPopup
        isOpen={true}
        onCloseHandler={handleClose}
        title={
          getTitle({ type: "edit" }, "1", false)
        }
        subTitle={
          getSubtitle({ type: "edit" }, "1", false)
        }
        guideLink={getGuideLink("1", false)}
        classNames="sensor-modal-popup-container"
      >
        <FlowSensorContainer
          onCloseHandler={() => {
            setShowFlowSensor(false);
          }}
          id={showPopUp.data.collector_sensor_id}
          AfterSensorOperation={() => { }}
          sensorType={showPopUp.data.sensorType}
        />
      </SensorPopup>
      }
      {showFlowSensor && (
        <SensorPopup
          isOpen={showFlowSensor}
          onCloseHandler={() => {
            setShowFlowSensor(false);
          }}
          title={"Add Flow Sensor"}
          subTitle={POPUP_SUB_TITLE_META.FLOW_SENSOR.title}
          guideLink={POPUP_SUB_TITLE_META.FLOW_SENSOR.guideLink}
          classNames="sensor-modal-popup-container"
        >
          <FlowSensorContainer
            sensorType="3"
            onCloseHandler={() => {
              setShowFlowSensor(false);
            }}
          />
        </SensorPopup>
      )}
      {showSiemVendor && (
        <SensorPopup
          isOpen={showSiemVendor}
          onCloseHandler={() => {
            setShowSiemVendor(false);
          }}
          title={"Add SIEM Vendor"}
          classNames="sensor-modal-popup-container"
        >
          <SiemConfiguration
            onCloseHandler={() => {
              setShowSiemVendor(false);
            }}
          />
        </SensorPopup>
      )}
      {/* {showCollectorConfiguration && (
          <SensorPopup isOpen={showCollectorConfiguration} onCloseHandler={() => { setShowCollectorConfiguration(false) }} title={`Add ${configurationData.collector_data_source} collector`}
          classNames='sensor-modal-popup-container' logoClass={configurationData.vendor_name.replace(/\s+/g, "_").toLowerCase()}>
              <CollectorConfigurationContainer onCloseHandler={() => { setShowCollectorConfiguration(false) }} collectorName={configurationData.collector_data_source} id={''} data={configurationData} setShowFlowSensor={setShowFlowSensor} />
          </SensorPopup>
      )} */}
      {openFlow ? <FlowTrend data={flowsTrendResponseData} chartDetails={flowsChartDetails} closeFlowPopup={() => { setOpenFlow(false); setFlowsTrendResponseData(null); setFlowsChartDetails(null) }} showZoom={false} updatedExtremes={updatedExtremesRef} zoomLevel={zoomLevel} /> : ''
      }
    </>
  );
};

export default withQueryParams(
  {
    q: StringParam,
    page: StringParam,
    rpp: NumberParam,
    sort_by: StringParam,
    order_by: StringParam,
    filters: withDefault(ArrayParam, []),
    hard_refresh: StringParam,
    sensor_id: StringParam,
    tableIndex: NumberParam
  },
  CollectorsList
);