import React from 'react';

export const SuspectedADBruteForceView = ({ config }) => {
    return (
        <>            
            {config?.dir_params?.dir_bad_password_nuser_curhour && config?.dir_params?.dir_bad_password_nuser_curhour !==
                '' && <li>

                    <span>
                        An Active Directory (AD) will be marked as under brute-force attack when:
                    </span>
                    <ul className='ml-1'>
                        {config?.dir_params?.dir_bad_password_nuser_curhour &&
                            config?.dir_params?.dir_bad_password_nuser_curhour !==
                            '' && (
                                <li>
                                    Number of AD accounts in the current hour with 'bad password' error is more than{' '}
                                    <strong>
                                        {
                                            config?.dir_params
                                                ?.dir_bad_password_nuser_curhour
                                        }
                                    </strong>
                                </li>
                            )}
                    </ul>

                </li>}
        </>
    );
};
