
import React, { useEffect, useRef, useState } from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts, { chart } from "highcharts/highstock";
import "./FlowChart.css";
import moment from "moment";
import { addDaysToEpoc, addHoursToEpoc, addMinsToEpoc, getResolutionMatch, withDebounce } from "../../../utils/util-methods";
import { useTimeBasedSearchActions } from "../../TimeBasedSearch/TimeBasedSearchActions";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { isApplyBtnDisabled } from "../../TimeBasedSearch/TimeBasedutils";
import { Tooltip } from "@mui/material";

type Props = {
  flowsData:
        | any
        | undefined;
    primaryColor: String | undefined;
    data: any
    onRangeChange: any,
    selectedRange: string,
    startDate?: string,
    closePopup?: any,
    zoomLevel?:any;
    updatedExtremes?:any;
    showZoom?:boolean;
};

interface ExtendedChart extends Highcharts.Chart {
    zoomInButton?: Highcharts.SVGElement;
    zoomOutButton?: Highcharts.SVGElement;
}

function getElementByClass(selector: string) {
    let elem = document.getElementsByClassName(selector);
    return elem?.length > 0 ? elem[0] : null;
}

let inlineChartRange = [];
const FlowChart = ({ flowsData, primaryColor = '#9fa1ae', data, onRangeChange, selectedRange, startDate, closePopup  , zoomLevel , updatedExtremes , showZoom}: Props) => {
    const [chartOptions90Days, setChartOptions90Days] = useState<any>(null);
    const [dataLoading, setDataLoading] = useState<boolean>(true);
    const currentDate = useRef(+new Date());
    const softMin = (flowsData[0][0]) && flowsData.length < 24 ? { softMin: (flowsData[0][0] - 86400 * 1000 * 5) } : {};
    const maxFlows = Math.max(...flowsData.map(p => p[1]));
    const chartRef = useRef<{
        chart: ExtendedChart; // Using ExtendedChart here
        container: React.RefObject<HTMLDivElement>;
    }>(null);
    const { appliedFilterTimestamp } = useTimeBasedSearchActions();
    const params = useLocation();
    const history = useHistory();
    const [isViewAccessDisabled, setIsViewAccessDisabled] = useState(false);
   

    const isEnabledRaw = localStorage.getItem("TIME_BASED_SEARCH_FEATURE");
    let isEnabled = false;
    if (isEnabledRaw) {
        isEnabled = JSON.parse(isEnabledRaw);
    }



    const getQueryParam = () => {
        if (!isEnabled) { 
            return '';
        }
        if (inlineChartRange.length > 0) { 
            // let startDate = moment(inlineChartRange[0]).set('hour', 0).set('minute', 0).unix();
            // let endDate = moment(inlineChartRange[1]).set('hour', 23).set('minute', 59)
            let startDate = moment(inlineChartRange[0]).unix();
            let endDate = moment(inlineChartRange[1])
            if (endDate.isAfter(moment())) {
                endDate = `${moment().unix()}_H`;
            } else { 
                endDate = moment(endDate) instanceof moment ? `${endDate.unix()}_H` : `${moment(endDate).unix()}_H`;
            }
    
            return `any_activity_time|${startDate},${endDate}`
        }
        const paramsArr = params.search.split("&");
        const qParam = paramsArr.filter(item => item.indexOf("any_activity_time") > -1);
        if (qParam && qParam.length > 0) {
            let decodedStr = qParam[0].split("="); 
            let firstDecode = decodeURIComponent(decodedStr[1]);
            let secondDecodeArr = firstDecode.split("+");
            let foundItem = secondDecodeArr.filter(item => item.indexOf("any_activity_time") > -1);
            return foundItem && foundItem[0] ? foundItem[0] : "";
        } else { 
            return '';
        }
    }
    Highcharts.setOptions({
        lang: {
            rangeSelectorFrom: '',
            rangeSelectorTo: '-'
        },
        time: {
            timezoneOffset: -moment().utcOffset() // Negate because Highcharts expects a negative value for positive UTC offsets
        }
    });

    const zoomIn = () => {
        let timeFilter = appliedFilterTimestamp();
        const { min, max } = chartRef.current?.chart.xAxis[0].getExtremes(); 
        const range = max - min;
        const center = (max + min) / 2;
    
        // Proportional buffer size
        let bufferPercentage = 0.1; // 10% of the current range as buffer
    
        if (range > 48 * 60 * 60 * 1000) { // If range is greater than 48 hours  day
            bufferPercentage = 0.3; // Use 5% buffer for larger ranges
        } else if (range <= 48 * 60 * 60 * 1000 && range >= 12 * 60 * 60 * 1000) { // Between 12 hours and 48 hours
            bufferPercentage = 0.5; // Use 10% buffer
        } else if (range < 12 * 60 * 60 * 1000) { // Less than 6 hours
            bufferPercentage = 0.6; // Use 20% buffer for shorter ranges
        }
    
        const buffer = range * bufferPercentage;
        const newRange = range / 2; // Halve the range for zoom in
    
        // Calculate new min and max with proportional buffer applied
        const newMin = center - newRange / 2 - buffer;
        const newMax = center + newRange / 2 + buffer;

        document.querySelector('.zoom-out')?.classList.remove('disabled');
        document.querySelector('.reset')?.classList.remove('disabled');  
    
        // Update the chart data and zoom
       // updateInlineChartVal(newMin, newMax, true);

       if(timeFilter.length> 0){

        if(timeFilter[0]< newMin &&  newMax < timeFilter[1] ){
            updateInlineChartVal(newMin, newMax, true);
        } else {
            if(newMin < timeFilter[0] &&  newMax < timeFilter[1] ){
                updateInlineChartVal(timeFilter[0], newMax, true);
            } else if(newMin > timeFilter[0] &&  newMax > timeFilter[1] ) {
                updateInlineChartVal(newMin, timeFilter[1], true);
            } else {
            updateInlineChartVal(timeFilter[0], timeFilter[1], true);
            }
        }
    } else {
        updateInlineChartVal(newMin, newMax, true);
    }
      //  chart.xAxis[0].setExtremes(newMin, newMax);
    }

    const zoomOut = ()=> {

        let timeFilter = appliedFilterTimestamp();
        const { min, max } = chartRef.current?.chart.xAxis[0].getExtremes(); 
        const range = max - min;
        const center = (max + min) / 2;
    
        // Proportional buffer size for zooming out
        let bufferPercentage = 0.1; // 10% of the current range as buffer
    
        // Adjust buffer percentage based on the range
        if (range > 48 * 60 * 60 * 1000) { // If range is greater than 30 hours
            bufferPercentage = 0.3; // Use 5% buffer for larger ranges
        } else if (range <= 48 * 60 * 60 * 1000 && range >= 12 * 60 * 60 * 1000) { // Between 12 hours and 48 hours
            bufferPercentage = 0.5; // Use 15% buffer
        } else if (range < 12 * 60 * 60 * 1000) { // Less than 6 hours
            bufferPercentage = 0.6 // Use 30% buffer for shorter ranges
        }
    
        const buffer = range * bufferPercentage;
        const newRange = range * 2; // Double the range for zoom out
    
        // Calculate new min and max with proportional buffer applied
        const newMin = center - newRange / 2 - buffer;
        const newMax = center + newRange / 2 + buffer;
    
        // Update the chart data and zoom
        if(timeFilter.length> 0){
            if(timeFilter[0]< newMin &&  newMax < timeFilter[1] ){
                updateInlineChartVal(newMin, newMax, true);
            } else {
                if(newMin < timeFilter[0] &&  newMax < timeFilter[1] ){
                    updateInlineChartVal(timeFilter[0], newMax, true);
                } else if(newMin > timeFilter[0] &&  newMax > timeFilter[1] ) {
                    updateInlineChartVal(newMin, timeFilter[1], true);
                } else {
                updateInlineChartVal(timeFilter[0], timeFilter[1], true);
                }
            }
        } else {
            if( moment(startDate) < moment(newMin) &&  moment() >= moment(newMax) ) {
            updateInlineChartVal(newMin, newMax, true);
            } else {
                updateInlineChartVal(moment(startDate).valueOf(), moment().valueOf(),true);
                document.querySelector('.zoom-out')?.classList.add('disabled');
            }
        }

        document.querySelector('.zoom-in')?.classList.remove('disabled');
       
       // chart.xAxis[0].setExtremes(newMin, newMax);
    }
 
    const updateYAxisExtremes = (maxValue) => {
        if (chartRef.current?.chart) {
            const chrt = chartRef.current.chart;
            const yAxis = chrt.yAxis[0];
            let newYMax = Math.max(6, maxValue * 1.4); // Calculate the new Y-axis max based on flowData
            yAxis.setExtremes(0, newYMax); // Set new Y-axis extremes
        }
    };

    useEffect(() => {

       setTimeout(()=>{
            if (flowsData && flowsData.length > 0) {
                // Find the max value from the second element of each child array in flowData
                const maxFlowValue = Math.max(...flowsData.map(item => item[1])); // Assuming item[1] contains the value
                updateYAxisExtremes(maxFlowValue);
                if (updatedExtremes?.current?.min !== null && updatedExtremes?.current?.max !== null) {
                    // Calculate the average (center point) between min and max
                    const center = (updatedExtremes?.current?.min + updatedExtremes?.current?.max) / 2;

                    // Calculate the new 1/4 and 3/4 points based on the range between min and max
                    const quarterRange = (updatedExtremes?.current?.max - updatedExtremes?.current?.min) / 4;
                    let newMin = center - quarterRange;  // 1/4 of the range
                    let newMax = center + quarterRange;  // 3/4 of the range

                    // Ensure the range between newMin and newMax does not exceed 14 days (in milliseconds)
                    const maxRange = 14 * 24 * 60 * 60 * 1000;  // 14 days in milliseconds
                    const currentRange = newMax - newMin;

                    if (currentRange > maxRange) {
                        // Adjust the newMin and newMax to be within 14 days, keeping the center fixed
                        const halfMaxRange = maxRange / 2;
                        newMin = center - halfMaxRange;
                        newMax = center + halfMaxRange;
                    }

                    // Set the new extremes to 1/4 and 3/4 points or the adjusted range
                    if (newMin < newMax) {
                        chartRef.current?.chart?.xAxis[0].setExtremes(newMin, newMax, true, false);
                    } else {
                        console.log('error');
                    }



                    if (zoomLevel === '1-minute' && quarterRange * 4 < 60 * 60 * 1000) {
                        document.querySelector('.zoom-in')?.classList.add('disabled');
                    } else {
                        document.querySelector('.zoom-in')?.classList.remove('disabled');
                    }
                } 

               
               
                
            }

        },500)

      
          
      
        
    }, [flowsData]); 



    useEffect(() => {

        let lastClickTime = 0;
        let timeFilter = appliedFilterTimestamp();
        setChartOptions90Days({
            chart: {
                type: "spline",
                renderTo: "chart",
                margin: getResolutionMatch(100,50),
                style: {
                    fontFamily: "Metropolis-Regular"
                },
                height: getResolutionMatch(900, 450),
                events: {
                    load: () => {
                        setTimeout(() => {
                            if (chartRef?.current?.chart) {
                                const chrt = chartRef.current?.chart;
                    
                               
                                // set y axis max value
                                let { dataMax:yDataMax } = chrt?.yAxis[0].getExtremes();
                                chrt?.yAxis[0].setExtremes(0,Math.max(6, yDataMax * 1.4));
                        
                    
                                let { dataMax, dataMin } = chrt?.xAxis[0].getExtremes();
                                let start_ms = addDaysToEpoc(moment().valueOf(), -5);
                                // if(updatedExtremes.current.min === null){
                                // chrt?.xAxis[0].setExtremes(start_ms, dataMax + 43200000, true, false);
                                // } else {

                                // }

                                // const zoomInButton = chrt.renderer.button('+', 810, 100, function () {
                                //     zoomIn(chrt);
                                // }).add().attr({
                                //     class: 'zoom-button zoom-in'
                                // });
                    
                                // const zoomOutButton = chrt.renderer.button('-', 810, 132, function () {
                                //     zoomOut(chrt);
                                // }).add().attr({
                                //     class: 'zoom-button zoom-out'
                                // });
                            
                                // chrt.zoomInButton = zoomInButton;
                                // chrt.zoomOutButton = zoomOutButton;

                                const timeFilter = appliedFilterTimestamp();  

                                if(timeFilter.length > 0 && showZoom ) {

                                    const center = (timeFilter[0] + timeFilter[1]) / 2;
                                    
                                    // Calculate the new 1/4 and 3/4 points based on the range between min and max
                                        const quarterRange = (timeFilter[1] - timeFilter[0]) / 4;
                                        const newMin = center - quarterRange;  // 1/4 of the range
                                        const newMax = center + quarterRange;  // 3/4 of the range
                        
                                    
                                        // Set the new extremes to 1/4 and 3/4 points
                                    if(newMin<newMax){
                                        chartRef.current?.chart?.xAxis[0].setExtremes(newMin, newMax, true, false);
                                        document.querySelector('.zoom-out')?.classList.add('disabled'); 
                                    }
                                     
                                } else {
                            
                                if ( showZoom && (updatedExtremes?.current?.min === null || !updatedExtremes)) {

                                    const center = (moment(startDate).valueOf() + moment().valueOf()) / 2;

                                    // Calculate the new 1/4 and 3/4 points based on the range between min and max
                                    const quarterRange = (moment().valueOf() - moment(startDate).valueOf()) / 4;
                                    let newMin = center - quarterRange;  // 1/4 of the range
                                    let newMax = center + quarterRange;  // 3/4 of the range

                                    // Ensure the range between newMin and newMax does not exceed 14 days (in milliseconds)
                                    const maxRange = 14 * 24 * 60 * 60 * 1000;  // 14 days in milliseconds
                                    const currentRange = newMax - newMin;

                                    if (currentRange > maxRange) {
                                        // Adjust the newMin and newMax to be within 14 days, keeping the center fixed
                                        const halfMaxRange = maxRange / 2;
                                        newMin = center - halfMaxRange;
                                        newMax = center + halfMaxRange;
                                    }

                                    // Set the new extremes to 1/4 and 3/4 points or the adjusted range
                                    if (newMin < newMax) {
                                        chartRef.current?.chart?.xAxis[0].setExtremes(newMin, newMax, true, false);
                                        document.querySelector('.zoom-out')?.classList.add('disabled');
                                    }
                                    document.querySelector('.reset')?.classList.add('disabled');   
                            } else {
                                if(updatedExtremes.current.min === null){
                                  chrt?.xAxis[0].setExtremes(start_ms, moment().valueOf(), true, false);
                                }
                            }
                        }

                               

                          

                            //    chartRef.current.chart = chartRef.current?.chart as ExtendedChart;
                             
                            }
                        }, 100);
                    },
                  
                    
                }
            },

            title: {
                align: "left",
                useHTML: true,
                text: ""
            },
            subtitle: {
                text: ""
            },
            credits: undefined,
            xAxis: [{
                crosshair: false,
                type: "datetime",
                id: 'main-x-axis',
                allowOverlap: false,
                minPadding: 0.05,
                maxPadding: 0.05,
                // offset: 20,
                labels: {
                    useHTML:true,
                    margin:50,
                    align:'left',
                    allowOverlap:false,
                    step: 5,
                    tickInterval: flowsData.length > 100 ? Math.floor(flowsData.length / 40) : 3,
                    formatter: function () {
                        const chrt = chartRef.current?.chart;
                        let { userMax,userMin } = chrt?.xAxis[0].getExtremes() || {userMax:0,userMin:0 };
                        const daysDiff = moment(userMax).diff(userMin,'hours');
                        
                        if(moment(this.value).isAfter(moment(),'day')){
                            return `<span class='flow-x-axis-label'><span>`;
                        }
                        // if(moment(this.value).isSame(moment(),'day')){
                        //     return `<span class='flow-x-axis-label'><b>Today</b><span>`;
                        // }
                        return  daysDiff > 120 ? `<span class='flow-x-axis-label'><b>${moment(this.value).format("MMM DD YYYY")}</b><span>`: `<span class='flow-x-axis-label' style="margin-left:-30px"><b>${moment(this.value).format("MMM DD YYYY,hh:mm A")}</b><span>`;
                    },
                    overflow: "justify",
                    style:{
                        paddingTop: getResolutionMatch( '70px','35px')
                    }
                },
                events: {
                    afterSetExtremes(e) {
                        const chart = chartRef.current.chart;
                        const output = `${new Date(e.min)} - ${new Date(e.max)}`
                        updateInlineChartVal(e.min,e.max,false);
                      const range = e.max - e.min;

                    //   const oneDayMs = 24 * 60 * 60 * 1000;  // 24 hours in milliseconds
                    //   const threeHoursMs = 3 * 60 * 60 * 1000; // 3 hours in milliseconds




                    // let tooltipText = '';
                    // if (zoomLevel === 'hourly') {
                    //     tooltipText = 'Click to zoom to 10-minute data';
                    // } else if (zoomLevel === '10-minute') {
                    //     tooltipText = 'Click to zoom to 1-minute data';
                    // } else {
                    //     tooltipText = 'Zoom In'; // Default tooltip
                    // }

                    // ;
                  
                      // Show/hide zoom buttons based on selected range
                    //   if (chart) {
                    //     // Update the button's tooltip
                    //       if(chart?.zoomInButton){
                    //         chart?.zoomInButton?.attr({
                    //             title: tooltipText
                    //         })
                    //     }
                    //       if (range < oneDayMs && range >= threeHoursMs && zoomLevel=='hourly' ) {
                    //           chart?.zoomInButton?.attr({ opacity: 1 }).css({ pointerEvents: 'auto', cursor: 'pointer' });  // Show zoom in if range is less than 24 hours
                    //           chart.zoomOutButton?.attr({ opacity: 0.5 }).css({ pointerEvents: 'none', cursor: 'default' });  // Show zoom out button
                    //       } else if (range < threeHoursMs &&  zoomLevel=='10-minute') {
                    //           chart?.zoomInButton?.attr({ opacity: 1 }).css({ pointerEvents: 'auto', cursor: 'pointer' });  // Show zoom in for further zoom (1-minute data)
                    //           chart.zoomOutButton?.attr({ opacity: 0.5 }).css({ pointerEvents: 'auto', cursor: 'pointer' });
                    //       } else {
                    //           chart?.zoomInButton?.attr({ opacity: 0.5 }).css({ pointerEvents: 'none', cursor: 'default' });  // Hide zoom in for ranges greater than 24 hours
                    //         //   chart?.zoomOutButton?.hide(); 
                    //       }

                    //       if(zoomLevel === '10-minute' || zoomLevel==='1-minute'){
                    //         chart.zoomOutButton?.attr({ opacity: 1 }).css({ pointerEvents: 'auto', cursor: 'pointer' })
                    //       }
                    //   }
                    }
                }
            }],
            
            yAxis: {
                opposite:false,
                softMax:60,
                title: {
                    text: ""
                },
                labels: {
                    style: {
                        // color: 'red',
                        fontSize: getResolutionMatch(20,10)
                    }
                }
            },
            tooltip: {
                shape: "rect",
                // hideDelay: 50000,
                positioner: function (w, h, p) {
                    return {
                        x: Math.min(getResolutionMatch(1400,705), Math.max(getResolutionMatch(290,110), p.plotX)),
                        y: -10
                    };
                },
                useHTML: true,
                padding: 10,
                shadow: false,
                borderWidth: 0,
                backgroundColor: "#ffffff00",
                formatter: function () {
                            const chrt = chartRef.current?.chart;
                            let { userMax, userMin } = chrt?.xAxis[0].getExtremes() || { userMax: 0, userMin: 0 };
                            if (true) {
                                // Get the chart instance and x-axis extremes
                                const chrt = chartRef.current?.chart;
                                let { userMax, userMin } = chrt?.xAxis[0].getExtremes() || { userMax: 0, userMin: 0 };
                                const hoursDiff = moment(userMax).diff(userMin, 'hours');

                                let timeFormat = "MMM DD YYYY, hh:mm A";
                                if (zoomLevel === '1-minute' || zoomLevel === '10-minute' ) {
                                    timeFormat = "hh:mm A";
                                }
                                const startTime = moment(this.x).format(timeFormat);
                                const endTime = hoursDiff < 12 && zoomLevel === '1-minute' ? moment(addMinsToEpoc(+this.x, 1)).format(timeFormat) :
                                    hoursDiff < 48   && zoomLevel === '10-minute' ? moment(addMinsToEpoc(+this.x, 10)).format(timeFormat) :
                                        moment(addHoursToEpoc(+this.x, 1)).format(timeFormat);

                                return `<span class='flow-chart-tooltip' style='box-shadow: 2px 2px 4px -2px ${primaryColor}; border: 1px solid ${primaryColor};'>
                                        <p>${startTime} - ${endTime}</p><br>
                                        <b>Flows: ${parseFloat(this.y).toFixed()}</b>
                                    </span>`;
                            } 
                },
                style: {
                    zIndex: 100000
                }
            },
            plotOptions: {
                spline: {
                     color: primaryColor,
                    //color: "#fdbf06",
                    lineWidth: getResolutionMatch( 3,1.5),
                    states: {
                        hover: {
                            lineWidth: getResolutionMatch( 3,1.5)
                        }
                    }
                },
                series: {
                    dataGrouping: {
                        enabled:false
                    }, 
                    marker: {
                        enabled: false,
                        radius: getResolutionMatch( 5,1.5),
                        states: {
                            hover: {
                                enabled: true
                            }
                        }
                    },
                    point: {
                        events: {
                            click: function () {
                                if(showZoom){
                                        const pointData = this; // Access the clicked point's data
                                        const currentTime = new Date().getTime();
                                        const timeDifference = currentTime - lastClickTime;

                                        // If two clicks happen within 300 milliseconds, treat it as a double-click
                                        if (timeDifference < 300) {
                                            console.log("Double-click detected on data point:", pointData);

                                            // Get the chart instance and x-axis extremes
                                            const chrt = chartRef.current?.chart;
                                            let { userMax, userMin } = chrt?.xAxis[0].getExtremes() || { userMax: 0, userMin: 0 };
                                            const hoursDiff = moment(userMax).diff(userMin, 'hours');

                                            // Calculate the new range based on hoursDiff
                                            let newMin, newMax;

                                            if (hoursDiff < 12) {
                                                // For ranges less than 3 hours, zoom into 1 minute intervals
                                                newMin = moment(pointData.x).valueOf();
                                                newMax = moment(pointData.x).add(1, 'minute').valueOf();
                                            } else if (hoursDiff < 48) {
                                                // For ranges between 3 hours and 24 hours, zoom into 10 minute intervals
                                                newMin = moment(pointData.x).valueOf();
                                                newMax = moment(pointData.x).add(10, 'minutes').valueOf();
                                            } else {
                                                // For ranges more than 24 hours, zoom into 1-hour intervals
                                                newMin = moment(pointData.x).valueOf();
                                                newMax = moment(pointData.x).add(1, 'hour').valueOf();
                                            }

                                            // Set the new x-axis extremes to zoom in

                                            if (data && data.pageType && data.pageType === 'issue') {
                                                history.push(`/posture/accesses?order_by=desc&sort_by=score&q=${issuePageTypeInterval(newMin,newMax)}`);
                                            } else {
                                                history.push(`/posture/accesses?order_by=desc&sort_by=score&q=${posturePageTypeInterval(newMin,newMax)}`);
                                            }


                                        }

                                        // Update the last click time
                                        lastClickTime = currentTime;
                               }
                            }
                        }
                    }
                }
            },
            legend: {
                enabled: false
            },
            rangeSelector : {
                enabled: true,
                inputEnabled: true,
                inputStyle: {
                    color: 'black',
                    fontWeight: 'normal',
                    fontFamily: 'Metropolis-Regular'
                },
                        inputDateFormat: `%b %e %Y, %I:%M %p`,
                        buttons: [],
            },
            scrollbar: {
                buttonBackgroundColor: 'transparent',
                buttonArrowColor: 'transparent',
                trackBorderColor: 'transparent',
                trackBackgroundColor: 'transparent',
                buttonBorderWidth: 0,
                height:getResolutionMatch(25,10)
            },
            navigator:{
                useHTML:true,
                height:getResolutionMatch( 100,55),
                handles: {
                    height:getResolutionMatch(35,20),
                    width:getResolutionMatch(15,7),
                },
                plotOptions: {
                    spline: {
                         color: primaryColor,
                        //color: "#fdbf06",
                        lineWidth: getResolutionMatch( 3,1.5),
                        states: {
                            hover: {
                                lineWidth: getResolutionMatch( 3,1.5)
                            }
                        }
                    },
                    series: {
                        dataGrouping: {
                            enabled:false
                        }, 
                        marker: {
                            enabled: false,
                            radius: getResolutionMatch( 5,1.5),
                            states: {
                                hover: {
                                    enabled: true
                                }
                            }
                        },
                    }
                },
                series: {
                    marker: {
                        enabled:false,
                            fillColor: '#FFFFFF',
                            lineWidth: 0,
                            lineColor: null // inherit from series
                        },
                    useHTML:true,
                    type: 'areaspline',
                    fillOpacity: 0.2,
                    dataGrouping: {
                        smoothed: true
                    },
                    lineWidth: 1,
                },
                xAxis: {
                            min:  updatedExtremes?.current?.min !== null
                                ? updatedExtremes?.current?.min
                                : timeFilter.length > 0  && showZoom  ? timeFilter[0] : moment(startDate).valueOf(),
                            max:  updatedExtremes?.current?.max !== null
                                ? updatedExtremes?.current?.max
                                : timeFilter.length > 0 && showZoom  ? timeFilter[1] : moment().valueOf(),
                                // min:  moment(startDate).valueOf(),
                                // max: moment().valueOf(), 
                            allowOverlap: false,
                            minPadding: 0.05,
                            maxPadding: 0.05,
                    labels: {
                  
                        allowOverlap: false,
                        y: getResolutionMatch(50, 25),
                        x: getResolutionMatch(200, 40),
                        useHTML: true,
                        align: 'left',
                        step: 5,
                        tickInterval: flowsData.length > 100 ? Math.floor(flowsData.length / 56) :'',
                        formatter: function () {
                                    return `<span></span>`;
                                }
                            },
                            plotBands:   updatedExtremes?.current.min !== null ? [
                                {
                                    from: moment(updatedExtremes?.current.min).valueOf(),
                                    to: moment(updatedExtremes?.current.min).valueOf() + 1, // small range to ensure visibility
                                    label: {
                                        text: moment(updatedExtremes?.current?.min).format('MMM DD YYYY, hh:mm A'),
                                        align: 'left',
                                        rotation: 0,
                                        x: -20,
                                        y: 20,
                                        useHTML: true,
                                        style: {
                                            color: 'black',
                                            fontWeight: 'bold',
                                            fontSize: '1em'
                                        }
                                    }
                                },
                                {
                                    from: moment(updatedExtremes?.current?.max).valueOf(),
                                    to: moment(updatedExtremes?.current?.max).valueOf() + 1, // small range to ensure visibility
                                    label: {
                                        text: moment(updatedExtremes?.current?.max).format('MMM DD YYYY, hh:mm A'),
                                        align: 'right',
                                        rotation: 0,
                                        x: 20,
                                        y: 20,
                                        useHTML: true,
                                        style: {
                                            color: 'black',
                                            fontWeight: 'bold',
                                            fontSize: '1em'
                                        }
                                    }
                                }
                            ]: timeFilter.length > 0  && showZoom ? [
                                    {
                                        from: moment(timeFilter[0]).valueOf(),
                                        to: moment(timeFilter[0]).valueOf() + 1, // small range to ensure visibility
                                        label: {
                                            text: moment(timeFilter[0]).format('MMM DD YYYY, hh:mm A'),
                                            align: 'left',
                                            rotation: 0,
                                            x: -20,
                                            y: 20,
                                            useHTML: true,
                                            style: {
                                                color: 'black',
                                                fontWeight: 'bold',
                                                fontSize: '1em'
                                            }
                                        }
                                    },
                                    {
                                        from: moment(timeFilter[1]).valueOf(),
                                        to: moment(timeFilter[1]).valueOf() + 1, // small range to ensure visibility
                                        label: {
                                            text: moment(timeFilter[1]).format('MMM DD YYYY, hh:mm A'),
                                            align: 'right',
                                            rotation: 0,
                                            x: 20,
                                            y: 20,
                                            useHTML: true,
                                            style: {
                                                color: 'black',
                                                fontWeight: 'bold',
                                                fontSize: '1em'
                                            }
                                        }
                                    }
                                ] : [
                                    {
                            from: moment(startDate).valueOf(),
                            to: moment(startDate).valueOf()+1,
                            label: {
                            text: moment(startDate).format('MMM DD YYYY, hh:mm A'),
                            align: 'left',
                            rotation: 0,
                            x: -20,
                            y: 20,
                            useHTML: true,
                            style: {
                                color: 'black',
                                fontWeight: 'bold',
                                fontSize:'1em'
                            }
                        }
                    }, {
                        from: moment().valueOf(),
                        to: moment().valueOf()+1,
                        label: {
                            text: moment().format('MMM DD YYYY, hh:mm A'),
                            align: 'right',
                            rotation: 0,
                            x: 20,
                            y: 20,
                            useHTML: true,
                            style: {
                                color: 'black',
                                fontWeight: 'bold',
                                fontSize:'1em'
                            }
                        }
                    }]

                

                    
                },
                
               
            },
            series: [
                {
                    name: "timestamp",
                    data: flowsData || [],
                    // data: getdata(currentDate.current) || []
                }
            ]
        });
       
    }, [flowsData, data , updatedExtremes?.current]);

    const throttledRange = withDebounce(onRangeChange, 0);

    const updateInlineChartVal = (min, max , isUserInteraction) => {
        let timeFilter = appliedFilterTimestamp();
        inlineChartRange = [];
        inlineChartRange.push(min)
        inlineChartRange.push(max)
        if (moment(max).isAfter(moment())) { 
            max = moment().valueOf();
        }
        setIsViewAccessDisabled(isApplyBtnDisabled(min, max))

        // let startDate = moment(inlineChartRange[0]).unix();
        // let endDate = moment(inlineChartRange[1])
        //              if (endDate.isAfter(moment())) {
        //                  endDate = `${moment().unix()}_H`;
        //              } else { 
        //                  endDate = moment(endDate) instanceof moment ? `${endDate.unix()}_H` : `${moment(endDate).unix()}_H`;
        //              }
        if (isUserInteraction) {
            throttledRange(min, max);
           // updatedExtremes.current = { min, max };


            
        }
    }

    const onView = () => {
        if (data && data.pageType && data.pageType === 'issue') {            
            history.push(`/posture/accesses?order_by=desc&sort_by=score&q=${issuePageType()}`)
        } else { 
            history.push(`/posture/accesses?order_by=desc&sort_by=score&q=${posturePageType()}`)
           closePopup();
            
        }
    }

    const posturePageType = () => { 
        let res = `${getQueryParam()}`;
        res = res?.length > 0 ? (res+ '+' ): ''
        res += decodeURIComponent(data?.queryData);

        return `${encodeURIComponent(res)}`;        
    }

    const issuePageType = () => {
        let res = `${getQueryParam()}`
        res = res?.length > 0 ? (res+ '+' ): ''
        res += `identity_name:${decodeURIComponent(data['source_name'])}+identity_type:${data['source_type']}+asset_name:${decodeURIComponent(data['destination_name'])}+asset_type:${data['destination_type']}`
        if (data['directory_name'] && data['directory_name'] !== "") { 
            res += `+dir_name:${data['directory_name']}`
        }

        return `${encodeURIComponent(res)}`;
    }

    const posturePageTypeInterval = (newMin,newMax) => { 
        let res = `${getQueryParamTime(newMin,newMax)}`;
        res = res?.length > 0 ? (res+ '+' ): ''
        res += decodeURIComponent(data?.queryData);

        return `${encodeURIComponent(res)}`;        
    }

    const issuePageTypeInterval = (newMin,newMax) => {
        let res = `${getQueryParamTime(newMin,newMax)}`;
        res = res?.length > 0 ? (res+ '+' ): ''
        res += `identity_name:${decodeURIComponent(data['source_name'])}+identity_type:${data['source_type']}+asset_name:${decodeURIComponent(data['destination_name'])}+asset_type:${data['destination_type']}`
        if (data['directory_name'] && data['directory_name'] !== "") { 
            res += `+dir_name:${data['directory_name']}`
        }

        return `${encodeURIComponent(res)}`;
    }

  
    const getQueryParamTime = (newMin , newMax) => {
        if (!isEnabled) { 
            return '';
        }
        if (newMin > 0) { 
            // let startDate = moment(inlineChartRange[0]).set('hour', 0).set('minute', 0).unix();
            // let endDate = moment(inlineChartRange[1]).set('hour', 23).set('minute', 59)
            let startDate = moment(newMin).unix();
            let endDate = moment(newMax)
            if (endDate.isAfter(moment())) {
                endDate = `${moment().unix()}_H`;
            } else { 
                endDate = moment(endDate) instanceof moment ? `${endDate.unix()}_H` : `${moment(endDate).unix()}_H`;
            }
    
            return `any_activity_time|${startDate},${endDate}`
        }
        const paramsArr = params.search.split("&");
        const qParam = paramsArr.filter(item => item.indexOf("any_activity_time") > -1);
        if (qParam && qParam.length > 0) {
            let decodedStr = qParam[0].split("="); 
            let firstDecode = decodeURIComponent(decodedStr[1]);
            let secondDecodeArr = firstDecode.split("+");
            let foundItem = secondDecodeArr.filter(item => item.indexOf("any_activity_time") > -1);
            return foundItem && foundItem[0] ? foundItem[0] : "";
        } else { 
            return '';
        }
    }

    

    useEffect(() => {
        if (!isEnabled) return;
        setTimeout(() => {
            let timeFilter = appliedFilterTimestamp();
            if (timeFilter.length > 0) {
                if (chartRef.current?.chart) {
                    // chartRef.current?.chart?.xAxis[0].setExtremes(timeFilter[0], timeFilter[1]);
                    inlineChartRange = [];
                }
            }
        }, 300)
    }, [])


    const [isHovered, setIsHovered] = useState(false);

    useEffect(() => {
        const maskElement = document.querySelector('.highcharts-navigator-mask-inside');
        if (maskElement) {
            if (isHovered) {
                maskElement.style.fill = '#ffcc33';
                maskElement.style.opacity = 0.3;
            } else {
                maskElement.style.fill = '';  // Reset to original or default color
            }
        }
    }, [isHovered]);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const resetToDefault = () => {
         
        const timeFilter = appliedFilterTimestamp();
        if(timeFilter?.length > 0){
        onRangeChange(timeFilter[0], timeFilter[1]);
        } else {
            onRangeChange(moment(startDate).valueOf(), moment().valueOf());
        }
        document.querySelector('.zoom-out').classList.add('disabled');
    }

    return (
        <>
            <div className='trend-90days'>
            {selectedRange === 'all' && <div title="Reset" className="reset" onClick={resetToDefault} style={{ position: 'absolute', zIndex: 1000, color: 'black', right: '72px', top: '88px', cursor: 'pointer' }}>X</div>}
            {selectedRange === 'all' && showZoom &&  
            <div className="zooom-absolute-container" >
                <div className="zoom-button-container">
               
                <button title="Zoom In" className="zoom-button zoom-in">
                    <div className="zoom-in-button" onClick={zoomIn}></div>
                </button>
                
                <button title="Zoom out" className="zoom-button zoom-out">
                     <div className="zoom-out-button"  onClick={zoomOut}></div>
                </button>
                <div className="zoom-sepearator"></div>
                </div>
            </div> }
                <HighchartsReact
                    ref={chartRef}
                    highcharts={Highcharts}
                    options={chartOptions90Days}
                    constructorType={'stockChart'}
                />
                {data && (<>
                {isEnabled && getQueryParam() !== '' ? (
                    <div>
                        {isViewAccessDisabled ? (
                            <Tooltip
                                classes={{
                                    tooltip: 'playbook-level-widget-tooltip'
                                }}
                                title='Adjust your date range to be less than 15 days.'
                            >
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <button
                                    className='chart-action-btn inline-navigator-chart-btn marginbottom10 disabled-btn'
                                    disabled={true}
                                >
                                    View Accesses
                                </button>
                                     
                                    </div>
                            </Tooltip>
                        ) : (
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <button
                                        className='chart-action-btn inline-navigator-chart-btn marginbottom10'
                                        onClick={onView}
                                        onMouseEnter={handleMouseEnter}
                                        onMouseLeave={handleMouseLeave}

                                    >
                                        View Accesses
                                    </button>
                                   
                                </div>
                            )}
                        </div>
                    ) : (
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <button
                                className='chart-action-btn inline-navigator-chart-btn marginbottom10'
                                onClick={onView}
                                onMouseEnter={handleMouseEnter}
                                onMouseLeave={handleMouseLeave}
                            >
                                View Accesses
                            </button>
                        
                    </div>
                    )}
                </>)}
            </div>
        </>
    );
};

export default React.memo(FlowChart);
