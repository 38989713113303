import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import AMTagInput from '../../../components/core/AMTagInput/AMTagInput';
import 'react-tagsinput/react-tagsinput.css';
import { Api } from '../../../components/Axios';
import { useToasts } from '../../../components/core';
import AMMultiSelect from '../../../components/core/AMMultiSelect/AMMultiSelect';
import { Titles, SOURCE_CATALOG } from '../const/Constants';
import { removeDuplicates } from '../../../../src/utils/util-methods';
import './add-saas-application.scss';
import { DialogContent } from '@material-ui/core';

type SaaSApplication = {
    name?: string;
    selectedItems?: [];
}

interface Props {
    AfterKnownApplicationOperation?: any;
    AfterAddNewSaaSApplicationBtn?: any;
    AfterAddCustomApplicationClick?: any;
    selectedApps?: any;
    clearSelectedApps?: any;
    searchState?: any;
    updateSearchState?: any;
    onPanelClose: any;
    newCustomApps: any;
}

interface KnownSaaSApplication {
    fqdn: any[] | undefined;
    name: string;
    global_saas_id: string;
    tenantID: string;
    is_saas_app: boolean;
    is_known: boolean;
    source_catalog?: string;
}

export const AddSaaSPanel = ({ AfterKnownApplicationOperation, AfterAddNewSaaSApplicationBtn, AfterAddCustomApplicationClick, selectedApps, clearSelectedApps, searchState, updateSearchState, onPanelClose, newCustomApps }: Props) => {
    const { register, handleSubmit, errors } = useForm<SaaSApplication>();
    const [selectedItems, setSelectedItems] = useState<any>([]);
    const [loading, setLoading] = useState<Boolean>(false);
    const [loader, setLoader] = useState<Boolean>(false);
    const [fqdn, setFqdn] = useState<any>([]);
    const { addToast } = useToasts()
    const [open, setOpen] = useState(true);
    const [requestFqdn, setRequestFqdn] = useState<any>([]);
    const [totalRecords, setTotalRecords] = useState<any>(0);
    const [currentPage, setCurrentPage] = useState<any>(1);
    const currentPageRef = useRef(currentPage);
    const [searchKeyword, setSearchKeyword] = useState<any>('');
    const [clearKeyword, setClearKeyword] = useState<any>(false);
    const currentSearchKeywordRef = useRef(searchKeyword);
    const [isAddDisabled, setIsAddDisabled] = useState<any>(false);
    const [showPopUp, setShowPopUp] = useState<any>({ show: false, action: 'none' });
    let keyword = localStorage.getItem('saas_state') as any;
    keyword = keyword ? JSON.parse(keyword).searchKeyword : null;
    useEffect(() => {
        let search_state = localStorage.getItem('saas_state') as any;
        search_state = search_state ? JSON.parse(search_state) : {};
        if (search_state?.fqdn || search_state?.searchKeyword) {
            if (search_state?.searchKeyword) {
                setSearchKeyword(search_state.searchKeyword)
                currentSearchKeywordRef.current = search_state.searchKeyword;
            };
            if (search_state?.fqdn) setFqdn(search_state.fqdn);
            setCurrentPage(search_state.currentPage);
        } else {
            loadList()
        }
    }, []);

    const onMultiSelectClear = (evt: any) => {
        localStorage.removeItem('saas_state');
        setSelectedItems([]);
        setSearchKeyword('');
        currentSearchKeywordRef.current = '';
        setFqdn([]);
        setCurrentPage(1);
        loadList();
    }

    currentPageRef.current = currentPage;
    currentSearchKeywordRef.current = searchKeyword;

    const onAddKnownApplication = (data?: any, callback?: any) => {
        const request = selectedItems.map((item: any) => {
            let app = null
            if (isNewCustomApp(item)) {
                app = newCustomApps.find((i: any) => i.name == getActualCustomAppName(item)) || { fqdn: [], name: '', tenantID: '' };
            } else {
                app = requestFqdn.find((i: any) => i.name == item) || { fqdn: [], name: '', tenantID: '' };
            }
            let result: KnownSaaSApplication = {
                name: app.source_catalog && app.source_catalog === SOURCE_CATALOG.CUSTOM ? getActualCustomAppName(app.name) : app.name,
                fqdn: app.fqdn,
                global_saas_id: app.source_catalog && app.source_catalog === SOURCE_CATALOG.CUSTOM ? getActualCustomAppName(app.name) : app.name,
                tenantID: app.tenantID,
                is_saas_app: app.is_saas_app,
                is_known: true,
                source_catalog: app.source_catalog ? app.source_catalog : "NA"
            };
            return result;
        });
        setLoading(true);
        setIsAddDisabled(true);
        Api.post('/knownglobalsaasapplications', request)
            .then((res: any) => {
                clearSelectedApps();
                setLoading(false)
                setIsAddDisabled(false);
                AfterKnownApplicationOperation('none');
                if (res.status == 207) {
                    addToast(res.data, {
                        appearance: 'success',
                        autoDismiss: true,
                    })
                } else
                    addToast("SaaS app marked as known, all related incidents resolved.", {
                        appearance: 'success',
                        autoDismiss: true,
                    });
                if (callback) callback();
            })
            .catch((error: any) => {
                setLoading(false)
                setIsAddDisabled(false);
                if (error?.response?.data) {
                    addToast(error.response.data, {
                        appearance: 'error',
                        autoDismiss: true,
                    })
                } else if (error?.response?.status === 500) {
                    addToast("Sorry, something went wrong there, try again.", {
                        appearance: 'error',
                        autoDismiss: true,
                    })
                } else if (error?.response?.status === 419) {
                    addToast("We encounted validation problem, please correct and try again.", {
                        appearance: 'error',
                        autoDismiss: true,
                    })
                } else if (error?.response?.status === 404) {
                    addToast("We are not able to find associated email, please check and try again.", {
                        appearance: 'error',
                        autoDismiss: true,
                    })
                }
            })
    }

    const onAddNewSaaSAppClick = (evt: any) => {
        AfterAddNewSaaSApplicationBtn(selectedItems, { searchKeyword, currentPage, fqdn });
        localStorage.setItem('saas_state', JSON.stringify({ searchKeyword, currentPage, fqdn }));
    }

    const onAddCustomApp = (evt: any) => {
        AfterAddCustomApplicationClick(selectedItems, { searchKeyword, currentPage, fqdn });
        localStorage.setItem('saas_state', JSON.stringify({ searchKeyword, currentPage, fqdn }));
    }

    const AddTags = (tag: any) => {
        const currentIndex = selectedItems ? selectedItems.indexOf(tag) : -1;
        const newChecked = [...selectedItems];
        if (currentIndex === -1) {
            newChecked.push(tag);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        const tempTags = tag.filter((item: string) => item && item.trim().length > 0);
        setSelectedItems(tempTags);
    }

    const onItemSelect = (evt: any) => {
        setSelectedItems(evt);
    }

    const handleOnSearchKeyUp = (evt: any, clear: any = false) => {
        localStorage.removeItem('saas_state');
        if (evt && evt.length >= 3) {
            setFqdn([]);
        }
        setSearchKeyword(evt);
        currentSearchKeywordRef.current = evt;
        setCurrentPage(1);
        setClearKeyword(clear);
        loadList();
    }

    const loadMoreItems = () => {
        currentPageRef.current = currentPageRef.current + 1;
        setCurrentPage(currentPageRef.current);
    }

    useEffect(() => {
        loadList(searchKeyword, true);
    }, [currentPage])

    const loadList = (searchParam: any = null, pushToNew: boolean = false) => {
        const searchKey = currentSearchKeywordRef?.current || "";
        const currentPg = currentPageRef?.current || 1;
        let fqdnList = localStorage.getItem('saas_state') as any;
        fqdnList = fqdnList ? JSON.parse(fqdnList).fqdn : [];
        if (searchKey?.trim().length > 0 && searchKey?.trim().length < 3) {
            return;
        } else if (searchKey?.trim().length >= 3) {
            setLoader(true);
            Api.get(`/knownglobalsaasapplications?q=${searchKey.trim()}&page=${currentPg}`).then(res => {
                setLoader(false);
                if (res.status === 200) {
                    let items = res.data.result ? res.data.result : [];
                    if (items.length > 0) {
                        items.forEach((item: any) => {
                            item.is_known = true;
                            if (item['source_catalog'] && item['source_catalog'] === SOURCE_CATALOG.CUSTOM) { 
                                item.name = getCustomAppName(item.name);
                            }
                        })
                    }
                    const i = [...(items ? items : []), ...requestFqdn];
                    const result = removeDuplicates([...fqdn, ...items], 'name');
                    if (fqdnList && fqdnList.length > 0) {
                        setFqdn(removeDuplicates(fqdnList, 'name'));
                        localStorage.removeItem('saas_state');
                    } else {
                        setFqdn(pushToNew ? result : removeDuplicates(items, 'name'));
                    }
                    setRequestFqdn(i);
                }
            })
                .catch(err => {
                    console.log(err);
                    setLoader(false);
                });
        } else {
            setLoader(true);
            Api.get(`/knownglobalsaasapplications?page=${currentPg}`)
                .then(res => {
                    setLoader(false);
                    if (res.status === 200) {
                        let items = res.data.result ? res.data.result : [];
                        if (items.length > 0) {
                            items.forEach((item: any) => {
                                if (item['source_catalog'] && item['source_catalog'] === SOURCE_CATALOG.CUSTOM) {
                                    item.name = getCustomAppName(item.name);
                                }
                            })
                        }
                        const i = [...(items ? items : []), ...requestFqdn];
                        const result = removeDuplicates([...fqdn, ...(items ? items : [])], 'name');
                        if (fqdnList && fqdnList.length > 0) {
                            setFqdn(removeDuplicates(fqdnList, 'name'));
                            localStorage.removeItem('saas_state');
                        } else {
                            setFqdn(pushToNew ? result : removeDuplicates(items, 'name'));
                        }
                        setRequestFqdn(i);
                        setTotalRecords(res.data.total);
                    }
                })
                .catch(err => {
                    console.log(err);
                    setLoader(false);
                });
            if (searchState?.fqdn || searchState?.searchKeyword)
                updateSearchState({});
        }
    }

    const setListInitialState = (evt: any) => {
        currentPageRef.current = 1;
        currentSearchKeywordRef.current = '';
        setCurrentPage(1);
        setFqdn([]);
        setSearchKeyword('');
        loadList(null, false);
    }

    const onDoneClick = (evt: any) => {

    }

    const handleNo = (evt: any) => {
        setShowPopUp({ show: false, action: 'none' });
    }

    const handleYes = (evt: any) => {
        const popUp = showPopUp;
        setShowPopUp({ show: false, action: 'none' });
        if (popUp.action == 'add') {
            AfterAddCustomApplicationClick(selectedItems, { searchKeyword, currentPage, fqdn });
            localStorage.setItem('saas_state', JSON.stringify({ searchKeyword, currentPage, fqdn }));
        } else if (popUp.action == 'saas') {
            AfterAddNewSaaSApplicationBtn(selectedItems, { searchKeyword, currentPage, fqdn });
            localStorage.setItem('saas_state', JSON.stringify({ searchKeyword, currentPage, fqdn }));
        }
    }

    useEffect(() => {
        if (selectedApps) {
            setSelectedItems(selectedApps);
        }
    }, []);


    const getCustomAppName = (appName: string): string => `${appName} (${SOURCE_CATALOG.CUSTOM})`;

    const getActualCustomAppName = (appName: string): string => appName.replace(` (${SOURCE_CATALOG.CUSTOM})`, '');

    const isNewCustomApp = (item) => { 
        let res = false;
        const tempItem = newCustomApps.find((i: any) => i.name == getActualCustomAppName(item));
        if (tempItem !== undefined) { 
            res = true;
        }
        return res;
    }

    return (
        <>
            <form onSubmit={handleSubmit(onAddKnownApplication)} className='add-saas-application-form add-saas-appln'>
                <div className='add-saas-application-container'>
                    <div className="add-saas-application-form-container">
                        <div className="form-control-item span-2">
                            <label className='marginbottom10' style={{ float: 'none' }}>Search From SaaS Catalog</label>
                            <AMMultiSelect
                                onSelect={onItemSelect}
                                list={fqdn}
                                selectedItems={selectedItems}
                                onClear={onMultiSelectClear}
                                onSearchKeyUp={handleOnSearchKeyUp}
                                loading={loader}
                                setInitialState={setListInitialState}
                                totalRecords={totalRecords}
                                loadMoreItems={loadMoreItems}
                                onDoneClick={onAddNewSaaSAppClick}
                                doneLabel={'Add Custom Application'}
                                searchValue={keyword || searchKeyword || null}
                            />
                        </div>
                        <div className="form-control-item span-2 known-saas-app-tag">
                            <label className="marginbottom10">Apps to be Added</label>
                            <AMTagInput
                                value={selectedItems}
                                onChange={AddTags}
                            />
                        </div>
                    </div>
                </div>

                <div className="add-saas-application-action-buttons action-button">

                    <button type={('button')} disabled={isAddDisabled}
                        onClick={onPanelClose}
                        className="button_styled ghost"
                    >Cancel</button>

                    <button type={(loading ? 'button' : 'submit')} className={"float_right " + (loading ? "loader" : "add-saas-application-submit")}
                        disabled={!selectedItems || selectedItems.length === 0}
                    >
                        Save
                    </button>
                </div>


                {/* <div className="pane_footer">
                    <div className="launch_button">
                        <button type='button'
                            disabled={isAddDisabled}
                            onClick={onAddCustomApp}
                            className={"float_left btn-color-555555 button_styled"}>{Titles.CustomTitle}</button>
                        <button
                            type={(loading ? 'button' : 'submit')}
                            className={"float_right " + (loading ? 'loader' : 'button_styled') + (!selectedItems || selectedItems.length == 0 ? ' disabled' : ' ')}
                            disabled={!selectedItems || selectedItems.length == 0}
                        >Save</button>
                    </div>
                </div> */}
            </form >
        </>

    )
}