import { RuleType } from "./Constants";

export const PbFieldMap = {
    [RuleType.ACCESS_FROM_UNAUTH_COUNTRIES]: [
        "d_hostname",
        "d_ip",
        "d_is_public",
        "d_mac",
        "d_name",
        "d_type",
        "d_port",
        "d_is_known",
        "d_protocol",
        "t_protocol",
        "s_hostname",
        "s_ip",
        "identity_status", "identity_groups", "id_attrs_change_timestamp",
        "s_is_cdn",
        "s_is_datacenter",
        "s_is_icloudrelday",
        "s_organization",
        "s_name",
        "s_type",
        "s_port",
        "s_is_known",
        "s_susp_external_scanner",
        "src_category",
        "dir_hostname",
        "dir_name",
        "dir_ip",
        "dir_category",
        "src_inter_ip",
        "loc",
        "is_streaming_flow",
        "is_svc_to_svc_flow",
        "collector_name",
        "flow_time"
    ],
    [RuleType.ACCESS_TO_UNAUTH_COUNTRIES]: [
        "d_hostname",
        "d_ip",
        "d_is_cdn",
        "d_is_datacenter",
        "d_is_icloudrelday",
        "d_organization",
        "d_name",
        "d_type",
        "d_port",
        "d_is_known",
        "d_protocol",
        "t_protocol",
        "dst_category",
        "s_hostname",
        "s_ip",
        "identity_status", "identity_groups", "id_attrs_change_timestamp",
        "s_is_public",
        "s_mac",
        "s_name",
        "s_type",
        "s_port",
        "s_susp_internal_scanner",
        "dir_hostname",
        "dir_name",
        "dir_ip",
        "dir_category",
        "src_inter_ip",
        "loc",
        "is_streaming_flow",
        "is_svc_to_svc_flow",
        "collector_name",
        "flow_time"
    ],
    [RuleType.ACCESS_FROM_ANON_IP]: [
        "d_hostname",
        "d_ip",
        "d_is_public",
        "d_mac",
        "d_name",
        "d_type",
        "d_port",
        "d_is_known",
        "d_protocol",
        "t_protocol",
        "src_countries",
        "s_hostname",
        "s_ip",
        "identity_status", "identity_groups", "id_attrs_change_timestamp",
        "s_is_cdn",
        "s_is_datacenter",
        "s_is_icloudrelday",
        "s_organization",
        "s_name",
        "s_type",
        "s_port",
        "s_is_known",
        "s_susp_external_scanner",
        "src_category",
        "dir_hostname",
        "dir_name",
        "dir_ip",
        "dir_category",
        "src_inter_ip",
        "loc",
        "is_streaming_flow",
        "is_svc_to_svc_flow",
        "collector_name",
        "flow_time"
    ],
    [RuleType.ACCESS_TO_ANON_IP]: [
        "dest_countries",
        "d_hostname",
        "d_ip",
        "d_is_cdn",
        "d_is_datacenter",
        "d_is_icloudrelday",
        "d_organization",
        "d_name",
        "d_type",
        "d_port",
        "d_is_known",
        "d_protocol",
        "t_protocol",
        "dst_category",
        "s_hostname",
        "s_ip",
        "identity_status", "identity_groups", "id_attrs_change_timestamp",
        "s_is_public",
        "s_mac",
        "s_name",
        "s_type",
        "s_port",
        "s_susp_internal_scanner",
        "dir_hostname",
        "dir_name",
        "dir_ip",
        "dir_category",
        "src_inter_ip",
        "loc",
        "is_streaming_flow",
        "is_svc_to_svc_flow",
        "collector_name",
        "flow_time",
        "s_is_known"
    ],
    [RuleType.ACCESS_FROM_PUBLIC_VPN]: [
        "d_hostname",
        "d_ip",
        "d_is_public",
        "d_mac",
        "d_name",
        "d_type",
        "d_port",
        "d_is_known",
        "d_protocol",
        "t_protocol",
        "src_countries",
        "s_hostname",
        "s_ip",
        "identity_status", "identity_groups", "id_attrs_change_timestamp",
        "s_is_cdn",
        "s_is_datacenter",
        "s_is_icloudrelday",
        "s_organization",
        "s_name",
        "s_type",
        "s_port",
        "s_is_known",
        "s_susp_external_scanner",
        "src_category",
        "dir_hostname",
        "dir_name",
        "dir_ip",
        "dir_category",
        "src_inter_ip",
        "loc",
        "is_streaming_flow",
        "is_svc_to_svc_flow",
        "collector_name",
        "flow_time"
    ],
    [RuleType.ACCESS_TO_PUBLIC_VPN]: [
        "dest_countries",
        "d_hostname",
        "d_ip",
        "d_is_cdn",
        "d_is_datacenter",
        "d_is_icloudrelday",
        "d_organization",
        "d_name",
        "d_type",
        "d_port",
        "d_is_known",
        "d_protocol",
        "t_protocol",
        "dst_category",
        "s_hostname",
        "s_ip",
        "identity_status", "identity_groups", "id_attrs_change_timestamp",
        "s_is_public",
        "s_mac",
        "s_name",
        "s_type",
        "s_port",
        "s_susp_internal_scanner",
        "dir_hostname",
        "dir_name",
        "dir_ip",
        "dir_category",
        "src_inter_ip",
        "loc",
        "is_streaming_flow",
        "is_svc_to_svc_flow",
        "collector_name",
        "flow_time"
    ],
    [RuleType.AUTH_HASH_QUALITY]: [
        "d_protocol",
        "t_protocol",
        "dir_hostname",
        "dir_name",
        "dir_ip",
        "dir_category",
        "loc",
        "collector_name",
        "flow_time"
    ],
    [RuleType.AUTH_HASH_SECURITY]: [
        "d_protocol",
        "t_protocol",
        "dir_hostname",
        "dir_name",
        "dir_ip",
        "dir_category",
        "loc",
        "collector_name",
        "flow_time"
    ],
    [RuleType.AUTH_PROTOCOL_QUALITY]: [
        "dest_countries",
        "d_hostname",
        "d_ip",
        "d_is_public",
        "d_is_cdn",
        "d_is_datacenter",
        "d_is_icloudrelday",
        "d_organization",
        "d_mac",
        "d_name",
        "d_type",
        "d_port",
        "d_is_known",
        "d_protocol",
        "t_protocol",
        "dst_category",
        "src_countries",
        "s_hostname",
        "s_ip",
        "identity_status", "identity_groups", "id_attrs_change_timestamp",
        "s_is_public",
        "s_is_cdn",
        "s_is_datacenter",
        "s_is_icloudrelday",
        "s_organization",
        "s_mac",
        "s_name",
        "s_type",
        "s_port",
        "s_is_known",
        "s_susp_internal_scanner",
        "s_susp_external_scanner",
        "src_category",
        "dir_hostname",
        "dir_name",
        "dir_ip",
        "dir_category",
        "src_inter_ip",
        "loc",
        "is_streaming_flow",
        "is_svc_to_svc_flow",
        "collector_name",
        "flow_time"
    ],
    [RuleType.COMPROMISED_PASSWORD]: [
        "d_protocol",
        "t_protocol",
        "s_name",
        "s_type",
        "dir_hostname",
        "dir_name",
        "dir_ip",
        "dir_category",
        "loc",
        "is_svc_to_svc_flow",
        "collector_name",
        "flow_time"
    ],
    [RuleType.COMPROMISED_USER]: [
        "d_protocol",
        "t_protocol",
        "s_name",
        "s_type",
        "dir_hostname",
        "dir_name",
        "dir_ip",
        "dir_category",
        "loc",
        "is_svc_to_svc_flow",
        "collector_name",
        "flow_time"
    ],
    [RuleType.DEVIATION_IN_DAILY_ASSET]: [
        "dest_countries",
        "d_hostname",
        "d_ip",
        "d_is_public",
        "d_is_cdn",
        "d_is_datacenter",
        "d_is_icloudrelday",
        "d_organization",
        "d_mac",
        "d_name",
        "d_type",
        "d_port",
        "d_is_known",
        "d_protocol",
        "t_protocol",
        "dst_category",
        "src_countries",
        "s_hostname",
        "s_ip",
        "identity_status", "identity_groups", "id_attrs_change_timestamp",
        "s_is_public",
        "s_is_cdn",
        "s_is_datacenter",
        "s_is_icloudrelday",
        "s_organization",
        "s_mac",
        "s_name",
        "s_type",
        "s_port",
        "s_is_known",
        "s_susp_internal_scanner",
        "s_susp_external_scanner",
        "src_category",
        "dir_hostname",
        "dir_name",
        "dir_ip",
        "dir_category",
        "src_inter_ip",
        "loc",
        "is_streaming_flow",
        "is_svc_to_svc_flow",
        "collector_name",
        "flow_time"
    ],
    [RuleType.ENUM_AD_ADMINS]: [
        "d_protocol",
        "t_protocol",
        "s_hostname",
        "s_ip",
        "identity_status", "identity_groups", "id_attrs_change_timestamp",
        "s_name",
        "s_type",
        "s_is_known",
        "s_susp_internal_scanner",
        "dir_hostname",
        "dir_name",
        "dir_ip",
        "src_inter_ip",
        "loc",
        "is_svc_to_svc_flow",
        "collector_name",
        "flow_time"
    ],
    [RuleType.ENUM_AD_USERS]: [
        "d_protocol",
        "t_protocol",
        "s_hostname",
        "s_ip",
        "identity_status", "identity_groups", "id_attrs_change_timestamp",
        "s_name",
        "s_type",
        "s_is_known",
        "s_susp_internal_scanner",
        "dir_hostname",
        "dir_name",
        "dir_ip",
        "src_inter_ip",
        "loc",
        "is_svc_to_svc_flow",
        "collector_name",
        "flow_time"
    ],
    [RuleType.EXPOSED_ASSETS]: [
        "d_hostname",
        "d_ip",
        "d_is_public",
        "d_mac",
        "d_name",
        "d_type",
        "d_port",
        "d_protocol",
        "t_protocol",
        "dst_category",
        "src_countries",
        "s_hostname",
        "s_ip",
        "identity_status", "identity_groups", "id_attrs_change_timestamp",
        "s_is_public",
        "s_is_cdn",
        "s_is_datacenter",
        "s_is_icloudrelday",
        "s_organization",
        "s_mac",
        "s_name",
        "s_type",
        "s_port",
        "s_is_known",
        "s_susp_external_scanner",
        "src_category",
        "dir_hostname",
        "dir_name",
        "dir_ip",
        "dir_category",
        "src_inter_ip",
        "loc",
        "is_streaming_flow",
        "is_svc_to_svc_flow",
        "collector_name",
        "flow_time"
    ],
    [RuleType.LACK_OF_MFA]: [
        "dest_countries",
        "d_hostname",
        "d_ip",
        "d_is_public",
        "d_is_cdn",
        "d_is_datacenter",
        "d_is_icloudrelday",
        "d_organization",
        "d_mac",
        "d_name",
        "d_type",
        "d_port",
        "d_is_known",
        "d_protocol",
        "t_protocol",
        "dst_category",
        "src_countries",
        "s_hostname",
        "s_ip",
        "identity_status", "identity_groups", "id_attrs_change_timestamp",
        "s_is_public",
        "s_is_cdn",
        "s_is_datacenter",
        "s_is_icloudrelday",
        "s_organization",
        "s_mac",
        "s_name",
        "s_type",
        "s_port",
        "s_is_known",
        "s_susp_internal_scanner",
        "s_susp_external_scanner",
        /*  "dir_hostname",
         "dir_name",
         "dir_ip",
         "dir_category", */
        "src_inter_ip",
        "loc",
        "is_streaming_flow",
        "is_svc_to_svc_flow",
        "collector_name",
        "flow_time",
        "inter_name", "inter_hostname"
    ],
    [RuleType.ASSETS_WITHOUT_MFA]: [
        "dest_countries",
        "d_hostname",
        "d_ip",
        "d_is_public",
        "d_is_cdn",
        "d_is_datacenter",
        "d_is_icloudrelday",
        "d_organization",
        "d_mac",
        "d_name",
        "d_type",
        "d_port",
        "d_is_known",
        "d_protocol",
        "t_protocol",
        "dst_category",
        "src_countries",
        "s_hostname",
        "s_ip",
        "identity_status", "identity_groups", "id_attrs_change_timestamp",
        "s_is_public",
        "s_is_cdn",
        "s_is_datacenter",
        "s_is_icloudrelday",
        "s_organization",
        "s_mac",
        "s_name",
        "s_type",
        "s_port",
        "s_is_known",
        "s_susp_internal_scanner",
        "s_susp_external_scanner",
        "dir_hostname",
        "dir_name",
        "dir_ip",
        "dir_category",
        "src_inter_ip",
        "loc",
        "is_streaming_flow",
        "is_svc_to_svc_flow",
        "collector_name",
        "flow_time",
        "inter_name", "inter_hostname"
    ],
    [RuleType.UNAUTH_LOGIN_TIME]: [
        "d_hostname",
        "d_ip",
        "d_is_public",
        "d_mac",
        "d_name",
        "d_type",
        "d_port",
        "d_is_known",
        "d_protocol",
        "t_protocol",
        "s_hostname",
        "s_ip",
        "identity_status", "identity_groups", "id_attrs_change_timestamp",
        "s_mac",
        "s_name",
        "s_type",
        "s_port",
        "s_is_known",
        "s_susp_internal_scanner",
        "dir_hostname",
        "dir_name",
        "dir_ip",
        "src_inter_ip",
        "loc",
        "is_svc_to_svc_flow",
        "collector_name",
        "flow_time"
    ],
    [RuleType.UNAUTH_LOGIN_DEVICE]: [
        "d_hostname",
        "d_ip",
        "d_is_public",
        "d_mac",
        "d_name",
        "d_type",
        "d_port",
        "d_is_known",
        "d_protocol",
        "t_protocol",
        "s_hostname",
        "s_ip",
        "identity_status", "identity_groups", "id_attrs_change_timestamp",
        "s_mac",
        "s_name",
        "s_type",
        "s_port",
        "s_is_known",
        "s_susp_internal_scanner",
        "dir_hostname",
        "dir_name",
        "dir_ip",
        "src_inter_ip",
        "loc",
        "is_svc_to_svc_flow",
        "collector_name",
        "flow_time"
    ],
    [RuleType.SHADOW_ACCESS]: [
        "dest_countries",
        "d_hostname",
        "d_ip",
        "d_is_public",
        "d_is_cdn",
        "d_is_datacenter",
        "d_is_icloudrelday",
        "d_organization",
        "d_mac",
        "d_name",
        "d_type",
        "d_port",
        "d_is_known",
        "d_protocol",
        "t_protocol",
        "src_countries",
        "s_hostname",
        "s_ip",
        "identity_status", "identity_groups", "id_attrs_change_timestamp",
        "s_is_public",
        "s_is_cdn",
        "s_is_datacenter",
        "s_is_icloudrelday",
        "s_organization",
        "s_mac",
        "s_name",
        "s_type",
        "s_port",
        "s_is_known",
        "s_susp_internal_scanner",
        "s_susp_external_scanner",
        "src_category",
        "dir_hostname",
        "dir_name",
        "dir_ip",
        "dir_category",
        "src_inter_ip",
        "loc",
        "is_streaming_flow",
        "is_svc_to_svc_flow",
        "collector_name",
        "flow_time"
    ],
    [RuleType.SHADOW_ASSETS]: [
        "dest_countries",
        "d_hostname",
        "d_ip",
        "d_is_public",
        "d_is_cdn",
        "d_is_datacenter",
        "d_is_icloudrelday",
        "d_organization",
        "d_mac",
        "d_name",
        "d_type",
        "d_port",
        "d_is_known",
        "d_protocol",
        "t_protocol",
        "src_countries",
        "s_hostname",
        "s_ip",
        "identity_status", "identity_groups", "id_attrs_change_timestamp",
        "s_is_public",
        "s_is_cdn",
        "s_is_datacenter",
        "s_is_icloudrelday",
        "s_organization",
        "s_mac",
        "s_name",
        "s_type",
        "s_port",
        "s_is_known",
        "s_susp_internal_scanner",
        "s_susp_external_scanner",
        "src_category",
        "dir_hostname",
        "dir_name",
        "dir_ip",
        "dir_category",
        "src_inter_ip",
        "loc",
        "is_streaming_flow",
        "is_svc_to_svc_flow",
        "collector_name",
        "flow_time"
    ],
    [RuleType.SHADOW_DIRECTORY]: [
        "d_hostname",
        "d_ip",
        "d_is_public",
        "d_is_cdn",
        "d_is_datacenter",
        "d_is_icloudrelday",
        "d_organization",
        "d_mac",
        "d_name",
        "d_type",
        "d_port",
        "d_is_known",
        "d_protocol",
        "t_protocol",
        "dst_category",
        "src_countries",
        "s_hostname",
        "s_ip",
        "identity_status", "identity_groups", "id_attrs_change_timestamp",
        "s_is_public",
        "s_is_cdn",
        "s_is_datacenter",
        "s_is_icloudrelday",
        "s_organization",
        "s_mac",
        "s_name",
        "s_type",
        "s_port",
        "s_is_known",
        "s_susp_internal_scanner",
        "dir_hostname",
        "dir_name",
        "dir_ip",
        "intermediary_hostname",
        "intermediary_name",
        "intermediary_ip",
        "dir_category",
        "loc",
        "is_svc_to_svc_flow",
        "collector_name",
        "flow_time"
    ],
    [RuleType.SHADOW_EXTERNAL_ACCESS]: [
        "dest_countries",
        "d_hostname",
        "d_ip",
        "d_is_public",
        "d_is_cdn",
        "d_is_datacenter",
        "d_is_icloudrelday",
        "d_organization",
        "d_mac",
        "d_name",
        "d_type",
        "d_port",
        "d_is_known",
        "d_protocol",
        "t_protocol",
        "dst_category",
        "s_hostname",
        "s_ip",
        "identity_status", "identity_groups", "id_attrs_change_timestamp",
        "s_name",
        "s_type",
        "s_port",
        "s_is_known",
        "s_susp_internal_scanner",
        "dir_hostname",
        "dir_name",
        "dir_ip",
        "dir_category",
        "src_inter_ip",
        "loc",
        "is_streaming_flow",
        "is_svc_to_svc_flow",
        "collector_name",
        "flow_time"
    ],
    [RuleType.NTLM_RELAY_ATTACK]: [
        "d_hostname",
        "d_ip",
        "d_name",
        "d_type",
        "d_port",
        "d_is_known",
        "d_protocol",
        "t_protocol",
        "s_hostname",
        "s_ip",
        "identity_status", "identity_groups", "id_attrs_change_timestamp",
        "s_is_public",
        "s_mac",
        "s_name",
        "s_type",
        "s_port",
        "s_is_known",
        "s_susp_internal_scanner",
        "dir_hostname",
        "dir_name",
        "dir_ip",
        "src_inter_ip",
        "loc",
        "is_svc_to_svc_flow",
        "collector_name",
        "flow_time"
    ],
    [RuleType.SUSPECTED_ATT_DIS_ACC]: [
        "d_hostname",
        "d_ip",
        "d_name",
        "d_type",
        "d_port",
        "d_is_known",
        "d_protocol",
        "t_protocol",
        "s_hostname",
        "s_ip",
        "identity_status", "identity_groups", "id_attrs_change_timestamp",
        "s_is_public",
        "s_mac",
        "s_name",
        "s_type",
        "s_port",
        "s_is_known",
        "s_susp_internal_scanner",
        "dir_hostname",
        "dir_name",
        "dir_ip",
        "src_inter_ip",
        "loc",
        "is_svc_to_svc_flow",
        "collector_name",
        "flow_time"
    ],
    [RuleType.SUSPECTED_ATT_EXP_ACC]: [
        "d_hostname",
        "d_ip",
        "d_name",
        "d_type",
        "d_port",
        "d_is_known",
        "d_protocol",
        "t_protocol",
        "s_hostname",
        "s_ip",
        "identity_status", "identity_groups", "id_attrs_change_timestamp",
        "s_is_public",
        "s_mac",
        "s_name",
        "s_type",
        "s_port",
        "s_is_known",
        "s_susp_internal_scanner",
        "dir_hostname",
        "dir_name",
        "dir_ip",
        "src_inter_ip",
        "loc",
        "is_svc_to_svc_flow",
        "collector_name",
        "flow_time"
    ],
    [RuleType.SUSPECTED_ATT_LOCK_ACC]: [
        "d_hostname",
        "d_ip",
        "d_name",
        "d_type",
        "d_port",
        "d_is_known",
        "d_protocol",
        "t_protocol",
        "s_hostname",
        "s_ip",
        "identity_status", "identity_groups", "id_attrs_change_timestamp",
        "s_is_public",
        "s_mac",
        "s_name",
        "s_type",
        "s_port",
        "s_is_known",
        "s_susp_internal_scanner",
        "dir_hostname",
        "dir_name",
        "dir_ip",
        "src_inter_ip",
        "loc",
        "is_svc_to_svc_flow",
        "collector_name",
        "flow_time"
    ],
    [RuleType.SUSPECTED_DIR_BOT]: [
        "dest_countries",
        "d_hostname",
        "d_ip",
        "d_is_public",
        "d_is_cdn",
        "d_is_datacenter",
        "d_is_icloudrelday",
        "d_organization",
        "d_mac",
        "d_name",
        "d_type",
        "d_port",
        "d_is_known",
        "d_protocol",
        "t_protocol",
        "src_countries",
        "s_hostname",
        "s_ip",
        "identity_status", "identity_groups", "id_attrs_change_timestamp",
        "s_is_public",
        "s_is_cdn",
        "s_is_datacenter",
        "s_is_icloudrelday",
        "s_organization",
        "s_mac",
        "s_name",
        "s_type",
        "s_port",
        "s_is_known",
        "s_susp_internal_scanner",
        "s_susp_external_scanner",
        "src_category",
        "dir_hostname",
        "dir_name",
        "dir_ip",
        "dir_category",
        "src_inter_ip",
        "loc",
        "is_streaming_flow",
        "is_svc_to_svc_flow",
        "collector_name",
        "flow_time"
    ],
    [RuleType.SUSPECTED_BRUTE_FORCE]: [
        "dest_countries",
        "d_hostname",
        "d_ip",
        "d_is_public",
        "d_is_cdn",
        "d_is_datacenter",
        "d_is_icloudrelday",
        "d_organization",
        "d_mac",
        "d_name",
        "d_type",
        "d_port",
        "d_is_known",
        "d_protocol",
        "t_protocol",
        "src_countries",
        "s_hostname",
        "s_ip",
        "identity_status", "identity_groups", "id_attrs_change_timestamp",
        "s_is_public",
        "s_is_cdn",
        "s_is_datacenter",
        "s_is_icloudrelday",
        "s_organization",
        "s_mac",
        "s_name",
        "s_type",
        "s_port",
        "s_is_known",
        "s_susp_internal_scanner",
        "s_susp_external_scanner",
        "src_category",
        "dir_hostname",
        "dir_name",
        "dir_ip",
        "dir_category",
        "src_inter_ip",
        "loc",
        "is_streaming_flow",
        "is_svc_to_svc_flow",
        "collector_name",
        "flow_time"
    ],
    [RuleType.SUSPECTED_AD_BRUTE_FORCE]: [
        "dest_countries",
        "d_hostname",
        "d_ip",
        "d_is_public",
        "d_is_cdn",
        "d_is_datacenter",
        "d_is_icloudrelday",
        "d_organization",
        "d_mac",
        "d_name",
        "d_type",
        "d_port",
        "d_is_known",
        "d_protocol",
        "t_protocol",
        "src_countries",
        "s_hostname",
        "s_ip",
        "identity_status", "identity_groups", "id_attrs_change_timestamp",
        "s_is_public",
        "s_is_cdn",
        "s_is_datacenter",
        "s_is_icloudrelday",
        "s_organization",
        "s_mac",
        "s_name",
        "s_type",
        "s_port",
        "s_is_known",
        "s_susp_internal_scanner",
        "s_susp_external_scanner",
        "src_category",
        "dir_hostname",
        "dir_name",
        "dir_ip",
        "dir_category",
        "src_inter_ip",
        "loc",
        "is_streaming_flow",
        "is_svc_to_svc_flow",
        "collector_name",
        "flow_time"
    ],
    [RuleType.SUSPECTED_IDP_BRUTE_FORCE]: [
        "dest_countries",
        "d_hostname",
        "d_ip",
        "d_is_public",
        "d_is_cdn",
        "d_is_datacenter",
        "d_is_icloudrelday",
        "d_organization",
        "d_mac",
        "d_name",
        "d_type",
        "d_port",
        "d_is_known",
        "d_protocol",
        "t_protocol",
        "src_countries",
        "s_hostname",
        "s_ip",
        "identity_status", "identity_groups", "id_attrs_change_timestamp",
        "s_is_public",
        "s_is_cdn",
        "s_is_datacenter",
        "s_is_icloudrelday",
        "s_organization",
        "s_mac",
        "s_name",
        "s_type",
        "s_port",
        "s_is_known",
        "s_susp_internal_scanner",
        "s_susp_external_scanner",
        "src_category",
        "dir_hostname",
        "dir_name",
        "dir_ip",
        "dir_category",
        "src_inter_ip",
        "loc",
        "is_streaming_flow",
        "is_svc_to_svc_flow",
        "collector_name",
        "flow_time"
    ],
    [RuleType.SUSPECTED_PASS_SPRAY]: [
        "dest_countries",
        "d_hostname",
        "d_ip",
        "d_is_public",
        "d_is_cdn",
        "d_is_datacenter",
        "d_is_icloudrelday",
        "d_organization",
        "d_mac",
        "d_name",
        "d_type",
        "d_port",
        "d_is_known",
        "d_protocol",
        "t_protocol",
        "src_countries",
        "s_hostname",
        "s_ip",
        "identity_status", "identity_groups", "id_attrs_change_timestamp",
        "s_is_public",
        "s_is_cdn",
        "s_is_datacenter",
        "s_is_icloudrelday",
        "s_organization",
        "s_mac",
        "s_name",
        "s_type",
        "s_port",
        "s_is_known",
        "s_susp_internal_scanner",
        "s_susp_external_scanner",
        "src_category",
        "dir_hostname",
        "dir_name",
        "dir_ip",
        "dir_category",
        "src_inter_ip",
        "loc",
        "is_streaming_flow",
        "is_svc_to_svc_flow",
        "collector_name",
        "flow_time"
    ],
    [RuleType.SUSPICIOUS_INBOUND_ACCESS]: [
        "d_hostname",
        "d_ip",
        "d_mac",
        "d_name",
        "d_type",
        "d_port",
        "d_is_known",
        "d_protocol",
        "t_protocol",
        "src_countries",
        "s_hostname",
        "s_ip",
        "identity_status", "identity_groups", "id_attrs_change_timestamp",
        "s_is_public",
        "s_is_cdn",
        "s_is_datacenter",
        "s_is_icloudrelday",
        "s_organization",
        "s_name",
        "s_type",
        "s_port",
        "s_is_known",
        "s_susp_external_scanner",
        "src_category",
        "src_inter_ip",
        "loc",
        "is_streaming_flow",
        "is_svc_to_svc_flow",
        "collector_name",
        "flow_time"
    ],
    [RuleType.SUSPICIOUS_OUTBOUND_ACCESS]: [
        "dest_countries",
        "d_hostname",
        "d_ip",
        "d_is_public",
        "d_is_cdn",
        "d_is_datacenter",
        "d_is_icloudrelday",
        "d_organization",
        "d_mac",
        "d_name",
        "d_type",
        "d_port",
        "d_is_known",
        "d_protocol",
        "t_protocol",
        "dst_category",
        "s_hostname",
        "s_ip",
        "identity_status", "identity_groups", "id_attrs_change_timestamp",
        "s_mac",
        "s_name",
        "s_type",
        "s_port",
        "s_is_known",
        "s_susp_internal_scanner",
        "src_inter_ip",
        "loc",
        "is_streaming_flow",
        "is_svc_to_svc_flow",
        "collector_name",
        "flow_time"
    ],
    [RuleType.UNAUTHORIZED_ASSET_ACCESS]: [
        "dest_countries",
        "d_hostname",
        "d_ip",
        "d_is_public",
        "d_is_cdn",
        "d_is_datacenter",
        "d_is_icloudrelday",
        "d_organization",
        "d_mac",
        "d_name",
        "d_type",
        "d_port",
        "d_is_known",
        "d_protocol",
        "t_protocol",
        "dst_category",
        "src_countries",
        "s_hostname",
        "s_ip",
        "identity_status", "identity_groups", "id_attrs_change_timestamp",
        "s_is_public",
        "s_is_cdn",
        "s_is_datacenter",
        "s_is_icloudrelday",
        "s_organization",
        "s_mac",
        "s_name",
        "s_type",
        "s_port",
        "s_is_known",
        "s_susp_internal_scanner",
        "s_susp_external_scanner",
        "src_category",
        "dir_hostname",
        "dir_name",
        "dir_ip",
        "dir_category",
        "src_inter_ip",
        "inter_hostname",
        "inter_name",
        "loc",
        "is_streaming_flow",
        "is_svc_to_svc_flow",
        "collector_name",
        "flow_time"
    ],
    [RuleType.UNAUTHORIZED_IDENTITY_ACCESS]: [
        "dest_countries",
        "d_hostname",
        "d_ip",
        "d_is_public",
        "d_is_cdn",
        "d_is_datacenter",
        "d_is_icloudrelday",
        "d_organization",
        "d_mac",
        "d_name",
        "d_type",
        "d_port",
        "d_is_known",
        "d_protocol",
        "t_protocol",
        "dst_category",
        "src_countries",
        "s_hostname",
        "s_ip",
        "identity_status", "identity_groups", "id_attrs_change_timestamp",
        "s_is_public",
        "s_is_cdn",
        "s_is_datacenter",
        "s_is_icloudrelday",
        "s_organization",
        "s_mac",
        "s_name",
        "s_type",
        "s_port",
        "s_is_known",
        "s_susp_internal_scanner",
        "s_susp_external_scanner",
        "src_category",
        "dir_hostname",
        "dir_name",
        "dir_ip",
        "dir_category",
        "src_inter_ip",
        "inter_hostname",
        "inter_name",
        "loc",
        "is_streaming_flow",
        "is_svc_to_svc_flow",
        "collector_name",
        "flow_time"
    ],
    [RuleType.UNKNOWN_ACCESS]: [
        "dest_countries",
        "d_hostname",
        "d_ip",
        "d_is_cdn",
        "d_is_datacenter",
        "d_is_icloudrelday",
        "d_organization",
        "d_name",
        "d_type",
        "d_port",
        "d_protocol",
        "t_protocol",
        "dst_category",
        "src_countries",
        "s_hostname",
        "s_ip",
        "identity_status", "identity_groups", "id_attrs_change_timestamp",
        "s_is_cdn",
        "s_is_datacenter",
        "s_is_icloudrelday",
        "s_organization",
        "s_mac",
        "s_name",
        "s_type",
        "s_port",
        "s_is_known",
        "s_susp_external_scanner",
        "src_category",
        "src_inter_ip",
        "loc",
        "is_streaming_flow",
        "is_svc_to_svc_flow",
        "collector_name",
        "flow_time"
    ],
    [RuleType.UNKNOWN_SAAS_ACCESS]: [
        "dest_countries",
        "d_hostname",
        "d_ip",
        "d_name",
        "d_type",
        "d_port",
        "d_is_known",
        "d_protocol",
        "t_protocol",
        "dst_category",
        "s_hostname",
        "s_ip",
        "identity_status", "identity_groups", "id_attrs_change_timestamp",
        "s_is_public",
        "s_mac",
        "s_name",
        "s_type",
        "s_port",
        "s_is_known",
        "s_susp_internal_scanner",
        "dir_hostname",
        "dir_name",
        "dir_ip",
        "dir_category",
        "src_inter_ip",
        "loc",
        "is_streaming_flow",
        "is_svc_to_svc_flow",
        "collector_name",
        "flow_time"
    ],
    [RuleType.WEAK_PASSWORD]: [
        "d_protocol",
        "t_protocol",
        "s_name",
        "s_type",
        'identity_status', 'identity_groups', 'id_attrs_change_timestamp',
        "dir_hostname",
        "dir_name",
        "dir_ip",
        "dir_category",
        "loc",
        "is_svc_to_svc_flow",
        "collector_name",
        "flow_time"
    ],
    [RuleType.ACCOUNT_TAKEOVER]: [
        "d_hostname", "d_ip", 'd_mac', 'd_name', 'd_type', 'd_port', 't_protocol', 'd_protocol',
        'src_countries', 's_hostname', 's_ip', 'identity_status', 'identity_groups', 'id_attrs_change_timestamp', 's_is_cdn', 's_is_datacenter',
        's_organization', 's_mac', 's_name', 's_port', 's_is_known',
        's_susp_internal_scanner', 's_susp_external_scanner', 'src_category', 'src_inter_ip', 'loc', 'flow_time',
        'collector_name',
    ],
    [RuleType.DEVIATION_IDENTITY_ACTIVITY]: [
        "d_hostname", "d_ip", 'd_mac', 'd_name', 'd_type', 'd_port', 't_protocol', 'd_protocol',
        'src_countries', 's_hostname', 's_ip', 's_is_cdn', 's_is_datacenter',
        's_organization', 's_mac', 's_name', 's_port', 's_is_known',
        's_susp_internal_scanner', 's_susp_external_scanner', 'src_category', 'src_inter_ip', 'loc', 'flow_time',
        'collector_name',
    ],
    [RuleType.UNUSUAL_USER_ACCESS]: [
        "d_hostname", "d_ip", 'd_mac', 'd_name', 'd_type', 'd_port', 't_protocol', 'd_protocol',
        'src_countries', 's_hostname', 's_ip', 's_is_cdn', 's_is_datacenter',
        's_organization', 's_mac', 's_name', 's_port', 's_is_known',
        's_susp_internal_scanner', 's_susp_external_scanner', 'src_category', 'src_inter_ip', 'loc', 'flow_time',
        'collector_name',
    ],
    [RuleType.SUSPECTED_ACCESS_TOKEN]: [
        'destination_asset', 'd_hostname', 'd_is_public', "d_ip", "dest_countries",
        'd_organization', 'd_name', 'd_type', 'd_port', 'd_is_known',
        'd_protocol', 't_protocol', 'dst_category', 'src_countries',
        's_hostname', 's_ip', 'identity_status', 'identity_groups', 'id_attrs_change_timestamp', 's_is_public', 's_organization', 's_mac',
        's_name', 's_type', 's_is_known', 'loc'
    ],
    [RuleType.AD_GOLDEN_TICKET]: [
        "d_protocol",
        "t_protocol",
        "s_hostname",
        "s_ip",
        "s_name",
        "s_type",
        "s_is_known",
        "identity_status", "identity_groups", "id_attrs_change_timestamp",
        "s_susp_internal_scanner",
        "dir_hostname",
        "dir_name",
        "dir_ip",
        "src_inter_ip",
        "loc",
        "is_svc_to_svc_flow",
        "collector_name",
        "flow_time"
    ],
    [RuleType.AD_PASS_THE_TICKET]: [
        "d_protocol",
        "t_protocol",
        "s_hostname",
        "s_ip",
        "s_name",
        "s_type",
        "s_is_known",
        "identity_status", "identity_groups", "id_attrs_change_timestamp",
        "s_susp_internal_scanner",
        "dir_hostname",
        "dir_name",
        "dir_ip",
        "src_inter_ip",
        "loc",
        "is_svc_to_svc_flow",
        "collector_name",
        "flow_time"
    ],
}