import React, { useEffect, useReducer } from 'react';
import { useTimeBasedSearchReducer } from './TimeBasedSearchReducer';
import TimeBasedSearchDockingComponent from './components/TimeBasedSearchDockingComponent';
import {
    TimeBasedSearchDispatchContextProvider,
    TimeBasedSearchPropsContextProvider,
    TimeBasedSearchStateContextProvider
} from './useTimeBasedSearch';
import './TimeBasedSearchStyle.scss';
import ACTION_TYPES from './TimeBasedSearchActionTypes';
import { PERIOD_OPTIONS, TIME_RANGE_OPERATORS } from './Constants';
import { dateToEpoch } from './utils';
import moment from 'moment';

const TimeBasedSearchContainer = (props) => {

    const isEnabledRaw = localStorage.getItem("TIME_BASED_SEARCH_FEATURE");
    let isEnabled = false;
    if (isEnabledRaw) {
        isEnabled = JSON.parse(isEnabledRaw);
    }

    const { TimeBasedSearchReducer, initialStore } = useTimeBasedSearchReducer();
    const [store, dispatchAction] = useReducer(
        TimeBasedSearchReducer,
        initialStore
    );

    useEffect(() => {
        // console.log({props})
        if (props.filters && props.filters.length > 0 && !props.forAdvancedSearch) {
            setTimeout(() => {
                dispatchAction({ type: ACTION_TYPES.UPDATE_FROM_PROPS, value: props.filters })
            }, 50)
        }
        if (props.filters && props.filters.length === 0 && (!props.forAdvancedSearch || props.isClearFilter)) {
            dispatchAction({ type: ACTION_TYPES.CLEAR_FILTERS, value: store });
        }
    }, [props.filters])

       const prepareData = (data) => {
            let res = {};
            if (data['selectedPeriod'] === PERIOD_OPTIONS.PRESETS.id) {
                res = {
                    any_activity_time: {
                        type: TIME_RANGE_OPERATORS.BETWEEN.id,
                        value: data['selectedPreset']
                    }
                };
            } else if (data['selectedPeriod'] === PERIOD_OPTIONS.HISTOGRAM.id) {
                var startTime = data['selectedStartDateHistogram'];
                var endTime = data['selectedEndDateHistogram'];
                res = {
                    any_activity_time: {
                        type: TIME_RANGE_OPERATORS.BETWEEN.id,
                        value: startTime.toString().endsWith('000') && endTime.toString().endsWith('000') ? [
                            `${dateToEpoch(data['selectedStartDateHistogram'])}_H`,
                            `${dateToEpoch(data['selectedEndDateHistogram'])}_H`
                        ] : [
                            `${(data['selectedStartDateHistogram'])}_H`,
                            `${(data['selectedEndDateHistogram'])}_H`
                        ]
                    }
                };
            } else {
                if (data['selectedTimeRange'] === TIME_RANGE_OPERATORS.BETWEEN.id) {
                    res = {
                        any_activity_time: {
                            type: TIME_RANGE_OPERATORS.BETWEEN.id,
                            value: [
                                dateToEpoch(data['selectedStartDate']),
                                dateToEpoch(data['selectedEndDate'])
                            ]
                        }
                    };
                } else if (
                    data['selectedTimeRange'] === TIME_RANGE_OPERATORS.EQUALS.id
                ) {
                    let startDate = dateToEpoch(
                        moment(data['selectedStartDate']).startOf('day')
                    );
                    let endDate = dateToEpoch(
                        moment(data['selectedStartDate']).endOf('day')
                    );
                    res = {
                        any_activity_time: {
                            type: TIME_RANGE_OPERATORS.EQUALS.id,
                            value: [startDate, endDate]
                        }
                    };
                } else if (
                    data['selectedTimeRange'] === TIME_RANGE_OPERATORS.AFTER.id
                ) {
                    let startDate = dateToEpoch(moment(data['selectedStartDate']));
                    let endDate = dateToEpoch(moment(data['selectedStartDate']).add(15, 'days').diff(moment(), 'days') > 0 ? moment() : moment(data['selectedStartDate']).add(15, 'days'))
                    
                    res = {
                        any_activity_time: {
                            type: TIME_RANGE_OPERATORS.AFTER.id,
                            value: [startDate, endDate]
                        }
                    };
                }
            }
            return res;
        };
    

    useEffect(() => {
        // console.log({props})
        if (props.filters && props.filters.length > 0 && props.forAdvancedSearch) {
            const updatedFilters = props.filters.map(filter => {
                if (filter && filter[0] === "any_activity_time") {
                    const data = prepareData(store);
                    if(store.selectedPeriod === PERIOD_OPTIONS.PRESETS.id){
                    return ["any_activity_time", store.selectedPreset, store.selectedTimeRange];
                    } else if (store.selectedPeriod === PERIOD_OPTIONS.CUSTOM_RANGE.id){
                        return ["any_activity_time", data['any_activity_time'].value?.join(), store.selectedTimeRange];
                    } else {
                        if(store.selectedPeriod === PERIOD_OPTIONS.HISTOGRAM.id){
                         return filter;
                        }
                    }
                }
                return filter;
            });
        
            setTimeout(() => {
                dispatchAction({ type: ACTION_TYPES.UPDATE_FROM_PROPS, value: updatedFilters });
            }, 50);
        }
        // if (props.filters && props.filters.length === 0) {
        //     dispatchAction({ type: ACTION_TYPES.CLEAR_FILTERS, value: store });
        // }
    }, [props.filters])

    return (
        <>
            {
                isEnabled && <div className='time-based-search-container'>
                    <TimeBasedSearchPropsContextProvider value={props}>
                        <TimeBasedSearchDispatchContextProvider value={dispatchAction}>
                            <TimeBasedSearchStateContextProvider value={store}>
                                <TimeBasedSearchDockingComponent></TimeBasedSearchDockingComponent>
                            </TimeBasedSearchStateContextProvider>
                        </TimeBasedSearchDispatchContextProvider>
                    </TimeBasedSearchPropsContextProvider>
                </div>
            }
        </>
    );
};

export default TimeBasedSearchContainer;
