import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useToasts } from '../../../components/core';

import AMTagInput from '../../../components/core/AMTagInput/AMTagInput';
import 'react-tagsinput/react-tagsinput.css'

import { Api } from '../../../components/Axios'
import { AMCIDRHelpText } from '../../ref/AMCIDRHelpText';
import './edit-intermediary.scss';

type Directory = {
    name?: string;
    fqdn?: string;
    ips?: [];
    type?: string;
}

interface Props {
    id?: string;
    AfterSensorOperation?: any;
}

export const EditPanel = ({ id, AfterSensorOperation }: Props) => {
    const { register, handleSubmit, errors, setValue } = useForm<Directory>()
    const [loading, setLoading] = useState<Boolean>(false)
    const [directory, setDirectory] = useState<Directory>()
    const [ips, setIps] = useState([])
    const { addToast } = useToasts()

    const onUpdateGroup = (data: any) => {
        setLoading(true)
        data['ips'] = ips
        const headers = { 'Operation': 'PUT' }
        Api.post('/intermediary/' + id, data, { headers: headers })
            .then((res: { data: any }) => {
                setLoading(false)
                AfterSensorOperation('edit')
                addToast("Updated successfully.", {
                    appearance: 'success',
                    autoDismiss: true,
                })
            })
            .catch((error: any) => {
                setLoading(false)
                if (error.response.data) {
                    addToast(error.response.data, {
                        appearance: 'error',
                        autoDismiss: true,
                    })
                } else if (error.response.status === 500) {
                    addToast("Sorry, something went wrong there, try again.", {
                        appearance: 'error',
                        autoDismiss: true,
                    })
                } else if (error.response.status === 419) {
                    addToast("We encounted validation problem, please correct and try again.", {
                        appearance: 'error',
                        autoDismiss: true,
                    })
                } else if (error.response.status === 404) {
                    addToast("Please check and try again.", {
                        appearance: 'error',
                        autoDismiss: true,
                    })
                }
            })
    }

    useEffect(() => {
        Api.get('/intermediary/' + id)
            .then((res: { data: any }) => {
                setValue("name", res.data.name)
                setValue("fqdn", res.data.fqdn)
                setValue("type", res.data.type)
                if (res.data.ips) {
                    setIps(res.data.ips)
                }
            })
            .catch((error: any) => {
            })
    }, [])

    const AddTags = (tag: any) => {
        const tempTags = tag.filter((item: string) => item && item.trim().length > 0);
        setIps(tempTags)
    }

    return (
        <form onSubmit={handleSubmit(onUpdateGroup)}>
            <div className="add-intermediary-form-container">
                <div className="form-control-group-left">
                    <div className="form-control">
                        <label>Name</label>
                        <input
                            type="text"
                            placeholder="Enter name"
                            name="name"
                            ref={register({
                                required: true,
                                pattern:/^[^'"]*$/
                            })}
                            className={(errors.name ? "error" : "")}
                        />
                    </div>
                    <div className="form-control">
                        <label className='margin-10' style={{display: 'flex'}}>IP Addresses/Host Name/FQDNs<AMCIDRHelpText/></label>
                        <AMTagInput
                            value={ips}
                            onChange={AddTags}
                            renderLayout={(tagElements, inputElement) => {
                                return (
                                    <span>
                                        <div
                                            className="scrollbar-container directory-tag-container">
                                            {tagElements}
                                        </div>
                                        <div>
                                        {inputElement}
                                        </div>
                                    </span>
                                )
                            }}
                            inputProps={{ placeholder: "Add IP address, etc." }}
                            // validationRegex={(/^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\-][^'"]*[a-zA-Z0-9][^'"])\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9\-]*[A-Za-z0-9])*([/0-9]*)$/)}
                            validationRegex={(/^(([\*]|[a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\-][^'"]*[a-zA-Z0-9]*[^'"])\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9\-]*[A-Za-z0-9])*([/0-9]*)$/)}
                        />
                        <div className="marginleft40per error">Note: Hit the Enter/Return, Tab, Space, or Comma key after entering or copy-pasting one or more IPs (separated by comma, space, or newline).</div> 
                    </div>
                </div>
            </div>
            
            { (Object.keys(errors || {}).length > 0) && (
                <div className="add-intermediary-errors-list-container">
                        {errors.name && errors.name.type === 'required' && <div className="error dot marginleft40per">Please enter name.</div>}
                        {errors.name && errors.name.type !== 'required' && <div className={`error marginleft40per ${errors.name.message && "dot"}`}>{errors.name.message}</div>}
                        {errors.name && errors.name.type === 'pattern' && <div className="error dot marginleft40per">Invalid name.</div>}
                </div>
            )}
             <div className="add-edit-action-buttons dialog-button">
                <button type={loading ? "button" : "submit"} className={"float_right " + (loading ? "loader" : "add-edit-submit")}>
                    Save
                </button>
            </div>
        </form>
    )
}