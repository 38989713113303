import React from "react";
import { Chip } from '@mui/material';
import { PlaybookTableActions } from "../../../playbooks/ref/AdvancedPlaybook/PlaybookTableActions";
import Tags from "../tags/tags";
import { getTagsForSearchInput } from "../../../../components/core/PostureFilter/posture-filter-utils";
import { TIME_BASED_SEARCH_FIELD } from "../../../../components/TimeBasedSearch/Constants";
import './SearchBox.scss'

export const SearchBox = ({ reloadRef, reloadEntity, lastUpdate, showOudatedData,
    MoreActionsOptions, selectCount, showAdvanceSearch, isTimeBasedSearchApplied,
    onAdvanceSearchClick, handleLensSearch, tags, deleteTag, searchInput, handleSearchChange, handleSearch, setTags, setTagFlag
}) => {
    return <div className="ad-search-box-container">
        <div className="reload_container">
            <div ref={reloadRef} className="reload_color_icon" onClick={reloadEntity}></div>
        </div>
        <div className="table_title">Last Updated: {lastUpdate}</div>
        {showOudatedData ? <Chip label="Data could be outdated, reload to update" color="error" style={{ float: "left" }} className='outdated-data-msg' /> : <></>}
        {/* <div className="search_white_icon">&nbsp;</div> */}
        {/* <input type="text" className="table_search clear_search" onKeyUp={handleSearch} placeholder="Searched item" title=""/> */}
        <div className="search_flex">
            {/* <div className={(disabledFilter ? "filter_disabled" : filterStatus ? "filter_button" : "filter_button_grey") + (!enableFilter ? ' disableItems' : '')} onClick={() => handleFilterWidget(true)}></div> */}
            <PlaybookTableActions actions={MoreActionsOptions} rowData={{}}
                isDisabled={selectCount === 0 || showAdvanceSearch || isTimeBasedSearchApplied} id='issue-more-action-btn-grp'
            />
            <div className='issue-advance-search-btn-con'><button onClick={onAdvanceSearchClick} className={'button_styled issue-advance-search'} >Advanced Search</button></div>
            <div className="search_container issue_page scrollbar-container">
                <div className="search_white_icon flex_none" style={{ cursor: 'pointer' }}
                    onClick={handleLensSearch}
                >&nbsp;</div>&nbsp;
                <Tags tags={getTagsForSearchInput(tags)} deleteTag={deleteTag} />&nbsp;
                <input
                    className="search_input flex_none"
                    value={searchInput}
                    placeholder="Search..."
                    onKeyDown={handleSearch}
                    onChange={handleSearchChange}
                />
                {getTagsForSearchInput(tags).length > 0 ? <span style={{ cursor: 'pointer' }} onClick={() => {
                    setTagFlag(true);
                    if (isTimeBasedSearchApplied) {
                        const tempTags = tags.filter(item => item.indexOf(TIME_BASED_SEARCH_FIELD) > -1);
                        setTags(tempTags);
                    } else {
                        setTags([])
                    }
                }}>&nbsp;X</span> : null}
            </div>
        </div>
    </div>
}

