import React, { useState, useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form'
import {
    withQueryParams,
    StringParam,
    NumberParam,
    ArrayParam,
    withDefault,
} from 'use-query-params'
import { useAuthDetails } from '../../components/Authorization'
import { useHistory, Link } from "react-router-dom"
import SlidingPanel, { PanelType } from 'react-sliding-side-panel'
import 'react-sliding-side-panel/lib/index.css'
import { Api } from '../../components/Axios'
import Moment from 'moment'
import { AddPanel, EditPanel, ResetPassword, GroupPanel } from './sub'
import ReactPaginate from 'react-paginate'
import { useToasts } from '../../components/core';
import { ActionMenu } from '../../theme/ActionMenu'
import Popup from 'reactjs-popup';
import InfoIcon from '@mui/icons-material/Info';
import { UserRoleDisplay } from './sub/UserRoleDisplay';

import './listing.css'
import ModalPopup from '../../components/core/AMModalPopup/ModalPopup'
import { ColDef } from '@ag-grid-community/core'
import { formatDate, getResolutionMatchFor1920 } from '../../utils/util-methods'
import { ITableAction } from '../../components/core/AMTable/AMAdminTable'
import BaseAMAdminGrid from '../../components/core/AMTable/BaseAMAdminGrid'
import { useBaseAMGrid } from '../../components/core/AMTable/useBaseAMGrid'
import CustomHeader from '../../components/core/AMTable/CustomHeader'

type Search = {
    query?: string;
}

type Listing = {
    _id: string;
    name: string;
    email: string;
    role: string;
    last_login: string;
    ip: string;
}

type Result = {
    total: number;
    result: Listing[];
    rpp: number;
    sort_by: string;
    order_by: string;
}

type ActionType = {
    type?: string;
    id?: string;
}

export interface Params {
    rpp?: number;
    sort_by?: string;
    order_by?: string;
    q?: string;
    page?: number;
    type?: string;
}

type SSOList = {
    enable_sso?: boolean;
    name?: string;
    portal_fqdn?: [];
    entity_id?: string;
    metadata_url?: string;
    metadata_xml?: string;
    sso_login_url?: string;
    certificate?: string;
    private_key?: string;
    company_id?: string;
    root_sso?: boolean
}

const UsersTableAction: ITableAction<Listing>[] = [
    {
        actionId: "edit",
        actionLabel: "Edit",
    },
    {
        actionId: "reset",
        actionLabel: "Reset Password",
    },
    {
        actionId: "delete",
        actionLabel: "Delete",
    }
];


const Users = ({ query, setQuery }: any) => {
    const { authDetails } = useAuthDetails()!;
    const { register, handleSubmit } = useForm<Search>()
    const { q: q, page: page_number, rpp: record_per_page, order_by: order, sort_by: sort } = query
    const [actionType, setActionType] = useState<ActionType>()
    const [openPanel, setOpenPanel] = React.useState<boolean>(false)
    const [panelType, setPanelType] = React.useState<PanelType>('right')
    const [panelSize, setPanelSize] = React.useState<number>(30)
    const [panelTitle, setPanelTitle] = useState<string>("")
    const [result, setResult] = useState<Result>()
    const { addToast } = useToasts()
    const [openModal, setOpenModal] = useState(false);
    const [ssoData, setSsoData] = useState(null);
    const closeModal = () => setOpenModal(false);

    let p: Params = {
        q: q,
        page: page_number,
        rpp: record_per_page || 100,
        order_by: order,
        sort_by: sort
    }

    const [nameSortDir, setNameSortDir] = useState<string | undefined>("tablesort_down");
    const [emailSortDir, setEmailSortDir] = useState<string | undefined>("tablesort_down");
    const [roleSortDir, setRoleSortDir] = useState<string | undefined>("tablesort_down");
    const [lastLoginSortDir, setLastLoginSortDir] = useState<string | undefined>("tablesort_down");
    const [lastIpSortDir, setLastIpSortDir] = useState<string | undefined>("tablesort_down");

    const handleClick = (e) => {
        e?.preventDefault()
        e?.stopPropagation()
        setOpenModal(true)
    }

    const [UsersCols] = useState<Array<ColDef<Listing>>>([
        {
            headerName: 'Name',
            field: 'name',
            sortable: true,
            cellClass: 'align_left width380',
        },
        {
            headerName: 'Email',
            field: 'email',
            sortable: true,
            autoHeight: true,
            cellClass: 'align_left',
        },
        {
            headerName: 'Role',
            field: 'role',
            headerComponent: CustomHeader,
            sortable: true,
            width: getResolutionMatchFor1920(200, 200),
            maxWidth: getResolutionMatchFor1920(200, 200),
            // headerClass: 'text_align_center',
            initialSort: "desc",
            cellClass: 'align_left',
            headerComponentParams: {
                showInfoIcon: true, handleOnInfoClick: handleClick
            },
            cellRenderer: ({ data }) => {
                const u: Listing = data;
                return <>{u.role ? u.role : '-'}</>
            }
        }, {
            headerName: 'Last Login',
            field: 'last_login',
            sortable: true,
            // headerClass: 'text_align_center',
            width: getResolutionMatchFor1920(180, 180),
            maxWidth: getResolutionMatchFor1920(180, 180),
            cellClass: 'align_left',
            cellRenderer: ({ data }) => {
                const u: Listing = data;
                return <>{u.last_login ? formatDate(u.last_login) : '-'}</>
            }
        }, {
            headerName: 'Last IP',
            field: 'ip',
            sortable: true,
            // headerClass: 'text_align_center',
            cellClass: 'align_left',
            width: getResolutionMatchFor1920(180, 180),
            maxWidth: getResolutionMatchFor1920(180, 180),
            cellRenderer: ({ data }) => {
                const u: Listing = data;
                return <>{u.ip ? u.ip : '-'}</>
            }
        },
    ]
    )

    const highlightSortDir = () => {
        if (query.sort_by === "name" && query.order_by === "asc") {
            setNameSortDir("tablesort_up_selected")
        } else if (query.sort_by === "name" && query.order_by === "desc") {
            setNameSortDir("tablesort_down_selected")
        } else if (query.sort_by === "email" && query.order_by === "asc") {
            setEmailSortDir("tablesort_up_selected")
        } else if (query.sort_by === "email" && query.order_by === "desc") {
            setEmailSortDir("tablesort_down_selected")
        } else if (query.sort_by === "role" && query.order_by === "asc") {
            setRoleSortDir("tablesort_up_selected")
        } else if (query.sort_by === "role" && query.order_by === "desc") {
            setRoleSortDir("tablesort_down_selected")
        } else if (query.sort_by === "last_login" && query.order_by === "asc") {
            setLastLoginSortDir("tablesort_up_selected")
        } else if (query.sort_by === "last_login" && query.order_by === "desc") {
            setLastLoginSortDir("tablesort_down_selected")
        } else if (query.sort_by === "ip" && query.order_by === "asc") {
            setLastIpSortDir("tablesort_up_selected")
        } else if (query.sort_by === "ip" && query.order_by === "desc") {
            setLastIpSortDir("tablesort_down_selected")
        }
    }

    const deleteConfirm = () => {
        const headers = { 'Operation': 'DELETE' }
        Api.post('/user/' + actionType?.id, "", { headers: headers })
            .then((res: { data: any }) => {
                addToast("User deleted successfully.", {
                    appearance: 'success',
                    autoDismiss: true,
                })
                p.page = 1
                setQuery(p)
                refreshGrid()
                closeConfirm()
            })
            .catch((error: any) => {
                if (error.response.status === 401) {
                    window.location.href = '/login'
                } if (error.response.status === 403) {
                    addToast("You are not allowed to perform this action.", {
                        appearance: 'error',
                        autoDismiss: true,
                    })
                } else {
                    addToast("Sorry, something went wrong there, try again.", {
                        appearance: 'error',
                        autoDismiss: true,
                    })
                }
            })
    }

    useEffect(() => {
        highlightSortDir();
    }, [query])

    useEffect(() => {
        /* Listing() */
    }, [query])

    UsersTableAction[0].actionCallback = (data?: Listing | any) => {
        setActionType({
            id: data._id,
            type: 'edit'
        })
    }

    UsersTableAction[1].actionCallback = (data?: Listing | any) => {
        setActionType({
            id: data._id,
            type: 'reset'
        })
    }

    UsersTableAction[2].actionCallback = (data?: Listing | any) => {
        setActionType({
            id: data._id,
            type: 'delete'
        })
    }

    useEffect(() => {
        switch (actionType?.type) {
            case 'add':
                setPanelTitle("Add User")
                setOpenPanel(true)
                return

            case 'edit':
                setPanelTitle("Edit User")
                setOpenPanel(true)
                return

            case 'groups':
                setPanelTitle("Add Groups")
                setOpenPanel(true)
                return

            case 'reset':
                setPanelTitle("Reset Password")
                setOpenPanel(true)
                return

            case 'delete':
                setOpenConfirm(true)
                return

            default:
                return
        }

    }, [actionType])

    const onSubmit = (data: any) => {
        p.q = data.query;
        p.page = 1
        setQuery(p)
    }


    const RightPanel = () => {
        return (
            <SlidingPanel
                type={panelType}
                isOpen={openPanel}
                size={panelSize}
                panelClassName="sidepane fix_width"
            >
                <div className="pane_container">
                    <div className="pane_heading">{panelTitle}</div>
                    <div className="pane_close" onClick={() => setOpenPanel(false)}></div>
                    <div className="hr_ruler"></div>
                    <div className="panel_body">
                        {actionType?.type === "add" &&
                            <AddPanel AfterUserOperation={AfterUserOperation} />
                        }

                        {actionType?.type === "edit" &&
                            <EditPanel id={actionType.id} AfterUserOperation={AfterUserOperation} />
                        }

                        {/* {actionType?.type === "groups" &&
                            <GroupPanel AfterUserOperation={AfterUserOperation} />
                        } */}

                        {actionType?.type === "reset" &&
                            <ResetPassword id={actionType.id} AfterUserOperation={AfterUserOperation} />
                        }
                    </div>
                </div>
            </SlidingPanel>
        )
    }

    const renderPopup = () => {
        return (
            <ModalPopup
                onCloseHandler={() => setOpenPanel(false)}
                title={panelTitle}
                isOpen={openPanel}
            >
                {actionType?.type === "add" &&
                    <AddPanel AfterUserOperation={AfterUserOperation} hidePasswordFields={ssoData?.enable_sso} />
                }

                {actionType?.type === "edit" &&
                    <EditPanel id={actionType.id} AfterUserOperation={AfterUserOperation} />
                }

                {actionType?.type === "reset" &&
                    <ResetPassword id={actionType.id} AfterUserOperation={AfterUserOperation} />
                }

            </ModalPopup>
        )
    }

    const Listing = (queryParams?, paramGrid?) => {
        if (paramGrid)
            Api.get('/users', {
                params: queryParams
            })
                .then((res: { data: any }) => {
                    setResult(res.data)
                    if (paramGrid) {
                        paramGrid.success({
                            rowData: res.data.result,
                            rowCount: res.data.total
                        });
                        if (childRef?.current?.api) {
                            childRef.current.api.hideOverlay();
                            if (res.data.total === 0) {
                                childRef.current.api.showNoRowsOverlay();
                            }
                        }
                    }
                    setQuery({ ...query, ...queryParams })
                })
                .catch((error: any) => {
                    if (error.response.status === 401) {
                        window.location.href = '/login'
                    } else {
                        addToast("Sorry, something went wrong there, try again.", {
                            appearance: 'error',
                            autoDismiss: true,
                        })
                    }
                })
    }

    const Action = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        setActionType({
            id: e.currentTarget.id,
            type: e.currentTarget.dataset.action
        })
        e.preventDefault()
    }

    const Paginate = (selectedPage: any) => {
        p.page = selectedPage.selected === 0 ? 1 : selectedPage.selected + 1
        setQuery(p)
    }

    const AfterUserOperation = (action: string) => {
        setOpenPanel(false)
        if (action === 'add') {
            p.page = 1
            p.q = ""
            setQuery(p)
        }
        refreshGrid()
    }

    const FormatDate = (dte: any) => {
        Moment.locale('en')
        return (
            <>
                {Moment(dte).format('MMM DD, YYYY h:mm A')}
            </>
        )
    }

    const LoadSorting = (field: string) => {
        if (p.sort_by === field) {
            p.order_by = p.order_by === 'asc' ? 'desc' : 'asc'
            setQuery(p)
        } else {
            p.sort_by = field
            p.order_by = 'desc'
            setQuery(p)
        }
    };

    const SortBy = (name: string) => {
        if (p.sort_by === name) {
            return p.order_by === 'desc' ? 'tablesort_down' : 'tablesort_up'
        }
    }

    const [openConfirm, setOpenConfirm] = useState(false);
    const closeConfirm = () => setOpenConfirm(false);

    // const Sorting = (name: string, field: string) => {
    //     const fl = name.toLowerCase()
    //     return (
    //         <Link to="#" data-field={field} onClick={LoadSorting}>
    //             <div className={SortBy(field)}>{name}</div>
    //         </Link>
    //     )
    // }


    const isAmopsUser = (userEmail: string): boolean => {
        if (userEmail === "" || userEmail === undefined || userEmail === null) return false;
        return userEmail.toString().startsWith("amops@") && userEmail.toString().endsWith(".authmind");
    }

    const fetchSso = () => {
        Api.get('/sso')
            .then((res: { data: SSOList }) => {
                setSsoData(res.data);
            })
            .catch((error: any) => {
                console.log(error);
            })
    }

    useEffect(() => {
        fetchSso();
    }, []);

    const isResetPasswordVisible = (u) => {
        if (u.role.toLowerCase() === 'root') {
            return ((authDetails.sessionindex === "" || authDetails.sessionindex === undefined) && authDetails.role.toLowerCase() === 'root' && !ssoData?.root_sso);
        } else {
            return ((authDetails.sessionindex === "" || authDetails.sessionindex === undefined) && !ssoData?.enable_sso);
        }
    }

    const isActionsMenuVisible = (u) => {
        if (u.role.toLowerCase() === 'root') {
            return !ssoData?.root_sso && authDetails.role.toLowerCase() === 'root';
        } else {
            return (u.role.toLowerCase() != 'root' || authDetails.role.toLowerCase() == 'root') && !u.is_amops;
        }
    }

    const childRef = useRef()
    const { refreshGrid } = useBaseAMGrid(childRef)
    const customActionCellRender = (data) => {
        if (isActionsMenuVisible(data)) {
            return null
        }
        return <></>
    }

    const onBeforeActionCellRender = (data: Listing) => {
        let actions: ITableAction<Listing>[] = [...UsersTableAction];
        if (data.role === 'Root') {
            actions = [...actions.filter((val) => !['edit', 'delete'].includes(val.actionId))]
        }
        if (!isResetPasswordVisible(data)) {
            actions = [...actions.filter((i) => i.actionId !== 'reset')];
        }
        return actions;
    }

    return (
        <>
            {renderPopup()}
            <div className="page_title_area">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div className='font18'>Registered Users</div>
                        <div className="search_container issue_page scrollbar-container marginleft20 admin-page-search-container">
                            <div className="search_white_icon flex_none" style={{ cursor: 'pointer' }}
                                onClick={handleSubmit(onSubmit)}
                            >&nbsp;</div>&nbsp;
                            <input
                                className="search_input flex_none admin-page"
                                defaultValue={p.q}
                                placeholder="Search by Name, Email"
                                ref={register}
                                name='query'
                            />
                        </div>
                        <div className="launch_button admin_page_launch_btn">
                            <button type="button" className="button_main" onClick={() => setActionType({ id: "", type: "add" })}>Add</button>
                        </div>
                    </div>
                </form>
            </div>

            <BaseAMAdminGrid
                query={query}
                setQuery={setQuery}
                columnDefs={UsersCols}
                fetchEntitiesFn={Listing}
                gridRef={childRef}
                {...(UsersTableAction?.length > 0 && authDetails.permissions.Admin.directories !== "readonly"
                    ? { actions: UsersTableAction } : null)}
                onBeforeActionCellRender={onBeforeActionCellRender}
                customActionCellRender={customActionCellRender}
                rpp={100}
            /* TODO//known_public_ip replace with this */
            />

            {/* {renderTable()} */}

            {/* Delete confirmation Popup */}
            <Popup
                open={openConfirm}
                closeOnDocumentClick
                closeOnEscape={false}
                onClose={closeConfirm}
                modal
            >
                <div className="modal">
                    <div className="close" onClick={closeConfirm}></div>
                    <div className="header">Delete User</div>
                    <div className="content">
                        <div className="font16 margintop20 marginbottom20">Are you sure you want to Delete this User?</div>
                    </div>
                    <div className="popup_footer">
                        <button style={{ display: 'inline' }} type="button" className="button_gray width25per margintop10" onClick={closeConfirm}>No</button>
                        <button style={{ float: 'right' }} type="button" className="button_main width25per" onClick={deleteConfirm}>Yes</button>
                    </div>
                </div>
            </Popup>
            {openModal && <UserRoleDisplay closeConfirm={closeModal} />}
        </>
    )
}

export default withQueryParams({
    q: StringParam,
    page: StringParam,
    rpp: NumberParam,
    sort_by: StringParam,
    order_by: StringParam,
    filters: withDefault(ArrayParam, [])
}, Users)
