import { RuleType } from "./Constants"

export const ResetState = {
    [RuleType.WEAK_PASSWORD]: {
        isTimeChecked: true,
        duration: 10,
        unit: "hours",
        isDollarChecked: true,
        dollar: 25
    },
    [RuleType.COMPROMISED_PASSWORD]: {
        commonPasswordFlag: true,
        feedPasswordFlag: true,
        feeds: [
            {
                name: 'Have I Been Pwned',
                isChecked: true
            },
            {
                name: 'Dehashed',
                isChecked: true
            },
            {
                name: 'Md5decrypt.net',
                isChecked: false
            }
        ],
        hashFlag: true,
        hashFeeds: [
            /* {
                websiteName: 'DeHashed',
                isChecked: true,
            }, */
            {
                websiteName: 'Have I Been Pwned',
                isChecked: true,
            },
            {
                websiteName: 'Md5decrypt.net',
                isChecked: false,
            }
        ]
    },
    [RuleType.COMPROMISED_USER]: {
        user_breach_threshold_enabled: true,
        user_breach_threshold: 90,
        feeds: [
            {
                name: 'Have I Been Pwned',
                isChecked: true
            },
            {
                name: 'Dehashed',
                isChecked: true
            }
        ],
    },
    [RuleType.AUTHENTICATION_QUALITY]: {
        hashFlag: true,
        hashValue: 10,
        saltFlag: true,
        saltValue: 10,
        algoFlag: true,
        algoValue: [
            {
                name: 'MD4',
                isChecked: true
            },
            {
                name: 'MD5',
                isChecked: true
            },
            {
                name: 'RC2',
                isChecked: true
            },
            {
                name: 'RC4',
                isChecked: true
            },
            {
                name: 'SHA1',
                isChecked: true
            }
        ],
        legacyProcFlag: true,
        legacyProcValue: [
            {
                name: "SMTP",
                isChecked: true
            },
            {
                name: "IMAP",
                isChecked: true
            },
            {
                name: "POP",
                isChecked: true
            },
            {
                name: "FTP",
                isChecked: true
            },
            {
                name: "SMB",
                isChecked: true
            },
            {
                name: "NFS",
                isChecked: true
            },
            {
                name: "Telnet",
                isChecked: true
            }
        ],
        commonProcFlag: true,
        commonProcValue: [
            {
                name: "LDAP",
                isChecked: true
            },
            {
                name: "RADIUS",
                isChecked: true
            },
            {
                name: "DIAMETER",
                isChecked: true
            },
            {
                name: "NTLM",
                isChecked: true
            },
            {
                name: "NTLMv1",
                isChecked: true
            },
            {
                name: "NTLMv2",
                isChecked: true
            },
            {
                name: "HTTP",
                isChecked: true
            }
        ]
    },
    [RuleType.AUTH_HASH_QUALITY]: {
        hashFlag: true,
        hashValue: 41,
        saltFlag: true,
        saltValue: 33,
        algoFlag: true,
        algoValue: [
            {
                name: 'MD4',
                isChecked: true
            },
            {
                name: 'MD5',
                isChecked: true
            },
            {
                name: 'RC2',
                isChecked: true
            },
            {
                name: 'RC4',
                isChecked: true
            },
            {
                name: 'SHA1',
                isChecked: true
            }
        ],
        legacyProcFlag: true,
        legacyProcValue: [
            {
                name: "SMTP",
                isChecked: true
            },
            {
                name: "IMAP",
                isChecked: true
            },
            {
                name: "POP",
                isChecked: true
            },
            {
                name: "FTP",
                isChecked: true
            },
            {
                name: "SMB",
                isChecked: true
            },
            {
                name: "NFS",
                isChecked: true
            },
            {
                name: "Telnet",
                isChecked: true
            }
        ],
        commonProcFlag: true,
        commonProcValue: [
            {
                name: "LDAP",
                isChecked: true
            },
            {
                name: "RADIUS",
                isChecked: true
            },
            {
                name: "DIAMETER",
                isChecked: true
            },
            {
                name: "NTLM",
                isChecked: true
            },
            {
                name: "NTLMv1",
                isChecked: true
            },
            {
                name: "NTLMv2",
                isChecked: true
            },
            {
                name: "HTTP",
                isChecked: true
            }
        ]
    },
    [RuleType.AUTH_HASH_SECURITY]: {
        hashFlag: true,
        hashValue: 10,
        saltFlag: true,
        saltValue: 10,
        algoFlag: true,
        algoValue: [
            {
                name: 'MD4',
                isChecked: true
            },
            {
                name: 'MD5',
                isChecked: true
            },
            {
                name: 'RC2',
                isChecked: true
            },
            {
                name: 'RC4',
                isChecked: true
            },
            {
                name: 'SHA1',
                isChecked: true
            }
        ],
        legacyProcFlag: true,
        legacyProcValue: [
            {
                name: "SMTP",
                isChecked: true
            },
            {
                name: "IMAP",
                isChecked: true
            },
            {
                name: "POP",
                isChecked: true
            },
            {
                name: "FTP",
                isChecked: true
            },
            {
                name: "SMB",
                isChecked: true
            },
            {
                name: "NFS",
                isChecked: true
            },
            {
                name: "Telnet",
                isChecked: true
            }
        ],
        commonProcFlag: true,
        commonProcValue: [
            {
                name: "LDAP",
                isChecked: true
            },
            {
                name: "RADIUS",
                isChecked: true
            },
            {
                name: "DIAMETER",
                isChecked: true
            },
            {
                name: "NTLM",
                isChecked: true
            },
            {
                name: "NTLMv1",
                isChecked: true
            },
            {
                name: "NTLMv2",
                isChecked: true
            },
            {
                name: "HTTP",
                isChecked: true
            }
        ]
    },
    [RuleType.AUTH_PROTOCOL_QUALITY]: {
        hashFlag: true,
        hashValue: 10,
        saltFlag: true,
        saltValue: 10,
        algoFlag: true,
        apq_ignore_private_ip: false,
        algoValue: [
            {
                name: 'MD4',
                isChecked: true
            },
            {
                name: 'MD5',
                isChecked: true
            },
            {
                name: 'RC2',
                isChecked: true
            },
            {
                name: 'RC4',
                isChecked: true
            },
            {
                name: 'SHA1',
                isChecked: true
            }
        ],
        legacyProcFlag: true,
        legacyProcValue: [
            {
                name: "SMTP",
                isChecked: true
            },
            {
                name: "IMAP",
                isChecked: true
            },
            {
                name: "SMBv1",
                isChecked: true
            },
            {
                name: "POP2",
                isChecked: true
            },

            {
                name: "FTP",
                isChecked: true
            },
            {
                name: "SMBv2",
                isChecked: true
            },
            {
                name: "POP3",
                isChecked: true
            },
            {
                name: "NFS",
                isChecked: true
            },
            {
                name: "Telnet",
                isChecked: true
            }
        ],
        commonProcFlag: true,
        commonProcValue: [
            {
                name: "LDAP",
                isChecked: true
            },
            {
                name: "RADIUS",
                isChecked: true
            },
            {
                name: "DIAMETER",
                isChecked: true
            },
            {
                name: "Local NTLM",
                isChecked: false
            },
            {
                name: "NBT/NTLM",
                isChecked: false
            },
            {
                name: "NTLM",
                isChecked: false
            },
            {
                name: "NTLMv1",
                isChecked: true
            },
            {
                name: "NTLMv2",
                isChecked: true
            },
            {
                name: "HTTP",
                isChecked: true
            }
        ]
    },
    [RuleType.ACCESS_TO_UNAUTH_COUNTRIES]: undefined,
    [RuleType.ACCESS_FROM_UNAUTH_COUNTRIES]: undefined,
    [RuleType.SHADOW_INDENTITIES]: {
        skip_mfa_match: true
    },
    [RuleType.SUSPICIOUS_OUTBOUND_ACCESS]: {
        ip_rep_threshold: 3
    },
    [RuleType.SUSPICIOUS_INBOUND_ACCESS]: {
        ip_rep_threshold: 3
    },
    [RuleType.LACK_OF_MFA]: {
        mfa_servers: [],
        mfa_timeout: 720
    },
    [RuleType.DEVIATION_IN_DAILY_ASSET]: {
        today_flow_count_7_day_avg:
            { is_checked: true, percent: 10, operator: 'higher' },
        today_flow_count_gt_7_day_max:
            { is_checked: true, percent: 10 },
        today_flow_count_lt_7_day_min:
            { is_checked: false, percent: null },
        today_flow_count_gt_yesterday:
            { is_checked: false, percent: null, operator: 'higher' },
        today_flow_count_gt_count:
            { is_checked: false, value: null }
    },
    [RuleType.SUSPECTED_DIR_BOT]: {
        "dir_params": {
            "param_durations": [
                {
                    "param_name": "user_auth_fail_nhosts_today",
                    "duration": "day"
                },
                {
                    "param_name": "user_auth_fail_count_today",
                    "duration": "day"
                }
            ],
            "user_auth_fail_nhosts_today": 5,
            "user_auth_fail_count_today": 25,
            "param_thresholds":
                [{
                    "param_name": "user_auth_fail_nhosts_today",
                    "threshold_type": "manual",
                    "threshold_confidence": undefined,
                    "allow_ml_modify": false
                },
                {
                    "param_name": "user_auth_fail_count_today",
                    "threshold_type": "manual",
                    "threshold_confidence": undefined,
                    "allow_ml_modify": false
                }
                ]
        }
    },
    [RuleType.SUSPECTED_BRUTE_FORCE]: {
        dir_params: {
            param_durations: [
                {
                    duration: 'day',
                    param_name: 'user_auth_fail_count_today'
                }
            ],
            // time_duration: 'day', // "hour|day",
            user_auth_fail_count_today: 20,  // should be present only if time_duration is set to 'day'
            // user_bad_password_count_curhour: undefined, // should be present only if time_duration is set to 'hour'
            dir_bad_password_nuser_curhour: 5,
            "param_thresholds":
                [{
                    "param_name": "dir_bad_password_nuser_curhour",
                    "threshold_type": "manual",
                    "threshold_confidence": undefined,
                    "allow_ml_modify": false
                },
                {
                    "param_name": "user_auth_fail_count_today",
                    "threshold_type": "manual",
                    "threshold_confidence": undefined,
                    "allow_ml_modify": false
                }
                ]
        }
    },
    [RuleType.SUSPECTED_IDP_BRUTE_FORCE]: {
        dir_params: {
            param_durations: [
                {
                    duration: 'day',
                    param_name: 'user_auth_fail_count_today'
                }
            ],
            // time_duration: 'day', // "hour|day",
            user_auth_fail_count_today: 20,  // should be present only if time_duration is set to 'day'
            // user_bad_password_count_curhour: undefined, // should be present only if time_duration is set to 'hour'           
            "param_thresholds":
                [
                    {
                        "param_name": "user_auth_fail_count_today",
                        "threshold_type": "manual",
                        "threshold_confidence": undefined,
                        "allow_ml_modify": false
                    }
                ]
        }
    },
    [RuleType.SUSPECTED_AD_BRUTE_FORCE]: {
        dir_params: {
            param_durations: [
                {
                    duration: 'day',
                    param_name: 'user_auth_fail_count_today'
                }
            ],
            // time_duration: 'day', // "hour|day",            
            // user_bad_password_count_curhour: undefined, // should be present only if time_duration is set to 'hour'
            dir_bad_password_nuser_curhour: 5,
            "param_thresholds":
                [{
                    "param_name": "dir_bad_password_nuser_curhour",
                    "threshold_type": "manual",
                    "threshold_confidence": undefined,
                    "allow_ml_modify": false
                },
                ]
        }
    },
    [RuleType.SUSPECTED_PASS_SPRAY]: {
        "dir_params": {
            "dir_bad_password_nuser_curhour": 25,  // could be idp_bad_password_nuser_curhour as well,
            "param_thresholds":
                [{
                    "param_name": "dir_bad_password_nuser_curhour",
                    "threshold_type": "manual",
                    "threshold_confidence": undefined,
                    "allow_ml_modify": false
                }]
        }
    },
    [RuleType.NTLM_RELAY_ATTACK]: {
        "dir_params": {
            "dir_ntlm_relay_count_today": 2,
            "param_thresholds":
                [{
                    "param_name": "dir_ntlm_relay_count_today",
                    "threshold_type": "manual",
                    "threshold_confidence": undefined,
                    "allow_ml_modify": false
                }]
        }
    },
    [RuleType.ENUM_AD_ADMINS]: {
        "dir_params": {
            "dir_bad_username_count_curhour": 50,
            "dir_bad_username_nadmin_curhour": 3,
            "param_thresholds":
                [{
                    "param_name": "dir_bad_username_count_curhour",
                    "threshold_type": "manual",
                    "threshold_confidence": undefined,
                    "allow_ml_modify": false
                },
                {
                    "param_name": "dir_bad_username_nadmin_curhour",
                    "threshold_type": "manual",
                    "threshold_confidence": undefined,
                    "allow_ml_modify": false
                }
                ]
        }
    },
    [RuleType.ENUM_AD_USERS]: {
        "dir_params": {
            "dir_bad_username_count_curhour": 50,
            "dir_bad_username_nuser_curhour": 10,
            "param_thresholds":
                [{
                    "param_name": "dir_bad_username_count_curhour",
                    "threshold_type": "manual",
                    "threshold_confidence": undefined,
                    "allow_ml_modify": false
                },
                {
                    "param_name": "dir_bad_username_nuser_curhour",
                    "threshold_type": "manual",
                    "threshold_confidence": undefined,
                    "allow_ml_modify": false
                }
                ]
        }
    },
    [RuleType.UNAUTH_LOGIN_TIME]: {
        "dir_params": {
            "user_unauthorized_time_count_curhour": 2
        }
    },
    [RuleType.UNAUTH_LOGIN_DEVICE]: {
        "dir_params": {
            "user_unauthorized_device_count_curhour": 2
        }
    },
    [RuleType.SUSPECTED_ATT_EXP_ACC]: {
        "dir_params": {
            "user_expired_logon_count_curhour": 3,
            "param_thresholds":
                [{
                    "param_name": "user_expired_logon_count_curhour",
                    "threshold_type": "manual",
                    "threshold_confidence": undefined,
                    "allow_ml_modify": false
                }
                ]
        }
    },
    [RuleType.SUSPECTED_ATT_DIS_ACC]: {
        "dir_params": {
            "user_disabled_logon_count_curhour": 3,
            "param_thresholds":
                [{
                    "param_name": "user_disabled_logon_count_curhour",
                    "threshold_type": "manual",
                    "threshold_confidence": undefined,
                    "allow_ml_modify": false
                }]
        }
    },
    [RuleType.SUSPECTED_ATT_LOCK_ACC]: {
        "dir_params": {
            "user_locked_logon_count_curhour": 5,
            "param_thresholds":
                [{
                    "param_name": "user_locked_logon_count_curhour",
                    "threshold_type": "manual",
                    "threshold_confidence": undefined,
                    "allow_ml_modify": false
                }
                ]
        }
    },
    [RuleType.ACCOUNT_TAKEOVER]: {
        account_takeover_params: {
            displacementFlag: true,
            displacementValue: 500,
            displacementUnit: "miles",
            distanceTraveledFlag: true,
            distanceTraveledValue: 500,
            distanceTraveledUnit: 'miles',
            newIspFlag: true,
            newIpRangeFlag: true
        }
    },
    [RuleType.DEVIATION_IDENTITY_ACTIVITY]: {
        deviation_identity_params: {
            geoLocationFlag: true,
            cityCountryFlag: true,
            iPCidrFlag: true,
            ispFlag: true,
            appByUserFlag: false,
            deviceAccessFlag: false,
            hostnameAccessFlag: true,
            browserAccessFlag: false,
            osAccessFlag: false,
        }
    },
    [RuleType.UNUSUAL_USER_ACCESS]: {
        unusual_user_params: {
            geoLocationFlag: true,
            cityCountryFlag: true,
            iPCidrFlag: true,
            ispFlag: true,
            appByUserFlag: false,
            deviceAccessFlag: false,
            hostnameAccessFlag: true,
            browserAccessFlag: false,
            osAccessFlag: false,
            deviationsCount: 2
        }
    },
    [RuleType.SHADOW_DIRECTORY]: {
        "IdentitySystemCategories": [0, 560, 580, 640, 660, 680, 700, 620],
    },
    [RuleType.SUSPECTED_ACCESS_TOKEN]: {
        session_token_params: {
            assertion_timeout: 1,
            period_timeout_unit: 'hours'
        }
    },
    [RuleType.AD_GOLDEN_TICKET]: {
        "dir_params": {
            "kb_ticket_max_age": 10,
            encryption_algo_checked: true,
            "param_thresholds": [
                {
                    "param_name": "kb_ticket_max_age",
                    "threshold_type": "manual",
                    "allow_ml_modify": false
                }
            ]
        },
    }
}

export const DefaultRisk = {
    [RuleType.ACCESS_TO_UNAUTH_COUNTRIES]: 'High',
    [RuleType.ACCESS_FROM_UNAUTH_COUNTRIES]: 'High',
    [RuleType.WEAK_PASSWORD]: 'Low',
    [RuleType.COMPROMISED_PASSWORD]: 'Critical',
    [RuleType.COMPROMISED_USER]: 'High',
    [RuleType.AUTHENTICATION_QUALITY]: 'Medium',
    [RuleType.AUTH_HASH_QUALITY]: 'Medium',
    [RuleType.AUTH_HASH_SECURITY]: 'Medium',
    [RuleType.AUTH_PROTOCOL_QUALITY]: 'Medium',
    [RuleType.SHADOW_INDENTITIES]: 'Critical',
    [RuleType.SHADOW_ASSETS]: 'Critical',
    [RuleType.SHADOW_ACCESS]: 'Critical',
    [RuleType.SHADOW_DIRECTORY]: 'Critical',
    [RuleType.SHADOW_EXTERNAL_ACCESS]: 'High',
    [RuleType.UNKNOWN_ACCESS]: 'High',
    [RuleType.EXPOSED_ASSETS]: 'Critical',
    [RuleType.SUSPICIOUS_INBOUND_ACCESS]: 'High',
    [RuleType.SUSPICIOUS_OUTBOUND_ACCESS]: 'Critical',
    [RuleType.UNKNOWN_SAAS_ACCESS]: 'High',
    [RuleType.LACK_OF_MFA]: 'High',
    [RuleType.ACCESS_FROM_PUBLIC_VPN]: 'High',
    [RuleType.ACCESS_TO_PUBLIC_VPN]: 'High',
    [RuleType.ACCESS_FROM_ANON_IP]: 'High',
    [RuleType.ACCESS_TO_ANON_IP]: 'High',
    [RuleType.DEVIATION_IN_DAILY_ASSET]: 'High',
    [RuleType.UNAUTHORIZED_ASSET_ACCESS]: 'High',
    [RuleType.UNAUTHORIZED_IDENTITY_ACCESS]: 'High',
    [RuleType.SUSPECTED_DIR_BOT]: 'High',
    [RuleType.SUSPECTED_BRUTE_FORCE]: 'Critical',
    [RuleType.SUSPECTED_AD_BRUTE_FORCE]: 'Critical',
    [RuleType.SUSPECTED_IDP_BRUTE_FORCE]: 'Critical',
    [RuleType.SUSPECTED_PASS_SPRAY]: 'High',
    [RuleType.NTLM_RELAY_ATTACK]: 'High',
    [RuleType.UNAUTH_LOGIN_TIME]: 'Medium',
    [RuleType.ENUM_AD_ADMINS]: 'Medium',
    [RuleType.ENUM_AD_USERS]: 'Medium',
    [RuleType.UNAUTH_LOGIN_DEVICE]: 'Medium',
    [RuleType.SUSPECTED_ATT_EXP_ACC]: 'Medium',
    [RuleType.SUSPECTED_ATT_DIS_ACC]: 'Medium',
    [RuleType.SUSPECTED_ATT_LOCK_ACC]: 'Medium',
    [RuleType.ACCOUNT_TAKEOVER]: 'High',
    [RuleType.DEVIATION_IDENTITY_ACTIVITY]: 'High',
    [RuleType.UNUSUAL_USER_ACCESS]: 'High',
    [RuleType.SUSPECTED_ACCESS_TOKEN]: 'High',
    [RuleType.ASSETS_WITHOUT_MFA]: 'High',
    [RuleType.AD_GOLDEN_TICKET]: 'High',
    [RuleType.AD_PASS_THE_TICKET]: 'High'
}
