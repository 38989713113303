import React, { createContext, useState, useContext } from "react";
import { usePostureCharts } from "../pages/identity_asset/ref/PostureCharts/usePostureCharts";
import { IdAssetWidget, ServerResponse } from "../types/response-types";

interface IActiveTabs { identities: boolean, assets: boolean, accesses: boolean, directory: boolean }

export type UsePostureChartsResponse = {
    responseData?: ServerResponse;
    showAdvanceSearch: boolean;
    showGraph: boolean;
    selectedItems: any[]; // Update `any[]` with specific type if known
    items: any[];         // Update `any[]` with specific type if known
    idWidget?: IdAssetWidget;
    ruleWidget?: IdAssetWidget,
    ruleRootIdentity?: IdAssetWidget,
    widgetData?: IdAssetWidget,
    setResponseData: (data?: ServerResponse) => void;
    setShowAdvanceSearch: (show: boolean) => void;
    setShowGraph: (show: boolean) => void;
    setSelectedItems: (items: any[]) => void; // Update `any[]` with specific type if known
    setItems: (items: any[]) => void;         // Update `any[]` with specific type if known
    setIdWidget: (state: IdAssetWidget) => void;
    setRuleWidget: (state: IdAssetWidget) => void;
    setRuleRootIdentity: (state: IdAssetWidget) => void;
    setWidgetData: (state: IdAssetWidget | undefined) => void;
    totalCount: number;
    setTotalCount: (state: number) => void
};


interface PostureStateValues {
    PostureSearchState: {
        activeTabs: IActiveTabs;
        setActiveTabs: (o: IActiveTabs) => void;
        isAdSearchApplied: boolean,
        setIsAdSearchApplied: (i: boolean) => void,
        isAdvancedSearchOpen: boolean,
        setIsAdvancedSearchOpen: (i: boolean) => void,
        tags: string[],
        setTags: (val: string[]) => void,
        currentSearch: string,
        setCurrentSearch: (s: string) => void
    },
    PostureChartState: UsePostureChartsResponse
}

export const DefaultActiveTabs: IActiveTabs = {
    accesses: true, assets: true, directory: true, identities: true
}

export const PostureStateContext = createContext<PostureStateValues | undefined>(undefined);

export const PostureStateContextProvider: React.FC = ({ children }) => {
    //Unknown Access State

    // activeTabs, isPostureAdSearchApplied, setActiveTabs, setIsPostureAdvancedSearchApplied;
    const postureCharts = usePostureCharts();

    const [isPostureAdSearchApplied, setIsPostureAdvancedSearchApplied] = useState<boolean>(false);
    const [isSearchOpen, setIsSearchOpen] = useState<boolean>(false);
    const [activeTabs, setActiveTabs] = useState<IActiveTabs>(DefaultActiveTabs);
    const [tags, setTags] = useState<string[]>([]);
    const [currentSearch, setCurrentSearch] = useState<string>('');

    const setPostureActiveTabs = (id: IActiveTabs) => {
        setActiveTabs(id);
    };

    const setPostureAdvancedSearchApplied = (i: boolean) => {
        if (!i) {
            setActiveTabs(DefaultActiveTabs);
        }
        setIsPostureAdvancedSearchApplied(i);
    }

    const setIsAdSearchOpen = (i: boolean) => setIsSearchOpen(i)


    const contextValue: PostureStateValues = {
        PostureSearchState: {
            activeTabs: activeTabs, isAdSearchApplied: isPostureAdSearchApplied,
            setActiveTabs: setPostureActiveTabs, setIsAdSearchApplied: setPostureAdvancedSearchApplied,
            isAdvancedSearchOpen: isSearchOpen, setIsAdvancedSearchOpen: setIsAdSearchOpen,
            setTags, tags, currentSearch, setCurrentSearch
        },
        PostureChartState: postureCharts
    };
    return <PostureStateContext.Provider value={contextValue}>{children}</PostureStateContext.Provider>;
};


export const usePostureStateContext = (): PostureStateValues => {
    const context = useContext(PostureStateContext);
    if (!context) {
        throw new Error("usePostureStateContext must be used within a PostureStateContextProvider");
    }
    return context;
};


