import { CustomCellRendererProps } from "@ag-grid-community/react"
import { FunctionComponent } from "react"
import { convertToCommaValue } from "../../../../utils/util-methods";
import React from "react";

interface IdentityAssetCountCellRendererProps extends CustomCellRendererProps {
    handleAssetsAccess
}

export const IdentityAssetCountCell: FunctionComponent<IdentityAssetCountCellRendererProps> = ({ data, node,
    handleAssetsAccess
}: IdentityAssetCountCellRendererProps) => {
    const item = data;
    /* if (node.group) {
        return <span>{ }</span>; // Display group key for grouped rows
    } */
    return item.asset_count !== 0 ?
        <div className="shadowbox num_flows" onClick={() => { handleAssetsAccess(item._id?.toString()) }} id={item._id?.toString()}>
            <span>{convertToCommaValue(item.asset_count)}</span>
        </div>
        :
        <div style={{ textAlign: 'center' }} id={item._id?.toString()}>
            <span>{convertToCommaValue(item.asset_count)}</span>
        </div>
}