import { CustomCellRendererProps } from "@ag-grid-community/react";
import { FunctionComponent } from "react";
import { convertToCommaValue } from "../../../../../utils/util-methods";
import React from "react";
import { BasePostureObj } from "../../../../../types/response-types";

interface PostureIssueCountCellRendererProps extends CustomCellRendererProps {
    handleIssueFilterClick,
    responseData?
}

export const PostureIssueCountCell: FunctionComponent<PostureIssueCountCellRendererProps> = ({ data, node,
    handleIssueFilterClick, responseData
}: PostureIssueCountCellRendererProps) => {
    const item: BasePostureObj = data;
    /* if (node.group) {
        return <span>{ }</span>; // Display group key for grouped rows
    } */
    return item.issue_count !== 0 ?
        <div className="shadowbox num_flows" onClick={() => handleIssueFilterClick(item?._id, item?.type, item, responseData)} id={item._id?.toString()}>
            <span>{convertToCommaValue(item.issue_count)}</span>
        </div>
        :
        <div style={{ textAlign: 'center' }} id={item._id?.toString()}>
            <span>{convertToCommaValue(item.issue_count)}</span>
        </div>
}