import { CustomCellRendererProps } from "@ag-grid-community/react";
import React, { FunctionComponent, useState } from "react";
import { DirectoryCategory } from "../../ref/PostureCategories/DirectoryCategory";
import { AccessesObj } from "../../../../types/response-types";
import { AMToolTipAd } from "../../../../components/core/AMTooltipAd/AMTooltipAd";
import { FailedDirPopUp } from "../sub/FailedDirPopUp";

interface AccessDirectoryCellRendererProps extends CustomCellRendererProps { }

export const AccessDirectoryCell: FunctionComponent<AccessDirectoryCellRendererProps> = ({ data }: AccessDirectoryCellRendererProps) => {
    const [failedDir, setFailedDir] = useState<AccessesObj>();
    const item = data;
    const handleFailedDirClick = (item: AccessesObj) => {
        if (item?.failed_auth_list && item?.failed_auth_list?.length > 0) {
            setFailedDir(item)
        }
    }

    return <div className="dir-icon-img">
        {
            item.is_directory_present ?
                item.directory_flow_status === "success" ?
                    <AMToolTipAd
                        title={<div className="">
                            {(item?.directory_category_list?.length > 0 || item?.is_directory_public) ? 'Identity Provider' : 'Directory Hostname'}: {item.directory_hostname} <br />
                            <DirectoryCategory categories={item.directory_category_list || []} />
                            {/* Type: {item.asset_type} <br/>
                Protocol: {item.asset_protocol} <br/>
                Port: {item.asset_port} <br/> */}
                        </div>}
                        classes={{
                            tooltip: 'dir_titletip'
                        }}>
                        <div className={"dir_icon_green " + (item?.directory_category_list &&
                            (item?.directory_category_list?.length > 0 || item?.is_directory_public) && ' dir_icon_green_cloud')} >
                        </div>
                    </AMToolTipAd>
                    :
                    item.directory_flow_status === "fail" ?
                        <AMToolTipAd
                            classes={{
                                tooltip: 'dir_titletip'
                            }}
                            title={
                                <div className="">
                                    {item?.failed_auth_list?.length ?
                                        <span>{item?.directory_category_list?.length > 0 ? 'Identity Provider' : 'Directory Hostname'}: {item.directory_hostname} <br />
                                            Fail Count: {item.directory_flow_fail_count} <br />
                                        </span> : <span>No recent failed authentications.</span>
                                    }
                                    <DirectoryCategory categories={item.directory_category_list || []} />
                                </div>
                            }>
                            <div className={
                                "dir_icon_" + (Math.round(Number(item?.auth_failed_percent || 0)) > 99 ? 'red' :
                                    Math.round(Number(item?.auth_failed_percent || 0)) >= 80 ? 'risk_3' :
                                        Math.round(Number(item?.auth_failed_percent || 0)) > 0 ? 'yellow' : 'grey') + ' ' +
                                (Math.round(Number(item?.auth_failed_percent || 0)) > 99 ? '' :
                                    Math.round(Number(item?.auth_failed_percent || 0)) >= 80 ? 'risk_3' :
                                        Math.round(Number(item?.auth_failed_percent || 0)) > 0 ? 'risk_2' : 'risk_0') +
                                ((item?.directory_category_list && item?.directory_category_list.length > 0 || item?.is_directory_public) ? ' dir_icon_' + (Math.round(Number(item?.auth_failed_percent || 0)) > 99 ? 'red' :
                                    Math.round(Number(item?.auth_failed_percent || 0)) >= 80 ? 'risk_3' :
                                        Math.round(Number(item?.auth_failed_percent || 0)) > 0 ? 'yellow' : '') + '_cloud' : '')
                            }
                                style={{
                                    cursor: item?.failed_auth_list && item?.failed_auth_list.length > 0 ? 'pointer' : '',
                                }}
                                onClick={() => handleFailedDirClick(item)}
                            >

                            </div>
                        </AMToolTipAd>
                        :
                        item.directory_flow_status === "unknown" ?
                            <AMToolTipAd
                                title={<div className="">
                                    {(item?.directory_category_list?.length > 0 || item?.is_directory_public) ? 'Identity Provider' : 'Directory Hostname'}: {item.directory_hostname} <br />
                                    <DirectoryCategory categories={item.directory_category_list || []} />
                                    {/* Type: {item.asset_type} <br/>
                Protocol: {item.asset_protocol} <br/>
                Port: {item.asset_port} <br/> */}
                                </div>}
                                classes={{
                                    tooltip: 'dir_titletip'
                                }}>
                                <div className={"dir_icon_grey " + (item?.directory_category_list &&
                                    (item?.directory_category_list?.length > 0 || item?.is_directory_public) && ' dir_icon_grey_cloud')} >
                                </div>
                            </AMToolTipAd>
                            :
                            <div></div>
                : null
        }
        {failedDir && <FailedDirPopUp data={failedDir} onClose={() => setFailedDir(undefined)} />}
    </div>
}