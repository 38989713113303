const tableMessage = {
    TABLE_ERR: `An error occurred while processing your request. Please check your input and try again. If the error persists, please reach out to $1 support.`,
    NO_RECS: `No Records Found.`
};

export const generateTableError = (errKey: keyof typeof tableMessage, ...args: string[]): string => {
    let message = tableMessage[errKey];
    args.forEach((arg, index) => {
        const placeholder = `$${index + 1}`;
        message = message.replace(placeholder, arg);
    });
    return message;
};