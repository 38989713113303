import React from "react";
import { PostureCategory } from "../../../identity_asset/ref/PostureCategories/PostureCategory";
import { Tooltip } from "@mui/material";
import { RuleType } from "../../../playbooks/constants/Constants";
import InfoIcon from '@mui/icons-material/Info';
import { getRisk } from "../../../../utils/risk-level";
import moment from "moment";
import { AMCountryFlag } from "../../../../components/core/AMCountryFlag/AMCountryFlag";
import { dir_names, getItemIconClass, getItemIconTitle, SuspectedPbList } from "./issue-grid-utils";

export const IssueIdentity = ({ item, handleSuspectedClick, handleMounseLeaveHost, handleMouseEnterHost }) => {
    return <div className='issue_last_access_time_cell identity_cell'>
        <div className='issue_last_access_time_cell_item type_cell display-flex'>
        {
            item.src_ip_reputation === 3 ?
                <div className="reputation_icon_orange">
                    <div className="titletip">
                        {item.src_ip_fraud_score ?
                            <span>IP Fraud Score: {item.src_ip_fraud_score} (out of 100)</span> : null
                        }
                    </div>
                </div>
                : 
            item.src_ip_reputation === 2 ?
                <div className="reputation_icon_gray">
                    <div className="titletip">
                        {item.src_ip_fraud_score ?
                            <span>IP Fraud Score: {item.src_ip_fraud_score} (out of 100)</span> : null
                        }
                    </div>
                </div>
                :
                item.src_ip_reputation === 4 ?
                    <div className="reputation_icon_red">
                        <div className="titletip">
                            {item.src_ip_fraud_score ?
                                <span>IP Fraud Score: {item.src_ip_fraud_score} (out of 100)</span> : null
                            }
                        </div>
                    </div>
                    :  <div className="reputation_icon" style={{width:'14px'}}>
                    
                </div>
            }
            <PostureCategory
                type={item.source_type}
                title={getItemIconTitle(item)}
                categories={item.src_cat_ids || []}
            >
                <div className='asset_type_icons'>
           
                    <div className={getItemIconClass(item)} >
                        {(item.is_known_identity === false && (item?.source_type === "User" || item?.source_type === "User/Agent" || item?.source_type === "User/Background Browsing")) ? <span title={"Unresolved User"}>?</span> : null}
                        {(item.is_known_identity === false && (item?.source_type === "Service" || item?.source_type === "Service/Application" || item?.source_type === 'Service/Computer Account' || item?.source_type === 'Service/Key and Secret' || item?.source_type === 'Service/Token' || item?.source_type === 'Service/Service Principal' || item?.source_type === 'Service/Service Account')) ? <span>?</span> : null}
                    </div>
                </div>
            </PostureCategory>
        </div>
        <div style={{ flexGrow:1}} className='issue_last_access_time_cell_item' >
            <div className="copy_field issue-page" style={{ display: 'flex' , flexGrow: '1' }}>
                <Tooltip
                    onMouseOver={() => {
                        const el = document.getElementsByClassName('issue-parent-tooltip');
                        if (el && el[0])
                            el[0].classList.remove('visibility_hidden');
                    }}
                    classes={{ tooltip: 'matching-rule-tooltip issue-parent-tooltip' }} title={
                        <>
                            <Tooltip classes={{ tooltip: 'posture-root-column-container scrollbar-container' }} title={<div>
                                <div><b>Full Name:</b>{item?.source_full_name || item?.userdetails_cn || ''}</div>

                            </div>}>
                                <div>{item?.source_full_name || item?.userdetails_cn || ''}</div>
                            </Tooltip>
                            <div style={{ paddingTop: '2%' }}>{item.source_name}</div>
                            {item?.identity_groups && item?.identity_groups.length > 0 && <div style={{ marginTop: '0px', paddingTop: '3px', overflow: 'hidden' }} className=''> <div className="float_left" ><b>Groups: <span>&nbsp;</span> </b></div> <div className="float_left" style={{ fontWeight: 'normal', width: '150px' }} >
                                {item?.identity_groups.join(', ')}

                            </div> </div>}
                        </>
                    }>
                    <span>
                    <div className={`${item.suspected_source_info ? 'suspcted ellipsis_name' : 'ellipsis_name'} issue-page`}>
                            <span>
                                <span>{item.source_name.toString()}</span>
                            </span>
                        </div>
                        {
                            (((item.matching_rule_name == RuleType.SUSPECTED_DIR_BOT.toString() &&
                                (!dir_names.filter((i: string) => item?.directory_hostname?.toLowerCase().includes(i)).length ||
                                    !dir_names.filter((i: string) => item?.directory_name?.toLowerCase().includes(i)).length)) ||
                                (SuspectedPbList.includes(item?.matching_rule_name) && item.matching_rule_name != RuleType.SUSPECTED_DIR_BOT.toString())
                            )) &&
                            (item?.suspected_source_info ? <div className={'suspected_icon ' + (`${getRisk(item?.risk)?.toLowerCase()}-risk-sus`)}
                                onClick={() => handleSuspectedClick(item._id.toString(), item.source_name, item.suspected_source_info, item.matching_rule_name)}></div>
                                :
                                item?.status === 'Open' &&
                                    moment.duration(moment(new Date()).diff(moment(item.first_flow_time))).asHours() < 12
                                    ? <>
                                        <Tooltip classes={{ tooltip: 'matching-rule-tooltip' }}
                                            title={"We're still computing the possible sources of attack."}
                                            onMouseOver={(e: any) => {
                                                setTimeout(() => {
                                                    const el = document.getElementsByClassName('issue-parent-tooltip');
                                                    if (el && el[0])
                                                        el[0].classList.add('visibility_hidden');
                                                }, 100)
                                            }
                                            }
                                        >
                                            <div className={'suspected_icon_invert low-risk-sus'} style={{ cursor: 'default' }}></div>
                                        </Tooltip> </> : <></>
                            )
                        }
                        <InfoIcon style={{ fontSize: 14, color: '#d4d8e1', marginTop: 2, transform: 'skewX(-10deg)' }} />
                    </span>
                </Tooltip>
                <div className="copy_name" title="Copy this text to Clipboard" onClick={() => navigator.clipboard.writeText(item.source_name)}></div>
            </div>
        </div>
        <div className='issue_last_access_time_cell_item full_width' style={{marginLeft:'15px'}}>
            {item?.source_countrycode2 ?
                <span>
                    {item?.source_countryname ?
                        <div className="flag_icon">
                            <AMCountryFlag countryCode={item?.source_countrycode2} />
                            <div className="titletip">
                                {item?.source_cityname ?
                                    <span>{item?.source_cityname}, </span> : null
                                }
                                <span>{item?.source_countryname}</span>
                            </div>
                        </div>
                        :
                        <div className="flag_icon">
                            <div className='no_flag' title='Country details not available'></div>
                        </div>
                    }
                </span>
                :
                <span>
                    {item?.source_countryname ?
                        <div className="location_icon">
                            <div className="titletip">
                                {item?.source_cityname ?
                                    <span>{item?.source_cityname}, </span> : null
                                }
                                <span>{item?.source_countryname}</span>
                            </div>
                        </div>
                        :
                        <div className="flag_icon">
                            <div className='no_flag' title='Country details not available'></div>
                        </div>
                    }
                </span>
            }
            <div className="copy_field ellips-hostname-issue">
                <div className="ellipsis_hostname droplist issue-page"
                    onScrollCapture={(e) => handleMounseLeaveHost(null, null, e)}
                >
                    <Tooltip
                        classes={{ tooltip: 'posture-root-column-container scrollbar-container' }}
                        title={
                            <div>
                                <h2 className='marginBottom5'>
                                    <span className="ellipsis_idassetname-ip"><strong>IP:Port</strong>{
                                        item.source_ip.startsWith('127.') ? ' - N.A.' :
                                            ` - ${item.source_ip.toString()}:${item.source_port.toString()}`}</span>
                                    <div className="copy_idassetname" title="Copy this text to Clipboard" onClick={() => navigator.clipboard.writeText(`${item.source_ip.toString()}:${item.source_port.toString()}`)}></div>
                                </h2>
                                {
                                    item?.src_company_name ?
                                        <h2>
                                            <span className="ellipsis_idassetname-ip"><strong>Organization: </strong>{`${item.src_company_name.toString()}`}</span>
                                            <div className="copy_idassetname" style={{ marginLeft: '100%', marginTop: '0.1875em' }} title="Copy this text to Clipboard" onClick={() => navigator.clipboard.writeText(`${item?.src_company_name?.toString() || ''}`)}></div>
                                        </h2> : null
                                }
                                {
                                    item?.src_mac ?
                                        <h2
                                            className='marginBottom5'
                                            style={{ paddingTop: 0 }}
                                            title={item.src_mac.toString()}>
                                            <span className="ellipsis_idassetname"><strong>MAC</strong>{` - ${item.src_mac.toString()}`}</span>
                                            <div className="copy_idassetname" title="Copy this text to Clipboard" onClick={() => navigator.clipboard.writeText(`${item.src_mac ? item?.src_mac.toString() : ''}`)}></div>
                                        </h2> : null
                                }
                                {item?.user_agent ?
                                    <h2
                                        style={{ paddingTop: 0 }} title={item.user_agent.toString()}>
                                        <span className="ellipsis_idassetname"><strong>User Agent</strong>{` - ${decodeURIComponent(item.user_agent.toString())}`}</span>
                                        <div className="copy_idassetname" title="Copy this text to Clipboard" onClick={() => navigator.clipboard.writeText(`${item.user_agent ? item.user_agent.toString() : ''}`)}></div>
                                    </h2> : null
                                }
                                {item.source_hostnames && item.source_hostnames.length && item.source_hostnames.length > 0 ?
                                    <table>
                                        <tr className='show_copy_btn'>
                                            <th className="align_left">Recent source hostnames
                                                <div className="copy_btn" title="Copy all IPs to clipboard"
                                                    onClick={() => navigator.clipboard.writeText(`${Array.from(new Set([item.source_ip.toString(),
                                                    ...item.source_hostnames.map((el: any) => el.ip).filter(el => el)]))}`)}></div>
                                            </th>
                                        </tr>
                                        {item.source_hostnames.map((el: any, index: any) => {
                                            return (<tr
                                                key={el.hostname + '-' + index}
                                                onMouseLeave={(e) => handleMounseLeaveHost(index, el.name, e)}
                                                onScroll={(e) => handleMounseLeaveHost(index, el.name, e)}
                                            >
                                                <td className="hostname_td align_left display-flex-column">
                                                    <div style={{ display: 'flex' }}>
                                                        <span className="ellipsis_idassetname"
                                                            onMouseOver={(e) => handleMouseEnterHost(index, el.name, e)}>
                                                            <div className="flag_icon" style={{ position: 'relative' }}>{
                                                                el.location ?
                                                                    <AMCountryFlag countryCode={el?.location} />
                                                                    : <div className='no_flag'></div>
                                                            }
                                                            </div>
                                                            <span style={{
                                                                marginLeft: !el.location ? '' : el.location && window.matchMedia("(min-width: 2560px)").matches ? 10 : 10
                                                            }}>{el.name}</span></span>
                                                        <div style={{
                                                            flex: 1
                                                        }}>
                                                            <div
                                                                style={{ position: 'relative' }}
                                                                className="copy_idassetname" title="Copy this text to Clipboard" onClick={() => navigator.clipboard.writeText(el.name)}></div>
                                                        </div>
                                                    </div>
                                                    <div className={`hidden-block host-details-tooltip paddingLeft20 host-name-list-${index}-${el.name}`} >
                                                        {el.name ? <li>
                                                            <strong>Hostname</strong>: {el.name}</li> : null}
                                                        {el.country ? <li><strong>Country</strong>: {el.country}</li> : null}
                                                        {el.city ? <li><strong>City</strong>: {el.city}</li> : null}
                                                        {el.ip ? <li><strong>Ip</strong>: {el.ip}</li> : null}
                                                    </div>

                                                </td>
                                            </tr>)
                                        })}
                                    </table>
                                    : null
                                }
                            </div>
                        }
                    >
                        <div style={{ display: 'flex' }}>
                            <span style={{ display: 'inline-block' }} className='ellipsis'>{item.latest_source_hostname ? item.latest_source_hostname : ''}
                            </span>

                            {
                                item.source_hostnames?.length > 1 ?
                                    <span><strong>&nbsp;({'+'.concat((item.source_hostnames?.length - 1).toString())})</strong></span> : null
                            }

                        </div>
                    </Tooltip>
                </div>
                <InfoIcon style={{ fontSize: 14, color: '#d4d8e1', marginTop: 2, transform: 'skewX(-10deg)' }} />
                <span style={{ marginLeft: '2px' }} className="copy_hostname" title="Copy this text to Clipboard" onClick={() => navigator.clipboard.writeText(item.latest_source_hostname ? item.latest_source_hostname : '')}></span>
            </div>
        </div>
    </div>
}