
import React, {
    useMemo
} from "react";

import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-quartz.css";
import './AMBaseGrid.scss';
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import {
    ModuleRegistry
} from "@ag-grid-community/core";
import { RowGroupingModule } from "@ag-grid-enterprise/row-grouping";
import { ServerSideRowModelModule } from "@ag-grid-enterprise/server-side-row-model";
import './AMBaseGrid.scss';
import { InfiniteRowModelModule } from '@ag-grid-community/infinite-row-model';

import { AgGridReact, AgGridReactProps } from 'ag-grid-react';

ModuleRegistry.registerModules([ClientSideRowModelModule,
    RowGroupingModule, ServerSideRowModelModule,
    InfiniteRowModelModule
]);

interface IBaseAMGrid<T> extends AgGridReactProps {
    gridRef
}

export const BaseAMGrid = <T,>(props: IBaseAMGrid<T>) => {
    const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

    return <div style={containerStyle}>
        <div style={gridStyle} className={
            "base-am-grid-container ag-theme-quartz"
        }><AgGridReact<T>
                ref={props.gridRef}
                {...props}
            />
        </div>
    </div>
}