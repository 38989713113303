import ModalPopup from "../../../../components/core/AMModalPopup/ModalPopup";
import { PlaybookMap, RiskOptionConfig, POSTURE_ISSUE, DASHBOARD_CATEGORY, ENTITIES } from "../../constants/Constants";
import { RuleDescription } from "../../constants/RuleDescription";
import { RiskOption } from "../../constants/types";
import { MenuItem, Select } from '@mui/material';
import './PlaybookModal.scss';
import { useEffect, useState } from "react";
import { PlaybookRules } from "./PlaybookRules";
import React from "react";
import { PlaybookActions } from "./PlaybookActions";
import { PlaybookService } from "./PlaybookService";
import { useLocation, useParams } from "react-router-dom";
import { SelectChangeEvent } from "@mui/material/Select";
import { getDefaultRiskByPlaybook, getIssueNameById, getPlaybookList, parseRulesFromApi, updateCategories, validateAgainstInterface } from "../../helpers/playbook-helper";
import PlaybookException from "./PlaybookException";
import {
    AMModalError
} from "../../../../components/core/AMModalPopup/AMModalError";
import { PlaybookErrors } from "./PlaybookErrors";
import moment from "moment";
import { IPlaybook, IPlaybookActionsState, IPlaybookModalProps, IPlaybookRule, IPlaybookRuleState } from "./PlaybookTypes";
import { validate } from "../../helpers/Validators";
import { PlaybookPriority } from "./PlaybookPriority";
import { deepCompare, generateCategoriesForValues } from "../../../../utils/util-methods";
import { CategoriesFields } from "../../../../constants/Categories";
import { IPatternPayload } from "../PatternForm/PatternFormGenerator";
import { CSVOverridePopUp } from "../CSVOverridePopUp";
import { usePlaybookConfig } from "../useCustomConfig";
import { PlaybookCloseIncPopUp } from "./PlaybookGeneral";
import PostureFilter from '../../../../components/core/PostureFilter/PostureFilter'
import { getFilterOperatorFromApiOperator } from '../../../../components/core/PostureFilter/posture-filter-utils';
import { PostureSearchTemplate, notSupportedKeys } from '../../../../pages/identity_asset/constants/PostureSearchTemplate';
import { supportedOperators } from '../../../issue_prev/issues/constant/issueTemplate';
import { useToasts } from "../../../../components/core";
import CheckboxAutocomplete from "../../../../components/core/AMAutoComplete/AMAutoComplete";
import { Api } from "../../../../components/Axios";
import { GLOBAL_SERVICE } from "../../../services/GlobalService";
import { GlobalConsts } from "../../../../GlobalConst";

const PlaybookModalTitle = {
    edit: 'Edit Playbook',
    view: 'View Playbook',
    create: 'New Playbook'
}

export const PlaybookModal = (
    {
        playbookImportConfig,
        isOpen, title,
        onCloseHandler,
        rule = '', formType = '', totalPlaybooks = 20, entityType, query,
        playbookId
    }: IPlaybookModalProps
) => {
    const [risk, setRisk] = useState('');
    const [rules, setRules] = useState<Array<IPlaybookRuleState>>();
    const [pbName, setPbName] = useState('');
    const [actions, setActions] = useState<Array<IPlaybookActionsState>>();
    const { Playbook } = PlaybookService();
    const location = useLocation();
    const params = useParams<any>();
    const [ruleType, setRuleType] = useState(rule);
    const [errors, setErrors] = useState({});
    const [playbookData, setPlaybookData] = useState<IPlaybook>();
    const [responseData, setResponseData] = useState([]);
    const [createData, setCreateData] = useState<any>();
    const [priorityItems, setPriorityItems] = useState<any>();
    const [priorityFlag, setPriorityFlag] = useState<boolean>(false);
    const [confirmEdit, setShowConfirmEdit] = useState({ show: false, close: false, edit: false });
    const [csvOverrideWarning, setCSVOverrideWarning] = useState(false);
    const [isSavedClicked, setIsSavedClicked] = useState(false);
    const [dashboardCategory, setDashboardCategory] = useState('id_access_infra');
    const [tags, setTags] = useState<Array<string>>([]);
    const [entity, setEntity] = useState(entityType ? entityType : '');
    const [isFormValid, setIsFormValid] = useState(true);
    let postureRules = null;
    const { addToast } = useToasts();

    const { updateResponseBodyBeforeLoad } = usePlaybookConfig();

    useEffect(() => {
        let tempTags: string[] = [];        
        if (query && query.q) {
            tempTags = query.q.split('+');
        }        
        if (tempTags.length > 0) {
            setTags(tempTags);
        } else {
            setTags([]);
        }
    }, [query]);

    const savePlaybookRules = (data: Array<IPlaybookRuleState>) => {
        setRules(data);
        setErrors({});
    }

    useEffect(() => {
        if (playbookData && playbookData?.issue_rule_list && rules && rules?.length > 0 && formType == 'edit') {
            const parsedRules = parseRules(playbookData?.issue_rule_list, playbookData.issue_type);
            const same = deepCompare(parsedRules, rules)
            if (!same) {
                setShowConfirmEdit({ edit: true, show: false, close: false });
            } else {
                setShowConfirmEdit({ edit: false, show: false, close: false });
            }
        }
    }, [
        rules, playbookData
    ])

    useEffect(() => {
        Playbook.GET_ALL(totalPlaybooks, (data) => {
            if (data) {
                let tempPbArr: any = [];
                if (data.result !== undefined && data.result.length !== 0) {
                    data.result.map((item: IPlaybook, index: any) => {
                        tempPbArr.push({
                            "ruletype": item.issue_type,
                            "risk": item.risk,
                            "playbook_name": item.playbook_name,
                            "_id": item._id,
                            "order": item.order
                        })
                    })
                }
                setResponseData(tempPbArr);
            }
        })
    }, [])

    useEffect(() => {
        const state = location?.state as any;
        const r = state?.ruleName || '';
        const id = params?.id || playbookId;
        if (id) {
            Playbook.GET(id, (res: any) => {
                if (['edit', 'view'].includes(formType)) {
                    setPbName(res.playbook_name);
                }
                loadPlaybookData(res);
            }, (data: any) => {
                addToast(data, {
                    appearance: 'error',
                    autoDismiss: true,
                });
                onCloseHandler();

            });
        }
    }, [playbookId])

    useEffect(() => {
        if (playbookImportConfig) {
            const valid = validateAgainstInterface(playbookImportConfig)
            if (!valid) {
                setTimeout(() => {
                    setErrors({
                        import: {
                            type: 'invalidJson'
                        }
                    })
                }, 500)
            } else {
                loadPlaybookData(playbookImportConfig)
            }
        }
    }, [playbookImportConfig])

    const loadPlaybookData = (res) => {
        if (res['is_posture_playbook']) {
            setEntity(res['posture_entity_type'].toUpperCase());
            setTags(getTagsFromApi(res['issue_rule_list'][0]['search_field_info']['search_field']))
            setRuleType(POSTURE_ISSUE.type);
            setDashboardCategory(res['dashboard_widget']);
            setActions(res.actions);
        } else {
            setActions(res.actions);
            updateCategories(res);
            setRuleType(res.issue_type);
            setRules(parseRules(res.issue_rule_list, res.issue_type));
        }
        const rsk = RiskOptionConfig.find((i: RiskOption) => i.priority === res.risk)?.key || 'lw';
        setRisk(rsk);
        setPlaybookData(structuredClone(res));
    }

    const getTagsFromApi = (data: any) => {
        let res = [];
        for (let i in data) {
            res.push(`${i}${getFilterOperatorFromApiOperator(data[i]['type'])}${data[i]['value']}`);
        }
        return res;
    }

    const parseRules = (issueRulesList: IPlaybookRule[], ruleTyp: string) => {
        return parseRulesFromApi(issueRulesList, ruleTyp);
    }

    useEffect(() => {
        // validateOnSave();
    }, [actions, rules])

    useEffect(() => {
        // validateOnSave();
    }, [rules])

    useEffect(() => {
        if (formType == 'create') {
            const defaultRisk = getDefaultRiskByPlaybook(ruleType);
            const riskId = RiskOptionConfig.find((i: RiskOption) => i.value == defaultRisk)?.key || 'lw';
            setRisk(riskId);
            setDashboardCategory(DASHBOARD_CATEGORY[0].key);
        }
    }, [ruleType])

    const validateOnSave = () => {
        if (formType == 'view') return true;
        setErrors({});
        if (!pbName || pbName.length == 0) {
            setErrors({ name: { type: 'emptyName' } });
            return false;
        } else if (pbName.includes(',') || pbName.includes('+')) {
            setErrors({ name: { type: 'invalidName' } });
            return false;
        } else if (pbName.length > 150) {
            setErrors({ name: { type: 'nameLength' } });
            return false;
        }
        /* if (!actions || actions.length == 0) {
            setErrors({ actions: { type: 'noActions' } });
            return false;
        } */
        if (rules && rules?.length > 0) {
            for (let index = 0; index < rules.length; index++) {
                const rule = rules[index];
                const err = validate(ruleType || '', rule.config, false, rule);
                if (err) {
                    setErrors({ issueConfig: { type: 'Issue Config', message: `Rule ${index + 1} - ${err}` } });
                    return false;
                }
            }
            const r = [...rules];
            const set = new Set();

            for (let index = 0; index < r.length; index++) {
                // Verify 
                const rule = r[index];
                if (rule?.identity?.pattern && Object.keys(rule?.identity?.pattern)?.length > 0) {
                    const sortedKeys = Object.keys(rule?.identity?.pattern).sort();
                    const pattern = {} as any;
                    sortedKeys.forEach(key => {
                        pattern[key] = rule?.identity?.pattern[key]
                    });
                    rule.identity.pattern = pattern
                }
                if (rule?.asset?.pattern && Object.keys(rule?.asset?.pattern)?.length > 0) {
                    const sortedKeys = Object.keys(rule?.asset?.pattern).sort();
                    const pattern = {} as any;
                    sortedKeys.forEach(key => {
                        pattern[key] = rule?.asset?.pattern[key]
                    });
                    rule.asset.pattern = pattern
                }
                if (rule?.intermediary?.pattern && Object.keys(rule?.intermediary?.pattern)?.length > 0) {
                    const sortedKeys = Object.keys(rule?.intermediary?.pattern).sort();
                    const pattern = {} as any;
                    sortedKeys.forEach(key => {
                        pattern[key] = rule?.intermediary?.pattern[key]
                    });
                    rule.intermediary.pattern = pattern
                }
                set.add(JSON.stringify(rule).toLowerCase());
            }

            if (set.size != r.length) {
                setErrors({ issueConfig: { type: 'Issue Config', message: `Duplicate Issue Config found.` } });
                return false;
            }
        }
        return true;
    }

    const validatePlaybookIssuesOnSave = () => {
        if (formType == 'view') return true;
        setErrors({});
        if (!pbName || pbName.length == 0) {
            setErrors({ name: { type: 'emptyName' } });
            return false;
        } else if (pbName.includes(',')) {
            setErrors({ name: { type: 'invalidName' } });
            return false;
        }
        return true;
    }

    const updateCategoriesRequest = (pattern: Record<string, IPatternPayload>) => {
        if (pattern) {
            Object.keys(pattern).forEach((i) => {
                if (CategoriesFields.includes(i)) {
                    pattern[i].value = generateCategoriesForValues(pattern[i].value) as any;
                }
            })
        }
    }

    const updateKeyVaueDuringSave = (pattern: Record<string, IPatternPayload>) => {
        if (pattern) {
            Object.keys(pattern).forEach((index) => {
                if (pattern[index].value?.length > 0) {
                    pattern[index].value = pattern[index].value.map((i) => {
                        if (i?.key) {
                            i = i?.key;
                        }
                        if(index === 's_type' || index === 'd_type'){
                            i = i === 'Service' ? 'Service/Service Account' : i === 'Application' ? 'Service/Application':i; 
                        }
                        return i;
                    });
                }
            })
        }
    }

    const handlePlaybookSave = () => {
        const isValid = validateOnSave();
        if (!isValid) return;
        const issue_rule_list: any = [];
        /* Create copy of rules */
        const copyRules = JSON.parse(JSON.stringify(rules));
        copyRules?.forEach((rule: IPlaybookRuleState) => {
            updateKeyVaueDuringSave(rule.identity.pattern)
            updateKeyVaueDuringSave(rule.asset.pattern)
            updateKeyVaueDuringSave(rule?.intermediary?.pattern)
            updateCategoriesRequest(rule.identity.pattern);
            updateCategoriesRequest(rule.asset.pattern);
            updateCategoriesRequest(rule?.intermediary?.pattern);

            issue_rule_list.push({
                ...(rule.config && rule.config != 'NA' && rule.config) as Object,
                ...(rule.identity.type[0] == 'No Identities' && { no_identities: true }),
                ...(rule.asset.type[0] == 'No Assets' && { no_assets: true }),
                search_field_info: {
                    search_field: {
                        ...rule.identity.pattern,
                        ...rule.asset.pattern,
                        ...rule?.intermediary?.pattern
                    }
                }
            })
        });

        const rsk = RiskOptionConfig.find((r: RiskOption) => r.key == risk)?.priority;
        const playbook = {
            playbook_name: pbName,
            issue_type: ruleType,
            actions, risk: rsk,
            issue_rule_list
        }

        if (formType == 'edit') {
            if (confirmEdit.edit) {
                setCreateData(playbook);
                setShowConfirmEdit({ ...confirmEdit, show: true, close: false });
            } else {
                updatePb(playbook, false, false);
            }

        } else {
            let filter: any = {
                ruletype: playbook.issue_type,
                risk: playbook.risk
            };
            const pbPriority = responseData.filter(function (item: any) {
                for (var key in filter) {
                    if (item[key] === undefined || item[key] != filter[key])
                        return false;
                }
                return true;
            }) as any;

            if (pbPriority.length > 0) {
                setCreateData(playbook);
                pbPriority.sort((a: any, b: any) => { return a.order - b.order });
                pbPriority.unshift({
                    ruletype: playbook.issue_type,
                    risk: playbook.risk,
                    playbook_name: playbook.playbook_name,
                    _id: 'newItem'
                });
                setPriorityItems(pbPriority);
                setPriorityFlag(true);
            }
            else {
                createPB(playbook);
            }
        }
    }

    const handlePlaybookIssuesSave = () => {
        const isValid = validatePlaybookIssuesOnSave();
        if (!isValid) return;
        const issue_rule_list: any = [];
        issue_rule_list.push({
            search_field_info: {
                search_field: getPlaybookIssuesRules()
            }
        })

        const rsk = RiskOptionConfig.find((r: RiskOption) => r.key == risk)?.priority;
        const playbook = {
            playbook_name: pbName,
            issue_type: ruleType,
            actions, risk: rsk,
            issue_rule_list,
            is_posture_playbook: true,
            posture_entity_type: entity && ENTITIES[entity] ? ENTITIES[entity]['apiVal'] : '',
            dashboard_widget: dashboardCategory
        }

        if (formType == 'edit') {
            if (confirmEdit.edit) {
                setCreateData(playbook);
                setShowConfirmEdit({ ...confirmEdit, show: true, close: false });
            } else {
                updatePb(playbook, false, false);
            }

        } else {
            let filter: any = {
                ruletype: playbook.issue_type,
                risk: playbook.risk
            };
            const pbPriority = responseData.filter(function (item: any) {
                for (var key in filter) {
                    if (item[key] === undefined || item[key] != filter[key])
                        return false;
                }
                return true;
            }) as any;

            // if (pbPriority.length > 0) {
            //     setCreateData(playbook);
            //     pbPriority.sort((a: any, b: any) => { return a.order - b.order });
            //     pbPriority.unshift({
            //         ruletype: playbook.issue_type,
            //         risk: playbook.risk,
            //         playbook_name: playbook.playbook_name,
            //         _id: 'newItem'
            //     });
            //     setPriorityItems(pbPriority);
            //     setPriorityFlag(true);
            // }
            // else {
            //     createPB(playbook);
            // }
            createPB(playbook);
        }
    }

    const getPlaybookIssuesRules = () => {
        let res = {};
        if (postureRules?.length > 0) {
            postureRules.forEach(rule => {
                if (rule['isValid'])
                    res[rule['search_key']] = { type: rule['operator'], value: rule['value'] }
            })
        }
        return res;
    }

    const createPB = (playbookData) => {        
        // return;
        Playbook.POST(playbookData,
            () => {
                onCloseHandler();
            },
            (data) => {
                setPriorityFlag(false);
                setErrors({ issueConfig: { type: 'Error', message: data } });
            }
        );
    }

    const updatePb = (playbook, show, close) => {
        setShowConfirmEdit({ ...confirmEdit, show, close });
        playbook.close_incidents_on_edit = close;
        Playbook.PUT(playbook, params.id, () => {
            onCloseHandler();
        }, (data) => {
            setPriorityFlag(false);
            setErrors({ issueConfig: { type: 'Error', message: data } });
        });
    }

    const savePlaybookActions = (data: Array<IPlaybookActionsState>) => {
        setActions(data);
    }

    const cancelPlaybookCreate = () => {
        onCloseHandler(true);
    }

    const onModalClose = () => {
        if (formType == 'create') {
            onCloseHandler(true);
        } else {
            onCloseHandler();
        }
    }

    const handleCsvProceed = (data: any, isHeader: boolean) => {
        setCSVOverrideWarning(false);
        const d = updateResponseBodyBeforeLoad(ruleType || '', data, isHeader, { isCSV: true });
        setRules(d);
    }

    const viewQueryResult = () => {
        if (entity && ENTITIES[entity.toUpperCase()])
            window.open(`/posture/${ENTITIES[entity.toUpperCase()].path}?order_by=desc&page=1&sort_by=score&playbook_type=${POSTURE_ISSUE.type}&q=${encodeURIComponent(getPostureRulesQuery())}`, '_posturescreen')
    }

    const onApplyFilter = (postureRulesData: any) => {
        postureRules = postureRulesData;
    }

    const validFormCheck = (formValid: boolean) => {
        setIsFormValid(formValid);
    }

    const getPostureRulesQuery = () => {
        if (postureRules?.length > 0) {
            const searchTextList = [] as Array<string>;
            postureRules.forEach(rule => {
                let filter = rule['search_key'];
                let filterValue = rule['value'];
                let opr =
                    supportedOperators.get(String(rule['operator'])) ||
                    '';
                if (filter === 'any_field') {
                    filterValue.forEach((p) => {
                        if (p) {
                            searchTextList.push(p);
                        }
                    });
                } else {
                    searchTextList.push(`${filter}${opr}${filterValue}`);
                }
            });

            if (searchTextList.length > 0) {
                return searchTextList.join('+');
            }
        }
    }

    const getItemLabel = (op) => {        
        if (op?.hasOwnProperty('key')) {
            if (op?.key) {
                return op.value
            } else {
                return ''
            }
        }
        return op || ''
    }

    const renderRiskOptions = (props, value: RiskOption) => {
        return <MenuItem {...props} id={value.key} value={value.key} className={value.key + ' risk-menu-item'}
        ><span className="risk-menu-item-text">{value.value}</span></MenuItem>
    }

    const getRiskValue = (risk: String) => {        
        const rsk = RiskOptionConfig.filter((value: RiskOption) => value.key == risk)[0];
        if (rsk) {
            return rsk.value;
        }
        return ""
    }

    const getDashboardCategoryValue = (cat) => {
        return DASHBOARD_CATEGORY.find((option) => option.key == cat)?.value || '';
    }

    const getIssueValue = () => {
        if (ruleType) {
            const rule = getPlaybookList().filter((i) => i.key == ruleType)[0];
            if (rule && rule.value) {
                return rule.value
            }
        }
        return ""
    }

    const preSetMetaDataHandler = (res) => {
        res.data.dir_type = ['On-premise', 'Cloud IDP'];
        res.data.identity_is_public = ['true', 'false'];
        res.data.asset_is_directory = ['true', 'false'];
        res.data.asset_is_public = ['true', 'false'];
        res.data.asset_is_saas = ['true', 'false'];
        res.data.asset_is_known = ['true', 'false'];
        res.data.identity_is_known = ['true', 'false'];
        res.data.first_activity_time = ['hour(s)', 'day(s)'];
        res.data.latest_activity_time = ['hour(s)', 'day(s)'];
        res.data.id_attrs_change_timestamp = ['hour(s)', 'day(s)'];
        if (res.data.access_playbooks) {
            res.data.access_playbooks = res.data.access_playbooks.map((i) => ({ ...i, key: i.id, value: i.name })).sort((a, b) => a.name.localeCompare(b.name));
        }
        if (res.data.asset_playbooks) {
            res.data.asset_playbooks = res.data.asset_playbooks.map((i) => ({ ...i, key: i.id, value: i.name })).sort((a, b) => a.name.localeCompare(b.name));
        }

        if (res.data.directory_playbooks) {
            res.data.directory_playbooks = res.data.directory_playbooks.map((i) => ({ ...i, key: i.id, value: i.name })).sort((a, b) => a.name.localeCompare(b.name));
        }
        if (res.data.identity_playbooks) {
            res.data.identity_playbooks = res.data.identity_playbooks.map((i) => ({ ...i, key: i.id, value: i.name })).sort((a, b) => a.name.localeCompare(b.name));
        }
        res.data.rule_name = res.data.rule_name.map((i) => ({ key: i, value: getIssueNameById(i) || i }))
        res.data.risk = res.data.risk.map((i) => ({ key: i, value: GlobalConsts.RISK[i] }))
    };

    const preMetaDataHandler = (setMetaData) => {
        const fetchFilterMetaData = () => {
            return Api.get('/posture/uniquefields');
        };
        fetchFilterMetaData()
            .then(async (res) => {
                preSetMetaDataHandler(res);
                const arr = await GLOBAL_SERVICE.Category.GET();
                const data = { ...res.data, ...arr };
                setMetaData(data);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    return <>
        <ModalPopup onCloseHandler={onModalClose}
            isOpen={isOpen}
            title={PlaybookModalTitle[formType as keyof typeof PlaybookModalTitle] + ': ' + getIssueValue()}
            classNames="advanced-playbook-modal-root-container"
            subTitle={
                <RuleDescription ruleType={ruleType} showLink={true} />
            }
        >
            <div>
                <div style={{ width: "100%" }}>
                    {/* <div style={{ display: 'flex' }}>
                        <RuleDescription ruleType={ruleType} showLink={true} />
                    </div> */}
                    <div className="advanced-playbook-modal-container">
                        <div className="advanced-playbook-general-container">
                            <div className="pb-name-container"><label><strong>Name</strong></label><input
                                type='text'
                                readOnly={['view'].includes(formType)}
                                value={pbName}
                                placeholder="Playbook Name"
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    setPbName(e.target.value);
                                    setErrors({});
                                }}
                            /></div>
                            {ruleType === POSTURE_ISSUE.type && (
                                <div className="pb-name-container">
                                    <label>Dashboard Category</label>
                                    <CheckboxAutocomplete
                                        disabled={['view', 'edit'].includes(formType)}
                                        options={DASHBOARD_CATEGORY}
                                        getItemLabel={getItemLabel}
                                        value={{ key: dashboardCategory, value: getDashboardCategoryValue(dashboardCategory) }}
                                        multiple={false}
                                        hideClearBtn={true}
                                        onChange={(e: SelectChangeEvent, config) => {
                                            setDashboardCategory(config[0].key);
                                        }}
                                        classes={{ root: "width300Pb" }}
                                    />
                                </div>
                            )}

                            <div className="pb-name-container">
                                <label>Risk</label>
                                <CheckboxAutocomplete
                                    options={RiskOptionConfig}
                                    disabled={['view', 'edit'].includes(formType)}
                                    value={{ key: risk, value: getRiskValue(risk) }}
                                    onChange={(e: SelectChangeEvent, config: Array<RiskOption>) => {
                                        setRisk(config[0].key);
                                    }}
                                    getItemLabel={getItemLabel}
                                    multiple={false}
                                    hideClearBtn={true}
                                    renderOptionsFn={renderRiskOptions}
                                    classes={{ root: "width150Pb" }}
                                    renderCustomInput={(inputProps, placeholderText, className, disabled) => {
                                        const risk = RiskOptionConfig.find((option: RiskOption) => option.value == inputProps.value);
                                        return <input
                                            style={{
                                                flexBasis: "109px !important",
                                                flexGrow: "0",
                                                width: "100%",
                                            }}
                                            placeholder={placeholderText}
                                            type="text"
                                            {...inputProps}
                                            className={className + " " + inputProps.className + ((` ${risk?.key}` || '') + ' risk-option-render-value')}
                                            disabled={disabled}
                                        />
                                    }}
                                />
                            </div>
                        </div>
                        {ruleType === POSTURE_ISSUE.type ? (
                            <>
                                <hr></hr>
                                <div className="adv-pb-rules-section">
                                    <div className='margin-top-bottom-8'>
                                        <span className='section-heading'>Rules</span>
                                    </div>
                                    <div style={{ marginBottom: "10px" }}><span className="rule-type-description">This playbook is searching <strong>{entity && ENTITIES[entity] ? ENTITIES[entity]['label1'] : ''}</strong> and will create a new incident for every {entity && ENTITIES[entity] ? ENTITIES[entity]['label2'] : ''} that matches the below conditions:</span></div>
                                    <div className='adv-pb-rules-data-container scrollbar-container'>
                                        <PostureFilter
                                            filterTemplate={PostureSearchTemplate}
                                            notSupportedKeys={notSupportedKeys}
                                            isOpen={true} onApplyFilter={onApplyFilter}
                                            onClose={() => { }}
                                            onlyFields={true}
                                            entityType={entity?.toUpperCase()}
                                            filters={tags}
                                            validFormCheck={validFormCheck}
                                            preMetaDataHandler={preMetaDataHandler}
                                        />
                                        <div
                                            className={`btn_styled view-query-btn ${(!isFormValid) &&
                                                'disabled'
                                                }`}
                                            onClick={isFormValid ? viewQueryResult : () => { }}
                                        >
                                            View Query Results
                                        </div>
                                    </div >
                                </div>
                            </>
                        ) : (
                            <>
                                <PlaybookRules ruleType={ruleType} formType={formType} saveRules={savePlaybookRules} defaultRules={rules} />
                                <PlaybookException ruleType={ruleType} title="open Exception" />
                            </>
                        )}
                        <PlaybookActions ruleType={ruleType} formType={formType} saveActions={savePlaybookActions} defaultActions={actions} />
                    </div>

                </div>
            </div>
            <AMModalError errors={errors} errorMap={PlaybookErrors} />
            <div className="popup-container-button-container dialog-button">
                <div
                    className={"float_right btn_styled btn_gray"}
                    onClick={cancelPlaybookCreate}
                >
                    {formType === 'view' ? 'Close' : 'Cancel'}
                </div>
                {formType != 'view' ? (ruleType === POSTURE_ISSUE.type ? <div
                    className={`float_right btn_styled save_btn ${(!isFormValid) &&
                        'disabled'
                        }`}
                    onClick={isFormValid ? handlePlaybookIssuesSave : () => { }}
                >
                    Save
                </div> : <div
                    className={"float_right btn_styled save_btn " + (playbookImportConfig && errors?.import ? ' disabled ' : '')}
                    onClick={handlePlaybookSave}
                >
                    Save
                </div>) : <></>}
                {
                    ruleType && ruleType !== POSTURE_ISSUE.type && PlaybookMap[ruleType as keyof typeof PlaybookMap]?.showImportCSV && formType == 'create' ?
                        <>
                            {/*  <input
                          type={"file"}
                          id={"csvFileInput"}
                          accept={".csv"}
                          onChange={handleImportCSV}
                          hidden
                        /> */}
                            <div type={"button"}
                                className={"float_right btn_styled save_btn"}
                                onClick={() => setCSVOverrideWarning(true)}
                            >
                                Import CSV
                            </div>
                        </> :
                        null
                }
                {playbookData && formType != 'create' && <div className="font12 modified_section_note">
                    <span>Last Modified by <strong>{playbookData?.last_modified_by}</strong>
                        <br></br>
                        On {moment(playbookData.modified_date).format('MMM DD YYYY')}
                    </span>

                </div>}
            </div>
        </ModalPopup >
        {priorityFlag &&
            <PlaybookPriority createData={createData} createPB={createPB} handlePriorityBack={() => setPriorityFlag(false)}
                priorityFlag={priorityFlag} priorityItems={priorityItems} setPriorityItems={setPriorityItems}
            />
        }
        {csvOverrideWarning ? <CSVOverridePopUp handleClose={() => setCSVOverrideWarning(false)}
            handleProceed={handleCsvProceed} ruleType={ruleType}
        /> : null}


        {confirmEdit.show && <PlaybookCloseIncPopUp showConfirm={confirmEdit.show}
            onCloseHandler={() => setShowConfirmEdit({ ...confirmEdit, show: false, close: false })}
            onNoHandler={() => updatePb(createData, false, true)}
            onKeepInc={() => updatePb(createData, false, false)}
        />}
    </>
}