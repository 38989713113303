import React, { useEffect, useState } from 'react';
import { Switch, Route, useLocation, useHistory, Redirect } from 'react-router-dom';
//import Dashboard from './Dashboard';
import Dashboard from './DashboardNew';
import Users from './users/listing';
import Groups from './groups/listing';
import MfaServers from './mfa_servers/listing';
import KnownApplications from './known_applications/listing';
import Proxy from './proxy/listing';
import GeneralSettings from './general_settings/listing';
import Exceptions from './exceptions/listing';
import Playbooks from '../pages/playbooks/grid/Playbooks';
import FlowsIssues from './FlowsIssues';
import Reports from './Reports';
import Tickets from './tickets/index';
import TicketDetails from './tickets/details';
import Sensors from './sensors/sensor';
import Directories from './directories/index';
import Intermediary from './intermediary/index';
import SMTP from './SMTP/Smtp';
import SSO from './sso/sso';
import AuditTrail from './audittrail/audittrail';
import DataFeed from './data_feed/DataFeed';
import Dns from './dns/dns';
import Certificate from './certificate/Ssl';
import Logout from './Logout';
import { useToasts } from '../components/core';
import Issues from './issue_prev/issues/issue';
import Identities from './identity_asset/identities/identities';
import Assets from './identity_asset/assets/assets';
import Accesses from './identity_asset/accesses/accesses';
import Directory from './identity_asset/directories/directories';
import { AMNotFound } from '../components/core/AMNotFound/AMNotFound';
import ArchivedData from './archived_data/ArchivedData';
import SensorDownload from './sensor_download/SensorDownload';
import collectors from './sensors/collectors';
import KnownPublicIps from './known_public_ips/KnownPublicIps';
import DirQueryParamListing from './dir_query_param/listing';
import { PostureStateContextProvider } from '../store/PostureStateContextProvider';
import EOD from './sensors/eod';
import SupportedCollector from './sensors/supportedCollector';
import manageCollector from './sensors/manageCollector';
import { ReleaseNotes } from './ReleaseNotes/ReleaseNotes';
import { ApiToken } from './ApiToken/Apitoken';
import ApiTokenListing from './ApiToken/ApiTokenListing';
import { Api } from '../components/Axios';
import { ITableResult } from '../components/core/AMTable/AMAdminTable';
import { AxiosResponse } from 'axios';
import PostureTab from './identity_asset/PostureTab';
import { useAuthDetails } from '../components/Authorization';
import { isAmopsUser } from '../utils/util-methods';
import AmopsRoleManagment from './amopsRoleManagement/AmopsRoleManagment';

/**  PATH EXLCUSION LIST
 *  [api]
 * * */

const Home = () => {

  const admin_menu = ["/users", "/mfa", "/directories", "/deploy/manageCollector", "/deploy/sensor",
    "/deploy/collector", "/deploy/supportedCollector", "/deploy", "/dirQueryParam", "/audittrail",
    "/dataFeed", "/dns", "/smtp", "/sso", "/certificate", "/known_applications", "/intermediary", "/exceptions",
    "/proxy", "/general_settings", "/archivedata", "/deploy", '/known_public_ip', '/sensor_download', '/token_mg','/role_view_management'];
  let location = useLocation()
  const { addToast } = useToasts()
  const history = useHistory();
  const { authDetails } = useAuthDetails()!;
  useEffect(() => {
    const data = window.localStorage.getItem('user')
    if (data !== null) {
      const user = JSON.parse(data)
      if (typeof (user.token) != 'undefined') {
        // socket.emit('authenticate', user.token)
      }
    }
  }, [])

  useEffect(() => {
    // socket.emit('path', location.pathname)
    const data = localStorage.getItem('refresh-needed');
    if (location.state == null && data && data == 'true') {
      console.log('App not updated... refreshing...');
      localStorage.removeItem('refresh-needed');
      window.location.reload();
      console.log('App refreshed');
    }
  }, [location])

  const [isAdminRoute, setIsAdminRoute] = useState(false);

  useEffect(() => {
    if (admin_menu.includes(location.pathname)) {
      setIsAdminRoute(true);
    } else {
      setIsAdminRoute(false);
    }
  }, [location.pathname]);

  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > 1500);

  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth > 1500);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  // Handle ResizeObserver overlay bug. This bug is part of nested dependency. Exact dependency/package unknown.
  window.addEventListener('error', e => {
    // Refactor below code later.
    // if (e.message === 'ResizeObserver loop completed with undelivered notifications.') {
    const resizeObserverErrDiv = document.getElementById(
      'webpack-dev-server-client-overlay-div'
    );
    const resizeObserverErr = document.getElementById(
      'webpack-dev-server-client-overlay'
    );
    if (resizeObserverErr) {
      resizeObserverErr.setAttribute('style', 'display: none');
    }
    if (resizeObserverErrDiv) {
      resizeObserverErrDiv.setAttribute('style', 'display: none');
    }
    // }
  });

  const [flag, setFlag] = useState(false);


  const fetchAdCollectorList = () => {
    Api.get(`/collectors/managed?category=ad-cloud-idp`)
      .then((resp: any) => {
        const data = resp.data as ITableResult<CollectorCollection>;


        if (resp.data.result.length === 0) {
          setFlag(true);
          history.push('/deploy/manageCollector');
        }
      })
      .catch(() => { }
      );
  };

  const fetchFeautreListing = () => {
    Api.get(`/is_feature_enabled?feature_name=TIME_BASED_SEARCH`)
      .then((resp: AxiosResponse<any>) => {
        localStorage.setItem("TIME_BASED_SEARCH_FEATURE", JSON.stringify(resp.data));
      })
      .catch(() => { }
      );
      Api.get(`/is_feature_enabled?feature_name=POSTURE_PLAYBOOK`)
      .then((resp: AxiosResponse<any>) => {
        localStorage.setItem("POSTURE_PLAYBOOK_FEATURE", JSON.stringify(resp.data));
      })
      .catch(() => { }
      );
  };

  useEffect(() => {
    fetchAdCollectorList();
    fetchFeautreListing();
  }, [])

  useEffect(() => {
    // Function to reload the page if screen width crosses 1900
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      const storedWidth = parseInt(localStorage.getItem("screenWidth"), 10);

      // Check if storedWidth exists and whether the threshold is crossed
      if (
        (storedWidth && storedWidth > 1900 && screenWidth <= 1900) ||
        (storedWidth && storedWidth <= 1900 && screenWidth > 1900)
      ) {
        window.location.reload(); // Reload the page
      }

      // Update stored screen width
      localStorage.setItem("screenWidth", screenWidth.toString());
    };

    // Set the initial screen width
    localStorage.setItem("screenWidth", window.innerWidth.toString());

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  const ProtectedRoute = ({ hasPermission, ...rest }) => {

  
    return hasPermission ? (

      <Route {...rest} />
    ) : (
      <Redirect to="/unauthorized" />
    );
  };


  return (
    <div className={
      `${isAdminRoute ? 'container admin-route' : 'container'} ${isWideScreen ? '' : 'no-wide-screen'}`
    }>
      <ReleaseNotes />
      <Switch>
        <Route exact path='/' component={Dashboard} />
        <Route exact path='/dashboard' component={Dashboard} />
        <Route exact path='/users' component={Users} />
        <Route exact path='/info' component={FlowsIssues} />
        <Route path='/issues' component={Issues} />
        {/* <Route path='/prevention' component={Prevention} /> */}
        <Route path='/playbooks' component={Playbooks} />
        <Route exact path='/reports' component={Reports} />
        <Route exact path='/groups' component={Groups} />
        <Route exact path='/mfa' component={MfaServers} />
        <Route exact path='/known_applications' component={KnownApplications} />
        <Route exact path='/proxy' component={Proxy} />
        {/* <Route exact path='/general_settings' component={GeneralSettings} /> */}
        <Route exact path='/exceptions' component={Exceptions} />
        <Route exact path='/exceptions/add' component={Exceptions} />
        <Route exact path='/tickets' component={Tickets} />
        <Route path='/tickets/:tid' component={TicketDetails} />
        <Route path="/deploy" component={EOD} />
        <Route exact path="/deploy/sensor" component={Sensors} />
        <Route exact path='/deploy/collectors' component={collectors} />
        <Route exact path='/deploy/manageCollector' component={manageCollector} />
        <Route exact path='/deploy/supportedCollector' component={SupportedCollector} />
        <Route exact path='/directories' component={Directories} />
        <Route exact path='/directories/add' component={Directories} />
        <Route exact path='/intermediary' component={Intermediary} />
        <Route exact path='/smtp' component={SMTP} />
        <Route exact path='/sso' component={SSO} />
        <Route exact path='/dirQueryParam' component={DirQueryParamListing} />
        <Route exact path='/audittrail' component={AuditTrail} />
        <Route exact path='/dataFeed' component={DataFeed} />
        <Route exact path='/dns' component={Dns} />
        <Route exact path='/certificate' component={Certificate} />
        {/* authDetails.permissions.Admin.api_token !== "invisible"
        <Route exact path='/archivedata' component={ArchivedData} /> */}
        <ProtectedRoute
        exact
        path="/archivedata"
        component={ArchivedData}
        hasPermission={authDetails.permissions.Admin.archive_data !== 'invisible'}
      />
        <Route exact path='/sensor_download' component={SensorDownload} />
        <Route exact path='/known_public_ip' component={KnownPublicIps} />
        <Route exact path='/known_public_ip/add' component={KnownPublicIps} />
        {/* <Route exact path='/token_mg' component={ApiTokenListing} /> */}
        <ProtectedRoute
        exact
        path="/token_mg"
        component={ApiTokenListing}
        hasPermission={authDetails.permissions.Admin.api_token !== 'invisible'}
      />
       {/* <Route exact path='/token_mg' component={ApiTokenListing} /> */}
       <ProtectedRoute
        exact
        path="/role_view_management"
        component={AmopsRoleManagment}
        hasPermission={isAmopsUser(authDetails.email)}
      />
        <Route exact path='/logout' component={Logout} />
        <Route path="/health">
          <h3>Hey There!!! The App is Healthy</h3>
        </Route>
        <Route path="/unauthorized">
          <h3>Hey There!!! Unauthorized access</h3>
        </Route>
        {/* <Route path="/playbooks/new" render={()=> <AddPlaybook/>}/> */}
        <Route path='/not-found' component={AMNotFound} />
        <PostureStateContextProvider>
          <Switch>
            <Route path='/posture' component={PostureTab} />
            <Route path='/posture/identities' component={Identities} />
            <Route path='/posture/assets' component={Assets} />
            <Route path='/posture/accesses' component={Accesses} />
            <Route path='/posture/directory' component={Directory} />
          </Switch>
        </PostureStateContextProvider>

        <Route component={AMNotFound} />
      </Switch>
    </div>
  )
}

export default Home