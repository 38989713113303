import type { CustomCellRendererProps } from "@ag-grid-community/react";
import { Tooltip } from "@mui/material";
import React, { FunctionComponent } from 'react';
import { AMCountryFlag } from "../../../../components/core/AMCountryFlag/AMCountryFlag";
import InfoIcon from '@mui/icons-material/Info';


interface AccessIdCellRendererProps extends CustomCellRendererProps {
    handleMouseEnterHost,
    handleMounseLeaveHost
}

export const AccessIdCell: FunctionComponent<AccessIdCellRendererProps> = ({ data, node,
    handleMouseEnterHost,
    handleMounseLeaveHost
}: AccessIdCellRendererProps) => {
    const item = data;

    let id_hostsList: any = [];

    item.identity_host_detail && item.identity_host_detail.forEach((group: any) => {
        if (group.hostname !== "" && group.hostname !== undefined && group.hostname !== null) {
            const hostname = group.hostname.startsWith('127.') ? 'N.A.' : group.hostname;
            if (item.identity_host_detail.length === 1 && group.hostname?.toLowerCase() === "unknown") {
                id_hostsList.push({
                    "hostname": hostname,
                    "city_name": group.city_name,
                    "country_code2": group.country_code2,
                    "country_name": group.country_name,
                    "ip": group?.ip && group.ip.startsWith('127.') ? 'N.A.' : group.ip,
                    "state_name": group.state_name
                })
            }
            else {
                if (group.hostname?.toLowerCase() !== "unknown")
                    id_hostsList.push({
                        "hostname": hostname,
                        "city_name": group.city_name,
                        "country_code2": group.country_code2,
                        "country_name": group.country_name,
                        "ip": group?.ip && group.ip.startsWith('127.') ? 'N.A.' : group.ip,
                        "state_name": group.state_name
                    })
            }
        }
    })

    return <div className="copy_field copy_host_count" style={{ minWidth: '290px' }}>
        <Tooltip
            classes={{ tooltip: 'posture-root-column-container scrollbar-container' }}
            title={
                <div className="service_list">

                    {item.identity_full_name?.toLowerCase() !== item.identity?.toLowerCase() ?
                        <div>
                            <h2 style={{ marginBottom: 0 }} >
                                <Tooltip classes={{ tooltip: 'posture-root-column-container scrollbar-container' }} title={<div>
                                    <div><b>Full Name: </b>{item.identity_full_name?.toString()}</div>


                                </div>}>
                                    <span className="ellipsis_idassetname tooltip_ellipses" >{item.identity_full_name?.toString()}</span>
                                </Tooltip>
                                <div className="copy_idassetname tooltip_copy_title" title="Copy this text to Clipboard" onClick={() => navigator.clipboard.writeText(item.identity_full_name)}></div>
                            </h2>
                            <h2 style={{ marginTop: 0, paddingTop: '6px', marginBottom: item?.identity_groups && item?.identity_groups.length > 0 ? '7px' : '10px' }} >
                                <span className="ellipsis_idassetname" title={item.identity?.toString()}>{item.identity?.toString()}</span>
                                <div className="copy_idassetname" title="Copy this text to Clipboard" onClick={() => navigator.clipboard.writeText(item.identity)}></div>
                            </h2>
                            {item?.identity_groups && item?.identity_groups.length > 0 && <h2 style={{ marginTop: '0px', paddingTop: '0px' }} className=''> <div className="float_left" ><b>Groups: <span>&nbsp;</span> </b></div> <div className="float_left" style={{ fontWeight: 'normal' }} >
                                {item?.identity_groups.join(', ')}

                            </div> </h2>

                            }
                        </div>
                        : <><h2 style={{ marginBottom: item?.identity_groups && item?.identity_groups.length > 0 ? '7px' : '10px' }}>

                            <Tooltip classes={{ tooltip: 'posture-root-column-container scrollbar-container' }} title={<div>
                                <div><b>Identity Id: </b>{item.identity?.toString()}</div>


                            </div>}>
                                <span className="ellipsis_idassetname tooltip_ellipses" >{item.identity?.toString()}</span>

                            </Tooltip>
                            <div className="copy_idassetname tooltip_copy_title" title="Copy this text to Clipboard" onClick={() => navigator.clipboard.writeText(item.identity)}></div>

                        </h2>
                            {item?.identity_groups && item?.identity_groups.length > 0 && <h2 style={{ marginTop: '0px', paddingTop: '0px' }} className=''> <div className="float_left" ><b>Groups: <span>&nbsp;</span> </b></div> <div className="float_left" style={{ fontWeight: 'normal' }} >
                                {item?.identity_groups.join(', ')}

                            </div> </h2>

                            }
                        </>

                    }

                    {id_hostsList.length > 0 ?
                        <table>
                            <tr>
                                <th className="align_left">Hosts</th>
                            </tr>
                            {id_hostsList.map((group: any, index: any) => {
                                if (index < 5) {
                                    return (<tr
                                        key={group.hostname + '-' + index}
                                        onMouseLeave={(e) => handleMounseLeaveHost(index, group.hostname, e)}
                                    >
                                        <td className="hostname_td align_left display-flex-column" onMouseOver={(e) => handleMouseEnterHost(index, group.hostname, e)} onMouseLeave={(e) => handleMounseLeaveHost(index, group.hostname, e)}>
                                            <div
                                                style={{ display: 'flex' }}
                                            >
                                                {group.country_code2 ?
                                                    <span className="flag_wrapper"
                                                    >
                                                        {group.country_name ?
                                                            <div className="flag_icon" style={{ position: 'relative' }}>
                                                                <AMCountryFlag countryCode={group.country_code2} />
                                                            </div>
                                                            :
                                                            null
                                                        }
                                                    </span>
                                                    :
                                                    <span className="flag_wrapper">
                                                        {group.country_name ?
                                                            <div className="location_icon">
                                                            </div>
                                                            :
                                                            <div className="flag_icon" style={{ position: 'relative' }}>
                                                                <div className='no_flag'></div>
                                                            </div>
                                                        }
                                                    </span>
                                                }
                                                <span
                                                    className="ellipsis_idassetname margin_left30">{group.hostname}</span>
                                                <div style={{ flex: '1' }}>
                                                    <div className="copy_idassetname"
                                                        style={{ position: 'relative', flex: 1, marginLeft: 0, float: 'right' }}
                                                        title="Copy this text to Clipboard" onClick={() => navigator.clipboard.writeText(group.hostname)}></div>
                                                </div>
                                            </div>
                                            <div className={`hidden-block  paddingLeft20 host-name-list-${index}-${group.hostname}`} >
                                                {group.hostname ? <li>
                                                    <strong>Hostname</strong>: {group.hostname}</li> : null}
                                                {group.country_name ? <li><strong>Country</strong>: {group.country_name}</li> : null}
                                                {group.state_name ? <li><strong>State</strong>: {group.state_name}</li> : null}
                                                {group.city_name ? <li><strong>City</strong>: {group.city_name}</li> : null}
                                                {group.ip ? <li><strong>Ip</strong>: {group.ip}</li> : null}
                                            </div>

                                        </td>
                                    </tr>)
                                }
                            })}
                            {id_hostsList.length > 5 ?
                                <tr>
                                    <td className="hostname_td align_left">
                                        <span className="ellipsis_idassetname">...</span>
                                    </td>
                                </tr> : null}
                        </table>
                        : null
                    }
                </div>
            }>
            <span className={((item.is_known_identity === false && item?.identity_type === "User") ?
                "ellipsis_access_host droplist" :
                "ellipsis_access droplist access-page") + (id_hostsList.length <= 1 && item?.identity_type === "User" ? ' ellipsis_acces_host_ext' : ' ')}
            >
                <span style={{ display: 'inline-block', maxWidth: window.matchMedia("(min-width: 1901px)").matches ? '420px' : '260px', textOverflow: 'ellipsis', overflow: 'hidden' }}>{item.identity}</span>
                <span><InfoIcon style={{ fontSize: 14, color: '#d4d8e1', marginTop: 2, marginLeft: 2, transform: 'skewX(-10deg)' }} /></span>
            </span>
        </Tooltip>
        <div className="copy_access" title="Copy this text to Clipboard" onClick={() => navigator.clipboard.writeText(item.identity)}></div>
        {(item.is_known_identity === false && item?.identity_type === "User" && id_hostsList.length > 1) ? <span className="host_count">
            <i>(on multiple hosts)</i></span> : null}
    </div>
}