import React, { useState, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useToasts } from "../../../components/core";
import { Api } from "../../../components/Axios";
import { useAuthDetails } from "../../../components/Authorization";

import { PasswordStrengthMeter } from "../../../components/PasswordStrengthMeter";
import "./add-user.scss";
import { UserRoleDisplay } from "./UserRoleDisplay";
import InfoIcon from '@mui/icons-material/Info';
import { AMModalError } from "../../../components/core/AMModalPopup/AMModalError";
import { UserError } from "./UserError";
import CheckboxAutocomplete from "../../../components/core/AMAutoComplete/AMAutoComplete";

type User = {
  name?: string;
  email?: string;
  password?: string;
  confirm_password?: string;
  role?: string;
  // group?: []
};

interface Props {
  AfterUserOperation?: any;
  hidePasswordFields?: boolean;
}

export const AddPanel = ({ AfterUserOperation, hidePasswordFields }: Props) => {
  const { authDetails } = useAuthDetails()!;
  const { register, handleSubmit, errors, watch, setError, control, setValue } = useForm<User>({
    mode: "onChange",
    defaultValues: {
      role: [],
    },
  });
  const values = watch();
  const [loading, setLoading] = useState<Boolean>(false);
  const [sErrors, setSErrors] = useState<User>();
  const [passWd, setPassWd] = useState("");
  const [roles, setRoles] = useState<string[]>([]);
  const { addToast } = useToasts();
  const [openConfirm, setOpenConfirm] = useState(false);
  const closeConfirm = () => setOpenConfirm(false);

  // let options: import("react-multi-select-component/dist/lib/interfaces").Option[] = []

  const isNameValid = (value: string) => {

    const pattern = /^[a-zA-Z\u0590-\u05FF\s]*$/;

    if (!pattern.test(value)) {
      return "Name should not contain special characters and numbers";
    }

    if (value.length < 5) {
      return "Name should be at least 5 characters long";
    }

    if (value.length > 30) {
      return "Name should be at most 30 characters long";
    }
    return true;
  };

  const onAddUser = (data: any) => {
    if (data.password !== data.confirm_password) {
      setError('confirm_password', { type: 'custom', message: "Password do not match" })
      return;
    }
    setLoading(true);
    Api.post("/users", data)
      .then((res: { data: any }) => {
        setLoading(false);
        AfterUserOperation("add");
        addToast("User added successfully.", {
          appearance: "success",
          autoDismiss: true,
        });
      })
      .catch((error: any) => {
        setLoading(false);
        if (error.response.status === 500) {
          addToast("Sorry, something went wrong there, try again.", {
            appearance: "error",
            autoDismiss: true,
          });
        } else if (error.response.status === 419) {
          const o = error.response.data;
          Object.keys(o).map((key: string) => {
            setError(key as keyof User, {
              type: 'custom',
              message: o[key]
            });
          });
        } else if (error.response.status === 404) {
          addToast("We are not able to find associated email, please check and try again.", {
            appearance: "error",
            autoDismiss: true,
          });
        }
      });
  };

  const fetchRoles = () => {
    Api.get("/roles")
      .then((res) => {
        if (res.status === 200) {
          setRoles(res.data);
        }
      })
      .catch((error: any) => { });
  };

  useEffect(() => {
    fetchRoles();
  }, []);

  const passwordChanged = (e: any) => {
    setPassWd(e.target.value);
  };

  const handleClick = () => {
    setOpenConfirm(true)
  }

  const getItemLabel = (
    option: { key: string; value: string } | { key: string; value: string }[]
  ) => {
    if (Array.isArray(option)) {
      return option[0]?.value ? option[0]?.value : "";
    } else {
      return option?.value ? option?.value : "";
    }
  };

  useEffect(() => {
    console.log({ errors })
  }, [errors])

  return (
    <form onSubmit={handleSubmit(onAddUser)}>
      <div className="add-user-form-container">
        <div className="form-control-group-left">
          <div className="form-control">
            <label>Name</label>
            <input
              type="text"
              placeholder="Enter your name"
              name="name"
              ref={register({
                required: "Please enter name.",
                validate: isNameValid,
              })}
              className={errors.name ? "error" : ""}
              tabIndex={1}
            />
          </div>
          {(authDetails.sessionindex === "" || authDetails.sessionindex === undefined) && !hidePasswordFields && (
            <div className="form-control">
              <label>Password</label>
              <input
                type="password"
                placeholder="Enter password"
                name="password"
                ref={register({
                  required: true,
                  minLength: { value: 12, message: 'Passwords minimum length is 12' }
                })}
                className={errors.password ? "error" : ""}
                onChange={(e) => {
                  passwordChanged(e);
                }}
                tabIndex={3}
              />
              <PasswordStrengthMeter password={passWd} />

            </div>
          )}
          <div className="form-control">
            <label>Role<span className='span-with-info-icon'><InfoIcon style={{ fontSize: 14, color: '#d4d8e1', transform: 'skewX(-10deg)' }} onClick={handleClick} /></span></label>
            {/* <select
              className="form_drodown"
              name="role"
              ref={register({
                required: true,
              })}
              tabIndex={5}
            >
              <option value="">Select Role</option>
              {roles?.map((item: any) => (
                <option key={item} value={item}>
                  {item}
                </option>
              ))}
            </select> */}

            <div className="role-dropdown-container" style={{ width: "94%" }}>
              <Controller
                name="role"
                control={control}
                defaultValue={[]}
                ref={register({
                  required: true,
                })}
                render={({ onChange, value = "" }) => (
                  <CheckboxAutocomplete
                    label="Select options"
                    options={roles.map(item => ({ key: item, value: item }))}
                    value={{ key: value, value }}
                    onChange={(event, newValue) => onChange(newValue[0]?.key)}
                    getItemLabel={getItemLabel}
                    multiple={false}
                    name="role"
                    formType="edit"
                    className={errors.role ? "error" : ""}
                  />
                )}
              />
            </div>

          </div>
        </div>
        <div className="form-control-group-right">
          <div className="form-control">
            <label>Email</label>
            <input
              type="text"
              placeholder="Enter your email"
              name="email"
              ref={register({
                required: true,
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "Please enter valid email.",
                },
              })}
              className={errors.email ? "error" : ""}
              tabIndex={2}
            />

          </div>
          {(authDetails.sessionindex === "" || authDetails.sessionindex === undefined) && !hidePasswordFields && (
            <div className="form-control">
              <label>Confirm Password</label>
              <input
                type="password"
                placeholder="Confirm your password"
                name="confirm_password"
                ref={register({
                  required: true,
                  validate: (value) => value === watch('password'),
                })}
                className={errors.confirm_password ? "error" : ""}
                tabIndex={4}
              />

            </div>
          )}

        </div>

      </div>
      <AMModalError errors={errors} errorMap={UserError} />
      <div className="add-edit-action-buttons dialog-button">
        <button type={loading ? "button" : "submit"} className={`float_right ${loading ? "loader" : "add-edit-submit"}`}>
          Save
        </button>
      </div>
      {openConfirm && <UserRoleDisplay closeConfirm={closeConfirm} />}
    </form>
  );
};
