import { CustomCellRendererProps } from "@ag-grid-community/react";
import React, { FunctionComponent } from "react";
import { PostureCategory } from "../../ref/PostureCategories/PostureCategory";
import { BasePostureActionMenu } from "../../ref/PostureActionMenus/BasePostureActionMenu";

interface AccessAssetTypeCellRendererProps extends CustomCellRendererProps { loadBasePostureActionProps, isTimeBasedSearchApplied }


const getDestinationItemIconClass = (item) => {
    switch (item.asset_type) {
        case "Device":
            return "device_icon";
        case "App":
            return "application_account_icon";
        case "Service":
            return item?.asset_category_list && item.asset_category_list?.length > 0 ? "gear_icon_cloud" : "gear_icon_issue";
        case "Service/Application":
            return item?.asset_category_list && item.asset_category_list?.length > 0 ? "cloud_application_account_icon" : "application_account_icon";
        case "Service/Computer Account":
            return item?.asset_category_list && item.asset_category_list?.length > 0 ? "cloud_computer_account_icon" : "computer_account_icon";
        case "Service/Service Account":
            return item?.asset_category_list && item.asset_category_list?.length > 0 ? "cloud_service_account_icon" : "service_account_icon";
        case "Service/Service Principal":
            return item?.asset_category_list && item.asset_category_list?.length > 0 ? "cloud_service_principal_icon" : "principal_icon";
        case "Service/Key and Secret":
            return item?.asset_category_list && item.asset_category_list?.length > 0 ? "cloud_key_secret__icon" : "key_secret__icon";
        case "Service/Token":
            return item?.asset_category_list && item.asset_category_list?.length > 0 ? "cloud_service_token_icon" : "token_icon";
        default:
            // If identity_category_list exists, return 'settings_icon_cloud', otherwise 'settings_icon'
            return item.identity_category_list ? 'settings_icon_cloud' : 'settings_icon';
    }
}


const getItemDestinationTitle = (item) => {
    switch (item?.asset_type) {
        case "Device":
            return "Device";
        case "App":
            return "App";
        case "Service":
            return "Service";
        case "Service/Application":
            return item?.asset_category_list && item.asset_category_list?.length > 0 ? "Cloud Application" : "Application";
        case "Service/Computer Account":
            return item?.asset_category_list && item.asset_category_list?.length > 0 ? "Cloud Service/Computer Account" : "Service/Computer Account";
        case "Service/Service Account":
            return item?.asset_category_list && item.asset_category_list?.length > 0 ? "Cloud Service" : "Service";
        case "Service/Service Principal":
            return item?.asset_category_list && item.asset_category_list?.length > 0 ? "Cloud Service/Service Principal" : "Service/Service Principal";
        case "Service/Key and Secret":
            return item?.asset_category_list && item.asset_category_list?.length > 0 ? "Cloud Service/Key and Secret" : "Service/Key and Secret";
        case "Service/Token":
            return item?.asset_category_list && item.asset_category_list?.length > 0 ? "Cloud Service/Token" : "Service/Token";

        case "User/Agent":
            return item?.asset_category_list && item.asset_category_list?.length > 0 ? "Cloud User/Agent" : "User/Agent";
        case "User/Background Browsing":
            return item?.asset_category_list && item.asset_category_list?.length > 0 ? "Cloud User/Background Browsing" : "User/Background Browsing";
        case "User":
            return item?.asset_category_list && item.asset_category_list?.length > 0 ? "Cloud User" : "User";
        default:
            // If identity_category_list exists, return 'settings_icon_cloud', otherwise 'settings_icon'
            return 'N/A';
    }

}
export const AccessAssetTypeCell: FunctionComponent<AccessAssetTypeCellRendererProps> = ({ data, loadBasePostureActionProps, isTimeBasedSearchApplied }) => {
    const item = data;
    return <span className="access-dot"><PostureCategory categories={item.asset_category_list || []} title={getItemDestinationTitle(item)}
        type={item.asset_type}
    >
        <div className='asset_type_icons'>
            <div className={getDestinationItemIconClass(item)} >
                {(item.asset_is_known === false && (item?.asset_type === "User" || item?.asset_type === "User/Agent" || item?.asset_type === "User/Background Browsing")) ? <span>?</span> : null}
                {(item.asset_is_known === false && (item?.asset_type === "Service" || item?.asset_type === "Service/Application" || item?.asset_type === 'Service/Computer Account' || item?.asset_type === 'Service/Key and Secret' || item?.asset_type === 'Service/Token' || item?.asset_type === 'Service/Service Principal' || item?.asset_type === 'Service/Service Account')) ? <span>?</span> : null}
            </div>
        </div>
    </PostureCategory>
        <BasePostureActionMenu isTimeBasedSearchApplied={isTimeBasedSearchApplied} {...loadBasePostureActionProps(item)} />
    </span>
}