import { CustomCellRendererProps } from "@ag-grid-community/react"
import { FunctionComponent } from "react"
import { convertToCommaValue } from "../../../../utils/util-methods";
import React from "react";

interface AssetIdentityCountCellRendererProps extends CustomCellRendererProps {
    handleIdAccess
}

export const AssetIdentityCountCell: FunctionComponent<AssetIdentityCountCellRendererProps> = ({ data, node,
    handleIdAccess
}: AssetIdentityCountCellRendererProps) => {
    const item = data;
    /* if (node.group) {
        return <span>{ }</span>; // Display group key for grouped rows
    } */
    return item.identity_count !== 0 ?
        <div className="shadowbox num_flows" onClick={() => { handleIdAccess(item._id?.toString(), item.type) }} id={item._id?.toString()}>
            <span>{convertToCommaValue(item.identity_count)}</span>
        </div>
        :
        <div style={{ textAlign: 'center' }} id={item._id?.toString()}>
            <span>{convertToCommaValue(item.identity_count)}</span>
        </div>
}