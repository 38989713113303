import { CustomCellRendererProps } from "@ag-grid-community/react"
import { FunctionComponent } from "react"
import { convertToCommaValue } from "../../../../../utils/util-methods";
import React from "react";
import moment from "moment";

interface LatestTimeCellRendererProps extends CustomCellRendererProps {
    onClick?
}

export const LatestTimeCell: FunctionComponent<LatestTimeCellRendererProps> = ({ data, node, onClick
}: LatestTimeCellRendererProps) => {
    return <div
        style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', gap: 10 , lineHeight:'18px' }}
    ><span>{moment(data.latest_time).format('MMM DD YYYY, hh:mm A')}</span>
        {
            onClick && <div
                className='shadowbox ' onClick={() => onClick(data)} style={{ margin: '10px auto 10px', float: 'none', width:'100px !important' }}>More Details</div>
        }
    </div>
}