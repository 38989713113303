import { CustomCellRendererProps } from "@ag-grid-community/react";
import { FunctionComponent } from "react";
import { PostureCategory } from "../../ref/PostureCategories/PostureCategory";
import React from "react";

interface IdentitySystemTypeCellRendererProps extends CustomCellRendererProps {
}

export const IdentitySystemTypeCell: FunctionComponent<IdentitySystemTypeCellRendererProps> = ({ data }) => {
    const item = data;
    return <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}><PostureCategory categories={item.directory_category_list || []}
        type={item?.directory_category_list && item?.directory_category_list?.length > 0 ? 'app' : ''
        }
        title={item?.directory_category_list && item?.directory_category_list?.length > 0 ?
            'Cloud Identity System' : item.is_public ? 'Cloud IDP' : 'On-premise'}
    >
        <div
            className={item.is_public ? 'dir_icon_cloud_directory' : 'dir_icon_directory'}
        >
        </div>
    </PostureCategory >
    </div>
}