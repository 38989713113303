import React from "react";
import {
    WeakPasswordConfig, CompromisedPasswordConfig, CompromisedUserConfig,
    ShadowIdentityConfig, SpInboundAccessConfig, SpOutboundAccessConfig, HashQualityConfig,
    HashSecurityConfig, ProtocolQualityConfig, AccessToUnauthCountries, DeviationInDailyAsset, SuspectedDirectoryBot,
    NTLMRelayAttack, SuspectedBruteForce, SuspectedPasswordSpray, EnumADAdmin, UnauthLoginTime, UnauthLoginDevice,
    SuspAttackExpiredAcc, EnumAdUser, SuspAttackDisaledAcc, SuspAttackLockedAcc, MfaConfig, ShadowIdentitySystemConfig, AccountTakerConfig,
    SuspectedAccessTokenSharing, UnusualUserAccessPl
} from "../rules-config";
import DeviationInIdentityActivity from "../rules-config/account-takeover-config/deviation-identity-act";
import { ADGoldenTicket } from "../rules-config/directory-config/ad-golden-ticket";
import { SuspectedADBruteForce } from "../rules-config/directory-config/susp-ad-brute-force";
import { SuspectedIDPBruteForce } from "../rules-config/directory-config/susp-idp-brute-force";
import { RuleType } from "./Constants";


export const loadDefaultPlaybook = (ruleType: string, props: any) => {
    return <>{ruleType === RuleType.LACK_OF_MFA && <MfaConfig {...props} />}
        {ruleType === RuleType.WEAK_PASSWORD && <WeakPasswordConfig  {...props} />}
        {ruleType === RuleType.COMPROMISED_PASSWORD && <CompromisedPasswordConfig {...props} />}
        {ruleType === RuleType.COMPROMISED_USER && <CompromisedUserConfig {...props} />}
        {ruleType === RuleType.SHADOW_INDENTITIES && <ShadowIdentityConfig {...props} />}
        {ruleType === RuleType.SUSPICIOUS_INBOUND_ACCESS && <SpInboundAccessConfig {...props} />}
        {ruleType === RuleType.SUSPICIOUS_OUTBOUND_ACCESS && <SpOutboundAccessConfig {...props} />}
        {ruleType === RuleType.AUTH_HASH_QUALITY && <HashQualityConfig {...props} />}
        {ruleType === RuleType.AUTH_HASH_SECURITY && <HashSecurityConfig {...props} />}
        {ruleType === RuleType.AUTH_PROTOCOL_QUALITY && <ProtocolQualityConfig {...props} />}
        {ruleType === RuleType.ACCESS_TO_UNAUTH_COUNTRIES && <AccessToUnauthCountries {...props} />}
        {ruleType === RuleType.ACCESS_FROM_UNAUTH_COUNTRIES && <AccessToUnauthCountries  {...props} />}
        {ruleType === RuleType.DEVIATION_IN_DAILY_ASSET && <DeviationInDailyAsset {...props} />}
        {ruleType === RuleType.SUSPECTED_DIR_BOT && <SuspectedDirectoryBot {...props} />}
        {ruleType === RuleType.SUSPECTED_BRUTE_FORCE && <SuspectedBruteForce {...props} />}
        {ruleType === RuleType.SUSPECTED_PASS_SPRAY && <SuspectedPasswordSpray {...props} />}
        {ruleType === RuleType.NTLM_RELAY_ATTACK && <NTLMRelayAttack {...props} />}
        {ruleType === RuleType.ENUM_AD_ADMINS && <EnumADAdmin {...props} />}
        {ruleType === RuleType.UNAUTH_LOGIN_TIME && <UnauthLoginTime {...props} />}
        {ruleType === RuleType.UNAUTH_LOGIN_DEVICE && <UnauthLoginDevice {...props} />}
        {ruleType === RuleType.SUSPECTED_ATT_EXP_ACC && <SuspAttackExpiredAcc {...props} />}
        {ruleType === RuleType.ENUM_AD_USERS && <EnumAdUser {...props} />}
        {ruleType === RuleType.SUSPECTED_ATT_DIS_ACC && <SuspAttackDisaledAcc {...props} />}
        {ruleType === RuleType.SUSPECTED_ATT_LOCK_ACC && <SuspAttackLockedAcc {...props} />}
        {ruleType === RuleType.ACCOUNT_TAKEOVER && <AccountTakerConfig {...props} />}
        {ruleType === RuleType.SHADOW_DIRECTORY && <ShadowIdentitySystemConfig {...props} />}
        {ruleType === RuleType.SUSPECTED_ACCESS_TOKEN && <SuspectedAccessTokenSharing {...props} />}
        {ruleType === RuleType.DEVIATION_IDENTITY_ACTIVITY && <DeviationInIdentityActivity {...props} />}
        {ruleType === RuleType.UNUSUAL_USER_ACCESS && <UnusualUserAccessPl {...props} />}
        {ruleType === RuleType.SUSPECTED_IDP_BRUTE_FORCE && <SuspectedIDPBruteForce {...props} />}
        {ruleType === RuleType.SUSPECTED_AD_BRUTE_FORCE && <SuspectedADBruteForce {...props} />}
        {ruleType === RuleType.AD_GOLDEN_TICKET && <ADGoldenTicket {...props} />}
    </>
}