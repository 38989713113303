import { IHeaderParams } from "@ag-grid-community/core";
import React from "react";
import { Box, Dialog, DialogContent, DialogTitle, Fade, Link, Tooltip } from "@mui/material";

interface CustomHeaderProps extends IHeaderParams {
    gridRef, copyConfiguration, role
}

export const AmOpsCustomHeader = (props: CustomHeaderProps) => {
    const { displayName, copyConfiguration, role } = props;

    return (
        <div className="custom-header" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {/* Column Label */}
            <span className="amops-custom-header-label">
                {displayName}
            </span>
            <Tooltip
                TransitionComponent={Fade}
                TransitionProps={{ timeout: 0 }}
                classes={{ tooltip: 'am-admin-action-content' }}
                style={{
                    display: 'block', right: '10px',
                    position: 'absolute'                   
                }}
                PopperProps={{ style: { zIndex: 1000000 } }}
                title={<div >
                    <ul>
                        {
                            <li>
                                {/* <Link> Allow users with this roles to modify settings</Link> */}
                                <Link style={{ cursor: 'pointer' }} onClick={() => { copyConfiguration(role) }}> Copy this configuration to other roles</Link>
                            </li>

                        }
                    </ul>
                </div>}>
                <button className="action_button"></button>
            </Tooltip>
        </div>
    );
}