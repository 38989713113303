import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import { Menu } from "@mui/material";
import { getFilterObjFromTag } from "../../../components/core/PostureFilter/posture-filter-utils";
import "./IdentityStatus.scss";
import { Api } from "../../../components/Axios";
import { useEffect, useState } from "react";

type IMoreActionsProps = {
    options: string[],
    pageType?: string,
    filterTemplate: any,
    notSupportedKeys: any,
    onFieldSelect?: any,
    handleAddtionalResultColumn?: any
}

const is4K = window.matchMedia("(min-width: 2560px)").matches ? 1 : 1;

const IdentityStatus = ({ widId, onDoneClick, OnCancel}: any) => {

       const [identityStatus, setIdentityStatus] = useState([]);
       const [selectedIdentityStatus, setSelectedIdentityStatus] = useState([]);
       const [chosenIdentityStatus, setChosenIdentityStatus] = useState([]);

  const getIdentityStatus = () => {
       Api.get('/posture/uniquefields').then((res: { data: any }) => {
        
        setIdentityStatus(res.data.identity_status);
      })
      .catch((error: any) => {
       
        // setRedirect(true)
      })
    }

    useEffect(()=>{
        getIdentityStatus();
    },[]);

    const handleDoneClick = (widId: string) => {
        onDoneClick(widId, selectedIdentityStatus);
      };
    
      const handleCancel = (widId: string) => {
        OnCancel(widId);
      };

      const selectStatus = (selectStatus,e)=> {
        setSelectedIdentityStatus((prev)=> {
        return [...prev,selectStatus]
       })
      }
       

    return (
        <div > 
            <div style={{border:'1px solid' , padding:'5px 10px',borderRadius:'4px'}}>
            {identityStatus.map((status)=>{
              return (
                <div >
                <div className="display-flex" style={{alignItems:'center' , justifyContent:'left' ,  margin:'5px 0px'}}>
                   
                   <div style={{width:'35px' ,}} >
                    
                    <input
                      type="checkbox"
                      style={{margin:0}}
                      checked={selectedIdentityStatus.includes(status) || false}
                      onChange={(e) =>
                        selectStatus(status, e)
                      }
                    />
                   
            </div>
            <div>
                    {status}
            </div>
            </div>
            </div> )
            }
            )}
            </div>
           

<div className="launch_button">
        <button
          type="button"
          className={"clr-btn button_styled  ghost"}
          onClick={() => handleCancel(widId)}
        >
          Cancel
        </button>
        <button
          type="button"
          className={"done-btn button_styled btn-color-555555"}
          onClick={() => handleDoneClick(widId)}
        >
          Save
        </button>
      </div>

        </div>
    )
  
}


export default IdentityStatus;