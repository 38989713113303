import { IHeaderGroupParams } from '@ag-grid-community/core';
import React, { useEffect, useRef, useState } from 'react';
import '../../identity_asset.scss'
import { useBaseAMPostureGridHook } from './BaseAMPostureGridHook';
import { useQueryParam, useQueryParams } from 'use-query-params';
import { useHistory, useLocation } from 'react-router-dom';
import { useScrollIssue } from '../Hooks/Posture';

interface PostureIssueGroupHeaderProps extends IHeaderGroupParams {
    containerRef, gridRef, type
}
export const PostureIssueGroupHeader: React.FC<PostureIssueGroupHeaderProps> = (props) => {
    const { containerRef, gridRef, type } = props;
    const [issueGridState, setIssueGridState] = useState<Array<boolean>>([]);
    const { scrollToIssueColumnFn } = useBaseAMPostureGridHook();
    const location = useLocation();
    const groupHeaderRef = useRef<HTMLDivElement>(null);

    const [hasReport, setHasReport] = useState();
    const history = useHistory();
    const { updateColumnEffect } = useScrollIssue()
    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        setHasReport(searchParams.has('reportType') ? searchParams.get('reportType') : searchParams.has('ruleType') ? searchParams.get('ruleType') : '')
    }, [location])

    useEffect(() => {
        const query = searchToJSON(location.search)
        updateColumnEffect(true, history, query?.q, type);
    }, [hasReport])

    const searchToJSON = (search: string): Record<string, string> => {
        const queryObject: Record<string, string> = {};
        const searchParams = new URLSearchParams(search);
        searchParams.forEach((value, key) => {
            queryObject[key] = value;
        });
        return queryObject;
    };

    useEffect(() => {
        gridRef?.current?.api?.addEventListener("firstDataRendered", () => {
            const query = searchToJSON(location.search)
            onScrollToViewByRule(query, location.pathname.split('/').filter(Boolean).pop())
        });
    }, [gridRef, location])

    useEffect(() => {
        let intervalId: NodeJS.Timeout;
        const checkColumnVisibility = () => {
            intervalId = setInterval(() => {
                if (containerRef?.current) {
                    const eles = containerRef?.current.getElementsByClassName("base-am-posture-grid-custom-header-root");
                    if (eles?.length > 0) {
                        initIssueHeaders();
                        const query = searchToJSON(location.search)
                        onScrollToViewByRule(query, location.pathname.split('/').filter(Boolean).pop())
                        clearInterval(intervalId);
                    }
                }
            }, 500);
        };
        checkColumnVisibility();
        return () => {
            clearInterval(intervalId);
        };
    }, []);


    useEffect(() => {
        const query = searchToJSON(location.search)
        onScrollToViewByRule(query, location.pathname.split('/').filter(Boolean).pop())
    }, [location])

    const initIssueHeaders = () => {
        const issueHeaders = loadIssueHeaders();
        const issueHeaderArray = new Array(issueHeaders.length).fill(false);
        let isFirstVisible = true;
        let isLastVisible = false;
        issueHeaders.forEach((element, index) => {

            issueHeaderArray[index] = isColumnVisible(element)
            if (index === 0 && !issueHeaderArray[index]) {
                isFirstVisible = false
            } else if (index === issueHeaderArray.length - 1 && issueHeaderArray[index]) {
                isLastVisible = true
            }
        });
        setIssueGridState(issueHeaderArray);
        return issueHeaderArray;
    }

    useEffect(() => {
        setTimeout(() => {
            const issueHeaders = loadIssueHeaders();
            let isFirstVisible = true;
            let isLastVisible = false;
            issueHeaders.forEach((element, index) => {
                if (index === 0 && !isColumnVisible(element)) {
                    isFirstVisible = false
                } else if (index === issueGridState.length - 1 && isColumnVisible(element)) {
                    isLastVisible = true
                }
            });

            if (groupHeaderRef?.current) {
                const leftArrow = groupHeaderRef?.current?.getElementsByClassName("left-arrow")[0]
                const rightArrow = groupHeaderRef?.current?.getElementsByClassName('right-arrow')[0]

                rightArrow.classList.add("disabled")
                leftArrow.classList.add("disabled")

                if (!isFirstVisible) {
                    leftArrow.classList.remove("disabled")
                }

                if (!isLastVisible) {
                    rightArrow.classList.remove("disabled")
                }
            }
        })

    }, [issueGridState])

    const onRightClick = (evt?, rule_name?: string) => {
        const issueIndex = initIssueHeaders().lastIndexOf(true);
        const issueFirstIndex = initIssueHeaders().indexOf(true);
        const issueHeaders = loadIssueHeaders();
        const issueToScroll = rule_name || issueHeaders[issueIndex + 1];
        if (issueToScroll && !isColumnVisible(issueToScroll) && issueIndex <= initIssueHeaders()?.length) {
            /*   issueGridState[issueFirstIndex] = false;
              issueGridState[issueIndex + 1] = true; */
            gridRef?.current?.api?.ensureColumnVisible(issueToScroll);
        }
    }

    const onLeftClick = (evt?, rule_name?: string) => {
        const issueIndex = initIssueHeaders().indexOf(true);
        const issueLastIndex = initIssueHeaders().lastIndexOf(true);
        const issueHeaders = loadIssueHeaders();
        const issueToScroll = rule_name || issueHeaders[issueIndex - 1];
        if (issueToScroll && !isColumnVisible(issueToScroll) && issueIndex >= 0) {
            /*    issueGridState[issueLastIndex] = false;
               issueGridState[issueIndex - 1] = true; */
            gridRef?.current?.api?.ensureColumnVisible(issueToScroll);
        }
    }

    const onScrollToViewByRule = (query, scrollEntity) => {
        scrollToIssueColumnFn(query, query.q, scrollEntity, onRightClick)
    }

    const loadIssueHeaders = () => {
        const result: Array<string> = [];
        if (containerRef?.current) {
            const els = containerRef.current.getElementsByClassName("base-am-posture-grid-custom-header-root");
            const elems = Array.from(els);
            for (let i = 0; i < elems.length; i++) {
                const element = elems[i];
                const issueType = element.getAttribute('data-issue-type');
                if (issueType) {
                    result.push(issueType)
                }
            }
        }
        return result;
    }

    const isColumnVisible = (colName: string) => {
        if (containerRef.current) {
            let holder = containerRef.current.getElementsByClassName("ag-header-viewport")[0];
            const els = containerRef.current.getElementsByClassName("base-am-posture-grid-custom-header-root");

            let issueEle;
            const elems = Array.from(els);

            for (let i = 0; i < elems.length; i++) {
                const element = elems[i];
                const issueType = element.getAttribute('data-issue-type');
                if (issueType === colName) {
                    issueEle = element;
                }
            }

            if (issueEle) {
                holder = holder || document.body
                const { left, right, width } = issueEle.getBoundingClientRect()
                const holderRect = holder.getBoundingClientRect()

                return left <= holderRect.left
                    ? holderRect.left - left <= width
                    : right - holderRect.right <= width
            }
        }
        return false;
    }
    return (
        <div className="posture-issue-custom-header-group ag-header-group-cell-label ag-sticky-label"
            ref={groupHeaderRef}
        >
            <div className="posture-issue-group-title ag-header-group-text">
                <div className="left-arrow" onClick={onLeftClick} role="button" tabIndex={0}>
                </div>
                <div>{props.displayName}</div>
                <div className="right-arrow" onClick={onRightClick} role="button" tabIndex={0}>
                </div>
            </div>
        </div>
    );
};
