import { useEffect } from "react";

export const useCollectorHook = (adchildRef, networkchildRef, onpremchildRef) => {

    const checkNoRowsOverlayAd = () => {
        const gridApi = adchildRef?.current?.api;
        const totalRowCount = gridApi?.getDisplayedRowCount();
        if (gridApi && totalRowCount === 0) {
            gridApi.showNoRowsOverlay();
        } else {
            gridApi.hideOverlay();
        }
    };

    useEffect(() => {
        const gridApi = adchildRef?.current?.api;
        if (gridApi) {
            // Attach event listeners to check for data changes
            gridApi.addEventListener("firstDataRendered", checkNoRowsOverlayAd);
            gridApi.addEventListener("modelUpdated", checkNoRowsOverlayAd);
            gridApi.addEventListener("paginationChanged", checkNoRowsOverlayAd);
            gridApi.addEventListener("sortChanged", checkNoRowsOverlayAd);
            gridApi.addEventListener("filterChanged", checkNoRowsOverlayAd);

            // Cleanup event listeners when component unmounts
            return () => {
                gridApi.removeEventListener("firstDataRendered", checkNoRowsOverlayAd);
                gridApi.removeEventListener("modelUpdated", checkNoRowsOverlayAd);
                gridApi.removeEventListener("paginationChanged", checkNoRowsOverlayAd);
                gridApi.removeEventListener("sortChanged", checkNoRowsOverlayAd);
                gridApi.removeEventListener("filterChanged", checkNoRowsOverlayAd);
            };
        }
    }, [adchildRef?.current?.api]);


    const checkNoRowsOverlayNet = () => {
        const gridApi = networkchildRef?.current?.api;
        const totalRowCount = gridApi?.getDisplayedRowCount();
        if (gridApi && totalRowCount === 0) {
            gridApi.showNoRowsOverlay();
        } else {
            gridApi.hideOverlay();
        }
    };

    useEffect(() => {
        const gridApi = networkchildRef?.current?.api;
        if (gridApi) {
            // Attach event listeners to check for data changes
            gridApi.addEventListener("firstDataRendered", checkNoRowsOverlayNet);
            gridApi.addEventListener("modelUpdated", checkNoRowsOverlayNet);
            gridApi.addEventListener("paginationChanged", checkNoRowsOverlayNet);
            gridApi.addEventListener("sortChanged", checkNoRowsOverlayNet);
            gridApi.addEventListener("filterChanged", checkNoRowsOverlayNet);

            // Cleanup event listeners when component unmounts
            return () => {
                gridApi.removeEventListener("firstDataRendered", checkNoRowsOverlayNet);
                gridApi.removeEventListener("modelUpdated", checkNoRowsOverlayNet);
                gridApi.removeEventListener("paginationChanged", checkNoRowsOverlayNet);
                gridApi.removeEventListener("sortChanged", checkNoRowsOverlayNet);
                gridApi.removeEventListener("filterChanged", checkNoRowsOverlayNet);
            };
        }
    }, [networkchildRef?.current?.api]);


    const checkNoRowsOverlayOnPrem = () => {
        const gridApi = onpremchildRef?.current?.api;
        const totalRowCount = gridApi?.getDisplayedRowCount();
        if (gridApi && totalRowCount === 0) {
            gridApi.showNoRowsOverlay();
        } else {
            gridApi.hideOverlay();
        }
    };

    useEffect(() => {
        const gridApi = onpremchildRef?.current?.api;
        if (gridApi) {
            // Attach event listeners to check for data changes
            gridApi.addEventListener("firstDataRendered", checkNoRowsOverlayOnPrem);
            gridApi.addEventListener("modelUpdated", checkNoRowsOverlayOnPrem);
            gridApi.addEventListener("paginationChanged", checkNoRowsOverlayOnPrem);
            gridApi.addEventListener("sortChanged", checkNoRowsOverlayOnPrem);
            gridApi.addEventListener("filterChanged", checkNoRowsOverlayOnPrem);

            // Cleanup event listeners when component unmounts
            return () => {
                gridApi.removeEventListener("firstDataRendered", checkNoRowsOverlayOnPrem);
                gridApi.removeEventListener("modelUpdated", checkNoRowsOverlayOnPrem);
                gridApi.removeEventListener("paginationChanged", checkNoRowsOverlayOnPrem);
                gridApi.removeEventListener("sortChanged", checkNoRowsOverlayOnPrem);
                gridApi.removeEventListener("filterChanged", checkNoRowsOverlayOnPrem);
            };
        }
    }, [onpremchildRef?.current?.api]);
}